import { PersonFormSchema } from "./PersonFormSchema";

import { z } from "zod";

export const ProjectExpressFormSchema = z
  .object({
    fullPower: z.number(),
    value: z.number(),
    code: z.string(),
    note: z.string(),
    title: z.string(),
    client: PersonFormSchema,
    pipedriveDealId: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined), // zero is acceptable
  })
  .partial();

export interface IProjectExpressFormSchema
  extends z.infer<typeof ProjectExpressFormSchema> {}
