import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedProposalHistory } from "./interface";

import { fetchGetPaginatedProposalHistory } from "~api/proposal/history/{custom-id}/get";

export const useFetchGetPaginatedProposalHistoryKey =
  "api/proposal/history/{custom-id}/get";

export function useFetchGetPaginatedProposalHistory({
  dependencyArray = [],
  options,
  payload = {
    customId: 0,
    page: 0,
    size: 5,
  },
}: IUseFetchGetPaginatedProposalHistory) {
  return useQuery(
    [useFetchGetPaginatedProposalHistoryKey, dependencyArray],
    async () => await fetchGetPaginatedProposalHistory(payload),
    options,
  );
}
