import { useMutation } from "react-query";

import { useFetchGetStructureCalculationKey } from "../useFetchGetStructureCalculation";
import type { IUseFetchUpdateStructureCalculation } from "./interface";

import { fetchUpdateStructuresMaterialCostRule } from "~api/structuresMaterialCostRule/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateStructureCalculationKey =
  "api/structuresMaterialCostRule/{id}/put";

export function useFetchUpdateStructureCalculation({
  options,
}: IUseFetchUpdateStructureCalculation) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateStructuresMaterialCostRule(payload),
    {
      ...options,
      mutationKey: useFetchUpdateStructureCalculationKey,
      onSuccess: (data, variables, context) => {
        message.success("Cálculo de estrutura foi alterado");
        queryClient.invalidateQueries(useFetchGetStructureCalculationKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
