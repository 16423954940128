import { TotalWonGroupedByMonthInsightBarDataSchema } from "./TotalWonGroupedByMonthInsightBarDataSchema";

import { z } from "zod";

export const TotalWonGroupedByMonthInsightDTOSchema = z.object({
  data: TotalWonGroupedByMonthInsightBarDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ITotalWonGroupedByMonthInsightDTOSchema
  extends z.infer<typeof TotalWonGroupedByMonthInsightDTOSchema> {}
