import { api, apiException } from "~services/api";
import {
  FetchUpdateMaxUsersLicensedPayloadSchema,
  IFetchUpdateMaxUsersLicensedPayloadSchema,
} from "~types/schemas";

export const fetchUpdateMaxUsersLicensed = async (
  payload: IFetchUpdateMaxUsersLicensedPayloadSchema,
) => {
  try {
    const { userId, maxUsersLicensed } =
      FetchUpdateMaxUsersLicensedPayloadSchema.parse(payload);

    await api.patch(`/admin/users/${userId}/max-users-licensed`, {
      maxUsersLicensed,
    });
  } catch (error) {
    throw apiException(error);
  }
};
