import { GenericProductResponseSchema } from "./GenericProductResponseSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedGenericProductResponseSchema = PaginationSchema(
  GenericProductResponseSchema,
);

export interface IPaginatedGenericProductResponseSchema
  extends z.infer<typeof PaginatedGenericProductResponseSchema> {}
