import { UsefulAreaDTOSchema } from "./UsefulAreaDTOSchema";

import {
  AlternatingCurrentMaterialCalcBaseEnum,
  IrradiationTypeEnum,
  PipedriveBaseCalculationTypeEnum,
  StructureGroupEnum,
  VoltageEnum,
} from "~types/enum";
import { z } from "zod";

export const PremiseDTOSchema = z.object({
  id: z
    .number({ message: "Faltando o ID da premissa" })
    .int({ message: "O ID da deve ser um número inteiro" })
    .gt(0, { message: "O ID da premissa deve ser maior do que zero" }),
  currentEnergyInflation: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vplDiscountRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  moduleDeprecationFirstYear: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  moduleDeprecationOtherYears: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  performanceRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  showIncreasingPowerOptions: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  kitPowerLowerLimitPercent: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  opexRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  ghiSystemId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  alternatingCurrentMaterialCalcBase:
    AlternatingCurrentMaterialCalcBaseEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  proposalExpirationPeriodInDays: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  usefulAreaList: z
    .array(UsefulAreaDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  updateValueDealPipedrive: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  pipedriveBaseCalculationType:
    PipedriveBaseCalculationTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  dealBaseCalculationType: PipedriveBaseCalculationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  irradiationType: IrradiationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  pipedrivePipelineId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  commonUserPipelineId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  ignoreElectricUtilityRules: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  accumulatedCreditsUsedInPayback: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  considerConsumptionUnits: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  considerDispCostInPlantPower: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  ignoreGenericManufactureList: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  structureGroup: StructureGroupEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  minCelsiusTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  showPaymentStep: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  applyInflationToCosip: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  customIdProposalFormat: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  microAndStringInverterMixAllowedInSolarKit: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  differentPhaseInverterMixAllowedInSolarKit: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPremiseDTOSchema extends z.infer<typeof PremiseDTOSchema> {}
