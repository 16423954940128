import { api, apiException } from "~services/api";
import {
  IFetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema,
  FetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema,
  PaginatedElectricUtilityProfileDetailDTOSchema,
} from "~types/schemas";

export const fetchPostToGetPaginatedElectricUtilityProfiles = async (
  payload: IFetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema,
) => {
  try {
    const body =
      FetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema.parse(
        payload,
      );

    const response = await api.post(`/electric-utility-profile/filters`, body);

    const parsedData = PaginatedElectricUtilityProfileDetailDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
