import { z } from "zod";

export const logEntityTypeEnumValues = [
  "AlternatingCurrentMaterial",
  "AdditionalCostDescriptor",
  "Labor",
  "Logistics",
  "Project",
  "StructureMaterialCostRule",
  "UserAccount",
  "Company",
  "Inverter",
  "PvModule",
] as const;

export const LogEntityTypeEnum = z
  .enum(logEntityTypeEnumValues)
  .catch((e) => e.input);

export type LogEntityTypeEnum = z.infer<typeof LogEntityTypeEnum>;
