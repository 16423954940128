import { SimplePipelineStageDTOSchema } from "./SimplePipelineStageDTOSchema";

import { z } from "zod";

export const OrganizationDealDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  pipelineName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  pipelinePosition: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  pipelineStages: SimplePipelineStageDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IOrganizationDealDTOSchema
  extends z.infer<typeof OrganizationDealDTOSchema> {}
