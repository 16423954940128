import { api, apiException } from "~services/api";
import {
  type IFetchGetLostMotiveInsightPayloadSchema,
  FetchGetLostMotiveInsightPayloadSchema,
  LostMotiveInsightDTOSchema,
} from "~types/schemas";

export const fetchGetLostMotiveInsight = async (
  payload: IFetchGetLostMotiveInsightPayloadSchema,
) => {
  try {
    const queryParams = FetchGetLostMotiveInsightPayloadSchema.parse(payload);

    const response = await api.get("/insights/lostMotiveInsight", {
      params: queryParams,
    });

    const parsedData = LostMotiveInsightDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
