import { z } from "zod";

export const FetchGetPersonByIdPayloadSchema = z.object({
  personId: z
    .number({ message: "ID da pessoa é obrigatório" })
    .int({ message: "ID da pessoa deve ser um inteiro" })
    .gt(0, { message: "ID da pessoa deve ser maior que zero" }),
});

export interface IFetchGetPersonByIdPayloadSchema
  extends z.infer<typeof FetchGetPersonByIdPayloadSchema> {}
