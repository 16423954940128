import { PaginationSchema } from "./PaginationSchema";
import { SimultaneityProfileDTOSchema } from "./SimultaneityProfileDTOSchema";

import { z } from "zod";

export const PaginatedSimultaneityProfilesDTOSchema = PaginationSchema(
  SimultaneityProfileDTOSchema,
);

export interface IPaginatedSimultaneityProfilesDTOSchema
  extends z.infer<typeof PaginatedSimultaneityProfilesDTOSchema> {}
