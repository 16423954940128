import { api, apiException } from "~services/api";
import {
  ProjectPaymentSimulationOptionDTOSchema,
  FetchGetSimulationOptionByIdPayloadSchema,
  IFetchGetSimulationOptionByIdPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetSimulationOptionById = async (
  payload: IFetchGetSimulationOptionByIdPayloadSchema,
) => {
  try {
    const { projectPaymentSimulationGroupId } =
      FetchGetSimulationOptionByIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/project/payment/simulation/option/${projectPaymentSimulationGroupId}`,
    );

    const parsedData = z
      .array(ProjectPaymentSimulationOptionDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
