import { api, apiException } from "~services/api";
import {
  ActivityDTOSchema,
  FetchToggleStatusActivityPayloadSchema,
  type IFetchToggleStatusActivityPayloadSchema,
} from "~types/schemas";

export const fetchToggleStatusActivity = async (
  payload: IFetchToggleStatusActivityPayloadSchema,
) => {
  try {
    const { id } = FetchToggleStatusActivityPayloadSchema.parse(payload);

    const response = await api.patch(`/activities/${id}/toggleStatus`);

    const parsedData = ActivityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
