import { useQuery } from "react-query";

import type { IUseFetchGetStructureCalculation } from "./interface";

import { fetchGetStructuresMaterialCostRule } from "~api/structuresMaterialCostRule/get";

export const useFetchGetStructureCalculationKey =
  "api/structuresMaterialCostRule/get";

export function useFetchGetStructureCalculation({
  dependencyArray = [],
  options,
}: IUseFetchGetStructureCalculation) {
  return useQuery(
    [useFetchGetStructureCalculationKey, dependencyArray],
    async () => await fetchGetStructuresMaterialCostRule(),
    options,
  );
}
