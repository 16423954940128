import { ProjectTypeEnum } from "~types/enum";
import { z } from "zod";

export const PricingTypeDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  default: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPricingTypeDTOSchema
  extends z.infer<typeof PricingTypeDTOSchema> {}
