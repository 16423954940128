import { z } from "zod";

export const FortlevStructureTypeEnum = z
  .enum([
    "COLONIAL",
    "FIBROMADEIRA",
    "FIBROMETAL",
    "FIBROMETAL_ROSCA_DUPLA",
    "KALHETAO",
    "LAJE",
    "METALICO",
    "SOLO",
  ] as const)
  .catch((e) => e.input);

export type FortlevStructureTypeEnum = z.infer<typeof FortlevStructureTypeEnum>;
