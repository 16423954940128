import { useQuery } from "react-query";

import type { IUseFetchGetVariablesWithFieldBind } from "./interface";

import { fetchGetVariablesWithFieldBind } from "~api/variables/field-bind/get";

export const useFetchGetVariablesWithFieldKey = "api/variables/field-bind/get";

export function useFetchGetVariablesWithFieldBind({
  dependencyArray = [],
  options,
}: IUseFetchGetVariablesWithFieldBind) {
  return useQuery(
    [useFetchGetVariablesWithFieldKey, dependencyArray],
    async () => await fetchGetVariablesWithFieldBind(),
    options,
  );
}
