import { useMutation } from "react-query";

import type { IUseFetchCreateCustomFieldValue } from "./interface";

import { fetchCreateCustomFieldValue } from "~api/projects/step_two/custom-field-value/custom-id/post";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchCreateCustomFieldValueKey =
  "api/projects/step_two/custom-field-value/custom-id/post";

export function useFetchCreateCustomFieldValue({
  options,
}: IUseFetchCreateCustomFieldValue) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchCreateCustomFieldValue(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateCustomFieldValueKey,
      onSuccess: (data, variables, context) => {
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
