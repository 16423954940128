import { useQuery } from "react-query";

import type { IUseFetchGetAllStructuresToAssociateLabor } from "./interface";

import { fetchGetAllStructuresToAssociateLabor } from "~api/labors/structures/for-associate/get";

export const useFetchGetAllStructuresToAssociateLaborKey =
  "api/labors/structures/for-associate/get";

export function useFetchGetAllStructuresToAssociateLabor({
  dependencyArray = [],
  options,
  payload = {
    id: 0,
  },
}: IUseFetchGetAllStructuresToAssociateLabor) {
  return useQuery(
    [useFetchGetAllStructuresToAssociateLaborKey, dependencyArray],
    async () => await fetchGetAllStructuresToAssociateLabor(payload),
    options,
  );
}
