import { useMutation } from "react-query";

import { useFetchGetDealByCustomIdKey } from "../../deal/useFetchGetDealByCustomId";
import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetAutoCompletePersonKey } from "../useFetchGetAutoCompletePerson";
import { useFetchGetPaginatedPersonKey } from "../useFetchGetPaginatedPerson";
import { useFetchGetPersonByIdKey } from "../useFetchGetPersonById";
import type { IUseFetchUpdatePerson } from "./interface";

import { fetchUpdatePerson } from "~api/persons/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePersonKey = "api/persons/{id}/put";

export function useFetchUpdatePerson({ options }: IUseFetchUpdatePerson) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdatePerson(payload), {
    ...options,
    mutationKey: useFetchUpdatePersonKey,
    onSuccess: (data, variables, context) => {
      message.success("Pessoa foi atualizada");
      queryClient.invalidateQueries(useFetchGetAutoCompletePersonKey);
      queryClient.invalidateQueries(useFetchGetPaginatedPersonKey);
      queryClient.invalidateQueries(useFetchGetPersonByIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
      queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao atualizar pessoa: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
