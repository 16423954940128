import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesByPersonId } from "./interface";

import { fetchGetHistoriesByPersonId } from "~api/histories/person/{id}/get";

export const useFetchGetHistoriesByPersonIdKey =
  "api/histories/person/{id}/get";

export function useFetchGetHistoriesByPersonId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesByPersonId) {
  return useQuery(
    [useFetchGetHistoriesByPersonIdKey, dependencyArray],
    async () => await fetchGetHistoriesByPersonId(payload),
    options,
  );
}
