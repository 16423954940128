import { api, apiException } from "~services/api";
import {
  FetchActivateBusinessUnitPayloadSchema,
  IFetchActivateBusinessUnitPayloadSchema,
} from "~types/schemas";

export const fetchActivateBusinessUnit = async (
  payload: IFetchActivateBusinessUnitPayloadSchema,
) => {
  try {
    const { businessUnitId } =
      FetchActivateBusinessUnitPayloadSchema.parse(payload);

    await api.put(`/business-units/activate/${businessUnitId}`);
  } catch (error) {
    throw apiException(error);
  }
};
