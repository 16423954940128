import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises";
import { useFetchGetPremisesTariffKey } from "../useFetchGetPremisesTariff";
import type { IUseFetchUpdatePremisesFinancial } from "./interface";

import { fetchUpdatePremisesFinancial } from "~api/premises/financial/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePremisesFinancialKey = "api/premises/usefulArea/put";

export function useFetchUpdatePremisesFinancial({
  options,
  displaySuccessMessage = true,
}: IUseFetchUpdatePremisesFinancial) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdatePremisesFinancial(payload),
    {
      ...options,
      mutationKey: useFetchUpdatePremisesFinancialKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Premissas financeiras foram atualizadas");
        }

        queryClient.invalidateQueries(useFetchGetPremisesKey);
        queryClient.invalidateQueries(useFetchGetPremisesTariffKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao alterar premissas financeiras: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
