import { z } from "zod";

export const FetchGetProposalCustomFieldSettingsByProfilePayloadSchema =
  z.object({
    customFieldId: z.number().int().gt(0),
  });

export interface IFetchGetProposalCustomFieldSettingsByProfilePayloadSchema
  extends z.infer<
    typeof FetchGetProposalCustomFieldSettingsByProfilePayloadSchema
  > {}
