import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesTypeFilterByDealId } from "./interface";

import { fetchGetHistoriesFiltersTypeByDealid } from "~api/histories/deal/filters/types/get";

export const useFetchGetHistoriesTypeFilterByDealIdKey =
  "api/histories/deal/filters/types/get";

export function useFetchGetHistoriesTypeFilterByDealId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesTypeFilterByDealId) {
  return useQuery(
    [useFetchGetHistoriesTypeFilterByDealIdKey, dependencyArray],
    async () => await fetchGetHistoriesFiltersTypeByDealid(payload),
    options,
  );
}
