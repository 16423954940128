import { api, apiException } from "~services/api";
import {
  OrganizationDetailPipedriveDTOSchema,
  FetchGetOrganizationDetailPipedrivePayloadSchema,
  IFetchGetOrganizationDetailPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchGetPipedriveOrganizationAddress = async (
  payload: IFetchGetOrganizationDetailPipedrivePayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchGetOrganizationDetailPipedrivePayloadSchema.parse(payload);

    const response = await api.get(
      `/projects/step_one/pipedrive/organization/${pipedriveId}`,
    );

    const parsedData = OrganizationDetailPipedriveDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
