import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAneelInDevelopmentPayloadSchema,
  IFetchGetPaginatedAneelInDevelopmentPayloadSchema,
  PaginatedAneelInDevelopmentDTOSchema,
} from "~types/schemas";

export async function fetchGetPaginatedAneelInDevelopment(
  payload: IFetchGetPaginatedAneelInDevelopmentPayloadSchema,
) {
  try {
    const params =
      FetchGetPaginatedAneelInDevelopmentPayloadSchema.parse(payload);

    const response = await api.get("/development/aneel-list", {
      params,
    });

    const parsedData = PaginatedAneelInDevelopmentDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
