import { useMutation } from "react-query";

import { useFetchGetAllBusinessUnitsKey } from "../useFetchGetAllBusinessUnits";
import { useFetchGetPaginatedBusinessUnitsKey } from "../useFetchGetPaginatedBusinessUnits";
import type { IUseFetchCopyBusinessUnit } from "./interface";

import { fetchCopyBusinessUnit } from "~api/business-units/{id}/copy/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCopyBusinessUnitKey = "api/business-units/{id}/copy/post";

export function useFetchCopyBusinessUnit({
  options,
}: IUseFetchCopyBusinessUnit) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCopyBusinessUnit(payload), {
    ...options,
    mutationKey: useFetchCopyBusinessUnitKey,
    onSuccess: (data, variables, context) => {
      message.success("Unidade criada com sucesso!");
      queryClient.invalidateQueries(useFetchGetPaginatedBusinessUnitsKey);
      queryClient.invalidateQueries(useFetchGetAllBusinessUnitsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar unidade: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
