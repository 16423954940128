import { z } from "zod";

export const CompanyPipedriveMessageFormSchema = z.object({
  customPipedriveActivityMessage: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ICompanyPipedriveMessageFormSchema
  extends z.infer<typeof CompanyPipedriveMessageFormSchema> {}
