import { ScaleEnum } from "~types/enum";
import { z } from "zod";

export const CalculationTypeComplementaryItemsSchema = z.object({
  value: ScaleEnum,
  description: z.string(),
});

export interface ICalculationTypeComplementaryItemsSchema
  extends z.infer<typeof CalculationTypeComplementaryItemsSchema> {}
