import { useQuery } from "react-query";

import type { IUseFetchGetAllVariables } from "./interface";

import { fetchGetVariables } from "~api/variables/get";

export const useFetchGetAllVariablesKey = "api/variables/get";

export function useFetchGetAllVariables({
  dependencyArray = [],
  options,
}: IUseFetchGetAllVariables) {
  return useQuery(
    [useFetchGetAllVariablesKey, dependencyArray],
    async () => await fetchGetVariables(),
    options,
  );
}
