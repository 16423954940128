import { api, apiException } from "~services/api";
import {
  FetchDeleteOrganizationPayloadSchema,
  type IFetchDeleteOrganizationPayloadSchema,
} from "~types/schemas";

export const fetchDeleteOrganization = async (
  payload: IFetchDeleteOrganizationPayloadSchema,
) => {
  try {
    const { organizationId } =
      FetchDeleteOrganizationPayloadSchema.parse(payload);

    await api.delete(`/organizations/${organizationId}`);
  } catch (error) {
    throw apiException(error);
  }
};
