import { api, apiException } from "~services/api";
import {
  FetchImportUserFromPipedrivePayloadSchema,
  IFetchImportUserFromPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchImportUserFromPipedrive = async (
  payload: IFetchImportUserFromPipedrivePayloadSchema,
) => {
  try {
    const { userId, ...body } =
      FetchImportUserFromPipedrivePayloadSchema.parse(payload);

    await api.post(`/pipedrive/users/add/${userId}`, body);
  } catch (error) {
    throw apiException(error);
  }
};
