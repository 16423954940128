import { useMutation } from "react-query";

import type { IUseFetchUpdateUserBusinessUnit } from "./interface";

import { fetchUpdateUserBusinessUnit } from "~api/users/business-units/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateUserBusinessUnitKey =
  "api/users/business-units/{id}/put";

export function useFetchUpdateUserBusinessUnit({
  options,
}: IUseFetchUpdateUserBusinessUnit) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateUserBusinessUnit(payload),
    {
      ...options,
      mutationKey: useFetchUpdateUserBusinessUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidade de negócio foi alterada");
        queryClient.resetQueries(); // reset all queries
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao alterar unidade de negócio: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
