import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductModulePowersPayloadSchema,
  IFetchGetPaginatedProductModulePowersPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetPaginatedProductModulePowers = async (
  payload: IFetchGetPaginatedProductModulePowersPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductModulePowersPayloadSchema.parse(payload);

    const response = await api.get(`/product/search/pvModule/modulePowerList`, {
      params,
    });

    const parsedData = z.array(z.number()).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
