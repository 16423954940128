import { z } from "zod";

export const FetchGetOrganizationInformationByPipedriveIdPayloadSchema =
  z.object({
    pipedriveId: z.number().int().gt(0),
  });

export interface IFetchGetOrganizationInformationByPipedriveIdPayloadSchema
  extends z.infer<
    typeof FetchGetOrganizationInformationByPipedriveIdPayloadSchema
  > {}
