import { api, apiException } from "~services/api";
import {
  type IFetchCreatePersonPayloadSchema,
  FetchCreatePersonPayloadSchema,
  PersonDTOSchema,
} from "~types/schemas";

export const fetchCreatePerson = async (
  payload: IFetchCreatePersonPayloadSchema,
) => {
  try {
    const body = FetchCreatePersonPayloadSchema.parse(payload);

    const response = await api.post(`/persons`, body);

    const parsedData = PersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
