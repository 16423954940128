import { useMutation } from "react-query";

import { useFetchGetReportsKey } from "../useFetchGetReports";
import { useFetchGetReportTemplateKey } from "../useFetchGetReportTemplateById";
import type { IUseFetchUpdateReportTemplateById } from "./interface";

import { fetchUpdateReportTemplateById } from "~api/reports/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateReportTemplateKey = "api/reports/{id}/put";

export function useFetchUpdateReportTemplateById({
  options,
}: IUseFetchUpdateReportTemplateById) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateReportTemplateById(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateReportTemplateKey,
      onSuccess: (data, variables, context) => {
        message.success("Template atualizado com sucesso!");
        queryClient.invalidateQueries(useFetchGetReportTemplateKey);
        queryClient.invalidateQueries(useFetchGetReportsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
