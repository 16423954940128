import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchCreateManyPaymentSimulationOptions } from "./interface";

import { fetchCreateManySimulationOption } from "~api/project/payment/simulation/option/from-list/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateManyPaymentSimulationOptionsKey =
  "api/project/payment/simulation/option/from-list/post";

export function useFetchCreateManyPaymentSimulationOptions({
  options,
  displaySuccessMessage = true,
}: IUseFetchCreateManyPaymentSimulationOptions) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateManySimulationOption(payload),
    {
      ...options,
      mutationKey: useFetchCreateManyPaymentSimulationOptionsKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Simulação criada");
        }
        queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar simulação: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
