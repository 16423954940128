import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises/index";
import { useFetchGetPremisesTariffKey } from "../useFetchGetPremisesTariff";
import { IUseFetchUpdateUpdateDealPipedrive } from "./interface";

import { fetchUpdatePremisesPipedrive } from "~api/premises/pipedrive/update/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchToggleUpdateDealPipedriveKey =
  "api/premises/pipedrive/update/patch";

export function useFetchToggleUpdateDealPipedrive(
  options?: IUseFetchUpdateUpdateDealPipedrive,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdatePremisesPipedrive(payload),
    {
      ...options,
      mutationKey: useFetchToggleUpdateDealPipedriveKey,
      onSuccess: (data, variables, context) => {
        message.success("Premissas atualizadas!");

        queryClient.invalidateQueries(useFetchGetPremisesKey);
        queryClient.invalidateQueries(useFetchGetPremisesTariffKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
