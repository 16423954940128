import { EosAddressDTOSchema } from "./EosAddressDTOSchema";
import { EosClientResponseSchema } from "./EosClientResponseSchema";
import { EosDocumentSearchResponseSchema } from "./EosDocumentSearchResponseSchema";
import { EosGuarantorResponseSchema } from "./EosGuarantorResponseSchema";

import { z } from "zod";

export const EosProposalDetailSchema = z.object({
  protocolo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  carencia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  parcela: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valorTotal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maoObra: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  entrada: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tempoFinanciamento: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  potenciaInstaladaSugerida: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  kitFotovoltaico: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  mediaContaEnergia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  comSeguro: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  geracaoMediaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
  cliente: EosClientResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  documentos: z.array(EosDocumentSearchResponseSchema).nullish().default([]),
  avalista: EosGuarantorResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  vendedor: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  integrador: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosProposalDetailSchema
  extends z.infer<typeof EosProposalDetailSchema> {}
