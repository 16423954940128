import { api, apiException } from "~services/api";
import {
  HideableFieldDTOSchema,
  FetchUpdateHideableFieldsPayloadSchema,
  IFetchUpdateHideableFieldsPayloadSchema,
} from "~types/schemas";

export const fetchUpdateHideableFields = async (
  payload: IFetchUpdateHideableFieldsPayloadSchema,
) => {
  try {
    const { fieldId } = FetchUpdateHideableFieldsPayloadSchema.parse(payload);

    const response = await api.put(`/fields/hideable/${fieldId}`);

    const parsedData = HideableFieldDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
