import { UpdateProjectPaymentSimulationOptionFormSchema } from "./UpdateProjectPaymentSimulationOptionFormSchema";

import { z } from "zod";

export const FetchUpdateManySimulationOptionPayloadSchema = z
  .array(UpdateProjectPaymentSimulationOptionFormSchema)
  .nullish()
  .transform((val) => val ?? []);

export interface IFetchUpdateManySimulationOptionPayloadSchema
  extends z.infer<typeof FetchUpdateManySimulationOptionPayloadSchema> {}
