import { api, apiException } from "~services/api";
import {
  FetchUpdateDealByCustomIdPayloadSchema,
  type IFetchUpdateDealByCustomIdPayloadSchema,
} from "~types/schemas";

export const fetchUpdateDealByCustomId = async (
  payload: IFetchUpdateDealByCustomIdPayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateDealByCustomIdPayloadSchema.parse(payload);

    await api.put(`/deal/${customId}`, body);
    // const response = await api.put(`/deal/${customId}`, body);

    // const parsedData = DealDTOSchema.parse(response.data);

    // return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
