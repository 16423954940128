import { AldoParameterFormSchema } from "./AldoParameterFormSchema";

import { z } from "zod";

export const FetchUpdateConfigAldoDistributorIntegrationPayloadSchema =
  AldoParameterFormSchema;

export interface IFetchUpdateConfigAldoDistributorIntegrationPayloadSchema
  extends z.infer<
    typeof FetchUpdateConfigAldoDistributorIntegrationPayloadSchema
  > {}
