import { z } from "zod";

export const PipelineStageItemSchema = z.object({
  id: z.number().int().gte(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPipelineStageItemSchema
  extends z.infer<typeof PipelineStageItemSchema> {}
