import { useQuery } from "react-query";

import type { IUseFetchGetSearchClient } from "./interface";

import { fetchGetSearchClient } from "~api/projects/step_one/search/get";

export const useFetchGetSearchClientKey = "api/projects/step_one/search/get";

export function useFetchGetSearchClient({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetSearchClient) {
  return useQuery(
    [useFetchGetSearchClientKey, dependencyArray],
    async () => await fetchGetSearchClient(payload),
    options,
  );
}
