import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedModulesPayloadSchema,
  IFetchGetPaginatedModulesPayloadSchema,
  PaginatedCustomPvModuleDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedModules = async (
  payload: IFetchGetPaginatedModulesPayloadSchema,
) => {
  try {
    const body = FetchGetPaginatedModulesPayloadSchema.parse(payload);

    const response = await api.post(`/product/pvModule`, body);

    const parsedData = PaginatedCustomPvModuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
