import { z } from "zod";

export const FetchGetPaginatedDatasheetRevisionsPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 10),
  id: z
    .number({ message: "ID do Datasheet é obrigatório" })
    .int({ message: "ID do Datasheet deve ser um numero inteiro" })
    .gt(0, { message: "ID do Datasheet deve ser maior que zero" }),
});

export interface IFetchGetPaginatedDatasheetRevisionsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedDatasheetRevisionsPayloadSchema> {}
