import { LeadTimeInsightGroupedByUserDataSchema } from "./LeadTimeInsightGroupedByUserDataSchema";

import { z } from "zod";

export const LeadTimeInsightGroupedByUserDtoSchema = z.object({
  data: LeadTimeInsightGroupedByUserDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ILeadTimeInsightGroupedByUserDtoSchema
  extends z.infer<typeof LeadTimeInsightGroupedByUserDtoSchema> {}
