import { StructureTypeDetailsDTOSchema } from "./StructureTypeDetailsDTOSchema";

import {
  StructureFixationEnum,
  StructureSubCategoryEnum,
  StructureTypeEnum,
  StructureUnitSizeEnum,
} from "~types/enum";
import { z } from "zod";

export const UsefulAreaDTOSchema = z.object({
  id: z
    .number({ message: "Faltando o ID da área útil" })
    .int({ message: "ID da área útil deve ser um inteiro" })
    .gt(0, { message: "ID da área útil deve ser maior do que zero" }),
  type: StructureTypeEnum.nullish().transform((val) => val ?? undefined),
  subCategory: StructureSubCategoryEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  details: StructureTypeDetailsDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  fixation: StructureFixationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  unitSize: StructureUnitSizeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  spacingFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  structureDescription: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUsefulAreaDTOSchema
  extends z.infer<typeof UsefulAreaDTOSchema> {}
