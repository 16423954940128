import { api, apiException } from "~services/api";
import {
  FetchGetUsersAssignedWithProfilePayloadSchema,
  type IFetchGetUsersAssignedWithProfilePayloadSchema,
  UserManagementDTOSchema,
} from "~types/schemas";

export const fetchGetUsersAssignedWithProfile = async (
  payload: IFetchGetUsersAssignedWithProfilePayloadSchema,
) => {
  try {
    const { profileId } =
      FetchGetUsersAssignedWithProfilePayloadSchema.parse(payload);

    const response = await api.get(`/company/users/profile/${profileId}`);

    const parsedData = UserManagementDTOSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
