import { useMutation } from "react-query";

import type { IUseFetchUpdateConsumptionUnit } from "./interface";

import { fetchUpdateConsumptionUnit } from "~api/projects/step_two/consumption-unit/{customId}/compensationType/{compensationType}/put";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchUpdateConsumptionUnitKey =
  "api/projects/step_two/consumption-unit/{customId}/compensationType/{compensationType}/put";

export function useFetchUpdateConsumptionUnit(
  options?: IUseFetchUpdateConsumptionUnit,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateConsumptionUnit(payload),
    {
      ...options,
      mutationKey: useFetchUpdateConsumptionUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidades foram definidas");
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
