import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import { useFetchGetCountOfUsersAccountOwnerKey } from "../useFetchGetCountOfUsersAccountOwner";
import type { IUseFetchLinkPipedrive } from "./interface";

import { fetchLinkPipedrive } from "~api/admin/users/{companyId}/link-pipedrive/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchLinkPipedriveKey =
  "api/admin/users/{companyId}/link-pipedrive/patch";

export function useFetchLinkPipedrive(options?: IUseFetchLinkPipedrive) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchLinkPipedrive(payload), {
    ...options,
    mutationKey: useFetchLinkPipedriveKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário foi associado ao pipedrive com sucesso!");
      queryClient.invalidateQueries(useFetchGetAdminUsersKey);
      queryClient.invalidateQueries(useFetchGetCountOfUsersAccountOwnerKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
