import { LeadTimeInsightGroupedByPipelineStageDataSchema } from "./LeadTimeInsightGroupedByPipelineStageDataSchema";

import { z } from "zod";

export const LeadTimeInsightGroupedByPipelineStageDtoSchema = z.object({
  data: LeadTimeInsightGroupedByPipelineStageDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ILeadTimeInsightGroupedByPipelineStageDtoSchema
  extends z.infer<typeof LeadTimeInsightGroupedByPipelineStageDtoSchema> {}
