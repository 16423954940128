import { useMutation } from "react-query";

import { useFetchGetPaginatedDistributorsKey } from "../useFetchGetPaginatedDistributors";
import type { IUseFetchPatchActivateDistributorKitFilter } from "./interface";

import { fetchPatchActivateDistributorKitFilter } from "~api/distributors/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchActivateDistributorKitFilterKey =
  "api/distributors/{id}/patch";

export function useFetchPatchActivateDistributorKitFilter({
  options,
}: IUseFetchPatchActivateDistributorKitFilter) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchPatchActivateDistributorKitFilter(payload);
    },
    {
      ...options,
      mutationKey: useFetchPatchActivateDistributorKitFilterKey,
      onSuccess: (data, variables, context) => {
        message.success("Filtro de distribuidor alterado com sucesso");
        queryClient.invalidateQueries(useFetchGetPaginatedDistributorsKey);
        queryClient.invalidateQueries(
          useFetchPatchActivateDistributorKitFilterKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError: (error, variables, context) => {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
