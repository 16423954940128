import { useMutation } from "react-query";

import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "../useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "../useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetRemainingPercentageKey } from "../useFetchGetRemainingPercentage";
import type { IUseFetchUpdateComplementaryItemsOfSettings } from "./interface";

import { updateAdvancedAdditionalCosts } from "~api/additional-costs/advanced/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateComplementaryItemsOfSettingsKey =
  "api/additional-costs/advanced/{id}/put";

export function useFetchUpdateComplementaryItemsOfSettings({
  options,
}: IUseFetchUpdateComplementaryItemsOfSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await updateAdvancedAdditionalCosts(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateComplementaryItemsOfSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Item alterado com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedComplementaryItemsOfSettingsKey,
        );
        queryClient.invalidateQueries(
          useFetchGetPaginatedAdditionalCostsAuxVariableKey,
        );
        queryClient.invalidateQueries(useFetchGetRemainingPercentageKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
