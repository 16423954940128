import { api, apiException } from "~services/api";
import {
  FetchUpdateManySimulationOptionPayloadSchema,
  IFetchUpdateManySimulationOptionPayloadSchema,
  ProjectPaymentSimulationOptionDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchUpdateManySimulationOption = async (
  payload: IFetchUpdateManySimulationOptionPayloadSchema,
) => {
  try {
    const body = FetchUpdateManySimulationOptionPayloadSchema.parse(payload);

    const response = await api.put(
      "project/payment/simulation/option/from-list",
      body,
    );

    const parsedData = z
      .array(ProjectPaymentSimulationOptionDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
