import { z } from "zod";

export const EnvSchema = z.object(
  {
    AMBIENT: z.enum(["DEVELOPMENT", "HOMOLOG", "PRODUCTION"], {
      required_error: "Missing AMBIENT VAR",
      invalid_type_error: "Invalid AMBIENT VAR",
    }),
    APP_VERSION: z.string({
      required_error: "Missing APP_VERSION ambient var",
    }),
    // PIPEDRIVE
    PIPEDRIVE_URL: z.string({
      required_error: "Missing PIPEDRIVE_URL ambient var",
    }),
    PIPEDRIVE_CLIENT_ID: z.string({
      required_error: "Missing PIPEDRIVE_CLIENT_ID ambient var",
    }),
    PIPEDRIVE_REDIRECT_URI: z.string({
      required_error: "Missing PIPEDRIVE_REDIRECT_URI ambient var",
    }),
    // SLACK ERRORS
    THROW_ERROR_IN_SLACK: z.string().transform((val) => val === "true"),
    // WEB SOCKET URL
    WEB_SOCKET_URL: z.string({
      required_error: "Missing WEB_SOCKET_URL ambient var",
    }),
    // API URL
    API_URL: z.string({
      required_error: "Missing API_URL ambient var",
    }),
    // PROGRESSIVE WEB APP
    ACTIVE_PWA: z.string().transform((val) => val === "true"),
    // GOOGLE MAPS
    GOOGLE_MAPS_API_KEY: z.string({
      required_error: "Missing GOOGLE_MAPS_API_KEY ambient var",
    }),
    // FIREBASE
    FIREBASE_API_KEY: z.string({
      required_error: "Missing FIREBASE_API_KEY ambient var",
    }),
    FIREBASE_AUTH_DOMAIN: z.string({
      required_error: "Missing FIREBASE_AUTH_DOMAIN ambient var",
    }),
    FIREBASE_PROJECT_ID: z.string({
      required_error: "Missing FIREBASE_PROJECT_ID ambient var",
    }),
    FIREBASE_STORAGE_BUCKET: z.string({
      required_error: "Missing FIREBASE_STORAGE_BUCKET ambient var",
    }),
    FIREBASE_MESSAGING_SENDER_ID: z.string({
      required_error: "Missing FIREBASE_MESSAGING_SENDER_ID ambient var",
    }),
    FIREBASE_APP_ID: z.string({
      required_error: "Missing FIREBASE_APP_ID ambient var",
    }),
    FIREBASE_DATABASE_URL: z.string({
      required_error: "Missing FIREBASE_DATABASE_URL ambient var",
    }),
    FIREBASE_MEASUREMENT_ID: z.string({
      required_error: "Missing FIREBASE_MEASUREMENT_ID ambient var",
    }),
    CYPRESS_USER_SESSION_TOKEN: z.string({
      required_error: "Missing CYPRESS_USER_SESSION_TOKEN ambient var",
    }),
    CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN: z.string({
      required_error:
        "Missing CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN ambient var",
    }),
    CYPRESS_ADMIN_USER_SESSION_TOKEN: z.string({
      required_error: "Missing CYPRESS_ADMIN_USER_SESSION_TOKEN ambient var",
    }),
    CYPRESS_USER_EMAIL: z.string({
      required_error: "Missing CYPRESS_USER_EMAIL ambient var",
    }),
    CYPRESS_USER_PASSWORD: z.string({
      required_error: "Missing CYPRESS_USER_PASSWORD ambient var",
    }),
  },
  {
    required_error: "Missing ambient vars",
  },
);

export interface IEnvSchema extends z.infer<typeof EnvSchema> {}
