import { z } from "zod";

export const FetchGetOrganizationDealsCountByOrganizationIdPayloadSchema =
  z.object({
    organizationId: z.number().int().gt(0),
  });

export interface IFetchGetOrganizationDealsCountByOrganizationIdPayloadSchema
  extends z.infer<
    typeof FetchGetOrganizationDealsCountByOrganizationIdPayloadSchema
  > {}
