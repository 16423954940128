import { useQuery } from "react-query";

import type { IUseFetchGetEosFinancialProposalDetails } from "./interface";

import { fetchGetEosFinancialProposalDetails } from "~api/eosfin/proposals/{protocol}/get";

export const useFetchGetEosFinancialProposalDetailsKey =
  "api/eosfin/proposals/{protocol}/get";

export function useFetchGetEosFinancialProposalDetails({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetEosFinancialProposalDetails) {
  return useQuery(
    [useFetchGetEosFinancialProposalDetailsKey, dependencyArray],
    async () => await fetchGetEosFinancialProposalDetails(payload),
    options,
  );
}
