import { useMutation } from "react-query";

import { useFetchGetAllConsultantsKey } from "../useFetchGetAllConsultants";
import { useFetchGetPaginatedConsultantKey } from "../useFetchGetPaginatedConsultants";
import type { IUseFetchDeleteConsultantUser } from "./interface";

import { fetchDeleteConsultantUser } from "~api/consultants/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteConsultantUserKey = "api/consultants/{id}/delete";

export function useFetchDeleteConsultantUser({
  options,
}: IUseFetchDeleteConsultantUser) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchDeleteConsultantUser(payload);
    },
    {
      ...options,
      mutationKey: useFetchDeleteConsultantUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário consultor foi removido");
        queryClient.invalidateQueries(useFetchGetAllConsultantsKey);
        queryClient.invalidateQueries(useFetchGetPaginatedConsultantKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
