import { z } from "zod";

export const FetchUpdateActivityDateSchema = z.object({
  id: z.number().int().gt(0),
  begin: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  end: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchUpdateActivityDateSchema
  extends z.infer<typeof FetchUpdateActivityDateSchema> {}
