import { api, apiException } from "~services/api";
import { ActivitiesDTOSchema } from "~types/schemas";

export const fetchGetActivityCurrent = async () => {
  try {
    const response = await api.get(`/activitiesType`);

    const parsedData = ActivitiesDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
