import { SimpleDealDTOSchema } from "./SimpleDealDTOSchema";

import { z } from "zod";

export const PipelineStageDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  probability: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  rottingInDays: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  position: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  deals: SimpleDealDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IPipelineStageDTOSchema
  extends z.infer<typeof PipelineStageDTOSchema> {}
