import { ElectricUtilityDTOSchema } from "./ElectricUtilityDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedElectricUtilityDTOSchema = PaginationSchema(
  ElectricUtilityDTOSchema,
);

export interface IPaginatedElectricUtilityDTOSchema
  extends z.infer<typeof PaginatedElectricUtilityDTOSchema> {}
