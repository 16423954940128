import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductImportHistoryPayloadSchema,
  IFetchGetPaginatedProductImportHistoryPayloadSchema,
  PaginatedImportProductQueueDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedProductImportHistory = async (
  payload: IFetchGetPaginatedProductImportHistoryPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductImportHistoryPayloadSchema.parse(payload);

    const response = await api.get(`/product-queue`, {
      params,
    });

    const parsedData = PaginatedImportProductQueueDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
