import { useMutation } from "react-query";

import { useFetchGetCompanyDistributorsKey } from "../../company/useFetchGetCompanyDistributors";
import { useFetchGetStructureDistributorsKey } from "../../structure-distributor/useFetchGetStructureDistributors";
import { useFetchGetConfigFortlevDistributorIntegrationKey } from "../useFetchGetConfigFortlevDistributorIntegration";
import { useFetchGetPaginatedDistributorsKey } from "../useFetchGetPaginatedDistributors";
import type { IUseFetchUpdateConfigFortlevDistributorIntegration } from "./interface";

import { fetchUpdateConfigFortlevDistributorIntegration } from "~api/distributors/fortlev/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchConfigFortlevDistributorIntegrationKey =
  "api/distributors/fortlev/put";

export function useFetchUpdateConfigFortlevDistributorIntegration(
  options?: IUseFetchUpdateConfigFortlevDistributorIntegration,
) {
  const { message } = useAppConfig();

  return useMutation(
    async () => await fetchUpdateConfigFortlevDistributorIntegration(),
    {
      ...options,
      mutationKey: useFetchConfigFortlevDistributorIntegrationKey,
      onSuccess: (data, variables, context) => {
        message.success(
          "Configuração da integração com a Fortlev foi atualizada",
        );
        queryClient.invalidateQueries(
          useFetchGetConfigFortlevDistributorIntegrationKey,
        );
        queryClient.invalidateQueries(useFetchGetStructureDistributorsKey);
        queryClient.invalidateQueries(useFetchGetPaginatedDistributorsKey);
        queryClient.invalidateQueries(useFetchGetCompanyDistributorsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao configurar: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
