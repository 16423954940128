import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const OrganizationUpdateCustomFieldFormSchema = z.object({
  id: z.number().int().gt(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val || undefined),
  label: z.string(),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z.boolean(),
  enabled: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  businessUnits: z
    .array(z.number())
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IOrganizationUpdateCustomFieldFormSchema
  extends z.infer<typeof OrganizationUpdateCustomFieldFormSchema> {}
