import { z } from "zod";

export const EcoriStructureIdentifierSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEcoriStructureIdentifierSchema
  extends z.infer<typeof EcoriStructureIdentifierSchema> {}
