import { z } from "zod";

export const FetchDeleteDealCustomFieldGroupPayloadSchema = z.object({
  id: z
    .number({
      message: "ID do grupo do campo customizado de negócio é obrigatório",
    })
    .int({
      message: "ID do grupo do campo customizado deve ser um inteiro",
    })
    .gt(0, {
      message:
        "ID do grupo do campo customizado de negócio deve ser maior que zero",
    }),
  destinationGroupId: z
    .number({
      message: "ID do grupo de destino é obrigatório",
    })
    .int({
      message: "ID do grupo de destino um inteiro",
    })
    .gt(0, {
      message: "ID do grupo de destino deve ser maior que zero",
    })
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchDeleteDealCustomFieldGroupPayloadSchema
  extends z.infer<typeof FetchDeleteDealCustomFieldGroupPayloadSchema> {}
