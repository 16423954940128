import { api, apiException } from "~services/api";
import { TariffPremiseDTOSchema } from "~types/schemas";

export const fetchGetPremisesTariff = async () => {
  try {
    const response = await api.get(`/premises/tariff`);

    const parsedData = TariffPremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
