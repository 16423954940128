import { useQuery } from "react-query";

import type { IUseGetProposalPayments } from "./interface";

import { fetchGetProposalPayments } from "~api/project/payment/simulation-item-project/custom-id/{custom-id}/get";

export const useFetchGetProposalPaymentsKey =
  "api/project/payment/simulation-item-project/custom-id/{custom-id}";

export function useFetchGetProposalPayments({
  dependencyArray = [],
  options,
  payload = {
    customId: 0,
  },
}: IUseGetProposalPayments) {
  return useQuery(
    [useFetchGetProposalPaymentsKey, dependencyArray],
    async () => await fetchGetProposalPayments(payload),
    options,
  );
}
