import { api, apiException } from "~services/api";
import {
  IFetchUpdateMonthlyRadiationByCustomIdPayloadSchema,
  FetchUpdateMonthlyRadiationByCustomIdPayloadSchema,
  MonthlySolarRadiationDTOSchema,
} from "~types/schemas";

export const fetchUpdateMonthlyRadiationByCustomId = async (
  payload: IFetchUpdateMonthlyRadiationByCustomIdPayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateMonthlyRadiationByCustomIdPayloadSchema.parse(payload);

    const response = await api.patch(
      `/projects/step_two/monthly-solar-radiation/${customId}`,
      body,
    );

    if (!response.data) return undefined;

    const parsedData = MonthlySolarRadiationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
