import { z } from "zod";

export const FileTypeEnum = z
  .enum([
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/csv",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "image/png",
    "image/jpeg",
  ] as const)
  .catch((e) => e.input);

export type FileTypeEnum = z.infer<typeof FileTypeEnum>;
