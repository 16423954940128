import { api, apiException } from "~services/api";
import {
  type IFetchUpdateAdminInvertersPayloadSchema,
  FetchUpdateAdminInvertersPayloadSchema,
  InverterDTOSchema,
} from "~types/schemas";

export const fetchUpdateAdminInverters = async (
  payload: IFetchUpdateAdminInvertersPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateAdminInvertersPayloadSchema.parse(payload);

    const response = await api.put(`/admin/inverters/${id}`, body);

    const parsedData = InverterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
