import { api, apiException } from "~services/api";
import {
  FetchPostToGetPaginatedProposalsPayloadSchema,
  IFetchPostToGetPaginatedProposalsPayloadSchema,
  PaginatedProposalResponseSchema,
} from "~types/schemas";

/* it's a POST to GET A LIST of proposals */
export const fetchPostToGetPaginatedProposals = async (
  payload: IFetchPostToGetPaginatedProposalsPayloadSchema,
) => {
  try {
    const body = FetchPostToGetPaginatedProposalsPayloadSchema.parse(payload);

    const response = await api.post(`/proposal/filter`, body);

    const parsedData = PaginatedProposalResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
