import { api, apiException } from "~services/api";
import {
  ActivityDTOSchema,
  FetchGetActivityByIdPayloadSchema,
  type IFetchGetActivityByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetActivityById = async (
  payload?: IFetchGetActivityByIdPayloadSchema,
) => {
  try {
    const { activityId } = FetchGetActivityByIdPayloadSchema.parse(payload);

    const response = await api.get(`/activities/${activityId}`);

    const parsedData = ActivityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
