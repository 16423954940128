import { z } from "zod";

export const FetchCreateEncryptedPersonalTokenPayloadSchema = z.object({
  userId: z
    .number({
      message: "ID do usuário deve ser um numero inteiro maior do que zero",
    })
    .int({
      message: "ID do usuário deve ser um numero inteiro maior do que zero",
    })
    .gt(0, {
      message: "ID do usuário deve ser um numero inteiro maior do que zero",
    }),
  companyId: z
    .number({
      message: "ID da companhia deve ser um numero inteiro maior do que zero",
    })
    .int({
      message: "ID da companhia deve ser um numero inteiro maior do que zero",
    })
    .gt(0, {
      message: "ID da companhia deve ser um numero inteiro maior do que zero",
    }),
});

export interface IFetchCreateEncryptedPersonalTokenPayloadSchema
  extends z.infer<typeof FetchCreateEncryptedPersonalTokenPayloadSchema> {}
