import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedOrganizationPayloadSchema,
  IFetchGetPaginatedOrganizationPayloadSchema,
  PaginatedOrganizationDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedOrganization = async (
  payload?: IFetchGetPaginatedOrganizationPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetPaginatedOrganizationPayloadSchema.parse(payload);

    const response = await api.get(`/organizations`, {
      params: queryParams,
    });

    const parsedData = PaginatedOrganizationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
