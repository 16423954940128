import { api, apiException } from "~services/api";
import {
  FetchDeletePricingTypesPayloadSchema,
  IFetchDeletePricingTypesPayloadSchema,
} from "~types/schemas";

export const fetchDeletePricingTypes = async (
  payload: IFetchDeletePricingTypesPayloadSchema,
) => {
  try {
    const { pricingId } = FetchDeletePricingTypesPayloadSchema.parse(payload);

    await api.delete(`/pricing-types/${pricingId}`);
  } catch (error) {
    throw apiException(error);
  }
};
