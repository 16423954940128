import { z } from "zod";

export const LeadFormSchema = z
  .object({
    id: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    ownerId: z.number().int().gt(0),
    funnelId: z.number().int().gt(0),
    funnelPhase: z.number(),
    value: z.number(),
    companyId: z.number().int().gt(0),
    contactPeopleId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    telephone: z.string().min(10),
    email: z.string().email(),
  })
  .partial();

export interface ILeadFormSchema extends z.infer<typeof LeadFormSchema> {}
