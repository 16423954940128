import { z } from "zod";

export const AdditionalCostMarginTypeDTOSchema = z.object({
  id: z.number().int().gt(0),
  afterCalculations: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  includeTaxes: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  insideCalculation: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IAdditionalCostMarginTypeDTOSchema
  extends z.infer<typeof AdditionalCostMarginTypeDTOSchema> {}
