import { useQuery } from "react-query";

import type { IUseFetchGetActivityMessageFromPipedrive } from "./interface";

import { fetchGetActivityMessageFromPipedrive } from "~api/company/custom-pipedrive-activity-message/get";

export const useFetchGetActivityMessageFromPipedriveKey =
  "api/company/custom-pipedrive-activity-message/get";

export function useFetchGetActivityMessageFromPipedrive({
  dependencyArray = [],
  options,
}: IUseFetchGetActivityMessageFromPipedrive) {
  return useQuery(
    [useFetchGetActivityMessageFromPipedriveKey, dependencyArray],
    async () => await fetchGetActivityMessageFromPipedrive(),
    options,
  );
}
