import { api, apiException } from "~services/api";
import { WegParameterDTOSchema } from "~types/schemas";

export const fetchGetConfigWegDistributorIntegration = async () => {
  try {
    const response = await api.get(`/distributors/weg`);

    const parsedData = WegParameterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
