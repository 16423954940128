import { z } from "zod";

export const EdeltecParameterFormSchema = z
  .object({
    accessKey: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    password: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
  })
  .partial();

export interface IEdeltecParameterFormSchema
  extends z.infer<typeof EdeltecParameterFormSchema> {}
