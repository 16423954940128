import { z } from "zod";

export const FetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema =
  z.object({
    page: z.number().int().gte(0).default(0),
    size: z.number().int().gte(0).default(5),
  });

export interface IFetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema
  > {}
