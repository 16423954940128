import { z } from "zod";

export const FieldBindFormSchema = z.object({
  fieldKey: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined)
    .optional(),
  bindKey: z.string(),
});

export interface IFieldBindFormSchema
  extends z.infer<typeof FieldBindFormSchema> {}
