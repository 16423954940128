import { useQuery } from "react-query";

import type { IUseFetchGetTransformerRulesById } from "./interface";

import { fetchGetTransformerRulesById } from "~api/config/transformer/{id}/get";

export const useFetchGetTransformerRulesByIdKey =
  "api/config/transformer/{id}/get";

export function useFetchGetTransformerRulesById({
  dependencyArray = [],
  options,
  payload = {
    ruleId: 0,
  },
}: IUseFetchGetTransformerRulesById) {
  return useQuery(
    [useFetchGetTransformerRulesByIdKey, dependencyArray],
    async () => fetchGetTransformerRulesById(payload),
    options,
  );
}
