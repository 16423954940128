import { useQuery } from "react-query";

import type { IUseFetchGetNotificationStatus } from "./interface";

import { fetchGetNotificationStatus } from "~api/notifications/status/get";

export const fetchGetNotificationStatusKey = "api/notifications/status/get";

export function useFetchGetNotificationStatus({
  options,
  dependencyArray,
}: IUseFetchGetNotificationStatus) {
  return useQuery(
    [fetchGetNotificationStatusKey, dependencyArray],
    async () => await fetchGetNotificationStatus(),
    options,
  );
}
