import { CityDTOSchema } from "./CityDTOSchema";
import { CountryDTOSchema } from "./CountryDTOSchema";
import { ProvinceDTOSchema } from "./ProvinceDTOSchema";

import { z } from "zod";

export const AddressDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  street: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  zipCode: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  district: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  number: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  latitude: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  longitude: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  city: CityDTOSchema.nullish().transform((val) => val ?? undefined),
  province: ProvinceDTOSchema.nullish().transform((val) => val ?? undefined),
  country: CountryDTOSchema.nullish().transform((val) => val ?? undefined),
  formattedAddress: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IAddressDTOSchema extends z.infer<typeof AddressDTOSchema> {}
