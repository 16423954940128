import { useQuery } from "react-query";

import type { IUseFetchGetStepOne } from "./interface";

import { fetchGetProposalStepOne } from "~api/projects/step_one/{id}/get";

export const useFetchGetStepOnesKey = "api/projects/step_one/{id}/get";

export function useFetchGetStepOne({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetStepOne) {
  return useQuery(
    [useFetchGetStepOnesKey, dependencyArray],
    async () => await fetchGetProposalStepOne(payload),
    options,
  );
}
