import { z } from "zod";

export const FetchGetDealCustomFieldGroupByIdPayloadSchema = z.object({
  id: z
    .number({
      message: "ID do grupo do campo customizado de negócio é obrigatório",
    })
    .int({
      message: "ID do grupo do campo customizado deve ser um inteiro",
    })
    .gt(0, {
      message:
        "ID do grupo do campo customizado de negócio deve ser maior que zero",
    }),
});

export interface IFetchGetDealCustomFieldGroupByIdPayloadSchema
  extends z.infer<typeof FetchGetDealCustomFieldGroupByIdPayloadSchema> {}
