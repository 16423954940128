import { ProjectTypeEnum } from "~types/enum";
import { z } from "zod";

export const FieldSettingsDTOSchema = z.object({
  id: z.number().int().gt(0).nullish(),
  nameField: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  fieldCategory: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  displayName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  profileId: z.number().int().gt(0).nullish(),
  profileName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  projectTypes: z
    .array(ProjectTypeEnum)
    .nullish()
    .transform((val) => val ?? []),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IFieldSettingsDTOSchema
  extends z.infer<typeof FieldSettingsDTOSchema> {}
