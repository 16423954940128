import { z } from "zod";

export const ProposalExpirationRuleFormSchema = z.object({
  periods: z.record(z.number().int()),
  defaultPeriod: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProposalExpirationRuleFormSchema
  extends z.infer<typeof ProposalExpirationRuleFormSchema> {}
