import { useMutation } from "react-query";

import { useFetchGetProposalFieldsKey } from "../useFetchGetProposalFields";
import type { IUseFetchUpdateProposalFields } from "./interface";

import { fetchUpdateProposalFields } from "~api/fields/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalFieldsKey = "api/fields/{id}/put";

export function useFetchUpdateProposalFields(
  options?: IUseFetchUpdateProposalFields,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProposalFields(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi atualizado!");
        queryClient.invalidateQueries(useFetchGetProposalFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao atualizar campo: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
