import { z } from "zod";

export const KomecoStructureTypeEnum = z
  .enum([
    "FIBRO_METALICA",
    "FIBRO_MADEIRA",
    "LAJE",
    "METALICA",
    "COLONIAL",
  ] as const)
  .catch((e) => e.input);

export type KomecoStructureTypeEnum = z.infer<typeof KomecoStructureTypeEnum>;
