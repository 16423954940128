import { useQuery } from "react-query";

import type { IUseFetchGetAldoInverterBrands } from "./interface";

import { fetchGetAldoInverterBrands } from "~api/equipments/aldo/inverters/get";

export const useFetchGetAldoInverterBrandsKey =
  "api/equipments/aldo/inverters/get";

export function useFetchGetAldoInverterBrands({
  dependencyArray = [],
  options,
}: IUseFetchGetAldoInverterBrands) {
  return useQuery(
    [useFetchGetAldoInverterBrandsKey, dependencyArray],
    async () => await fetchGetAldoInverterBrands(),
    options,
  );
}
