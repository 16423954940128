import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import type { IUseFetchAdminUploadSheet } from "./interface";

import { fetchUploadSheet } from "~api/admin/users/importProducts/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchAdminUploadSheetKey =
  "api/admin/users/importProducts/post";

export function useFetchAdminUploadSheet({
  options,
}: IUseFetchAdminUploadSheet) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUploadSheet(payload);
    },
    {
      ...options,
      mutationKey: useFetchAdminUploadSheetKey,
      onSuccess: (data, variables, context) => {
        message.success("Arquivo enviado");
        queryClient.invalidateQueries(useFetchGetAdminUsersKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao enviar arquivo: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
