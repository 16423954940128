import { z } from "zod";

export const FetchGetPaginatedAdminInvertersPayloadSchema = z.object({
  models: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  manufacturers: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  nominalPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  page: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedAdminInvertersPayloadSchema
  extends z.infer<typeof FetchGetPaginatedAdminInvertersPayloadSchema> {}
