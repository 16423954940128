import { useMutation } from "react-query";

import { UseFetchGetPaginatedUserGroupKey } from "../useFetchGetPaginatedUserGroup";
import type { IUseFetchUpdateUserGroup } from "./interface";

import { fetchUpdateUserGroup } from "~api/usergroup/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateUserGroupKey = "api/usergroup/{id}/put";

export function useFetchUpdateUserGroup({ options }: IUseFetchUpdateUserGroup) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateUserGroup(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateUserGroupKey,
      onSuccess: (data, variables, context) => {
        message.success("Grupo de usuários atualizado com sucesso!");
        queryClient.invalidateQueries(UseFetchGetPaginatedUserGroupKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
