import { useMutation } from "react-query";

import { useFetchGetAdminInvertersByIdKey } from "../useFetchGetAdminInvertersById";
import { useFetchGetPaginatedAdminInvertersKey } from "../useFetchGetPaginatedAdminInverters";
import type { IUseFetchUpdateAdminInverters } from "./interface";

import { fetchUpdateAdminInverters } from "~api/admin/inverters/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAdminInvertersKey = "api/admin/inverters/{id}/put";

export function useFetchUpdateAdminInverters({
  options,
}: IUseFetchUpdateAdminInverters) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateAdminInverters(payload),
    {
      ...options,
      mutationKey: useFetchUpdateAdminInvertersKey,
      onSuccess: (data, variables, context) => {
        message.success("Inversor foi atualizado");

        queryClient.invalidateQueries(useFetchGetPaginatedAdminInvertersKey);
        queryClient.invalidateQueries(useFetchGetAdminInvertersByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar inversor: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
