import { z } from "zod";

export const SimultaneityProfileFormSchema = z
  .object({
    description: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    value: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface ISimultaneityProfileFormSchema
  extends z.infer<typeof SimultaneityProfileFormSchema> {}
