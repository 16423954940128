import { useQuery } from "react-query";

import type { IFetchGetPaginatedProductInverterManufacturers } from "./interface";

import { fetchGetPaginatedProductInverterManufacturers } from "~api/product/search/inverter/manufacturerList/get";

export const useFetchGetPaginatedProductInverterManufacturersKey =
  "api/product/search/inverter/manufacturerList/get";

export function useFetchGetPaginatedProductInverterManufacturers({
  dependencyArray = [],
  options,
  payload,
}: IFetchGetPaginatedProductInverterManufacturers) {
  return useQuery(
    [useFetchGetPaginatedProductInverterManufacturersKey, dependencyArray],
    async () => await fetchGetPaginatedProductInverterManufacturers(payload),
    options,
  );
}
