import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProposalByDealIdPayloadSchema,
  type IFetchGetPaginatedProposalByDealIdPayloadSchema,
  PaginatedProposalByDealIdResponseSchema,
} from "~types/schemas";

export const fetchGetPaginatedProposalByDealId = async (
  payload: IFetchGetPaginatedProposalByDealIdPayloadSchema,
) => {
  try {
    const { dealId, ...queryParams } =
      FetchGetPaginatedProposalByDealIdPayloadSchema.parse(payload);

    const response = await api.get(`/proposal/deal/${dealId}`, {
      params: queryParams,
    });

    const parsedData = PaginatedProposalByDealIdResponseSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
