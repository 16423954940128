import { useQuery } from "react-query";

import type { IUseFetchGetProposalExpirationRule } from "./interface";

import { fetchGetProposalExpirationRule } from "~api/proposal-expiration-rule/get";

export const useFetchGetProposalExpirationRuleKey =
  "api/proposal-expiration-rule/get";

export function useFetchGetProposalExpirationRule({
  dependencyArray = [],
  options,
}: IUseFetchGetProposalExpirationRule) {
  return useQuery(
    [useFetchGetProposalExpirationRuleKey, dependencyArray],
    async () => await fetchGetProposalExpirationRule(),
    options,
  );
}
