import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldsById } from "./interface";

import { fetchGetDealCustomFieldsById } from "~api/config/deals/custom-fields/{id}/get";

export const useFetchGetDealCustomFieldsByIdKey =
  "api/config/deals/custom-fields/{id}/get";

export function useFetchGetDealCustomFieldsById({
  dependencyArray = [],
  payload,
  options,
  customKey = useFetchGetDealCustomFieldsByIdKey,
}: IUseFetchGetDealCustomFieldsById) {
  return useQuery(
    [customKey, dependencyArray],
    async () => await fetchGetDealCustomFieldsById(payload),
    options,
  );
}
