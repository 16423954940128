import { CompanySchema } from "./CompanySchema";
import { GrantedAuthoritySchema } from "./GrantedAuthoritySchema";
import { ProfileSchema } from "./ProfileSchema";
import { RoleSchema } from "./RoleSchema";

import { z } from "zod";

const UserAccountSchema = z
  .object({
    id: z.number().int(),
    idPipedrive: z.number().int(),
    email: z.string(),
    password: z.string(),
    token: z.string(),
    tokenExpiresAt: z.string(),
    refreshToken: z.string(),
    name: z.string(),
    defaultProposalMessage: z.string(),
    phone: z.string(),
    profile: ProfileSchema,
    roles: z.array(RoleSchema),
    company: CompanySchema,
    accountOwner: z.boolean(),
    createdAt: z.string(),
    enabled: z.boolean(),
    username: z.string(),
    initialAccess: z.boolean(),
    authorities: z.array(GrantedAuthoritySchema),
    credentialsNonExpired: z.boolean(),
    accountNonExpired: z.boolean(),
    accountNonLocked: z.boolean(),
  })
  .partial();

export interface IUserAccountSchema extends z.infer<typeof UserAccountSchema> {}
