import { useQuery } from "react-query";

import type { IUseFetchGetConcessionairesWithAssociatedProfileByProvince } from "./interface";

import { fetchGetConcessionairesWithAssociatedProfileByProvince } from "~api/electric-utility/list-by-province/get";

export const useFetchGetConcessionairesWithAssociatedProfileByProvinceKey =
  "api/electric-utility/list-by-province/get";

export function useFetchGetConcessionairesWithAssociatedProfileByProvince({
  dependencyArray = [],
  options,
  payload = {
    provinceId: 0,
  },
}: IUseFetchGetConcessionairesWithAssociatedProfileByProvince) {
  return useQuery(
    [
      useFetchGetConcessionairesWithAssociatedProfileByProvinceKey,
      dependencyArray,
    ],
    async () =>
      await fetchGetConcessionairesWithAssociatedProfileByProvince(payload),
    options,
  );
}
