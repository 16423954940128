import { z } from "zod";

export const historyFilterEnum = [
  "ALL",
  "NOTE",
  "ACTIVITY",
  "CHANGE_LOG",
] as const;

export const HistoryFilterEnum = z
  .enum(historyFilterEnum)
  .catch((e) => e.input);

export type HistoryFilterEnum = z.infer<typeof HistoryFilterEnum>;
