import { PipelineStageDTOSchema } from "./PipelineStageDTOSchema";

import { z } from "zod";

export const PipelineDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  stages: PipelineStageDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IPipelineDTOSchema extends z.infer<typeof PipelineDTOSchema> {}
