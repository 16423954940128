import { z } from "zod";

export const CountrySchema = z.object({
  id: z.number().int().optional(),
  name: z.string().min(4).max(60),
  authority: z.string().min(4).max(60).optional(),
  abbreviation: z.string().optional().default(""),
});

export interface ICountrySchema extends z.infer<typeof CountrySchema> {}
