import { z } from "zod";

export const FetchUploadComplementaryCostSpreadsheetPayloadSchema = z.object({
  data: z.string(),
});

export interface IFetchUploadComplementaryCostSpreadsheetPayloadSchema
  extends z.infer<
    typeof FetchUploadComplementaryCostSpreadsheetPayloadSchema
  > {}
