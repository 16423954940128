import { BusinessUnitDTOSchema } from "./BusinessUnitDTOSchema";
import { CostAggregatorDTOSchema } from "./CostAggregatorDTOSchema";

import { AlternatingCurrentMaterialCalcBaseEnum } from "~types/enum";
import { z } from "zod";

export const AlternatingCurrentMaterialDTOSchema = z.object({
  id: z.number().int().gt(0),
  power: z.number().gte(0),
  value: z.number().gte(0),
  active: z.boolean(),
  businessUnit: BusinessUnitDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  alternatingCurrentMaterialCalcBase:
    AlternatingCurrentMaterialCalcBaseEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  additionalCostTaxId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  notApplyDefaultAdditionalCostTax: z
    .boolean()
    .nullish()
    .transform((val) => (val === null ? false : val)),
  costAggregators: z
    .array(CostAggregatorDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IAlternatingCurrentMaterialDTOSchema
  extends z.infer<typeof AlternatingCurrentMaterialDTOSchema> {}
