import { api, apiException } from "~services/api";
import {
  FetchGetCustomFieldsSectionsByProposalStepPayloadSchema,
  IFetchGetCustomFieldsSectionsByProposalStepPayloadSchema,
  ProposalSectionDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetCustomFieldsSectionsByProposalStep = async (
  payload: IFetchGetCustomFieldsSectionsByProposalStepPayloadSchema,
) => {
  try {
    const query =
      FetchGetCustomFieldsSectionsByProposalStepPayloadSchema.parse(payload);

    const response = await api.get(`/config/customFields/sections`, {
      params: query,
    });

    const parsedData = z
      .array(ProposalSectionDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
