import { z } from "zod";

export const FortlevParameterDTOSchema = z.object({
  id: z.number().int().gt(0),
  accessToken: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  integratorEmail: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IFortlevParameterDTOSchema
  extends z.infer<typeof FortlevParameterDTOSchema> {}
