import { IntegratorEnum } from "~types/enum";
import { z } from "zod";

export const DistributorDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: IntegratorEnum,
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  enabled: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  activeKitFilter: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  version: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  lastUpdate: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDistributorDTOSchema
  extends z.infer<typeof DistributorDTOSchema> {}
