import { api, apiException } from "~services/api";
import {
  ActivityFormSchema,
  type IActivityFormSchema,
  ActivityDTOSchema,
} from "~types/schemas";

export const fetchCreateActivity = async (payload: IActivityFormSchema) => {
  try {
    const body = ActivityFormSchema.parse(payload);

    const response = await api.post(`/activities`, body);

    const parsedData = ActivityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
