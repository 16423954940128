import { z } from "zod";

export const VariableDTOSchema = z.object({
  templateVariable: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  placeholder: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  category: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  variableForPrintOnly: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IVariableDTOSchema extends z.infer<typeof VariableDTOSchema> {}
