import { VoltageEnum } from "~types/enum";
import { z } from "zod";

export const TransformerAdditionalCostListItemDTOSchema = z.object({
  id: z.number().int().gt(0),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  inputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  defaultPrice: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ITransformerAdditionalCostListItemDTOSchema
  extends z.infer<typeof TransformerAdditionalCostListItemDTOSchema> {}
