import { useMutation } from "react-query";

import { useFetchGetStructureDistributorsKey } from "../useFetchGetStructureDistributors";
import type { IUseFetchDeleteIntegrationLinks } from "./interface";

import { fetchDeleteIntegrationLinks } from "~api/structure-distributor/distributor/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteIntegrationLinksKey =
  "api/structure-distributor/distributor";

export function useFetchDeleteIntegrationLinks(
  options?: IUseFetchDeleteIntegrationLinks,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteIntegrationLinks(payload),
    {
      ...options,
      mutationKey: useFetchDeleteIntegrationLinksKey,
      onSuccess: (data, variables, context) => {
        message.success("Vínculos removidos!");
        queryClient.invalidateQueries(useFetchGetStructureDistributorsKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao remover logística: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
