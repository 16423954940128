import { api, apiException } from "~services/api";
import {
  FetchDeleteSimulationOptionPayloadSchema,
  IFetchDeleteSimulationOptionPayloadSchema,
} from "~types/schemas";

export const fetchDeleteSimulationOption = async (
  payload: IFetchDeleteSimulationOptionPayloadSchema,
) => {
  try {
    const { projectPaymentSimulationOptionId } =
      FetchDeleteSimulationOptionPayloadSchema.parse(payload);

    await api.delete<void>(
      `/project/payment/simulation/option/${projectPaymentSimulationOptionId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
