import { EmailSchema } from "./EmailSchema";
import { PhoneSchema } from "./PhoneSchema";

import { z } from "zod";

export const PersonIdSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .array(EmailSchema)
    .nullish()
    .transform((val) => val ?? []),
  phone: z
    .array(PhoneSchema)
    .nullish()
    .transform((val) => val ?? []),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPersonIdSchema extends z.infer<typeof PersonIdSchema> {}
