import { api, apiException } from "~services/api";
import {
  FetchSetOrganizationOwnerPayloadSchema,
  type IFetchSetOrganizationOwnerPayloadSchema,
} from "~types/schemas";

export const fetchSetOrganizationOwner = async (
  payload: IFetchSetOrganizationOwnerPayloadSchema,
) => {
  try {
    const { organizationId, ownerId } =
      FetchSetOrganizationOwnerPayloadSchema.parse(payload);

    await api.patch(`/organizations/${organizationId}/owner/${ownerId}`);
  } catch (error) {
    throw apiException(error);
  }
};
