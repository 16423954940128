import { z } from "zod";

export const TrackingProjectDTOSchema = z
  .object({
    id: z.number().int().gt(0),
    viewedAt: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    lastHeartbeatAt: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface ITrackingProjectDTOSchema
  extends z.infer<typeof TrackingProjectDTOSchema> {}
