import { api, apiException } from "~services/api";
import {
  FetchBindsPipedriveIdToOrganizationPayloadSchema,
  OrganizationDTOSchema,
  type IFetchBindsPipedriveIdToOrganizationPayloadSchema,
} from "~types/schemas";

export const fetchBindsPipedriveIdToOrganization = async (
  payload: IFetchBindsPipedriveIdToOrganizationPayloadSchema,
) => {
  try {
    const { pipedriveId, organizationId } =
      FetchBindsPipedriveIdToOrganizationPayloadSchema.parse(payload);

    const response = await api.patch(
      `/organizations/${organizationId}/link-pipedrive-id/${pipedriveId}`,
    );

    const parsedData = OrganizationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
