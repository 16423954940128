import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalExpirationRulePayloadSchema,
  IFetchUpdateProposalExpirationRulePayloadSchema,
  ProposalExpirationRuleDTOSchema,
} from "~types/schemas";

export const fetchUpdateProposalExpirationRule = async (
  payload: IFetchUpdateProposalExpirationRulePayloadSchema,
) => {
  try {
    const { expirationRuleId, ...body } =
      FetchUpdateProposalExpirationRulePayloadSchema.parse(payload);

    const response = await api.put(
      `/proposal-expiration-rule/${expirationRuleId}`,
      body,
    );
    const parsedData = ProposalExpirationRuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
