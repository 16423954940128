import { IApiErrorResponse } from "./interface";

import { ENV } from "~configs/Env";
import createAxios, { isAxiosError } from "axios";

export const api = createAxios.create({
  baseURL: ENV.API_URL + "/api",
});

export const apiException = (error: any) => {
  if (!isAxiosError(error)) {
    const errorMessage = (error?.message as string) || "Indefinido";

    const data = {
      message: "Client error: " + errorMessage,
      type: "node exception",
      code: undefined,
      timestamp: new Date(),
      token: api.defaults.headers.common["Authorization"],
      path: typeof window !== "undefined" ? window.location.href : undefined,
    };

    if (ENV.THROW_ERROR_IN_SLACK) {
      fetch("/api/slack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }

    return data;
  }

  const parsedCode = Number(error.code);

  const axiosError = {
    ...error,
    code: isNaN(parsedCode) ? undefined : parsedCode,
  } as IApiErrorResponse;

  const data = {
    message: axiosError.response?.data?.message || "Ocorreu um erro inesperado",
    type: "api request exception",
    code: axiosError.response?.status,
    timestamp: new Date(),
    token: api.defaults.headers.common["Authorization"],
    path: typeof window !== "undefined" ? window.location.href : undefined,
    endpoint: `${api.defaults.baseURL}${axiosError.config?.url}`,
  };

  if (ENV.THROW_ERROR_IN_SLACK && axiosError.response?.data?.message) {
    fetch("/api/slack", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  return data;
};
