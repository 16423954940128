import { InverterDetailDTOSchema } from "./InverterDetailDTOSchema";

import {
  GridTypeEnum,
  InverterTypeEnum,
  PhaseEnum,
  VoltageEnum,
} from "~types/enum";
import { z } from "zod";

export const InverterCustomDTOSchema = z.object({
  id: z.number().int().gt(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  manufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  stringPerMppt: z
    .record(z.number())
    .nullish()
    .transform((val) => val ?? {}),
  model: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  distributor: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  maxPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  nominalPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  mpptNum: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  inputPerMpptNum: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  overload: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  gridType: GridTypeEnum.nullish().transform((val) => val ?? undefined),
  startupVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxInputVoltagePerMppt: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  precoBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  type: InverterTypeEnum.nullish().transform((val) => val ?? undefined),
  extraItemsCount: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  warrantyInYears: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxNumberSupportedModules: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  mppVoltageRange: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  outputCurrent: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxEfficiency: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  operatingTemperatureRange: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  protectionClass: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  humidity: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  dimensions: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  weight: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  details: InverterDetailDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  maxCurrentPerMppt: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IInverterCustomDTOSchema
  extends z.infer<typeof InverterCustomDTOSchema> {}
