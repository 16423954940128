import { z } from "zod";

export const FortlevStructureIdentifierSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IFortlevStructureIdentifierSchema
  extends z.infer<typeof FortlevStructureIdentifierSchema> {}
