import { StructureSubCategoryEnum } from "~types/enum";
import { z } from "zod";

export const UsefulAreaDataSchema = z
  .object({
    subCategory: StructureSubCategoryEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    spacingFactor: z.number(),
  })
  .partial();

export interface IUsefulAreaDataSchema
  extends z.infer<typeof UsefulAreaDataSchema> {}
