import { useQuery } from "react-query";

import type { IUseFetchGetActivityTypes } from "./interface";

import { fetchGetActivityTypes } from "~api/activities/types/get";

export const useFetchGetActivityTypesKey = "api/activities/types/get";

export function useFetchGetActivityTypes({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetActivityTypes) {
  return useQuery(
    [useFetchGetActivityTypesKey, dependencyArray],
    async () => await fetchGetActivityTypes(payload),
    options,
  );
}
