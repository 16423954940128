import { EosDocFormatedReturnSchema } from "./EosDocFormatedReturnSchema";

import { z } from "zod";

export const EosUploadResponseSchema = z.object({
  message: z.array(z.string()).nullish().default([]),
  bodyDocFormatedReturn: EosDocFormatedReturnSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  protocolo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosUploadResponseSchema
  extends z.infer<typeof EosUploadResponseSchema> {}
