import { z } from "zod";

export const RangeDTOSchema = z.object({
  id: z.number().int().gt(0),
  maxValue: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  costPerValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minimumCost: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  pricePerKm: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minimumDistance: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IRangeDTOSchema extends z.infer<typeof RangeDTOSchema> {}
