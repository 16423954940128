import { useMutation } from "react-query";

import { useFetchGetPersonCustomFieldsKey } from "../useFetchGetPersonCustomFields";
import type { IUseFetchUpdatePersonCustomFieldsOrder } from "./interface";

import { fetchUpdatePersonCustomFieldsOrder } from "~api/config/personCustomFields/fieldOrder/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePersonCustomFieldsOrderFieldsKey =
  "api/config/personCustomFields/fieldOrder/put";

export function useFetchUpdatePersonCustomFieldsOrder({
  options,
}: IUseFetchUpdatePersonCustomFieldsOrder) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdatePersonCustomFieldsOrder(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdatePersonCustomFieldsOrderFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Ordem dos campos foi alterada");
        queryClient.invalidateQueries(useFetchGetPersonCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        queryClient.invalidateQueries(useFetchGetPersonCustomFieldsKey);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
