import { api, apiException } from "~services/api";
import { TrackingProjectDTO } from "~types/api/TrackingProjectDTO";
import {
  TrackingProjectDTOSchema,
  FetchSaveTrackingHeartbeatPayloadSchema,
  IFetchSaveTrackingHeartbeatPayloadSchema,
} from "~types/schemas";

export const fetchSaveTrackingHeartbeat = async (
  path: IFetchSaveTrackingHeartbeatPayloadSchema,
) => {
  try {
    const { uuid } = FetchSaveTrackingHeartbeatPayloadSchema.parse(path);

    const response = await api.patch<TrackingProjectDTO>(
      `/tracking/heartbeat/${uuid}`,
    );

    const parsedData = TrackingProjectDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
