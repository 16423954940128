import { useQuery } from "react-query";

import type { IUseFetchGetActivitiesDoneByUser } from "./interface";

import { fetchGetActivitiesDoneByUser } from "~api/insights/activitiesDoneByUser/get";

export const useFetchGetActivitiesDoneByUserKey =
  "api/insights/activitiesDoneByUser/get";

export function useFetchGetActivitiesDoneByUser({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetActivitiesDoneByUser) {
  return useQuery(
    [useFetchGetActivitiesDoneByUserKey, dependencyArray],
    async () => await fetchGetActivitiesDoneByUser(payload),
    options,
  );
}
