import { z } from "zod";

export const FetchGetPaginatedInvertersPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IFetchGetPaginatedInvertersPayloadSchema
  extends z.infer<typeof FetchGetPaginatedInvertersPayloadSchema> {}
