import { AddressDTOSchema } from "./AddressDTOSchema";
import { ConsumptionUnitDTOSchema } from "./ConsumptionUnitDTOSchema";
import { CustomFieldValueSchema } from "./CustomFieldValueSchema";
import { ElectricUtilityProfileResponseSchema } from "./ElectricUtilityProfileResponseSchema";
import { LaborDTOSchema } from "./LaborDTOSchema";
import { LowVoltageDTOSchema } from "./LowVoltageDTOSchema";
import { MediumVoltageDTOSchema } from "./MediumVoltageDTOSchema";
import { MonthlySolarRadiationDTOSchema } from "./MonthlySolarRadiationDTOSchema";
import { PersonDTOSchema } from "./PersonDTOSchema";
import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";

import {
  ConsumerUnitCompensationEnum,
  CostGroupEnum,
  IrradiationTypeEnum,
  PhaseEnum,
  VoltageEnum,
  VoltageTypeEnum,
} from "~types/enum";
import { z } from "zod";

export const StepTwoDTOSchema = z.object({
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  performanceRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  generationFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  structureType: StructureTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  lowVoltage: LowVoltageDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  mediumVoltage: MediumVoltageDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  currentBUName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  client: PersonDTOSchema.nullish().transform((val) => val ?? undefined),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  electricUtilityId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  irradiationType: IrradiationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  simultaneityFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  labor: LaborDTOSchema.nullish().transform((val) => val ?? undefined),
  plantDataCustomFieldValues: z
    .array(CustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
  intermediateDataCustomFieldValues: z
    .array(CustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
  electricUtilityProfile:
    ElectricUtilityProfileResponseSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
  consumptionUnits: z
    .array(ConsumptionUnitDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  structureGroup: CostGroupEnum.nullish().transform((val) =>
    val !== "CUSTOS_COMPLEMENTARES" && val !== "KIT" ? undefined : val,
  ),
  averageExcessEnergyTariff: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  expectedSurplusEnergy: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageExcessEnergyTariffWireB: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minCelsiusTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlySolarRadiation: MonthlySolarRadiationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  compensationType: ConsumerUnitCompensationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  accumulatedCreditsUsedInPayback: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  considerConsumptionUnits: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  allocationPercentage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  genKwhPerKwpMonth: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  plantUnitCode: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  regionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  voltageType: VoltageTypeEnum.nullish().transform((val) => val ?? undefined),
  installationAddress: AddressDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IStepTwoDTOSchema extends z.infer<typeof StepTwoDTOSchema> {}
