import { api, apiException } from "~services/api";
import {
  type IFetchGetDatasheetLastRevisionPayloadSchema,
  FetchGetDatasheetLastRevisionPayloadSchema,
  DatasheetRevisionDTOSchema,
} from "~types/schemas";

export const fetchGetDatasheetLastRevision = async (
  payload: IFetchGetDatasheetLastRevisionPayloadSchema,
) => {
  try {
    const { id } = FetchGetDatasheetLastRevisionPayloadSchema.parse(payload);

    const response = await api.get(`/admin/datasheets/${id}/last-revision`);

    const parsedData = DatasheetRevisionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
