import { api, apiException } from "~services/api";
import { AldoStructureDTOSchema } from "~types/schemas";

export const fetchGetAldoStructures = async () => {
  try {
    const response = await api.get(`/equipments/aldo/structures`);

    const parsedData = AldoStructureDTOSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
