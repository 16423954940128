import { useQuery } from "react-query";

import type { IUseFetchGetAllPerformanceProfiles } from "./interface";

import { fetchGetAllPerformanceProfiles } from "~api/performance-profiles/get";

export const useFetchGetAllPerformanceProfilesKey =
  "api/performance-profiles/get";

export function useFetchGetAllPerformanceProfiles({
  dependencyArray = [],
  options,
}: IUseFetchGetAllPerformanceProfiles) {
  return useQuery(
    [useFetchGetAllPerformanceProfilesKey, dependencyArray],
    async () => await fetchGetAllPerformanceProfiles(),
    options,
  );
}
