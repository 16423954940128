import { api, apiException } from "~services/api";
import { FortlevParameterDTOSchema } from "~types/schemas";

export const fetchGetConfigFortlevDistributorIntegration = async () => {
  try {
    const response = await api.get(`/distributors/fortlev`);

    const parsedData = FortlevParameterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
