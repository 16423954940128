import { useQuery } from "react-query";

import type { IUseFetchGetEosFinancialProposalDocuments } from "./interface";

import { fetchGetEosFinancialProposalDocuments } from "~api/eosfin/proposals/{protocol}/documents/get";

export const useFetchGetEosFinancialProposalDocumentsKey =
  "api/eosfin/proposals/{protocol}/documents/get";

export function useFetchGetEosFinancialProposalDocuments({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetEosFinancialProposalDocuments) {
  return useQuery(
    [useFetchGetEosFinancialProposalDocumentsKey, dependencyArray],
    async () => await fetchGetEosFinancialProposalDocuments(payload),
    options,
  );
}
