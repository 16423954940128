import { ProfileFormSchema } from "./ProfileFormSchema";

import { z } from "zod";

export const FetchUpdateProfilePayloadSchema = ProfileFormSchema.extend({
  profileId: z.number().int().gt(0),
});

export interface IFetchUpdateProfilePayloadSchema
  extends z.infer<typeof FetchUpdateProfilePayloadSchema> {}
