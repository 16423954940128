import { AdditionalCostMarginTypeFormSchema } from "./AdditionalCostMarginTypeFormSchema";

import { z } from "zod";

export const FetchUpdateAdditionalCostMarginTypePayloadSchema =
  AdditionalCostMarginTypeFormSchema.extend({
    additionalCostMarginTypeId: z.number().int().gt(0),
  });

export interface IFetchUpdateAdditionalCostMarginTypePayloadSchema
  extends z.infer<typeof FetchUpdateAdditionalCostMarginTypePayloadSchema> {}
