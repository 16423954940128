import { useQuery } from "react-query";

import type { IUseFetchGetAllUsersList } from "./interface";

import { fetchGetAllUsersList } from "~api/users/list/get";

export const useFetchGetAllUsersListKey = "api/users/get";

export function useFetchGetAllUsersList({
  dependencyArray = [],
  options,
}: IUseFetchGetAllUsersList) {
  return useQuery(
    [useFetchGetAllUsersListKey, dependencyArray],
    async () => await fetchGetAllUsersList(),
    options,
  );
}
