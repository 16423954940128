import { api, apiException } from "~services/api";
import {
  IFetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema,
  FetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema,
  PersonAutoCompleteResponseSchema,
} from "~types/schemas";

export const fetchGetPipedriveUnlinkedAutoCompletePerson = async (
  payload: IFetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema.parse(payload);

    const response = await api.get(
      `/persons/pipedrive-unlinked/auto-complete`,
      {
        params: queryParams,
      },
    );

    const parsedData = PersonAutoCompleteResponseSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
