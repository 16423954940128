import { StepTwoFormSchema } from "./StepTwoFormSchema";

import { z } from "zod";

export const FetchUpdateProposalStepTwoPayloadSchema = StepTwoFormSchema.extend(
  {
    customId: z.number().int().gt(0),
  },
);

export interface IFetchUpdateProposalStepTwoPayloadSchema
  extends z.infer<typeof FetchUpdateProposalStepTwoPayloadSchema> {}
