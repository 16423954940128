import { useQuery } from "react-query";

import type { IUseFetchGetAdminModulesById } from "./interface";

import { fetchGetAdminModulesById } from "~api/admin/modules/{id}/get";

export const useFetchGetAdminModulesByIdKey = "api/admin/modules/{id}/get";

export function useFetchGetAdminModulesById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAdminModulesById) {
  return useQuery(
    [useFetchGetAdminModulesByIdKey, dependencyArray],
    async () => await fetchGetAdminModulesById(payload),
    options,
  );
}
