import { api, apiException } from "~services/api";
import {
  FetchUpdateLocationServicePayloadSchema,
  IFetchUpdateLocationServicePayloadSchema,
} from "~types/schemas";

export const fetchUpdateLocationService = async (
  payload: IFetchUpdateLocationServicePayloadSchema,
) => {
  try {
    const { userId, locationService } =
      FetchUpdateLocationServicePayloadSchema.parse(payload);

    await api.patch(
      `/admin/users/${userId}/location-service/${locationService}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
