import { useQuery } from "react-query";

import type { IUseFetchGetAllPipelines } from "./interface";

import { fetchGetAllPipelines } from "~api/pipeline/get";

export const useFetchGetAllPipelinesKey = "api/pipeline/get";

export function useFetchGetAllPipelines({
  options,
  dependencyArray,
}: IUseFetchGetAllPipelines) {
  return useQuery(
    [useFetchGetAllPipelinesKey, dependencyArray],
    async () => await fetchGetAllPipelines(),
    options,
  );
}
