import { useQuery } from "react-query";

import type { IUseFetchGetPersonalToken } from "./interface";

import { fetchGetPersonalToken } from "~api/users/personal-token/get";

export const useFetchGetPersonalTokenKey = "api/users/personal-token/get";

export function useFetchGetPersonalToken({
  dependencyArray = [],
  options,
}: IUseFetchGetPersonalToken) {
  return useQuery(
    [useFetchGetPersonalTokenKey, dependencyArray],
    async () => await fetchGetPersonalToken(),
    options,
  );
}
