import { useQuery } from "react-query";

import type { IUseFetchGetAllCostAggregator } from "./interface";

import { fetchGetAllCostAggregator } from "~api/cost-aggregator/no-pageable/get";

export const useFetchGetAllCostAggregatorKey =
  "api/cost-aggregator/no-pageable/get";

export function useFetchGetAllCostAggregator({
  dependencyArray = [],
  options,
}: IUseFetchGetAllCostAggregator) {
  return useQuery(
    [useFetchGetAllCostAggregatorKey, dependencyArray],
    async () => {
      return await fetchGetAllCostAggregator();
    },
    options,
  );
}
