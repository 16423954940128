import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldsKey } from "../../useFetchGetDealCustomFields";
import { useFetchGetDealCustomFieldsGroupKey } from "../useFetchGetDealCustomFieldsGroup";
import { useFetchGetDealCustomFieldsGroupListKey } from "../useFetchGetDealCustomFieldsGroupList";
import type { IUseFetchCreateDealCustomFieldGroup } from "./interface";

import { fetchCreateDealCustomFieldGroup } from "~api/config/deals/custom-fields/groups/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateDealCustomFieldGroupKey =
  "api/config/deals/custom-fields/groups/post";

export function useFetchCreateDealCustomFieldGroup({
  options,
}: IUseFetchCreateDealCustomFieldGroup) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateDealCustomFieldGroup(payload),
    {
      ...options,
      mutationKey: useFetchCreateDealCustomFieldGroupKey,
      onSuccess: (data, variables, context) => {
        message.success("Grupo de campos customizados de negócio foi criado");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupListKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
