import { useMutation } from "react-query";

import type { IUseFetchUpdateProposalCustomFieldsProfileSettings } from "./interface";

import { fetchUpdateProposalCustomFieldsProfileSettings } from "~api/fields/hideable/custom-field/{customFieldId}/put";
import { useFetchGetCustomFieldsKey } from "~hooks/api/config/useFetchGetCustomFields";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalCustomFieldsProfileSettingsKey =
  "api/fields/hideable/custom-field/{customFieldId}/put";

export function useFetchUpdateProposalCustomFieldsProfileSettings({
  options,
}: IUseFetchUpdateProposalCustomFieldsProfileSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchUpdateProposalCustomFieldsProfileSettings(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalCustomFieldsProfileSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Configurações para o perfil foi atualizado!");
        queryClient.invalidateQueries(useFetchGetCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao atualizar configurações: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
