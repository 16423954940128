import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedDatasheetPayloadSchema,
  type IFetchGetPaginatedDatasheetPayloadSchema,
  PaginatedDatasheetDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedDatasheet = async (
  payload: IFetchGetPaginatedDatasheetPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedDatasheetPayloadSchema.parse(payload);

    const response = await api.get(`/admin/datasheets`, {
      params,
    });

    const parsedData = PaginatedDatasheetDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
