import { useQuery } from "react-query";

import { IUseFetchGetHistoriesFilterByPersonId } from "./interface";

import { fetchGetPaginatedHistoriesFilterByPersonId } from "~api/histories/person/filters/get";

export const useFetchGetPaginatedHistoriesFilterByPersonIdKey =
  "api/histories/person/filters/get";

export function useFetchGetPaginatedHistoriesFilterByPersonId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesFilterByPersonId) {
  return useQuery(
    [useFetchGetPaginatedHistoriesFilterByPersonIdKey, dependencyArray],
    async () => await fetchGetPaginatedHistoriesFilterByPersonId(payload),
    options,
  );
}
