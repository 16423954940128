import { ElectricUtilityProfileDTOSchema } from "./ElectricUtilityProfileDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedElectricUtilityProfileDTOSchema = PaginationSchema(
  ElectricUtilityProfileDTOSchema,
);

export interface IPaginatedElectricUtilityProfileDTOSchema
  extends z.infer<typeof PaginatedElectricUtilityProfileDTOSchema> {}
