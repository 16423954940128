import { UpdateDealCustomFieldFormSchema } from "./UpdateDealCustomFieldFormSchema";

import { z } from "zod";

export const FetchUpdateDealCustomFieldFormPayloadSchema =
  UpdateDealCustomFieldFormSchema.extend({
    id: z.number().int().gt(0),
  });

export interface IFetchUpdateDealCustomFieldFormPayloadSchema
  extends z.infer<typeof FetchUpdateDealCustomFieldFormPayloadSchema> {}
