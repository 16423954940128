import { NotificationDTOSchema } from "./NotificationDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedNotificationDTOSchema = PaginationSchema(
  NotificationDTOSchema,
);

export interface IPaginatedNotificationDTOSchema
  extends z.infer<typeof PaginatedNotificationDTOSchema> {}
