import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductInvertersPayloadSchema,
  type IFetchGetPaginatedProductInvertersPayloadSchema,
  PaginatedInverterCustomDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedProductInverters = async (
  payload: IFetchGetPaginatedProductInvertersPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductInvertersPayloadSchema.parse(payload);

    const response = await api.get(`/product/search/inverter`, {
      params,
    });

    const parsedData = PaginatedInverterCustomDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
