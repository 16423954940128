import { useQuery } from "react-query";

import type { IUseFetchGetDatasheetLastRevision } from "./interface";

import { fetchGetDatasheetLastRevision } from "~api/admin/datasheets/{id}/last-revision/get";

export const useFetchGetDatasheetLastRevisionKey =
  "api/admin/datasheets/${id}/last-revision";

export function useFetchGetDatasheetLastRevision({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDatasheetLastRevision) {
  return useQuery(
    [useFetchGetDatasheetLastRevisionKey, dependencyArray],
    async () => await fetchGetDatasheetLastRevision(payload),
    options,
  );
}
