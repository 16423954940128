import { useQuery } from "react-query";

import type { IUseFetchGetEdeltecInverterBrands } from "./interface";

import { fetchGetEdeltecInverterBrands } from "~api/equipments/edeltec/inverter-brands/get";

export const useFetchGetEdeltecInverterBrandsKey =
  "api/equipments/edeltec/inverter-brands/get";

export function useFetchGetEdeltecInverterBrands({
  dependencyArray = [],
  options,
}: IUseFetchGetEdeltecInverterBrands) {
  return useQuery(
    [useFetchGetEdeltecInverterBrandsKey, dependencyArray],
    async () => await fetchGetEdeltecInverterBrands(),
    options,
  );
}
