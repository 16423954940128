import { z } from "zod";

export const FetchGetSessionDataPayloadSchema = z.object({
  bearerToken: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetSessionDataPayloadSchema
  extends z.infer<typeof FetchGetSessionDataPayloadSchema> {}
