import { api, apiException } from "~services/api";
import {
  FetchImportProductQueuePayloadSchema,
  IFetchImportProductQueuePayloadSchema,
} from "~types/schemas";

export const fetchImportProductQueue = async (
  payload: IFetchImportProductQueuePayloadSchema,
) => {
  try {
    const body = FetchImportProductQueuePayloadSchema.parse(payload);

    await api.post(`/product-queue`, body);
  } catch (error) {
    throw apiException(error);
  }
};
