import { api, apiException } from "~services/api";
import {
  FetchUpdateDealCustomFieldGroupPayloadSchema,
  type IFetchUpdateDealCustomFieldGroupPayloadSchema,
  DealCustomFieldGroupListItemSchema,
} from "~types/schemas";

export const fetchUpdateDealCustomFieldGroup = async (
  payload: IFetchUpdateDealCustomFieldGroupPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateDealCustomFieldGroupPayloadSchema.parse(payload);

    const response = await api.put(
      `/config/deals/custom-fields/groups/${id}`,
      body,
    );

    const parsedData = DealCustomFieldGroupListItemSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
