import { z } from "zod";

export const IntegratorEnum = z
  .enum([
    "EDELTEC",
    "FOCO",
    "FORTLEV",
    "GT",
    "KOMECO",
    "SOUENERGY",
    "WEG",
    "ALDO",
    "ECORI",
  ] as const)
  .catch((e) => e.input);

export type IntegratorEnum = z.infer<typeof IntegratorEnum>;
