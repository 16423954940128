import { MonthlySolarRadiationObjectSchema } from "./MonthlySolarRadiationObjectSchema";

import { IrradiationTypeEnum } from "~types/enum";
import { z } from "zod";

export const MonthlySolarRadiationDTOSchema = z.object({
  monthlySolarRadiation: MonthlySolarRadiationObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  irradiationType: IrradiationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IMonthlySolarRadiationDTOSchema
  extends z.infer<typeof MonthlySolarRadiationDTOSchema> {}
