import { api, apiException } from "~services/api";
import {
  FetchGetGetProposalByCustomIdPayloadSchema,
  IFetchGetGetProposalByCustomIdPayloadSchema,
  ProposalItemDetailsSchema,
} from "~types/schemas";

export const fetchGetGetProposalByCustomId = async (
  payload: IFetchGetGetProposalByCustomIdPayloadSchema,
) => {
  try {
    const { customId } =
      FetchGetGetProposalByCustomIdPayloadSchema.parse(payload);

    const response = await api.get(`/proposal/${customId}`);

    const parsedData = ProposalItemDetailsSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
