import { useQuery } from "react-query";

import { IUseFetchGetPremises } from "./interface";

import { fetchGetPremises } from "~api/premises/get";

export const useFetchGetPremisesKey = "api/premises/get";

export function useFetchGetPremises({
  dependencyArray = [],
  options,
}: IUseFetchGetPremises) {
  return useQuery(
    [useFetchGetPremisesKey, dependencyArray],
    async () => fetchGetPremises(),
    options,
  );
}
