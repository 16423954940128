import { AlternatingCurrentMaterialCalcBaseEnum } from "~types/enum";
import { z } from "zod";

export const AlternatingCurrentMaterialCalcBaseDTOSchema = z.object({
  alternatingCurrentMaterialCalcBase: AlternatingCurrentMaterialCalcBaseEnum,
  name: z.string(),
  selected: z.boolean(),
});

export interface IAlternatingCurrentMaterialCalcBaseDTOSchema
  extends z.infer<typeof AlternatingCurrentMaterialCalcBaseDTOSchema> {}
