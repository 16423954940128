import { api, apiException } from "~services/api";
import { PremiseDTOSchema } from "~types/schemas";

export const fetchGetPremises = async () => {
  try {
    const response = await api.get(`/premises`);

    const parsedData = PremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
