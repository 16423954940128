import { api, apiException } from "~services/api";
import {
  type IFetchUpdateProposalStatusByCustomIdPayloadSchema,
  FetchUpdateProposalStatusByCustomIdPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalStatusByCustomId = async (
  payload: IFetchUpdateProposalStatusByCustomIdPayloadSchema,
) => {
  try {
    const { customId, changeDealStatus, status } =
      FetchUpdateProposalStatusByCustomIdPayloadSchema.parse(payload);
    await api.patch(
      `/proposal/custom-id/${customId}/status?changeDealStatus=${changeDealStatus}`,
      {
        status,
      },
    );
  } catch (error) {
    throw apiException(error);
  }
};
