import { z } from "zod";

const customFieldTypeEnumArray = [
  "MONOCRISTALINO",
  "POLICRISTALINO",
  "MONO_PERC",
] as const;

export const CustomFieldTypeEnum = z
  .enum(customFieldTypeEnumArray)
  .catch((e) => e.input);

export type CustomFieldTypeEnum = z.infer<typeof CustomFieldTypeEnum>;
