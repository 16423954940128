import { ClientDTOSchema } from "./ClientDTOSchema";
import { TemplateReportDTOSchema } from "./TemplateReportDTOSchema";

import { VoltageEnum } from "~types/enum";
import { z } from "zod";

export const StepFiveDTOSchema = z.object({
  id: z
    .number({ message: "ID da proposta deve ser um número" })
    .int({ message: "ID da proposta deve ser um inteiro" })
    .gt(0, { message: "ID da proposta deve ser maior do que zero" }),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  totalValueWithDiscount: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  client: ClientDTOSchema.nullish().transform((val) => val ?? undefined),
  currentBUName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  generationInKwh: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  templates: TemplateReportDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  selectedTemplateId: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  demandAfterSolar: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  demandBeforeSolar: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  isMediumVoltage: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  performanceRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IStepFiveDTOSchema extends z.infer<typeof StepFiveDTOSchema> {}
