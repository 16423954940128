import { MonthEnum } from "~types/enum";
import { z } from "zod";

export const TotalWonGroupedByMonthInsightBarDataSchema = z.object({
  year: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  month: MonthEnum,
  totalWon: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  dealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ITotalWonGroupedByMonthInsightBarDataSchema
  extends z.infer<typeof TotalWonGroupedByMonthInsightBarDataSchema> {}
