import { CustomEosProposalResponseSchema } from "./CustomEosProposalResponseSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedEosFinancialProposalsSchema = PaginationSchema(
  CustomEosProposalResponseSchema,
);

export interface IPaginatedEosFinancialProposalsSchema
  extends z.infer<typeof PaginatedEosFinancialProposalsSchema> {}
