import { z } from "zod";

export const FetchGetPaginatedTrackingPayloadSchema = z.object({
  page: z.number().int().gte(0).default(0),
  size: z.number().int().gte(0).lte(100).default(5),
  trackingId: z.number().int().gt(0),
});

export interface IFetchGetPaginatedTrackingPayloadSchema
  extends z.infer<typeof FetchGetPaginatedTrackingPayloadSchema> {}
