import { api, apiException } from "~services/api";
import {
  FetchGetPersonDetailPipedrivePayloadSchema,
  type IFetchGetPersonDetailPipedrivePayloadSchema,
  PersonDetailPipedriveDTOSchema,
} from "~types/schemas";

export const fetchGetPersonDetailPipedrive = async (
  payload: IFetchGetPersonDetailPipedrivePayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchGetPersonDetailPipedrivePayloadSchema.parse(payload);

    const response = await api.get(
      `/projects/step_one/pipedrive/person/${pipedriveId}`,
    );

    const parsedData = PersonDetailPipedriveDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
