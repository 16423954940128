import { z } from "zod";

export const LoginDTOSchema = z
  .object({
    user: z.string(),
    password: z.string(),
  })
  .partial();

export interface ILoginDTOSchema extends z.infer<typeof LoginDTOSchema> {}
