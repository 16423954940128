import { DistributorDTOSchema } from "./DistributorDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedDistributorDTOSchema =
  PaginationSchema(DistributorDTOSchema);

export interface IPaginatedDistributorDTOSchema
  extends z.infer<typeof PaginatedDistributorDTOSchema> {}
