import { api, apiException } from "~services/api";
import {
  type IFetchCreateDatasheetPayloadSchema,
  DatasheetDTOSchema,
  DatasheetBase64RequestDTOSchema,
} from "~types/schemas";

export const fetchCreateDatasheet = async (
  payload: IFetchCreateDatasheetPayloadSchema,
) => {
  try {
    const body = DatasheetBase64RequestDTOSchema.parse(payload);

    const response = await api.post(`/admin/datasheets/base64`, body);

    const parsedData = DatasheetDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
