import { z } from "zod";

export const EcoriStructureDTOSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEcoriStructureDTOSchema
  extends z.infer<typeof EcoriStructureDTOSchema> {}
