import { BaseCalculationEnum } from "~types/enum";
import { z } from "zod";

export const CreateProjectPaymentSimulationOptionFormSchema = z.object({
  id: z
    .number()
    .int()
    .gte(0)
    .optional()
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  projectPaymentSimulationOptionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  installments: z.number().int().gte(0).lte(240),
  monthlyInterestRate: z.number().gte(0).lte(80),
  downPaymentPercentage: z
    .number()
    .gte(0)
    .lte(100)
    .nullish()
    .transform((val) => val ?? undefined),
  downPaymentFixedValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  baseCalculation: BaseCalculationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ICreateProjectPaymentSimulationOptionFormSchema
  extends z.infer<typeof CreateProjectPaymentSimulationOptionFormSchema> {}
