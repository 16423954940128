import { useQuery } from "react-query";

import type { IUseFetchGetConversionRateInsightGroupedByUser } from "./interface";

import { fetchGetConversionRateInsightGroupedByUser } from "~api/insights/conversionRateInsightGroupedByUser/get";

export const useFetchGetConversionRateInsightGroupedByUserKey =
  "api/insights/leadTimeInsightGroupedByUser/get";

export function useFetchGetConversionRateInsightGroupedByUser({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetConversionRateInsightGroupedByUser) {
  return useQuery(
    [useFetchGetConversionRateInsightGroupedByUserKey, dependencyArray],
    async () => await fetchGetConversionRateInsightGroupedByUser(payload),
    options,
  );
}
