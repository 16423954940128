import { api, apiException } from "~services/api";
import {
  FetchSetDefaultSimultaneityProfileSchema,
  IFetchSetDefaultSimultaneityProfileSchema,
  SimultaneityProfileDTOSchema,
} from "~types/schemas";

export const fetchSetDefaultSimultaneityProfile = async (
  payload: IFetchSetDefaultSimultaneityProfileSchema,
) => {
  try {
    const { simultaneityProfileId } =
      FetchSetDefaultSimultaneityProfileSchema.parse(payload);

    const response = await api.patch(
      `/simultaneityProfiles/default/${simultaneityProfileId}`,
    );

    const parsedData = SimultaneityProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
