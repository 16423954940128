import { useMutation } from "react-query";

import { useFetchGetPaginatedUsersByCompanyKey } from "../../company/useFetchGetPaginatedUsersByCompany";
import type { IUseFetchUpdateUser } from "./interface";

import { fetchUpdateUser } from "~api/users/put";
import { useFetchGetSessionDataKey } from "~hooks/api/auth/useFetchGetSessionData";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateUserKey = "api/users/put";

export function useFetchUpdateUser({ options }: IUseFetchUpdateUser) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdateUser(payload), {
    ...options,
    mutationKey: useFetchUpdateUserKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário foi alterado");
      queryClient.invalidateQueries(useFetchGetPaginatedUsersByCompanyKey);
      queryClient.invalidateQueries(useFetchGetSessionDataKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao alterar usuário: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
