import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveActivities } from "./interface";

import { fetchGetActivitiesPipedrive } from "~api/pipedrive/activities/get";

export const useFetchGetPipedriveActivitiesKey = "api/pipedrive/activities/get";

export function useFetchGetPipedriveActivities({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedriveActivities) {
  return useQuery(
    [useFetchGetPipedriveActivitiesKey, dependencyArray],
    async () => await fetchGetActivitiesPipedrive(),
    options,
  );
}
