import { ProjectAdditionalCostFormSchema } from "./ProjectAdditionalCostFormSchema";
import { ProjectDiscountFormSchema } from "./ProjectDiscountFormSchema";
import { ProjectEquipmentKitFormSchema } from "./ProjectEquipmentKitFormSchema";

import { z } from "zod";

export const StepFourFormSchema = z
  .object({
    totalValue: z.number(),
    totalValueWithDiscount: z.number(),
    usefulAreaInM2: z.number(),
    projectDiscount: ProjectDiscountFormSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
    equipmentKit: ProjectEquipmentKitFormSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
    additionalCosts: z
      .array(ProjectAdditionalCostFormSchema)
      .nullish()
      .transform((val) => val ?? []),
    insights: z
      .array(ProjectAdditionalCostFormSchema)
      .nullish()
      .transform((val) => val ?? []),
    step: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface IStepFourFormSchema
  extends z.infer<typeof StepFourFormSchema> {}
