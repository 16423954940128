import { z } from "zod";

export const FetchGetProposalStepFivePayloadSchema = z.object({
  customId: z
    .number({ message: "Faltando o CustomId da proposta" })
    .int({ message: "CustomId da proposta deve ser um número inteiro" })
    .gt(0, { message: "CustomId da proposta deve ser maior do que zero" }),
});

export interface IFetchGetProposalStepFivePayloadSchema
  extends z.infer<typeof FetchGetProposalStepFivePayloadSchema> {}
