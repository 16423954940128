import { CountrySchema } from "./CountrySchema";

import { z } from "zod";

export const ProvinceSchema = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  uf: z.string(),
  ibge: z.number().int(),
  country: CountrySchema.optional(),
});

export interface IProvinceSchema extends z.infer<typeof ProvinceSchema> {}
