import { useMutation } from "react-query";

import { useFetchGetLogisticsKey } from "../useFetchGetLogistics";
import type { IUseFetchDeleteLogistics } from "./interface";

import { fetchDeleteLogistics } from "~api/logistics/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteLogisticsKey = "api/logistics/{id}/delete";

export function useFetchDeleteLogistics(options?: IUseFetchDeleteLogistics) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeleteLogistics(payload), {
    ...options,
    mutationKey: useFetchDeleteLogisticsKey,
    onSuccess: (data, variables, context) => {
      message.success("Logística inativada!");
      queryClient.invalidateQueries(useFetchGetLogisticsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao remover logística: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
