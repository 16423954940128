import { useQuery } from "react-query";

import type { IUseFetchGetAllReasonForLoss } from "./interface";

import { fetchGetAllReasonForLoss } from "~api/reason-for-loss/get";

export const useFetchGetAllReasonForLossKey = "api/reason-for-loss/get";

export function useFetchGetAllReasonForLoss({
  dependencyArray = [],
  options,
}: IUseFetchGetAllReasonForLoss) {
  return useQuery(
    [useFetchGetAllReasonForLossKey, dependencyArray],
    async () => await fetchGetAllReasonForLoss(),
    options,
  );
}
