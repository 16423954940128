import { api, apiException } from "~services/api";
import {
  type IFetchUpdatePipelinePayloadSchema,
  FetchUpdatePipelinePayloadSchema,
  PipelineDTOSchema,
} from "~types/schemas";

export const fetchUpdatePipeline = async (
  payload: IFetchUpdatePipelinePayloadSchema,
) => {
  try {
    const { id, ...body } = FetchUpdatePipelinePayloadSchema.parse(payload);

    const response = await api.put(`/pipeline/${id}`, body);

    const parsedData = PipelineDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
