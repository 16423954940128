import { useQuery } from "react-query";

import type { IUseFetchGetProfileImage } from "./interface";

import { fetchGetProfileImage } from "~api/users/{id}/avatar/get";

export const useFetchGetProfileImageKey = "api/users/{id}/avatar/get";

export function useFetchGetProfileImage({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProfileImage) {
  return useQuery(
    [useFetchGetProfileImageKey, dependencyArray],
    async () => await fetchGetProfileImage(payload),
    options,
  );
}
