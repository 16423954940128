import { useMutation } from "react-query";

import { useFetchGetStructureCalculationKey } from "../useFetchGetStructureCalculation";
import type { IUseFetchCreateStructureCalculation } from "./interface";

import { fetchCreateStructuresMaterialCostRule } from "~api/structuresMaterialCostRule/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateStructureCalculationKey =
  "api/structuresMaterialCostRule/post";

export function useFetchCreateStructureCalculation({
  options,
}: IUseFetchCreateStructureCalculation) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateStructuresMaterialCostRule(payload),
    {
      ...options,
      mutationKey: useFetchCreateStructureCalculationKey,
      onSuccess: (data, variables, context) => {
        message.success("Cálculo de estrutura foi criado");
        queryClient.invalidateQueries(useFetchGetStructureCalculationKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
