import { Base64 } from "js-base64";
import { z } from "zod";

export const DatasheetCreateRequestDTOSchema = z.object({
  name: z.string({ message: "Nome do Datasheet é obrigatório" }),
  brand: z.string({ message: "Marca do Datasheet é obrigatória" }),
  file: z
    .string()
    .refine(Base64.isValid, { message: "Arquivo não é um base64 válido" }),
});

export interface IDatasheetCreateRequestDTOSchema
  extends z.infer<typeof DatasheetCreateRequestDTOSchema> {}
