import { ActivitiesDoneByUserInsightDataSchema } from "./ActivitiesDoneByUserInsightDataSchema";

import { z } from "zod";

export const ActivitiesDoneByUserInsightDTOSchema = z.object({
  activitiesDoneByUserInsightData: ActivitiesDoneByUserInsightDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IActivitiesDoneByUserInsightDTOSchema
  extends z.infer<typeof ActivitiesDoneByUserInsightDTOSchema> {}
