import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedKits } from "./interface";

import { fetchGetPaginatedKits } from "~api/product/kits/post";

export const useFetchGetPaginatedKitsKey = "api/product/kits/post";

export function useFetchGetPaginatedKits({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
  },
}: IUseFetchGetPaginatedKits) {
  return useQuery(
    [useFetchGetPaginatedKitsKey, dependencyArray],
    async () => await fetchGetPaginatedKits(payload),
    options,
  );
}
