import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetActivitiesCountByPersonIdKey } from "../useFetchGetActivitiesCountByPersonId";
import { useFetchGetActivityByIdKey } from "../useFetchGetActivityById";
import { useFetchGetActivityTypesKey } from "../useFetchGetActivityTypes";
import { useFetchGetBoardActivityKey } from "../useFetchGetBoardActivity";
import { useFetchGetPaginatedActivityByPeriodKey } from "../useFetchGetPaginatedActivityByPeriod";
import { useFetchGetPeriodActivityKey } from "../useFetchGetPeriodActivity";
import type { IUseFetchCreateActivity } from "./interface";

import { fetchCreateActivity } from "~api/activities/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateActivityKey = "api/activities/post";

export function useFetchCreateActivity({ options }: IUseFetchCreateActivity) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateActivity(payload), {
    ...options,
    mutationKey: useFetchCreateActivityKey,
    onSuccess: (data, variables, context) => {
      message.success("Atividade foi cadastrada!");

      queryClient.invalidateQueries(useFetchGetActivityByIdKey);
      queryClient.invalidateQueries(useFetchGetBoardActivityKey);
      queryClient.invalidateQueries(useFetchGetPeriodActivityKey);
      queryClient.invalidateQueries(useFetchGetPaginatedActivityByPeriodKey);
      queryClient.invalidateQueries(useFetchGetActivityTypesKey);
      queryClient.invalidateQueries(useFetchGetActivitiesCountByPersonIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao cadastrar atividade: " + error.message);

      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
