import { useMutation } from "react-query";

import { useFetchPostToGetPaginatedConsultantCompaniesKey } from "../useFetchPostToGetPaginatedConsultantCompanies";
import type { IUseFetchLeaveConsultantInCompanyById } from "./interface";

import { fetchLeaveConsultantInCompanyById } from "~api/consultants/companies/{companyId}/leave/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchLeaveConsultantInCompanyByIdKey =
  "api/consultants/companies/{companyId}/leave/post";

export function useFetchLeaveConsultantInCompanyById({
  options,
}: IUseFetchLeaveConsultantInCompanyById) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchLeaveConsultantInCompanyById(payload);
    },
    {
      ...options,
      mutationKey: useFetchLeaveConsultantInCompanyByIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário consultor saiu da empresa!");
        queryClient.invalidateQueries(
          useFetchPostToGetPaginatedConsultantCompaniesKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
