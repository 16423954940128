import { useQuery } from "react-query";

import type { IUseFetchGetAllSimultaneityProfiles } from "./interface";

import { fetchGetSimultaneityProfiles } from "~api/simultaneityProfiles/get";

export const useFetchGetAllSimultaneityProfilesKey =
  "api/simultaneityProfiles/get";

export function useFetchGetAllSimultaneityProfiles({
  dependencyArray = [],
  options,
}: IUseFetchGetAllSimultaneityProfiles) {
  return useQuery(
    [useFetchGetAllSimultaneityProfilesKey, dependencyArray],
    async () => {
      return await fetchGetSimultaneityProfiles();
    },
    { ...options },
  );
}
