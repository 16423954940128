import { api, apiException } from "~services/api";
import { SimplePipelineDTOSchema } from "~types/schemas";

export const fetchGetAllPipelines = async () => {
  try {
    const response = await api.get(`/pipeline`);

    const parsedData = SimplePipelineDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
