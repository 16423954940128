import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { TaxTypeEnum } from "~types/enum";
import { z } from "zod";

export const MediumVoltageDTOSchema = z.object({
  id: z.number().int().gt(0),
  averageConsumptionP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  taxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageConsumptionFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemand: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  taxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxBefore: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfter: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  reactivePowerAverage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlyValuesP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  monthlyValuesFP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  taxType: TaxTypeEnum.nullish().transform((val) => val ?? undefined),
  contractedDemandP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  postSolarDemandKw: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxTusdG: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IMediumVoltageDTOSchema
  extends z.infer<typeof MediumVoltageDTOSchema> {}
