import { z } from "zod";

export const CustomProductFormSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  priceBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface ICustomProductFormSchema
  extends z.infer<typeof CustomProductFormSchema> {}
