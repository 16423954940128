import { api, apiException } from "~services/api";
import {
  FetchDeleteAlternatingCurrentMaterialPayloadSchema,
  IFetchDeleteAlternatingCurrentMaterialPayloadSchema,
} from "~types/schemas";

export const fetchDeleteAlternatingCurrentMaterial = async (
  payload: IFetchDeleteAlternatingCurrentMaterialPayloadSchema,
) => {
  try {
    const { acMaterialId } =
      FetchDeleteAlternatingCurrentMaterialPayloadSchema.parse(payload);

    return await api.delete<void>(`/ac-materials/${acMaterialId}`);
  } catch (error) {
    throw apiException(error);
  }
};
