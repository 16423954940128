import { api, apiException } from "~services/api";
import {
  IFetchGetPricingTypesPayloadSchema,
  FetchGetCustomFieldsPayloadSchema,
  PricingTypeDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetAllPricingTypes = async (
  payload?: IFetchGetPricingTypesPayloadSchema,
) => {
  try {
    const query = payload
      ? FetchGetCustomFieldsPayloadSchema.parse(payload)
      : undefined;

    const response = await api.get(`/pricing-types`, {
      params: query,
    });

    const parsedData = z
      .array(PricingTypeDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
