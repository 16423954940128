import { useMutation } from "react-query";

import { useFetchGetPaginatedUsersByCompanyKey } from "../../company/useFetchGetPaginatedUsersByCompany";
import { useFetchGetPipedriveUsersKey } from "../../pipedrive/useFetchGetPipedriveUsers";
import type { IUseFetchImportPipedriveUser } from "./interface";

import { fetchImportUserFromPipedrive } from "~api/pipedrive/users/add/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchImportPipedriveUserKey = "api/pipedrive/users/add/post";

export function useFetchImportPipedriveUser({
  options,
}: IUseFetchImportPipedriveUser) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchImportUserFromPipedrive(payload),
    {
      ...options,
      mutationKey: useFetchImportPipedriveUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário foi importado!");
        queryClient.invalidateQueries(useFetchGetPipedriveUsersKey);
        queryClient.invalidateQueries(useFetchGetPaginatedUsersByCompanyKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao importar usuário: " + error?.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
