import { z } from "zod";

export const MessageDTOSchema = z.object({
  message: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IMessageDTOSchema extends z.infer<typeof MessageDTOSchema> {}
