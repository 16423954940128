import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesFilterByOrganizationIdPayloadSchema,
  PaginatedHistoryDTOSchema,
  type IFetchGetHistoriesFilterByOrganizationIdPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedHistoriesFilterByOrganizationId = async (
  payload: IFetchGetHistoriesFilterByOrganizationIdPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetHistoriesFilterByOrganizationIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/organization/filters", {
      params: queryParams,
    });

    const parsedData = PaginatedHistoryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
