import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProposalHistoryPayloadSchema,
  IFetchGetPaginatedProposalHistoryPayloadSchema,
  PaginatedProposalHistoryResponseSchema,
} from "~types/schemas";

export const fetchGetPaginatedProposalHistory = async (
  payload: IFetchGetPaginatedProposalHistoryPayloadSchema,
) => {
  try {
    const { customId, ...params } =
      FetchGetPaginatedProposalHistoryPayloadSchema.parse(payload);

    const response = await api.get(`/proposal/history/${customId}`, {
      params,
    });

    const parsedData = PaginatedProposalHistoryResponseSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
