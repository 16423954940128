import { useMutation } from "react-query";

import { fetchGetMonthlyRadiationByCustomIdKey } from "../useFetchGetMonthlyRadiationByCustomId";
import type { IUseFetchUpdateMonthlyRadiationByCustomId } from "./interface";

import { fetchUpdateMonthlyRadiationByCustomId } from "~api/projects/step_two/monthly-solar-radiation/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateMonthlyRadiationByCustomIdKey =
  "api/ac-materials/post";

export function useFetchUpdateMonthlyRadiationByCustomId({
  options,
}: IUseFetchUpdateMonthlyRadiationByCustomId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateMonthlyRadiationByCustomId(payload),
    {
      ...options,
      mutationKey: useFetchUpdateMonthlyRadiationByCustomIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Solarimetria foi definida!");
        queryClient.invalidateQueries(fetchGetMonthlyRadiationByCustomIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao alterar solarimetria: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
