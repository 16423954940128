import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import { useFetchGetCountOfUsersAccountOwnerKey } from "../useFetchGetCountOfUsersAccountOwner";
import type { IUseFetchDeleteAdminUser } from "./interface";

import { fetchDeleteUser } from "~api/admin/users/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteAdminUserKey = "api/admin/users/{id}/delete";

export function useFetchDeleteAdminUser(options?: IUseFetchDeleteAdminUser) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeleteUser(payload), {
    ...options,
    mutationKey: useFetchDeleteAdminUserKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário foi removido com sucesso!");
      queryClient.invalidateQueries(useFetchGetAdminUsersKey);
      queryClient.invalidateQueries(useFetchGetCountOfUsersAccountOwnerKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
