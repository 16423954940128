import { z } from "zod";

export const SimplePipelineStageDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  position: z.number().int(),
});

export interface ISimplePipelineStageDTOSchema
  extends z.infer<typeof SimplePipelineStageDTOSchema> {}
