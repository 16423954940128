import { useMutation } from "react-query";

import { useFetchGetPaginatedOtherProductsKey } from "../useFetchGetPaginatedOtherProducts";
import type { IUseFetchDeleteProductTypeOther } from "./interface";

import { fetchDeleteProductTypeOther } from "~api/product/other/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteProductTypeOtherKey =
  "api/product/other/{id}/delete";

export function useFetchDeleteProductTypeOther({
  options,
}: IUseFetchDeleteProductTypeOther) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteProductTypeOther(payload),
    {
      ...options,
      mutationKey: useFetchDeleteProductTypeOtherKey,
      onSuccess: (data, variables, context) => {
        message.success("Produto foi removido");
        queryClient.invalidateQueries(useFetchGetPaginatedOtherProductsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao remover produto: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
