import { api, apiException } from "~services/api";
import {
  AdditionalCostMarginTypeDTOSchema,
  FetchUpdateAdditionalCostMarginTypePayloadSchema,
  IFetchUpdateAdditionalCostMarginTypePayloadSchema,
} from "~types/schemas";

export const fetchUpdateAdditionalCostMarginType = async (
  payload: IFetchUpdateAdditionalCostMarginTypePayloadSchema,
) => {
  try {
    const { additionalCostMarginTypeId, ...body } =
      FetchUpdateAdditionalCostMarginTypePayloadSchema.parse(payload);

    const response = await api.put(
      `/additional-cost-margin-type/${additionalCostMarginTypeId}`,
      body,
    );

    const parsedData = AdditionalCostMarginTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
