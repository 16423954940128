import { CustomFieldSettingsFormSchema } from "./CustomFieldSettingsFormSchema";

import { FieldTypeEnum, ProposalSectionEnum } from "~types/enum";
import { z } from "zod";

export const CreateCustomFieldFormSchema = z.object({
  identifier: z
    .string()
    .optional()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z.string(),
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fieldSettings: CustomFieldSettingsFormSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ICreateCustomFieldFormSchema
  extends z.infer<typeof CreateCustomFieldFormSchema> {}
