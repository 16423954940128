import { SolarServiceFormSchema } from "./SolarServiceFormSchema";

import { z } from "zod";

export const FetchUpdateSolarServicebyDealIdPayloadSchema =
  SolarServiceFormSchema.extend({
    id: z.number().int().gt(0),
  });

export interface IFetchUpdateSolarServicebyDealIdPayloadSchema
  extends z.infer<typeof FetchUpdateSolarServicebyDealIdPayloadSchema> {}
