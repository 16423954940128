import { PermissionsEnum } from "~types/enum";
import { z } from "zod";

export const PermissionValueSchema = z.object({
  value: PermissionsEnum.nullish().transform((val) => val ?? undefined),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  tooltipMessage: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IPermissionValueSchema
  extends z.infer<typeof PermissionValueSchema> {}
