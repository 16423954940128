import { z } from "zod";

export const UserManagementDTOSchema = z.object({
  id: z.number().int().gt(0),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  profileId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  profileName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  accountOwner: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  avatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUserManagementDTOSchema
  extends z.infer<typeof UserManagementDTOSchema> {}
