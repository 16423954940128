import { api, apiException } from "~services/api";
import {
  FetchCreateProposalPayloadSchema,
  IFetchCreateProposalPayloadSchema,
  StepOneDTOSchema,
} from "~types/schemas";

export const fetchCreateProposal = async (
  payload: IFetchCreateProposalPayloadSchema,
) => {
  try {
    const body = FetchCreateProposalPayloadSchema.parse(payload);

    const response = await api.post(`/projects/step_one`, body);

    const parsedData = StepOneDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
