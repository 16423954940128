import { EosAddressDTOSchema } from "./EosAddressDTOSchema";

import { z } from "zod";

export const EosClientResponseSchema = z.object({
  nome: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  doc: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  docTipo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  telefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  rendaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IEosClientResponseSchema
  extends z.infer<typeof EosClientResponseSchema> {}
