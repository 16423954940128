import { useMutation } from "react-query";

import { useFetchGetConcessionairesWithAssociatedProfileKey } from "../../electric-utility/useFetchGetConcessionairesWithAssociatedProfile";
import { useFetchGetPaginatedConcessionaireProfilesKey } from "../useFetchGetPaginatedConcessionaireProfilesById";
import type { IUseFetchUpdateConcessionaireProfile } from "./interface";

import { fetchUpdateConcessionaireProfile } from "~api/electric-utility-profile/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateConcessionaireProfileKey =
  "api/electric-utility-profile/{id}/put";

export function useFetchUpdateConcessionaireProfile({
  options,
}: IUseFetchUpdateConcessionaireProfile) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateConcessionaireProfile(payload),
    {
      ...options,
      mutationKey: useFetchUpdateConcessionaireProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil foi alterado");
        queryClient.invalidateQueries(
          useFetchGetPaginatedConcessionaireProfilesKey,
        );
        queryClient.invalidateQueries(
          useFetchGetConcessionairesWithAssociatedProfileKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
