import { api, apiException } from "~services/api";
import { IUsefulAreaFormSchema, UsefulAreaFormSchema } from "~types/schemas";

export const fetchUpdatePremisesUsefulArea = async (
  payload: IUsefulAreaFormSchema,
) => {
  try {
    const body = UsefulAreaFormSchema.parse(payload);

    await api.put<void>(`/premises/useful-area`, body);
  } catch (error) {
    throw apiException(error);
  }
};
