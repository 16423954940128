import { ScaleEnum } from "~types/enum";
import { z } from "zod";

export const DiscountFormSchema = z
  .object({
    maximumPercentageDiscount: z.number(),
    baseCalculationType: ScaleEnum,
    enable: z.boolean(),
  })
  .partial();

export interface IDiscountFormSchema
  extends z.infer<typeof DiscountFormSchema> {}
