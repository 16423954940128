import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import type {
  IUseFetchUnblockUser,
  UseFetchUnblockUserResultType,
  UseFetchUnblockUserErrorType,
  UseFetchUnblockUserPayloadType,
} from "./interface";

import { fetchUnblockUser } from "~api/admin/users/{id}/unblock/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUnblockUserKey = "api/admin/users/{id}/unblock/patch";

export function useFetchUnblockUser(options?: IUseFetchUnblockUser) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchUnblockUserResultType,
    UseFetchUnblockUserErrorType,
    UseFetchUnblockUserPayloadType,
    unknown
  >(
    async (payload: UseFetchUnblockUserPayloadType) => {
      await fetchUnblockUser({
        path: {
          id: payload.id,
        },
      });
    },
    {
      ...options,
      mutationKey: useFetchUnblockUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Acesso do usuário foi desbloqueado!");
        queryClient.invalidateQueries(useFetchGetAdminUsersKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao desbloquear usuário: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
