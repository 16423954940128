import { DealCustomFieldValueResponseSchema } from "./DealCustomFieldValueResponseSchema";

import { z } from "zod";

export const DealCustomFieldValueGroupDTOSchema = z.object({
  id: z
    .number({ message: "É esperado o ID do grupo" })
    .int({ message: "O ID do grupo deve ser um inteiro" })
    .gte(0, { message: "O ID do grupo deve ser maior que zero" }), // zero is acceptable, in some cases dealCustomFields don't have any group. But... is not correctly.
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  dealCustomFields: DealCustomFieldValueResponseSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IDealCustomFieldValueGroupDTOSchema
  extends z.infer<typeof DealCustomFieldValueGroupDTOSchema> {}
