import { useQuery } from "react-query";

import type { IUseFetchGetPeriodByActivity } from "./interface";

import { fetchGetActivityByPeriod } from "~api/activities/period/get";

export const useFetchGetPeriodActivityKey = "api/activities/period/get";

export function useFetchGetPeriodByActivity({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPeriodByActivity) {
  return useQuery(
    [useFetchGetPeriodActivityKey, dependencyArray],
    async () => await fetchGetActivityByPeriod(payload),
    options,
  );
}
