import { EosAddressDTOSchema } from "./EosAddressDTOSchema";

import { z } from "zod";

export const EosGuarantorResponseSchema = z.object({
  nome: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  doc: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  docTipo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  telefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  rendaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
  rg: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  dataNascimento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  sexo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  estadoCivil: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  cargoFuncao: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  naturezaOcupacao: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  tempoEmpresa: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  patrimonio: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosGuarantorResponseSchema
  extends z.infer<typeof EosGuarantorResponseSchema> {}
