import { z } from "zod";

export const AttechedFileFormSchema = z.object({
  fileName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  fileType: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  fileDataInBase64: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IAttechedFileFormSchema
  extends z.infer<typeof AttechedFileFormSchema> {}
