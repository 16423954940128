import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetAllProfilesKey } from "../useFetchGetPaginatedProfiles";
import type { IUseFetchDeleteProfile } from "./interface";

import { fetchDeleteProfile } from "~api/profiles/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteProfileKey = "api/profiles/{id}/delete";

export function useFetchDeleteProfile(options?: IUseFetchDeleteProfile) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => fetchDeleteProfile(payload), {
    ...options,
    mutationKey: useFetchDeleteProfileKey,
    onSuccess: (data, variables, context) => {
      message.success("Perfil foi removido!");
      queryClient.invalidateQueries(useFetchGetAllProfilesKey);
      queryClient.invalidateQueries(useFetchGetSessionDataKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao remover perfil: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
