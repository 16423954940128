import { api, apiException } from "~services/api";
import {
  CustomVariableDTOSchema,
  FetchUpdateCustomVariablePayloadSchema,
  IFetchUpdateCustomVariablePayloadSchema,
} from "~types/schemas";

export const fetchUpdateCustomVariable = async (
  payload: IFetchUpdateCustomVariablePayloadSchema,
) => {
  try {
    const { name, ...body } =
      FetchUpdateCustomVariablePayloadSchema.parse(payload);

    const response = await api.put(`/custom-variable/${name}`, body);

    const parsedData = CustomVariableDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
