import {
  ActivityEventTypeEnum,
  ActivityStatusEnum,
  BusyFreeEnum,
  ActivityPriorityEnum,
} from "~types/enum";
import { z } from "zod";

export const ActivityFormSchema = z
  .object({
    name: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    beginDate: z
      .string()
      .datetime()
      .nullish()
      .transform((val) => val ?? undefined),
    endDate: z
      .string()
      .datetime()
      .nullish()
      .transform((val) => val ?? undefined),
    priority: ActivityPriorityEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    busyFree: BusyFreeEnum.nullish().transform((val) => val ?? undefined),
    addressId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    notes: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    owner: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    allDay: z
      .boolean()
      .nullish()
      .transform((val) => val ?? undefined),
    dealId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    organizationId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    clientId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    status: ActivityStatusEnum.nullish().transform((val) => val ?? undefined),
    eventType: ActivityEventTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    mentionedIds: z
      .number()
      .array()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface IActivityFormSchema
  extends z.infer<typeof ActivityFormSchema> {}
