import { z } from "zod";

export const BusinessFormSchema = z
  .object({
    id: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    ownerId: z.number().int().gt(0),
    userId: z.number().int().gt(0),
    cnpj: z.string().min(18),
    addressId: z.string(),
    telephone: z.string().min(10),
  })
  .partial();

export interface IBusinessFormSchema
  extends z.infer<typeof BusinessFormSchema> {}
