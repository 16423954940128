import { useMutation } from "react-query";

import { useFetchGetPaginatedValidationRulesKey } from "../useFetchGetPaginatedValidationRules";
import type { IUseFetchDeleteValidationRule } from "./interface";

import { fetchDeleteValidationRule } from "~api/rule-expression/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteValidationRuleKey =
  "api/rule-expression/{id}/delete";

export function useFetchDeleteValidationRule(
  options?: IUseFetchDeleteValidationRule,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteValidationRule(payload),
    {
      ...options,
      mutationKey: useFetchDeleteValidationRuleKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra removida!");
        queryClient.invalidateQueries(useFetchGetPaginatedValidationRulesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
