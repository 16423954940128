import { api, apiException } from "~services/api";
import {
  IFetchGetAutoCompleteOrganizationPayloadSchema,
  FetchGetAutoCompleteOrganizationPayloadSchema,
  OrganizationAutoCompleteResponseSchema,
} from "~types/schemas";

export const fetchGetAutoCompleteOrganization = async (
  payload: IFetchGetAutoCompleteOrganizationPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetAutoCompleteOrganizationPayloadSchema.parse(payload);

    const response = await api.get(`/organizations/auto-complete`, {
      params: queryParams,
    });

    const parsedData = OrganizationAutoCompleteResponseSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
