import { useQuery } from "react-query";

import type { IUseFetchGetAdminInvertersById } from "./interface";

import { fetchGetAdminInvertersById } from "~api/admin/inverters/{id}/get";

export const useFetchGetAdminInvertersByIdKey = "api/admin/inverters/{id}/get";

export function useFetchGetAdminInvertersById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAdminInvertersById) {
  return useQuery(
    [useFetchGetAdminInvertersByIdKey, dependencyArray],
    async () => await fetchGetAdminInvertersById(payload),
    options,
  );
}
