import { CustomPvModuleDTOSchema } from "./CustomPvModuleDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCustomPvModuleDTOSchema = PaginationSchema(
  CustomPvModuleDTOSchema,
);

export interface IPaginatedCustomPvModuleDTOSchema
  extends z.infer<typeof PaginatedCustomPvModuleDTOSchema> {}
