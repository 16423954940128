import { api, apiException } from "~services/api";
import {
  type IFetchGetPaginatedAdminInvertersPayloadSchema,
  FetchGetPaginatedAdminInvertersPayloadSchema,
  PaginatedInverterSummaryDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedAdminInverters = async (
  payload: IFetchGetPaginatedAdminInvertersPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetPaginatedAdminInvertersPayloadSchema.parse(payload);

    const response = await api.get(`/admin/inverters`, {
      params: queryParams,
    });

    const parsedData = PaginatedInverterSummaryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
