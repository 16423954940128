import { ProvinceDTOSchema } from "./ProvinceDTOSchema";

import { z } from "zod";

export const ElectricUtilityDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  initialism: z.string(),
  province: ProvinceDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IElectricUtilityDTOSchema
  extends z.infer<typeof ElectricUtilityDTOSchema> {}
