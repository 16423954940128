import { z } from "zod";

export const KitItemReferenceEntityEnum = z
  .enum([
    "ADDITIONAL_COST_DESCRIPTOR",
    "LABOR",
    "ALTERNATING_CURRENT_MATERIAL",
    "LOGISTIC",
    "STRUCTURE",
    "TRANSFORMER",
  ] as const)
  .catch((e) => e.input);

export type KitItemReferenceEntityEnum = z.infer<
  typeof KitItemReferenceEntityEnum
>;
