import { AlternatingCurrentMaterialCalcBaseEnum } from "~types/enum";
import { z } from "zod";

export const AlternatingCurrentMaterialCalcBaseFormSchema = z
  .object({
    alternatingCurrentMaterialCalcBase: AlternatingCurrentMaterialCalcBaseEnum,
  })
  .partial();

export interface IAlternatingCurrentMaterialCalcBaseFormSchema
  extends z.infer<typeof AlternatingCurrentMaterialCalcBaseFormSchema> {}
