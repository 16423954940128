import { api, apiException } from "~services/api";
import {
  FetchCreateBusinessUnitPayloadSchema,
  IFetchCreateBusinessUnitPayloadSchema,
} from "~types/schemas";

export const fetchCreateBusinessUnit = async (
  payload: IFetchCreateBusinessUnitPayloadSchema,
) => {
  try {
    const body = FetchCreateBusinessUnitPayloadSchema.parse(payload);

    await api.post(`/business-units`, body);
  } catch (error) {
    throw apiException(error);
  }
};
