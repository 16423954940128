import { useQuery } from "react-query";

import { IUseFetchGetPremisesTariff } from "./interface";

import { fetchGetPremisesTariff } from "~api/premises/tariff/get";

export const useFetchGetPremisesTariffKey = "api/premises/tariff/get";

export function useFetchGetPremisesTariff({
  dependencyArray = [],
  options,
}: IUseFetchGetPremisesTariff) {
  return useQuery(
    [useFetchGetPremisesTariffKey, dependencyArray],
    async () => fetchGetPremisesTariff(),
    options,
  );
}
