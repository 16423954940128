import { api, apiException } from "~services/api";
import { GhiSystemNameDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetIrradiationSources = async () => {
  try {
    const response = await api.get(`/premises/ghi/system`);

    const parsedData = z.array(GhiSystemNameDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
