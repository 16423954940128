import { z } from "zod";

export const PermissionSectionEnum = z
  .enum([
    "GENERAL",
    "DATA_STEP",
    "SIZING_STEP",
    "DETAILS_STEP",
    "USER_GROUP",
  ] as const)
  .catch((e) => e.input);

export type PermissionSectionEnum = z.infer<typeof PermissionSectionEnum>;
