import { api, apiException } from "~services/api";
import {
  PaginatedDatasheetRevisionSummaryDTOSchema,
  IFetchGetPaginatedDatasheetRevisionsPayloadSchema,
  FetchGetPaginatedDatasheetRevisionsPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedDatasheetRevisions = async (
  payload: IFetchGetPaginatedDatasheetRevisionsPayloadSchema,
) => {
  try {
    const { id, ...params } =
      FetchGetPaginatedDatasheetRevisionsPayloadSchema.parse(payload);

    const response = await api.get(`/admin/datasheets/${id}/revisions`, {
      params,
    });

    const parsedData = PaginatedDatasheetRevisionSummaryDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
