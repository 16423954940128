import {
  ActivityEventTypeEnum,
  ActivityStatusEnum,
  BusyFreeEnum,
  ActivityPriorityEnum,
} from "~types/enum";
import { z } from "zod";

export const FetchUpdateActivityPayloadSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  beginDate: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? undefined),
  endDate: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? undefined),
  priority: ActivityPriorityEnum.nullish().transform((val) => val ?? undefined),
  busyFree: BusyFreeEnum.nullish().transform((val) => val ?? undefined),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  notes: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  organizationId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  clientId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  owner: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  status: ActivityStatusEnum.nullish().transform((val) => val ?? undefined),
  eventType: ActivityEventTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  mentionedIds: z
    .number()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchUpdateActivityPayloadSchema
  extends z.infer<typeof FetchUpdateActivityPayloadSchema> {}
