import { z } from "zod";

export const NewUsefulAreaFormSchema = z.object({
  usefulAreaId: z
    .number({ message: "Faltando o ID da área útil" })
    .int({ message: "ID da área útil deve ser um inteiro" })
    .gt(0, { message: "ID da área útil deve ser maior que zero" }),
  spacingFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface INewUsefulAreaFormSchema
  extends z.infer<typeof NewUsefulAreaFormSchema> {}
