import { api, apiException } from "~services/api";
import {
  FetchJoinConsultantInCompanyByIdPayloadSchema,
  IFetchJoinConsultantInCompanyByIdPayloadSchema,
  UserAccountDTOSchema,
} from "~types/schemas";

export const fetchJoinConsultantInCompanyByIdPayloadSchema = async (
  payload: IFetchJoinConsultantInCompanyByIdPayloadSchema,
) => {
  try {
    const { companyId } =
      FetchJoinConsultantInCompanyByIdPayloadSchema.parse(payload);

    const response = await api.post(`/consultants/companies/${companyId}/join`);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
