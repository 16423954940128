import { UpdateProposalPaymentPayloadSchema } from "./UpdateProposalPaymentPayloadSchema";

import { z } from "zod";

export const UpdateManyProposalPaymentPayloadSchema = z.object({
  customId: z.number().gt(0),
  payments: z
    .array(UpdateProposalPaymentPayloadSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IUpdateManyProposalPaymentPayloadSchema
  extends z.infer<typeof UpdateManyProposalPaymentPayloadSchema> {}
