import { z } from "zod";

export const TotalWonInsightDTOSchema = z.object({
  dealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  dealsCountPreviousPeriod: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  totalWon: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  totalWonPreviousPeriod: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ITotalWonInsightDTOSchema
  extends z.infer<typeof TotalWonInsightDTOSchema> {}
