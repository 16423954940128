import { api, apiException } from "~services/api";
import {
  CustomFieldProposalDTOSchema,
  FetchGetProposalCustomFieldsPayloadSchema,
  IFetchGetProposalCustomFieldsPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetProposalCustomFields = async (
  payload: IFetchGetProposalCustomFieldsPayloadSchema,
) => {
  try {
    const { pricingTypeId } =
      FetchGetProposalCustomFieldsPayloadSchema.parse(payload);

    const response = await api.get(
      `/config/customFields/proposal/${pricingTypeId}`,
    );

    const parsedData = z
      .array(CustomFieldProposalDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
