import { CustomFieldValueSchema } from "./CustomFieldValueSchema";

import { z } from "zod";

export const FetchCreateCustomFieldValuePayloadSchema = z.object({
  customId: z.number().int().gt(0),
  intermediateDataCustomFieldValues: z.array(CustomFieldValueSchema).optional(),
});

export interface IFetchCreateCustomFieldValuePayloadSchema
  extends z.infer<typeof FetchCreateCustomFieldValuePayloadSchema> {}
