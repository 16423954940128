import { useQuery } from "react-query";

import type { IUseFetchGetOrganizationInformationByPipedriveId } from "./interface";

import { fetchGetOrganizationInformationByPipedriveId } from "~api/organizations/pipedrive/{pipedriveId}/get";

export const useFetchGetOrganizationInformationByPipedriveIdKey =
  "api/organizations/pipedrive/{pipedriveId}/get";

export function useFetchGetOrganizationInformationByPipedriveId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetOrganizationInformationByPipedriveId) {
  return useQuery(
    [useFetchGetOrganizationInformationByPipedriveIdKey, dependencyArray],
    async () => await fetchGetOrganizationInformationByPipedriveId(payload),
    options,
  );
}
