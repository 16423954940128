import { ConsultantCompanyDTOSchema } from "./ConsultantCompanyDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedConsultantCompanyDTOSchema = PaginationSchema(
  ConsultantCompanyDTOSchema,
);

export interface IPaginatedConsultantCompanyDTOSchema
  extends z.infer<typeof PaginatedConsultantCompanyDTOSchema> {}
