import { LogChangeTypeEnum } from "~types/enum";
import { z } from "zod";

export const PropertyDiffSchema = z.object({
  propertyName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  propertyNameWithPath: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  oldValue: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  newValue: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  changeType: LogChangeTypeEnum.nullish().transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IPropertyDiffSchema
  extends z.infer<typeof PropertyDiffSchema> {}
