import { InverterDetailFormSchema } from "./InverterDetailFormSchema";

import {
  GridTypeEnum,
  InverterTypeEnum,
  PhaseEnum,
  VoltageEnum,
} from "~types/enum";
import { z } from "zod";

export const InverterFormSchema = z.object({
  gridType: GridTypeEnum,
  type: InverterTypeEnum,
  model: z.string(),
  manufacturer: z.string(),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  maxPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  nominalPower: z.number(),
  overload: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  startupVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maxInputVoltagePerMppt: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  warrantyInYears: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  details: InverterDetailFormSchema.array()
    .nullish()
    .transform((val) => val ?? undefined),
  datasheetId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IInverterFormSchema
  extends z.infer<typeof InverterFormSchema> {}
