import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesByPersonIdPayloadSchema,
  HistoryOrganizationFilterDTOSchema,
  type IFetchGetHistoriesByPersonIdPayloadSchema,
} from "~types/schemas";

export const fetchGetHistoriesFiltersTypeByPersonId = async (
  payload: IFetchGetHistoriesByPersonIdPayloadSchema,
) => {
  try {
    const queryParams = FetchGetHistoriesByPersonIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/organization/filters/types", {
      params: queryParams,
    });

    const parsedData = HistoryOrganizationFilterDTOSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
