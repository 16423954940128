import { api, apiException } from "~services/api";
import {
  FetchGetProfileByIdPayloadSchema,
  IFetchGetProfileByIdPayloadSchema,
  ProfileDTOSchema,
} from "~types/schemas";

export const fetchGetProfileById = async (
  payload: IFetchGetProfileByIdPayloadSchema,
) => {
  try {
    const { profileId } = FetchGetProfileByIdPayloadSchema.parse(payload);

    const response = await api.get(`/profiles/${profileId}`);

    const parsedData = ProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
