import { z } from "zod";

export const DatasheetRevisionSummaryDTOSchema = z.object({
  id: z
    .number({ message: "ID do Datasheet é obrigatório" })
    .int({ message: "ID do Datasheet deve ser um numero inteiro" })
    .gt(0, { message: "ID do Datasheet deve ser maior que zero" }),
  revision: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  hash: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDatasheetRevisionSummaryDTOSchema
  extends z.infer<typeof DatasheetRevisionSummaryDTOSchema> {}
