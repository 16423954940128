import { useMutation } from "react-query";

import { useFetchGetCustomFieldsKey } from "../useFetchGetCustomFields";
import type { IUseFetchCreateCustomField } from "./interface";

import { fetchCreateCustomField } from "~api/config/customFields/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateCustomFieldsKey = "api/config/customFields/post";

export function useFetchCreateCustomField({
  options,
}: IUseFetchCreateCustomField) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateCustomField(payload), {
    ...options,
    mutationKey: useFetchCreateCustomFieldsKey,
    onSuccess: (data, variables, context) => {
      message.success("Campo foi criado com sucesso");
      queryClient.invalidateQueries(useFetchGetCustomFieldsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
