import { Base64 } from "js-base64";
import { z } from "zod";

export const DatasheetBase64RequestDTOSchema = z.object({
  name: z.string({ message: "Nome do Datasheet é obrigatório" }),
  brand: z.string({ message: "Fabricante do Datasheet é obrigatório" }),
  base64: z
    .string({ message: "Arquivo .pdf do Datasheet é obrigatório" })
    .refine(Base64.isValid, { message: "Arquivo não é um base64 válido" }),
});

export interface IDatasheetBase64RequestDTOSchema
  extends z.infer<typeof DatasheetBase64RequestDTOSchema> {}
