import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedConcessionairesPayloadSchema,
  IFetchGetPaginatedConcessionairesPayloadSchema,
  PaginatedElectricUtilityDTOSchema,
} from "~types/schemas";

export const fetchGetConcessionaires = async (
  payload: IFetchGetPaginatedConcessionairesPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedConcessionairesPayloadSchema.parse(payload);

    const response = await api.get(`/electric-utility`, {
      params,
    });

    const parsedData = PaginatedElectricUtilityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
