import { z } from "zod";

export const BusinessUnitAccountUserDTOSchema = z.object({
  id: z.number().int().gt(0),
  email: z.string().email(),
  accountOwner: z.boolean(),
});

export interface IBusinessUnitAccountUserDTOSchema
  extends z.infer<typeof BusinessUnitAccountUserDTOSchema> {}
