import { CustomProductDTOSchema } from "./CustomProductDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCustomProductDTOSchema = PaginationSchema(
  CustomProductDTOSchema,
);

export interface IPaginatedCustomProductDTOSchema
  extends z.infer<typeof PaginatedCustomProductDTOSchema> {}
