import { useQuery } from "react-query";

import { IUseFetchGetPaginatedConcessionaires } from "./interface";

import { fetchGetConcessionaires } from "~api/electric-utility/get";

export const UseFetchGetPaginatedConcessionairesKey =
  "api/electric-utility/get";

export function useFetchGetPaginatedConcessionaires({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedConcessionaires) {
  return useQuery(
    [UseFetchGetPaginatedConcessionairesKey, dependencyArray],
    async () => {
      return await fetchGetConcessionaires(payload);
    },
    options,
  );
}
