import { z } from "zod";

export const AttachedFileDTOSchema = z.object({
  fileName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  fileType: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  fileSize: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fileId: z.number().int().gt(0),
});

export interface IAttachedFileDTOSchema
  extends z.infer<typeof AttachedFileDTOSchema> {}
