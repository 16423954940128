import { AddressDTOSchema } from "./AddressDTOSchema";

import { ClientTypeEnum } from "~types/enum/ClientTypeEnum";
import { z } from "zod";

export const ClientSearchDTOSchema = z.object({
  id: z.number().int().gt(0),
  customId: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  clientType: ClientTypeEnum.nullish().transform((val) => val ?? undefined),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IClientSearchDTOSchema
  extends z.infer<typeof ClientSearchDTOSchema> {}
