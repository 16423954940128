import { useMutation } from "react-query";

import type { IUseFetchSaveTrackingHeartbeat } from "./interface";

import { fetchSaveTrackingHeartbeat } from "~api/tracking/viewed/{uuid}/patch";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchSaveTrackingHeartbeatKey =
  "api/tracking/viewed/{uuid}/patch";

export function useFetchSaveTrackingHeartbeat({
  options,
}: IUseFetchSaveTrackingHeartbeat) {
  const { message } = useAppConfig();

  return useMutation(
    async (path) => {
      return await fetchSaveTrackingHeartbeat(path);
    },
    {
      ...options,
      mutationKey: useFetchSaveTrackingHeartbeatKey,
      onSuccess: (data, variables, context) => {
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
