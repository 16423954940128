import { ConsumptionUnitFormSchema } from "./ConsumptionUnitFormSchema";

import { PhaseEnum } from "~types/enum";
import { z } from "zod";

export const StepTwoEstimatedPowerFormSchema = z.object({
  generationFactor: z.number(),
  performanceRate: z.number(),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  teP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageConsumption: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageConsumptionP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageConsumptionFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  lowVoltageForm: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  mediumVoltageForm: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  considerConsumptionUnits: z.boolean().nullish(),
  consumptionUnits: z.array(ConsumptionUnitFormSchema).nullish(),
});

export interface IStepTwoEstimatedPowerFormSchema
  extends z.infer<typeof StepTwoEstimatedPowerFormSchema> {}
