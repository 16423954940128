import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedUsageLogsPayloadSchema,
  IFetchGetPaginatedUsageLogsPayloadSchema,
  PaginatedUsageLogsDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedUsageLogs = async (
  payload: IFetchGetPaginatedUsageLogsPayloadSchema,
) => {
  try {
    const queryParams = FetchGetPaginatedUsageLogsPayloadSchema.parse(payload);

    const response = await api.get("/usage-logs", {
      params: queryParams,
    });

    const parsedData = PaginatedUsageLogsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
