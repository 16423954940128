import { z } from "zod";

export const FetchGetPaginatedEosFinancialProposalsPayloadSchema = z.object({
  page: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 1),
  // query: z
  //   .string()
  //   .nullish()
  //   .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedEosFinancialProposalsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedEosFinancialProposalsPayloadSchema> {}
