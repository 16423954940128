import { api, apiException } from "~services/api";
import {
  FetchGetMonthlyRadiationByCustomIdPayloadSchema,
  IFetchGetMonthlyRadiationByCustomIdPayloadSchema,
  MonthlySolarRadiationDTOSchema,
} from "~types/schemas";

export const fetchGetMonthlyRadiationByCustomId = async (
  payload: IFetchGetMonthlyRadiationByCustomIdPayloadSchema,
) => {
  try {
    const { customId } =
      FetchGetMonthlyRadiationByCustomIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/projects/step_two/monthly-solar-radiation/${customId}`,
    );

    if (!response.data) return undefined;

    const parsedData = MonthlySolarRadiationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
