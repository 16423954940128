import { TypeDocumentEnum } from "~types/enum";
import { z } from "zod";

export const FetchUploadDocumentEosFinancingPayloadSchema = z.object({
  protocol: z.string(),
  documentType: TypeDocumentEnum,
  file: z.instanceof(FormData),
});

export interface IFetchUploadDocumentEosFinancingPayloadSchema
  extends z.infer<typeof FetchUploadDocumentEosFinancingPayloadSchema> {}
