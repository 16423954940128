import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesByDealIdPayloadSchema,
  PaginatedHistoryDTOSchema,
  type IFetchGetHistoriesByDealIdPayloadSchema,
} from "~types/schemas";

export const fetchGetHistoriesByDealId = async (
  payload: IFetchGetHistoriesByDealIdPayloadSchema,
) => {
  try {
    const { dealId } = FetchGetHistoriesByDealIdPayloadSchema.parse(payload);

    const response = await api.get(`/histories/deal/${dealId}`);

    const parsedData = PaginatedHistoryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
