import { LaborCopierFormSchema } from "./LaborCopierFormSchema";

import { z } from "zod";

export const FetchCreateLaborCopyPayloadSchema = LaborCopierFormSchema.extend({
  laborId: z.number().int().gt(0),
});

export interface IFetchCreateLaborCopyPayloadSchema
  extends z.infer<typeof FetchCreateLaborCopyPayloadSchema> {}
