import { api, apiException } from "~services/api";
import {
  type IFetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema,
  FetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema,
  PaginatedOrganizationDealDTOSchema,
} from "~types/schemas";

export async function fetchGetPaginatedOrganizationDealsByOrganizationId(
  payload: IFetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema,
) {
  try {
    const { organizationId, ...queryParams } =
      FetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema.parse(
        payload,
      );

    const response = await api.get(`/organizations/${organizationId}/deals`, {
      params: queryParams,
    });

    const parsedData = PaginatedOrganizationDealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
