import { useQuery } from "react-query";

import type { IFetchGetElectricUtilityProfileModalities } from "./interface";

import { fetchGetElectricUtilityProfileModalities } from "~api/electric-utility-profile/modalities/get";

export const useFetchGetElectricUtilityProfileConsumerModalitiesKey =
  "api/electric-utility-profile/modalities/get";

export function useFetchGetElectricUtilityProfileModalities({
  dependencyArray = [],
  options,
}: IFetchGetElectricUtilityProfileModalities) {
  return useQuery(
    [useFetchGetElectricUtilityProfileConsumerModalitiesKey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileModalities(),
    options,
  );
}
