import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchCreatePaymentSimulation } from "./interface";

import { fetchSaveSimulationGroup } from "~api/project/payment/simulation/group/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePaymentSimulationKey =
  "api/projects/payment/simulation/group/post";

export function useFetchCreatePaymentSimulation({
  options,
}: IUseFetchCreatePaymentSimulation) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchSaveSimulationGroup(payload),
    {
      ...options,
      mutationKey: useFetchCreatePaymentSimulationKey,
      onSuccess: (data, variables, context) => {
        message.success("Simulação criada!");
        queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar simulação: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
