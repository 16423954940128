import { ReasonForLossRequestSchema } from "./ReasonForLossRequestSchema";

import { z } from "zod";

export const FetchUpdateReasonForLossPayloadSchema =
  ReasonForLossRequestSchema.extend({
    id: z
      .number({ message: "ID do motivo de perda é obrigatório" })
      .int({ message: "ID do motivo de perda deve ser um inteiro" })
      .gt(0, { message: "ID do motivo de perda deve ser maior do que zero" }),
  });

export interface IFetchUpdateReasonForLossPayloadSchema
  extends z.infer<typeof FetchUpdateReasonForLossPayloadSchema> {}
