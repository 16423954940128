import { z } from "zod";

export const FetchUpdateReasonForLossPositionPayloadSchema = z.object({
  id: z
    .number({ message: "ID do motivo de perda é obrigatório" })
    .int({ message: "ID do motivo de perda deve ser um inteiro" })
    .gt(0, { message: "ID do motivo de perda deve ser maior do que zero" }),
  position: z
    .number({ message: "Posição do motivo de perda é obrigatório" })
    .int({ message: "Posição do motivo de perda deve ser um inteiro" })
    .gt(0, {
      message: "Posição do motivo de perda deve ser maior do que zero",
    }),
});

export interface IFetchUpdateReasonForLossPositionPayloadSchema
  extends z.infer<typeof FetchUpdateReasonForLossPositionPayloadSchema> {}
