import { api, apiException } from "~services/api";
import {
  CreateCustomFieldFormSchema,
  ICreateCustomFieldFormSchema,
  CustomFieldDTOSchema,
} from "~types/schemas";

export const fetchCreateCustomField = async (
  payload: ICreateCustomFieldFormSchema,
) => {
  try {
    const body = CreateCustomFieldFormSchema.parse(payload);

    const response = await api.post(`/config/customFields`, body);

    const parsedData = CustomFieldDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
