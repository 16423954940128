import { api, apiException } from "~services/api";
import {
  FetchCreateUserPayloadSchema,
  IFetchCreateUserPayloadSchema,
  UserAccountDTOSchema,
} from "~types/schemas";

export const fetchCreateUser = async (
  payload: IFetchCreateUserPayloadSchema,
) => {
  try {
    const body = FetchCreateUserPayloadSchema.parse(payload);

    const response = await api.post(`/users`, body);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
