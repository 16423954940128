import { z } from "zod";

export const FetchGetPaginatedOrganizationPayloadSchema = z.object({
  page: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 1),
  query: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  userId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedOrganizationPayloadSchema
  extends z.infer<typeof FetchGetPaginatedOrganizationPayloadSchema> {}
