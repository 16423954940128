import { z } from "zod";

export const VariableBindDTOSchema = z.object({
  templateVariable: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  placeholder: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  category: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  fieldKey: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IVariableBindDTOSchema
  extends z.infer<typeof VariableBindDTOSchema> {}
