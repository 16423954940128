import { darkTheme, darkColors } from "./darkTheme";
import { defaultTheme, defaultColors } from "./defaultTheme";

export const theme = {
  default: defaultTheme,
  dark: darkTheme,
};

export const themeColor = {
  default: defaultColors,
  dark: darkColors,
};

export type ITheme = keyof typeof theme;
export type IThemeColor = typeof defaultColors | typeof darkColors;
