import { z } from "zod";

export const DatasheetRevisionDTOSchema = z.object({
  id: z.number({ message: "ID do Datasheet é obrigatório" }).int().gt(0),
  revision: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  hash: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDatasheetRevisionDTOSchema
  extends z.infer<typeof DatasheetRevisionDTOSchema> {}
