import { z } from "zod";

export const SimpleCompanyDTOSchema = z.object({
  id: z.number().int().gt(0),
  idPipedrive: z
    .number()
    .int()
    .gte(0) // 0 is valid
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
});

export interface ISimpleCompanyDTOSchema
  extends z.infer<typeof SimpleCompanyDTOSchema> {}
