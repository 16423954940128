import { z } from "zod";

export const UpdateClientFormSchema = z.object({
  personCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  organizationCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IUpdateClientFormSchema
  extends z.infer<typeof UpdateClientFormSchema> {}
