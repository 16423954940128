import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedProposalByDealId } from "./interface";

import { fetchGetPaginatedProposalByDealId } from "~api/proposal/deal/{id}/get";

export const useFetchGetPaginatedProposalByDealIdKey =
  "api/proposal/deal/{id}/get";

export function useFetchGetPaginatedProposalByDealId({
  dependencyArray = [],
  options,
  payload = {
    dealId: 0,
  },
}: IUseFetchGetPaginatedProposalByDealId) {
  return useQuery(
    [useFetchGetPaginatedProposalByDealIdKey, dependencyArray],
    async () => await fetchGetPaginatedProposalByDealId(payload),
    options,
  );
}
