import { DealCustomFieldValueSchema } from "./DealCustomFieldValueSchema";
import { DealReasonForLossDTOSchema } from "./DealReasonForLossDTOSchema";

import { ProposalStatusEnum } from "~types/enum";
import { z } from "zod";

// this schema must be have default value as null and not undefined, actually backend ignore missing atributes from payload STATUS is a exception
export const UpdateDealFormSchema = z
  .object({
    name: z
      .string()
      .nullish()
      .transform((val) => val ?? null),
    organizationId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? null),
    pipelineStageId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? null),
    personId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? null),
    ownerId: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? null),
    value: z
      .number()
      .nullish()
      .transform((val) => val ?? null),
    dealReasonForLoss: DealReasonForLossDTOSchema.nullish().transform(
      (val) => val ?? null,
    ),
    status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
    customFieldValues: DealCustomFieldValueSchema.array()
      .nullish()
      .transform((val) => val ?? null),
  })
  .partial();

export interface IUpdateDealFormSchema
  extends z.infer<typeof UpdateDealFormSchema> {}
