import { z } from "zod";

export const FetchGetMonthlyPerformanceRateByCustomIdPayloadSchema = z.object({
  customId: z.number().int().gt(0),
});

export interface IFetchGetMonthlyPerformanceRateByCustomIdPayloadSchema
  extends z.infer<
    typeof FetchGetMonthlyPerformanceRateByCustomIdPayloadSchema
  > {}
