import { useQuery } from "react-query";

import { IUseFetchGetComplementaryCostExcel } from "./interface";

import { fetchGetComplementaryCostExcel } from "~api/complementary-cost/download-template/get";

export const UseFetchGetComplementaryCostExcelKey =
  "api/complementary-cost/download-template/get";

export function useFetchGetComplementaryCostExcel({
  dependencyArray = [],
  options,
}: IUseFetchGetComplementaryCostExcel) {
  return useQuery(
    [UseFetchGetComplementaryCostExcelKey, dependencyArray],
    async () => {
      const response = await fetchGetComplementaryCostExcel();

      return response.data;
    },
    options,
  );
}
