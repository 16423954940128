import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetUserBusinessUnitsKey } from "../../business-unit/useFetchGetUserBusinessUnits";
import { useFetchGetPaginatedUsersByCompanyKey } from "../useFetchGetPaginatedUsersByCompany";
import type { IUseFetchUpdateUserProfile } from "./interface";

import { fetchUpdateUserProfile } from "~api/company/users/profile/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateUserProfileKey = "api/company/users/profile";

export function useFetchUpdateUserProfile(
  options?: IUseFetchUpdateUserProfile,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchUpdateUserProfile(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateUserProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário foi associado ao perfil!");
        queryClient.invalidateQueries(useFetchGetPaginatedUsersByCompanyKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          "Falha ao associar perfil para o usuário: " + error.message,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
