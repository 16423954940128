import { z } from "zod";

export const FetchSetOrRemovePerformanceProfileDefaultPayloadSchema = z.object({
  id: z.number().int().gt(0),
});

export interface IFetchSetOrRemovePerformanceProfileDefaultPayloadSchema
  extends z.infer<
    typeof FetchSetOrRemovePerformanceProfileDefaultPayloadSchema
  > {}
