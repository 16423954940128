import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedProfiles } from "./interface";

import { fetchGetPaginatedProfiles } from "~api/profiles/get";

export const useFetchGetAllProfilesKey = "api/profiles/get";

export function useFetchGetPaginatedProfiles({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedProfiles) {
  return useQuery(
    [useFetchGetAllProfilesKey, dependencyArray],
    async () => await fetchGetPaginatedProfiles(payload),
    options,
  );
}
