import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedUserGroupPayloadSchema,
  IFetchGetPaginatedUserGroupPayloadSchema,
  PaginatedUserGroupDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedUserGroup = async (
  payload: IFetchGetPaginatedUserGroupPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedUserGroupPayloadSchema.parse(payload);

    const response = await api.get(`/usergroup`, { params });

    const parsedData = PaginatedUserGroupDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
