import { z } from "zod";

export const FetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema =
  z.object({
    term: z.string(),
  });

export interface IFetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema
  extends z.infer<
    typeof FetchGetPipedriveUnlinkedAutoCompletePersonPayloadSchema
  > {}
