import { api, apiException } from "~services/api";
import {
  FetchUpdateLaborPayloadSchema,
  IFetchUpdateLaborPayloadSchema,
  LaborDTOSchema,
} from "~types/schemas";

export const fetchUpdateLabor = async (
  payload: IFetchUpdateLaborPayloadSchema,
) => {
  try {
    const { laborId, ...body } = FetchUpdateLaborPayloadSchema.parse(payload);

    const response = await api.put(`/labors/${laborId}`, body);

    const parsedData = LaborDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
