import { HistoryFilterEnum } from "~types/enum";
import { z } from "zod";

export const HistoryOrganizationFilterDTOSchema = z.object({
  type: HistoryFilterEnum,
  quantity: z.number().int().gte(0),
});

export interface IHistoryOrganizationFilterDTOSchema
  extends z.infer<typeof HistoryOrganizationFilterDTOSchema> {}
