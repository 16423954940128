import { api, apiException } from "~services/api";
import {
  AlternatingCurrentMaterialDTOSchema,
  FetchUpdateAlternatingCurrentMaterialPayloadSchema,
  IFetchUpdateAlternatingCurrentMaterialPayloadSchema,
} from "~types/schemas";

export const fetchUpdateAlternatingCurrentMaterial = async (
  payload: IFetchUpdateAlternatingCurrentMaterialPayloadSchema,
) => {
  try {
    const { acMaterialId, ...body } =
      FetchUpdateAlternatingCurrentMaterialPayloadSchema.parse(payload);

    const response = await api.put(`/ac-materials/${acMaterialId}`, body);

    const parsedData = AlternatingCurrentMaterialDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
