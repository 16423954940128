import { CustomFieldSettingsFormSchema } from "./CustomFieldSettingsFormSchema";

import { FieldTypeEnum, ProposalSectionEnum } from "~types/enum";
import { z } from "zod";

export const UpdateCustomFieldFormSchema = z.object({
  id: z.number().int().gt(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val || undefined),
  label: z.string(),
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fieldSettings: z.array(CustomFieldSettingsFormSchema).optional(),
});

export interface IUpdateCustomFieldFormSchema
  extends z.infer<typeof UpdateCustomFieldFormSchema> {}
