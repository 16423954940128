import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedPersonDealsByPersonId } from "./interface";

import { fetchGetPaginatedPersonDealsByPersonId } from "~api/persons/{id}/deals/get";

export const useFetchGetPaginatedPersonDealsByPersonIdKey =
  "api/persons/{id}/deals/get";

export function useFetchGetPaginatedPersonDealsByPersonId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedPersonDealsByPersonId) {
  return useQuery(
    [useFetchGetPaginatedPersonDealsByPersonIdKey, dependencyArray],
    async () => await fetchGetPaginatedPersonDealsByPersonId(payload),
    options,
  );
}
