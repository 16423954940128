import { useMutation } from "react-query";

import { useFetchGetPaginatedSimultaneityProfilesKey } from "../useFetchGetPaginatedSimultaneityProfiles";
import type { IUseFetchUpdateSimultaneityProfile } from "./interface";

import { fetchUpdateSimultaneityProfile } from "~api/simultaneityProfiles/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateSimultaneityProfileKey =
  "api/simultaneityProfiles/put";

export function useFetchUpdateSimultaneityProfile({
  options,
}: IUseFetchUpdateSimultaneityProfile) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateSimultaneityProfile(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateSimultaneityProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil alterado com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedSimultaneityProfilesKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
