import { z } from "zod";
import { api, apiException } from "~services/api";
import { BusinessUnit } from "~types/api/BusinessUnit";
import { BusinessUnitDTOSchema, FetchGetUserOutBusinessUnitPayloadSchema, IFetchGetUserOutBusinessUnitPayloadSchema } from "~types/schemas";

export const fetchGetUserOutBusinessUnit = async (payload: IFetchGetUserOutBusinessUnitPayloadSchema) => {
  try {
    const { userId } = FetchGetUserOutBusinessUnitPayloadSchema.parse(payload);

    const response = await api.get<BusinessUnit[]>(`/users/business-units/out/${userId}`);

    const parsedData = z.array(BusinessUnitDTOSchema).nullish().transform((val) => val ?? []).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
