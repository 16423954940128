import { z } from "zod";

export const DealHistoryDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});
