import { api, apiException } from "~services/api";
import {
  TemplateReportDTOSchema,
  FetchUpdateReportTemplateByIdPayloadSchema,
  IFetchUpdateReportTemplateByIdPayloadSchema,
} from "~types/schemas";

export const fetchUpdateReportTemplateById = async (
  payload: IFetchUpdateReportTemplateByIdPayloadSchema,
) => {
  try {
    const { reportTemplateId, ...body } =
      FetchUpdateReportTemplateByIdPayloadSchema.parse(payload);

    const response = await api.put(`/reports/${reportTemplateId}`, body);

    const parsedData = TemplateReportDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
