import { useMutation } from "react-query";

import { useFetchGetPaginatedTransformerRulesKey } from "../useFetchGetPaginatedTransformerRules";
import type { IUseFetchDeleteTransformerRule } from "./interface";

import { fetchDeleteTransformerRule } from "~api/config/transformer/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteTransformerRuleKey =
  "api/config/transformer/{id}/delete";

export function useFetchDeleteTransformerRule(
  options?: IUseFetchDeleteTransformerRule,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchDeleteTransformerRule(payload);
    },
    {
      ...options,
      mutationKey: useFetchDeleteTransformerRuleKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra de transformador foi removida");
        queryClient.invalidateQueries(useFetchGetPaginatedTransformerRulesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
