import { z } from "zod";

export const FetchGetPaginatedLaborsPayloadSchema = z
  .object({
    page: z.number().int().gte(0).default(0),
    size: z.number().int().gte(0).default(5),
  })
  .partial();

export interface IFetchGetPaginatedLaborsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedLaborsPayloadSchema> {}
