import { z } from "zod";

export const CalculationTypeEnum = z
  .enum([
    "PERCENTUAL",
    "VALOR_FIXO",
    "MULTIPLICADOR",
    "EXPRESSAO_PERSONALIZADA",
    "PERCENTUAL_AGREGADOR_CUSTO",
  ] as const)
  .catch((e) => e.input);

export type CalculationTypeEnum = z.infer<typeof CalculationTypeEnum>;
