import { QueryKey, useQuery } from "react-query";

import type {
  IUseFetchGetProposalEmailMessage,
  UseFetchGetProposalEmailMessageResultType,
  UseFetchGetProposalEmailMessageErrorType,
} from "./interface";

import { fetchGetMessageEmailProposal } from "~api/projects/step_five/message/{id}/get";

export const useFetchGetProposalEmailMessageKey =
  "~api/projects/step_five/message/{id}/get/email";

export function useFetchGetProposalEmailMessage({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalEmailMessage) {
  return useQuery<
    UseFetchGetProposalEmailMessageResultType,
    UseFetchGetProposalEmailMessageErrorType,
    UseFetchGetProposalEmailMessageResultType,
    QueryKey
  >(
    [useFetchGetProposalEmailMessageKey, dependencyArray],
    async () => {
      const response = await fetchGetMessageEmailProposal({
        path: {
          customId: payload.customId,
        },
      });

      const data = response.data;

      return data;
    },
    options,
  );
}
