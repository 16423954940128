import { api, apiException } from "~services/api";
import {
  DealCustomFieldResponseSchema,
  FetchUpdateDealCustomFieldFormPayloadSchema,
  IFetchUpdateDealCustomFieldFormPayloadSchema,
} from "~types/schemas";

export const fetchUpdateDealCustomFields = async (
  payload: IFetchUpdateDealCustomFieldFormPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateDealCustomFieldFormPayloadSchema.parse(payload);

    const response = await api.put(`/config/deals/custom-fields/${id}`, body);

    const parsedData = DealCustomFieldResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
