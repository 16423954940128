import { z } from "zod";

export const EcoriModuleBrandDTOSchema = z.object({
  id: z.number().int().gt(0),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEcoriModuleBrandDTOSchema
  extends z.infer<typeof EcoriModuleBrandDTOSchema> {}
