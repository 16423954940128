import { CustomVariableFormSchema } from "./CustomVariableFormSchema";

import { z } from "zod";

export const FetchUpdateCustomVariablePayloadSchema =
  CustomVariableFormSchema.extend({
    name: z.string(),
  });

export interface IFetchUpdateCustomVariablePayloadSchema
  extends z.infer<typeof FetchUpdateCustomVariablePayloadSchema> {}
