import { z } from "zod";

export const ElectricUtilityProfileDetailSearchSchema = z.object({
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 10),
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  electricUtilityName: z.string(), // CANNOT BE EMPTY
  subgroup: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  modality: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  consumerClass: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  consumerSubclass: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  detail: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  accessBy: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? undefined),
  icms: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  pisCofins: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IElectricUtilityProfileDetailSearchSchema
  extends z.infer<typeof ElectricUtilityProfileDetailSearchSchema> {}
