import { api, apiException } from "~services/api";
import {
  FetchUpdateStructureTypePayloadSchema,
  IFetchUpdateStructureTypePayloadSchema,
} from "~types/schemas";

export const fetchUpdateStructureType = async (
  payload: IFetchUpdateStructureTypePayloadSchema,
) => {
  try {
    const { structureTypeId } =
      FetchUpdateStructureTypePayloadSchema.parse(payload);

    await api.put(`/company/structure_type/${structureTypeId}`);
  } catch (error) {
    throw apiException(error);
  }
};
