import { useMutation } from "react-query";

import { useFetchGetCompanyDistributorsKey } from "../../company/useFetchGetCompanyDistributors";
import { useFetchGetStructureDistributorsKey } from "../../structure-distributor/useFetchGetStructureDistributors";
import { useFetchGetConfigWegDistributorIntegrationKey } from "../useFetchGetConfigWegDistributorIntegration";
import { useFetchGetPaginatedDistributorsKey } from "../useFetchGetPaginatedDistributors";
import type { IUseFetchUpdateConfigWegDistributorIntegration } from "./interface";

import { fetchUpdateConfigWegDistributorIntegration } from "~api/distributors/weg/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchConfigWegDistributorIntegrationKey =
  "api/distributors/weg/patch";

export function useFetchUpdateConfigWegDistributorIntegration(
  options?: IUseFetchUpdateConfigWegDistributorIntegration,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchUpdateConfigWegDistributorIntegration(payload),
    {
      ...options,
      mutationKey: useFetchConfigWegDistributorIntegrationKey,
      onSuccess: (data, variables, context) => {
        message.success("Configuração da integração com a WEG foi atualizada");
        queryClient.invalidateQueries(
          useFetchGetConfigWegDistributorIntegrationKey,
        );
        queryClient.invalidateQueries(useFetchGetStructureDistributorsKey);
        queryClient.invalidateQueries(useFetchGetPaginatedDistributorsKey);
        queryClient.invalidateQueries(useFetchGetCompanyDistributorsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao configurar: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
