import { useQuery } from "react-query";

import type { IUseFetchGetLeadTimeInsightGroupedByUser } from "./interface";

import { fetchGetLeadTimeInsightGroupedByUser } from "~api/insights/leadTimeInsightGroupedByUser/get";

export const useFetchGetLeadTimeInsightGroupedByUserKey =
  "api/insights/leadTimeInsightGroupedByUser/get";

export function useFetchGetLeadTimeInsightGroupedByUser({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetLeadTimeInsightGroupedByUser) {
  return useQuery(
    [useFetchGetLeadTimeInsightGroupedByUserKey, dependencyArray],
    async () => await fetchGetLeadTimeInsightGroupedByUser(payload),
    options,
  );
}
