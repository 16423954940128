import { api, apiException } from "~services/api";
import {
  DistributorDTOSchema,
  FetchPatchActivateDistributorKitFilterPayloadSchema,
  FetchToggleDistributorsPayloadSchema,
  IFetchPatchActivateDistributorKitFilterPayloadSchema,
  IFetchToggleDistributorsPayloadSchema,
} from "~types/schemas";

export const fetchToggleDistributors = async (
  payload: IFetchToggleDistributorsPayloadSchema,
) => {
  try {
    const { distributorId } =
      FetchToggleDistributorsPayloadSchema.parse(payload);

    await api.patch<void>(`/distributors/${distributorId}`);
  } catch (error) {
    throw apiException(error);
  }
};

export const fetchPatchActivateDistributorKitFilter = async (
  payload: IFetchPatchActivateDistributorKitFilterPayloadSchema,
) => {
  try {
    const { distributorId } =
      FetchPatchActivateDistributorKitFilterPayloadSchema.parse(payload);

    const response = await api.patch(
      `/distributors/${distributorId}/kit-filter`,
    );

    const parsedData = DistributorDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
