import { z } from "zod";

export const FetchGetLeadTimeInsightGroupedByUserPayloadSchema = z.object({
  pipelineId: z
    .number({ message: "Faltando o ID do funil" })
    .int({ message: "O ID do funil deve ser um número inteiro" })
    .gt(0, { message: "O ID do funil deve ser maior do que zero " }),
  ownerId: z
    .number({ message: "ID do responsável deve ser um número" })
    .int({ message: "O ID do responsável deve ser um número inteiro" })
    .gt(0, { message: "O ID do responsável deve ser maior do que zero " })
    .nullish()
    .transform((val) => val ?? undefined),
  startDate: z.string({ message: "Faltando a data de inicio" }), // FORMAT YYYY/MM/DD
  endDate: z.string({ message: "Faltando a data de fim" }), // FORMAT YYYY/MM/DD
});

export interface IFetchGetLeadTimeInsightGroupedByUserPayloadSchema
  extends z.infer<typeof FetchGetLeadTimeInsightGroupedByUserPayloadSchema> {}
