import { useQuery } from "react-query";

import type { IUseFetchGetBusinessUnitUsers } from "./interface";

import { fetchGetBusinessUnitUsers } from "~api/business-units/{id}/list/users/post";

export const useFetchGetBusinessUnitUsersKey =
  "api/business-units/{id}/list/users/post";

export function useFetchGetBusinessUnitUsers({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetBusinessUnitUsers) {
  return useQuery(
    [useFetchGetBusinessUnitUsersKey, dependencyArray],
    async () => await fetchGetBusinessUnitUsers(payload),
    options,
  );
}
