import { useQuery } from "react-query";

import { IUseFetchGetConfigWegDistributorIntegration } from "./interface";

import { fetchGetConfigWegDistributorIntegration } from "~api/distributors/weg/get";

export const useFetchGetConfigWegDistributorIntegrationKey =
  "api/distributors/weg/get";

export function useFetchGetConfigWegDistributorIntegration({
  dependencyArray = [],
  options,
}: IUseFetchGetConfigWegDistributorIntegration) {
  return useQuery(
    [useFetchGetConfigWegDistributorIntegrationKey, dependencyArray],
    async () => await fetchGetConfigWegDistributorIntegration(),
    options,
  );
}
