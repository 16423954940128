import { api, apiException } from "~services/api";
import {
  FetchGetAldoStructureByStructureIdPayloadSchema,
  type IFetchGetAldoStructureByStructureIdPayloadSchema,
  AldoStructureIdentifierSchema,
} from "~types/schemas";

export const fetchGetAldoStructureByStructureId = async (
  payload: IFetchGetAldoStructureByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetAldoStructureByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/equipments/aldo/structure-by-relationship/${structureId}`,
    );

    const parsedData = AldoStructureIdentifierSchema.parse(response.data || {});

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
