import { StepOneFormSchema } from "./StepOneFormSchema";

import { z } from "zod";

export const FetchUpdateProposalStepOnePayloadSchema = StepOneFormSchema.extend(
  {
    customId: z.number().int().gt(0),
  },
);

export interface IFetchUpdateProposalStepOnePayloadSchema
  extends z.infer<typeof FetchUpdateProposalStepOnePayloadSchema> {}
