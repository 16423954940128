import { PersonDefaultFieldDTOSchema } from "./PersonDefaultFieldDTOSchema";

import { z } from "zod";

export const PersonDefaultFieldSettingsDTOSchema = z.object({
  id: z.number().int().gte(0),
  personDefaultField: PersonDefaultFieldDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPersonDefaultFieldSettingsDTOSchema
  extends z.infer<typeof PersonDefaultFieldSettingsDTOSchema> {}
