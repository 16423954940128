import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { PhaseEnum } from "~types/enum";
import { z } from "zod";

export const LowVoltageFormSchema = z.object({
  averageConsumption: z.number(),
  tax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  te: z.number(),
  tusd: z.number(),
  wireBTax: z.number(),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  cosipTaxBefore: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfter: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  inverterTransformerPowerLimitKwp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlyValues: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ILowVoltageFormSchema
  extends z.infer<typeof LowVoltageFormSchema> {}
