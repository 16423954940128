import { api, apiException } from "~services/api";
import {
  type IFetchCreateAdminModulesPayloadSchema,
  FetchCreateAdminModulesPayloadSchema,
  PvModuleDTOSchema,
} from "~types/schemas";

export const fetchCreateAdminModules = async (
  payload: IFetchCreateAdminModulesPayloadSchema,
) => {
  try {
    const body = FetchCreateAdminModulesPayloadSchema.parse(payload);

    const response = await api.post(`/admin/modules`, body);

    const parsedData = PvModuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
