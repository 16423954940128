import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedCostAggregator } from "./interface";

import { fetchGetPaginatedCostAggregator } from "~api/cost-aggregator/pageable/get";

export const useFetchGetPaginatedCostAggregatorKey =
  "api/cost-aggregator/pageable/get";

export function useFetchGetPaginatedCostAggregator({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 5,
  },
}: IUseFetchGetPaginatedCostAggregator) {
  return useQuery(
    [useFetchGetPaginatedCostAggregatorKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedCostAggregator(payload);
    },
    options,
  );
}
