import { StructureTypeDetailsDTOSchema } from "./StructureTypeDetailsDTOSchema";

import {
  StructureFixationEnum,
  StructureSubCategoryEnum,
  StructureTypeEnum,
  StructureUnitSizeEnum,
} from "~types/enum";
import { z } from "zod";

export const StructureTypeDTOSchema = z.object({
  id: z.number().int().gt(0),
  type: StructureTypeEnum.nullish().transform((val) => val ?? undefined),
  subCategory: StructureSubCategoryEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  available: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  belongsToLabor: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  fixation: StructureFixationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  unitSize: StructureUnitSizeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  details: StructureTypeDetailsDTOSchema.nullish(),
});

export interface IStructureTypeDTOSchema
  extends z.infer<typeof StructureTypeDTOSchema> {}
