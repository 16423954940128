import { useMutation } from "react-query";

import { useFetchGetPersonalTokenKey } from "../useFetchGetPersonalToken";
import type { IUseFetchCreatePersonalToken } from "./interface";

import { fetchCreatePersonalToken } from "~api/users/personal-token/new/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePersonalTokenKey =
  "api/users/personal-token/new/post";

export function useFetchCreatePersonalToken({
  options,
}: IUseFetchCreatePersonalToken) {
  const { message } = useAppConfig();

  return useMutation(async () => await fetchCreatePersonalToken(), {
    ...options,
    mutationKey: useFetchCreatePersonalTokenKey,
    onSuccess: (data, variables, context) => {
      message.success("Novo token de API foi gerado");
      queryClient.invalidateQueries(useFetchGetPersonalTokenKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao criar usuário: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
