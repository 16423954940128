import { EquipmentCategoryEnum } from "~types/enum";
import { z } from "zod";

export const GenericProductResponseSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  priceBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  category: EquipmentCategoryEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  otherParams: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  distributor: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IGenericProductResponseSchema
  extends z.infer<typeof GenericProductResponseSchema> {}
