import { colors } from "./colors";

import type { ThemeConfig } from "antd/lib/config-provider/context";

export const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: colors["primary.500"],
    colorLink: colors["primary.500"],
    colorLinkHover: colors["primary.400"],
    colorInfo: colors["primary.500"],
    colorBgContainer: colors.backgroundContainer,
    colorBgLayout: colors.backgroundLayout,
    fontFamily:
      "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Layout: {
      headerBg: colors.backgroundContainer,
      siderBg: colors.backgroundContainer,
      triggerBg: colors.backgroundContainer,
    },
    Menu: {
      itemBg: colors.backgroundContainer,
      subMenuItemBg: colors.backgroundContainer,
    },
  },
};

export const defaultColors = colors;
