import { api, apiException } from "~services/api";
import {
  FetchDeleteStructureTypePayloadSchema,
  IFetchDeleteStructureTypePayloadSchema,
} from "~types/schemas";

export const fetchDeleteStructureType = async (
  payload: IFetchDeleteStructureTypePayloadSchema,
) => {
  try {
    const { structureTypeId } =
      FetchDeleteStructureTypePayloadSchema.parse(payload);

    await api.delete(`/company/structure_type/${structureTypeId}`);
  } catch (error) {
    throw apiException(error);
  }
};
