import { ProposalSectionEnum, ProjectTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetCustomFieldsPayloadSchema = z.object({
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
});

export interface IFetchGetCustomFieldsPayloadSchema
  extends z.infer<typeof FetchGetCustomFieldsPayloadSchema> {}
