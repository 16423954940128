import { api, apiException } from "~services/api";
import { CustomVariableDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetAllCustomVariables = async () => {
  try {
    const response = await api.get("/custom-variable/no-page");

    const parsedData = z
      .array(CustomVariableDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
