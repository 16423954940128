import { z } from "zod";

export const OrgIdSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  address: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  owner_id: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IOrgIdSchema extends z.infer<typeof OrgIdSchema> {}
