import { z } from "zod";
import { api, apiException } from "~services/api";
import { BusinessUnitAccountUserDTOSchema, FetchGetBusinessUnitUsersOutPayloadSchema, IFetchGetBusinessUnitUsersOutPayloadSchema } from "~types/schemas";

export const fetchGetBusinessUnitUsersOut = async (payload: IFetchGetBusinessUnitUsersOutPayloadSchema) => {
  try {
    const { businessUnitId } = FetchGetBusinessUnitUsersOutPayloadSchema.parse(payload);

    const response = await api.post(
      `/business-units/${businessUnitId}/list/users/out`,
    );

    const parsedData = z.array(BusinessUnitAccountUserDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
