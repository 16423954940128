import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema,
  IFetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalDefaultFieldsProfileSettings = async (
  payload: IFetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema,
) => {
  try {
    const { formFieldId, settings } =
      FetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema.parse(
        payload,
      );

    await api.put(`/default-fields/profile-settings/${formFieldId}`, settings);
  } catch (error) {
    throw apiException(error);
  }
};
