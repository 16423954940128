import { api, apiException } from "~services/api";

export type FetchBlockUser = {
  path: {
    id: number;
  };
};

export const fetchBlockUser = async ({ path }: FetchBlockUser) => {
  try {
    await api.patch<void>(`/admin/users/${path.id}/block`);
  } catch (error) {
    throw apiException(error);
  }
};
