import { api, apiException } from "~services/api";
import { DetailedPipelineDTOSchema } from "~types/schemas";

export const fetchGetAllPipelinesDetailed = async () => {
  try {
    const response = await api.get(`/pipeline/all`);

    const parsedData = DetailedPipelineDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
