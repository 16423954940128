import { api, apiException } from "~services/api";
import { PipedriveApiTokenDTOSchema } from "~types/schemas";

export const fetchGetPipedriveApiToken = async () => {
  try {
    const response = await api.get(`/company/pipedrive-api-token`);

    const parsedData = PipedriveApiTokenDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
