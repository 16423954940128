import { ProposalExpirationRuleFormSchema } from "./ProposalExpirationRuleFormSchema";

import { z } from "zod";

export const FetchUpdateProposalExpirationRulePayloadSchema =
  ProposalExpirationRuleFormSchema.extend({
    expirationRuleId: z.number().int().gt(0),
  });

export interface IFetchUpdateProposalExpirationRulePayloadSchema
  extends z.infer<typeof FetchUpdateProposalExpirationRulePayloadSchema> {}
