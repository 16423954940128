import { api, apiException } from "~services/api";
import {
  FetchUpdatePerformanceProfilePayloadSchema,
  IFetchUpdatePerformanceProfilePayloadSchema,
  PerformanceProfileDTOSchema,
} from "~types/schemas";

export const fetchUpdatePerformanceProfile = async (
  payload: IFetchUpdatePerformanceProfilePayloadSchema,
) => {
  try {
    const { performanceProfileId, ...body } =
      FetchUpdatePerformanceProfilePayloadSchema.parse(payload);

    const response = await api.put(
      `/performance-profile/${performanceProfileId}`,
      body,
    );

    const parsedData = PerformanceProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
