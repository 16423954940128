import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetCompanyDistributorsKey } from "../../company/useFetchGetCompanyDistributors";
import { useFetchGetStructureDistributorsKey } from "../../structure-distributor/useFetchGetStructureDistributors";
import { useFetchGetPaginatedDistributorsKey } from "../useFetchGetPaginatedDistributors";
import type { IUseFetchToggleDistributors } from "./interface";

import { fetchToggleDistributors } from "~api/distributors/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchToggleDistributorsKey = "api/distributors/{id}/patch";

export function useFetchToggleDistributors(
  options?: IUseFetchToggleDistributors,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchToggleDistributors(payload),
    {
      ...options,
      mutationKey: useFetchToggleDistributorsKey,
      onSuccess: (data, variables, context) => {
        message.success("Distribuidor atualizado!");
        queryClient.invalidateQueries(useFetchGetPaginatedDistributorsKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        queryClient.invalidateQueries(useFetchGetStructureDistributorsKey);
        queryClient.invalidateQueries(useFetchGetCompanyDistributorsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
