import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedNotificationsPayloadSchema,
  PaginatedNotificationDTOSchema,
  type IFetchGetPaginatedNotificationsPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedNotifications = async (
  payload: IFetchGetPaginatedNotificationsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedNotificationsPayloadSchema.parse(payload);

    const response = await api.get(`/notifications`, { params });

    const parsedData = PaginatedNotificationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
