import { FinancingCalculationTypeEnum } from "~types/enum";
import { z } from "zod";

export const FinancingFormSchema = z
  .object({
    calculationType: FinancingCalculationTypeEnum,
    entryInPercent: z.number(),
    deadlineInMonths: z.number().int(),
    gracePeriodInMonths: z.number().int(),
    taxInPercentPerMonth: z.number(),
    provision: z.string(),
    bankId: z.number().int(),
  })
  .partial();

export interface IFinancingFormSchema
  extends z.infer<typeof FinancingFormSchema> {}
