import { z } from "zod";

export const FetchGetPaginatedModulesPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IFetchGetPaginatedModulesPayloadSchema
  extends z.infer<typeof FetchGetPaginatedModulesPayloadSchema> {}
