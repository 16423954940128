import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldsByPipelineId } from "./interface";

import { fetchGetDealCustomFieldsByPipelineId } from "~api/config/deals/custom-fields/pipeline/{id}/get";

export const useFetchGetDealCustomFieldsByPipelineIdKey =
  "api/config/deals/custom-fields/pipeline/{id}/get";

export function useFetchGetDealCustomFieldsByPipelineId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDealCustomFieldsByPipelineId) {
  return useQuery(
    [useFetchGetDealCustomFieldsByPipelineIdKey, dependencyArray],
    async () => await fetchGetDealCustomFieldsByPipelineId(payload),
    options,
  );
}
