import { api, apiException } from "~services/api";
import {
  FetchSetPersonOwnerPayloadSchema,
  type IFetchSetPersonOwnerPayloadSchema,
} from "~types/schemas";

export const fetchSetPersonOwner = async (
  payload: IFetchSetPersonOwnerPayloadSchema,
) => {
  try {
    const { ownerId, personId } =
      FetchSetPersonOwnerPayloadSchema.parse(payload);

    await api.patch(`/persons/${personId}/owner/${ownerId}`);
  } catch (error) {
    throw apiException(error);
  }
};
