import { AddressFormSchema } from "./AddressFormSchema";

import { z } from "zod";

export const OrganizationFormSchema = z.object({
  name: z.string(),
  cnpj: z
    .string()
    .max(18)
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .max(15)
    .nullish()
    .transform((val) => val ?? undefined),
  ownerId: z.number().int().gt(0),
  address: AddressFormSchema.nullish().transform((val) => val ?? undefined),
  pipedriveId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IOrganizationFormSchema
  extends z.infer<typeof OrganizationFormSchema> {}
