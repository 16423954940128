import { z } from "zod";

export const GlobalHorizontalMeansFormSchema = z
  .object({
    jan: z.number().gte(0),
    feb: z.number().gte(0),
    mar: z.number().gte(0),
    apr: z.number().gte(0),
    may: z.number().gte(0),
    jun: z.number().gte(0),
    jul: z.number().gte(0),
    aug: z.number().gte(0),
    sep: z.number().gte(0),
    oct: z.number().gte(0),
    nov: z.number().gte(0),
    dec: z.number().gte(0),
  })
  .partial();

export interface IGlobalHorizontalMeansFormSchema
  extends z.infer<typeof GlobalHorizontalMeansFormSchema> {}
