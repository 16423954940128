import { MonthlyPerformanceRate } from "./MonthlyPerformanceRate";

import { z } from "zod";

export const FetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema =
  MonthlyPerformanceRate.extend({
    customId: z.number().int().gt(0),
  });

export interface IFetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema
  extends z.infer<
    typeof FetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema
  > {}
