import { api, apiException } from "~services/api";
import { z } from "zod";

export const fetchGetCompanyLogo = async () => {
  try {
    const response = await api.get<string | null | undefined>(`/company/logo`);

    const parsedData = z
      .string()
      .nullish()
      .transform((val) => val ?? "")
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
