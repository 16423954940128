import { SituacaoImovelEnum } from "~types/enum";
import { z } from "zod";

export const EosAddressDTOSchema = z.object({
  cep: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  bairro: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  cidade: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  estado: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  logradouro: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  numero: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  complemento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  metragemQuadradaM2: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  situacaoImovel: SituacaoImovelEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IEosAddressDTOSchema
  extends z.infer<typeof EosAddressDTOSchema> {}
