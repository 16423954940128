import { z } from "zod";

export const PipedriveApiTokenDTOSchema = z.object({
  apiToken: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPipedriveApiTokenDTOSchema
  extends z.infer<typeof PipedriveApiTokenDTOSchema> {}
