import { useMutation } from "react-query";

import { useFetchGetOrganizationByIdKey } from "../useFetchGetOrganizationById";
import { fetchGetPaginatedOrganizationKey } from "../useFetchGetPaginatedOrganization";
import type { IUseFetchBindsPipedriveIdToOrganization } from "./interface";

import { fetchBindsPipedriveIdToOrganization } from "~api/organizations/{id}/link-pipedrive-id/{pipedriveId}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchBindsPipedriveIdToOrganizationKey =
  "api/organizations/{id}/link-pipedrive-id/{pipedriveId}/patch";

export function useFetchBindsPipedriveIdToOrganization({
  options,
}: IUseFetchBindsPipedriveIdToOrganization) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchBindsPipedriveIdToOrganization(payload),
    {
      ...options,
      mutationKey: useFetchBindsPipedriveIdToOrganizationKey,
      onSuccess: (data, variables, context) => {
        message.success("Vinculada com sucesso!");
        queryClient.invalidateQueries(fetchGetPaginatedOrganizationKey);
        queryClient.invalidateQueries(useFetchGetOrganizationByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao vincular o ID do pipedrive à organização: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
