import { useQuery } from "react-query";

import type { IUseFetchGetGetProposalByCustomId } from "./interface";

import { fetchGetGetProposalByCustomId } from "~api/proposal/{customId}/get";

export const useFetchGetGetProposalByCustomIdKey =
  "api/proposal/{customId}/get";

export function useFetchGetGetProposalByCustomId({
  options,
  dependencyArray,
  payload = {
    customId: 0,
  },
}: IUseFetchGetGetProposalByCustomId) {
  return useQuery(
    [useFetchGetGetProposalByCustomIdKey, dependencyArray],
    async () => await fetchGetGetProposalByCustomId(payload),
    options,
  );
}
