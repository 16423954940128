import { CitySchema } from "./CitySchema";
import { CountrySchema } from "./CountrySchema";
import { ProvinceSchema } from "./ProvinceSchema";

import { z } from "zod";

export const AddressSchema = z.object({
  id: z.number().int().optional(),
  identifier: z.string().optional(),
  street: z.string().optional(),
  district: z.string().optional(),
  number: z.string().optional(),
  zipCode: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  formattedAddress: z.string().optional(),
  city: CitySchema,
  country: CountrySchema.optional(),
  province: ProvinceSchema.optional(),
  concatLatLong: z.string().optional(),
  concatAddress: z.string().optional(),
});

export interface IAddressSchema extends z.infer<typeof AddressSchema> {}
