import { SimpleClientDTOSchema } from "./SimpleClientDTOSchema";
import { SimpleOrganizationDTOSchema } from "./SimpleOrganizationDTOSchema";
import { SimpleUserAccountDTOSchema } from "./SimpleUserAccountDTOSchema";

import { z } from "zod";

export const SimpleDealDTOSchema = z.object({
  id: z.number().int().gt(0),
  displayOrder: z.number().int(),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  companyId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pipelineId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pipelineStageId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  person: SimpleClientDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: SimpleOrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  owner: SimpleUserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ISimpleDealDTOSchema
  extends z.infer<typeof SimpleDealDTOSchema> {}
