import { z } from "zod";

export const CreateAdditionalCostTaxFormSchema = z.object({
  rate: z.number(),
  identifier: z.string().optional(),
  name: z.string(),
  active: z.boolean(),
});

export interface ICreateAdditionalCostTaxFormSchema
  extends z.infer<typeof CreateAdditionalCostTaxFormSchema> {}
