import { ElectricUtilityProfileDetailDTOSchema } from "./ElectricUtilityProfileDetailDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedElectricUtilityProfileDetailDTOSchema = PaginationSchema(
  ElectricUtilityProfileDetailDTOSchema,
);

export interface IPaginatedElectricUtilityProfileDetailDTOSchema
  extends z.infer<typeof PaginatedElectricUtilityProfileDetailDTOSchema> {}
