import { EnvSchema } from "~types/schemas";

function getProductionVars() {
  return {
    AMBIENT: "PRODUCTION",
    APP_VERSION: process.env.NEXT_PUBLIC_PRODUCTION_APP_VERSION,
    PIPEDRIVE_URL: process.env.NEXT_PUBLIC_PRODUCTION_PIPEDRIVE_URL,
    PIPEDRIVE_CLIENT_ID: process.env.NEXT_PUBLIC_PRODUCTION_PIPEDRIVE_CLIENT_ID,
    PIPEDRIVE_REDIRECT_URI:
      process.env.NEXT_PUBLIC_PRODUCTION_PIPEDRIVE_REDIRECT_URI,
    THROW_ERROR_IN_SLACK:
      process.env.NEXT_PUBLIC_PRODUCTION_THROW_ERROR_IN_SLACK,
    API_URL: process.env.NEXT_PUBLIC_PRODUCTION_API_URL,
    WEB_SOCKET_URL: process.env.NEXT_PUBLIC_PRODUCTION_WEB_SOCKET_URL,
    ACTIVE_PWA: process.env.NEXT_PUBLIC_PRODUCTION_ACTIVE_PWA,
    GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_PRODUCTION_GOOGLE_MAPS_API_KEY,
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN:
      process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_APP_ID,
    FIREBASE_DATABASE_URL:
      process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_DATABASE_URL,
    FIREBASE_MEASUREMENT_ID:
      process.env.NEXT_PUBLIC_PRODUCTION_FIREBASE_MEASUREMENT_ID,
    CYPRESS_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_PRODUCTION_CYPRESS_USER_SESSION_TOKEN,
    CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_PRODUCTION_CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN,
    CYPRESS_ADMIN_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_PRODUCTION_CYPRESS_ADMIN_USER_SESSION_TOKEN,
    CYPRESS_USER_EMAIL: process.env.NEXT_PUBLIC_PRODUCTION_CYPRESS_USER_EMAIL,
    CYPRESS_USER_PASSWORD:
      process.env.NEXT_PUBLIC_PRODUCTION_CYPRESS_USER_PASSWORD,
  };
}

function getHomologVars() {
  return {
    AMBIENT: "HOMOLOG",
    APP_VERSION: process.env.NEXT_PUBLIC_HOMOLOG_APP_VERSION,
    PIPEDRIVE_URL: process.env.NEXT_PUBLIC_HOMOLOG_PIPEDRIVE_URL,
    PIPEDRIVE_CLIENT_ID: process.env.NEXT_PUBLIC_HOMOLOG_PIPEDRIVE_CLIENT_ID,
    PIPEDRIVE_REDIRECT_URI:
      process.env.NEXT_PUBLIC_HOMOLOG_PIPEDRIVE_REDIRECT_URI,
    THROW_ERROR_IN_SLACK: process.env.NEXT_PUBLIC_HOMOLOG_THROW_ERROR_IN_SLACK,
    API_URL: process.env.NEXT_PUBLIC_HOMOLOG_API_URL,
    WEB_SOCKET_URL: process.env.NEXT_PUBLIC_HOMOLOG_WEB_SOCKET_URL,
    ACTIVE_PWA: process.env.NEXT_PUBLIC_HOMOLOG_ACTIVE_PWA,
    GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_HOMOLOG_GOOGLE_MAPS_API_KEY,
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_APP_ID,
    FIREBASE_DATABASE_URL:
      process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_DATABASE_URL,
    FIREBASE_MEASUREMENT_ID:
      process.env.NEXT_PUBLIC_HOMOLOG_FIREBASE_MEASUREMENT_ID,
    CYPRESS_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_HOMOLOG_CYPRESS_USER_SESSION_TOKEN,
    CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_HOMOLOG_CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN,
    CYPRESS_ADMIN_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_HOMOLOG_CYPRESS_ADMIN_USER_SESSION_TOKEN,
    CYPRESS_USER_EMAIL: process.env.NEXT_PUBLIC_HOMOLOG_CYPRESS_USER_EMAIL,
    CYPRESS_USER_PASSWORD:
      process.env.NEXT_PUBLIC_HOMOLOG_CYPRESS_USER_PASSWORD,
  };
}

function getDevelopmentVars() {
  return {
    AMBIENT: "DEVELOPMENT",
    APP_VERSION: process.env.NEXT_PUBLIC_DEVELOPMENT_APP_VERSION,
    PIPEDRIVE_URL: process.env.NEXT_PUBLIC_DEVELOPMENT_PIPEDRIVE_URL,
    PIPEDRIVE_CLIENT_ID:
      process.env.NEXT_PUBLIC_DEVELOPMENT_PIPEDRIVE_CLIENT_ID,
    PIPEDRIVE_REDIRECT_URI:
      process.env.NEXT_PUBLIC_DEVELOPMENT_PIPEDRIVE_REDIRECT_URI,
    THROW_ERROR_IN_SLACK:
      process.env.NEXT_PUBLIC_DEVELOPMENT_THROW_ERROR_IN_SLACK,
    API_URL: process.env.NEXT_PUBLIC_DEVELOPMENT_API_URL,
    WEB_SOCKET_URL: process.env.NEXT_PUBLIC_DEVELOPMENT_WEB_SOCKET_URL,
    ACTIVE_PWA: process.env.NEXT_PUBLIC_DEVELOPMENT_ACTIVE_PWA,
    GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_MAPS_API_KEY,
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_APP_ID,
    FIREBASE_DATABASE_URL:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_DATABASE_URL,
    FIREBASE_MEASUREMENT_ID:
      process.env.NEXT_PUBLIC_DEVELOPMENT_FIREBASE_MEASUREMENT_ID,
    CYPRESS_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_DEVELOPMENT_CYPRESS_USER_SESSION_TOKEN,
    CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_DEVELOPMENT_CYPRESS_PIPEDRIVE_USER_SESSION_TOKEN,
    CYPRESS_ADMIN_USER_SESSION_TOKEN:
      process.env.NEXT_PUBLIC_DEVELOPMENT_CYPRESS_ADMIN_USER_SESSION_TOKEN,
    CYPRESS_USER_EMAIL: process.env.NEXT_PUBLIC_DEVELOPMENT_CYPRESS_USER_EMAIL,
    CYPRESS_USER_PASSWORD:
      process.env.NEXT_PUBLIC_DEVELOPMENT_CYPRESS_USER_PASSWORD,
  };
}

function getEnvVars() {
  const AMBIENT = process.env.NEXT_PUBLIC_AMBIENT;

  switch (AMBIENT) {
    case "PRODUCTION":
      return getProductionVars();

    case "HOMOLOG":
      return getHomologVars();

    case "DEVELOPMENT":
      return getDevelopmentVars();
  }
}

export const ENV = EnvSchema.parse(getEnvVars());
