import { TariffPremiseFormSchema } from "./TariffPremiseFormSchema";

import { z } from "zod";

export const FetchUpdatePremisesTariffPayloadSchema =
  TariffPremiseFormSchema.extend({
    id: z.number().int().gt(0),
  });

export interface IFetchUpdatePremisesTariffPayloadSchema
  extends z.infer<typeof FetchUpdatePremisesTariffPayloadSchema> {}
