import { PerformanceProfileFormSchema } from "./PerformanceProfileFormSchema";

import { z } from "zod";

export const FetchUpdatePerformanceProfilePayloadSchema =
  PerformanceProfileFormSchema.extend({
    performanceProfileId: z.number().int().gt(0),
  });

export interface IFetchUpdatePerformanceProfilePayloadSchema
  extends z.infer<typeof FetchUpdatePerformanceProfilePayloadSchema> {}
