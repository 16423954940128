import { DealHistoryDTOSchema } from "./DealHistoryDTOSchema";
import { HistoryLogSchema } from "./HistoryLogSchema";
import { OrganizationHistoryDTOSchema } from "./OrganizationHistoryDTOSchema";
import { PersonHistoryDTOSchema } from "./PersonHistoryDTOSchema";
import { UserAccountHistoryDTOSchema } from "./UserAccountHistoryDTOSchema";

import { z } from "zod";

export const HistoryDTOSchema = z.object({
  id: z.number().int().gt(0),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  log: HistoryLogSchema,
  userAccount: UserAccountHistoryDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  person: PersonHistoryDTOSchema.nullish().transform((val) => val ?? undefined),
  deal: DealHistoryDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: OrganizationHistoryDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IHistoryDTOSchema extends z.infer<typeof HistoryDTOSchema> {}
