import { useMutation } from "react-query";

import { useFetchGetPaginatedBusinessUnitsKey } from "../useFetchGetPaginatedBusinessUnits";
import { useFetchGetUserBusinessUnitsKey } from "../useFetchGetUserBusinessUnits";
import type { IUseFetchActiveBusinessUnit } from "./interface";

import { fetchActivateBusinessUnit } from "~api/business-units/activate/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchActiveBusinessUnitKey = "api/business-units/{id}/delete";

export function useFetchActiveBusinessUnit(
  options?: IUseFetchActiveBusinessUnit,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchActivateBusinessUnit(payload),
    {
      ...options,
      mutationKey: useFetchActiveBusinessUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidade ativada!");
        queryClient.invalidateQueries(useFetchGetPaginatedBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
