import { z } from "zod";

export const PhotovoltaicSystemFormSchema = z.object({
  moduleDeprecationFirstYear: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  moduleDeprecationOtherYears: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  ghiSystemId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPhotovoltaicSystemFormSchema
  extends z.infer<typeof PhotovoltaicSystemFormSchema> {}
