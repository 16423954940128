import { useQuery } from "react-query";

import type { IUseFetchGetAldoStructures } from "./interface";

import { fetchGetAldoStructures } from "~api/equipments/aldo/structures/get";

export const useFetchGetAldoStructuresKey =
  "api/equipments/aldo/structures/get";

export function useFetchGetAldoStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetAldoStructures) {
  return useQuery(
    [useFetchGetAldoStructuresKey, dependencyArray],
    async () => await fetchGetAldoStructures(),
    options,
  );
}
