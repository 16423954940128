import { api, apiException } from "~services/api";
import {
  FetchUpdatePremisesPipedrivePayloadSchema,
  IFetchUpdatePremisesPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchUpdatePremisesPipedrive = async (
  payload: IFetchUpdatePremisesPipedrivePayloadSchema,
) => {
  try {
    const body = FetchUpdatePremisesPipedrivePayloadSchema.parse(payload);
    await api.patch<void>(`/premises/pipedrive`, body);
  } catch (error) {
    throw apiException(error);
  }
};
