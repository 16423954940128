import { CreateProjectPaymentSimulationOptionFormSchema } from "./CreateProjectPaymentSimulationOptionFormSchema";

import { z } from "zod";

export const FetchCreateManySimulationOptionPayloadSchema = z
  .array(CreateProjectPaymentSimulationOptionFormSchema)
  .nullish()
  .transform((val) => val ?? []);

export interface IFetchCreateManySimulationOptionPayloadSchema
  extends z.infer<typeof FetchCreateManySimulationOptionPayloadSchema> {}
