import { ProjectItemKitFormSchema } from "./ProjectItemKitFormSchema";
import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";

import { PhaseEnum, VoltageEnum } from "~types/enum";
import { z } from "zod";

export const ProjectEquipmentKitFormSchema = z
  .object({
    supplierName: z.string(),
    structureType: StructureTypeDTOSchema,
    code: z.number().int(),
    name: z.string(),
    fullPower: z.number(),
    minArea: z.number(),
    totalValue: z.number(),
    inverterBrand: z.string(),
    moduleBrand: z.string(),
    inverterPower: z.number(),
    outputVoltage: VoltageEnum,
    inverterMaxPower: z.number(),
    sourceKitId: z.number().int(),
    inverterPhase: PhaseEnum,
    items: z.array(ProjectItemKitFormSchema),
  })
  .partial();

export interface IProjectEquipmentKitFormSchema
  extends z.infer<typeof ProjectEquipmentKitFormSchema> {}
