import { useQuery } from "react-query";

import type { IUseFetchGetBusinessUnitUsersOut } from "./interface";

import { fetchGetBusinessUnitUsersOut } from "~api/business-units/{id}/list/users/out/post";

export const useFetchGetBusinessUnitUsersOutKey =
  "api/business-units/{id}/list/users/out/post";

export function useFetchGetBusinessUnitUsersOut({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetBusinessUnitUsersOut) {
  return useQuery(
    [useFetchGetBusinessUnitUsersOutKey, dependencyArray],
    async () => await fetchGetBusinessUnitUsersOut(payload),
    options,
  );
}
