import { AddressSchema } from "./AddressSchema";

import { z } from "zod";

export const CompanySchema = z
  .object({
    id: z.number().int(),
    idPipedrive: z.number().int(),
    name: z.string(),
    companyDomain: z.string(),
    customIdPrefix: z.string(),
    testPeriodStartDate: z.string(),
    testPeriodEndDate: z.string(),
    uuid: z.string().uuid(),
    roundUpProjectValueForCustomer: z.boolean(),
    address: z.array(AddressSchema),
    pipedriveIntegration: z.boolean(),
  })
  .partial();

export interface ICompanySchema extends z.infer<typeof CompanySchema> {}
