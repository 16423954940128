import { z } from "zod";

export const RuleExpressionFormSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  expression: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IRuleExpressionFormSchema
  extends z.infer<typeof RuleExpressionFormSchema> {}
