import { CityDTOSchema } from "./CityDTOSchema";
import { CountryDTOSchema } from "./CountryDTOSchema";
import { ProvinceDTOSchema } from "./ProvinceDTOSchema";

import { z } from "zod";

export const AddressFormSchema = z
  .object({
    identifier: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    street: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    district: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    number: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    zipCode: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    city: CityDTOSchema.pick({ name: true }),
    province: ProvinceDTOSchema.pick({ name: true, uf: true }).partial(),
    country: CountryDTOSchema.pick({ name: true })
      .nullish()
      .transform((val) => val ?? undefined),
    latitude: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    longitude: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    formattedAddress: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    note: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface IAddressFormSchema extends z.infer<typeof AddressFormSchema> {}
