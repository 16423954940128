import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedTrackingPayloadSchema,
  IFetchGetPaginatedTrackingPayloadSchema,
  PaginatedTrackingDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedTracking = async (
  payload: IFetchGetPaginatedTrackingPayloadSchema,
) => {
  try {
    const { trackingId, ...params } =
      FetchGetPaginatedTrackingPayloadSchema.parse(payload);
    const response = await api.get(`/tracking/custom-id/${trackingId}`, {
      params,
    });

    const parsedData = PaginatedTrackingDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
