import { useQuery } from "react-query";

import type { IUseFetchGetOrganizationDealsCountByOrganizationId } from "./interface";

import { fetchGetOrganizationDealsCountByOrganizationId } from "~api/organizations/{id}/deals/count/get";

export const useFetchGetOrganizationDealsCountByOrganizationIdKey =
  "api/persons/{id}/deals/count/get";

export function useFetchGetOrganizationDealsCountByOrganizationId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetOrganizationDealsCountByOrganizationId) {
  return useQuery(
    [useFetchGetOrganizationDealsCountByOrganizationIdKey, dependencyArray],
    async () => await fetchGetOrganizationDealsCountByOrganizationId(payload),
    options,
  );
}
