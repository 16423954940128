import { z } from "zod";

export const FetchGetPaginatedProductModuleManufacturersPayloadSchema =
  z.object({
    page: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => val ?? 0),
    size: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => (!val ? 100 : val)),
  });

export interface IFetchGetPaginatedProductModuleManufacturersPayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedProductModuleManufacturersPayloadSchema
  > {}
