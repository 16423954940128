import { FileTypeEnum, HistoryTypeEnum, LogActionTypeEnum } from "~types/enum";
import { z } from "zod";

const AttachedFileSchema = z.object({
  fileName: z.string(),
  fileType: FileTypeEnum,
  fileId: z.number().int().gt(0),
});
const UserSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});
const PersonSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});
const DealSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});
const OrganizationSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});
const LogSchema = z.object({
  entityId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  entityCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  entityType: z.string(),
  entityAction: LogActionTypeEnum,
  historyType: HistoryTypeEnum,
  title: z.string(),
  attachedFiles: z
    .array(AttachedFileSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export const HistoryDealDTOSchema = z.object({
  id: z.number().int().gt(0),
  createdAt: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: "Invalid date format",
  }),
  log: LogSchema,
  userAccount: UserSchema.nullish().transform((val) => val ?? null),
  person: PersonSchema.nullish().transform((val) => val ?? null),
  deal: DealSchema.nullish().transform((val) => val ?? null),
  organization: OrganizationSchema.nullish().transform((val) => val ?? null),
});

export interface IHistoryDealDTOSchema
  extends z.infer<typeof HistoryDealDTOSchema> {}
