import { api, apiException } from "~services/api";
import {
  FetchGetProposalPDFFilePayloadSchema,
  IFetchGetProposalPDFFilePayloadSchema,
} from "~types/schemas";
import { FilePdfResponseSchema } from "~types/schemas/FilePdfResponseSchema";

export const fetchGetProposalPDFFile = async (
  payload: IFetchGetProposalPDFFilePayloadSchema,
) => {
  try {
    const { id } = FetchGetProposalPDFFilePayloadSchema.parse(payload);

    const response = await api.get(`/proposal/file/${id}`);

    const parsedData = FilePdfResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
