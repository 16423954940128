import { api, apiException } from "~services/api";
import {
  FetchMoveDealInPipelinePayloadSchema,
  type IFetchMoveDealInPipelinePayloadSchema,
} from "~types/schemas";

export const fetchMoveDealInPipeline = async (
  payload: IFetchMoveDealInPipelinePayloadSchema,
) => {
  try {
    const { dealId, ...body } =
      FetchMoveDealInPipelinePayloadSchema.parse(payload);

    await api.patch(`/deal/${dealId}/move`, body);
  } catch (error) {
    throw apiException(error);
  }
};
