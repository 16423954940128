import { z } from "zod";

export const FetchGetPaginatedConcessionairesProfilesByIdPayloadSchema =
  z.object({
    concessionaireId: z.number().int().gt(0),
    page: z.number().int().gte(0).default(0),
    size: z.number().int().gte(0).default(10),
  });

export interface IFetchGetPaginatedConcessionairesProfilesByIdPayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedConcessionairesProfilesByIdPayloadSchema
  > {}
