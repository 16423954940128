import { api, apiException } from "~services/api";
import { BlobSchema } from "~types/schemas";

export const fetchGetExcelExport = async () => {
  try {
    const response = await api.get(`product/export`, {
      responseType: "blob",
    });

    const parsedData = BlobSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
