import { useMutation } from "react-query";

import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "../useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "../useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetRemainingPercentageKey } from "../useFetchGetRemainingPercentage";
import type { IUseFetchCreateComplementaryItemsOfSettings } from "./interface";

import { fetchSaveAdvancedComplementaryItems } from "~api/additional-costs/advanced/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateComplementaryItemsOfSettingsKey =
  "api/additional-costs/advanced/post";

export function useFetchCreateComplementaryItemsOfSettings({
  options,
}: IUseFetchCreateComplementaryItemsOfSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchSaveAdvancedComplementaryItems(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateComplementaryItemsOfSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Item foi criado");
        queryClient.invalidateQueries(
          useFetchGetPaginatedComplementaryItemsOfSettingsKey,
        );
        queryClient.invalidateQueries(
          useFetchGetPaginatedAdditionalCostsAuxVariableKey,
        );
        queryClient.invalidateQueries(useFetchGetRemainingPercentageKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
