import { z } from "zod";

export const FetchMoveDealInPipelinePayloadSchema = z.object({
  dealId: z.number().int().gt(0),
  pipelineStageId: z.number().int().gt(0),
  position: z.number().int().gt(0),
});

export interface IFetchMoveDealInPipelinePayloadSchema
  extends z.infer<typeof FetchMoveDealInPipelinePayloadSchema> {}
