import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const UpdateDealCustomFieldFormSchema = z.object({
  identifier: z
    .string()
    .optional()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  type: FieldTypeEnum.nullish().transform((val) => val ?? undefined),
  pipelinesId: z
    .number()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  requiredInPipelinesStagesId: z
    .number()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  groupId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IUpdateDealCustomFieldFormSchema
  extends z.infer<typeof UpdateDealCustomFieldFormSchema> {}
