import { useMutation } from "react-query";

import { useFetchGetConcessionairesWithAssociatedProfileKey } from "../../electric-utility/useFetchGetConcessionairesWithAssociatedProfile";
import { useFetchGetPaginatedConcessionaireProfilesKey } from "../useFetchGetPaginatedConcessionaireProfilesById";
import type { IUseFetchDeleteConcessionaireProfile } from "./interface";

import { fetchDeleteConcessionaireProfile } from "~api/electric-utility-profile/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteConcessionaireProfileKey =
  "api/electric-utility-profile/{id}/delete";

export function useFetchDeleteConcessionaireProfile({
  options,
}: IUseFetchDeleteConcessionaireProfile) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      const response = await fetchDeleteConcessionaireProfile(payload);
      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchDeleteConcessionaireProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil de concessionária foi removida");
        queryClient.invalidateQueries(
          useFetchGetPaginatedConcessionaireProfilesKey,
        );
        queryClient.invalidateQueries(
          useFetchGetConcessionairesWithAssociatedProfileKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
