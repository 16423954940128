import { api, apiException } from "~services/api";
import {
  DealDTOSchema,
  FetchSearchDealPayloadSchema,
  type IFetchSearchDealPayloadSchema,
} from "~types/schemas";

export const fetchSearchDeal = async (
  payload: IFetchSearchDealPayloadSchema,
) => {
  try {
    const query = FetchSearchDealPayloadSchema.parse(payload);

    const response = await api.get("/deal/auto-complete", {
      params: query,
    });

    const parsedData = DealDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
