import { api, apiException } from "~services/api";
import {
  FetchGetOrganizationByIdPayloadSchema,
  type IFetchGetOrganizationByIdPayloadSchema,
  OrganizationDTOSchema,
} from "~types/schemas";

export const fetchGetOrganizationById = async (
  payload: IFetchGetOrganizationByIdPayloadSchema,
) => {
  try {
    const { organizationId, ...body } =
      FetchGetOrganizationByIdPayloadSchema.parse(payload);

    const response = await api.get(`/organizations/${organizationId}`, body);

    const parsedData = OrganizationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
