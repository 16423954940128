import { useMutation } from "react-query";

import { fetchGetPaginatedNotificationsKey } from "../useFetchGetPaginatedNotifications";
import { IUseFetchSetNotificationRead } from "./interface";

import { fetchSetNotificationRead } from "~api/notifications/{id}/read/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetNotificationReadKey =
  "api/notifications/{id}/read/patch";

export function useFetchSetNotificationRead({
  options,
}: IUseFetchSetNotificationRead) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchSetNotificationRead(payload),
    {
      ...options,
      mutationKey: useFetchSetNotificationReadKey,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(fetchGetPaginatedNotificationsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao marcar mensagem como lida: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
