import { api, apiException } from "~services/api";
import type { Page } from "~types/api/Page";
import type { PerformanceProfileDTO } from "~types/api/PerformanceProfileDTO";
import {
  FetchGetPaginatedPerformanceProfilesPayloadSchema,
  IFetchGetPaginatedPerformanceProfilesPayloadSchema,
  PaginatedPerformanceProfileDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedPerformanceProfiles = async (
  payload: IFetchGetPaginatedPerformanceProfilesPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedPerformanceProfilesPayloadSchema.parse(payload);

    const response = await api.get<Page<PerformanceProfileDTO>>(
      `/performance-profile/page`,
      {
        params,
      },
    );

    const parsedData = PaginatedPerformanceProfileDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
