import { EosClientResponseSchema } from "./EosClientResponseSchema";

import { z } from "zod";

export const EosProposalListResponseSchema = z.object({
  data: z.array(EosClientResponseSchema).nullish().default([]),
});

export interface IEosProposalListResponseSchema
  extends z.infer<typeof EosProposalListResponseSchema> {}
