import { api, apiException } from "~services/api";
import {
  type IFetchCreateAdminInvertersPayloadSchema,
  FetchCreateAdminInvertersPayloadSchema,
  InverterDTOSchema,
} from "~types/schemas";

export const fetchCreateAdminInverters = async (
  payload: IFetchCreateAdminInvertersPayloadSchema,
) => {
  try {
    const body = FetchCreateAdminInvertersPayloadSchema.parse(payload);

    const response = await api.post(`/admin/inverters`, body);

    const parsedData = InverterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
