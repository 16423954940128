import { DiscountFormSchema } from "./DiscountFormSchema";

import { z } from "zod";

export const FetchUpdateDiscountPayloadSchema = DiscountFormSchema.extend({
  discountId: z.number().int().gt(0),
});

export interface IFetchUpdateDiscountPayloadSchema
  extends z.infer<typeof FetchUpdateDiscountPayloadSchema> {}
