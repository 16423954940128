import { EosAddressDTOSchema } from "./EosAddressDTOSchema";
import { EosOrganizationSchema } from "./EosOrganizationSchema";

import { GuarantorNatureOccupationEnum, TypeEosProjectEnum } from "~types/enum";
import { z } from "zod";

export const CreateProposalForOrganizationRequestSchema = z.object({
  tipoProjeto: TypeEosProjectEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  potenciaInstaladaSugerida: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  mediaContaEnergia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  autorizacaoScr: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  valorTotal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  kitFotovoltaico: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maoObra: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  entrada: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  carencia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tempoFinanciamento: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteTelefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaNome: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaCpf: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaDataNascimento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaEmail: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaTelefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaCargoFuncao: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaPatrimonio: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaNaturezaOcupacao: GuarantorNatureOccupationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  avalistaTempoEmpresa: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaCep: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaLogradouro: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaBairro: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaCidade: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaEstado: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  geracaoMediaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaNumero: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaComplemento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaRendaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaOutrasRendas: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  avalistaOutrasRendasDescricao: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteCnpj: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteNomeFantasia: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
  cliente: EosOrganizationSchema.nullish().transform((val) => val ?? undefined),
  comSeguro: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface ICreateProposalForOrganizationRequestSchema
  extends z.infer<typeof CreateProposalForOrganizationRequestSchema> {}
