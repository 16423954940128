import { api, apiException } from "~services/api";
import {
  StepTwoAutocompleteDTO,
  FetchGetStepTwoAutocompletePayloadSchema,
  IFetchGetStepTwoAutocompletePayloadSchema,
} from "~types/schemas";

export const fetchGetStepTwoAutoComplete = async (
  payload?: IFetchGetStepTwoAutocompletePayloadSchema,
) => {
  try {
    const params = FetchGetStepTwoAutocompletePayloadSchema.parse(
      payload || { potency: null },
    );

    const response = await api.get(`/projects/step_two/auto-complete`, {
      params,
    });

    const parsedData = StepTwoAutocompleteDTO.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
