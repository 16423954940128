import { MentionOriginEnum, NotificationStatusEnum } from "~types/enum";
import { z } from "zod";

export const NotificationDTOSchema = z.object({
  id: z.number().int().gt(0),
  status: NotificationStatusEnum,
  text: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  userName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  userAvatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  mentionedName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  originId: z.number().int().gt(0),
  origin: MentionOriginEnum,
  originDescription: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  createdAt: z.string(),
});

export interface INotificationDTOSchema
  extends z.infer<typeof NotificationDTOSchema> {}
