import { useQuery } from "react-query";

import type { IUseFetchGetAllStructures } from "./interface";

import { fetchGetAllStructures } from "~api/structures/all/get";

export const useFetchGetAllStructuresKey = "api/structures/all/get";

export function useFetchGetAllStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetAllStructures) {
  return useQuery(
    [useFetchGetAllStructuresKey, dependencyArray],
    async () => await fetchGetAllStructures(),
    options,
  );
}
