import { api, apiException } from "~services/api";
import {
  FetchUpdatePersonCustomFieldOrderFormPayloadSchema,
  IFetchUpdatePersonCustomFieldOrderFormPayloadSchema,
  PersonCustomFieldDtoSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchUpdatePersonCustomFieldsOrder = async (
  payload: IFetchUpdatePersonCustomFieldOrderFormPayloadSchema,
) => {
  try {
    const body =
      FetchUpdatePersonCustomFieldOrderFormPayloadSchema.parse(payload);

    const response = await api.put(
      `/config/personCustomFields/fieldOrder`,
      body,
    );

    const parsedData = z
      .array(PersonCustomFieldDtoSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
