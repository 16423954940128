import { useMutation } from "react-query";

import { useFetchGetAllPerformanceProfilesKey } from "../useFetchGetAllPerformanceProfiles";
import { useFetchGetPaginatedPerformanceProfileKey } from "../useFetchGetPaginatedPerformanceProfile";
import type { IUseFetchSetOrRemovePerformanceProfileDefault } from "./interface";

import { fetchSetOrRemovePerformanceProfileDefault } from "~api/performance-profiles/default/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetOrRemovePerformanceProfileDefaultKey =
  "api/performance-profiles/default/{id}/patch";

export function useFetchSetOrRemovePerformanceProfileDefault({
  options,
}: IUseFetchSetOrRemovePerformanceProfileDefault) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchSetOrRemovePerformanceProfileDefault(payload),
    {
      ...options,
      mutationKey: useFetchSetOrRemovePerformanceProfileDefaultKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil padrão atualizado!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedPerformanceProfileKey,
        );
        queryClient.invalidateQueries(useFetchGetAllPerformanceProfilesKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao atualizar perfil de performance: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
