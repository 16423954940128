import { api, apiException } from "~services/api";
import {
  ProfileSchema,
  FetchUpdateProfilePayloadSchema,
  IFetchUpdateProfilePayloadSchema,
} from "~types/schemas";

export const fetchUpdateProfile = async (
  payload: IFetchUpdateProfilePayloadSchema,
) => {
  try {
    const { profileId, ...body } =
      FetchUpdateProfilePayloadSchema.parse(payload);

    const response = await api.put(`/profiles/${profileId}`, body);

    const parsedData = ProfileSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
