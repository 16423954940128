import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const CreatePersonCustomFieldFormSchema = z.object({
  identifier: z
    .string()
    .optional()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z.string(),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z.boolean(),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  enabled: z.boolean(),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  businessUnits: z
    .array(z.number())
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICreatePersonCustomFieldFormSchema
  extends z.infer<typeof CreatePersonCustomFieldFormSchema> {}
