import { useMutation } from "react-query";

import { useFetchGetAllProfilesKey } from "../useFetchGetPaginatedProfiles";
import type { IUseFetchCreateProfile } from "./interface";

import { fetchCreateProfile } from "~api/profiles/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateProfileKey = "api/profiles/post";

export function useFetchCreateProfile({ options }: IUseFetchCreateProfile) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchCreateProfile(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil foi cadastrado!");
        queryClient.invalidateQueries(useFetchGetAllProfilesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao cadastrar perfil: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
