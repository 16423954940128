import { FortlevStructureTypeEnum } from "~types/enum";
import { z } from "zod";

export const FortlevEquipmentStructureDTOSchema = z.object({
  structureType: FortlevStructureTypeEnum,
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFortlevEquipmentStructureDTOSchema
  extends z.infer<typeof FortlevEquipmentStructureDTOSchema> {}
