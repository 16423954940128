import { useMutation } from "react-query";

import type {
  IUseFetchProposalFinish,
  UseFetchProposalFinishResultType,
  UseFetchProposalFinishErrorType,
  UseFetchProposalFinishPayloadType,
} from "./interface";

import { fetchProposalFinish } from "~api/projects/step_five/finish/{id}/get";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchProposalFinishKey =
  "api/projects/step_five/finish/{id}/get";

export function useFetchProposalFinish({ options }: IUseFetchProposalFinish) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchProposalFinishResultType,
    UseFetchProposalFinishErrorType,
    UseFetchProposalFinishPayloadType,
    unknown
  >(
    async (payload: UseFetchProposalFinishPayloadType) => {
      await fetchProposalFinish({
        path: {
          id: payload.id,
        },
      });
    },
    {
      ...options,
      mutationKey: useFetchProposalFinishKey,
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
