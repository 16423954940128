import { useQuery } from "react-query";

import { IUseFetchGetPaginatedUsageLogs } from "./interface";

import { fetchGetPaginatedUsageLogs } from "~api/usage-logs/get";

export const UseFetchGetPaginatedUsageLogsKey = "api/usage-logs/get";

export function useFetchGetPaginatedUsageLogs({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedUsageLogs) {
  return useQuery(
    [UseFetchGetPaginatedUsageLogsKey, dependencyArray],
    async () => await fetchGetPaginatedUsageLogs(payload),
    options,
  );
}
