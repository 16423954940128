import { TaxTypeEnum, VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetConcessionaireProfilesByVoltagePayloadSchema = z.object({
  electricUtilityId: z.number().int(),
  voltageType: VoltageTypeEnum,
  modalidade: TaxTypeEnum.nullish().transform((val) => val ?? undefined),
});

export interface IFetchGetConcessionaireProfilesByVoltagePayloadSchema
  extends z.infer<
    typeof FetchGetConcessionaireProfilesByVoltagePayloadSchema
  > {}
