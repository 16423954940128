import { api, apiException } from "~services/api";
import { OrganizationDefaultFieldSettingsDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetOrganizationDefaultFieldSettings = async () => {
  try {
    const response = await api.get(
      `/config/organizations/default-fields/settings`,
    );

    const parsedData = z
      .array(OrganizationDefaultFieldSettingsDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
