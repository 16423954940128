import { api, apiException } from "~services/api";
import {
  EosUploadResponseSchema,
  FetchUploadDocumentEosFinancingPayloadSchema,
  IFetchUploadDocumentEosFinancingPayloadSchema,
} from "~types/schemas";

export const fetchUploadDocumentEosFinancing = async (
  payload: IFetchUploadDocumentEosFinancingPayloadSchema,
) => {
  try {
    const { protocol, documentType, file } =
      FetchUploadDocumentEosFinancingPayloadSchema.parse(payload);

    const response = await api.post(
      `/eosfin/proposals/${protocol}/document/${documentType}`,
      file,
    );

    const parsedData = EosUploadResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
