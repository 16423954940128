import { UserGroupFormSchema } from "./UserGroupFormSchema";

import { z } from "zod";

export const FetchUpdateUserGroupPayloadSchema = UserGroupFormSchema.extend({
  userGroupId: z.number().int().gt(0),
});

export interface IFetchUpdateUserGroupPayloadSchema
  extends z.infer<typeof FetchUpdateUserGroupPayloadSchema> {}
