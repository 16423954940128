import { useQuery } from "react-query";

import type { IUseFetchGetEosFinancialProposals } from "./interface";

import { fetchGetEosFinancialProposals } from "~api/eosfin/proposals/get";

export const useFetchGetEosFinancialProposalsKey = "api/eosfin/proposals/get";

export function useFetchGetEosFinancialProposals({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetEosFinancialProposals) {
  return useQuery(
    [useFetchGetEosFinancialProposalsKey, dependencyArray],
    async () => await fetchGetEosFinancialProposals(payload),
    options,
  );
}
