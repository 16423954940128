import { UpdateDealCustomFieldGroupFormSchema } from "./UpdateDealCustomFieldGroupFormSchema";

import { z } from "zod";

export const FetchUpdateDealCustomFieldGroupPayloadSchema =
  UpdateDealCustomFieldGroupFormSchema.extend({
    id: z
      .number({
        message: "ID do grupo do campo customizado de negócio é obrigatório",
      })
      .int({
        message: "ID do grupo do campo customizado deve ser um inteiro",
      })
      .gt(0, {
        message:
          "ID do grupo do campo customizado de negócio deve ser maior que zero",
      }),
  });

export interface IFetchUpdateDealCustomFieldGroupPayloadSchema
  extends z.infer<typeof FetchUpdateDealCustomFieldGroupPayloadSchema> {}
