import { useMutation } from "react-query";

import { useFetchGetAllPipelinesKey } from "../useFetchGetAllPipelines";
import { useFetchGetAllPipelinesDetailedKey } from "../useFetchGetAllPipelinesDetailed";
import { useFetchGetPipelineByIdKey } from "../useFetchGetPipelineById";
import type { IUseFetchDeletePipelineStage } from "./interface";

import { fetchDeletePipelineStage } from "~api/pipeline/stage/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePipelineStageKey = "api/pipeline/stage/{id}/delete";

export function useFetchDeletePipelineStage({
  options,
}: IUseFetchDeletePipelineStage) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeletePipelineStage(payload),
    {
      ...options,
      mutationKey: useFetchDeletePipelineStageKey,
      onSuccess: (data, variables, context) => {
        message.success("Fase da pipeline foi apagada!");
        queryClient.invalidateQueries(useFetchGetAllPipelinesKey);
        queryClient.invalidateQueries(useFetchGetAllPipelinesDetailedKey);
        queryClient.invalidateQueries(useFetchGetPipelineByIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao apagar fase da pipeline: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
