import { QueryKey, useQuery } from "react-query";

import type {
  IUseFetchGetProposalWhatsAppMessage,
  UseFetchGetProposalWhatsAppMessageResultType,
  UseFetchGetProposalWhatsAppMessageErrorType,
} from "./interface";

import { fetchGetMessageWhatsAppProposal } from "~api/projects/step_five/message/{id}/get";

export const useFetchGetProposalWhatsAppMessageKey =
  "~api/projects/step_five/message/{id}/get";

export function useFetchGetProposalWhatsAppMessage({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalWhatsAppMessage) {
  return useQuery<
    UseFetchGetProposalWhatsAppMessageResultType,
    UseFetchGetProposalWhatsAppMessageErrorType,
    UseFetchGetProposalWhatsAppMessageResultType,
    QueryKey
  >(
    [useFetchGetProposalWhatsAppMessageKey, dependencyArray],
    async () => {
      const response = await fetchGetMessageWhatsAppProposal({
        path: {
          customId: payload.customId,
        },
      });

      const data = response.data;

      return data;
    },
    options,
  );
}
