import { useQuery } from "react-query";

import type { IUseFetchGetEdeltecProductTypes } from "./interface";

import { fetchGetEdeltecProductTypes } from "~api/equipments/edeltec/product-types/get";

export const useFetchGetEdeltecProductTypesKey =
  "api/equipments/edeltec/product-types/get";

export function useFetchGetEdeltecProductTypes({
  dependencyArray = [],
  options,
}: IUseFetchGetEdeltecProductTypes) {
  return useQuery(
    [useFetchGetEdeltecProductTypesKey, dependencyArray],
    async () => await fetchGetEdeltecProductTypes(),
    options,
  );
}
