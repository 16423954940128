import { useQuery } from "react-query";

import type { IUseFetchGetAllBusinessUnit } from "./interface";

import { fetchGetAllBusinessUnits } from "~api/business-units/get";

export const useFetchGetAllBusinessUnitsKey = "api/business-units/get";

export function useFetchGetAllBusinessUnit({
  dependencyArray = [],
  options,
}: IUseFetchGetAllBusinessUnit) {
  return useQuery(
    [useFetchGetAllBusinessUnitsKey, dependencyArray],
    async () => await fetchGetAllBusinessUnits(),
    options,
  );
}
