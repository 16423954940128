import { z } from "zod";

export const UserGroupDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  users: z.array(z.number()).default([]),
});

export interface IUserGroupDTOSchema
  extends z.infer<typeof UserGroupDTOSchema> {}
