import { AneelInDevelopmentDTOSchema } from "./AneelInDevelopmentDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedAneelInDevelopmentDTOSchema = PaginationSchema(
  AneelInDevelopmentDTOSchema,
);

export interface IPaginatedAneelInDevelopmentDTOSchema
  extends z.infer<typeof PaginatedAneelInDevelopmentDTOSchema> {}
