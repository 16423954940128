import { useMutation } from "react-query";

import { useFetchGetPaginatedSimultaneityProfilesKey } from "../useFetchGetPaginatedSimultaneityProfiles";
import type { IUseFetchDeleteSimultaneityProfile } from "./interface";

import { fetchDeleteSimultaneityProfile } from "~api/simultaneityProfiles/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteSimultaneityProfileKey =
  "api/simultaneityProfiles/delete";

export function useFetchDeleteSimultaneityProfile(
  options?: IUseFetchDeleteSimultaneityProfile,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteSimultaneityProfile(payload),
    {
      ...options,
      mutationKey: useFetchDeleteSimultaneityProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil foi removido");
        queryClient.invalidateQueries(
          useFetchGetPaginatedSimultaneityProfilesKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao deletar perfil: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
