import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import { z } from "zod";

export const EntityIdentifierDTOSchema = z.object({
  id: z.number().int().gt(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IEntityIdentifierDTOSchema
  extends z.infer<typeof EntityIdentifierDTOSchema> {}
