import { useMutation } from "react-query";

import { useFetchGetPaginatedUsersByCompanyKey } from "../../company/useFetchGetPaginatedUsersByCompany";
import type { IUseFetchCreateUser } from "./interface";

import { fetchCreateUser } from "~api/users/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateUserKey = "api/users/post";

export function useFetchCreateUser({ options }: IUseFetchCreateUser) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateUser(payload), {
    ...options,
    mutationKey: useFetchCreateUserKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário foi criado");
      queryClient.invalidateQueries(useFetchGetPaginatedUsersByCompanyKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao criar usuário: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
