import { z } from "zod";

export const UserDefaultProposalMessageFormSchema = z.object({
  defaultProposalMessage: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUserDefaultProposalMessageFormSchema
  extends z.infer<typeof UserDefaultProposalMessageFormSchema> {}
