import { api, apiException } from "~services/api";
import {
  RuleExpressionDTOSchema,
  FetchUpdateValidationRulePayloadSchema,
  IFetchUpdateValidationRulePayloadSchema,
} from "~types/schemas";

export const fetchUpdateValidationRule = async (
  payload: IFetchUpdateValidationRulePayloadSchema,
) => {
  try {
    const { validationRuleId, ...body } =
      FetchUpdateValidationRulePayloadSchema.parse(payload);

    const response = await api.put(
      `/rule-expression/${validationRuleId}`,
      body,
    );

    const parsedData = RuleExpressionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
