import { useQuery } from "react-query";

import { IUseFetchGetPaginatedValidationRules } from "./interface";

import { fetchGetRuleExpression } from "~api/rule-expression/get";

export const useFetchGetPaginatedValidationRulesKey = "api/rule-expression/get";

export function useFetchGetPaginatedValidationRules({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedValidationRules) {
  return useQuery(
    [useFetchGetPaginatedValidationRulesKey, dependencyArray],
    async () => {
      return await fetchGetRuleExpression(payload);
    },
    { ...options },
  );
}
