import { api, apiException } from "~services/api";
import {
  FetchUpdateReasonForLossPositionPayloadSchema,
  type IFetchUpdateReasonForLossPositionPayloadSchema,
} from "~types/schemas";

export const fetchUpdateReasonForLossPosition = async (
  payload: IFetchUpdateReasonForLossPositionPayloadSchema,
) => {
  try {
    const { id, position } =
      FetchUpdateReasonForLossPositionPayloadSchema.parse(payload);

    await api.patch(`/reason-for-loss/${id}/position/${position}`);
  } catch (error) {
    throw apiException(error);
  }
};
