import { useMutation } from "react-query";

import { useFetchGetAllStructuresToAssociateLaborKey } from "../useFetchGetAllStructuresToAssociateLabor";
import type { IUseFetchUpdateLaborStructure } from "./interface";

import { fetchUpdateLaborStructure } from "~api/labors/structures/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchAddStructuresInLaborKey = "api/labors/structures/{id}/put";

export function useFetchAddStructuresInLabor({
  options,
}: IUseFetchUpdateLaborStructure) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateLaborStructure(payload),
    {
      ...options,
      mutationKey: useFetchAddStructuresInLaborKey,
      onSuccess: (data, variables, context) => {
        message.success("Estruturas salvas com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetAllStructuresToAssociateLaborKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
