import { useMutation } from "react-query";

import { useFetchGetAllReasonForLossKey } from "../useFetchGetAllReasonForLoss";
import { useFetchGetPaginatedReasonForLossKey } from "../useFetchGetPaginatedReasonForLoss";
import { useFetchGetReasonForLossByIdKey } from "../useFetchGetReasonForLossById";
import type { IUseFetchUpdateReasonForLoss } from "./interface";

import { fetchUpdateReasonForLoss } from "~api/reason-for-loss/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateReasonForLossKey = "api/reason-for-loss/{id}/put";

export function useFetchUpdateReasonForLoss({
  options,
}: IUseFetchUpdateReasonForLoss) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateReasonForLoss(payload),
    {
      ...options,
      mutationKey: useFetchUpdateReasonForLossKey,
      onSuccess: (data, variables, context) => {
        message.success("Motivo de perda foi atualizado");
        queryClient.invalidateQueries(useFetchGetPaginatedReasonForLossKey);
        queryClient.invalidateQueries(useFetchGetReasonForLossByIdKey);
        queryClient.invalidateQueries(useFetchGetAllReasonForLossKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar motivo de perda: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
