import { api, apiException } from "~services/api";
import {
  UserGroupDTOSchema,
  FetchCreateUserGroupPayloadSchema,
  IFetchCreateUserGroupPayloadSchema,
} from "~types/schemas";

export const fetchCreateUserGroup = async (
  payload: IFetchCreateUserGroupPayloadSchema,
) => {
  try {
    const body = FetchCreateUserGroupPayloadSchema.parse(payload);

    const response = await api.post(`/usergroup`, body);

    const parsedData = UserGroupDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
