import { useQuery } from "react-query";

import type { IUseFetchGetSearchPipedriveClient } from "./interface";

import { fetchGetSearchPipedriveClient } from "~api/projects/step_one/pipedrive/client/search/get";

export const useFetchGetSearchPipedriveClientKey =
  "api/projects/step_one/search/get";

export function useFetchGetSearchPipedriveClient({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetSearchPipedriveClient) {
  return useQuery(
    [useFetchGetSearchPipedriveClientKey, dependencyArray],
    async () => await fetchGetSearchPipedriveClient(payload),
    options,
  );
}
