import { useQuery } from "react-query";

import { IUseFetchGetActivityCurrent } from "./interface";

import { fetchGetActivityCurrent } from "~api/activitiesType/get";

export const useFetchGetActivityCurrentKey = "api/activitiesType/get";

export function useFetchGetActivityCurrent({
  dependencyArray = [],
  options,
}: IUseFetchGetActivityCurrent) {
  return useQuery(
    [useFetchGetActivityCurrentKey, dependencyArray],
    async () => {
      return await fetchGetActivityCurrent();
    },
    options,
  );
}
