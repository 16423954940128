import { ImportTypeEnum } from "~types/enum";
import { Base64 } from "js-base64";
import { z } from "zod";

export const ImportProductFileRequestDTOSchema = z.object({
  data: z.string().refine(Base64.isValid),
  importType: ImportTypeEnum,
});

export interface IImportProductFileRequestDTOSchema
  extends z.infer<typeof ImportProductFileRequestDTOSchema> {}
