import { api, apiException } from "~services/api";
import { UserDefaultProposalMessageFormSchema } from "~types/schemas";

export const fetchGetDefaultMessageUser = async () => {
  try {
    const response = await api.get("/users/default-message");

    const parsedData = UserDefaultProposalMessageFormSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
