import { FormFieldDTOSchema } from "./FormFieldDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";
import { ProfileDTOSchema } from "./ProfileDTOSchema";

import { z } from "zod";

export const DefaultFieldProfileSettingsDTOSchema = z.object({
  id: z.number().int().gt(0).nullish(),
  formField: FormFieldDTOSchema.nullish().transform((val) => val ?? undefined),
  profile: ProfileDTOSchema.nullish().transform((val) => val ?? undefined),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IDefaultFieldProfileSettingsDTOSchema
  extends z.infer<typeof DefaultFieldProfileSettingsDTOSchema> {}
