import { useQuery } from "react-query";

import type { IUseFetchGetCountOfUsersAccountOwner } from "./interface";

import { fetchGetCountOfUsersAccountOwner } from "~api/admin/users/count/get";

export const useFetchGetCountOfUsersAccountOwnerKey =
  "api/admin/users/count/get";

export function useFetchGetCountOfUsersAccountOwner({
  dependencyArray = [],
  options,
  payload = {
    status: "ACTIVE",
  },
}: IUseFetchGetCountOfUsersAccountOwner) {
  return useQuery(
    [useFetchGetCountOfUsersAccountOwnerKey, dependencyArray],
    async () => await fetchGetCountOfUsersAccountOwner(payload),
    options,
  );
}
