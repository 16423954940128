import { OrganizationDealDTOSchema } from "./OrganizationDealDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedOrganizationDealDTOSchema = PaginationSchema(
  OrganizationDealDTOSchema,
);

export interface IPaginatedOrganizationDealDTOSchema
  extends z.infer<typeof PaginatedOrganizationDealDTOSchema> {}
