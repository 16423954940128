import { z } from "zod";

export const FetchGetPaginatedProposalHistoryPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 100),
  customId: z.number().int().gt(0),
});

export interface IFetchGetPaginatedProposalHistoryPayloadSchema
  extends z.infer<typeof FetchGetPaginatedProposalHistoryPayloadSchema> {}
