import { api, apiException } from "~services/api";
import { CompanyDTOSchema } from "~types/schemas";

export const fetchGetActivityMessageFromPipedrive = async () => {
  try {
    const response = await api.get(
      `/company/custom-pipedrive-activity-message`,
    );

    const parsedData = CompanyDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
