import { api, apiException } from "~services/api";
import {
  DealCustomFieldValueGroupDTOSchema,
  FetchGetDealCustomFieldValueByIdPayloadSchema,
  IFetchGetDealCustomFieldValueByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetDealCustomFieldValueById = async (
  payload: IFetchGetDealCustomFieldValueByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetDealCustomFieldValueByIdPayloadSchema.parse(payload);

    const response = await api.get(`/deal/${id}/custom-fields`);

    const parsedData = DealCustomFieldValueGroupDTOSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
