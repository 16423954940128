import { z } from "zod";

export const UpdateDealCustomFieldGroupFormSchema = z.object({
  name: z.string({
    message: "Nome do grupo de variáveis customizado de negócio é obrigatório",
  }),
});

export interface IUpdateDealCustomFieldGroupFormSchema
  extends z.infer<typeof UpdateDealCustomFieldGroupFormSchema> {}
