import { useQuery } from "react-query";

import { IUseFetchGetLatestVersionPDFFinalizedProposal } from "./interface";

import { getLatestVersionPDFFinalizedProposal } from "~api/reports/download/pdf/custom-id/{customId}/get";

export const useFetchGetLatestVersionPDFFinalizedProposalKey =
  "api/reports/download/pdf/custom-id/{customId}/get";

export function useFetchGetLatestVersionPDFFinalizedProposal({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetLatestVersionPDFFinalizedProposal) {
  return useQuery(
    [useFetchGetLatestVersionPDFFinalizedProposalKey, dependencyArray],
    async () => {
      return await getLatestVersionPDFFinalizedProposal(payload);
    },
    options,
  );
}
