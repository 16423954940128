import { api, apiException } from "~services/api";
import {
  type IFetchCreateReasonForLossPayloadSchema,
  FetchCreateReasonForLossPayloadSchema,
  ReasonForLossResponseSchema,
} from "~types/schemas";

export const fetchCreateReasonForLoss = async (
  payload: IFetchCreateReasonForLossPayloadSchema,
) => {
  try {
    const body = FetchCreateReasonForLossPayloadSchema.parse(payload);

    const response = await api.post(`/reason-for-loss`, body);

    const parsedData = ReasonForLossResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
