import { z } from "zod";

export const StructureFixationEnum = z
  .enum([
    "METALICA",
    "MADEIRA",
    "PERFIL_CONTINUO",
    "MINI_TRILHO",
    "FIBROCIMENTO",
    "CONCRETO",
  ] as const)
  .catch((e) => e.input);

export type StructureFixationEnum = z.infer<typeof StructureFixationEnum>;
