import { z } from "zod";

export const ReasonForLossResponseSchema = z.object({
  id: z
    .number({ message: "ID do motivo de perda é obrigatório" })
    .int({ message: "ID do motivo de perda deve ser um inteiro" })
    .gt(0, { message: "ID do motivo de perda deve ser maior do que zero" }),
  reason: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IReasonForLossResponseSchema
  extends z.infer<typeof ReasonForLossResponseSchema> {}
