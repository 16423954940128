import { UsefulAreaDataSchema } from "./UsefulAreaDataSchema";

import { z } from "zod";

export const UsefulAreaFormSchema = z
  .object({ usefulAreas: z.array(UsefulAreaDataSchema) })
  .partial();

export interface IUsefulAreaFormSchema
  extends z.infer<typeof UsefulAreaFormSchema> {}
