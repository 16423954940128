import { z } from "zod";

export const CrmFileSchema = z
  .object({
    id: z.number().int().gt(0),
    path: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    name: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    contentType: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    size: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined),
    date: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    completeFileName: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface ICrmFileSchema extends z.infer<typeof CrmFileSchema> {}
