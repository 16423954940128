import { PaginationSchema } from "./PaginationSchema";
import { ProposalHistoryResponseSchema } from "./ProposalHistoryResponseSchema";

import { z } from "zod";

export const PaginatedProposalHistoryResponseSchema = PaginationSchema(
  ProposalHistoryResponseSchema,
);

export interface IPaginatedProposalHistoryResponseSchema
  extends z.infer<typeof PaginatedProposalHistoryResponseSchema> {}
