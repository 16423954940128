import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetPaginatedPersonDealsByPersonIdKey } from "../../persons/useFetchGetPaginatedPersonDealsByPersonId";
import { useFetchGetPersonDealsCountByPersonIdKey } from "../../persons/useFetchGetPersonDealsCountByPersonId";
import { useFetchGetDealByCustomIdKey } from "../useFetchGetDealByCustomId";
import { useFetchSearchDealKey } from "../useFetchSearchDeal";
import type { IUseFetchDeleteDealByCustomId } from "./interface";

import { fetchDeleteDealByCustomId } from "~api/deal/{customId}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteDealByCustomIdKey = "api/deal/{customId}/delete";

export function useFetchDeleteDealByCustomId({
  options,
}: IUseFetchDeleteDealByCustomId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteDealByCustomId(payload),
    {
      ...options,
      mutationKey: useFetchDeleteDealByCustomIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Negócio foi apagado!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedPersonDealsByPersonIdKey,
        );
        queryClient.invalidateQueries(useFetchGetPersonDealsCountByPersonIdKey);
        queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
        queryClient.invalidateQueries(useFetchSearchDealKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao apagar negócio: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
