import { PaginationSchema } from "./PaginationSchema";
import { UserManagementDTOSchema } from "./UserManagementDTOSchema";

import { z } from "zod";

export const PaginatedUserManagementDTOSchema = PaginationSchema(
  UserManagementDTOSchema,
);

export interface IPaginatedUserManagementDTOSchema
  extends z.infer<typeof PaginatedUserManagementDTOSchema> {}
