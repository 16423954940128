import { api, apiException } from "~services/api";
import {
  FetchUpdateFieldBindPayloadSchema,
  FieldBindDTOSchema,
  IFetchUpdateFieldBindPayloadSchema,
} from "~types/schemas";

export const fetchUpdateFieldBind = async (
  payload: IFetchUpdateFieldBindPayloadSchema,
) => {
  try {
    const body = FetchUpdateFieldBindPayloadSchema.parse(payload);

    const response = await api.put(`/field-bind`, body);

    const parsedData = FieldBindDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
