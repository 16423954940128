import { useQuery } from "react-query";

import type { IUseFetchGetAllDistributors } from "./interface";

import { fetchGetPaginatedDistributors } from "~api/distributors/get";

export const useFetchGetAllDistributorsKey = "api/distributors/get";

export function useFetchGetAllDistributors({
  dependencyArray = [],
  payload = {
    page: 0,
    size: 10,
  },
  options,
}: IUseFetchGetAllDistributors) {
  return useQuery(
    [useFetchGetAllDistributorsKey, dependencyArray],
    async () => await fetchGetPaginatedDistributors(payload),
    options,
  );
}
