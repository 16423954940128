import { z } from "zod";

export const FetchGetActivityBoardPayloadSchema = z.object({
  begin: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? ""),
  end: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IFetchGetActivityBoardPayloadSchema
  extends z.infer<typeof FetchGetActivityBoardPayloadSchema> {}
