import { EosPersonIdentitySchema } from "./EosPersonIdentitySchema";

import { z } from "zod";

export const EosPersonSchema = z.object({
  rendaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  patrimonio: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  outrasRendas: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  dataNascimento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  identidadePf: EosPersonIdentitySchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IEosPersonSchema extends z.infer<typeof EosPersonSchema> {}
