import { z } from "zod";

export const FetchGetPaginatedProfilesPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedProfilesPayloadSchema
  extends z.infer<typeof FetchGetPaginatedProfilesPayloadSchema> {}
