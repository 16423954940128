import { EosProposalDetailSchema } from "./EosProposalDetailSchema";

import { z } from "zod";

export const EosProposalDetailResponseSchema = z.object({
  data: EosProposalDetailSchema.nullish().transform((val) => val ?? undefined),
});

export interface IEosProposalDetailResponseSchema
  extends z.infer<typeof EosProposalDetailResponseSchema> {}
