import { QueryKey, useQuery } from "react-query";

import type {
  IUseFetchGetProposalDocx,
  UseFetchGetProposalDocxResultType,
  UseFetchGetProposalDocxErrorType,
} from "./interface";

import { fetchGetDocxProposal } from "~api/projects/step_five/docx/{id}/get";

export const useFetchGetProposalDocxKey =
  "api/projects/step_five/docx/{id}/get";

export function useFetchGetProposalDocx({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalDocx) {
  return useQuery<
    UseFetchGetProposalDocxResultType,
    UseFetchGetProposalDocxErrorType,
    UseFetchGetProposalDocxResultType,
    QueryKey
  >(
    [useFetchGetProposalDocxKey, dependencyArray],
    async () => {
      const response = await fetchGetDocxProposal({
        path: {
          id: payload.id,
        },
      });

      const data = response.data;

      return data;
    },
    { ...options },
  );
}
