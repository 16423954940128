import { useQuery } from "react-query";

import type { IUseFetchGetAllFortlevModules } from "./interface";

import { fetchGetAllFortlevModules } from "~api/equipments/fortlev/modules/get";

export const useFetchGetAllFortlevEquipmentModulesKey =
  "api/equipments/fortlev/modules/get";

export function useFetchGetAllFortlevEquipmentModules({
  dependencyArray = [],
  options,
}: IUseFetchGetAllFortlevModules) {
  return useQuery(
    [useFetchGetAllFortlevEquipmentModulesKey, dependencyArray],
    async () => await fetchGetAllFortlevModules(),
    options,
  );
}
