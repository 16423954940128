import { api, apiException } from "~services/api";
import {
  FetchGetAllStructuresToAssociateLaborPayloadSchema,
  IFetchGetAllStructuresToAssociateLaborPayloadSchema,
  StructureTypeDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetAllStructuresToAssociateLabor = async (
  payload: IFetchGetAllStructuresToAssociateLaborPayloadSchema,
) => {
  try {
    const { id } =
      FetchGetAllStructuresToAssociateLaborPayloadSchema.parse(payload);

    const response = await api.get(`/labors/structures/for-associate/${id}`);

    const parsedData = z.array(StructureTypeDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
