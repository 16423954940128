import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema,
  IFetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema,
  PaginatedAdditionalCostsDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedAdditionalCostsAuxVariable = async (
  payload: IFetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedAdditionalCostsAuxVariablePayloadSchema.parse(payload);

    const response = await api.get(`/additional-costs/aux-variable`, {
      params,
    });

    const parsedData = PaginatedAdditionalCostsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
