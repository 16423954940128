import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAdminUsersPayloadSchema,
  IFetchGetPaginatedAdminUsersPayloadSchema,
} from "~types/schemas";
import { PaginatedUserListItemDTOSchema } from "~types/schemas/PaginatedUserListItemDTOSchema";

export const fetchGetPaginatedAdminUsers = async (
  payload: IFetchGetPaginatedAdminUsersPayloadSchema,
) => {
  try {
    const body = FetchGetPaginatedAdminUsersPayloadSchema.parse(payload);

    const response = await api.post(`/admin/users`, body);

    const parsedData = PaginatedUserListItemDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
