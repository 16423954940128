import { api, apiException } from "~services/api";
import {
  PersonAutoCompleteResponseSchema,
  FetchGetAutoCompletePersonPayloadSchema,
  type IFetchGetAutoCompletePersonPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetAutoCompletePerson = async (
  payload?: IFetchGetAutoCompletePersonPayloadSchema,
) => {
  try {
    const params = FetchGetAutoCompletePersonPayloadSchema.parse(payload);

    const response = await api.get("/persons/auto-complete", {
      params,
    });

    const parsedData = z
      .array(PersonAutoCompleteResponseSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
