import { useMutation } from "react-query";

import { useFetchGetPaginatedTransformerRulesKey } from "../useFetchGetPaginatedTransformerRules";
import type { IUseFetchCreateTransformerRule } from "./interface";

import { fetchCreateTransformerRule } from "~api/config/transformer/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateTransformerRulesKey = "api/labors/post";

export function useFetchCreateTransformerRules({
  options,
}: IUseFetchCreateTransformerRule) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateTransformerRule(payload),
    {
      ...options,
      mutationKey: useFetchCreateTransformerRulesKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra criada com sucesso.");
        queryClient.invalidateQueries(useFetchGetPaginatedTransformerRulesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
