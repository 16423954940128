import { z } from "zod";

export const FilePdfResponseSchema = z.object({
  filePdfInBase64: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IFilePdfResponseSchema
  extends z.infer<typeof FilePdfResponseSchema> {}
