import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveUsers } from "./interface";

import { fetchGetUsersPipeDrive } from "~api/pipedrive/users/get";

export const useFetchGetPipedriveUsersKey = "api/pipedrive/users/get";

export function useFetchGetPipedriveUsers({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedriveUsers) {
  return useQuery(
    [useFetchGetPipedriveUsersKey, dependencyArray],
    async () => await fetchGetUsersPipeDrive(),
    options,
  );
}
