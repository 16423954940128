import { PaginationSchema } from "./PaginationSchema";
import { SysAdminUserDTOSchema } from "./SysAdminUserDTOSchema";

import { z } from "zod";

export const PaginatedSysAdminUserDTOSchema = PaginationSchema(
  SysAdminUserDTOSchema,
);

export interface IPaginatedSysAdminUserDTOSchema
  extends z.infer<typeof PaginatedSysAdminUserDTOSchema> {}
