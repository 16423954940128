import { api, apiException } from "~services/api";
import { ActivityEventTypeDTOSchema } from "~types/schemas";
import {
  FetchGetActivityEventTypesPayloadSchema,
  type IFetchGetActivityEventTypesPayloadSchema,
} from "~types/schemas";

export const fetchGetActivityTypes = async (
  payload: IFetchGetActivityEventTypesPayloadSchema,
) => {
  try {
    const params = FetchGetActivityEventTypesPayloadSchema.parse(payload);

    const response = await api.get(`/activities/types`, {
      params,
    });

    const parsedData = ActivityEventTypeDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
