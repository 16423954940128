import { CustomVariableFormSchema } from "./CustomVariableFormSchema";

import { z } from "zod";

export const FetchCreateCustomVariablePayloadSchema =
  CustomVariableFormSchema.extend({
    name: z.string().optional(),
  });
export interface IFetchCreateCustomVariablePayloadSchema
  extends z.infer<typeof FetchCreateCustomVariablePayloadSchema> {}
