import { DistributorDTOSchema } from "./DistributorDTOSchema";

import { z } from "zod";

export const CompanyFormDistributorSchema = z
  .object({ distributors: z.array(DistributorDTOSchema) })
  .partial();

export interface ICompanyFormDistributorSchema
  extends z.infer<typeof CompanyFormDistributorSchema> {}
