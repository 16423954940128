import { api, apiException } from "~services/api";
import {
  type IFetchGetPdfBase64FromDatasheetRevisionById,
  FetchGetPdfBase64FromDatasheetRevisionById,
  DatasheetRevisionBase64DTOSchema,
} from "~types/schemas";

export const fetchGetPdfBase64FromDatasheetRevisionById = async (
  payload: IFetchGetPdfBase64FromDatasheetRevisionById,
) => {
  try {
    const { id, revision } =
      FetchGetPdfBase64FromDatasheetRevisionById.parse(payload);

    const response = await api.get(
      `/admin/datasheets/${id}/revision/${revision}`,
    );

    const parsedData = DatasheetRevisionBase64DTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
