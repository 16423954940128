import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedStructures } from "./interface";

import { fetchGetPaginatedStructures } from "~api/product/estruturas/post";

export const useFetchGetPaginatedStructuresKey = "api/product/estruturas/post";

export function useFetchGetPaginatedStructures({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
  },
}: IUseFetchGetPaginatedStructures) {
  return useQuery(
    [useFetchGetPaginatedStructuresKey, dependencyArray],
    async () => fetchGetPaginatedStructures(payload),
    options,
  );
}
