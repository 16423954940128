import { z } from "zod";

export const CustomPvModuleDTOSchema = z.object({
  id: z
    .number({ message: "Faltando o ID do módulo" })
    .int({ message: "ID do módulo deve ser um inteiro" })
    .gt(0, { message: "ID do módulo deve ser maior que zero" }),
  model: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  distributor: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  type: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  precoBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  powerWp: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vmp: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  amp: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  isc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  voc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vocCoefficientTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  iscCoefficientTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  areaM2: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  lengthMm: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  widthMm: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  weight: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  warrantyInYears: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  pmaxCoefficientTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  efficiency: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  operatingTemperature: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  powerTolerance: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  cellArrangement: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  dimensions: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  targetName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ICustomPvModuleDTOSchema
  extends z.infer<typeof CustomPvModuleDTOSchema> {}
