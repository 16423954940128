import { z } from "zod";

export const StructuresMaterialCostRuleFormSchema = z
  .object({
    valuePerModule: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    valuePerWp: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    additionalCostTaxId: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined),
    notApplyDefaultAdditionalCostTax: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    structureType: z.object({
      id: z
        .number()
        .int()
        .nullish()
        .transform((val) => val ?? undefined),
    }),
  })
  .partial();

export interface IStructuresMaterialCostRuleFormSchema
  extends z.infer<typeof StructuresMaterialCostRuleFormSchema> {}
