import { api, apiException } from "~services/api";
import {
  FetchDeletePersonPipedrivePayloadSchema,
  IFetchDeletePersonPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchDeletePersonPipedrive = async (
  payload: IFetchDeletePersonPipedrivePayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchDeletePersonPipedrivePayloadSchema.parse(payload);

    await api.delete(`/persons/pipedrive/${pipedriveId}`);
  } catch (error) {
    throw apiException(error);
  }
};
