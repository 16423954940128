import { z } from "zod";

export const CustomProductDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  priceBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface ICustomProductDTOSchema
  extends z.infer<typeof CustomProductDTOSchema> {}
