import { useQuery } from "react-query";

import { IUseFetchGetSellersAutoComplete } from "./interface";

import { fetchGetSellersAutoComplete } from "~api/proposal/sellers/auto-complete/get";

export const useFetchGetSellersAutoCompleteKey =
  "api/proposal/sellers/auto-complete/get";

export function useFetchGetSellersAutoComplete({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetSellersAutoComplete) {
  return useQuery(
    [useFetchGetSellersAutoCompleteKey, dependencyArray],
    async () => await fetchGetSellersAutoComplete(payload),
    options,
  );
}
