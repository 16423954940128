import { z } from "zod";

export const EquipmentCategoryEnum = z
  .enum([
    "INVERTER",
    "INVERTER_PROTECTION",
    "WIRING",
    "EXTENDED_WARRANTY",
    "TRANSFORMER",
    "MODULE",
    "STRUCTURE",
    "OTHER",
    "CA_PROTECTION",
  ] as const)
  .catch((e) => e.input);

export type EquipmentCategoryEnum = z.infer<typeof EquipmentCategoryEnum>;
