import { AddressDTOSchema } from "./AddressDTOSchema";

import { z } from "zod";

export const BusinessUnitDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IBusinessUnitDTOSchema
  extends z.infer<typeof BusinessUnitDTOSchema> {}
