import { api, apiException } from "~services/api";
import {
  IFetchGetLatestVersionPDFFinalizedProposal,
  FetchGetLatestVersionPDFFinalizedProposal,
  ResponseGetLatestVersionPDFFinalizedProposal,
} from "~types/schemas";

export const getLatestVersionPDFFinalizedProposal = async (
  path: IFetchGetLatestVersionPDFFinalizedProposal,
) => {
  try {
    const { customId } = FetchGetLatestVersionPDFFinalizedProposal.parse(path);
    const response = await api.get(
      `/reports/download/pdf/base64/custom-id/${customId}`,
      {
        //Parameter to prevent the browser from caching the request, obs: trust the father
        params: {
          _v: Math.random(),
        },
      },
    );

    const parsedData = ResponseGetLatestVersionPDFFinalizedProposal.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
