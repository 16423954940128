import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedInvertersPayloadSchema,
  type IFetchGetPaginatedInvertersPayloadSchema,
  PaginatedInverterCustomDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedInverters = async (
  payload: IFetchGetPaginatedInvertersPayloadSchema,
) => {
  try {
    const body = FetchGetPaginatedInvertersPayloadSchema.parse(payload);

    const response = await api.post(`/product/inverter`, body);

    const parsedData = PaginatedInverterCustomDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
