import { useQuery } from "react-query";

import type { IUseFetchGetCompanyUserBusinessUnits } from "./interface";

import { fetchGetCompanyUserBusinessUnits } from "~api/business-units/me/get";

export const useFetchGetCompanyUserBusinessUnitsKey =
  "api/business-units/me/get";

export function useFetchGetCompanyUserBusinessUnits({
  dependencyArray = [],
  options,
}: IUseFetchGetCompanyUserBusinessUnits) {
  return useQuery(
    [useFetchGetCompanyUserBusinessUnitsKey, dependencyArray],
    async () => await fetchGetCompanyUserBusinessUnits(),
    options,
  );
}
