import { api, apiException } from "~services/api";
import {
  FetchUpdateUserBusinessUnitPayloadSchema,
  IFetchUpdateUserBusinessUnitPayloadSchema,
  UserAccountDTOSchema,
} from "~types/schemas";

export const fetchUpdateUserBusinessUnit = async (
  payload: IFetchUpdateUserBusinessUnitPayloadSchema,
) => {
  try {
    const { businessUnitId } =
      FetchUpdateUserBusinessUnitPayloadSchema.parse(payload);

    const response = await api.put(`/users/business-units/${businessUnitId}`);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
