import { api, apiException } from "~services/api";
import { AdminDistributorsDTOSchema } from "~types/schemas";

export const fetchGetAdminModules = async () => {
  try {
    const response = await api.get(`/admin/distributors`);

    const parsedData = AdminDistributorsDTOSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
