import { useQuery } from "react-query";

import type { IUseFetchGetSizingStep } from "./interface";

import { fetchGetProposalStepTwo } from "~api/projects/step_two/{id}/get";

export const useFetchGetSizingStepKey = "api/projects/step_two/{id}/get";

export function useFetchGetSizingStep({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetSizingStep) {
  return useQuery(
    [useFetchGetSizingStepKey, dependencyArray],
    async () => await fetchGetProposalStepTwo(payload),
    options,
  );
}
