import { api, apiException } from "~services/api";
import {
  FetchSetNotificationReadPayloadSchema,
  type IFetchSetNotificationReadPayloadSchema,
} from "~types/schemas";

export const fetchSetNotificationRead = async (
  payload: IFetchSetNotificationReadPayloadSchema,
) => {
  try {
    const { notificationId } =
      FetchSetNotificationReadPayloadSchema.parse(payload);

    await api.patch(`/notifications/${notificationId}/read`);
  } catch (error) {
    throw apiException(error);
  }
};
