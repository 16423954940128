import {
  FieldTypeEnum,
  ProjectTypeEnum,
  ProposalSectionEnum,
} from "~types/enum";
import { z } from "zod";

export const CustomFieldProposalDTOSchema = z.object({
  id: z.number().int().gte(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  displayOrder: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? []),
  projectTypes: z
    .array(ProjectTypeEnum)
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  notHidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface ICustomFieldProposalDTOSchema
  extends z.infer<typeof CustomFieldProposalDTOSchema> {}
