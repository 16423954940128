import { useQuery } from "react-query";

import type { IUseFetchGetPersonCustomFields } from "./interface";

import { fetchGetPersonCustomFields } from "~api/config/personCustomFields/get";

export const useFetchGetPersonCustomFieldsKey =
  "api/config/personCustomFields/get";

export function useFetchGetPersonCustomFields({
  dependencyArray = [],
  options,
}: IUseFetchGetPersonCustomFields) {
  return useQuery(
    [useFetchGetPersonCustomFieldsKey, dependencyArray],
    async () => {
      const data = await fetchGetPersonCustomFields();

      const sortedData = [...data].sort(
        (a, b) => (a.displayOrder ?? 999999) - (b.displayOrder ?? 999999),
      );

      return sortedData;
    },
    options,
  );
}
