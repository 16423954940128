import { api, apiException } from "~services/api";
import {
  type IFetchDeletePipelineStagePayloadSchema,
  FetchDeletePipelineStagePayloadSchema,
} from "~types/schemas";

export const fetchDeletePipelineStage = async (
  payload: IFetchDeletePipelineStagePayloadSchema,
) => {
  try {
    const { pipelineStageId, ...params } =
      FetchDeletePipelineStagePayloadSchema.parse(payload);

    await api.delete(`/pipeline/stage/${pipelineStageId}`, {
      params,
    });
  } catch (error) {
    throw apiException(error);
  }
};
