import { z } from "zod";

export const DealFieldSchema = z.object({
  id: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  key: z.string(), // this is the unique key
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  edit_flag: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  field_type: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDealFieldSchema extends z.infer<typeof DealFieldSchema> {}
