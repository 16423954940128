import { z } from "zod";

const ProposalPayment = z.object({
  id: z.string(),
  name: z.string(),
  monthlyInterestRate: z.number(),
  installments: z.number().int(),
  downPayment: z.number(),
  projectId: z.number(),
});

export const ProposalPaymentsDTO = z.array(ProposalPayment);

export interface IProposalPaymentsDTO
  extends z.infer<typeof ProposalPaymentsDTO> {}
