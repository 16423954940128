import { useMutation } from "react-query";

import { useFetchGetProjectRevisionKey } from "../useFetchGetProjectRevisionById";
import type { IUseFetchCreateStepOne } from "./interface";

import { fetchCreateProposal } from "~api/projects/step_one/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateStepOneKey = "api/projects/step_one/post";

export function useFetchCreateStepOne({ options }: IUseFetchCreateStepOne) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateProposal(payload), {
    ...options,
    mutationKey: useFetchCreateStepOneKey,
    onSuccess: (data, variables, context) => {
      message.success("Proposta foi iniciada");
      queryClient.invalidateQueries(useFetchGetProjectRevisionKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
