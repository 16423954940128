import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldGroupById } from "./interface";

import { fetchGetDealCustomFieldGroupById } from "~api/config/deals/custom-fields/groups/{id}/get";

export const useFetchGetDealCustomFieldGroupByIdKey =
  "api/config/deals/custom-fields/groups/{id}/get";

export function useFetchGetDealCustomFieldGroupById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDealCustomFieldGroupById) {
  return useQuery(
    [useFetchGetDealCustomFieldGroupByIdKey, dependencyArray],
    async () => await fetchGetDealCustomFieldGroupById(payload),
    options,
  );
}
