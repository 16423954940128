import { QueryKey, useQuery } from "react-query";

import type {
  IUseFetchGetProposalPdf,
  UseFetchGetProposalPdfResultType,
  UseFetchGetProposalPdfErrorType,
} from "./interface";

import { fetchGetProposalPdf } from "~api/projects/step_five/pdf/{id}/get";

export const useFetchGetProposalPdfKey = "api/projects/step_five/pdf/{id}/get";

export function useFetchGetProposalPdf({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalPdf) {
  return useQuery<
    UseFetchGetProposalPdfResultType,
    UseFetchGetProposalPdfErrorType,
    UseFetchGetProposalPdfResultType,
    QueryKey
  >(
    [useFetchGetProposalPdfKey, dependencyArray],
    async () => {
      const response = await fetchGetProposalPdf({
        path: {
          id: payload.id,
        },
      });

      const data = response.data;

      return data;
    },
    { ...options },
  );
}
