import { api, apiException } from "~services/api";
import {
  PersonDTOSchema,
  FetchGetPersonByIdPayloadSchema,
  type IFetchGetPersonByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetPersonById = async (
  payload: IFetchGetPersonByIdPayloadSchema,
) => {
  try {
    const { personId } = FetchGetPersonByIdPayloadSchema.parse(payload);

    const response = await api.get(`/persons/${personId}`);

    const parsedData = PersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
