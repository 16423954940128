import { z } from "zod";

export const DatasheetUpdatedRequestDTOSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IDatasheetUpdatedRequestDTOSchema
  extends z.infer<typeof DatasheetUpdatedRequestDTOSchema> {}
