import { useMutation } from "react-query";

import { useFetchGetCompanyDistributorsKey } from "../../company/useFetchGetCompanyDistributors";
import { useFetchGetStructureDistributorsKey } from "../../structure-distributor/useFetchGetStructureDistributors";
import { useFetchGetPaginatedDistributorsKey } from "../useFetchGetPaginatedDistributors";
import type { IUseFetchUpdateConfigAldoDistributorIntegration } from "./interface";

import { fetchUpdateConfigAldoDistributorIntegration } from "~api/distributors/aldo/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchConfigAldoDistributorIntegrationKey =
  "api/distributors/aldo/put";

export function useFetchUpdateConfigAldoDistributorIntegration(
  options?: IUseFetchUpdateConfigAldoDistributorIntegration,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchUpdateConfigAldoDistributorIntegration(payload),
    {
      ...options,
      mutationKey: useFetchConfigAldoDistributorIntegrationKey,
      onSuccess: (data, variables, context) => {
        message.success("Configuração da integração com a Aldo foi atualizada");

        queryClient.invalidateQueries(useFetchGetStructureDistributorsKey);
        queryClient.invalidateQueries(useFetchGetPaginatedDistributorsKey);
        queryClient.invalidateQueries(useFetchGetCompanyDistributorsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao configurar: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
