import { useQuery } from "react-query";

import { IUseFetchGetCompanyDistributors } from "./interface";

import { fetchGetCompanyDistributors } from "~api/company/distributors/get";

export const useFetchGetCompanyDistributorsKey = "api/company/distributors/get";

export function useFetchGetCompanyDistributors({
  dependencyArray = [],
  options,
}: IUseFetchGetCompanyDistributors) {
  return useQuery(
    [useFetchGetCompanyDistributorsKey, dependencyArray],
    async () => await fetchGetCompanyDistributors(),
    options,
  );
}
