import {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { Router } from "next/router";

import type {
  IAppConfigContext,
  IAppConfigContextProvider,
  AppConfigThemeType,
} from "./interface";

import {
  THEME_STORAGE_KEY,
  MESSAGE_NOTIFICATION_SESSION_STORAGE_KEY,
} from "~configs/AppConfig";
import { ENV } from "~configs/Env";
import { useWindowSize } from "~hooks/useWindowSize";
import { database } from "~services/firebase";
import { queryClient } from "~services/queryClient";
import { theme } from "~styles/themes";
import { checkIfIsValidTheme } from "~utils/checkIfIsValidTheme";
import {
  ConfigProvider,
  message as antdMessage,
  notification as antdNotification,
} from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import dayjs from "dayjs";
import { onValue, ref } from "firebase/database";
import NProgress from "nprogress";

export const AppConfigContext = createContext({} as IAppConfigContext);

AppConfigContext.displayName = "AppConfigContext";

dayjs.locale("pt-br");

export function AppConfigContextProvider({
  children,
  defaultTheme = "default",
}: IAppConfigContextProvider) {
  const windowSize = useWindowSize();

  // START LOAD N PROGRESS - controls the progress bar on top of the site when loading a page
  NProgress.configure({
    showSpinner: false,
    trickleSpeed: 300,
  });

  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });

  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });

  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  // END LOAD N PROGRESS

  // ANTD CONTROLS - controls all things of antd like theme, notifications, toasts...
  const [message, antdMessageContextHolder] = antdMessage.useMessage();
  const [notification, antdNotificationContextHolder] =
    antdNotification.useNotification();

  const [currentTheme, setCurrentTheme] =
    useState<AppConfigThemeType>(defaultTheme);

  const changeTheme = useCallback((theme: AppConfigThemeType) => {
    setCurrentTheme(theme);
    localStorage.setItem(THEME_STORAGE_KEY, theme);
  }, []);

  const changeHtmlClassTheme = useCallback((theme?: string) => {
    const htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.setAttribute("class", `${theme ?? "light"}`);
    htmlElement.setAttribute("style", `color-scheme: ${theme ?? "light"};`);
  }, []);

  const themeConfig = useMemo(() => {
    const defaultAlgorithm = theme[currentTheme].algorithm ?? [];

    const parsedAlgorithm =
      defaultAlgorithm instanceof Array ? defaultAlgorithm : [defaultAlgorithm];

    const themeConfig = {
      ...theme[currentTheme],
      algorithm: parsedAlgorithm,
    };

    return themeConfig;
  }, [currentTheme]);

  const memoizedContextValues: IAppConfigContext = useMemo(
    () => ({
      currentTheme,
      changeTheme,
      screens: {
        xs: windowSize.width <= 480,
        sm: windowSize.width >= 576,
        md: windowSize.width >= 768,
        lg: windowSize.width >= 992,
        xl: windowSize.width >= 1200,
        xxl: windowSize.width >= 1600,
        mobile: windowSize.width < 744,
        tablet: windowSize.width >= 744 && windowSize.width < 1280,
        desktop: windowSize.width >= 1280,
      },
      message,
      notification,
    }),
    [currentTheme, changeTheme, windowSize.width, message, notification],
  );

  useEffect(() => {
    const theme = localStorage.getItem(THEME_STORAGE_KEY) ?? "";

    switch (theme) {
      case "default":
        return setCurrentTheme("default");
      case "dark":
        return setCurrentTheme("dark");
      default:
        return localStorage.setItem(THEME_STORAGE_KEY, "default");
    }
  }, []);

  useEffect(() => {
    switch (currentTheme) {
      case "default":
        return changeHtmlClassTheme();
      case "dark":
        return changeHtmlClassTheme("dark");
      default:
        return;
    }
  }, [currentTheme, changeHtmlClassTheme]);

  useEffect(() => {
    queryClient.clear();
  }, []);
  // END ANTD

  // START APP NOTIFICATIONS WITH FIREBASE - notifications to force reload & another things
  useEffect(() => {
    const versionRef = ref(database, "version");
    const messageRef = ref(database, "messages");

    // APP VERSION NOTIFICATIONS
    const unsubscribeAppVersion = onValue(versionRef, (snapshot) => {
      const data: string | null | undefined = snapshot.val();
      const appVersion = ENV.APP_VERSION;

      if (data && appVersion && data > appVersion) {
        notification.info({
          message: "Aplicação desatualizada",
          placement: "topRight",
          description:
            "Nossos desenvolvedores lançaram uma nova versão da aplicação, por favor, recarregue a página quando possível",
          duration: 0,
        });
      }
    });

    // MESSAGES NOTIFICATIONS
    const unsubscribeMessage = onValue(messageRef, (snapshot) => {
      const data: null | Record<string, string> = snapshot.val();

      if (!data) return;

      const getMessageKeysFromStorage = () => {
        try {
          const storageMessageKeys = sessionStorage.getItem(
            MESSAGE_NOTIFICATION_SESSION_STORAGE_KEY,
          );

          if (!storageMessageKeys) return [];

          const parsedData = JSON.parse(storageMessageKeys);

          return Array.isArray(parsedData) ? parsedData : [];
        } catch {
          sessionStorage.removeItem(MESSAGE_NOTIFICATION_SESSION_STORAGE_KEY);
          return [];
        }
      };

      const keysOfMessageAlreadySent = getMessageKeysFromStorage();
      const messageAndKeysFromFirebase = Object.entries(data);

      const messagesAndKeysToSent = messageAndKeysFromFirebase.filter(
        ([key, _message]) => !keysOfMessageAlreadySent.includes(key),
      );

      messagesAndKeysToSent.map(([key, message], index) => {
        setTimeout(() => {
          notification.info({
            message: "AVISO",
            description: <div dangerouslySetInnerHTML={{ __html: message }} />,
            duration: 0,
            placement: "topRight",
          });
        }, index * 3000);

        const updatedStorageMessageKeys = [...getMessageKeysFromStorage(), key];

        sessionStorage.setItem(
          MESSAGE_NOTIFICATION_SESSION_STORAGE_KEY,
          JSON.stringify(updatedStorageMessageKeys),
        );
      });
    });

    return () => {
      unsubscribeAppVersion();
      unsubscribeMessage();
    };
  }, [notification]);
  // END APP NOTIFICATION WITH FIREBASE

  return (
    <AppConfigContext.Provider value={memoizedContextValues}>
      <ConfigProvider theme={themeConfig} locale={ptBR}>
        {antdMessageContextHolder}
        {antdNotificationContextHolder}
        {children}
      </ConfigProvider>
    </AppConfigContext.Provider>
  );
}

export const ConfigConsumer = AppConfigContext.Consumer;
