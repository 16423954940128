import { api, apiException } from "~services/api";
import {
  FetchUpdateUserPayloadSchema,
  IFetchUpdateUserPayloadSchema,
  UserAccountDTOSchema,
} from "~types/schemas";

export const fetchUpdateUser = async (
  payload: IFetchUpdateUserPayloadSchema,
) => {
  try {
    const { userId, ...body } = FetchUpdateUserPayloadSchema.parse(payload);

    const response = await api.put(`/users/${userId}`, body);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
