import { colors } from "./colors";

import { theme } from "antd";
import type { ThemeConfig } from "antd/lib/config-provider/context";

export const darkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: colors["primary.500"],
    colorBgContainer: colors.backgroundContainer,
    colorBgLayout: colors.backgroundLayout,
    colorLink: colors["primary.500"],
    colorLinkHover: colors["primary.300"],
    colorLinkActive: colors["primary.300"],
    fontFamily:
      "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Layout: {
      headerBg: colors.backgroundContainer,
      siderBg: colors.backgroundContainer,
      triggerBg: colors.backgroundContainer,
    },
    Menu: {
      itemBg: colors.backgroundContainer,
      subMenuItemBg: colors.backgroundContainer,
    },
  },
};

export const darkColors = colors;
