import { useMutation } from "react-query";

import { useFetchGetAlternatingCurrentMaterialCalcBaseKey } from "../useFetchGetAlternatingCurrentMaterialCalcBase";
import type { IUseFetchUpdateAlternatingCurrentMaterialCalcBase } from "./interface";

import { fetchUpdateAlternatingCurrentMaterialCalcBase } from "~api/ac-materials/calcBase/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAlternatingCurrentMaterialCalcBaseKey =
  "api/ac-materials/calcBase/put";

export function useFetchUpdateAlternatingCurrentMaterialCalcBase({
  options,
}: IUseFetchUpdateAlternatingCurrentMaterialCalcBase) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchUpdateAlternatingCurrentMaterialCalcBase(payload),
    {
      ...options,
      mutationKey: useFetchUpdateAlternatingCurrentMaterialCalcBaseKey,
      onSuccess: (data, variables, context) => {
        message.success("Base de cálculo do material foi atualizada!");
        queryClient.invalidateQueries(
          useFetchGetAlternatingCurrentMaterialCalcBaseKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          "Falha ao atualizar base de cálculo do material: " + error.message,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
