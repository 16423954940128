import { api, apiException } from "~services/api";
import { ReasonForLossResponseSchema } from "~types/schemas";

export const fetchGetAllReasonForLoss = async () => {
  try {
    const response = await api.get(`/reason-for-loss`);

    const parsedData = ReasonForLossResponseSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
