import { useMutation } from "react-query";

import { useFetchGetAllLaborsKey } from "../useFetchGetPaginatedLabors";
import type { IUseFetchCreateLaborCopy } from "./interface";

import { fetchCreateLaborCopy } from "~api/labors/{id}/copy/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateLaborCopyKey = "api/labors/post";

export function useFetchCreateLaborCopy({ options }: IUseFetchCreateLaborCopy) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateLaborCopy(payload), {
    ...options,
    mutationKey: useFetchCreateLaborCopyKey,
    onSuccess: (data, variables, context) => {
      message.success("Cópia foi gerada com sucesso!");
      queryClient.invalidateQueries(useFetchGetAllLaborsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao copiar mão de obra: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
