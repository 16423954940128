import { z } from "zod";

export const RuleExpressionDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  expression: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IRuleExpressionDTOSchema
  extends z.infer<typeof RuleExpressionDTOSchema> {}
