import {
  ProjectTypeEnum,
  ProposalStatusEnum,
  ProposalStepEnum,
} from "~types/enum";
import { z } from "zod";

export const ProposalByDealIdResponseSchema = z.object({
  id: z.number().int().gt(0),
  formattedIdentifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customIdProposalFormat: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  clientName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valueForCustomer: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cityName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  lastStep: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  lastStepEnum: ProposalStepEnum.nullish().transform((val) => val ?? "DATA"),
  pipedriveDealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined), // zero is acceptable
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  sellerName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  linkProposal: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  finished: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  haveIntermediateStep: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  havePaymentStep: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? "DEFAULT"),
});

export interface IProposalByDealIdResponseSchema
  extends z.infer<typeof ProposalByDealIdResponseSchema> {}
