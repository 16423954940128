import { HistoryFilterEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetHistoriesFilterByOrganizationIdPayloadSchema = z.object({
  organizationId: z.number().int().gt(0),
  searchQuery: HistoryFilterEnum,
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 5),
});

export interface IFetchGetHistoriesFilterByOrganizationIdPayloadSchema
  extends z.infer<
    typeof FetchGetHistoriesFilterByOrganizationIdPayloadSchema
  > {}
