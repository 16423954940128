import { useQuery } from "react-query";

import type { IUseFetchGetAutoCompleteProducts } from "./interface";

import { fetchGetAutoCompleteProducts } from "~api/product/search/get";

export const useFetchGetAutoCompleteProductsKey = "api/product/search/get";

export function useFetchGetAutoCompleteProducts({
  dependencyArray = [],
  options,
  payload = {},
}: IUseFetchGetAutoCompleteProducts) {
  return useQuery(
    [useFetchGetAutoCompleteProductsKey, dependencyArray],
    async () => await fetchGetAutoCompleteProducts(payload),
    options,
  );
}
