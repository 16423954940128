import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedUsersByCompany } from "./interface";

import { fetchGetPaginatedUsersByCompany } from "~api/company/users/get";

export const useFetchGetPaginatedUsersByCompanyKey = "api/company/users/get";

export function useFetchGetPaginatedUsersByCompany({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedUsersByCompany) {
  return useQuery(
    [useFetchGetPaginatedUsersByCompanyKey, dependencyArray],
    async () => fetchGetPaginatedUsersByCompany(payload),
    options,
  );
}
