import { z } from "zod";

export const KomecoEquipmentModuleDTOSchema = z.object({
  id: z.string().min(1),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IKomecoEquipmentModuleDTOSchema
  extends z.infer<typeof KomecoEquipmentModuleDTOSchema> {}
