import { z } from "zod";

export const ResponseGetLatestVersionPDFFinalizedProposal = z.object({
  filePdfInBase64: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IResponseGetLatestVersionPDFFinalizedProposal
  extends z.infer<typeof ResponseGetLatestVersionPDFFinalizedProposal> {}
