import { api, apiException } from "~services/api";
import {
  FetchGetProgressInsightDtoPayloadSchema,
  type IFetchGetProgressInsightDtoPayloadSchema,
  ProgressInsightDTOSchema,
} from "~types/schemas";

export const fetchGetProgressInsightDto = async (
  payload: IFetchGetProgressInsightDtoPayloadSchema,
) => {
  try {
    const queryParams = FetchGetProgressInsightDtoPayloadSchema.parse(payload);

    const response = await api.get("/insights/progressInsightDto", {
      params: queryParams,
    });

    const parsedData = ProgressInsightDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
