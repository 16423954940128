import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetPipedriveUsersKey } from "../../pipedrive/useFetchGetPipedriveUsers";
import { useFetchGetPaginatedUsersByCompanyKey } from "../useFetchGetPaginatedUsersByCompany";
import type { IUseFetchRemoveUserFromCompanyByUserId } from "./interface";

import { fetchDeleteUserFromCompanyByUserId } from "~api/company/users/remove/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchRemoveUserFromCompanyByUserIdKey =
  "api/company/users/remove/{id}/delete";

export function useFetchRemoveUserFromCompanyByUserId(
  options?: IUseFetchRemoveUserFromCompanyByUserId,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchDeleteUserFromCompanyByUserId(payload);
    },
    {
      ...options,
      mutationKey: useFetchRemoveUserFromCompanyByUserIdKey,
      onSuccess: (data, variables, context) => {
        message.success(data.message);
        queryClient.invalidateQueries(useFetchGetPaginatedUsersByCompanyKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        queryClient.invalidateQueries(useFetchGetPipedriveUsersKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao remover usuário:" + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
