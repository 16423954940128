import {
  PipedriveDealStatusEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
} from "~types/enum";
import { z } from "zod";

export const FetchPostToGetPaginatedProposalsPayloadSchema = z
  .object({
    id: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    clientName: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    sellerName: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    fullPower: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    valueForCustomer: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    cityName: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    pipedriveDealStatus: z
      .array(PipedriveDealStatusEnum)
      .nullish()
      .transform((val) => val ?? undefined),
    status: z
      .array(ProposalStatusEnum)
      .nullish()
      .transform((val) => val ?? undefined),
    page: z
      .number()
      .gte(0)
      .transform((val) => val ?? 0),
    size: z
      .number()
      .gte(0)
      .transform((val) => val ?? 10),
    projectType: z
      .array(ProjectTypeEnum)
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface IFetchPostToGetPaginatedProposalsPayloadSchema
  extends z.infer<typeof FetchPostToGetPaginatedProposalsPayloadSchema> {}
