import { useQuery } from "react-query";

import { IUseFetchSearchDeal } from "./interface";

import { fetchSearchDeal } from "~api/deal/auto-complete/get";

export const useFetchSearchDealKey = "api/deal/auto-complete/get";

export function useFetchSearchDeal({
  dependencyArray = [],
  options,
  payload = {
    term: "",
  },
}: IUseFetchSearchDeal) {
  return useQuery(
    [useFetchSearchDealKey, dependencyArray],
    async () => await fetchSearchDeal(payload),
    options,
  );
}
