import { DealCustomFieldGroupListItemSchema } from "./DealCustomFieldGroupListItemSchema";
import { EntityIdentifierDTOSchema } from "./EntityIdentifierDTOSchema";
import { PipelineStageItemSchema } from "./PipelineStageItemSchema";
import { SimplePipelineDTOSchema } from "./SimplePipelineDTOSchema";

import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const DealCustomFieldResponseSchema = z.object({
  id: z.number().int().gt(0),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  type: FieldTypeEnum.nullish().transform((val) => val ?? undefined),
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  displayOrder: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  pipelinesRequired: PipelineStageItemSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  futurePipelinesIncluded: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  group: DealCustomFieldGroupListItemSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  entityIdentifier: EntityIdentifierDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  pipelines: SimplePipelineDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IDealCustomFieldResponseSchema
  extends z.infer<typeof DealCustomFieldResponseSchema> {}
