import { useMutation } from "react-query";

import type { IUseFetchSendNewPassword } from "./interface";

import { fetchSendNewPassword } from "~api/users/reset-password/post";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchSendNewPasswordKey = "api/users/reset-password/post";

export function useFetchSendNewPassword({ options }: IUseFetchSendNewPassword) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchSendNewPassword(payload), {
    ...options,
    mutationKey: useFetchSendNewPasswordKey,
    onSuccess: (data, variables, context) => {
      message.success("A senha foi alterada!");
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error?.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
