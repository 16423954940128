import { api, apiException } from "~services/api";
import {
  FetchGetSellersAutoCompletePayloadSchema,
  IFetchGetSellersAutoCompletePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetSellersAutoComplete = async (
  payload?: IFetchGetSellersAutoCompletePayloadSchema,
) => {
  try {
    const params = FetchGetSellersAutoCompletePayloadSchema.parse(payload);

    const response = await api.get("/proposal/sellers/auto-complete", {
      params,
    });

    const parsedData = z.array(z.string()).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
