import { useQuery } from "react-query";

import type { IUseFetchGetBusinessUnitById } from "./interface";

import { fetchGetBusinessUnitById } from "~api/business-units/{id}/get";

export const useFetchGetBusinessUnitKey = "api/business-units/{id}/get";

export function useFetchGetBusinessUnitById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetBusinessUnitById) {
  return useQuery(
    [useFetchGetBusinessUnitKey, dependencyArray],
    async () => await fetchGetBusinessUnitById(payload),
    options,
  );
}
