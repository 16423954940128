import { api, apiException } from "~services/api";
import {
  UpdateManyProposalPaymentPayloadSchema,
  IUpdateManyProposalPaymentPayloadSchema,
} from "~types/schemas";

export const fetchUpdateManyProposalPayment = async (
  payload: IUpdateManyProposalPaymentPayloadSchema,
) => {
  try {
    const body = UpdateManyProposalPaymentPayloadSchema.parse(payload);

    const { data } = await api.put(
      `/project/payment/simulation-item-project/custom-id/${body.customId}`,
      body.payments,
    );

    return data;
  } catch (error) {
    throw apiException(error);
  }
};
