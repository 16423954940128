import { useQuery } from "react-query";

import type { IUseFetchGetExcelExport } from "./interface";

import { fetchGetExcelExport } from "~api/product/import/export/get";

export const UseFetchGetExcelExportKey = "api/product/import/export/get";

export function useFetchGetExcelExport({
  dependencyArray = [],
  options,
}: IUseFetchGetExcelExport) {
  return useQuery(
    [UseFetchGetExcelExportKey, dependencyArray],
    async () => await fetchGetExcelExport(),
    options,
  );
}
