import { PersonFormSchema } from "./PersonFormSchema";

import { z } from "zod";

export const FetchUpdatePersonPayloadSchema = PersonFormSchema.extend({
  personId: z.number().int().gt(0),
});

export interface IFetchUpdatePersonPayloadSchema
  extends z.infer<typeof FetchUpdatePersonPayloadSchema> {}
