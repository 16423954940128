import { useQuery } from "react-query";

import type { IUseFetchGetAllUsersGroups } from "./interface";

import { fetchGetAllUsersGroups } from "~api/usergroup/no-pageable/get";

export const useFetchGetAllUsersGroupsKey = "api/usergroup/no-pageable/get";

export function useFetchGetAllUsersGroups({
  dependencyArray = [],
  options,
}: IUseFetchGetAllUsersGroups) {
  return useQuery(
    [useFetchGetAllUsersGroupsKey, dependencyArray],
    async () => {
      return await fetchGetAllUsersGroups();
    },
    options,
  );
}
