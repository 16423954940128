import { useMutation } from "react-query";

import { useFetchGetReportsKey } from "../useFetchGetReports";
import type { IUseFetchDeleteReportTemplate } from "./interface";

import { fetchDeleteReportTemplateById } from "~api/reports/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteReportTemplateKey = "api/reports/{id}/delete";

export function useFetchDeleteReportTemplate(
  options?: IUseFetchDeleteReportTemplate,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteReportTemplateById(payload),
    {
      ...options,
      mutationKey: useFetchDeleteReportTemplateKey,
      onSuccess: (data, variables, context) => {
        message.success("Template foi apagado!");
        queryClient.invalidateQueries(useFetchGetReportsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
