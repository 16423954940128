import { z } from "zod";

export const CreatorUserIdSchema = z.object({
  id: z.number().int().gt(0),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICreatorUserIdSchema
  extends z.infer<typeof CreatorUserIdSchema> {}
