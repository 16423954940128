import { DiscountTypeEnum } from "~types/enum";
import { z } from "zod";

export const EquipmentKitDiscountFormSchema = z
  .object({
    value: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    discountType: DiscountTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  })
  .partial();

export interface IEquipmentKitDiscountFormSchema
  extends z.infer<typeof EquipmentKitDiscountFormSchema> {}
