import { FormFieldDTOSchema } from "./FormFieldDTOSchema";

import { z } from "zod";

export const DefaultFieldSettingsDTOSchema = z.object({
  id: z.number().int().gt(0).nullish(),
  formField: FormFieldDTOSchema.nullish().transform((val) => val ?? undefined),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IDefaultFieldSettingsDTOSchema
  extends z.infer<typeof DefaultFieldSettingsDTOSchema> {}
