import { useQuery } from "react-query";

import { IUseFetchGetCustomFieldsSectionsByProposalStep } from "./interface";

import { fetchGetCustomFieldsSectionsByProposalStep } from "~api/config/customFields/sections/get";

export const useFetchGetCustomFieldsSectionsByProposalStepKey =
  "api/config/customFields/sections/get";

export function useFetchGetCustomFieldsSectionsByProposalStep({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetCustomFieldsSectionsByProposalStep) {
  return useQuery(
    [useFetchGetCustomFieldsSectionsByProposalStepKey, dependencyArray],
    async () => await fetchGetCustomFieldsSectionsByProposalStep(payload),
    options,
  );
}
