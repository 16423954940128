import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import type { IUseFetchChangePassword } from "./interface";

import { fetchChangePassword } from "~api/users/change-password/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchChangePasswordKey = "api/users/change-password/post";

export function useFetchChangePassword({ options }: IUseFetchChangePassword) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchChangePassword(payload), {
    ...options,
    mutationKey: useFetchChangePasswordKey,
    onSuccess: (data, variables, context) => {
      message.success("Senha foi alterada");
      queryClient.invalidateQueries(useFetchGetSessionDataKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao alterar senha: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
