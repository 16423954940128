import { PipedriveLeadTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchUpdatePipedriveLeadPayloadSchema = z
  .object({
    pipedriveLeadType: PipedriveLeadTypeEnum,
  })
  .partial();

export interface IFetchUpdatePipedriveLeadPayloadSchema
  extends z.infer<typeof FetchUpdatePipedriveLeadPayloadSchema> {}
