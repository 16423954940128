import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedReasonForLoss } from "./interface";

import { fetchGetPaginatedReasonForLoss } from "~api/reason-for-loss/page/{page}/size/{size}/get";

export const useFetchGetPaginatedReasonForLossKey =
  "api/reason-for-loss/page/{page}/size/{size}/get";

export function useFetchGetPaginatedReasonForLoss({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedReasonForLoss) {
  return useQuery(
    [useFetchGetPaginatedReasonForLossKey, dependencyArray],
    async () => await fetchGetPaginatedReasonForLoss(payload),
    options,
  );
}
