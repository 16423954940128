import { z } from "zod";

export const SimultaneityProfileDTOSchema = z
  .object({
    id: z.number().int().gt(0),
    description: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    value: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    active: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    default: z
      .boolean()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface ISimultaneityProfileDTOSchema
  extends z.infer<typeof SimultaneityProfileDTOSchema> {}
