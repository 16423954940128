import { useQuery } from "react-query";

import type { IUseFetchGetProgressInsightDto } from "./interface";

import { fetchGetProgressInsightDto } from "~api/insights/progressInsightDto/get";

export const useFetchGetProgressInsightDtoKey =
  "api/insights/progressInsightDto/get";

export function useFetchGetProgressInsightDto({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProgressInsightDto) {
  return useQuery(
    [useFetchGetProgressInsightDtoKey, dependencyArray],
    async () => await fetchGetProgressInsightDto(payload),
    options,
  );
}
