import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";

import { z } from "zod";

export const StructureMaterialCostRuleDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  valuePerModule: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valuePerWp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  structureType: StructureTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  additionalCostTaxId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  notApplyDefaultAdditionalCostTax: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IStructureMaterialCostRuleDTOSchema
  extends z.infer<typeof StructureMaterialCostRuleDTOSchema> {}
