import { z } from "zod";

export const typeEosProjectEnum = [
  "SOLAR",
  "HOMEIMPROVE",
  "WORKING_CAPITAL",
] as const;

export const TypeEosProjectEnum = z
  .enum(typeEosProjectEnum)
  .catch((e) => e.input);

export type TypeEosProjectEnum = z.infer<typeof TypeEosProjectEnum>;
