import { z } from "zod";

export const UserFilterSchema = z
  .object({
    company: z.string(),
    email: z.string(),
    id: z.number().int().gt(0),
    page: z.number().int().gte(0),
    size: z.number().int().gte(0),
  })
  .partial();

export interface IUserFilterSchema extends z.infer<typeof UserFilterSchema> {}
