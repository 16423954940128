import { TaxTypeEnum, VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetConcessionaireProfilePayloadSchema = z.object({
  concessionaireProfileId: z.number().int().optional(),
  electricUtilityId: z.number().int().gt(0).optional(),
  voltageType: VoltageTypeEnum.optional(),
  profileName: z.string().optional(),
  modalidade: TaxTypeEnum.optional(),
});

export interface IFetchGetConcessionaireProfilePayloadSchema
  extends z.infer<typeof FetchGetConcessionaireProfilePayloadSchema> {}
