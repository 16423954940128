import { InverterSummaryDTOSchema } from "./InverterSummaryDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedInverterSummaryDTOSchema = PaginationSchema(
  InverterSummaryDTOSchema,
);

export interface IPaginatedInverterSummaryDTOSchema
  extends z.infer<typeof PaginatedInverterSummaryDTOSchema> {}
