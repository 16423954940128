import { useMutation } from "react-query";

import { useFetchGetProjectRevisionKey } from "../useFetchGetProjectRevisionById";
import type { IUseFetchUpdateStepOne } from "./interface";

import { fetchUpdateProposalStepOne } from "~api/projects/step_one/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const UseFetchUpdateStepOneKey = "api/projects/step_one/{id}/put";

export function useFetchUpdateStepOne({ options }: IUseFetchUpdateStepOne) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProposalStepOne(payload),
    {
      ...options,
      mutationKey: UseFetchUpdateStepOneKey,
      onSuccess: (data, variables, context) => {
        message.info("Proposta foi atualizada");
        queryClient.invalidateQueries(useFetchGetProjectRevisionKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
