import { OrganizationDefaultFieldDTOSchema } from "./OrganizationDefaultFieldDTOSchema";

import { z } from "zod";

export const OrganizationDefaultFieldSettingsDTOSchema = z.object({
  id: z.number().int().gt(0),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  organizationDefaultField:
    OrganizationDefaultFieldDTOSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
});

export interface IOrganizationDefaultFieldSettingsDTOSchema
  extends z.infer<typeof OrganizationDefaultFieldSettingsDTOSchema> {}
