import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedOrganization } from "./interface";

import { fetchGetPaginatedOrganization } from "~api/organizations/get";

export const fetchGetPaginatedOrganizationKey = "api/organizations/get";

export function useFetchGetPaginatedOrganization({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPaginatedOrganization) {
  return useQuery(
    [fetchGetPaginatedOrganizationKey, dependencyArray],
    async () => await fetchGetPaginatedOrganization(payload),
    options,
  );
}
