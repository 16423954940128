import { api, apiException } from "~services/api";
import {
  FetchPatchTogglePersonStatusPayloadSchema,
  type IFetchPatchTogglePersonStatusPayloadSchema,
} from "~types/schemas";

export const fetchPatchTogglePersonStatus = async (
  payload: IFetchPatchTogglePersonStatusPayloadSchema,
) => {
  try {
    const { clientId } =
      FetchPatchTogglePersonStatusPayloadSchema.parse(payload);

    await api.patch(`/persons/${clientId}`);
  } catch (error) {
    throw apiException(error);
  }
};
