import { DealDefaultFieldsDTOSchema } from "./DealDefaultFieldsDTOSchema";

import { z } from "zod";

export const DealDefaultFieldsSettingsDTOSchema = z.object({
  id: z.number().int().gt(0),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  dealDefaultField: DealDefaultFieldsDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IDealDefaultFieldsSettingsDTOSchema
  extends z.infer<typeof DealDefaultFieldsSettingsDTOSchema> {}
