import { HistoryPersonDTOSchema } from "./HistoryPersonDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedHistoryPersonFilterDTOSchema = PaginationSchema(
  HistoryPersonDTOSchema,
);

export interface IPaginatedHistoryPersonFilterDTOSchema
  extends z.infer<typeof PaginatedHistoryPersonFilterDTOSchema> {}
