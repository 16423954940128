import { useMutation } from "react-query";

import { useFetchGetProposalCustomFieldSettingsByProfileKey } from "../useFetchGetProposalCustomFieldSettingsByProfile";
import { useFetchGetProposalDefaultFieldSettingsByProfileKey } from "../useFetchGetProposalDefaultFieldSettingsByProfile";
import type { IUseFetchDeleteDataFields } from "./interface";

import { fetchDeleteDataFields } from "~api/default-fields/profile-settings/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteDataFieldsKey =
  "api/default-fields/profile-settings/delete";

export function useFetchDeleteDataFields(options?: IUseFetchDeleteDataFields) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => fetchDeleteDataFields(payload), {
    ...options,
    mutationKey: useFetchDeleteDataFieldsKey,
    onSuccess: (data, variables, context) => {
      message.success("Configuração do campos para o perfil foi removido!");
      queryClient.invalidateQueries(
        useFetchGetProposalCustomFieldSettingsByProfileKey,
      );
      queryClient.invalidateQueries(
        useFetchGetProposalDefaultFieldSettingsByProfileKey,
      );
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error("Falha ao remover configuração: " + error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
