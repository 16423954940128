import { useQuery } from "react-query";

import type { IUseFetchGetExcelDefault } from "./interface";

import { fetchGetExcelDefault } from "~api/product/download-template/get";

export const UseFetchGetExcelDefaultKey =
  "api/product/import/download-template/get";

export function useFetchGetExcelDefault({
  dependencyArray = [],
  options,
}: IUseFetchGetExcelDefault) {
  return useQuery(
    [UseFetchGetExcelDefaultKey, dependencyArray],
    async () => await fetchGetExcelDefault(),
    options,
  );
}
