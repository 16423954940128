import { api, apiException } from "~services/api";
import {
  type IFetchCreatePipelinePayloadSchema,
  FetchCreatePipelinePayloadSchema,
  PipelineDTOSchema,
} from "~types/schemas";

export const fetchCreatePipeline = async (
  payload: IFetchCreatePipelinePayloadSchema,
) => {
  try {
    const body = FetchCreatePipelinePayloadSchema.parse(payload);

    const response = await api.post(`/pipeline`, body);

    const parsedData = PipelineDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
