import { useQuery } from "react-query";

import type { IUseFetchGetUserCompanyConfiguration } from "./interface";

import { fetchGetUserCompanyConfiguration } from "~api/admin/users/{companyId}/configurations/get";

export const useFetchGetUserCompanyConfigurationKey =
  "api/admin/users/{companyId}/configurations/get";

export function useFetchGetUserCompanyConfiguration({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetUserCompanyConfiguration) {
  return useQuery(
    [useFetchGetUserCompanyConfigurationKey, dependencyArray],
    async () => await fetchGetUserCompanyConfiguration(payload),
    options,
  );
}
