import type { ValueOf } from "~helpers";
import type { Locale as AntLocale } from "antd/lib/locale";
// ANT-DESIGN LOCALES
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import ptBR from "antd/lib/locale/pt_BR";

// DAYJS LOCALES
import "dayjs/locale/pt-br";
import "dayjs/locale/es";
import "dayjs/locale/en";

export const THEME_STORAGE_KEY = "@solarz_crm:theme-1.0.0";
export const THEME_COMPACT_STORAGE_KEY = "@solarz_crm:theme-compact-1.0.0";
export const LOCALE_STORAGE_KEY = "@solarz_crm:locale-1.0.0";
export const MESSAGE_NOTIFICATION_SESSION_STORAGE_KEY =
  "@solarz_crm:messsage-notification-1.0.0";
export const RELEASE_NOTE_READ_STORAGE_KEY = "@solarz_crm:release-note-1.0.0";

// PIPELINE PAGE
export const PAGE_PIPELINE_LAST_PIPELINE_ID_KEY =
  "@solarz_crm:page-pipeline:last-pipeline-id";

export const PAGE_PIPELINE_LAST_PIPELINE_VIEW_MODE_KEY =
  "@solarz_crm:page-pipeline:last-pipeline-view-mode";

// INSIGHTS PAGE
export const PAGE_INSIGHTS_LAST_PIPELINE_ID_KEY =
  "@solarz_crm:page-insights:last-pipeline-id";

export const locales = {
  "pt-br": {
    key: "pt-br",
    antdLocale: ptBR,
    intlLocale: "pt-BR",
    dayjsLocale: "pt-br",
  },
  es: {
    key: "es",
    antdLocale: esES,
    intlLocale: "es-ES",
    dayjsLocale: "es",
  },
  us: {
    key: "us",
    antdLocale: enUS,
    intlLocale: "us-US",
    dayjsLocale: "us",
  },
} as const;

export type LocaleType = keyof typeof locales;
export type IntlLocale = ValueOf<typeof locales>["intlLocale"];
export type AppLocale = {
  key: LocaleType;
  antdLocale: AntLocale;
  intlLocale: IntlLocale;
};
