import { useQuery } from "react-query";

import type { IUseFetchGetAllCustomVariables } from "./interface";

import { fetchGetAllCustomVariables } from "~api/custom-variable/no-page/get";

export const useFetchGetAllCustomVariablesKey =
  "api/custom-variable/no-page/get";

export function useFetchGetAllCustomVariables({
  dependencyArray = [],
  options,
}: IUseFetchGetAllCustomVariables) {
  return useQuery(
    [useFetchGetAllCustomVariablesKey, dependencyArray],
    async () => await fetchGetAllCustomVariables(),
    options,
  );
}
