import { useQuery } from "react-query";

import type { IUseFetchGetPdfBase64FromDatasheetRevisionById } from "./interface";

import { fetchGetPdfBase64FromDatasheetRevisionById } from "~api/admin/datasheets/{id}/revision/{id}/get";

export const useFetchGetPdfBase64FromDatasheetRevisionByIdKey =
  "api/admin/datasheets/{id}/revision/{id}/get";

export function useFetchGetPdfBase64FromDatasheetRevisionById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPdfBase64FromDatasheetRevisionById) {
  return useQuery(
    [useFetchGetPdfBase64FromDatasheetRevisionByIdKey, dependencyArray],
    async () => await fetchGetPdfBase64FromDatasheetRevisionById(payload),
    options,
  );
}
