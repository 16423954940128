import { useMutation } from "react-query";

import { useFetchPostToGetPaginatedProposalsKey } from "../../proposal/useFetchPostToGetPaginatedProposals";
import type { IUseFetchDeleteProjectByCustomId } from "./interface";

import { fetchDeleteProjectByCustomId } from "~api/projects/custom-id/{custom-id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteProjectByCustomIdKey =
  "api/projects/custom-id/{custom-id}/delete";

export function useFetchDeleteProjectByCustomId({
  options,
}: IUseFetchDeleteProjectByCustomId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteProjectByCustomId(payload),
    {
      ...options,
      mutationKey: useFetchDeleteProjectByCustomIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Proposta foi apagada!");
        queryClient.invalidateQueries(useFetchPostToGetPaginatedProposalsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao apagar proposta: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
