import { api, apiException } from "~services/api";
import {
  PricingTypeDTOSchema,
  FetchUpdatePricingTypesPayloadSchema,
  IFetchUpdatePricingTypesPayloadSchema,
} from "~types/schemas";

export const fetchUpdatePricingTypes = async (
  payload: IFetchUpdatePricingTypesPayloadSchema,
) => {
  try {
    const { pricingId, ...body } =
      FetchUpdatePricingTypesPayloadSchema.parse(payload);

    const response = await api.put(`/pricing-types/${pricingId}`, body);

    const parsedData = PricingTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
