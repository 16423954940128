import { ClientSearchPipedriveDTOSchema } from "./ClientSearchPipedriveDTOSchema";

import { z } from "zod";

export const DealSearchPipedriveDTOSchema = z.object({
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  pipedriveId: z.number().int().gt(0),
  client: ClientSearchPipedriveDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  address: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDealSearchPipedriveDTOSchema
  extends z.infer<typeof DealSearchPipedriveDTOSchema> {}
