import { z } from "zod";

export const EosContactSchema = z.object({
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  telefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosContactSchema extends z.infer<typeof EosContactSchema> {}
