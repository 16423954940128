import { api, apiException } from "~services/api";
import {
  AdditionalCostDTOSchema,
  CreateCopyComplementaryItemsPayloadSchema,
  ICreateCopyComplementaryItemsPayloadSchema,
} from "~types/schemas";

export const fetchCreateCopyComplementaryItems = async (
  payload: ICreateCopyComplementaryItemsPayloadSchema,
) => {
  try {
    const { complementaryItemId, ...body } =
      CreateCopyComplementaryItemsPayloadSchema.parse(payload);

    const response = await api.post(
      `/additional-costs/${complementaryItemId}/copy`,
      body,
    );

    const parsedData = AdditionalCostDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
