import { api, apiException } from "~services/api";
import {
  ReportResponseDTOSchema,
  FetchGetReportPdfBase64PayloadSchema,
  IFetchGetReportPdfBase64PayloadSchema,
} from "~types/schemas";

export const fetchGetReportPdfBase64 = async (
  path: IFetchGetReportPdfBase64PayloadSchema,
) => {
  try {
    const { uuid } = FetchGetReportPdfBase64PayloadSchema.parse(path);

    const response = await api.get(`/reports/download/pdf/base64/${uuid}`);

    const parsedData = ReportResponseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
