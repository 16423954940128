import { z } from "zod";

export const ReasonDealLostSchema = z.object({
  reason: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  comments: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IReasonDealLostSchema
  extends z.infer<typeof ReasonDealLostSchema> {}
