import { useQuery } from "react-query";

import type { IUseFetchGetProjectRevisionById } from "./interface";

import { fetchGetProjectRevisionById } from "~api/projects/revision/{id}/get";

export const useFetchGetProjectRevisionKey = "api/projects/revision/{id}/get";

export function useFetchGetProjectRevisionById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProjectRevisionById) {
  return useQuery(
    [useFetchGetProjectRevisionKey, dependencyArray],
    async () => await fetchGetProjectRevisionById(payload),
    options,
  );
}
