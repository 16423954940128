import { api, apiException } from "~services/api";
import {
  type IFetchGetPaginatedPersonDealsByPersonIdPayloadSchema,
  FetchGetPaginatedPersonDealsByPersonIdPayloadSchema,
  PaginatedDealPersonDTOSchema,
} from "~types/schemas";

export async function fetchGetPaginatedPersonDealsByPersonId(
  payload: IFetchGetPaginatedPersonDealsByPersonIdPayloadSchema,
) {
  try {
    const { personId, ...queryParams } =
      FetchGetPaginatedPersonDealsByPersonIdPayloadSchema.parse(payload);

    const response = await api.get(`/persons/${personId}/deals`, {
      params: queryParams,
    });

    const parsedData = PaginatedDealPersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
