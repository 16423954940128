import { z } from "zod";

export const FetchGetPaginatedKitsPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IFetchGetPaginatedKitsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedKitsPayloadSchema> {}
