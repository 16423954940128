import { InverterFormSchema } from "./InverterFormSchema";

import { z } from "zod";

export const FetchUpdateAdminInvertersPayloadSchema = InverterFormSchema.extend(
  {
    id: z.number({ message: "ID do inversor é obrigatório! " }).int().gt(0),
  },
);

export interface IFetchUpdateAdminInvertersPayloadSchema
  extends z.infer<typeof FetchUpdateAdminInvertersPayloadSchema> {}
