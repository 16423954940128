import { StructureDistributorFormSchema } from "./StructureDistributorFormSchema";

import { z } from "zod";

export const FetchUpdateStructureDistributorsPayloadSchema = z.array(
  StructureDistributorFormSchema,
);

export interface IFetchUpdateStructureDistributorsPayloadSchema
  extends z.infer<typeof FetchUpdateStructureDistributorsPayloadSchema> {}
