import { api, apiException } from "~services/api";
import {
  type IFetchGetPersonsFromOrganizationPayloadSchema,
  FetchGetPersonsFromOrganizationPayloadSchema,
  PersonDTOSchema,
} from "~types/schemas";

export const fetchGetPersonsFromOrganization = async (
  payload: IFetchGetPersonsFromOrganizationPayloadSchema,
) => {
  try {
    const { organizationId } =
      FetchGetPersonsFromOrganizationPayloadSchema.parse(payload);

    const response = await api.get(`/organizations/${organizationId}/people`);

    const parsedData = PersonDTOSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
