import { api, apiException } from "~services/api";
import {
  FetchUploadSheetPayloadSchema,
  IFetchUploadSheetPayloadSchema,
} from "~types/schemas";

export const fetchUploadSheet = async (
  payload: IFetchUploadSheetPayloadSchema,
) => {
  try {
    const body = FetchUploadSheetPayloadSchema.parse(payload);

    await api.post("/admin/users/importProducts", body);
  } catch (e) {
    throw apiException(e);
  }
};
