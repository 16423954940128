import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedCostAggregatorPayloadSchema,
  IFetchGetPaginatedCostAggregatorPayloadSchema,
  PaginatedCostAggregatorDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedCostAggregator = async (
  payload: IFetchGetPaginatedCostAggregatorPayloadSchema,
) => {
  try {
    const query = FetchGetPaginatedCostAggregatorPayloadSchema.parse(payload);

    const response = await api.get(`/cost-aggregator/pageable`, {
      params: query,
    });

    const parsedData = PaginatedCostAggregatorDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
