import { useQuery } from "react-query";

import type { IUseFetchGetProposalCustomFields } from "./interface";

import { fetchGetProposalCustomFields } from "~api/config/customFields/proposal/get";

export const useFetchGetProposalCustomFieldsKey = "api/config/customFields/get";

export function useFetchGetProposalCustomFields({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalCustomFields) {
  return useQuery(
    [useFetchGetProposalCustomFieldsKey, dependencyArray],
    async () => await fetchGetProposalCustomFields(payload),
    options,
  );
}
