import { useMutation } from "react-query";

import { useFetchGetPipedriveLeadTypeKey } from "../useFetchGetPipedriveLeadType";
import type { IUseFetchUpdatePipedriveLead } from "./interface";

import { fetchUpdatePipedriveLead } from "~api/company/pipedrive-lead-type/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePipedriveLeadKey =
  "api/company/pipedrive-lead-type/patch";

export function useFetchUpdatePipedriveLead({
  options,
}: IUseFetchUpdatePipedriveLead) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdatePipedriveLead(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdatePipedriveLeadKey,
      onSuccess: (data, variables, context) => {
        message.success("Tipo de Lead atualizado!");
        queryClient.invalidateQueries(useFetchGetPipedriveLeadTypeKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
