import { api, apiException } from "~services/api";
import {
  ICreatePerformanceProfileFormSchema,
  IFetchCreatePerformanceProfilePayloadSchema,
  FetchCreatePerformanceProfilePayloadSchema,
  PerformanceProfileDTOSchema,
} from "~types/schemas";

export type FetchCreatePerformanceProfile = {
  body: ICreatePerformanceProfileFormSchema;
};
export const fetchCreatePerformanceProfile = async (
  payload: IFetchCreatePerformanceProfilePayloadSchema,
) => {
  try {
    const body = FetchCreatePerformanceProfilePayloadSchema.parse(payload);

    const response = await api.post(`/performance-profile`, body);

    const parsedData = PerformanceProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
