import { StructuresMaterialCostRuleFormSchema } from "./StructuresMaterialCostRuleFormSchema";

import { z } from "zod";

export const FetchUpdateStructuresMaterialCostRulePayloadSchema =
  StructuresMaterialCostRuleFormSchema.extend({
    structuresMaterialCostRuleId: z.number().int().gt(0),
  });

export interface IFetchUpdateStructuresMaterialCostRulePayloadSchema
  extends z.infer<typeof FetchUpdateStructuresMaterialCostRulePayloadSchema> {}
