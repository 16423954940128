import React, { Component, ErrorInfo } from "react";

import NextImage from "next/image";
import NextLink from "next/link";

import type { ErrorBoundaryProps, ErrorBoundaryState } from "./interface";

import { Button, Space, Typography, Flex } from "antd";

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Flex
          justify="center"
          align="center"
          vertical
          wrap="nowrap"
          gap={32}
          style={{
            minHeight: "100vh",
            width: "90vw",
            maxWidth: "1280px",
            margin: "0 auto",
          }}
        >
          <NextImage
            src="/images/server_error.svg"
            width={285}
            height={225}
            alt="500 - Erro interno do servidor"
          />

          <Space direction="vertical">
            <Typography.Title style={{ fontSize: "2rem", margin: 0 }}>
              500
            </Typography.Title>
            <Typography.Text style={{ fontSize: "1.125rem" }} strong>
              Algo deu errado
            </Typography.Text>
            <Typography.Text type="secondary" style={{ margin: 0 }}>
              Desculpe, ocorreu um problema, tente novamente mais tarde
            </Typography.Text>
            <NextLink passHref href="/">
              <Button type="primary" size="large" style={{ marginTop: "1rem" }}>
                Voltar ao início
              </Button>
            </NextLink>
          </Space>
        </Flex>
      );
    }

    return this.props.children;
  }
}
