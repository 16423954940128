import { z } from "zod";

export const ProposalExpirationRuleDTOSchema = z.object({
  id: z.number().int(),
  periods: z
    .record(z.number().int())
    .nullish()
    .transform((val) => val ?? undefined),
  defaultPeriod: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProposalExpirationRuleDTO
  extends z.infer<typeof ProposalExpirationRuleDTOSchema> {}
