import { z } from "zod";

export const RangeFormSchema = z.object({
  maxValue: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  costPerValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minimumCost: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  pricePerKm: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minimumDistance: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IRangeFormSchema extends z.infer<typeof RangeFormSchema> {}
