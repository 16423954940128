import { z } from "zod";

export const StructureSubCategoryEnum = z
  .enum([
    "LAJE",
    "FIBROCIMENTO",
    "CERAMICO",
    "METALICO",
    "ZIPADO",
    "MONOPOSTE",
    "BIPOSTE",
    "CARPORT",
    "SHINGLE",
    "FIBROMADEIRA",
    "FIBROMETALICA",
  ] as const)
  .catch((e) => e.input);

export type StructureSubCategoryEnum = z.infer<typeof StructureSubCategoryEnum>;
