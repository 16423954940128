import { api, apiException } from "~services/api";
import { RemainingPercentageResponseSchema } from "~types/schemas";

export const fetchGetRemainingPercentage = async () => {
  try {
    const response = await api.get(`/additional-costs/remaining-percentage`);

    const parsedData = RemainingPercentageResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
