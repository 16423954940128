import { api, apiException } from "~services/api";
import { ProjectDTO } from "~types/api";

export type FetchCreateProject = {
  path: {
    customId: number;
  };
};

export const fetchCopyProject = async ({ path }: FetchCreateProject) => {
  try {
    return await api.post<ProjectDTO>(`/projects/copy/${path.customId}`);
  } catch (error) {
    throw apiException(error);
  }
};
