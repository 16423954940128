import { z } from "zod";

export const ProposalPersonResponseSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IProposalPersonResponseSchema
  extends z.infer<typeof ProposalPersonResponseSchema> {}
