import { api, apiException } from "~services/api";
import {
  BusinessUnitAccountUserDTOSchema,
  FetchGetBusinessUnitUsersPayloadSchema,
  IFetchGetBusinessUnitUsersPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetBusinessUnitUsers = async (
  payload: IFetchGetBusinessUnitUsersPayloadSchema,
) => {
  try {
    const { businessUnitId } =
      FetchGetBusinessUnitUsersPayloadSchema.parse(payload);

    const response = await api.post(
      `/business-units/${businessUnitId}/list/users`,
    );

    const parsedData = z
      .array(BusinessUnitAccountUserDTOSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
