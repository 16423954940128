import { CustomFieldValueSchema } from "./CustomFieldValueSchema";
import { LowVoltageFormSchema } from "./LowVoltageFormSchema";
import { MediumVoltageFormSchema } from "./MediumVoltageFormSchema";

import { CostGroupEnum, PhaseEnum, VoltageEnum } from "~types/enum";
import { z } from "zod";

export const StepTwoFormSchema = z.object({
  performanceRate: z.number(),
  generationFactor: z.number(),
  idStructureType: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  lowVoltage: LowVoltageFormSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  mediumVoltage: MediumVoltageFormSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  electricUtilityId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  simultaneityFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  idLabor: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  plantDataCustomFieldValues: z
    .array(CustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
  structureGroup: CostGroupEnum.nullish().transform((val) =>
    val !== "CUSTOS_COMPLEMENTARES" && val !== "KIT" ? undefined : val,
  ),
  step: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  averageExcessEnergyTariff: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  expectedSurplusEnergy: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageExcessEnergyTariffWireB: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minCelsiusTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  accumulatedCreditsUsedInPayback: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  considerConsumptionUnits: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  plantUnitCode: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  regionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IStepTwoFormSchema extends z.infer<typeof StepTwoFormSchema> {}
