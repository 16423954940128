import { api, apiException } from "~services/api";
import {
  EncryptedPersonalTokenResponseSchema,
  FetchCreateEncryptedPersonalTokenPayloadSchema,
  type IFetchCreateEncryptedPersonalTokenPayloadSchema,
} from "~types/schemas";

export const fetchCreateEncryptedPersonalToken = async (
  payload: IFetchCreateEncryptedPersonalTokenPayloadSchema,
) => {
  try {
    const body = FetchCreateEncryptedPersonalTokenPayloadSchema.parse(payload);

    const response = await api.post(
      `/users/personal-token/encrypt-payload`,
      body,
    );

    const parsedData = EncryptedPersonalTokenResponseSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
