import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldsGroup } from "./interface";

import { fetchGetDealCustomFieldsGroup } from "~api/config/deals/custom-fields/groups/get";

export const useFetchGetDealCustomFieldsGroupKey =
  "api/config/deals/custom-fields/groups/get";

export function useFetchGetDealCustomFieldsGroup({
  dependencyArray = [],
  options,
}: IUseFetchGetDealCustomFieldsGroup) {
  return useQuery(
    [useFetchGetDealCustomFieldsGroupKey, dependencyArray],
    async () => await fetchGetDealCustomFieldsGroup(),
    options,
  );
}
