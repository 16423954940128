import { api, apiException } from "~services/api";
import {
  FetchGetProposalStepOnePayloadSchema,
  IFetchGetProposalStepOnePayloadSchema,
  StepTwoDTOSchema,
} from "~types/schemas";

export const fetchGetProposalStepTwo = async (
  payload: IFetchGetProposalStepOnePayloadSchema,
) => {
  try {
    const { customId } = FetchGetProposalStepOnePayloadSchema.parse(payload);

    const response = await api.get(`/projects/step_two/custom-id/${customId}`);

    const parsedData = StepTwoDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
