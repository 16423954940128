import { PasswordFormSchema } from "./PasswordFormSchema";

import { z } from "zod";

export const FetchSendNewPasswordPayloadSchema = PasswordFormSchema.extend({
  token: z.string(),
});

export interface IFetchSendNewPasswordPayloadSchema
  extends z.infer<typeof FetchSendNewPasswordPayloadSchema> {}
