import { api, apiException } from "~services/api";
import {
  FetchUpdatePremisesTariffPayloadSchema,
  IFetchUpdatePremisesTariffPayloadSchema,
  TariffPremiseDTOSchema,
} from "~types/schemas";

export const fetchUpdatePremisesTariff = async (
  payload: IFetchUpdatePremisesTariffPayloadSchema,
) => {
  try {
    const body = FetchUpdatePremisesTariffPayloadSchema.parse(payload);

    const response = await api.put(`/premises/tariff`, body);

    const parsedData = TariffPremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
