import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises";
import { useFetchGetPremisesTariffKey } from "../useFetchGetPremisesTariff";
import type { IUseFetchCreatePremisesTariff } from "./interface";

import { fetchCreatePremisesTariff } from "~api/premises/tariff/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePremisesTariffKey = "api/premises/tariff/post";

export function useFetchCreatePremisesTariff({
  options,
  displaySuccessMessage = true,
}: IUseFetchCreatePremisesTariff) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreatePremisesTariff(payload),
    {
      ...options,
      mutationKey: useFetchCreatePremisesTariffKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Tarifas criadas");
        }
        queryClient.invalidateQueries(useFetchGetPremisesKey);
        queryClient.invalidateQueries(useFetchGetPremisesTariffKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar tarifa: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
