import { useMutation } from "react-query";

import { useFetchGetEosFinancialProposalsKey } from "../useFetchGetEosFinancialProposals";
import type { IUseFetchCreatePersonEosFinancing } from "./interface";

import { fetchCreatePersonEosFinancing } from "~api/eosfin/person/post";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePersonEosFinancingKey = "api/eosfin/person/post";

export function useFetchCreatePersonEosFinancing({
  options,
}: IUseFetchCreatePersonEosFinancing) {
  return useMutation(
    async (payload) => await fetchCreatePersonEosFinancing(payload),
    {
      ...options,
      mutationKey: useFetchCreatePersonEosFinancingKey,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(useFetchGetEosFinancialProposalsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
}
