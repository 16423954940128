import { api, apiException } from "~services/api";
import {
  FetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema,
  IFetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema,
  EdeltecParameterDTOSchema,
} from "~types/schemas";

export const fetchUpdateConfigEdeltecDistributorIntegration = async (
  payload: IFetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema,
) => {
  try {
    const body =
      FetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema.parse(
        payload,
      );

    const response = await api.put(`/distributors/edeltec`, body);

    const parsedData = EdeltecParameterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
