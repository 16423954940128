import { useQuery } from "react-query";

import type { IUseFetchGetAllConsultants } from "./interface";

import { fetchGetAllConsultants } from "~api/consultants/get";

export const useFetchGetAllConsultantsKey = "api/consultants/get";

export function useFetchGetAllConsultants({
  dependencyArray = [],
  options,
}: IUseFetchGetAllConsultants) {
  return useQuery(
    [useFetchGetAllConsultantsKey, dependencyArray],
    async () => {
      return await fetchGetAllConsultants();
    },
    options,
  );
}
