import { api, apiException } from "~services/api";
import {
  FetchDeleteDealCustomFieldGroupPayloadSchema,
  type IFetchDeleteDealCustomFieldGroupPayloadSchema,
} from "~types/schemas";

export const fetchDeleteDealCustomFieldGroup = async (
  payload: IFetchDeleteDealCustomFieldGroupPayloadSchema,
) => {
  try {
    const { id, ...params } =
      FetchDeleteDealCustomFieldGroupPayloadSchema.parse(payload);

    await api.delete(`/config/deals/custom-fields/groups/${id}`, {
      params,
    });
  } catch (error) {
    throw apiException(error);
  }
};
