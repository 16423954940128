import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldsKey } from "../useFetchGetDealCustomFields";
import type { IUseFetchUpdateDealCustomField } from "./interface";

import { fetchUpdateDealCustomFields } from "~api/config/deals/custom-fields/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateDealCustomFieldsKey =
  "api/config/deals/custom-fields/{id}/put";

export function useFetchUpdateDealCustomFields({
  options,
}: IUseFetchUpdateDealCustomField) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateDealCustomFields(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateDealCustomFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi alterado com sucesso");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
