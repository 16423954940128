import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductModuleManufacturersPayloadSchema,
  IFetchGetPaginatedProductModuleManufacturersPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetPaginatedProductModuleManufacturers = async (
  payload: IFetchGetPaginatedProductModuleManufacturersPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductModuleManufacturersPayloadSchema.parse(payload);

    const response = await api.get(
      `/product/search/pvModule/manufacturerList`,
      {
        params,
      },
    );

    const parsedData = z.array(z.string()).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
