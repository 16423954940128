import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import { VoltageEnum } from "~types/enum";
import { z } from "zod";

export const TransformerAdditionalCostDtoSchema = z.object({
  id: z.number().int().gt(0),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  inputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  defaultPrice: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  prices: z
    .record(z.number())
    .optional()
    .nullish()
    .transform((val) => val ?? undefined),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export type ITransformerAdditionalCostDtoSchema = z.infer<
  typeof TransformerAdditionalCostDtoSchema
>;
