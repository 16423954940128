import { useQuery } from "react-query";

import type { IUseFetchGetPersonById } from "./interface";

import { fetchGetPersonById } from "~api/persons/{id}/get";

export const useFetchGetPersonByIdKey = "api/persons/{id}/get";

export function useFetchGetPersonById({
  dependencyArray = [],
  options,
  payload,
  customKey = useFetchGetPersonByIdKey,
}: IUseFetchGetPersonById) {
  return useQuery(
    [customKey, dependencyArray],
    async () => await fetchGetPersonById(payload),
    options,
  );
}
