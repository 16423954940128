import { useQuery } from "react-query";

import type { IUseFetchGetAllPipelinesDetailed } from "./interface";

import { fetchGetAllPipelinesDetailed } from "~api/pipeline/all/get";

export const useFetchGetAllPipelinesDetailedKey = "api/pipeline/all/get";

export function useFetchGetAllPipelinesDetailed({
  options,
  dependencyArray,
}: IUseFetchGetAllPipelinesDetailed) {
  return useQuery(
    [useFetchGetAllPipelinesDetailedKey, dependencyArray],
    async () => await fetchGetAllPipelinesDetailed(),
    options,
  );
}
