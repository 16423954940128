import { useQuery } from "react-query";

import type { IUseFetchGetConcessionairesWithAssociatedProfile } from "./interface";

import { fetchGetConcessionairesWithAssociatedProfile } from "~api/electric-utility/associated-profile/get";

export const useFetchGetConcessionairesWithAssociatedProfileKey =
  "api/electric-utility/associated-profile/get";

export function useFetchGetConcessionairesWithAssociatedProfile({
  dependencyArray = [],
  options,
}: IUseFetchGetConcessionairesWithAssociatedProfile) {
  return useQuery(
    [useFetchGetConcessionairesWithAssociatedProfileKey, dependencyArray],
    async () => await fetchGetConcessionairesWithAssociatedProfile(),
    options,
  );
}
