import { useQuery } from "react-query";

import type { IUseFetchGetTotalWonInsight } from "./interface";

import { fetchGetTotalWonInsight } from "~api/insights/totalWonInsight/get";

export const useFetchGetTotalWonInsightKey = "api/insights/totalWonInsight/get";

export function useFetchGetTotalWonInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetTotalWonInsight) {
  return useQuery(
    [useFetchGetTotalWonInsightKey, dependencyArray],
    async () => await fetchGetTotalWonInsight(payload),
    options,
  );
}
