import { BaseCalculationEnum } from "~types/enum";
import { z } from "zod";

export const ProjectPaymentSimulationOptionDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  monthlyInterestRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  projectPaymentSimulationGroupId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  installments: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  downPaymentPercentage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  downPaymentFixedValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  baseCalculation: BaseCalculationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IProjectPaymentSimulationOptionDTOSchema
  extends z.infer<typeof ProjectPaymentSimulationOptionDTOSchema> {}
