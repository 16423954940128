import { z } from "zod";

export const FetchGetProposalExpirationRulesPayloadSchema = z
  .object({
    potency: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .nullish()
  .transform((val) => val ?? {});

export interface IFetchGetProposalExpirationRulesPayloadSchema
  extends z.infer<typeof FetchGetProposalExpirationRulesPayloadSchema> {}
