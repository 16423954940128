import { api, apiException } from "~services/api";
import {
  FetchGetSearchPaginatedDatasheetPayloadSchema,
  type IFetchGetSearchPaginatedDatasheetPayloadSchema,
  PaginatedDatasheetDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedSearchDatasheet = async (
  payload: IFetchGetSearchPaginatedDatasheetPayloadSchema,
) => {
  try {
    const params = FetchGetSearchPaginatedDatasheetPayloadSchema.parse(payload);

    const response = await api.get(`/admin/datasheets/search`, {
      params,
    });

    const parsedData = PaginatedDatasheetDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
