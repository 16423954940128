import { useQuery } from "react-query";

import type { IUseFetchGetConversionRateInsight } from "./interface";

import { fetchGetConversionRateInsight } from "~api/insights/conversionRateInsight/get";

export const useFetchGetConversionRateInsightKey =
  "api/insights/leadTimeInsightGroupedByUser/get";

export function useFetchGetConversionRateInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetConversionRateInsight) {
  return useQuery(
    [useFetchGetConversionRateInsightKey, dependencyArray],
    async () => await fetchGetConversionRateInsight(payload),
    options,
  );
}
