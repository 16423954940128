import { api, apiException } from "~services/api";
import {
  type IFetchPatchOrganizationDefaultFieldSettingsPayloadSchema,
  OrganizationDefaultFieldSettingsDTOSchema,
} from "~types/schemas";

export const fetchPatchOrganizationDefaultFieldSettings = async (
  payload: IFetchPatchOrganizationDefaultFieldSettingsPayloadSchema,
) => {
  try {
    const { id, ...body } = payload;
    const response = await api.patch(
      `/config/organizations/default-fields/settings/${id}`,
      body,
    );

    const parsedData = OrganizationDefaultFieldSettingsDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
