import { api, apiException } from "~services/api";
import {
  FetchGetReportsPayloadSchema,
  IFetchGetReportsPayloadSchema,
  TemplateReportDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetReports = async (
  payload?: IFetchGetReportsPayloadSchema,
) => {
  try {
    const queryParams = FetchGetReportsPayloadSchema.parse(payload);

    const response = await api.get(`/reports`, {
      params: queryParams,
    });

    const parsedData = z.array(TemplateReportDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
