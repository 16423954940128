import { useMutation } from "react-query";

import type { IUseFetchAuth } from "./interface";

import { fetchAuth } from "~api/auth/post";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchAuthKey = "api/auth/post";

export function useFetchAuth({ options }: IUseFetchAuth) {
  const { message } = useAppConfig();

  return useMutation(
    async ({ user, password }) => {
      return await fetchAuth({
        password,
        user,
      });
    },
    {
      ...options,
      mutationKey: useFetchAuthKey,
      onError(error, variables, context) {
        message.error(
          error?.message === "Request failed with status code 403"
            ? "Email ou senha inválidos"
            : error?.message,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
