import { api, apiException } from "~services/api";
import {
  BusinessUnitDTOSchema,
  FetchGetBusinessUnitByIdPayloadSchema,
  IFetchGetBusinessUnitByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetBusinessUnitById = async (
  payload: IFetchGetBusinessUnitByIdPayloadSchema,
) => {
  try {
    const { businessUnitId } =
      FetchGetBusinessUnitByIdPayloadSchema.parse(payload);

    const response = await api.get(`/business-units/${businessUnitId}`);

    const parsedData = BusinessUnitDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
