import { AddressDTOSchema } from "./AddressDTOSchema";

import { z } from "zod";

export const PersonAutoCompleteResponseSchema = z.object({
  id: z.number({ message: "ID da pessoa é obrigatório" }).int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IPersonAutoCompleteResponseSchema
  extends z.infer<typeof PersonAutoCompleteResponseSchema> {}
