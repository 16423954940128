/*
  For details access:
  https://github.com/solarz-dev/solarz-frontend-crm/wiki/%5BNEW%5D-Session-context-&-hook
*/

import { createContext, useCallback, useMemo, useState } from "react";

import { useRouter } from "next/router";

import type {
  CheckAuthenticationAndRedirectParamsType,
  ISessionContext,
  ISessionContextProvider,
  SessionStatusType,
  PermissionType,
  UserRoleType,
} from "./interface";

import { fetchAdminImpersonateUser } from "~api/admin/impersonate/post";
import {
  THEME_STORAGE_KEY,
  THEME_COMPACT_STORAGE_KEY,
  PAGE_PIPELINE_LAST_PIPELINE_ID_KEY,
  PAGE_PIPELINE_LAST_PIPELINE_VIEW_MODE_KEY,
  PAGE_INSIGHTS_LAST_PIPELINE_ID_KEY,
} from "~configs/AppConfig";
import {
  PROPOSAL_DATA_HAS_CHANGED,
  PROPOSAL_SIZING_KIT_DATA,
  SIZING_STEP_STORAGE_KEY,
  CITY_WAS_CHANGED_KEY,
} from "~configs/ProposalConfig";
import {
  PIPEDRIVE_CLIENT_ID,
  PIPEDRIVE_REDIRECT_URI,
  TOKEN_STORAGE_KEY,
  BACKUP_TOKEN_STORAGE_KEY,
  PATH_BEFORE_REDIRECT,
  AFTER_SIZING_STEP_STORAGE_KEY,
} from "~configs/SessionConfig";
import { useFetchAuth, useFetchGetSessionData } from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { useIsomorphicLayoutEffect } from "~hooks/useIsomorphicLayoutEffect";
import { api } from "~services/api";
import { IntegratorEnum } from "~types/enum";

export const SessionContext = createContext({} as ISessionContext);

export function SessionContextProvider({ children }: ISessionContextProvider) {
  const router = useRouter();
  const { message } = useAppConfig();

  const [token, setToken] = useState<string>();

  const [user, setUser] = useState<ISessionContext["user"]>();

  const [sessionStatus, setSessionStatus] =
    useState<SessionStatusType>("loading");

  const setTokenOnStorageAndApiBearerToken = useCallback((token: string) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, []);

  const clearStorageAndApiBearerToken = useCallback(() => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(SIZING_STEP_STORAGE_KEY);
    localStorage.removeItem(PROPOSAL_DATA_HAS_CHANGED);
    localStorage.removeItem(PROPOSAL_SIZING_KIT_DATA);
    localStorage.removeItem(THEME_STORAGE_KEY);
    localStorage.removeItem(THEME_COMPACT_STORAGE_KEY);
    localStorage.removeItem(AFTER_SIZING_STEP_STORAGE_KEY);
    localStorage.removeItem(CITY_WAS_CHANGED_KEY);
    localStorage.removeItem(PAGE_PIPELINE_LAST_PIPELINE_ID_KEY);
    localStorage.removeItem(PAGE_PIPELINE_LAST_PIPELINE_VIEW_MODE_KEY);
    localStorage.removeItem(PAGE_INSIGHTS_LAST_PIPELINE_ID_KEY);
    api.defaults.headers.common["Authorization"] = "";
  }, []);

  const [pathBeforeRedirect, setPathBeforeRedirect] = useState<string>();

  const { mutate: signInWithCredentials } = useFetchAuth({
    options: {
      onSuccess: (data) => {
        setToken(data.token);
      },
      onError: () => {
        setSessionStatus("unauthenticated");
      },
      onMutate: () => {
        setSessionStatus("loading");
      },
    },
  });

  useFetchGetSessionData({
    payload: {
      bearerToken: token || "",
    },
    dependencyArray: [token],
    options: {
      enabled: !!token,
      refetchOnWindowFocus: true,
      onSuccess: (user) => {
        setUser(user);
        setTokenOnStorageAndApiBearerToken(token || "");
        setSessionStatus("authenticated");
      },
      onError: (error) => {
        message.error(error.message);
        clearStorageAndApiBearerToken();
        setSessionStatus("unauthenticated");
      },
    },
  });

  const signInWithPipedrive = useCallback(() => {
    router.push(
      `https://oauth.pipedrive.com/oauth/authorize?client_id=${PIPEDRIVE_CLIENT_ID}&redirect_uri=${PIPEDRIVE_REDIRECT_URI}`,
    );
  }, [router]);

  const impersonateUser = useCallback(
    async (userId: number) => {
      try {
        setSessionStatus("loading");

        const response = await fetchAdminImpersonateUser({
          userId,
        });

        const impersonateToken = response.token;

        if (!impersonateToken) {
          throw new Error("Missing session token on api response");
        }

        if (token) {
          localStorage.setItem(BACKUP_TOKEN_STORAGE_KEY, token);
        }

        setToken(impersonateToken);
        setTokenOnStorageAndApiBearerToken(impersonateToken);
      } catch (error: any) {
        if (token) {
          setTokenOnStorageAndApiBearerToken(token);
          setSessionStatus("authenticated");
        } else {
          clearStorageAndApiBearerToken();
          setUser(undefined);
          setSessionStatus("unauthenticated");
        }
        message.error(error.message);
      }
    },
    [
      token,
      setTokenOnStorageAndApiBearerToken,
      message,
      clearStorageAndApiBearerToken,
    ],
  );

  const signOut = useCallback(() => {
    const backupToken = localStorage.getItem(BACKUP_TOKEN_STORAGE_KEY);

    if (backupToken) {
      localStorage.removeItem(BACKUP_TOKEN_STORAGE_KEY);
      localStorage.removeItem(SIZING_STEP_STORAGE_KEY);
      localStorage.removeItem(PROPOSAL_DATA_HAS_CHANGED);
      localStorage.removeItem(PROPOSAL_SIZING_KIT_DATA);
      localStorage.removeItem(AFTER_SIZING_STEP_STORAGE_KEY);
      localStorage.removeItem(CITY_WAS_CHANGED_KEY);
      localStorage.removeItem(PAGE_PIPELINE_LAST_PIPELINE_ID_KEY);
      localStorage.removeItem(PAGE_PIPELINE_LAST_PIPELINE_VIEW_MODE_KEY);
      localStorage.removeItem(PAGE_INSIGHTS_LAST_PIPELINE_ID_KEY);
      setToken(backupToken);
      setTokenOnStorageAndApiBearerToken(backupToken);
      return;
    }

    setToken(undefined);
    setUser(undefined);
    setSessionStatus("unauthenticated");
    clearStorageAndApiBearerToken();

    message.success({
      content: "Sentirei sua falta!",
      icon: "😭 ",
    });
  }, [
    message,
    clearStorageAndApiBearerToken,
    setTokenOnStorageAndApiBearerToken,
  ]);

  const userHaveRole = useCallback(
    (role: UserRoleType) => {
      return !!user?.roles?.includes(role);
    },
    [user],
  );

  const userHavePermission = useCallback(
    (permission: PermissionType) => {
      return !!user?.profile?.permissions?.includes(permission);
    },
    [user],
  );

  const userHaveIntegrationWithDistributor = useCallback(
    (distributor: IntegratorEnum) => {
      return !!user?.distributors?.includes(distributor);
    },
    [user],
  );

  const userIsAdmin = useMemo(() => {
    return !!user?.accountOwner;
  }, [user?.accountOwner]);

  const userIsSuperSysAdmin = useMemo(() => {
    return !!user?.roles?.includes("SUPER_SYSTEM_ADMIN");
  }, [user?.roles]);

  const userIsSysAdmin = useMemo(() => {
    return (
      !!user?.roles?.includes("SYSTEM_ADMIN") ||
      !!user?.roles?.includes("SUPER_SYSTEM_ADMIN")
    );
  }, [user?.roles]);

  const checkAuthenticationAndRedirect = useCallback(
    ({
      ifUnauthenticatedRedirectTo,
      ifUnauthenticatedDisplayMessage,
      ifAdminRedirectTo,
      ifAdminDisplayMessage,
      ifCommonRedirectTo,
      ifCommonDisplayMessage,
    }: CheckAuthenticationAndRedirectParamsType) => {
      if (sessionStatus === "loading") return;

      if (sessionStatus === "unauthenticated") {
        if (ifUnauthenticatedDisplayMessage) {
          message.error(ifUnauthenticatedDisplayMessage);
        }

        if (ifUnauthenticatedRedirectTo) {
          setPathBeforeRedirect(router.asPath);

          try {
            localStorage.setItem(PATH_BEFORE_REDIRECT, router.asPath);
          } catch (error: any) {
            console.warn("SESSION CONTEXT PERSISTS URL:", error?.message);
          }

          router.push(ifUnauthenticatedRedirectTo);
        }

        return;
      }

      if (userIsSysAdmin) {
        if (ifAdminDisplayMessage) {
          message.success(ifAdminDisplayMessage);
        }

        try {
          localStorage.removeItem(PATH_BEFORE_REDIRECT);
        } catch (error: any) {
          console.warn("SESSION CONTEXT PERSISTS URL:", error?.message);
        }

        if (ifAdminRedirectTo) {
          router.push(ifAdminRedirectTo);
        }

        return;
      }

      if (ifCommonDisplayMessage) {
        message.success(ifCommonDisplayMessage);
      }

      try {
        localStorage.removeItem(PATH_BEFORE_REDIRECT);
      } catch (error: any) {
        console.warn("SESSION CONTEXT PERSISTS URL:", error?.message);
      }

      if (ifCommonRedirectTo) {
        router.push(ifCommonRedirectTo);
      }
    },
    [sessionStatus, userIsSysAdmin, message, router],
  );

  const onChangeToken = useCallback((value: string | undefined) => {
    setToken(value);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    const pathBeforeRedirect = localStorage.getItem(PATH_BEFORE_REDIRECT);

    if (pathBeforeRedirect && typeof pathBeforeRedirect === "string") {
      setPathBeforeRedirect(pathBeforeRedirect);
    }

    if (!token || typeof token !== "string") {
      clearStorageAndApiBearerToken();
      setToken(undefined);
      setUser(undefined);
      setSessionStatus("unauthenticated");
      return;
    }

    setToken(token);
  }, [clearStorageAndApiBearerToken]);

  const values: ISessionContext = useMemo(
    () => ({
      token,
      user,
      sessionStatus,
      pathBeforeRedirect,
      signOut,
      impersonateUser,
      signInWithPipedrive,
      signInWithCredentials,
      userHaveRole,
      userHavePermission,
      userHaveIntegrationWithDistributor,
      userIsAdmin,
      userIsSysAdmin,
      userIsSuperSysAdmin,
      checkAuthenticationAndRedirect,
      onChangeToken,
    }),
    [
      token,
      user,
      sessionStatus,
      pathBeforeRedirect,
      signOut,
      impersonateUser,
      signInWithPipedrive,
      signInWithCredentials,
      userHaveRole,
      userHavePermission,
      userHaveIntegrationWithDistributor,
      userIsAdmin,
      userIsSysAdmin,
      userIsSuperSysAdmin,
      checkAuthenticationAndRedirect,
      onChangeToken,
    ],
  );

  return (
    <SessionContext.Provider value={values}>{children}</SessionContext.Provider>
  );
}
