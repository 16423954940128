import { CrmFileSchema } from "./CrmFileSchema";
import { DistributorDTOSchema } from "./DistributorDTOSchema";

import { z } from "zod";

export const CompanyDTOSchema = z.object({
  id: z.number().int().gt(0),
  idPipedrive: z
    .number()
    .int()
    .gte(0) // 0 is valid
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  logo: CrmFileSchema.nullish().transform((val) => val ?? undefined),
  distributors: z.array(DistributorDTOSchema).nullish().default([]),
  customPipedriveActivityMessage: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICompanyDTOSchema extends z.infer<typeof CompanyDTOSchema> {}
