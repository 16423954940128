import { api, apiException } from "~services/api";
import { UserManagementDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetAllUsersList = async () => {
  try {
    const response = await api.get(`/users/list`);

    const parsedData = z.array(UserManagementDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
