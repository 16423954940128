import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedSimultaneityProfilesPayloadSchema,
  IFetchGetPaginatedSimultaneityProfilesPayloadSchema,
  PaginatedSimultaneityProfilesDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedSimultaneityProfiles = async (
  payload: IFetchGetPaginatedSimultaneityProfilesPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedSimultaneityProfilesPayloadSchema.parse(payload);

    const response = await api.get(`/simultaneityProfiles/pages`, {
      params,
    });

    const parsedData = PaginatedSimultaneityProfilesDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
