import { useMutation } from "react-query";

import { useFetchGetPaginatedSysAdminUserKey } from "../useFetchGetPaginatedSysAdminUser";
import type { IUseFetchPostSysAdminUser } from "./interface";

import { fetchPostSysAdminManagement } from "~api/sys-admin-management/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPostSysAdminUserKey = "api/sys-admin-management/post";

export function useFetchPostSysAdminUser({
  options,
}: IUseFetchPostSysAdminUser) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchPostSysAdminManagement(payload);
    },
    {
      ...options,
      mutationKey: useFetchPostSysAdminUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Administrador foi cadastrado!");
        queryClient.invalidateQueries(useFetchGetPaginatedSysAdminUserKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao cadastrar administrador: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
