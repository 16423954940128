import { useMutation } from "react-query";

import { UseFetchGetCurrentAdditionalCostMarginTypeKey } from "../useFetchGetCurrentAdditionalCostMarginType";
import { UseFetchGetPaginatedAdditionalCostMarginTypeKey } from "../useFetchGetPaginatedAdditionalCostMarginType";
import type { IUseFetchDeleteAdditionalCostMarginType } from "./interface";

import { fetchDeleteAdditionalCostMarginType } from "~api/additional-cost-margin-type/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteAdditionalCostMarginTypeKey =
  "api/ac-materials/{id}/delete";

export function useFetchDeleteAdditionalCostMarginType(
  options?: IUseFetchDeleteAdditionalCostMarginType,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      const response = await fetchDeleteAdditionalCostMarginType(payload);
      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchDeleteAdditionalCostMarginTypeKey,
      onSuccess: (data, variables, context) => {
        message.success("Tipo de marge de lucro foi removido!");
        queryClient.invalidateQueries(
          UseFetchGetPaginatedAdditionalCostMarginTypeKey,
        );
        queryClient.invalidateQueries(
          UseFetchGetCurrentAdditionalCostMarginTypeKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao tipo de marge de lucro: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
