import { api, apiException } from "~services/api";
import { AdditionalCostMarginTypeDTOSchema } from "~types/schemas";

export const fetchGetCurrentAdditionalCostMarginType = async () => {
  try {
    const response = await api.get(`/additional-cost-margin-type/current`);

    const parsedData = AdditionalCostMarginTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
