import { api, apiException } from "~services/api";
import {
  FetchGetFortlevStructureByStructureIdPayloadSchema,
  type IFetchGetFortlevStructureByStructureIdPayloadSchema,
  FortlevStructureIdentifierSchema,
} from "~types/schemas";

export const fetchGetFortlevStructureByStructureId = async (
  payload: IFetchGetFortlevStructureByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetFortlevStructureByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/equipments/fortlev/structure-by-relationship/${structureId}`,
    );

    // when not return result the backend returns a empty string in response.data
    const parsedData = FortlevStructureIdentifierSchema.parse(
      response.data || {},
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
