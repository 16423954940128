export const PROPOSAL_DATA_HAS_CHANGED =
  "@solarz_crm:proposal-data-has-changed-1.0.0";

export const PROPOSAL_SIZING_KIT_DATA =
  "@solarz_crm:proposal-sizing-kit-data-1.0.0";

export const SIZING_STEP_STORAGE_KEY = "@solarz_crm:sizing-step-1.0.0";
export const CITY_WAS_CHANGED_KEY = "@solarz_crm:city-was-changed-1.0.0";

export type ProposalStepsType = (
  | "DATA"
  | "SIZING"
  | "DETAILS"
  | "AFTER_SIZING"
  | "PAYMENT"
  | "PROPOSAL"
  | "FINISH"
)[];

export const PROPOSAL_STEPS = [
  "DATA",
  "SIZING",
  "DETAILS",
  "AFTER_SIZING",
  "PAYMENT",
  "PROPOSAL",
  "FINISH",
] as const;

export type ProposalStepKeyType = (typeof PROPOSAL_STEPS)[number];
