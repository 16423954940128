import { useQuery } from "react-query";

import type { IUseFetchGetStepTwoAutoComplete } from "./interface";

import { fetchGetStepTwoAutoComplete } from "~api/projects/step_two/auto-complete/get";

export const useFetchGetStepTwoAutoCompleteKey =
  "api/projects/step_two/auto-complete/get";

export function useFetchGetStepTwoAutoComplete({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetStepTwoAutoComplete) {
  return useQuery(
    [useFetchGetStepTwoAutoCompleteKey, dependencyArray],
    async () => await fetchGetStepTwoAutoComplete(payload),
    options,
  );
}
