import { z } from "zod";

export const FetchUpdateLaborStructurePayloadSchema = z.object({
  laborId: z.number().int().gt(0),
  structureIds: z
    .array(z.number())
    .nullish()
    .transform((val) => val ?? []),
});

export interface IFetchUpdateLaborStructurePayloadSchema
  extends z.infer<typeof FetchUpdateLaborStructurePayloadSchema> {}
