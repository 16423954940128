import { AddressDTOSchema } from "./AddressDTOSchema";
import { ClientDTOSchema } from "./ClientDTOSchema";
import { ElectricUtilityDTOSchema } from "./ElectricUtilityDTOSchema";
import { ElectricUtilityProfileDTOSchema } from "./ElectricUtilityProfileDTOSchema";
import { LaborDTOSchema } from "./LaborDTOSchema";
import { LowVoltageDTOSchema } from "./LowVoltageDTOSchema";
import { MediumVoltageDTOSchema } from "./MediumVoltageDTOSchema";
import { OrganizationDTOSchema } from "./OrganizationDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";
import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";
import { UserAccountDTOSchema } from "./UserAccountDTOSchema";

import {
  IrradiationTypeEnum,
  PhaseEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
  VoltageEnum,
} from "~types/enum";
import { z } from "zod";

export const ProposalDetailsResponseSchema = z.object({
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  responsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  emailResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phoneResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  creationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  lastUpdate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  code: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  plantUnitCode: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valueForCustomer: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveDealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedrivePipelineId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  regionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  electricUtility: ElectricUtilityDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  performanceRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  generationFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  minCelsiusTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maxCelsiusTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  dataLowVoltage: LowVoltageDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  dataMediumVoltage: MediumVoltageDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  client: ClientDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  organization: OrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  simultaneityFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  usefulAreaInM2: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  structureType: StructureTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  labor: LaborDTOSchema.nullish().transform((val) => val ?? undefined),
  baseAddress: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  irradiationType: IrradiationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  userAccount: UserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  electricUtilityProfile: ElectricUtilityProfileDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  distanceForLogisticCostInKm: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  installationAddress: AddressDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IProposalDetailsResponseSchema
  extends z.infer<typeof ProposalDetailsResponseSchema> {}
