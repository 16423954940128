import { AddressDTOSchema } from "./AddressDTOSchema";
import { ClientDTOSchema } from "./ClientDTOSchema";
import { CustomFieldValueSchema } from "./CustomFieldValueSchema";
import { OrganizationDTOSchema } from "./OrganizationDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import { ProjectTypeEnum } from "~types/enum";
import { z } from "zod";

export const StepOneDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  responsible: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  emailResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phoneResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  creationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  code: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  pipedriveDealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  client: ClientDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  organization: OrganizationDTOSchema.omit({ id: true })
    .extend({
      id: z
        .number()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  express: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  currentBUName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  baseAddress: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  initialDataCustomFieldValues: z
    .array(CustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedrivePipelineId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  installationAddress: AddressDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IStepOneDTOSchema extends z.infer<typeof StepOneDTOSchema> {}
