import { z } from "zod";

export const FortlevProductTypeEnum = z
  .enum([
    "GERADOR_FOTOVOLTAICO",
    "GERADOR_HIBRIDO",
    "GERADOR_MICROINVERSOR",
  ] as const)
  .catch((e) => e.input);

export type FortlevProductTypeEnum = z.infer<typeof FortlevProductTypeEnum>;
