import { api, apiException } from "~services/api";
import {
  FetchDeletePersonPayloadSchema,
  type IFetchDeletePersonPayloadSchema,
} from "~types/schemas";

export const fetchDeletePerson = async (
  payload: IFetchDeletePersonPayloadSchema,
) => {
  try {
    const { personId } = FetchDeletePersonPayloadSchema.parse(payload);

    await api.delete(`/persons/${personId}`);
  } catch (error) {
    throw apiException(error);
  }
};
