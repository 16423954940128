import { useQuery } from "react-query";

import { IUseFetchGetPaginatedPerformanceProfile } from "./interface";

import { fetchGetPaginatedPerformanceProfiles } from "~api/performance-profiles/page/get";

export const useFetchGetPaginatedPerformanceProfileKey =
  "api/performance-profiles/page/get";

export function useFetchGetPaginatedPerformanceProfile({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 5,
  },
}: IUseFetchGetPaginatedPerformanceProfile) {
  return useQuery(
    [useFetchGetPaginatedPerformanceProfileKey, dependencyArray],
    async () => await fetchGetPaginatedPerformanceProfiles(payload),
    options,
  );
}
