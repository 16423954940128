import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetAutoCompletePersonKey } from "../useFetchGetAutoCompletePerson";
import { useFetchGetPaginatedPersonKey } from "../useFetchGetPaginatedPerson";
import { useFetchGetPersonByIdKey } from "../useFetchGetPersonById";
import type { IUseFetchDeletePerson } from "./interface";

import { fetchDeletePerson } from "~api/persons/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePersonKey = "api/clients/{id}/delete";

export function useFetchDeletePerson(options?: IUseFetchDeletePerson) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeletePerson(payload), {
    ...options,
    mutationKey: useFetchDeletePersonKey,
    onSuccess: (data, variables, context) => {
      message.success("Pessoa foi apagada");
      queryClient.invalidateQueries(useFetchGetAutoCompletePersonKey);
      queryClient.invalidateQueries(useFetchGetPaginatedPersonKey);
      queryClient.invalidateQueries(useFetchGetPersonByIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao apagar pessoa: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
