import { api, apiException } from "~services/api";
import {
  type IFetchGetConversionRateInsightGroupedByUserPayloadSchema,
  FetchGetConversionRateInsightGroupedByUserPayloadSchema,
  ConversionRateGroupedByUserInsightDataDtoSchema,
} from "~types/schemas";

export const fetchGetConversionRateInsightGroupedByUser = async (
  payload: IFetchGetConversionRateInsightGroupedByUserPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetConversionRateInsightGroupedByUserPayloadSchema.parse(payload);

    const response = await api.get("/insights/conversionRateInsight", {
      params: queryParams,
    });

    const parsedData = ConversionRateGroupedByUserInsightDataDtoSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
