import { z } from "zod";

export const PhoneSchema = z.object({
  value: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  primary: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPhoneSchema extends z.infer<typeof PhoneSchema> {}
