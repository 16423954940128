import { z } from "zod";

export const activityPeriodsEnum = [
  "TODAY",
  "TOMORROW",
  "THIS_WEEK",
  "NEXT_WEEK",
  "THIS_MONTH",
] as const;

export const ActivityPeriodsEnum = z
  .enum(activityPeriodsEnum)
  .catch((e) => e.input);

export type ActivityPeriodsEnum = z.infer<typeof ActivityPeriodsEnum>;
