import { z } from "zod";

export const UpdateFormFieldSettingsFormSchema = z.object({
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IUpdateFormFieldSettingsFormSchema
  extends z.infer<typeof UpdateFormFieldSettingsFormSchema> {}
