import { LowVoltageTaxesDTOSchema } from "./LowVoltageTaxesDTOSchema";
import { MediumVoltageTaxesDTOSchema } from "./MediumVoltageTaxesDTOSchema";

import { z } from "zod";

export const ElectricUtilityProfileDetailDTOSchema = z.object({
  electricUtilityName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  startTerm: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  endTerm: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  tariffTerm: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  tariffBases: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  subgroup: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  modality: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  consumerClass: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  consumerSubclass: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  detail: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  accessBy: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  lowVoltageTaxes: LowVoltageTaxesDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  mediumVoltageTaxes: MediumVoltageTaxesDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  icms: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  pisCofins: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  aneelresolution: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  taxesForApply: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IElectricUtilityProfileDetailDTOSchema
  extends z.infer<typeof ElectricUtilityProfileDetailDTOSchema> {}
