import { z } from "zod";

export const AdditionalCostMarginTypeFormSchema = z
  .object({
    afterCalculations: z.boolean().optional().default(false),
    includeTaxes: z.boolean().optional().default(false),
    insideCalculation: z.boolean().optional().default(false),
  })
  .partial();

export interface IAdditionalCostMarginTypeFormSchema
  extends z.infer<typeof AdditionalCostMarginTypeFormSchema> {}
