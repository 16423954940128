import { AddressDTOSchema } from "./AddressDTOSchema";
import { SimpleOrganizationDTOSchema } from "./SimpleOrganizationDTOSchema";

import { z } from "zod";

export const SimpleClientDTOSchema = z.object({
  id: z.number().int().gt(0),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: SimpleOrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ISimpleClientDTOSchema
  extends z.infer<typeof SimpleClientDTOSchema> {}
