import { ProgressInsightDataSchema } from "./ProgressInsightDataSchema";

import { z } from "zod";

export const ProgressInsightDTOSchema = z.object({
  data: ProgressInsightDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IProgressInsightDTOSchema
  extends z.infer<typeof ProgressInsightDTOSchema> {}
