import { LocationServiceEnum } from "~types/enum";
import { z } from "zod";

export const UpdateUserSettingsSchema = z.object({
  maxUsersLicensed: z
    .number({ message: "Número máximo de licenças é obrigatório" })
    .int({ message: "Número máximo de licenças tem quer um inteiro" })
    .gte(0, {
      message: "Número máximo de licenças tem que ser maior que zero!",
    }),
  locationService: LocationServiceEnum,
  accountOwnerEmail: z.string({
    message: "Email do dono da conta é obrigatório",
  }),
});

export interface IUpdateUserSettingsSchema
  extends z.infer<typeof UpdateUserSettingsSchema> {}
