import { api, apiException } from "~services/api";

/*
  No swagger do homolog não tem a tipagem definida
  da resposta definida, por isso tive que criar uma.
*/

export type ResponseGetGenerateProposalPdf = {
  filePdfInBase64: string;
};

export type FetchGetGenerateProposalPdf = {
  path: {
    projectId: number;
    templateId: number;
  };
};

export const getGenerateProposalPdf = async ({
  path,
}: FetchGetGenerateProposalPdf) => {
  try {
    return await api.get<ResponseGetGenerateProposalPdf>(
      `/projects/step_five/custom-id/${path.projectId}/${path.templateId}`,
      {
        //Parameter to prevent the browser from caching the request, obs: trust the father
        params: {
          _v: Math.random(),
        },
      },
    );
  } catch (error) {
    throw apiException(error);
  }
};
