import { api, apiException } from "~services/api";
import {
  FetchGetNoteFileSchema,
  type INoteFileDTOSchema,
  NoteFileDTOSchema,
} from "~types/schemas";

export const fetchGetNoteFile = async (payload: INoteFileDTOSchema) => {
  try {
    const { fileId, noteId } = NoteFileDTOSchema.parse(payload);

    const response = await api.get(`/note/${noteId}/file/${fileId}`);

    const parsedData = FetchGetNoteFileSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
