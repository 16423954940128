import { useMutation } from "react-query";

import { useFetchGetAdminModulesByIdKey } from "../useFetchGetAdminModulesById";
import { useFetchGetPaginatedAdminModulesKey } from "../useFetchGetPaginatedAdminModules";
import type { IUseFetchCreateAdminModules } from "./interface";

import { fetchCreateAdminModules } from "~api/admin/modules/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateAdminModulesKey = "api/admin/modules/post";

export function useFetchCreateAdminModules({
  options,
}: IUseFetchCreateAdminModules) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateAdminModules(payload),
    {
      ...options,
      mutationKey: useFetchCreateAdminModulesKey,
      onSuccess: (data, variables, context) => {
        message.success("Módulo foi criado");

        queryClient.invalidateQueries(useFetchGetPaginatedAdminModulesKey);
        queryClient.invalidateQueries(useFetchGetAdminModulesByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar módulo: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
