import { UserConfigurationFormSchema } from "./UserConfigurationFormSchema";

import { z } from "zod";

export const FetchUpdateUserCompanyConfigurationPayloadSchema =
  UserConfigurationFormSchema.extend({
    companyId: z
      .number({ message: "ID da companhia é obrigatório" })
      .int({ message: "ID da companhia precisa ser um inteiro" })
      .gt(0, { message: "ID da companhia precisa ser maior que zero!" }),
  });

export interface IFetchUpdateUserCompanyConfigurationPayloadSchema
  extends z.infer<typeof FetchUpdateUserCompanyConfigurationPayloadSchema> {}
