import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetActivitiesCountByPersonIdKey } from "../useFetchGetActivitiesCountByPersonId";
import { useFetchGetActivityByIdKey } from "../useFetchGetActivityById";
import { useFetchGetActivityTypesKey } from "../useFetchGetActivityTypes";
import { useFetchGetBoardActivityKey } from "../useFetchGetBoardActivity";
import { useFetchGetPeriodActivityKey } from "../useFetchGetPeriodActivity";
import type { IUseFetchUpdateActivityDate } from "./interface";

import { fetchUpdateActivityDate } from "~api/activities/{id}/date/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateActivityDateKey = "api/activities/{id}/date/patch";

export function useFetchUpdateActivityDate({
  options,
}: IUseFetchUpdateActivityDate) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateActivityDate(payload),
    {
      ...options,
      mutationKey: useFetchUpdateActivityDateKey,
      onSuccess: (data, variables, context) => {
        message.success("Data/horário da atividade foi alterado");

        queryClient.invalidateQueries(useFetchGetActivityByIdKey);
        queryClient.invalidateQueries(useFetchGetBoardActivityKey);
        queryClient.invalidateQueries(useFetchGetPeriodActivityKey);
        queryClient.invalidateQueries(useFetchGetActivityTypesKey);
        queryClient.invalidateQueries(useFetchGetActivitiesCountByPersonIdKey);

        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          "Falha ao alterar data/horario da atividade: " + error.message,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
