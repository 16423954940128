import { useMutation } from "react-query";

import { fetchGetPaginatedNotificationsKey } from "../useFetchGetPaginatedNotifications";
import { IUseFetchSetAllNotificationsRead } from "./interface";

import { fetchSetAllNotificationsRead } from "~api/notifications/read/all/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetAllNotificationsReadKey =
  "api/notifications/read/all/patch";

export function useFetchSetAllNotificationsRead({
  options,
}: IUseFetchSetAllNotificationsRead) {
  const { message } = useAppConfig();

  return useMutation(async () => await fetchSetAllNotificationsRead(), {
    ...options,
    mutationKey: useFetchSetAllNotificationsReadKey,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(fetchGetPaginatedNotificationsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(
        `Falha ao marcar todas as mensagens como lidas: ${error.message}`,
      );
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
