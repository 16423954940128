import { api, apiException } from "~services/api";
import {
  CreateDealCustomFieldFormSchema,
  ICreateDealCustomFieldFormSchema,
  DealCustomFieldResponseSchema,
} from "~types/schemas";

export const fetchCreateDealCustomField = async (
  payload: ICreateDealCustomFieldFormSchema,
) => {
  try {
    const body = CreateDealCustomFieldFormSchema.parse(payload);

    const response = await api.post(`/config/deals/custom-fields`, body);

    const parsedData = DealCustomFieldResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
