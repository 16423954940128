import { api, apiException } from "~services/api";
import {
  FetchGetMonthlyPerformanceRateByCustomIdPayloadSchema,
  IFetchGetMonthlyPerformanceRateByCustomIdPayloadSchema,
  MonthlyPerformanceRate,
} from "~types/schemas";

export const fetchGetMonthlyPerformanceRateByCustomId = async (
  payload: IFetchGetMonthlyPerformanceRateByCustomIdPayloadSchema,
) => {
  try {
    const { customId } =
      FetchGetMonthlyPerformanceRateByCustomIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/projects/step_two/monthly-performance-rate/${customId}`,
    );

    if (!response.data) return undefined;

    const parsedData = MonthlyPerformanceRate.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
