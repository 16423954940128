import { HistoryFilterEnum } from "~types/enum";
import { z } from "zod";

export const HistoryDealFilterDTOSchema = z.object({
  type: HistoryFilterEnum,
  quantity: z.number().int().gte(0),
});

export interface IHistoryDealFilterDTOSchema
  extends z.infer<typeof HistoryDealFilterDTOSchema> {}
