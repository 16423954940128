import { AddressDTOSchema } from "./AddressDTOSchema";
import { CrmFileSchema } from "./CrmFileSchema";

import { z } from "zod";

export const CompanyFormSchema = z
  .object({
    id: z.number().int(),
    idPipedrive: z.number().int(),
    name: z.string(),
    address: z.array(AddressDTOSchema),
    logo: CrmFileSchema,
  })
  .partial();

export interface ICompanyFormSchema extends z.infer<typeof CompanyFormSchema> {}
