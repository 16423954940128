import { DiverseServiceDTOSchema } from "./DiverseServiceDTOSchema";

import { z } from "zod";

export const FetchUpdateDiverseServiceByDealIdPayloadSchema =
  DiverseServiceDTOSchema.extend({
    dealId: z.number().int().gt(0),
  });

export interface IFetchUpdateDiverseServiceByDealIdPayloadSchema
  extends z.infer<typeof FetchUpdateDiverseServiceByDealIdPayloadSchema> {}
