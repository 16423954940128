import { useMutation } from "react-query";

import { useFetchGetAllLaborsKey } from "../useFetchGetPaginatedLabors";
import type { IUseFetchCreateLabor } from "./interface";

import { fetchCreateLabor } from "~api/labors/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateLaborKey = "api/labors/post";

export function useFetchCreateLabor({ options }: IUseFetchCreateLabor) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateLabor(payload), {
    ...options,
    mutationKey: useFetchCreateLaborKey,
    onSuccess: (data, variables, context) => {
      message.success("Mão de obra criada com sucesso.");
      queryClient.invalidateQueries(useFetchGetAllLaborsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
