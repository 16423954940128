import { api, apiException } from "~services/api";
import {
  FetchGetConcessionaireProfilePayloadSchema,
  IFetchGetConcessionaireProfilePayloadSchema,
  ElectricUtilityProfileDTOSchema,
} from "~types/schemas";

export const fetchGetConcessionaireProfile = async (
  payload: IFetchGetConcessionaireProfilePayloadSchema,
) => {
  try {
    const { concessionaireProfileId, ...params } =
      FetchGetConcessionaireProfilePayloadSchema.parse(payload);

    const response = await api.get(
      `/electric-utility-profile/${concessionaireProfileId}`,
      {
        params,
      },
    );

    const parsedData = ElectricUtilityProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
