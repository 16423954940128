import { useQuery } from "react-query";

import type { IUseFetchGetMonthlyPerformanceRateByCustomId } from "./interface";

import { fetchGetMonthlyPerformanceRateByCustomId } from "~api/projects/step_two/monthly-performance-rate/{customId}/get";

export const fetchGetMonthlyPerformanceRateByCustomIdKey =
  "api/projects/step_two/monthly-performance-rate/{customId}/get";

export function useFetchGetMonthlyPerformanceRateByCustomId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetMonthlyPerformanceRateByCustomId) {
  return useQuery(
    [fetchGetMonthlyPerformanceRateByCustomIdKey, dependencyArray],
    async () => await fetchGetMonthlyPerformanceRateByCustomId(payload),
    options,
  );
}
