import { useQuery } from "react-query";

import { IUseFetchGetCompanyAddresses } from "./interface";

import { fetchGetAddressesCompany } from "~api/company/addresses/get";

export const useFetchGetCompanyAddressesKey = "api/company/addresses/get";

export function useFetchGetCompanyAddresses({
  dependencyArray = [],
  options,
}: IUseFetchGetCompanyAddresses) {
  return useQuery(
    [useFetchGetCompanyAddressesKey, dependencyArray],
    async () => {
      return await fetchGetAddressesCompany();
    },
    options,
  );
}
