import { QueryKey, useQuery } from "react-query";

import type {
  IUseFetchGenerateProposalPdf,
  UseFetchGenerateProposalPdfResultType,
  UseFetchGenerateProposalPdfErrorType,
} from "./interface";

import { getGenerateProposalPdf } from "~api/projects/step_five/custom-id/{customId}/{idTemplate}/get";

export const useFetchGenerateProposalPdfKey =
  "api/projects/step_five/custom-id/{customId}/{idTemplate}/get";

export function useFetchGenerateProposalPdf({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGenerateProposalPdf) {
  return useQuery<
    UseFetchGenerateProposalPdfResultType,
    UseFetchGenerateProposalPdfErrorType,
    UseFetchGenerateProposalPdfResultType,
    QueryKey
  >(
    [useFetchGenerateProposalPdfKey, dependencyArray],
    async () => {
      const response = await getGenerateProposalPdf({
        path: {
          projectId: payload.id,
          templateId: payload.templateId,
        },
      });

      const data = response.data;

      return data;
    },
    { ...options },
  );
}
