import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesByDealIdPayloadSchema,
  HistoryDealFilterDTOSchema,
  type IFetchGetHistoriesByDealIdPayloadSchema,
} from "~types/schemas";

export const fetchGetHistoriesFiltersTypeByDealid = async (
  payload: IFetchGetHistoriesByDealIdPayloadSchema,
) => {
  try {
    const queryParams = FetchGetHistoriesByDealIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/deal/filters/types", {
      params: queryParams,
    });

    const parsedData = HistoryDealFilterDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
