import { api, apiException } from "~services/api";
import {
  FetchDeleteConsultantUserPayloadSchema,
  IFetchDeleteConsultantUserPayloadSchema,
} from "~types/schemas";

export const fetchDeleteConsultantUser = async (
  payload: IFetchDeleteConsultantUserPayloadSchema,
) => {
  try {
    const { consultantId } =
      FetchDeleteConsultantUserPayloadSchema.parse(payload);

    await api.delete<void>(`/consultants/${consultantId}`);
  } catch (error) {
    throw apiException(error);
  }
};
