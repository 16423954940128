import { useQuery } from "react-query";

import type { IUseFetchGetAldoStructureByStructureId } from "./interface";

import { fetchGetAldoStructureByStructureId } from "~api/equipments/aldo/structure-by-relationship/{structureId}/get";

export const useFetchGetAldoStructureByStructureIdKey =
  "api/equipments/aldo/structure-by-relationship/{structureId}/get";

export function useFetchGetAldoStructureByStructureId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAldoStructureByStructureId) {
  return useQuery(
    [useFetchGetAldoStructureByStructureIdKey, dependencyArray],
    async () => await fetchGetAldoStructureByStructureId(payload),
    options,
  );
}
