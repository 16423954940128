import { BusinessUnitDTOSchema } from "./BusinessUnitDTOSchema";

import { z } from "zod";

export const UserAccountDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  lastname: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  idPipedrive: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  businessUnit: BusinessUnitDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  defaultProposalMessage: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  initialAccess: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  admin: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  avatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUserAccountDTOSchema
  extends z.infer<typeof UserAccountDTOSchema> {}
