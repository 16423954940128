import { useMutation } from "react-query";

import { useFetchGetProposalPaymentsKey } from "../useFetchGetProposalPayments";
import type { IUseUpdateManyProposalPayment } from "./interface";

import { fetchUpdateManyProposalPayment } from "~api/project/payment/simulation-item-project/custom-id/{custom-id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalPaymentsKey =
  "api/project/payment/simulation-item-project/custom-id/{custom-id}";

export function useFetchUpdateManyProposalPayment({
  options,
  displaySuccessMessage = true,
}: IUseUpdateManyProposalPayment) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateManyProposalPayment(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalPaymentsKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Método de pagamento criado");
        }
        queryClient.invalidateQueries(useFetchGetProposalPaymentsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao criar método de pagamento criado: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
