import { TemplateReportFormSchema } from "./TemplateReportFormSchema";

import { z } from "zod";

export const FetchUpdateReportTemplateByIdPayloadSchema =
  TemplateReportFormSchema.extend({
    reportTemplateId: z.number().int().gt(0),
  });

export interface IFetchUpdateReportTemplateByIdPayloadSchema
  extends z.infer<typeof FetchUpdateReportTemplateByIdPayloadSchema> {}
