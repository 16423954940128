import { z } from "zod";

export const OrganizationPeopleFormSchema = z.object({
  peopleId: z
    .array(z.number().int().gt(0))
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IOrganizationPeopleFormSchema
  extends z.infer<typeof OrganizationPeopleFormSchema> {}
