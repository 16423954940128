import { api, apiException } from "~services/api";

/*
  Types are here because in the swagger it was not documented
*/

export type FetchGetProposalPdf = {
  path: {
    id: number;
  };
};

export type FetchGetProposalPdfResponse = {
  file: string; // base64
};

export const fetchGetProposalPdf = async ({ path }: FetchGetProposalPdf) => {
  try {
    return await api.get<FetchGetProposalPdfResponse>(
      `/projects/step_five/pdf/custom-id/${path.id}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
