import { api, apiException } from "~services/api";
import {
  type IFetchGetTotalWonInsightPayloadSchema,
  FetchGetTotalWonInsightPayloadSchema,
  TotalWonInsightDTOSchema,
} from "~types/schemas";

export const fetchGetTotalWonInsight = async (
  payload: IFetchGetTotalWonInsightPayloadSchema,
) => {
  try {
    const queryParams = FetchGetTotalWonInsightPayloadSchema.parse(payload);

    const response = await api.get("/insights/totalWonInsight", {
      params: queryParams,
    });

    const parsedData = TotalWonInsightDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
