import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAdditionalCostMarginTypePayloadSchema,
  IFetchGetPaginatedAdditionalCostMarginTypePayloadSchema,
  PaginatedAdditionalCostMarginTypeDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedAdditionalCostMarginType = async (
  payload: IFetchGetPaginatedAdditionalCostMarginTypePayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedAdditionalCostMarginTypePayloadSchema.parse(payload);

    const response = await api.get(`/additional-cost-margin-type`, {
      params,
    });

    const parsedData = PaginatedAdditionalCostMarginTypeDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
