import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedRuleExpressionPayloadSchema,
  IFetchGetPaginatedRuleExpressionPayloadSchema,
  PaginatedRuleExpressionDTOSchema,
} from "~types/schemas";

export const fetchGetRuleExpression = async (
  payload: IFetchGetPaginatedRuleExpressionPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedRuleExpressionPayloadSchema.parse(payload);
    const response = await api.get(`/rule-expression`, {
      params,
    });

    const parsedData = PaginatedRuleExpressionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
