import { api, apiException } from "~services/api";
import {
  FetchDeletePerformanceProfilePayloadSchema,
  IFetchDeletePerformanceProfilePayloadSchema,
} from "~types/schemas";

export const fetchDeletePerformanceProfile = async (
  payload: IFetchDeletePerformanceProfilePayloadSchema,
) => {
  try {
    const { id } = FetchDeletePerformanceProfilePayloadSchema.parse(payload);

    await api.delete(`/performance-profile/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
