import { useQuery } from "react-query";

import { IUseFetchGetAlternatingCurrentMaterialCalcBase } from "./interface";

import { fetchGetAlternatingCurrentMaterialCalcBase } from "~api/ac-materials/calcBase/get";

export const useFetchGetAlternatingCurrentMaterialCalcBaseKey =
  "api/ac-materials/calcBase/get";

export function useFetchGetAlternatingCurrentMaterialCalcBase({
  dependencyArray = [],
  options,
}: IUseFetchGetAlternatingCurrentMaterialCalcBase) {
  return useQuery(
    [useFetchGetAlternatingCurrentMaterialCalcBaseKey, dependencyArray],
    async () => await fetchGetAlternatingCurrentMaterialCalcBase(),
    options,
  );
}
