import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { PhaseEnum } from "~types/enum";
import { z } from "zod";

export const LowVoltageDTOSchema = z.object({
  id: z.number().int().gt(0),
  averageConsumption: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  te: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusd: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  wireBTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxBefore: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfter: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  inverterTransformerPowerLimitKwp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlyValues: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ILowVoltageDTOSchema
  extends z.infer<typeof LowVoltageDTOSchema> {}
