import { api, apiException } from "~services/api";
import {
  FetchDeleteSimulationGroupPayloadSchema,
  IFetchDeleteSimulationGroupPayloadSchema,
} from "~types/schemas";

export const fetchDeleteSimulationGroup = async (
  payload: IFetchDeleteSimulationGroupPayloadSchema,
) => {
  try {
    const data = FetchDeleteSimulationGroupPayloadSchema.parse(payload);

    await api.delete<void>(`/project/payment/simulation`, {
      data,
    });
  } catch (error) {
    throw apiException(error);
  }
};
