import { z } from "zod";

export const AdditionalCostTaxFormSchema = z
  .object({
    id: z.number().int().gte(0),
    rate: z.number(),
    identifier: z.string().optional(),
    name: z.string(),
    active: z.boolean().optional(),
  })
  .partial();

export interface IAdditionalCostTaxFormSchema
  extends z.infer<typeof AdditionalCostTaxFormSchema> {}
