import { EdeltecStructureTypeEnum } from "~types/enum";
import { z } from "zod";

export const EdeltecStructureDTOSchema = z.object({
  structureType: EdeltecStructureTypeEnum.nullish().transform(
    (val) => val ?? "",
  ),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEdeltecStructureDTOSchema
  extends z.infer<typeof EdeltecStructureDTOSchema> {}
