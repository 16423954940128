import { useQuery } from "react-query";

import type { IUseFetchGetProposalExpirationRules } from "./interface";

import { fetchGetProposalExpirationRules } from "~api/projects/step_one/proposal-expiration-rules/get";

export const useFetchGetProposalExpirationRulesKey =
  "api/projects/step_one/proposal-expiration-rules/get";

export function useFetchGetProposalExpirationRules({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProposalExpirationRules) {
  return useQuery(
    [useFetchGetProposalExpirationRulesKey, dependencyArray],
    async () => await fetchGetProposalExpirationRules(payload),
    options,
  );
}
