import { api, apiException } from "~services/api";
import {
  FetchPatchPremisesUsefulAreaPayloadSchema,
  type IFetchPatchPremisesUsefulAreaPayloadSchema,
} from "~types/schemas";

export const fetchPatchPremisesUsefulArea = async (
  payload: IFetchPatchPremisesUsefulAreaPayloadSchema,
) => {
  try {
    const body = FetchPatchPremisesUsefulAreaPayloadSchema.parse(payload);

    await api.patch<void>(`/premises/useful-area`, body);
  } catch (error) {
    throw apiException(error);
  }
};
