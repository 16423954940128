import { api, apiException } from "~services/api";

export type FetchProposalFinish = {
  path: {
    id: number;
  };
};

export const fetchProposalFinish = async ({ path }: FetchProposalFinish) => {
  try {
    return await api.get<void>(
      `/projects/step_five/finish/custom-id/${path.id}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
