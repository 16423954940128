import { ImportProductQueueDTOSchema } from "./ImportProductQueueDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedImportProductQueueDTOSchema = PaginationSchema(
  ImportProductQueueDTOSchema,
);

export interface IPaginatedImportProductQueueDTOSchema
  extends z.infer<typeof PaginatedImportProductQueueDTOSchema> {}
