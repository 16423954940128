import { z } from "zod";

export const DealReasonForLossResponseSchema = z.object({
  reasonForLossId: z
    .number({ message: "ID do motivo de perda do negócio é obrigatório" })
    .int({ message: "ID do motivo de perda do negócio deve ser um inteiro" })
    .gt(0, {
      message: "ID do motivo de perda do negócio deve ser maior do que zero",
    }),
  reason: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  comment: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDealReasonForLossResponseSchema
  extends z.infer<typeof DealReasonForLossResponseSchema> {}
