import { api, apiException } from "~services/api";
import {
  IFetchGetEosFinancialProposalDetailsPayloadSchema,
  FetchGetEosFinancialProposalDetailsPayloadSchema,
  EosProposalDetailResponseSchema,
} from "~types/schemas";

export const fetchGetEosFinancialProposalDetails = async (
  payload: IFetchGetEosFinancialProposalDetailsPayloadSchema,
) => {
  try {
    const { protocol } =
      FetchGetEosFinancialProposalDetailsPayloadSchema.parse(payload);

    const response = await api.get(`/eosfin/proposals/${protocol}`);

    const parsedData = EosProposalDetailResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
