import { api, apiException } from "~services/api";
import {
  CustomFieldValuesDTOSchema,
  FetchCreateCustomFieldValuePayloadSchema,
  IFetchCreateCustomFieldValuePayloadSchema,
} from "~types/schemas";

export const fetchCreateCustomFieldValue = async (
  payload: IFetchCreateCustomFieldValuePayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchCreateCustomFieldValuePayloadSchema.parse(payload);
    const response = await api.patch(
      `/projects/step_two/intermediate-custom-field-value/custom-id/${customId}`,
      body,
    );

    const parsedData = CustomFieldValuesDTOSchema.parse(response.data);
    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
