import { z } from "zod";

export const UserFormSchema = z.object({
  email: z.string(),
  name: z.string(),
  lastName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  profileId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IUserFormSchema extends z.infer<typeof UserFormSchema> {}
