import { z } from "zod";

export const CustomEosProposalResponseSchema = z.object({
  protocol: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  clientName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  gracePeriod: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  installment: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  downPayment: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICustomEosProposalResponseSchema
  extends z.infer<typeof CustomEosProposalResponseSchema> {}
