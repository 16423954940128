import { OrganizationPeopleFormSchema } from "./OrganizationPeopleFormSchema";

import { z } from "zod";

export const FetchAddPersonToOrganizationPayloadSchema =
  OrganizationPeopleFormSchema.extend({
    organizationId: z.number().int().gt(0),
  });

export interface IFetchAddPersonToOrganizationPayloadSchema
  extends z.infer<typeof FetchAddPersonToOrganizationPayloadSchema> {}
