import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesByOrganizationIdPayloadSchema,
  HistoryOrganizationFilterDTOSchema,
  type IFetchGetHistoriesByOrganizationIdPayloadSchema,
} from "~types/schemas";

export const fetchGetHistoriesFiltersTypeByOrganizationId = async (
  payload: IFetchGetHistoriesByOrganizationIdPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetHistoriesByOrganizationIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/organization/filters/types", {
      params: queryParams,
    });

    const parsedData = HistoryOrganizationFilterDTOSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
