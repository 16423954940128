import { api, apiException } from "~services/api";
import { StepFourUpdateValueForCustomerForm } from "~types/api/StepFourUpdateValueForCustomerForm";

export type FetchUpdateValueForCustomer = {
  path: {
    id: number;
  };
  body: StepFourUpdateValueForCustomerForm;
};

export const fetchUpdateValueForCustomer = async ({
  path,
  body,
}: FetchUpdateValueForCustomer) => {
  try {
    return await api.put<number>(
      `/projects/step_four/custom-id/updateValueForCustomer/${path.id}`,
      body,
    );
  } catch (error) {
    throw apiException(error);
  }
};
