import { api, apiException } from "~services/api";
import {
  IFetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema,
  FetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema,
  MonthlyPerformanceRate,
} from "~types/schemas";

export const fetchUpdateMonthlyPerformanceRateByCustomId = async (
  payload: IFetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateMonthlyPerformanceRateByCustomIdPayloadSchema.parse(payload);

    const response = await api.patch(
      `/projects/step_two/monthly-performance-rate/${customId}`,
      body,
    );

    const parsedData = MonthlyPerformanceRate.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
