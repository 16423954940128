import { useQuery } from "react-query";

import type { IUseFetchGetLaborsSuggestedByStructureId } from "./interface";

import { fetchGetLaborsSuggestedByStructureId } from "~api/labors/suggested/{idStructure}/get";

export const useFetchGetAllLaborsSuggestedByStructureIdKey =
  "api/labors/suggested/{idStructure}/get";

export function useFetchGetLaborsSuggestedByStructureId({
  dependencyArray = [],
  options,
  payload = {
    structureId: 0,
  },
}: IUseFetchGetLaborsSuggestedByStructureId) {
  return useQuery(
    [useFetchGetAllLaborsSuggestedByStructureIdKey, dependencyArray],
    async () => await fetchGetLaborsSuggestedByStructureId(payload),
    options,
  );
}
