import { TypeDocumentEnum } from "~types/enum";
import { z } from "zod";

export const EosDocumentSearchDataSchema = z.object({
  tipoDocumento: TypeDocumentEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosDocumentSearchDataSchema
  extends z.infer<typeof EosDocumentSearchDataSchema> {}
