import { api, apiException } from "~services/api";
import {
  FetchDeleteProposalExpirationRulePayloadSchema,
  IFetchDeleteProposalExpirationRulePayloadSchema,
} from "~types/schemas";

export const fetchDeleteProposalExpirationRule = async (
  payload: IFetchDeleteProposalExpirationRulePayloadSchema,
) => {
  try {
    const { ProposalExpirationRuleId } =
      FetchDeleteProposalExpirationRulePayloadSchema.parse(payload);

    await api.delete(`/proposal-expiration-rule/${ProposalExpirationRuleId}`);
  } catch (error) {
    throw apiException(error);
  }
};
