import { api, apiException } from "~services/api";
import {
  FetchUnlinkPipedrivePayloadSchema,
  type IFetchUnlinkPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchUnlinkPipedrive = async (
  payload: IFetchUnlinkPipedrivePayloadSchema,
) => {
  try {
    const { companyId } = FetchUnlinkPipedrivePayloadSchema.parse(payload);

    await api.patch<void>(`/admin/users/${companyId}/unlink-pipedrive`);
  } catch (error) {
    throw apiException(error);
  }
};
