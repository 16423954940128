import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesTypeFilterByOrganizationId } from "./interface";

import { fetchGetHistoriesFiltersTypeByOrganizationId } from "~api/histories/organization/filters/types/get";

export const useFetchGetHistoriesTypeFilterByOrganizationIdKey =
  "api/histories/organization/filters/types/get";

export function useFetchGetHistoriesTypeFilterByOrganizationId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesTypeFilterByOrganizationId) {
  return useQuery(
    [useFetchGetHistoriesTypeFilterByOrganizationIdKey, dependencyArray],
    async () => await fetchGetHistoriesFiltersTypeByOrganizationId(payload),
    options,
  );
}
