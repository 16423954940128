import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedInverters } from "./interface";

import { fetchGetPaginatedInverters } from "~api/product/inversores/post";

export const useFetchGetPaginatedInvertersKey = "api/product/inversores/post";

export function useFetchGetPaginatedInverters({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
  },
}: IUseFetchGetPaginatedInverters) {
  return useQuery(
    [useFetchGetPaginatedInvertersKey, dependencyArray],
    async () => await fetchGetPaginatedInverters(payload),
    options,
  );
}
