import { useQuery } from "react-query";

import type { IUseFetchGetReports } from "./interface";

import { fetchGetReports } from "~api/reports/get";

export const useFetchGetReportsKey = "api/reports/get";

export function useFetchGetReports({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetReports) {
  return useQuery(
    [useFetchGetReportsKey, dependencyArray],
    async () => await fetchGetReports(payload),
    options,
  );
}
