import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchDeletePaymentSimulation } from "./interface";

import { fetchDeleteSimulationGroup } from "~api/project/payment/simulation/group/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePaymentSimulationKey =
  "api/project/payment/simulation/group/delete";

export function useFetchDeletePaymentSimulation({
  options,
}: IUseFetchDeletePaymentSimulation) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteSimulationGroup(payload),
    {
      ...options,
      mutationKey: useFetchDeletePaymentSimulationKey,
      onSuccess: (data, variables, context) => {
        message.success("Simulação foi removida");
        queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao remover simulação: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
