import { z } from "zod";

const cellTypeValues = [
  "MONOCRISTALINO",
  "POLICRISTALINO",
  "MONO_PERC",
] as const;

export const CellTypeEnum = z.enum(cellTypeValues).catch((e) => e.input);

export type CellTypeEnum = z.infer<typeof CellTypeEnum>;
