import { BusinessUnitFormSchema } from "./BusinessUnitFormSchema";

import { z } from "zod";

export const FetchUpdateBusinessUnitPayloadSchema =
  BusinessUnitFormSchema.extend({
    businessUnitId: z.number().int().gt(0),
  });

export interface IFetchUpdateBusinessUnitPayloadSchema
  extends z.infer<typeof FetchUpdateBusinessUnitPayloadSchema> {}
