import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import { useFetchGetCountOfUsersAccountOwnerKey } from "../useFetchGetCountOfUsersAccountOwner";
import type {
  IUseFetchBlockUser,
  UseFetchBlockUserResultType,
  UseFetchBlockUserErrorType,
  UseFetchBlockUserPayloadType,
} from "./interface";

import { fetchBlockUser } from "~api/admin/users/{id}/block/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchBlockUserKey = "api/admin/users/{id}/block/patch";

export function useFetchBlockUser(options?: IUseFetchBlockUser) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchBlockUserResultType,
    UseFetchBlockUserErrorType,
    UseFetchBlockUserPayloadType,
    unknown
  >(
    async (payload: UseFetchBlockUserPayloadType) => {
      await fetchBlockUser({
        path: {
          id: payload.id,
        },
      });
    },
    {
      ...options,
      mutationKey: useFetchBlockUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Acesso do usuário foi bloqueado!");
        queryClient.invalidateQueries(useFetchGetAdminUsersKey);
        queryClient.invalidateQueries(useFetchGetCountOfUsersAccountOwnerKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao bloquear usuário: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
