import { z } from "zod";

export const DealDefaultFieldsDTOSchema = z.object({
  id: z.number().int().gt(0),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  configurable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IDealDefaultFieldsDTOSchema
  extends z.infer<typeof DealDefaultFieldsDTOSchema> {}
