import { api, apiException } from "~services/api";
import {
  type IFetchPutSysAdminUserPayloadSchema,
  FetchPutSysAdminUserPayloadSchema,
  SysAdminUserDTOSchema,
} from "~types/schemas";

export const fetchPutSysAdminManagement = async (
  payload: IFetchPutSysAdminUserPayloadSchema,
) => {
  try {
    const { id, ...body } = FetchPutSysAdminUserPayloadSchema.parse(payload);

    const response = await api.put(`/sys-admin-management/${id}`, body);

    const parsedData = SysAdminUserDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
