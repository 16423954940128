import { z } from "zod";

export const FinancialFormSchema = z.object({
  currentEnergyInflation: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  vplDiscountRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  opexRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFinancialFormSchema
  extends z.infer<typeof FinancialFormSchema> {}
