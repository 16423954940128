import { z } from "zod";

export const FetchGetPermissionsAssignToProfileByProfileIdPayloadSchema =
  z.object({
    profileId: z.number().int().gt(0),
  });

export interface IFetchGetPermissionsAssignToProfileByProfileIdPayloadSchema
  extends z.infer<
    typeof FetchGetPermissionsAssignToProfileByProfileIdPayloadSchema
  > {}
