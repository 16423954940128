import { EosCompanySchema } from "./EosCompanySchema";
import { EosCredentialSchema } from "./EosCredentialSchema";

import { z } from "zod";

export const EosOrganizationSchema = z.object({
  rendaMensal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  outrasRendas: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  credencial: EosCredentialSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  empresa: EosCompanySchema.nullish().transform((val) => val ?? undefined),
});

export interface IEosOrganizationSchema
  extends z.infer<typeof EosOrganizationSchema> {}
