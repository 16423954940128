import { z } from "zod";

export const StepOneExpirationRuleSchema = z.object({
  proposalExpirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IStepOneExpirationRuleSchema
  extends z.infer<typeof StepOneExpirationRuleSchema> {}
