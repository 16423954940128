import { useMutation } from "react-query";

import { useFetchGetAllPipelinesKey } from "../useFetchGetAllPipelines";
import { useFetchGetAllPipelinesDetailedKey } from "../useFetchGetAllPipelinesDetailed";
import type { IUseFetchDeletePipeline } from "./interface";

import { fetchDeletePipeline } from "~api/pipeline/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePipelineKey = "api/pipeline/{id}/delete";

export function useFetchDeletePipeline({ options }: IUseFetchDeletePipeline) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeletePipeline(payload), {
    ...options,
    mutationKey: useFetchDeletePipelineKey,
    onSuccess: (data, variables, context) => {
      message.success("Pipeline foi apagada!");
      queryClient.invalidateQueries(useFetchGetAllPipelinesKey);
      queryClient.invalidateQueries(useFetchGetAllPipelinesDetailedKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao apagar Pipeline: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
