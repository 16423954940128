import { z } from "zod";

export const activityEventTypeEnum = [
  "CALL",
  "MEETING",
  "TASK",
  "EMAIL",
  "PROPOSAL",
] as const;

export const ActivityEventTypeEnum = z
  .enum(activityEventTypeEnum)
  .catch((e) => e.input);

export type ActivityEventTypeEnum = z.infer<typeof ActivityEventTypeEnum>;
