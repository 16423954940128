import { useQuery } from "react-query";

import { IUseFetchGetHideableFields } from "./interface";

import { fetchGetHideableFields } from "~api/fields/hideable/get";

export const useFetchGetHideableFieldsKey = "api/fields/hideable/get";

export function useFetchGetHideableFields({
  dependencyArray = [],
  options,
}: IUseFetchGetHideableFields) {
  return useQuery(
    [useFetchGetHideableFieldsKey, dependencyArray],
    async () => await fetchGetHideableFields(),
    options,
  );
}
