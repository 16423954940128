import { api, apiException } from "~services/api";
import {
  FetchDeleteProjectByCustomIdPayloadSchema,
  IFetchDeleteProjectByCustomIdPayloadSchema,
} from "~types/schemas";

export const fetchDeleteProjectByCustomId = async (
  payload: IFetchDeleteProjectByCustomIdPayloadSchema,
) => {
  try {
    const { customId } =
      FetchDeleteProjectByCustomIdPayloadSchema.parse(payload);

    await api.delete(`/projects/custom-id/${customId}`);
  } catch (error) {
    throw apiException(error);
  }
};
