import { useMutation } from "react-query";

import { useFetchGetPaginatedBusinessUnitsKey } from "../useFetchGetPaginatedBusinessUnits";
import { useFetchGetUserBusinessUnitsKey } from "../useFetchGetUserBusinessUnits";
import type { IUseFetchUpdateBusinessUnit } from "./interface";

import { fetchUpdateBusinessUnit } from "~api/business-units/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateBusinessUnitKey = "api/business-units/{id}/put";

export function useFetchUpdateBusinessUnit({
  options,
}: IUseFetchUpdateBusinessUnit) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateBusinessUnit(payload),
    {
      ...options,
      mutationKey: useFetchUpdateBusinessUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidade de negócio foi alterada");
        queryClient.invalidateQueries(useFetchGetPaginatedBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao alterar unidade de negócio: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
