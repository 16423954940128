import { useQuery } from "react-query";

import { IUseFetchGetPaginatedComplementaryItemsOfSettings } from "./interface";

import { fetchGetPaginatedComplementaryItems } from "~api/additional-costs/get";

export const useFetchGetPaginatedComplementaryItemsOfSettingsKey =
  "api/additional-costs/get";

export function useFetchGetPaginatedComplementaryItemsOfSettings({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedComplementaryItemsOfSettings) {
  return useQuery(
    [useFetchGetPaginatedComplementaryItemsOfSettingsKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedComplementaryItems(payload);
    },
    options,
  );
}
