import { PipelineFormSchema } from "./PipelineFormSchema";

import { z } from "zod";

export const FetchUpdatePipelinePayloadSchema = PipelineFormSchema.omit({
  id: true,
}).extend({
  id: z.number().int().gt(0),
});

export interface IFetchUpdatePipelinePayloadSchema
  extends z.infer<typeof FetchUpdatePipelinePayloadSchema> {}
