import { useQuery } from "react-query";

import type { IUseFetchGetRemainingPercentage } from "./interface";

import { fetchGetRemainingPercentage } from "~api/additional-costs/remaining-percentage/get";

export const useFetchGetRemainingPercentageKey =
  "api/additional-costs/remaining-percentage/get";

export function useFetchGetRemainingPercentage({
  dependencyArray = [],
  options,
}: IUseFetchGetRemainingPercentage) {
  return useQuery(
    [useFetchGetRemainingPercentageKey, dependencyArray],
    async () => await fetchGetRemainingPercentage(),
    options,
  );
}
