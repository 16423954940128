import { useQuery } from "react-query";

import type { IUseFetchGetReportTemplateById } from "./interface";

import { fetchGetReportTemplateById } from "~api/reports/{id}/get";

export const useFetchGetReportTemplateKey = "api/reports/{id}/get";

export function useFetchGetReportTemplateById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetReportTemplateById) {
  return useQuery(
    [useFetchGetReportTemplateKey, dependencyArray],
    async () => await fetchGetReportTemplateById(payload),
    options,
  );
}
