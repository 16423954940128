import { z } from "zod";

export const FetchGetPdfBase64FromDatasheetRevisionById = z.object({
  id: z
    .number({ message: "ID do Datasheet é obrigatório" })
    .int({ message: "ID do Datasheet deve ser um inteiro" })
    .gt(0, { message: "ID do Datasheet deve ser maior que zero" }),
  revision: z
    .number({ message: "ID da revisão é obrigatório" })
    .int({ message: "ID da revisão deve ser um inteiro" })
    .gt(0, { message: "ID da revisão deve ser maior que zero" }),
});

export interface IFetchGetPdfBase64FromDatasheetRevisionById
  extends z.infer<typeof FetchGetPdfBase64FromDatasheetRevisionById> {}
