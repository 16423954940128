import { api, apiException } from "~services/api";
import {
  EosProtocolResponseSchema,
  FetchCreatePersonEosFinancingPayloadSchema,
  IFetchCreatePersonEosFinancingPayloadSchema,
} from "~types/schemas";

export const fetchCreatePersonEosFinancing = async (
  payload: IFetchCreatePersonEosFinancingPayloadSchema,
) => {
  try {
    const body = FetchCreatePersonEosFinancingPayloadSchema.parse(payload);

    const response = await api.post(`/eosfin/person`, body);

    const parsedData = EosProtocolResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
