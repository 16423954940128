import { useQuery } from "react-query";

import { IUseFetchGetReportPdfBase64 } from "./interface";

import { fetchGetReportPdfBase64 } from "~api/reports/download/pdf/base64/{uuid}/get";

export const useFetchGetReportPdfBase64Key =
  "api/reports/download/pdf/base64/{uuid}/get";

export function useFetchGetReportPdfBase64({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetReportPdfBase64) {
  return useQuery(
    [useFetchGetReportPdfBase64Key, dependencyArray],
    async () => {
      return await fetchGetReportPdfBase64(payload);
    },
    options,
  );
}
