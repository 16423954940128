import { z } from "zod";

export const EdeltecStructureTypeEnum = z
  .enum([
    "COLONIAL",
    "FIBROMADEIRA",
    "FIBROMETAL",
    "FIBROMETAL_ROSCA_DUPLA",
    "KALHETAO",
    "LAJE",
    "METALICO",
    "SOLO",
  ] as const)
  .catch((e) => e.input);

export type EdeltecStructureTypeEnum = z.infer<typeof EdeltecStructureTypeEnum>;
