import { CityDTOSchema } from "./CityDTOSchema";

import { VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const SolarServiceResponseSchema = z.object({
  id: z.number().int().gt(0),
  voltageType: VoltageTypeEnum.nullish().transform((val) => val ?? undefined),
  energyRequirement: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  energyRequirementFp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  desiredPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  city: CityDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface ISolarServiceResponseSchema
  extends z.infer<typeof SolarServiceResponseSchema> {}
