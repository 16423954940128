import { api, apiException } from "~services/api";
import {
  FetchCreateNotePayloadSchema,
  type IFetchCreateNotePayloadSchema,
  NoteDTOSchema,
} from "~types/schemas";

export const fetchCreateNote = async (
  payload: IFetchCreateNotePayloadSchema,
) => {
  try {
    const body = FetchCreateNotePayloadSchema.parse(payload);

    const response = await api.post(`/note`, body);

    const parsedData = NoteDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
