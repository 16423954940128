import { CommissionDTOSchema } from "./CommissionDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCommissionDTOSchema =
  PaginationSchema(CommissionDTOSchema);

export interface IPaginatedCommissionDTOSchema
  extends z.infer<typeof PaginatedCommissionDTOSchema> {}
