import { ConversionRateGroupedByUserInsightDataSchema } from "./ConversionRateGroupedByUserInsightDataSchema";

import { z } from "zod";

export const ConversionRateGroupedByUserInsightDataDtoSchema = z.object({
  data: ConversionRateGroupedByUserInsightDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IConversionRateGroupedByUserInsightDataDtoSchema
  extends z.infer<typeof ConversionRateGroupedByUserInsightDataDtoSchema> {}
