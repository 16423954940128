import { z } from "zod";

export const FortlevStructureDTOSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IFortlevStructureDTOSchema
  extends z.infer<typeof FortlevStructureDTOSchema> {}
