import { useQuery } from "react-query";

import type { IUseFetchGetOrganizationById } from "./interface";

import { fetchGetOrganizationById } from "~api/organizations/{id}/get";

export const useFetchGetOrganizationByIdKey = "api/organizations/{id}/get";

export function useFetchGetOrganizationById({
  options,
  dependencyArray,
  payload,
  customKey = useFetchGetOrganizationByIdKey,
}: IUseFetchGetOrganizationById) {
  return useQuery(
    [customKey, dependencyArray],
    async () => await fetchGetOrganizationById(payload),
    options,
  );
}
