import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedPerson } from "./interface";

import { fetchGetPaginatedPerson } from "~api/persons/pageable/get";

export const useFetchGetPaginatedPersonKey = "api/persons/pageable/get";

export function useFetchGetPaginatedPerson({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
    query: undefined,
    userId: undefined,
  },
}: IUseFetchGetPaginatedPerson) {
  return useQuery(
    [useFetchGetPaginatedPersonKey, dependencyArray],
    async () => await fetchGetPaginatedPerson(payload),
    options,
  );
}
