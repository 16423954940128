import { api, apiException } from "~services/api";
import {
  FetchCancelImportProductQueuePayloadSchema,
  IFetchCancelImportProductQueuePayloadSchema,
} from "~types/schemas";

export const fetchCancelImportProductQueue = async (
  payload: IFetchCancelImportProductQueuePayloadSchema,
) => {
  try {
    const { id } = FetchCancelImportProductQueuePayloadSchema.parse(payload);

    await api.patch(`/product-queue/cancel/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
