import { EquipmentCategoryEnum } from "~types/enum";
import { z } from "zod";

export const ItemKitProjectSchema = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  category: EquipmentCategoryEnum.optional(),
  value: z.number().optional(),
  quantity: z.number(),
  description: z.string().optional(),
  totalValue: z.number(),
  systemGenerated: z.boolean().optional(),
  manuallyAdded: z.boolean().optional(),
  additionalCostDescriptorId: z.number().int().optional(),
  print: z.boolean().optional(),
  deactivated: z.boolean().optional(),
  nonDeactivated: z.boolean().optional(),
});

export interface IItemKitProjectSchema
  extends z.infer<typeof ItemKitProjectSchema> {}
