import { useMutation } from "react-query";

import { useFetchGetPaginatedOtherProductsKey } from "../useFetchGetPaginatedOtherProducts";
import type { IUseFetchCreateProductTypeOther } from "./interface";

import { fetchCreateProductTypeOther } from "~api/product/other/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateProductTypeOtherKey = "api/product/other/post";

export function useFetchCreateProductTypeOther({
  options,
}: IUseFetchCreateProductTypeOther) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateProductTypeOther(payload),
    {
      ...options,
      mutationKey: useFetchCreateProductTypeOtherKey,
      onSuccess: (data, variables, context) => {
        message.success("Produto foi adicionado");
        queryClient.invalidateQueries(useFetchGetPaginatedOtherProductsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao adicionar produto: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
