import { z } from "zod";

export const UpdateTransformerAdditionalCostFormSchema = z.object({
  id: z.number().int(),
  prices: z.record(z.number()),
  defaultPrice: z.number(),
});

export interface IUpdateTransformerAdditionalCostFormSchema
  extends z.infer<typeof UpdateTransformerAdditionalCostFormSchema> {}
