import { z } from "zod";

export const FetchPatchDealDefaultFieldsSettingsPayloadSchema = z.object({
  id: z.number().int().gt(0),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IFetchPatchDealDefaultFieldsSettingsPayloadSchema
  extends z.infer<typeof FetchPatchDealDefaultFieldsSettingsPayloadSchema> {}
