import { ModuleSummaryDTOSchema } from "./ModuleSummaryDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedModuleSummaryDTOSchema = PaginationSchema(
  ModuleSummaryDTOSchema,
);

export interface IPaginatedModuleSummaryDTOSchema
  extends z.infer<typeof PaginatedModuleSummaryDTOSchema> {}
