import { api, apiException } from "~services/api";
import {
  IFetchUpdateStructureDistributorsPayloadSchema,
  StructureDistributorDTOSchema,
  StructureDistributorFormSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchUpdateStructureDistributors = async (
  payload: IFetchUpdateStructureDistributorsPayloadSchema,
) => {
  try {
    const body = z.array(StructureDistributorFormSchema).parse(payload);

    const response = await api.put(`/structure-distributor`, body);

    const parsedData = z
      .array(StructureDistributorDTOSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
