import { api, apiException } from "~services/api";
import { PersonCustomFieldDtoSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetPersonCustomFields = async () => {
  try {
    const response = await api.get(`/config/personCustomFields`);

    const parsedData = z
      .array(PersonCustomFieldDtoSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
