import { CostAggregatorDTOSchema } from "./CostAggregatorDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import { z } from "zod";

export const LogisticsDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  minimunDistance: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pricePerKm: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  additionalCostTaxId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  notApplyDefaultAdditionalCostTax: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  costAggregators: z
    .array(CostAggregatorDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ILogisticsDTOSchema
  extends z.infer<typeof LogisticsDTOSchema> {}
