import { api, apiException } from "~services/api";
import {
  FetchUpdateBusinessUnitPayloadSchema,
  IFetchUpdateBusinessUnitPayloadSchema,
} from "~types/schemas";

export const fetchUpdateBusinessUnit = async (
  payload: IFetchUpdateBusinessUnitPayloadSchema,
) => {
  try {
    const { businessUnitId, ...body } =
      FetchUpdateBusinessUnitPayloadSchema.parse(payload);

    await api.put(`/business-units/${businessUnitId}`, body);
  } catch (error) {
    throw apiException(error);
  }
};
