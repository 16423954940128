import { api, apiException } from "~services/api";
import { z } from "zod";

export const fetchGetElectricUtilityProfileConsumerClass = async () => {
  try {
    const response = await api.get(`/electric-utility-profile/consumer-class`);

    const parsedData = z
      .array(z.string())
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
