import { SimultaneityProfileFormSchema } from "./SimultaneityProfileFormSchema";

import { z } from "zod";

export const FetchUpdateSimultaneityProfilePayloadSchema =
  SimultaneityProfileFormSchema.extend({
    simultaneityProfileId: z.number().int().gt(0),
  });

export interface IFetchUpdateSimultaneityProfilePayloadSchema
  extends z.infer<typeof FetchUpdateSimultaneityProfilePayloadSchema> {}
