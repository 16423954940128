import { useQuery } from "react-query";

import type { IUseFetchGetLostMotiveInsight } from "./interface";

import { fetchGetLostMotiveInsight } from "~api/insights/lostMotiveInsight/get";

export const useFetchGetLostMotiveInsightKey =
  "api/insights/lostMotiveInsight/get";

export function useFetchGetLostMotiveInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetLostMotiveInsight) {
  return useQuery(
    [useFetchGetLostMotiveInsightKey, dependencyArray],
    async () => await fetchGetLostMotiveInsight(payload),
    options,
  );
}
