import { InverterTypeEnum, PhaseEnum } from "~types/enum";
import { z } from "zod";

export const InverterSummaryDTOSchema = z.object({
  id: z
    .number({ message: "Faltando o ID do inversor" })
    .int({ message: "O ID do inversor deve ser um inteiro" })
    .gt(0, { message: "O ID do inversor tem que ser maior que zero" }),
  model: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  manufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  nominalPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  type: InverterTypeEnum.nullish().transform((val) => val ?? undefined),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  datasheetId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IInverterSummaryDTOSchema
  extends z.infer<typeof InverterSummaryDTOSchema> {}
