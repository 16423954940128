import { ItemKitProjectSchema } from "./ItemKitProjectSchema";
import { StructureTypeSchema } from "./StructureTypeSchema";

import { PhaseEnum, VoltageEnum } from "~types/enum";
import { z } from "zod";

export const EquipmentKitProjectSchema = z
  .object({
    id: z.number().int(),
    supplierName: z.string(),
    code: z.number().int(),
    name: z.string(),
    fullPower: z.number(),
    totalValue: z.number(),
    inverterBrand: z.string(),
    moduleBrand: z.string(),
    creatorUserId: z.number().int(),
    active: z.boolean(),
    inverterPower: z.number(),
    inverterMaxPower: z.number(),
    sourceKitId: z.number().int(),
    generationInKwh: z.number(),
    minArea: z.number(),
    inverterPhase: PhaseEnum,
    outputVoltage: VoltageEnum,
    structureType: StructureTypeSchema,
    projectData: z.record(z.string()),
    items: z.array(ItemKitProjectSchema),
  })
  .partial();

export interface IEquipmentKitProjectSchema
  extends z.infer<typeof EquipmentKitProjectSchema> {}
