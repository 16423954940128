import { useMutation } from "react-query";

import { useFetchGetProposalFieldsKey } from "../useFetchGetProposalFields";
import type { IUseFetchUpdateProposalDefaultFields } from "./interface";

import { fetchUpdateProposalDefaultField } from "~api/fields/settings/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalDefaultFieldsKey =
  "api/fields/settings/{id}/put";

export function useFetchUpdateProposalDefaultFields({
  options,
}: IUseFetchUpdateProposalDefaultFields) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProposalDefaultField(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalDefaultFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi atualizado!");
        queryClient.invalidateQueries(useFetchGetProposalFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao atualizar campo: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
