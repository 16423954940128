import { PvModuleFormSchema } from "./PvModuleFormSchema";

import { z } from "zod";

export const FetchUpdateAdminModulesPayloadSchema = PvModuleFormSchema.extend({
  id: z.number({ message: "ID do módulo é obrigatório! " }).int().gt(0),
});

export interface IFetchUpdateAdminModulesPayloadSchema
  extends z.infer<typeof FetchUpdateAdminModulesPayloadSchema> {}
