import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldsKey } from "../../useFetchGetDealCustomFields";
import { useFetchGetDealCustomFieldGroupByIdKey } from "../useFetchGetDealCustomFieldGroupById";
import { useFetchGetDealCustomFieldsGroupKey } from "../useFetchGetDealCustomFieldsGroup";
import { useFetchGetDealCustomFieldsGroupListKey } from "../useFetchGetDealCustomFieldsGroupList";
import type { IUseFetchUpdateDealCustomFieldGroup } from "./interface";

import { fetchUpdateDealCustomFieldGroup } from "~api/config/deals/custom-fields/groups/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateDealCustomFieldGroupKey =
  "api/config/deals/custom-fields/groups/{id}/put";

export function useFetchUpdateDealCustomFieldGroup({
  options,
}: IUseFetchUpdateDealCustomFieldGroup) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateDealCustomFieldGroup(payload),
    {
      ...options,
      mutationKey: useFetchUpdateDealCustomFieldGroupKey,
      onSuccess: (data, variables, context) => {
        message.success("Grupo de campos customizados de negócio foi alterado");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupListKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldGroupByIdKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
