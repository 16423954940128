import { RuleDTOSchema } from "./RuleDTOSchema";

import { z } from "zod";

export const CommissionFormSchema = z.object({
  description: z.string(),
  rules: z.array(RuleDTOSchema).optional(),
  sellers: z.array(z.string()).optional(),
});

export interface ICommissionFormSchema
  extends z.infer<typeof CommissionFormSchema> {}
