import { z } from "zod";

export const WegParameterDTOSchema = z.object({
  id: z.number().int().gt(0),
  companyId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  discountRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  freightRate: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  autoSizingCAProtector: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  autoSizingWiring: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  autoSizingConnector: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  includeInverterProtection: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  defaultRegionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  includeOutOfStock: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IWegParameterDTOSchema
  extends z.infer<typeof WegParameterDTOSchema> {}
