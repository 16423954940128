import { ConcessionaireProfileFormSchema } from "./ConcessionaireProfileFormSchema";

import { z } from "zod";

export const FetchUpdateConcessionaireProfilePayloadSchema =
  ConcessionaireProfileFormSchema.extend({
    concessionaireProfileId: z.number().int().gt(0),
  });

export interface IFetchUpdateConcessionaireProfilePayloadSchema
  extends z.infer<typeof FetchUpdateConcessionaireProfilePayloadSchema> {}
