import { useMutation } from "react-query";

import { useFetchGetPipedriveApiTokenKey } from "../useFetchGetPipedriveApiToken";
import type { IUseFetchUpdatePipedriveApiToken } from "./interface";

import { fetchUpdatePipedriveApiToken } from "~api/company/pipedrive-api-token/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePipedriveApiTokenKey =
  "api/company/pipedrive-api-token/patch";

export function useFetchUpdatePipedriveApiToken({
  options,
}: IUseFetchUpdatePipedriveApiToken) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdatePipedriveApiToken(payload),
    {
      ...options,
      mutationKey: useFetchUpdatePipedriveApiTokenKey,
      onSuccess: (data, variables, context) => {
        message.success("Token alterado com sucesso!");

        queryClient.invalidateQueries(useFetchGetPipedriveApiTokenKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao alterar o token: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
