import { z } from "zod";

export const EdeltecParameterDTOSchema = z.object({
  id: z.number().int().gt(0),
  accessKey: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  password: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEdeltecParameterDTOSchema
  extends z.infer<typeof EdeltecParameterDTOSchema> {}
