import { useQuery } from "react-query";

import type { IUseFetchGetIrradiationSources } from "./interface";

import { fetchGetIrradiationSources } from "~api/premises/ghi/system/get";

export const useFetchGetIrradiationSourcesKey = "api/premises/ghi/system/get";

export function useFetchGetIrradiationSources({
  dependencyArray = [],
  options,
}: IUseFetchGetIrradiationSources) {
  return useQuery(
    [useFetchGetIrradiationSourcesKey, dependencyArray],
    async () => await fetchGetIrradiationSources(),
    options,
  );
}
