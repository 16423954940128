import { api, apiException } from "~services/api";
import { CostAggregatorDTO } from "~types/api/CostAggregatorDTO";
import {
  CostAggregatorDTOSchema,
  FetchUpdateCostAggregatorPayloadSchema,
  IFetchUpdateCostAggregatorPayloadSchema,
} from "~types/schemas";

export const fetchUpdateCostAggregator = async (
  payload: IFetchUpdateCostAggregatorPayloadSchema,
) => {
  try {
    const { costAggregatorId, ...body } =
      FetchUpdateCostAggregatorPayloadSchema.parse(payload);

    const response = await api.put<CostAggregatorDTO>(
      `/cost-aggregator/${costAggregatorId}`,
      body,
    );

    const parsedData = CostAggregatorDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
