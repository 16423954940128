import { z } from "zod";

export const StructureDistributorFormSchema = z.object({
  structureTypeId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  distributorId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  structureDistributorIdentifiers: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? []),
});

export interface IStructureDistributorFormSchema
  extends z.infer<typeof StructureDistributorFormSchema> {}
