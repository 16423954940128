import { useQuery } from "react-query";

import type { IUseFetchGetDatasheetById } from "./interface";

import { fetchGetDatasheetById } from "~api/admin/datasheets/{id}/get";

export const useFetchGetDatasheetByIdKey = "api/admin/datasheets/{id}/get";

export function useFetchGetDatasheetById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDatasheetById) {
  return useQuery(
    [useFetchGetDatasheetByIdKey, dependencyArray],
    async () => await fetchGetDatasheetById(payload),
    options,
  );
}
