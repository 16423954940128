import { useQuery } from "react-query";

import type { IUseFetchPostToGetPaginatedElectricUtilityProfiles } from "./interface";

import { fetchPostToGetPaginatedElectricUtilityProfiles } from "~api/electric-utility-profile/filters/post";

export const useFetchPostToGetPaginatedElectricUtilityProfilesKey =
  "api/electric-utility-profile/filters/post";

export function useFetchPostToGetPaginatedElectricUtilityProfiles({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
    electricUtilityName: "",
  },
}: IUseFetchPostToGetPaginatedElectricUtilityProfiles) {
  return useQuery(
    [useFetchPostToGetPaginatedElectricUtilityProfilesKey, dependencyArray],
    async () => await fetchPostToGetPaginatedElectricUtilityProfiles(payload),
    options,
  );
}
