import { useQuery } from "react-query";

import type { IUseFetchGetMonthlyRadiationByCustomId } from "./interface";

import { fetchGetMonthlyRadiationByCustomId } from "~api/projects/step_two/monthly-solar-radiation/{id}/get";

export const fetchGetMonthlyRadiationByCustomIdKey =
  "api/projects/step_two/monthly-solar-radiation/{id}/get";

export function useFetchGetMonthlyRadiationByCustomId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetMonthlyRadiationByCustomId) {
  return useQuery(
    [fetchGetMonthlyRadiationByCustomIdKey, dependencyArray],
    async () => await fetchGetMonthlyRadiationByCustomId(payload),
    options,
  );
}
