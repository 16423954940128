import { z } from "zod";

export const CustomVariableFormSchema = z
  .object({
    value: z.string(),
    description: z.string().optional(),
  })
  .partial();

export interface ICustomVariableFormSchema
  extends z.infer<typeof CustomVariableFormSchema> {}
