import { useMutation } from "react-query";

import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "../../additional-costs/useFetchGetPaginatedComplementaryItemsOfSettings";
import type { IUseFetchUploadComplementaryCostSpreadsheet } from "./interface";

import { fetchUploadComplementaryCostSpreadsheet } from "~api/complementary-cost/import/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUploadComplementaryCostSpreadsheetKey =
  "api/complementary/import/post";

export function useFetchUploadComplementaryCostSpreadsheet({
  options,
}: IUseFetchUploadComplementaryCostSpreadsheet) {
  const { message } = useAppConfig();

  return useMutation(
    async (body) => {
      return await fetchUploadComplementaryCostSpreadsheet(body);
    },
    {
      ...options,
      mutationKey: useFetchUploadComplementaryCostSpreadsheetKey,
      onSuccess: (data, variables, context) => {
        message.success("Planilha foi enviada para importação");
        queryClient.invalidateQueries(
          useFetchGetPaginatedComplementaryItemsOfSettingsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao enviar planilha: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
