import { PipelineStageFormSchema } from "./PipelineStageFormSchema";

import { z } from "zod";

export const PipelineFormSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  stages: PipelineStageFormSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IPipelineFormSchema
  extends z.infer<typeof PipelineFormSchema> {}
