import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedAdminInverters } from "./interface";

import { fetchGetPaginatedAdminInverters } from "~api/admin/inverters/get";

export const useFetchGetPaginatedAdminInvertersKey = "api/admin/inverters/get";

export function useFetchGetPaginatedAdminInverters({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedAdminInverters) {
  return useQuery(
    [useFetchGetPaginatedAdminInvertersKey, dependencyArray],
    async () => await fetchGetPaginatedAdminInverters(payload),
    options,
  );
}
