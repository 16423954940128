import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetProfileImageKey } from "../useFetchGetProfileImage";
import type { IUseFetchUploadProfileImage } from "./interface";

import { fetchUploadProfileImage } from "~api/users/avatar/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUploadProfileImageKey = "api/users/avatar/post";

export function useFetchUploadProfileImage({
  options,
}: IUseFetchUploadProfileImage) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUploadProfileImage(payload);
    },
    {
      ...options,
      mutationKey: useFetchUploadProfileImageKey,
      onSuccess: (data, variables, context) => {
        message.success("Nova imagem de perfil foi salva!");
        queryClient.invalidateQueries(useFetchGetProfileImageKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha fazer upload do arquivo: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
