import { RevisionDealResponseSchema } from "./RevisionDealResponseSchema";

import {
  PipedriveDealStatusEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
} from "~types/enum";
import { z } from "zod";

export const RevisionResponseSchema = z.object({
  projectId: z
    .number({ message: "O id do projeto é obrigatório" })
    .int({ message: "O id do projeto deve ser um tipo inteiro" })
    .gt(0, { message: "O id do projeto deve ser maior que zero" }),
  projectCustomId: z
    .number({ message: "O id customizado do projeto é obrigatório" })
    .int({ message: "O id customizado do projeto deve ser um tipo inteiro" })
    .gt(0, { message: "O id customizado do projeto deve ser maior que zero" }),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  revision: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  pipedriveDealId: z
    .number()
    .int()
    .gte(0) // yes... can return zero
    .nullish()
    .transform((val) => val ?? 0),
  pricingTypeId: z
    .number({ message: "O id do tipo de precificação é obrigatório" })
    .int({ message: "O id do tipo de precificação deve ser um tipo inteiro" })
    .gt(0, { message: "O id do tipo de precificação deve ser maior que zero" }),
  businessUnitId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  lastStep: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  express: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  pipedriveDealStatus: PipedriveDealStatusEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  showPaymentStep: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  showIntermediateStep: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  revisionDealResponse: RevisionDealResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IRevisionResponseSchema
  extends z.infer<typeof RevisionResponseSchema> {}
