import { api, apiException } from "~services/api";
import type { TransformerAdditionalCostDto } from "~types/api/TransformerAdditionalCostDto";
import {
  FetchCreateTransformerRulePayloadSchema,
  IFetchCreateTransformerRulePayloadSchema,
  TransformerAdditionalCostDtoSchema,
} from "~types/schemas";

export const fetchCreateTransformerRule = async (
  payload: IFetchCreateTransformerRulePayloadSchema,
) => {
  try {
    const body = FetchCreateTransformerRulePayloadSchema.parse(payload);

    const response = await api.post<TransformerAdditionalCostDto>(
      `/config/transformer`,
      body,
    );

    const parsedData = TransformerAdditionalCostDtoSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
