import { PaginationSchema } from "./PaginationSchema";
import { TransformerAdditionalCostListItemDTOSchema } from "./TransformerAdditionalCostListItemDTOSchema";

import { z } from "zod";

export const PaginatedTransformerAdditionalCostListItemDTOSchema =
  PaginationSchema(TransformerAdditionalCostListItemDTOSchema);

export interface IPaginatedTransformerAdditionalCostListItemDTOSchema
  extends z.infer<typeof PaginatedTransformerAdditionalCostListItemDTOSchema> {}
