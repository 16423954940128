import { PipelineSchema } from "./PipelineSchema";

import { z } from "zod";

export const ResponsePipelineSchema = z
  .object({
    data: z.array(PipelineSchema),
  })
  .partial();

export interface IResponsePipelineSchema
  extends z.infer<typeof ResponsePipelineSchema> {}
