import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesByDealId } from "./interface";

import { fetchGetHistoriesByDealId } from "~api/histories/deal/{id}/get";

export const useFetchGetHistoriesByDealIdKey = "api/histories/deal/{id}/get";

export function useFetchGetHistoriesByDealId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesByDealId) {
  return useQuery(
    [useFetchGetHistoriesByDealIdKey, dependencyArray],
    async () => await fetchGetHistoriesByDealId(payload),
    options,
  );
}
