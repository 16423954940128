import { useQuery } from "react-query";

import type { IUseFetchPostToGetEstimatedPowerPlant } from "./interface";

import { fetchPostToGetEstimatedPowerPlant } from "~api/projects/step_two/expected-power/post";

export const useFetchPostToGetEstimatedPowerPlantKey =
  "api/projects/step_two/expected-power/post";

export function useFetchPostToGetEstimatedPowerPlant({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchPostToGetEstimatedPowerPlant) {
  return useQuery(
    [useFetchPostToGetEstimatedPowerPlantKey, dependencyArray],
    async () => await fetchPostToGetEstimatedPowerPlant(payload),
    options,
  );
}
