import { api, apiException } from "~services/api";
import {
  FormFieldDTOSchema,
  FetchUpdateDefaultFieldPayloadSchema,
  IFetchUpdateDefaultFieldPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalDefaultField = async (
  payload: IFetchUpdateDefaultFieldPayloadSchema,
) => {
  try {
    const { id, ...body } = FetchUpdateDefaultFieldPayloadSchema.parse(payload);

    const response = await api.put(`/fields/settings/${id}`, body);

    const parsedData = FormFieldDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
