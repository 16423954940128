import { useMutation } from "react-query";

import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "../useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "../useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetRemainingPercentageKey } from "../useFetchGetRemainingPercentage";
import type { IUseFetchDeleteComplementaryItemsOfSettings } from "./interface";

import { fetchDeleteComplementaryItemsOfSettings } from "~api/additional-costs/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteComplementaryItemsOfSettingsKey =
  "api/additional-costs/{id}/delete";

export function useFetchDeleteComplementaryItemsOfSettings(
  options?: IUseFetchDeleteComplementaryItemsOfSettings,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      const response = await fetchDeleteComplementaryItemsOfSettings(payload);
      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchDeleteComplementaryItemsOfSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Item complementar foi removido");
        queryClient.invalidateQueries(
          useFetchGetPaginatedComplementaryItemsOfSettingsKey,
        );
        queryClient.invalidateQueries(
          useFetchGetPaginatedAdditionalCostsAuxVariableKey,
        );
        queryClient.invalidateQueries(useFetchGetRemainingPercentageKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
