import { useMutation } from "react-query";

import { useFetchGetAllPricingTypesKey } from "../useFetchGetAllPricingTypes";
import type { IUseFetchCreatePricingTypes } from "./interface";

import { fetchCreatePricingTypes } from "~api/pricing-types/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePricingTypesKey = "api/pricing-types/post";

export function useFetchCreatePricingTypes({
  options,
}: IUseFetchCreatePricingTypes) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchCreatePricingTypes(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreatePricingTypesKey,
      onSuccess: (data, variables, context) => {
        message.success("Precificação foi cadastrado!");
        queryClient.invalidateQueries(useFetchGetAllPricingTypesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao cadastrar precificação: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
