import { api, apiException } from "~services/api";
import {
  FetchGetEdeltecStructureByStructureIdPayloadSchema,
  type IFetchGetEdeltecStructureByStructureIdPayloadSchema,
  EdeltecStructureDTOSchema,
} from "~types/schemas";

export const fetchGetEdeltecStructureByStructureId = async (
  payload: IFetchGetEdeltecStructureByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetEdeltecStructureByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/equipments/edeltec/structure-by-relationship/${structureId}`,
    );

    // when not return result the backend returns a empty string in response.data
    const parsedData = EdeltecStructureDTOSchema.parse(response.data || {});

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
