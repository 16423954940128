import { FileDTOSchema } from "./FileDTOSchema";

import { z } from "zod";

export const NoteDTOSchema = z.object({
  id: z.number().int().gt(0),
  text: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  textComponent: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  attachments: FileDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface INoteDTOSchema extends z.infer<typeof NoteDTOSchema> {}
