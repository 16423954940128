import { PermissionsEnum } from "~types/enum";
import { z } from "zod";

export const ProfileSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  permissions: PermissionsEnum.array().default([]),
  active: z.boolean(),
  userGroupId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  users: z
    .array(
      z.object({
        id: z.number().int().gt(0),
        name: z
          .string()
          .nullish()
          .transform((val) => val ?? ""),
        email: z
          .string()
          .nullish()
          .transform((val) => val ?? ""),
      }),
    )
    .nullish()
    .transform((val) => val ?? []),
});

export interface IProfileSchema extends z.infer<typeof ProfileSchema> {}
