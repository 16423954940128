import { useMutation } from "react-query";

import { useFetchGetPaginatedValidationRulesKey } from "../useFetchGetPaginatedValidationRules";
import type { IUseFetchUpdateValidationRule } from "./interface";

import { fetchUpdateValidationRule } from "~api/rule-expression/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateValidationRuleKey = "api/rule-expression/{id}/put";

export function useFetchUpdateValidationRule({
  options,
}: IUseFetchUpdateValidationRule) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateValidationRule(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateValidationRuleKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra foi alterada");
        queryClient.invalidateQueries(useFetchGetPaginatedValidationRulesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
