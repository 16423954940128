import { CalculationTypeEnum, ScaleEnum } from "~types/enum";
import { z } from "zod";

export const ProjectDiscountFormSchema = z
  .object({
    value: z.number(),
    discountType: CalculationTypeEnum,
    baseCalculationType: ScaleEnum,
  })
  .partial();

export interface IProjectDiscountFormSchema
  extends z.infer<typeof ProjectDiscountFormSchema> {}
