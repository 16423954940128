import {
  VoltageEnum,
  IrradiationTypeEnum,
  StructureGroupEnum,
  DealBaseCalculationTypeEnum,
} from "~types/enum";
import { z } from "zod";

export const DefaultValueFormSchema = z
  .object({
    performanceRate: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    fdi: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
    irradiationType: IrradiationTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    ignoreElectricUtilityRules: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    accumulatedCreditsUsedInPayback: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    considerConsumptionUnits: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    considerDispCostInPlantPower: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    ignoreGenericManufactureList: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    structureGroup: StructureGroupEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    minCelsiusTemperature: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    showPaymentStep: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    customIdProposalFormat: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    microAndStringInverterMixAllowedInSolarKit: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    differentPhaseInverterMixAllowedInSolarKit: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    dealBaseCalculationType: DealBaseCalculationTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  })
  .partial();

export interface IDefaultValueFormSchema
  extends z.infer<typeof DefaultValueFormSchema> {}
