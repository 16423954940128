import { z } from "zod";

export const ProposalSearchFiltersSchema = z
  .object({
    page: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined),
    size: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined),
    id: z
      .number()
      .int()
      .gt(0)
      .nullish()
      .transform((val) => val ?? undefined),
    clientName: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    fullPower: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    valueForCustomer: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    cityName: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
  })
  .partial();

export interface IProposalSearchFiltersSchema
  extends z.infer<typeof ProposalSearchFiltersSchema> {}
