import { useQuery } from "react-query";

import type { IUseFetchGetActivitiesCountByPersonId } from "./interface";

import { fetchGetActivitiesCountByPersonId } from "~api/activities/board/person/{id}/get";

export const useFetchGetActivitiesCountByPersonIdKey =
  "api/activities/board/person/{id}/get";

export function useFetchGetActivitiesCountByPersonId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetActivitiesCountByPersonId) {
  return useQuery(
    [useFetchGetActivitiesCountByPersonIdKey, dependencyArray],
    async () => await fetchGetActivitiesCountByPersonId(payload),
    options,
  );
}
