import { useMutation } from "react-query";

import { useFetchGetPaginatedAlternatingCurrentMaterialsKey } from "../useFetchGetPaginatedAlternatingCurrentMaterials";
import type { IUseFetchCreateAlternatingCurrentMaterial } from "./interface";

import { fetchCreateAlternatingCurrentMaterial } from "~api/ac-materials/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateAlternatingCurrentMaterialKey =
  "api/ac-materials/post";

export function useFetchCreateAlternatingCurrentMaterial({
  options,
}: IUseFetchCreateAlternatingCurrentMaterial) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchCreateAlternatingCurrentMaterial(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateAlternatingCurrentMaterialKey,
      onSuccess: (data, variables, context) => {
        message.success("Material criado com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedAlternatingCurrentMaterialsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar material: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
