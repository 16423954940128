import { api, apiException } from "~services/api";
import {
  FetchUpdateOrganizationPayloadSchema,
  IFetchUpdateOrganizationPayloadSchema,
  OrganizationDTOSchema,
} from "~types/schemas";

export const fetchUpdateOrganization = async (
  payload: IFetchUpdateOrganizationPayloadSchema,
) => {
  try {
    const { organizationId, ...body } =
      FetchUpdateOrganizationPayloadSchema.parse(payload);

    const response = await api.put(`/organizations/${organizationId}`, body);

    const parsedData = OrganizationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
