import { useQuery } from "react-query";

import type { IUseFetchGetEcoriStructureByStructureId } from "./interface";

import { fetchGetEcoriStructureByStructureId } from "~api/equipments/ecori/structure-by-relationship/{structureId}/get";

export const useFetchGetEcoriStructureByStructureIdKey =
  "api/equipments/ecori/structure-by-relationship/{structureId}/get";

export function useFetchGetEcoriStructureByStructureId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetEcoriStructureByStructureId) {
  return useQuery(
    [useFetchGetEcoriStructureByStructureIdKey, dependencyArray],
    async () => await fetchGetEcoriStructureByStructureId(payload),
    options,
  );
}
