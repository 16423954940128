import { CompanyPipedriveMessageFormSchema } from "./CompanyPipedriveMessageFormSchema";

import { z } from "zod";

export const FetchUpdateActivityMessageFromPipedrivePayloadSchema =
  CompanyPipedriveMessageFormSchema;

export interface IFetchUpdateActivityMessageFromPipedrivePayloadSchema
  extends z.infer<
    typeof FetchUpdateActivityMessageFromPipedrivePayloadSchema
  > {}
