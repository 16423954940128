import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedDiverseKitsPayloadSchema,
  IFetchGetPaginatedDiverseKitsPayloadSchema,
  PaginatedCustomDiverseKitDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedDiverseKits = async (
  payload: IFetchGetPaginatedDiverseKitsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedDiverseKitsPayloadSchema.parse(payload);

    const response = await api.get(`/product/other/kit`, {
      params,
    });

    const parsedData = PaginatedCustomDiverseKitDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
