import { useQuery } from "react-query";

import type { IUseFetchGetProposalFields } from "./interface";

import { fetchGetProposalFields } from "~api/fields/get";

export const useFetchGetProposalFieldsKey = "api/fields/get";

export function useFetchGetProposalFields({
  dependencyArray = [],
  options,
}: IUseFetchGetProposalFields) {
  return useQuery(
    [useFetchGetProposalFieldsKey, dependencyArray],
    async () => await fetchGetProposalFields(),
    options,
  );
}
