import { PaginationSchema } from "./PaginationSchema";
import { PerformanceProfileDTOSchema } from "./PerformanceProfileDTOSchema";

import { z } from "zod";

export const PaginatedPerformanceProfileDTOSchema = PaginationSchema(
  PerformanceProfileDTOSchema,
);

export interface IPaginatedPerformanceProfileDTOSchema
  extends z.infer<typeof PaginatedPerformanceProfileDTOSchema> {}
