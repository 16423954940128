import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalStepTwoPayloadSchema,
  IFetchUpdateProposalStepTwoPayloadSchema,
  StepTwoDTOSchema,
} from "~types/schemas";

export const fetchUpdateProposalStepTwo = async (
  payload: IFetchUpdateProposalStepTwoPayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateProposalStepTwoPayloadSchema.parse(payload);

    const response = await api.put(
      `/projects/step_two/custom-id/${customId}`,
      body,
    );

    const parsedData = StepTwoDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
