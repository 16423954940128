import { z } from "zod";

export const PipelineStageFormSchema = z.object({
  name: z.string(),
  position: z.number().int(),
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  probability: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  rottingInDays: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPipelineStageFormSchema
  extends z.infer<typeof PipelineStageFormSchema> {}
