import { useQuery } from "react-query";

import type { IUseFetchGetPersonsFromOrganization } from "./interface";

import { fetchGetPersonsFromOrganization } from "~api/organizations/{id}/people/get";

export const useFetchGetPersonsFromOrganizationKey =
  "api/organizations/{id}/people/get";

export function useFetchGetPersonsFromOrganization({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPersonsFromOrganization) {
  return useQuery(
    [useFetchGetPersonsFromOrganizationKey, dependencyArray],
    async () => await fetchGetPersonsFromOrganization(payload),
    options,
  );
}
