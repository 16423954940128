import { AdditionalCostMarginTypeDTOSchema } from "./AdditionalCostMarginTypeDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedAdditionalCostMarginTypeDTOSchema = PaginationSchema(
  AdditionalCostMarginTypeDTOSchema,
);

export interface IPaginatedAdditionalCostMarginTypeDTOSchema
  extends z.infer<typeof PaginatedAdditionalCostMarginTypeDTOSchema> {}
