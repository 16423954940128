import { api, apiException } from "~services/api";
import {
  FetchUpdateReasonForLossPayloadSchema,
  ReasonForLossResponseSchema,
  type IFetchUpdateReasonForLossPayloadSchema,
} from "~types/schemas";

export const fetchUpdateReasonForLoss = async (
  payload: IFetchUpdateReasonForLossPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateReasonForLossPayloadSchema.parse(payload);

    const response = await api.put(`/reason-for-loss/${id}`, body);

    const parsedData = ReasonForLossResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
