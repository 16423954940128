import { useQuery } from "react-query";

import type { IUseFetchGetConcessionaireProfilesByVoltage } from "./interface";

import { fetchGetConcessionaireProfilesByVoltage } from "~api/electric-utility-profile/electric-utility/voltage-type/{id}/get";

export const useFetchGetConcessionaireProfilesByVoltageKey =
  "api/electric-utility-profile/electric-utility/voltage-type/{id}/get";

export function useFetchGetConcessionaireProfilesByVoltage({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetConcessionaireProfilesByVoltage) {
  return useQuery(
    [useFetchGetConcessionaireProfilesByVoltageKey, dependencyArray],
    async () => await fetchGetConcessionaireProfilesByVoltage(payload),
    options,
  );
}
