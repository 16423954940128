import { useMutation } from "react-query";

import { useFetchGetPaginatedSysAdminUserKey } from "../useFetchGetPaginatedSysAdminUser";
import type { IUseFetchPatchSysAdminUser } from "./interface";

import { fetchPatchSysAdminManagement } from "~api/sys-admin-management/disable/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchSysAdminUserKey =
  "api/sys-admin-management/disable/{id}/patch";

export function useFetchPatchSysAdminUser({
  options,
}: IUseFetchPatchSysAdminUser) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchPatchSysAdminManagement(payload);
    },
    {
      ...options,
      mutationKey: useFetchPatchSysAdminUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Administrador foi desativado!");
        queryClient.invalidateQueries(useFetchGetPaginatedSysAdminUserKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao desativado administrador: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
