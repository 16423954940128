import { ConversionRateInsightDataSchema } from "./ConversionRateInsightDataSchema";

import { z } from "zod";

export const ConversionRateInsightDtoSchema = z.object({
  data: ConversionRateInsightDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IConversionRateInsightDtoSchema
  extends z.infer<typeof ConversionRateInsightDtoSchema> {}
