import { api, apiException } from "~services/api";
import {
  ActivityBoardDTOSchema,
  FetchGetActivityBoardPayloadSchema,
  IFetchGetActivityBoardPayloadSchema,
} from "~types/schemas";

export const fetchGetActivityBoard = async (
  payload?: IFetchGetActivityBoardPayloadSchema,
) => {
  try {
    const params = FetchGetActivityBoardPayloadSchema.parse(payload);

    const response = await api.get(`/activities/board`, {
      params,
    });

    const parsedData = ActivityBoardDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
