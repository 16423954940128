import { z } from "zod";

export const EncryptedPersonalTokenResponseSchema = z.object({
  token: z.string({
    message: 'Era esperado que o campo "token" fosse uma string',
  }),
});

export interface IEncryptedPersonalTokenResponseSchema
  extends z.infer<typeof EncryptedPersonalTokenResponseSchema> {}
