import { useMutation } from "react-query";

import { useFetchGetDatasheetByIdKey } from "../useFetchGetDatasheetById";
import { useFetchGetDatasheetLastRevisionKey } from "../useFetchGetDatasheetLastRevision";
import { useFetchGetDatasheetRevisionByIdKey } from "../useFetchGetDatasheetRevisionById";
import { useFetchGetPaginatedDatasheetKey } from "../useFetchGetPaginatedDatasheet";
import { useFetchGetPaginatedDatasheetRevisionsKey } from "../useFetchGetPaginatedDatasheetRevisions";
import { useFetchGetPdfBase64FromDatasheetRevisionByIdKey } from "../useFetchGetPdfBase64FromDatasheetRevisionById";
import { useFetchGetSearchPaginatedDatasheetKey } from "../useFetchGetSearchPaginatedSearchDatasheet";
import type { IUseFetchDeleteDatasheet } from "./interface";

import { fetchDeleteDatasheet } from "~api/admin/datasheets/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteDatasheetKey = "api/admin/datasheets/{id}/delete";

export function useFetchDeleteDatasheet({ options }: IUseFetchDeleteDatasheet) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeleteDatasheet(payload), {
    ...options,
    mutationKey: useFetchDeleteDatasheetKey,
    onSuccess: (data, variables, context) => {
      message.success("Datasheet foi apagado");

      queryClient.invalidateQueries(useFetchGetDatasheetByIdKey);
      queryClient.invalidateQueries(useFetchGetPaginatedDatasheetKey);
      queryClient.invalidateQueries(useFetchGetSearchPaginatedDatasheetKey);
      queryClient.invalidateQueries(useFetchGetDatasheetRevisionByIdKey);
      queryClient.invalidateQueries(useFetchGetDatasheetLastRevisionKey);
      queryClient.invalidateQueries(
        useFetchGetPdfBase64FromDatasheetRevisionByIdKey,
      );
      queryClient.invalidateQueries(useFetchGetPaginatedDatasheetRevisionsKey);

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao apagar Datasheet: ${error.message}`);

      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
