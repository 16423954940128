import { api, apiException } from "~services/api";
import {
  ActivityDTOSchema,
  FetchUpdateActivityPayloadSchema,
  type IFetchUpdateActivityPayloadSchema,
} from "~types/schemas";

export const fetchUpdateActivity = async (
  payload: IFetchUpdateActivityPayloadSchema,
) => {
  try {
    const { id, ...body } = FetchUpdateActivityPayloadSchema.parse(payload);

    const response = await api.put(`/activities/${id}`, body);

    const parsedData = ActivityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
