import { DatasheetUpdatedRequestDTOSchema } from "./DatasheetUpdatedRequestDTOSchema";

import { z } from "zod";

export const FetchUpdateDatasheetPayloadSchema =
  DatasheetUpdatedRequestDTOSchema.extend({
    id: z
      .number({ message: "ID do Datasheet é obrigatório." })
      .int({ message: "ID do Datasheet deve ser um inteiro." })
      .gt(0, { message: "ID deve ser maior que zero." }),
  });

export interface IFetchUpdateDatasheetPayloadSchema
  extends z.infer<typeof FetchUpdateDatasheetPayloadSchema> {}
