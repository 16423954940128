import { api, apiException } from "~services/api";
import {
  type IFetchDeleteDatasheetPayloadSchema,
  FetchDeleteDatasheetPayloadSchema,
} from "~types/schemas";

export const fetchDeleteDatasheet = async (
  payload: IFetchDeleteDatasheetPayloadSchema,
) => {
  try {
    const { id } = FetchDeleteDatasheetPayloadSchema.parse(payload);

    await api.delete(`/admin/datasheets/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
