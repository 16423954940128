import { useQuery } from "react-query";

import { IUseFetchGetCompanyLogo } from "./interface";

import { fetchGetCompanyLogo } from "~api/company/logo/get";

export const useFetchGetCompanyLogoKey = "api/company/logo/get";

export function useFetchGetCompanyLogo({
  dependencyArray = [],
  options,
}: IUseFetchGetCompanyLogo) {
  return useQuery(
    [useFetchGetCompanyLogoKey, dependencyArray],
    async () => {
      return await fetchGetCompanyLogo();
    },
    options,
  );
}
