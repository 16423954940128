import { useMutation } from "react-query";

import type { IUseFetchImpersonateConsultantInCompanyById } from "./interface";

import { fetchImpersonateConsultantInCompanyById } from "~api/consultants/companies/{companyId}/impersonate/post";

export const useFetchImpersonateConsultantInCompanyByIdKey =
  "api/consultants/companies/{companyId}/impersonate/post";

export function useFetchImpersonateConsultantInCompanyById({
  options,
}: IUseFetchImpersonateConsultantInCompanyById) {
  return useMutation(
    async (payload) => {
      return await fetchImpersonateConsultantInCompanyById(payload);
    },
    {
      ...options,
      mutationKey: useFetchImpersonateConsultantInCompanyByIdKey,
    },
  );
}
