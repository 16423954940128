import { z } from "zod";

export const LeadTimeInsightGroupedByUserDataSchema = z.object({
  userId: z
    .number({ message: "Faltando o ID do usuário" })
    .int({ message: "ID do usuário deve ser um número inteiro" }),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  deals: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  averageDays: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  averageHours: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ILeadTimeInsightGroupedByUserDataSchema
  extends z.infer<typeof LeadTimeInsightGroupedByUserDataSchema> {}
