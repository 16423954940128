import { z } from "zod";

export const PersonalApiTokenDTOSchema = z.object({
  token: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IPersonalApiTokenDTOSchema
  extends z.infer<typeof PersonalApiTokenDTOSchema> {}
