import { api, apiException } from "~services/api";
import {
  type IFetchUpdateDealStatusByCustomIdPayloadSchema,
  FetchUpdateDealStatusByCustomIdPayloadSchema,
  DealDTOSchema,
} from "~types/schemas";

export const fetchUpdateDealStatusByCustomId = async (
  payload: IFetchUpdateDealStatusByCustomIdPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateDealStatusByCustomIdPayloadSchema.parse(payload);

    const response = await api.patch(`/deal/${id}/status`, body);
    const parsedData = DealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
