import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedPersonPayloadSchema,
  type IFetchGetPaginatedPersonPayloadSchema,
  PaginatedPersonDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedPerson = async (
  payload: IFetchGetPaginatedPersonPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedPersonPayloadSchema.parse(payload);

    const response = await api.get(`/persons/pageable`, {
      params,
    });

    const parsedData = PaginatedPersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
