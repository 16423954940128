import { useQuery } from "react-query";

import type { IUseFetchGetUsersAssignedWithProfile } from "./interface";

import { fetchGetUsersAssignedWithProfile } from "~api/company/users/profile/{profileId}/get";

export const useFetchGetUsersAssignedWithProfileKey =
  "api/company/users/profile/{profileId}/get";

export function useFetchGetUsersAssignedWithProfile({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetUsersAssignedWithProfile) {
  return useQuery(
    [useFetchGetUsersAssignedWithProfileKey, dependencyArray],
    async () => await fetchGetUsersAssignedWithProfile(payload),
    options,
  );
}
