import { ClientTypeEnum } from "~types/enum";
import { z } from "zod";

export const ClientSearchPipedriveDTOSchema = z.object({
  pipedriveId: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  clientType: ClientTypeEnum,
  address: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IClientSearchPipedriveDTOSchema
  extends z.infer<typeof ClientSearchPipedriveDTOSchema> {}
