import { AlternatingCurrentMaterialDTOSchema } from "./AlternatingCurrentMaterialDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedAlternatingCurrentMaterialDTOSchema = PaginationSchema(
  AlternatingCurrentMaterialDTOSchema,
);

export interface IPaginatedAlternatingCurrentMaterialDTOSchema
  extends z.infer<typeof PaginatedAlternatingCurrentMaterialDTOSchema> {}
