import { useMutation } from "react-query";

import { useFetchGetAllReasonForLossKey } from "../useFetchGetAllReasonForLoss";
import { useFetchGetPaginatedReasonForLossKey } from "../useFetchGetPaginatedReasonForLoss";
import type { IUseFetchDeleteReasonForLoss } from "./interface";

import { fetchDeleteReasonForLoss } from "~api/reason-for-loss/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteReasonForLossKey = "api/reason-for-loss/{id}/delete";

export function useFetchDeleteReasonForLoss({
  options,
}: IUseFetchDeleteReasonForLoss) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteReasonForLoss(payload),
    {
      ...options,
      mutationKey: useFetchDeleteReasonForLossKey,
      onSuccess: (data, variables, context) => {
        message.success("Motivo de perda foi apagado");
        queryClient.invalidateQueries(useFetchGetPaginatedReasonForLossKey);
        queryClient.invalidateQueries(useFetchGetAllReasonForLossKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao apagar motivo de perda: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
