import { z } from "zod";

export const WegParameterFormSchema = z.object({
  discountRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  freightRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  autoSizingCAProtector: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  autoSizingWiring: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  autoSizingConnector: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  includeInverterProtection: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  defaultRegionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  includeOutOfStock: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IWegParameterFormSchema
  extends z.infer<typeof WegParameterFormSchema> {}
