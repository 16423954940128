import { useMutation } from "react-query";

import { fetchGetMonthlyPerformanceRateByCustomIdKey } from "../useFetchGetMonthlyPerformanceRateByCustomId";
import type { IUseFetchUpdateMonthlyPerformanceRateByCustomId } from "./interface";

import { fetchUpdateMonthlyPerformanceRateByCustomId } from "~api/projects/step_two/monthly-performance-rate/{customId}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateMonthlyPerformanceRateByCustomIdKey =
  "api/projects/step_two/monthly-performance-rate/{customId}/put";

export function useFetchUpdateMonthlyPerformanceRateByCustomId({
  options,
}: IUseFetchUpdateMonthlyPerformanceRateByCustomId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateMonthlyPerformanceRateByCustomId(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateMonthlyPerformanceRateByCustomIdKey,
      onSuccess: (data, variables, context) => {
        // message.success("Performance foi definida!");
        queryClient.invalidateQueries(
          fetchGetMonthlyPerformanceRateByCustomIdKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao alterar Performance: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
