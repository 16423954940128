import { AlternatingCurrentMaterialFormSchema } from "./AlternatingCurrentMaterialFormSchema";

import { z } from "zod";

export const FetchUpdateAlternatingCurrentMaterialPayloadSchema =
  AlternatingCurrentMaterialFormSchema.extend({
    acMaterialId: z.number().int().gt(0),
  });

export interface IFetchUpdateAlternatingCurrentMaterialPayloadSchema
  extends z.infer<typeof FetchUpdateAlternatingCurrentMaterialPayloadSchema> {}
