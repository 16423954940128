import { CustomFieldValueSchema } from "./CustomFieldValueSchema";

import { z } from "zod";

export const CustomFieldValuesDTOSchema = z.object({
  intermediateDataCustomFieldValues: z.array(CustomFieldValueSchema).nullish(),
});

export interface ICustomFieldValuesDTOSchema
  extends z.infer<typeof CustomFieldValuesDTOSchema> {}
