import { z } from "zod";

export const FetchGetPaginatedUsersByCompanyPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .lte(100)
    .nullish()
    .transform((val) => val ?? 5),
  userId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedUsersByCompanyPayloadSchema
  extends z.infer<typeof FetchGetPaginatedUsersByCompanyPayloadSchema> {}
