import { useQuery } from "react-query";

import type { IUseFetchGetEdeltecStructures } from "./interface";

import { fetchGetEdeltecStructures } from "~api/equipments/edeltec/structures/get";

export const useFetchGetEdeltecStructuresKey =
  "api/equipments/edeltec/structures/get";

export function useFetchGetEdeltecStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetEdeltecStructures) {
  return useQuery(
    [useFetchGetEdeltecStructuresKey, dependencyArray],
    async () => await fetchGetEdeltecStructures(),
    options,
  );
}
