import { api, apiException } from "~services/api";
import {
  FetchGetActivitiesDoneByUserPayloadSchema,
  type IFetchGetActivitiesDoneByUserPayloadSchema,
  ActivitiesDoneByUserInsightDTOSchema,
} from "~types/schemas";

export const fetchGetActivitiesDoneByUser = async (
  payload: IFetchGetActivitiesDoneByUserPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetActivitiesDoneByUserPayloadSchema.parse(payload);

    const response = await api.get("/insights/activitiesDoneByUser", {
      params: queryParams,
    });

    const parsedData = ActivitiesDoneByUserInsightDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
