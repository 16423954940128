import { api, apiException } from "~services/api";
import {
  FetchGetCountOfUsersAccountOwnerPayloadSchema,
  IFetchGetCountOfUsersAccountOwnerPayloadSchema,
  UserCountDTOSchema,
} from "~types/schemas";

export const fetchGetCountOfUsersAccountOwner = async (
  payload: IFetchGetCountOfUsersAccountOwnerPayloadSchema,
) => {
  try {
    const params = FetchGetCountOfUsersAccountOwnerPayloadSchema.parse(payload);

    const response = await api.get(`/admin/users/count`, {
      params,
    });

    const parsedData = UserCountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
