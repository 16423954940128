import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedConsultantPayloadSchema,
  IFetchGetPaginatedConsultantPayloadSchema,
  PaginatedConsultantUserDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedConsultant = async (
  payload: IFetchGetPaginatedConsultantPayloadSchema,
) => {
  try {
    const query = FetchGetPaginatedConsultantPayloadSchema.parse(payload);

    const response = await api.get(`/consultants/pageable`, {
      params: query,
    });

    const parsedData = PaginatedConsultantUserDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
