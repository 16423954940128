import { z } from "zod";

export const ReportResponseDTOSchema = z.object({
  data: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IReportResponseDTOSchema
  extends z.infer<typeof ReportResponseDTOSchema> {}
