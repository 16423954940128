import { api, apiException } from "~services/api";
import {
  FetchGetPipelineDealsListByIdPayloadSchema,
  type IFetchGetPipelineDealsListByIdPayloadSchema,
  PaginatedDealDTOSchema,
} from "~types/schemas";

export const fetchGetPipelineDealsListById = async (
  payload: IFetchGetPipelineDealsListByIdPayloadSchema,
) => {
  try {
    const { pipelineId, ...queryParams } =
      FetchGetPipelineDealsListByIdPayloadSchema.parse(payload);

    const response = await api.get(`/pipeline/${pipelineId}/deals`, {
      params: queryParams,
    });

    const parsedData = PaginatedDealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
