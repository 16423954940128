import { api, apiException } from "~services/api";
import { CustomVariableDTO } from "~types/api";
import {
  CustomVariableDTOSchema,
  FetchCreateCustomVariablePayloadSchema,
  IFetchCreateCustomVariablePayloadSchema,
} from "~types/schemas";

export const fetchCreateCustomVariable = async (
  payload: IFetchCreateCustomVariablePayloadSchema,
) => {
  try {
    const body = FetchCreateCustomVariablePayloadSchema.parse(payload);

    const response = await api.post<CustomVariableDTO>(
      `/custom-variable`,
      body,
    );

    const parsedData = CustomVariableDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
