import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveApiToken } from "./interface";

import { fetchGetPipedriveApiToken } from "~api/company/pipedrive-api-token/get";

export const useFetchGetPipedriveApiTokenKey =
  "api/company/pipedrive-api-token/get";

export function useFetchGetPipedriveApiToken({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedriveApiToken) {
  return useQuery(
    [useFetchGetPipedriveApiTokenKey, dependencyArray],
    async () => await fetchGetPipedriveApiToken(),
    options,
  );
}
