import { useQuery } from "react-query";

import type { IUseFetchGetAutoCompleteOrganization } from "./interface";

import { fetchGetAutoCompleteOrganization } from "~api/organizations/auto-complete/get";

export const fetchGetAutoCompleteOrganizationKey =
  "api/organizations/auto-complete/get";

export function useFetchGetAutoCompleteOrganization({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetAutoCompleteOrganization) {
  return useQuery(
    [fetchGetAutoCompleteOrganizationKey, dependencyArray],
    async () => await fetchGetAutoCompleteOrganization(payload),
    options,
  );
}
