import { api, apiException } from "~services/api";
import {
  FetchUpdateUserCompanyConfigurationPayloadSchema,
  type IFetchUpdateUserCompanyConfigurationPayloadSchema,
} from "~types/schemas";

export const fetchUpdateUserCompanyConfiguration = async (
  payload: IFetchUpdateUserCompanyConfigurationPayloadSchema,
) => {
  try {
    const { companyId, ...body } =
      FetchUpdateUserCompanyConfigurationPayloadSchema.parse(payload);

    await api.patch<void>(`/admin/users/${companyId}/configurations`, body);
  } catch (error) {
    throw apiException(error);
  }
};
