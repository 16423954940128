import { api, apiException } from "~services/api";
import type { CustomFieldDto } from "~types/api/CustomFieldDto";
import type { UpdateCustomFieldOrderForm } from "~types/api/UpdateCustomFieldOrderForm";

export type FetchUpdateCustomFieldsOrder = {
  body: UpdateCustomFieldOrderForm[];
};

export const fetchUpdateCustomFieldsOrder = async ({
  body,
}: FetchUpdateCustomFieldsOrder) => {
  try {
    return await api.put<CustomFieldDto[]>(
      `/config/customFields/fieldOrder`,
      body,
    );
  } catch (error) {
    throw apiException(error);
  }
};
