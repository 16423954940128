import { api, apiException } from "~services/api";
import {
  UserGroupDTOSchema,
  FetchUpdateUserGroupPayloadSchema,
  IFetchUpdateUserGroupPayloadSchema,
} from "~types/schemas";

export const fetchUpdateUserGroup = async (
  payload: IFetchUpdateUserGroupPayloadSchema,
) => {
  try {
    const { userGroupId, ...body } =
      FetchUpdateUserGroupPayloadSchema.parse(payload);

    const response = await api.put(`/usergroup/${userGroupId}`, body);

    const parsedData = UserGroupDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
