import { EosAddressDTOSchema } from "./EosAddressDTOSchema";
import { EosPersonSchema } from "./EosPersonSchema";

import { z } from "zod";

export const EosFinancingFormSchema = z.object({
  tipoProjeto: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  mediaContaEnergia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maoObra: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteNome: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteCpf: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteTelefone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  valorTotal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  potenciaInstaladaSugerida: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  entrada: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  carencia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tempoFinanciamento: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  kitFotovoltaico: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  clienteDataNascimento: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
  cliente: EosPersonSchema.nullish().transform((val) => val ?? undefined),
  comSeguro: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  autorizacaoScr: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IEosFinancingFormSchema
  extends z.infer<typeof EosFinancingFormSchema> {}
