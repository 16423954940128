import { NotificationStatusEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetPaginatedNotificationsPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 10),
  status: NotificationStatusEnum,
});

export interface IFetchGetPaginatedNotificationsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedNotificationsPayloadSchema> {}
