import { api, apiException } from "~services/api";
import {
  FetchUpdateProjectTypeInUsePayloadSchema,
  IFetchUpdateProjectTypeInUsePayloadSchema,
} from "~types/schemas";

export const fetchUpdateProjectTypeInUse = async (
  payload: IFetchUpdateProjectTypeInUsePayloadSchema,
) => {
  try {
    const { projectType } =
      FetchUpdateProjectTypeInUsePayloadSchema.parse(payload);

    await api.put(`/users/project-type/${projectType}`);
  } catch (error) {
    throw apiException(error);
  }
};
