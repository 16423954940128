import { z } from "zod";

export const FetchGetPaginatedDatasheetPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 10),
  id: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedDatasheetPayloadSchema
  extends z.infer<typeof FetchGetPaginatedDatasheetPayloadSchema> {}
