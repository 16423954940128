import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { z } from "zod";

export const DataMediumVoltageDTOSchema = z.object({
  averageConsumptionP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  averageConsumptionFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  taxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teP: z.number(),
  teFP: z.number(),
  tusdP: z.number(),
  tusdFP: z.number(),
  taxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxP: z.number(),
  wireBTaxFP: z.number(),
  contractedDemandTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxTusdG: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  monthlyValuesP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  monthlyValuesFP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IDataMediumVoltageDTOSchema
  extends z.infer<typeof DataMediumVoltageDTOSchema> {}
