import { useQuery } from "react-query";

import type { IUseFetchGetActivityById } from "./interface";

import { fetchGetActivityById } from "~api/activities/{id}/get";

export const useFetchGetActivityByIdKey = "api/activities/{id}/get";

export function useFetchGetActivityById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetActivityById) {
  return useQuery(
    [useFetchGetActivityByIdKey, dependencyArray],
    async () => await fetchGetActivityById(payload),
    options,
  );
}
