import { api, apiException } from "~services/api";
import {
  type IFetchUploadFileDatasheetPayloadSchema,
  FetchUploadFileDatasheetPayloadSchema,
  DatasheetDTOSchema,
} from "~types/schemas";

export const fetchUploadFileDatasheet = async (
  payload: IFetchUploadFileDatasheetPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUploadFileDatasheetPayloadSchema.parse(payload);

    const response = await api.post(`/admin/datasheets/${id}`, body);

    const parsedData = DatasheetDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
