export const colors = {
  "red.50": "#fff1f0",
  "red.100": "#ffccc7",
  "red.200": "#ffa39e",
  "red.300": "#ff7875",
  "red.400": "#ff4d4f",
  "red.500": "#f5222d",
  "red.600": "#cf1322",
  "red.700": "#a8071a",
  "red.800": "#820014",
  "red.900": "#5c0011",

  "volcano.50": "#fff2e8",
  "volcano.100": "#ffd8bf",
  "volcano.200": "#ffbb96",
  "volcano.300": "#ff9c6e",
  "volcano.400": "#ff7a45",
  "volcano.500": "#fa541c",
  "volcano.600": "#d4380d",
  "volcano.700": "#ad2102",
  "volcano.800": "#871400",
  "volcano.900": "#610b00",

  "orange.50": "#fff7e6",
  "orange.100": "#ffe7ba",
  "orange.200": "#ffd591",
  "orange.300": "#ffc069",
  "orange.400": "#ffa940",
  "orange.500": "#fa8c16",
  "orange.600": "#d46b08",
  "orange.700": "#ad4e00",
  "orange.800": "#873800",
  "orange.900": "#612500",

  "gold.50": "#fffbe6",
  "gold.100": "#fff1b8",
  "gold.200": "#ffe58f",
  "gold.300": "#ffd666",
  "gold.400": "#ffc53d",
  "gold.500": "#faad14",
  "gold.600": "#d48806",
  "gold.700": "#ad6800",
  "gold.800": "#874d00",
  "gold.900": "#613400",

  "yellow.50": "#feffe6",
  "yellow.100": "#ffffb8",
  "yellow.200": "#fffb8f",
  "yellow.300": "#fff566",
  "yellow.400": "#ffec3d",
  "yellow.500": "#fadb14",
  "yellow.600": "#d4b106",
  "yellow.700": "#ad8b00",
  "yellow.800": "#876800",
  "yellow.900": "#614700",

  "lime.50": "#fcffe6",
  "lime.100": "#f4ffb8",
  "lime.200": "#eaff8f",
  "lime.300": "#d3f261",
  "lime.400": "#bae637",
  "lime.500": "#a0d911",
  "lime.600": "#7cb305",
  "lime.700": "#5b8c00",
  "lime.800": "#3f6600",
  "lime.900": "#254000",

  "green.50": "#f6ffed",
  "green.100": "#d9f7be",
  "green.200": "#b7eb8f",
  "green.300": "#95de64",
  "green.400": "#73d13d",
  "green.500": "#52c41a",
  "green.600": "#389e0d",
  "green.700": "#237804",
  "green.800": "#135200",
  "green.900": "#092b00",

  "cyan.50": "#e6fffb",
  "cyan.100": "#b5f5ec",
  "cyan.200": "#87e8de",
  "cyan.300": "#5cdbd3",
  "cyan.400": "#36cfc9",
  "cyan.500": "#13c2c2",
  "cyan.600": "#08979c",
  "cyan.700": "#006d75",
  "cyan.800": "#00474f",
  "cyan.900": "#002329",

  "blue.50": "#e6f7ff",
  "blue.100": "#bae7ff",
  "blue.200": "#91d5ff",
  "blue.300": "#69c0ff",
  "blue.400": "#40a9ff",
  "blue.500": "#1890ff",
  "blue.600": "#096dd9",
  "blue.700": "#0050b3",
  "blue.800": "#003a8c",
  "blue.900": "#002766",

  "geekBlue.50": "#f0f5ff",
  "geekBlue.100": "#d6e4ff",
  "geekBlue.200": "#adc6ff",
  "geekBlue.300": "#85a5ff",
  "geekBlue.400": "#597ef7",
  "geekBlue.500": "#2f54eb",
  "geekBlue.600": "#1d39c4",
  "geekBlue.700": "#10239e",
  "geekBlue.800": "#061178",
  "geekBlue.900": "#030852",

  "purple.50": "#f9f0ff",
  "purple.100": "#efdbff",
  "purple.200": "#d3adf7",
  "purple.300": "#b37feb",
  "purple.400": "#9254de",
  "purple.500": "#722ed1",
  "purple.600": "#531dab",
  "purple.700": "#391085",
  "purple.800": "#22075e",
  "purple.900": "#120338",

  "magenta.50": "#fff0f6",
  "magenta.100": "#ffd6e7",
  "magenta.200": "#ffadd2",
  "magenta.300": "#ff85c0",
  "magenta.400": "#f759ab",
  "magenta.500": "#eb2f96",
  "magenta.600": "#c41d7f",
  "magenta.700": "#9e1068",
  "magenta.800": "#780650",
  "magenta.900": "#520339",

  "primary.50": "#fff2e6",
  "primary.100": "#ffcea3",
  "primary.200": "#ffb47a",
  "primary.300": "#ff9752",
  "primary.400": "#ff7729",
  "primary.500": "#ff5500",
  "primary.600": "#d94100",
  "primary.700": "#b33000",
  "primary.800": "#8c2100",
  "primary.900": "#661400",

  "gray.50": "#F9FAFB",
  "gray.100": "#F3F4F6",
  "gray.200": "#E5E7EB",
  "gray.300": "#D1D5DB",
  "gray.400": "#9CA3AF",
  "gray.500": "#6b7280",
  "gray.600": "#4B5563",
  "gray.700": "#374151",
  "gray.800": "#222933",
  "gray.900": "#111317",
  "gray.999": "#060709",

  white: "#ffffff",
  black: "#000000",

  text: "#000000",

  backgroundLayout: "#f5f5f5",
  backgroundContainer: "#ffffff",
} as const;
