import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedReasonForLossPayloadSchema,
  type IFetchGetPaginatedReasonForLossPayloadSchema,
  PaginatedReasonForLossResponseSchema,
} from "~types/schemas";

export const fetchGetPaginatedReasonForLoss = async (
  payload?: IFetchGetPaginatedReasonForLossPayloadSchema,
) => {
  try {
    const { page, size } =
      FetchGetPaginatedReasonForLossPayloadSchema.parse(payload);

    const response = await api.get(
      `/reason-for-loss/page/${page}/size/${size}`,
    );

    const parsedData = PaginatedReasonForLossResponseSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
