import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedTransformerRulesPayloadSchema,
  IFetchGetPaginatedTransformerRulesPayloadSchema,
  PaginatedTransformerAdditionalCostListItemDTOSchema,
} from "~types/schemas";

export const fetchGetTransformerRules = async (
  payload: IFetchGetPaginatedTransformerRulesPayloadSchema,
) => {
  try {
    const { page, pageSize } =
      FetchGetPaginatedTransformerRulesPayloadSchema.parse(payload);

    const response = await api.get(
      `/config/transformer/page/${page}/pageSize/${pageSize}`,
    );

    const parsedData =
      PaginatedTransformerAdditionalCostListItemDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
