import { api, apiException } from "~services/api";
import {
  FetchGetLeadTimeInsightPayloadSchema,
  type IFetchGetLeadTimeInsightPayloadSchema,
  LeadTimeInsightGroupedByPipelineStageDtoSchema,
} from "~types/schemas";

export const fetchGetLeadTimeInsight = async (
  payload: IFetchGetLeadTimeInsightPayloadSchema,
) => {
  try {
    const queryParams = FetchGetLeadTimeInsightPayloadSchema.parse(payload);

    const response = await api.get("/insights/leadTimeInsight", {
      params: queryParams,
    });

    const parsedData = LeadTimeInsightGroupedByPipelineStageDtoSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
