import { api, apiException } from "~services/api";
import {
  FetchUpdateLogisticsPayloadSchema,
  IFetchUpdateLogisticsPayloadSchema,
  LogisticsDTOSchema,
} from "~types/schemas";

export const fetchUpdateLogistics = async (
  payload: IFetchUpdateLogisticsPayloadSchema,
) => {
  try {
    const { logisticsId, ...body } =
      FetchUpdateLogisticsPayloadSchema.parse(payload);

    const response = await api.put(`/logistics/${logisticsId}`, body);

    const parsedData = LogisticsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
