import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalFieldsPayloadSchema,
  FormFieldDTOSchema,
  IFetchUpdateProposalFieldsPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalFields = async (
  payload: IFetchUpdateProposalFieldsPayloadSchema,
) => {
  try {
    const { fieldId } = FetchUpdateProposalFieldsPayloadSchema.parse(payload);

    const response = await api.put(`/fields/${fieldId}`, undefined);

    const parsedData = FormFieldDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
