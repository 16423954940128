import { api, apiException } from "~services/api";
import { BusinessUnitDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetCompanyUserBusinessUnits = async () => {
  try {
    const response = await api.get(`/business-units/me`);

    const parsedData = z.array(BusinessUnitDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
