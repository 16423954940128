import { CreatorUserIdSchema } from "./CreatorUserIdSchema";
import { PersonDTOSchema } from "./PersonDTOSchema";
import { PersonIdSchema } from "./PersonIdSchema";

import { z } from "zod";

export const DealSchema = z.object({
  id: z.number().int().gt(0),
  clientDTO: PersonDTOSchema.nullish().transform((val) => val ?? undefined),
  creator_user_id: CreatorUserIdSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  person_id: PersonIdSchema.nullish().transform((val) => val ?? undefined),
  org_id: PersonIdSchema.nullish().transform((val) => val ?? undefined),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  expected_close_date: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDealSchema extends z.infer<typeof DealSchema> {}
