import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetPaginatedOrganizationDealsByOrganizationIdKey } from "../../organizations/useFetchGetPaginatedOrganizationDealsByOrganizationId";
import { useFetchGetPaginatedPersonDealsByPersonIdKey } from "../../persons/useFetchGetPaginatedPersonDealsByPersonId";
import { useFetchGetPersonDealsCountByPersonIdKey } from "../../persons/useFetchGetPersonDealsCountByPersonId";
import { useFetchGetDealByCustomIdKey } from "../useFetchGetDealByCustomId";
import { useFetchSearchDealKey } from "../useFetchSearchDeal";
import type { IUseFetchSetDealToNextStep } from "./interface";

import { fetchSetDealToNextStep } from "~api/deal/{customId}/next-step/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetDealToNextStepKey =
  "api/deal/{customId}/next-step/patch";

export function useFetchSetDealToNextStep({
  options,
}: IUseFetchSetDealToNextStep) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchSetDealToNextStep(payload), {
    ...options,
    mutationKey: useFetchSetDealToNextStepKey,
    onSuccess: (data, variables, context) => {
      message.success("Negócio avançou para o próximo passo");
      queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedPersonDealsByPersonIdKey,
      );
      queryClient.invalidateQueries(
        useFetchGetPaginatedOrganizationDealsByOrganizationIdKey,
      );
      queryClient.invalidateQueries(useFetchGetPersonDealsCountByPersonIdKey);
      queryClient.invalidateQueries(useFetchSearchDealKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(
        `Falha ao avançar negócio para o próximo passo: ${error.message}`,
      );
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
