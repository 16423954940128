import { PersonDTOSchema } from "./PersonDTOSchema";

import { ProposalStatusEnum } from "~types/enum";
import { z } from "zod";

export const ProposalItemDetailsSchema = z.object({
  id: z.number().int().gt(0),
  client: PersonDTOSchema.nullish().transform((val) => val ?? undefined),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  dealId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProposalItemDetailsSchema
  extends z.infer<typeof ProposalItemDetailsSchema> {}
