import { z } from "zod";

export const FetchGetPaginatedReasonForLossPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedReasonForLossPayloadSchema
  extends z.infer<typeof FetchGetPaginatedReasonForLossPayloadSchema> {}
