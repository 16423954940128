import { api, apiException } from "~services/api";
import {
  IFetchSetDefaultAdditionalCostTaxSchema,
  FetchSetDefaultAdditionalCostTaxSchema,
  AdditionalCostTaxDTOSchema,
} from "~types/schemas";

export const fetchSetDefaultAdditionalCostTax = async (
  payload: IFetchSetDefaultAdditionalCostTaxSchema,
) => {
  try {
    const params = FetchSetDefaultAdditionalCostTaxSchema.parse(payload);

    const response = await api.post(
      `/config/additionalCostTax/setDefault`,
      null,
      {
        params,
      },
    );

    const parsedData = AdditionalCostTaxDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
