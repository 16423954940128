import { useQuery } from "react-query";

import type { IUseFetchGetElectricUtilityProfileDetail } from "./interface";

import { fetchGetElectricUtilityProfileDetail } from "~api/electric-utility-profile/detail/get";

export const useFetchGetElectricUtilityProfileDetailkey =
  "api/electric-utility-profile/detail/get";

export function useFetchGetElectricUtilityProfileDetail({
  dependencyArray = [],
  options,
}: IUseFetchGetElectricUtilityProfileDetail) {
  return useQuery(
    [useFetchGetElectricUtilityProfileDetailkey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileDetail(),
    options,
  );
}
