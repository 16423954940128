import { useQuery } from "react-query";

import type { IFetchGetPaginatedProductModuleManufacturers } from "./interface";

import { fetchGetPaginatedProductModuleManufacturers } from "~api/product/search/pvModule/manufacturerList/get";

export const useFetchGetPaginatedProductModuleManufacturersKey =
  "api/product/search/pvModule/manufacturerList/get";

export function useFetchGetPaginatedProductModuleManufacturers({
  dependencyArray = [],
  options,
  payload,
}: IFetchGetPaginatedProductModuleManufacturers) {
  return useQuery(
    [useFetchGetPaginatedProductModuleManufacturersKey, dependencyArray],
    async () => await fetchGetPaginatedProductModuleManufacturers(payload),
    options,
  );
}
