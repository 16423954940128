import { api, apiException } from "~services/api";
import {
  type IFetchGetDatasheetByIdPayloadSchema,
  FetchGetDatasheetByIdPayloadSchema,
  DatasheetDTOSchema,
} from "~types/schemas";

export const fetchGetDatasheetById = async (
  payload: IFetchGetDatasheetByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetDatasheetByIdPayloadSchema.parse(payload);

    const response = await api.get(`/admin/datasheets/${id}`);

    const parsedData = DatasheetDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
