import { AddressFormSchema } from "./AddressFormSchema";
import { ClientInputDTOSchema } from "./ClientInputDTOSchema";
import { CustomFieldValueSchema } from "./CustomFieldValueSchema";
import { OrganizationInputDTOSchema } from "./OrganizationInputDTOSchema";
import { UpdateClientFormSchema } from "./UpdateClientFormSchema";

import {
  FieldCategoryEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
} from "~types/enum";
import { z } from "zod";

export const StepOneFormSchema = z.object({
  fieldCategory: FieldCategoryEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  responsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  emailResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phoneResponsible: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  creationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  code: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveDealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  idBaseAddress: z.number().int().gt(0),
  initialDataCustomFieldValues: z
    .array(CustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
  step: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  dealCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  client: UpdateClientFormSchema,
  pipedrivePipelineId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  installationAddress: AddressFormSchema,
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  expirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pricingTypeId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  person: ClientInputDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: OrganizationInputDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IStepOneFormSchema extends z.infer<typeof StepOneFormSchema> {}
