import { useQuery } from "react-query";

import type { IFetchGetElectricUtilityProfileSubgroups } from "./interface";

import { fetchGetElectricUtilityProfileSubgroups } from "~api/electric-utility-profile/subgroups/get";

export const useFetchGetElectricUtilityProfileConsumerSubgroupsKey =
  "api/electric-utility-profile/subgroups/get";

export function useFetchGetElectricUtilityProfileSubgroups({
  dependencyArray = [],
  options,
}: IFetchGetElectricUtilityProfileSubgroups) {
  return useQuery(
    [useFetchGetElectricUtilityProfileConsumerSubgroupsKey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileSubgroups(),
    options,
  );
}
