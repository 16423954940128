import { api, apiException } from "~services/api";
import {
  type IFetchGetOrganizationDealsCountByOrganizationIdPayloadSchema,
  FetchGetOrganizationDealsCountByOrganizationIdPayloadSchema,
  OrganizationDealSectionDTOSchema,
} from "~types/schemas";

export async function fetchGetOrganizationDealsCountByOrganizationId(
  payload: IFetchGetOrganizationDealsCountByOrganizationIdPayloadSchema,
) {
  try {
    const { organizationId } =
      FetchGetOrganizationDealsCountByOrganizationIdPayloadSchema.parse(
        payload,
      );

    const response = await api.get(
      `/organizations/${organizationId}/deals/count`,
    );

    const parsedData = OrganizationDealSectionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
