import { api, apiException } from "~services/api";
import {
  FetchGetAllPermissionsPayloadSchema,
  IFetchGetAllPermissionsPayloadSchema,
  PermissionDTOSchema,
} from "~types/schemas";

export const fetchGetAllPermissions = async (
  payload?: IFetchGetAllPermissionsPayloadSchema,
) => {
  try {
    const queryParams = FetchGetAllPermissionsPayloadSchema.parse(payload);

    const response = await api.get(`/permissions`, {
      params: queryParams,
    });

    const parsedData = PermissionDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
