import { useQuery } from "react-query";

import { IUseFetchGetStructureTypes } from "./interface";

import { fetchGetStructureTypes } from "~api/company/structure_type/get";

export const useFetchGetStructureTypesKey = "api/company/structure_type/get";

export function useFetchGetStructureTypes({
  dependencyArray = [],
  options,
}: IUseFetchGetStructureTypes) {
  return useQuery(
    [useFetchGetStructureTypesKey, dependencyArray],
    async () => await fetchGetStructureTypes(),
    options,
  );
}
