import { LocationServiceEnum } from "~types/enum";
import { z } from "zod";

export const UserConfigurationDTOSchema = z.object({
  locationService: LocationServiceEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  maxUsersLicensed: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  accountOwnerEmail: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUserConfigurationDTOSchema
  extends z.infer<typeof UserConfigurationDTOSchema> {}
