import { useQuery } from "react-query";

import type { IUseFetchGetEcoriStructures } from "./interface";

import { fetchGetAllEcoriEquipmentStructures } from "~api/equipments/ecori/structures/get";

export const useFetchGetEcoriStructuresKey =
  "api/equipments/ecori/structures/get";

export function useFetchGetEcoriStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetEcoriStructures) {
  return useQuery(
    [useFetchGetEcoriStructuresKey, dependencyArray],
    async () => await fetchGetAllEcoriEquipmentStructures(),
    options,
  );
}
