import { api, apiException } from "~services/api";
import {
  FetchGetEcoriStructureByStructureIdPayloadSchema,
  type IFetchGetEcoriStructureByStructureIdPayloadSchema,
  EcoriStructureIdentifierSchema,
} from "~types/schemas";

export const fetchGetEcoriStructureByStructureId = async (
  payload: IFetchGetEcoriStructureByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetEcoriStructureByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/equipments/ecori/structure-by-relationship/${structureId}`,
    );

    const parsedData = EcoriStructureIdentifierSchema.parse(
      response.data || {},
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
