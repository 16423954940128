import { api, apiException } from "~services/api";
import {
  EosProtocolResponseSchema,
  FetchCreateOrganizationEosFinancingPayloadSchema,
  IFetchCreateOrganizationEosFinancingPayloadSchema,
} from "~types/schemas";

export const fetchCreateOrganizationEosFinancing = async (
  payload: IFetchCreateOrganizationEosFinancingPayloadSchema,
) => {
  try {
    const body =
      FetchCreateOrganizationEosFinancingPayloadSchema.parse(payload);

    const response = await api.post(`/eosfin/organization`, body);

    const parsedData = EosProtocolResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
