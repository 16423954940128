import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import type { IUseFetchUpdateMaxUsersLicensed } from "./interface";

import { fetchUpdateMaxUsersLicensed } from "~api/admin/users/{id}/max-users-licensed/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateMaxUsersLicensedKey =
  "api/admin/users/{id}/max-users-licensed/patch";

export function useFetchUpdateMaxUsersLicensed({
  options,
}: IUseFetchUpdateMaxUsersLicensed) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateMaxUsersLicensed(payload),
    {
      ...options,
      mutationKey: useFetchUpdateMaxUsersLicensedKey,
      onSuccess: (data, variables, context) => {
        // message.success("Quantidade de usuário da licença foi alterado");
        queryClient.invalidateQueries(useFetchGetAdminUsersKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
