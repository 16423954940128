import { api, apiException } from "~services/api";
import {
  FetchSaveSimulationGroupPayloadSchema,
  IFetchSaveSimulationGroupPayloadSchema,
  ProjectPaymentSimulationDTOSchema,
} from "~types/schemas";

export const fetchSaveSimulationGroup = async (
  payload: IFetchSaveSimulationGroupPayloadSchema,
) => {
  try {
    const body = FetchSaveSimulationGroupPayloadSchema.parse(payload);

    const response = await api.post("project/payment/simulation", body);

    const parsedData = ProjectPaymentSimulationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
