import { api, apiException } from "~services/api";
import {
  FetchUpdateSimulationGroupPayloadSchema,
  IFetchUpdateSimulationGroupPayloadSchema,
  ProjectPaymentSimulationDTOSchema,
} from "~types/schemas";

export const fetchUpdateSimulationGroup = async (
  payload: IFetchUpdateSimulationGroupPayloadSchema,
) => {
  try {
    const body = FetchUpdateSimulationGroupPayloadSchema.parse(payload);

    const response = await api.put("project/payment/simulation", body);

    const parsedData = ProjectPaymentSimulationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
