import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalStepOnePayloadSchema,
  IFetchUpdateProposalStepOnePayloadSchema,
  StepOneDTOSchema,
} from "~types/schemas";

export const fetchUpdateProposalStepOne = async (
  payload: IFetchUpdateProposalStepOnePayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateProposalStepOnePayloadSchema.parse(payload);

    const response = await api.put(
      `/projects/step_one/custom-id/${customId}`,
      body,
    );

    const parsedData = StepOneDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
