import { ProposalDealResponseSchema } from "./ProposalDealResponseSchema";
import { ProposalOrganizationResponseSchema } from "./ProposalOrganizationResponseSchema";
import { ProposalPersonResponseSchema } from "./ProposalPersonResponseSchema";

import {
  PipedriveDealStatusEnum,
  ProjectTypeEnum,
  ProposalStatusEnum,
  ProposalStepEnum,
} from "~types/enum";
import { z } from "zod";

export const ProposalResponseSchema = z.object({
  id: z.number().int().gt(0),
  formattedIdentifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  clientName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  fullPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valueForCustomer: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cityName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  lastStep: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  lastStepEnum: ProposalStepEnum.nullish().transform((val) => val ?? "DATA"),
  pipedriveDealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined), // zero is acceptable
  dealId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  customId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveDealStatus: PipedriveDealStatusEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  sellerName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  sellerAvatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  linkProposal: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  finished: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  haveIntermediateStep: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  havePaymentStep: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  proposalDealResponse: ProposalDealResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  person: ProposalPersonResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  organization: ProposalOrganizationResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IProposalResponseSchema
  extends z.infer<typeof ProposalResponseSchema> {}
