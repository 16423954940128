import { EdeltecModuleBrandTypeEnum } from "~types/enum";
import { z } from "zod";

export const EdeltecModuleBrandDTOSchema = z.object({
  moduleBrandType: EdeltecModuleBrandTypeEnum.nullish().transform(
    (val) => val ?? "",
  ),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEdeltecModuleBrandDTOSchema
  extends z.infer<typeof EdeltecModuleBrandDTOSchema> {}
