import { z } from "zod";

export const ActivityBoardDTOSchema = z.object({
  call: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  proposal: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  meeting: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  task: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  email: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  all: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IActivityBoardDTOSchema
  extends z.infer<typeof ActivityBoardDTOSchema> {}
