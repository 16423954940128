import { useQuery } from "react-query";

import type { IUseFetchGetFortlevStructureByStructureId } from "./interface";

import { fetchGetFortlevStructureByStructureId } from "~api/equipments/fortlev/structure-by-relationship/{structureId}/get";

export const useFetchGetFortlevStructureByStructureIdKey =
  "api/equipments/fortlev/structure-by-relationship/{structureId}/get";

export function useFetchGetFortlevStructureByStructureId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetFortlevStructureByStructureId) {
  return useQuery(
    [useFetchGetFortlevStructureByStructureIdKey, dependencyArray],
    async () => await fetchGetFortlevStructureByStructureId(payload),
    options,
  );
}
