import { useQuery } from "react-query";

import type { IUseFetchCreateEncryptedPersonalToken } from "./interface";

import { fetchCreateEncryptedPersonalToken } from "~api/users/personal-token/encrypt-payload/post";

export const useFetchCreateEncryptedPersonalTokenKey =
  "api/users/personal-token/encrypt-payload/post";

export function useFetchCreateEncryptedPersonalToken({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchCreateEncryptedPersonalToken) {
  return useQuery(
    [useFetchCreateEncryptedPersonalTokenKey, dependencyArray],
    async () => await fetchCreateEncryptedPersonalToken(payload),
    options,
  );
}
