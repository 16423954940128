import { z } from "zod";

export const CityDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICityDTOSchema extends z.infer<typeof CityDTOSchema> {}
