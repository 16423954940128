import { api, apiException } from "~services/api";
import {
  FetchAssignBusinessUnitToUserPayloadSchema,
  IFetchAssignBusinessUnitToUserPayloadSchema,
} from "~types/schemas";

export const fetchAssignBusinessUnitToUser = async (
  payload: IFetchAssignBusinessUnitToUserPayloadSchema,
) => {
  try {
    const { businessUnitId, userId } =
      FetchAssignBusinessUnitToUserPayloadSchema.parse(payload);

    await api.post<void>(`/business-units/${businessUnitId}/add/${userId}`);
  } catch (error) {
    throw apiException(error);
  }
};
