import { api, apiException } from "~services/api";
import {
  FetchDeleteUserGroupPayloadSchema,
  IFetchDeleteUserGroupPayloadSchema,
} from "~types/schemas";

export const fetchDeleteUserGroup = async (
  payload: IFetchDeleteUserGroupPayloadSchema,
) => {
  try {
    const { userGroupId } = FetchDeleteUserGroupPayloadSchema.parse(payload);

    await api.delete(`/usergroup/${userGroupId}`);
  } catch (error) {
    throw apiException(error);
  }
};
