import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedOrganizationDealsByOrganizationId } from "./interface";

import { fetchGetPaginatedOrganizationDealsByOrganizationId } from "~api/organizations/{id}/deals/get";

export const useFetchGetPaginatedOrganizationDealsByOrganizationIdKey =
  "api/organizations/{id}/deals/get";

export function useFetchGetPaginatedOrganizationDealsByOrganizationId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedOrganizationDealsByOrganizationId) {
  return useQuery(
    [useFetchGetPaginatedOrganizationDealsByOrganizationIdKey, dependencyArray],
    async () =>
      await fetchGetPaginatedOrganizationDealsByOrganizationId(payload),
    options,
  );
}
