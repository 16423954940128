import { useMutation } from "react-query";

import { useFetchGetDatasheetByIdKey } from "../useFetchGetDatasheetById";
import { useFetchGetDatasheetLastRevisionKey } from "../useFetchGetDatasheetLastRevision";
import { useFetchGetDatasheetRevisionByIdKey } from "../useFetchGetDatasheetRevisionById";
import { useFetchGetPaginatedDatasheetKey } from "../useFetchGetPaginatedDatasheet";
import { useFetchGetSearchPaginatedDatasheetKey } from "../useFetchGetSearchPaginatedSearchDatasheet";
import type { IUseFetchCreateDatasheet } from "./interface";

import { fetchCreateDatasheet } from "~api/admin/datasheets/base64/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateDatasheetKey = "api/admin/datasheets/base64/post";

export function useFetchCreateDatasheet({ options }: IUseFetchCreateDatasheet) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateDatasheet(payload), {
    ...options,
    mutationKey: useFetchCreateDatasheetKey,
    onSuccess: (data, variables, context) => {
      message.success("Datasheet foi criado");

      queryClient.invalidateQueries(useFetchGetDatasheetByIdKey);
      queryClient.invalidateQueries(useFetchGetPaginatedDatasheetKey);
      queryClient.invalidateQueries(useFetchGetSearchPaginatedDatasheetKey);
      queryClient.invalidateQueries(useFetchGetDatasheetRevisionByIdKey);
      queryClient.invalidateQueries(useFetchGetDatasheetLastRevisionKey);

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar Datasheet: ${error.message}`);

      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
