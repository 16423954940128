import { z } from "zod";

export const FetchLinkPipedrivePayloadSchema = z.object({
  companyId: z
    .number({ message: "ID da companhia é obrigatório" })
    .int({ message: "ID da companhia precisa ser um inteiro" })
    .gt(0, { message: "ID da companhia precisa ser maior que zero!" }),
});

export interface IFetchLinkPipedrivePayloadSchema
  extends z.infer<typeof FetchLinkPipedrivePayloadSchema> {}
