import { z } from "zod";

export const ConsultantCompanyFilterSchema = z
  .object({
    company: z.string(),
    email: z.string(),
    id: z.string(),
    isPipedriveIntegration: z.boolean(),
    page: z.number().int(),
    size: z.number().int(),
  })
  .partial();
export interface IConsultantCompanyFilterSchema
  extends z.infer<typeof ConsultantCompanyFilterSchema> {}
