import { api, apiException } from "~services/api";
import {
  DealCustomFieldResponseSchema,
  FetchGetDealCustomFieldByIdPayloadSchema,
  type IFetchGetDealCustomFieldByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetDealCustomFieldsById = async (
  payload: IFetchGetDealCustomFieldByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetDealCustomFieldByIdPayloadSchema.parse(payload);

    const response = await api.get(`/config/deals/custom-fields/${id}`);

    const parsedData = DealCustomFieldResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
