import { z } from "zod";

export const RevisionDealResponseSchema = z.object({
  dealId: z
    .number({
      message: "ID do negócio é obrigatório",
    })
    .int({
      message: "ID do negócio deve ser um número inteiro",
    })
    .gt(0, {
      message: "ID do negócio deve ser maior do que zero",
    }),
  dealName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IRevisionDealResponseSchema
  extends z.infer<typeof RevisionDealResponseSchema> {}
