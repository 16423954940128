import { api, apiException } from "~services/api";
import {
  FetchCopyBusinessUnitPayloadSchema,
  IFetchCopyBusinessUnitPayloadSchema,
} from "~types/schemas";

export const fetchCopyBusinessUnit = async (
  payload: IFetchCopyBusinessUnitPayloadSchema,
) => {
  try {
    const { businessUnitId, ...body } =
      FetchCopyBusinessUnitPayloadSchema.parse(payload);

    await api.post<void>(`/business-units/${businessUnitId}/copy`, body);
  } catch (error) {
    throw apiException(error);
  }
};
