import { AdditionalCostDTOSchema } from "./AdditionalCostDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedAdditionalCostsDTOSchema = PaginationSchema(
  AdditionalCostDTOSchema,
);

export interface IPaginatedAdditionalCostsDTOSchema
  extends z.infer<typeof PaginatedAdditionalCostsDTOSchema> {}
