import { useMutation } from "react-query";

import { UseFetchGetCurrentAdditionalCostMarginTypeKey } from "../useFetchGetCurrentAdditionalCostMarginType";
import { UseFetchGetPaginatedAdditionalCostMarginTypeKey } from "../useFetchGetPaginatedAdditionalCostMarginType";
import type { IUseFetchUpdateAdditionalCostMarginType } from "./interface";

import { fetchUpdateAdditionalCostMarginType } from "~api/additional-cost-margin-type/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAdditionalCostMarginTypeKey =
  "api/additional-cost-margin-type/{id}/put";

export function useFetchUpdateAdditionalCostMarginType({
  options,
}: IUseFetchUpdateAdditionalCostMarginType) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateAdditionalCostMarginType(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateAdditionalCostMarginTypeKey,
      onSuccess: (data, variables, context) => {
        message.success("Tipo de margem de lucro atualizado com sucesso!");
        queryClient.invalidateQueries(
          UseFetchGetPaginatedAdditionalCostMarginTypeKey,
        );
        queryClient.invalidateQueries(
          UseFetchGetCurrentAdditionalCostMarginTypeKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
