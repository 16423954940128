import { VoltageEnum, PhaseEnum, KitOriginEnum } from "~types/enum";
import { z } from "zod";

export const KitSearchFiltersSchema = z.object({
  fullPower: z.number(),
  inverterMinPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  inverterMaxPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  powerUpperLimit: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  powerLowerLimit: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  page: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  size: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  supplierName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  nameKit: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  moduleManufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  inverterManufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  maxInverterNum: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  structureId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  outputVoltage: VoltageEnum,
  sort: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  orderBy: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  custom: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  kitOrigin: KitOriginEnum.nullish().transform((val) => val ?? undefined),
  projectId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  customId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  electricUtilityId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  region: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fdiFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IKitSearchFiltersSchema
  extends z.infer<typeof KitSearchFiltersSchema> {}
