import { useQuery } from "react-query";

import type { IUseFetchGetDealByCustomId } from "./interface";

import { fetchGetDealByCustomId } from "~api/deal/{customId}/get";

export const useFetchGetDealByCustomIdKey = "api/deal/{customId}/get";

export function useFetchGetDealByCustomId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetDealByCustomId) {
  return useQuery(
    [useFetchGetDealByCustomIdKey, dependencyArray],
    async () => await fetchGetDealByCustomId(payload),
    options,
  );
}
