import { InverterCustomDTOSchema } from "./InverterCustomDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedInverterCustomDTOSchema = PaginationSchema(
  InverterCustomDTOSchema,
);

export interface IPaginatedInverterCustomDTOSchema
  extends z.infer<typeof PaginatedInverterCustomDTOSchema> {}
