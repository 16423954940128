import { useQuery } from "react-query";

import type { IUseFetchPostToGetPaginatedProposals } from "./interface";

import { fetchPostToGetPaginatedProposals } from "~api/proposal/filter/post";

export const useFetchPostToGetPaginatedProposalsKey =
  "api/proposal/filter/post";

export function useFetchPostToGetPaginatedProposals({
  options,
  dependencyArray,
  payload,
}: IUseFetchPostToGetPaginatedProposals) {
  return useQuery(
    [useFetchPostToGetPaginatedProposalsKey, dependencyArray],
    async () => await fetchPostToGetPaginatedProposals(payload),
    options,
  );
}
