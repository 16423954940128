import { useMutation } from "react-query";

import { useFetchGetPaginatedSimultaneityProfilesKey } from "../useFetchGetPaginatedSimultaneityProfiles";
import type { IUseFetchCreateSimultaneityProfile } from "./interface";

import { fetchCreateSimultaneityProfiles } from "~api/simultaneityProfiles/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateSimultaneityProfileKey =
  "api/simultaneityProfiles/post";

export function useFetchCreateSimultaneityProfile({
  options,
}: IUseFetchCreateSimultaneityProfile) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchCreateSimultaneityProfiles(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateSimultaneityProfileKey,
      onSuccess: (data, variables, context) => {
        message.success("Perfil cadastrado com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedSimultaneityProfilesKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao cadastrar perfil: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
