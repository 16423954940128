import { api, apiException } from "~services/api";
import type { ElectricUtilityProfileDTO } from "~types/api/ElectricUtilityProfileDTO";
import {
  ElectricUtilityProfileDTOSchema,
  FetchCreateConcessionaireProfilePayloadSchema,
  IFetchCreateConcessionaireProfilePayloadSchema,
} from "~types/schemas";

export const fetchCreateConcessionaireProfile = async (
  payload: IFetchCreateConcessionaireProfilePayloadSchema,
) => {
  try {
    const body = FetchCreateConcessionaireProfilePayloadSchema.parse(payload);

    const response = await api.post<ElectricUtilityProfileDTO>(
      `/electric-utility-profile`,
      body,
    );

    const parsedData = ElectricUtilityProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
