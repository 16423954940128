import { CostAggregatorDTOSchema } from "./CostAggregatorDTOSchema";
import { RangeDTOSchema } from "./RangeDTOSchema";
import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";

import { PriceRuleEnum } from "~types/enum";
import { z } from "zod";

export const LaborDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  ranges: z
    .array(RangeDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  structureTypes: z
    .array(StructureTypeDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  pricingRule: PriceRuleEnum.nullish().transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  additionalCostTaxId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  notApplyDefaultAdditionalCostTax: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  costAggregators: z
    .array(CostAggregatorDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  customExpression: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ILaborDTOSchema extends z.infer<typeof LaborDTOSchema> {}
