import { z } from "zod";

export const FetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema =
  z.object({
    organizationId: z.number().int().gt(0),
    page: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => val ?? 0),
    size: z
      .number()
      .int()
      .gte(0)
      .lte(100)
      .nullish()
      .transform((val) => val ?? 5),
  });

export interface IFetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedOrganizationDealsByOrganizationIdPayloadSchema
  > {}
