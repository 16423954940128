import { api, apiException } from "~services/api";
import {
  FetchCreateOrganizationPayloadSchema,
  IFetchCreateOrganizationPayloadSchema,
  OrganizationDTOSchema,
} from "~types/schemas";

export const fetchCreateOrganization = async (
  payload: IFetchCreateOrganizationPayloadSchema,
) => {
  try {
    const body = FetchCreateOrganizationPayloadSchema.parse(payload);

    const response = await api.post(`/organizations`, body);

    const parsedData = OrganizationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
