import { z } from "zod";

export const LaborSuggestedSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  suggested: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface ILaborSuggestedSchema
  extends z.infer<typeof LaborSuggestedSchema> {}
