import { useMutation } from "react-query";

import { useFetchGetAllCustomVariablesKey } from "../useFetchGetAllCustomVariables";
import type { IUseFetchCreateCustomVariable } from "./interface";

import { fetchCreateCustomVariable } from "~api/custom-variable/post";
import {
  useFetchGetCustomVariablesWithFieldBindKey,
  useFetchGetVariablesWithFieldKey,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateCustomVariableKey = "api/custom-variable/post";

export function useFetchCreateCustomVariable({
  options,
}: IUseFetchCreateCustomVariable) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchCreateCustomVariable(payload);
    },
    {
      ...options,
      mutationKey: useFetchCreateCustomVariableKey,
      onSuccess: (data, variables, context) => {
        message.success("Variável customizada criada!");
        queryClient.invalidateQueries(useFetchGetAllCustomVariablesKey);
        queryClient.invalidateQueries(useFetchGetVariablesWithFieldKey);
        queryClient.invalidateQueries(
          useFetchGetCustomVariablesWithFieldBindKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar variável customizada: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
