import { api, apiException } from "~services/api";
import {
  FetchGetAutoCompleteProductsPayloadSchema,
  IFetchGetAutoCompleteProductsPayloadSchema,
  PaginatedGenericProductResponseSchema,
} from "~types/schemas";

export const fetchGetAutoCompleteProducts = async (
  payload: IFetchGetAutoCompleteProductsPayloadSchema,
) => {
  try {
    const params = FetchGetAutoCompleteProductsPayloadSchema.parse(payload);

    const response = await api.get(`/product/search`, {
      params: {
        ...params,
        page: 0, // STATIC
        size: 100, // STATIC
      },
    });

    const parsedData = PaginatedGenericProductResponseSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
