import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedKitsPayloadSchema,
  IFetchGetPaginatedKitsPayloadSchema,
  PaginatedCustomKitDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedKits = async (
  payload: IFetchGetPaginatedKitsPayloadSchema,
) => {
  try {
    const body = FetchGetPaginatedKitsPayloadSchema.parse(payload);

    const response = await api.post(`/product/kit`, body);

    const parsedData = PaginatedCustomKitDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
