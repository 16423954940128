import "~styles/globals.css";

import { ReactElement, ReactNode, useEffect } from "react";
import { QueryClientProvider } from "react-query";

import { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";

import { ErrorBoundary } from "~components/ErrorBoundary";
import { GoogleAnalytics } from "~components/Others/GoogleAnalytics";
import { ENV } from "~configs/Env";
import { AppConfigContextProvider } from "~contexts/AppConfigContext";
import { RxStompClientContextProvider } from "~contexts/RxStompClientContext";
import { SessionContextProvider } from "~contexts/SessionContext";
import { queryClient } from "~services/queryClient";

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // PWA service worker register
  useEffect(() => {
    if (
      process.env.ENV === "production" &&
      ENV.ACTIVE_PWA &&
      "serviceWorker" in navigator
    ) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/service-worker.js").catch(() => {
          console.error("cannot register service-worker.js");
        });
      });
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  const ComponentWithLayout = getLayout(<Component {...pageProps} />);

  return (
    <ErrorBoundary>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AppConfigContextProvider>
          <SessionContextProvider>
            <RxStompClientContextProvider>
              {ComponentWithLayout}
            </RxStompClientContextProvider>
          </SessionContextProvider>
        </AppConfigContextProvider>
      </QueryClientProvider>

      <GoogleAnalytics />
    </ErrorBoundary>
  );
}
