import { z } from "zod";

export const InverterDetailDTOSchema = z.object({
  maxCurrentPerMpptInput: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxShortCircuitCurrentPerMpptInput: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  inputPerMpptNum: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IInverterDetailDTOSchema
  extends z.infer<typeof InverterDetailDTOSchema> {}
