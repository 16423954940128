import { AdditionalCostFormSchema } from "./AdditionalCostFormSchema";

import { z } from "zod";

export const FetchUpdateAdditionalCostsPayloadSchema =
  AdditionalCostFormSchema.extend({
    additionalCostId: z.number().int().gt(0),
  });

export interface IFetchUpdateAdditionalCostsPayloadSchema
  extends z.infer<typeof FetchUpdateAdditionalCostsPayloadSchema> {}
