import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedTransformerRules } from "./interface";

import { fetchGetTransformerRules } from "~api/config/transformer/page/{page}/pageSize/{pageSize}/get";

export const useFetchGetPaginatedTransformerRulesKey =
  "api/config/transformer/page/{page}/pageSize/{pageSize}/get";

export function useFetchGetPaginatedTransformerRules({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    pageSize: 10,
  },
}: IUseFetchGetPaginatedTransformerRules) {
  return useQuery(
    [useFetchGetPaginatedTransformerRulesKey, dependencyArray],
    async () => await fetchGetTransformerRules(payload),
    options,
  );
}
