import { LocationServiceEnum } from "~types/enum";
import { z } from "zod";

export const UserConfigurationFormSchema = z.object({
  locationService: LocationServiceEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  maxUsersLicensed: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  accountOwnerEmail: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IUserConfigurationFormSchema
  extends z.infer<typeof UserConfigurationFormSchema> {}
