import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedDatasheetRevisions } from "./interface";

import { fetchGetPaginatedDatasheetRevisions } from "~api/admin/datasheets/{id}/revisions/get";

export const useFetchGetPaginatedDatasheetRevisionsKey =
  "api/admin/datasheets/{id}/revisions/get";

export function useFetchGetPaginatedDatasheetRevisions({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedDatasheetRevisions) {
  return useQuery(
    [useFetchGetPaginatedDatasheetRevisionsKey, dependencyArray],
    async () => await fetchGetPaginatedDatasheetRevisions(payload),
    options,
  );
}
