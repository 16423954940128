import { ElectricUtilityProfileDTOSchema } from "./ElectricUtilityProfileDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedConcessionairesProfilesDTOSchema = PaginationSchema(
  ElectricUtilityProfileDTOSchema,
);

export interface IPaginatedConcessionairesProfilesDTOSchema
  extends z.infer<typeof PaginatedConcessionairesProfilesDTOSchema> {}
