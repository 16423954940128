import { ActivityEventTypeEnum } from "~types/enum";
import { z } from "zod";

export const ActivityEventTypeDTOSchema = z.object({
  eventType: ActivityEventTypeEnum,
  quantity: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IActivityEventTypeDTOSchema
  extends z.infer<typeof ActivityEventTypeDTOSchema> {}
