import { api, apiException } from "~services/api";
import {
  FetchGetHideableFieldsByProfilePayloadSchema,
  IFetchGetHideableFieldsByProfilePayloadSchema,
  HideableFieldDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetHideableFieldsByProfile = async (
  payload: IFetchGetHideableFieldsByProfilePayloadSchema,
) => {
  try {
    const { profileId } =
      FetchGetHideableFieldsByProfilePayloadSchema.parse(payload);

    const response = await api.get(`/fields/hideable/${profileId}`);

    const parsedData = z.array(HideableFieldDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
