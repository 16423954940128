import { z } from "zod";

export const DealCustomFieldValueSchema = z.object({
  dealCustomFieldId: z.number().int().gt(0),
  value: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IDealCustomFieldValueSchema
  extends z.infer<typeof DealCustomFieldValueSchema> {}
