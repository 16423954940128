import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedLabors } from "./interface";

import { fetchGetPaginatedLabors } from "~api/labors/get";

export const useFetchGetAllLaborsKey = "api/labors/get";

export function useFetchGetPaginatedLabors({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 5,
  },
}: IUseFetchGetPaginatedLabors) {
  return useQuery(
    [useFetchGetAllLaborsKey, dependencyArray],
    async () => await fetchGetPaginatedLabors(payload),
    options,
  );
}
