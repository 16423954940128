import { z } from "zod";

export const LostMotiveInsightDataSchema = z.object({
  motiveId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  count: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ILostMotiveInsightDataSchema
  extends z.infer<typeof LostMotiveInsightDataSchema> {}
