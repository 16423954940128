import { z } from "zod";

export const FetchGetPaginatedProductInverterManufacturersPayloadSchema =
  z.object({
    page: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => val ?? 0),
    size: z
      .number()
      .int()
      .gte(0)
      .nullish()
      .transform((val) => (!val ? 100 : val)),
  });

export interface IFetchGetPaginatedProductInverterManufacturersPayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedProductInverterManufacturersPayloadSchema
  > {}
