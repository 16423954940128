import { z } from "zod";

export const LogisticsFormSchema = z.object({
  minimunDistance: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pricePerKm: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  additionalCostTaxId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  notApplyDefaultAdditionalCostTax: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  costAggregatorIds: z.array(z.number()).optional(),
});

export interface ILogisticsFormSchema
  extends z.infer<typeof LogisticsFormSchema> {}
