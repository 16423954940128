import { z } from "zod";

export const TariffPremiseDTOSchema = z.object({
  id: z.number().int().gt(0),
  tusdLowVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teLowVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdMediumVoltageFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teMediumVoltageFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdMediumVoltageP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teMediumVoltageP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxLowVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxMediumVoltageP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBTaxMediumVoltageFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  simultaneityFactor: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxBeforeLowVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfterLowVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxBeforeMediumVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfterMediumVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  reactivePowerAverageMediumVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ITariffPremiseDTOSchema
  extends z.infer<typeof TariffPremiseDTOSchema> {}
