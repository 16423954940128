import { DealPersonDTOSchema } from "./DealPersonDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedDealPersonDTOSchema =
  PaginationSchema(DealPersonDTOSchema);

export interface IPaginatedDealPersonDTOSchema
  extends z.infer<typeof PaginatedDealPersonDTOSchema> {}
