import { api, apiException } from "~services/api";
import {
  FetchGetProposalStepFivePayloadSchema,
  IFetchGetProposalStepFivePayloadSchema,
  StepFiveDTOSchema,
} from "~types/schemas";

export const fetchGetProposalStepFive = async (
  payload: IFetchGetProposalStepFivePayloadSchema,
) => {
  try {
    const { customId } = FetchGetProposalStepFivePayloadSchema.parse(payload);

    const response = await api.get(`/projects/step_five/custom-id/${customId}`);

    const parsedData = StepFiveDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
