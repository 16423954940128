import { useQuery } from "react-query";

import type { IUseFetchGetKomecoStructureByStructureId } from "./interface";

import { fetchGetKomecoStructureByStructureId } from "~api/equipments/komeco/structure-by-relationship/{structureId}/get";

export const useFetchGetKomecoStructureByStructureIdKey =
  "api/equipments/komeco/structure-by-relationship/{structureId}/get";

export function useFetchGetKomecoStructureByStructureId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetKomecoStructureByStructureId) {
  return useQuery(
    [useFetchGetKomecoStructureByStructureIdKey, dependencyArray],
    async () => await fetchGetKomecoStructureByStructureId(payload),
    options,
  );
}
