import { useMutation } from "react-query";

import { useFetchGetLogisticsKey } from "../useFetchGetLogistics";
import type { IUseFetchCreateLogistics } from "./interface";

import { fetchSaveLogistics } from "~api/logistics/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateLogisticsKey = "api/logistics/post";

export function useFetchCreateLogistics({ options }: IUseFetchCreateLogistics) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchSaveLogistics(payload), {
    ...options,
    mutationKey: useFetchCreateLogisticsKey,
    onSuccess: (data, variables, context) => {
      message.success("Logística cadastrada com sucesso!");
      queryClient.invalidateQueries(useFetchGetLogisticsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar logística: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
