import { useQuery } from "react-query";

import type { IUseFetchGetPipelineById } from "./interface";

import { fetchGetPipelineById } from "~api/pipeline/{id}/get";

export const useFetchGetPipelineByIdKey = "api/pipeline/{id}/get";

export function useFetchGetPipelineById({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPipelineById) {
  return useQuery(
    [useFetchGetPipelineByIdKey, dependencyArray],
    async () => await fetchGetPipelineById(payload),
    options,
  );
}
