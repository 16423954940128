import { LocationServiceEnum } from "~types/enum";
import { z } from "zod";

export const UserListItemDTOSchema = z.object({
  id: z.number().int().gt(0),
  company: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  companyId: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  blocked: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  maxUsersLicensed: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  countUsers: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  locationService: LocationServiceEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  lastActivity: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  dataUsage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  pipedriveIntegration: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  templateCompany: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IUserListItemDTOSchema
  extends z.infer<typeof UserListItemDTOSchema> {}
