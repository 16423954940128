import { BusinessUnitDTOSchema } from "./BusinessUnitDTOSchema";

import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const PersonCustomFieldDtoSchema = z.object({
  id: z.number().int().gte(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  displayOrder: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  enabled: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? []),
  businessUnits: z
    .array(BusinessUnitDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPersonCustomFieldDtoSchema
  extends z.infer<typeof PersonCustomFieldDtoSchema> {}
