import { z } from "zod";

export const FetchGetAutoCompletePersonPayloadSchema = z
  .object({
    term: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .nullish()
  .transform((val) => val ?? {});

export interface IFetchGetAutoCompletePersonPayloadSchema
  extends z.infer<typeof FetchGetAutoCompletePersonPayloadSchema> {}
