import { AttechedFileFormSchema } from "./AttechedFileFormSchema";

import { z } from "zod";

export const CreateNoteFormSchema = z.object({
  text: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  textComponent: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  dealId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  organizationId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  personId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  attachedFiles: AttechedFileFormSchema.array()
    .nullish()
    .transform((val) => val ?? undefined),
  mentionedIds: z
    .number()
    .array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ICreateNoteFormSchema
  extends z.infer<typeof CreateNoteFormSchema> {}
