import { DealCustomFieldValueSchema } from "./DealCustomFieldValueSchema";
import { DealReasonForLossResponseSchema } from "./DealReasonForLossResponseSchema";
import { DiverseServiceResponseSchema } from "./DiverseServiceResponseSchema";
import { SimpleClientDTOSchema } from "./SimpleClientDTOSchema";
import { SimpleOrganizationDTOSchema } from "./SimpleOrganizationDTOSchema";
import { SimplePipelineDTOSchema } from "./SimplePipelineDTOSchema";
import { SimplePipelineStageDTOSchema } from "./SimplePipelineStageDTOSchema";
import { SimpleUserAccountDTOSchema } from "./SimpleUserAccountDTOSchema";
import { SolarServiceResponseSchema } from "./SolarServiceResponseSchema";

import { ProposalStatusEnum } from "~types/enum";
import { z } from "zod";

export const DealDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  age: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  inactiveDays: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  companyId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  dealReasonForLoss: DealReasonForLossResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  pipeline: SimplePipelineDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  pipelineStage: SimplePipelineStageDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  person: SimpleClientDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: SimpleOrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  owner: SimpleUserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  createdAt: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  estimatedClosingDate: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  status: ProposalStatusEnum.nullish().transform((val) => val ?? undefined),
  dealCustomFieldValues: DealCustomFieldValueSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  solarService: SolarServiceResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  diverseService: DiverseServiceResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IDealDTOSchema extends z.infer<typeof DealDTOSchema> {}
