import { api, apiException } from "~services/api";
import {
  FetchUpdateUserProfilePayloadSchema,
  IFetchUpdateUserProfilePayloadSchema,
} from "~types/schemas";

export const fetchUpdateUserProfile = async (
  payload: IFetchUpdateUserProfilePayloadSchema,
) => {
  try {
    const body = FetchUpdateUserProfilePayloadSchema.parse(payload);

    await api.put<void>(`/company/users/profile`, body);
  } catch (error) {
    throw apiException(error);
  }
};
