import { useMutation } from "react-query";

import { useFetchGetProposalExpirationRuleKey } from "../useFetchGetProposalExpirationRule";
import type { IUseFetchUpdateProposalExpirationRule } from "./interface";

import { fetchUpdateProposalExpirationRule } from "~api/proposal-expiration-rule/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalExpirationRuleKey =
  "api/proposal-expiration-rule/{id}/put";

export function useFetchUpdateProposalExpirationRule({
  options,
}: IUseFetchUpdateProposalExpirationRule) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateProposalExpirationRule(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateProposalExpirationRuleKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra de validade da proposta foi alterada");
        queryClient.invalidateQueries(useFetchGetProposalExpirationRuleKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
