import { WegParameterFormSchema } from "./WegParameterFormSchema";

import { z } from "zod";

export const FetchUpdateConfigWegDistributorIntegrationPayloadSchema =
  WegParameterFormSchema;

export interface IFetchUpdateConfigWegDistributorIntegrationPayloadSchema
  extends z.infer<
    typeof FetchUpdateConfigWegDistributorIntegrationPayloadSchema
  > {}
