import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedActivityByPeriod } from "./interface";

import { fetchGetPaginatedActivityByPeriod } from "~api/activities/period/page/{page}/size/{size}/get";

export const useFetchGetPaginatedActivityByPeriodKey =
  "api/activities/period/page/{page}/size/{size}/get";

export function useFetchGetPaginatedActivityByPeriod({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedActivityByPeriod) {
  return useQuery(
    [useFetchGetPaginatedActivityByPeriodKey, dependencyArray],
    async () => await fetchGetPaginatedActivityByPeriod(payload),
    options,
  );
}
