import { useMutation } from "react-query";

import { useFetchGetPaginatedProductImportHistoryKey } from "../useFetchGetPaginatedProductImportHistory";
import type { IUseFetchCancelImportProductQueue } from "./interface";

import { fetchCancelImportProductQueue } from "~api/product-queue/cancel/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCancelImportProductQueueKey = "api/product-queue/post";

export function useFetchCancelImportProductQueue({
  options,
}: IUseFetchCancelImportProductQueue) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCancelImportProductQueue(payload),
    {
      ...options,
      mutationKey: useFetchCancelImportProductQueueKey,
      onSuccess: (data, variables, context) => {
        message.success("Fila de importação foi cancelada!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedProductImportHistoryKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao cancelar fila de importação: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
