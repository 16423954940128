import { api, apiException } from "~services/api";
import {
  FetchCreatePremisesPayloadSchema,
  IFetchCreatePremisesPayloadSchema,
} from "~types/schemas";

export const fetchCreatePremises = async (
  payload: IFetchCreatePremisesPayloadSchema,
) => {
  try {
    const body = FetchCreatePremisesPayloadSchema.parse(payload);

    await api.post<void>(`/premises`, body);
  } catch (error) {
    throw apiException(error);
  }
};
