import { useMutation } from "react-query";

import type { IUseFetchSaveTrackingViewed } from "./interface";

import { fetchSaveTrackingViewed } from "~api/tracking/viewed/{uuid}/post";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchSaveTrackingViewedKey = "api/tracking/viewed/{uuid}/post";

export function useFetchSaveTrackingViewed({
  options,
}: IUseFetchSaveTrackingViewed) {
  const { message } = useAppConfig();

  return useMutation(
    async (path) => {
      return await fetchSaveTrackingViewed(path);
    },
    {
      ...options,
      mutationKey: useFetchSaveTrackingViewedKey,
      onSuccess: (data, variables, context) => {
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
