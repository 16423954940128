import { ProposalSectionEnum, ProposalStepEnum } from "~types/enum";
import { z } from "zod";

export const ProposalSectionDTOSchema = z.object({
  title: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  proposalStep: ProposalStepEnum.nullish().transform((val) => val ?? undefined),
});

export interface IProposalSectionDTOSchema
  extends z.infer<typeof ProposalSectionDTOSchema> {}
