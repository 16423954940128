import { UpdateDealStatusRequestSchema } from "./UpdateDealStatusRequestSchema";

import { z } from "zod";

export const FetchUpdateDealStatusByCustomIdPayloadSchema =
  UpdateDealStatusRequestSchema.extend({
    id: z.number().int().gt(0),
  });

export interface IFetchUpdateDealStatusByCustomIdPayloadSchema
  extends z.infer<typeof FetchUpdateDealStatusByCustomIdPayloadSchema> {}
