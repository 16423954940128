import { QueryKey, useQuery } from "react-query";

import {
  IUseFetchGetPaginatedBusinessUnits,
  UseFetchGetPaginatedBusinessUnitsResultType,
  UseFetchGetPaginatedBusinessUnitsErrorType,
} from "./interface";

import { pageBusinessUnit } from "~api/business-units/pageable/get";

export const useFetchGetPaginatedBusinessUnitsKey =
  "api/business-units/pageable/get";

export function useFetchGetPaginatedBusinessUnits({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    isActive: true,
  },
}: IUseFetchGetPaginatedBusinessUnits) {
  return useQuery<
    UseFetchGetPaginatedBusinessUnitsResultType,
    UseFetchGetPaginatedBusinessUnitsErrorType,
    UseFetchGetPaginatedBusinessUnitsResultType,
    QueryKey
  >(
    [useFetchGetPaginatedBusinessUnitsKey, dependencyArray],
    async () => {
      const response = await pageBusinessUnit(payload.page, payload.isActive);

      const businessUnits = response.data.content ?? []; // create a map parsing data to improves usability in all components
      const number = response.data.number;
      const size = response.data.size;
      const totalElements = response.data.totalElements;

      return {
        businessUnits,
        number,
        size,
        totalElements,
      };
    },
    options,
  );
}
