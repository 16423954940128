import { useMutation } from "react-query";

import { useFetchGetAdditionalCostTaxesKey } from "../useFetchGetAdditionalCostTaxes";
import type {
  UseFetchCreateAdditionalCostTaxPayloadType,
  UseFetchCreateAdditionalCostTaxErrorType,
  UseFetchCreateAdditionalCostTaxResultType,
  IUseFetchCreateAdditionalCostTax,
} from "./interface";

import { fetchCreateAdditionalCostTax } from "~api/config/additionalCostTax/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateAdditionalCostTaxKey =
  "api/config/additionalCostTax/post";

export function useFetchCreateAdditionalCostTax({
  options,
}: IUseFetchCreateAdditionalCostTax) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchCreateAdditionalCostTaxResultType,
    UseFetchCreateAdditionalCostTaxErrorType,
    UseFetchCreateAdditionalCostTaxPayloadType,
    unknown
  >(
    async (payload) => {
      return await fetchCreateAdditionalCostTax({ body: payload });
    },
    {
      ...options,
      mutationKey: useFetchCreateAdditionalCostTaxKey,
      onSuccess: (data, variables, context) => {
        message.success("Alíquota de imposto foi criada");
        queryClient.invalidateQueries(useFetchGetAdditionalCostTaxesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
