import { z } from "zod";

export const ConversionRateGroupedByUserInsightDataSchema = z.object({
  userId: z
    .number({ message: "Faltando o ID do usuário" })
    .int({ message: "ID do usuário deve ser um número inteiro" }),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  won: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  lost: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  wonPercentage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  lostPercentage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  deals: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IConversionRateGroupedByUserInsightDataSchema
  extends z.infer<typeof ConversionRateGroupedByUserInsightDataSchema> {}
