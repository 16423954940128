import { api, apiException } from "~services/api";
import {
  FetchDeleteBaseAddressPayloadSchema,
  IFetchDeleteBaseAddressPayloadSchema,
} from "~types/schemas/FetchDeleteBaseAddressPayloadSchema";

export const fetchDeleteBaseAddress = async (
  payload: IFetchDeleteBaseAddressPayloadSchema,
) => {
  try {
    const path = FetchDeleteBaseAddressPayloadSchema.parse(payload);

    await api.delete(`/company/addresses/${path.baseAddressId}`);
  } catch (error) {
    throw apiException(error);
  }
};
