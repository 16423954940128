import { z } from "zod";

export const FetchGetStepTwoAutocompletePayloadSchema = z.object({
  potency: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetStepTwoAutocompletePayloadSchema
  extends z.infer<typeof FetchGetStepTwoAutocompletePayloadSchema> {}
