import { z } from "zod";

export const AldoModuleBrandDTOSchema = z.object({
  id: z.number().int().gt(0),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IAldoModuleBrandDTOSchema
  extends z.infer<typeof AldoModuleBrandDTOSchema> {}
