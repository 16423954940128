import { useQuery } from "react-query";

import type { IUseFetchGetStructureDistributors } from "./interface";

import { fetchGetStructureDistributors } from "~api/structure-distributor/get";

export const useFetchGetStructureDistributorsKey =
  "api/structure-distributor/get";

export function useFetchGetStructureDistributors({
  dependencyArray = [],
  options,
}: IUseFetchGetStructureDistributors) {
  return useQuery(
    [useFetchGetStructureDistributorsKey, dependencyArray],
    async () => await fetchGetStructureDistributors(),
    options,
  );
}
