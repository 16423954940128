import { api, apiException } from "~services/api";
import {
  FetchGetProfileImagePayloadSchema,
  type IFetchGetProfileImagePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetProfileImage = async (
  payload: IFetchGetProfileImagePayloadSchema,
) => {
  try {
    const { userId } = FetchGetProfileImagePayloadSchema.parse(payload);
    const response = await api.get(`/users/${userId}/avatar`);

    const parsedData = z
      .string()
      .nullish()
      .transform((val) => val ?? "")
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
