import { IntegratorEnum, SyncLogStatusEnum } from "~types/enum";
import { z } from "zod";

export const SyncLogDTOSchema = z.object({
  id: z
    .number({ message: "ID do distribuidor é obrigatório!" })
    .int({ message: "ID do distribuidor deve ser um tipo inteiro" })
    .gt(0, { message: "ID do distribuidor deve ser maior que zero" }),
  syncInitTime: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  syncEndTime: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  status: SyncLogStatusEnum,
  message: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  distributor: IntegratorEnum,
  hash: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ISyncLogDTOSchema extends z.infer<typeof SyncLogDTOSchema> {}
