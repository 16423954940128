import { z } from "zod";

export const FetchGetPaginatedProductModulesPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => (!val ? 100 : val)),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  power: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  manufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedProductModulesPayloadSchema
  extends z.infer<typeof FetchGetPaginatedProductModulesPayloadSchema> {}
