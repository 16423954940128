import { api, apiException } from "~services/api";
import {
  ActivityBoardDTOSchema,
  FetchGetActivitiesCountByPersonIdPayloadSchema,
  IFetchGetActivitiesCountByPersonIdPayloadSchema,
} from "~types/schemas";

export const fetchGetActivitiesCountByPersonId = async (
  payload: IFetchGetActivitiesCountByPersonIdPayloadSchema,
) => {
  try {
    const { personId } =
      FetchGetActivitiesCountByPersonIdPayloadSchema.parse(payload);

    const response = await api.get(`/activities/board/person/${personId}`);

    const parsedData = ActivityBoardDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
