import { z } from "zod";

export const ProposalSectionEnum = z
  .enum([
    "PROJECT_DATA",
    "CLIENT_DATA",
    "ADDRESS_DATA",
    "INITIAL_DATA",
    "INVOICE_DATA",
    "POWER_PLANT_DATA",
    "PLANT_DATA",
    "INTERMEDIATE_DATA",
    "PAYMENT_METHOD",
    "FINISH_PROPOSAL ",
  ] as const)
  .catch((e) => e.input);

export type ProposalSectionEnum = z.infer<typeof ProposalSectionEnum>;
