import { z } from "zod";

export const ElectricUtilityProfileResponseSchema = z.object({
  id: z.number().int().gte(0),
  name: z.string(),
  electricUtilityId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IElectricUtilityProfileResponseSchema
  extends z.infer<typeof ElectricUtilityProfileResponseSchema> {}
