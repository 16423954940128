import { RangeFormSchema } from "./RangeFormSchema";
import { StructureTypeDTOSchema } from "./StructureTypeDTOSchema";

import { PriceRuleEnum } from "~types/enum";
import { z } from "zod";

export const LaborFormSchema = z
  .object({
    name: z.string(),
    ranges: z.array(RangeFormSchema),
    structureTypes: z.array(StructureTypeDTOSchema),
    pricingRule: PriceRuleEnum,
    active: z.boolean(),
    additionalCostTaxId: z
      .number()
      .int()
      .nullish()
      .transform((val) => val ?? undefined),
    notApplyDefaultAdditionalCostTax: z.boolean(),
    costAggregatorIds: z.array(z.number()).optional(),
    customExpression: z.string().optional(),
  })
  .partial();

export interface ILaborFormSchema extends z.infer<typeof LaborFormSchema> {}
