import { useQuery } from "react-query";

import type { IFetchGetElectricUtilityProfileConsumerSubclass } from "./interface";

import { fetchGetElectricUtilityProfileConsumerSubclass } from "~api/electric-utility-profile/consumer-subclass/get";

export const useFetchGetElectricUtilityProfileConsumerSubclassKey =
  "api/electric-utility-profile/consumer-subclass/get";

export function useFetchGetElectricUtilityProfileConsumerSubclass({
  dependencyArray = [],
  options,
}: IFetchGetElectricUtilityProfileConsumerSubclass) {
  return useQuery(
    [useFetchGetElectricUtilityProfileConsumerSubclassKey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileConsumerSubclass(),
    options,
  );
}
