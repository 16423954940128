import { api, apiException } from "~services/api";
import {
  type IFetchUpdateAdminModulesPayloadSchema,
  FetchUpdateAdminModulesPayloadSchema,
  PvModuleDTOSchema,
} from "~types/schemas";

export const fetchUpdateAdminModules = async (
  payload: IFetchUpdateAdminModulesPayloadSchema,
) => {
  try {
    const { id, ...body } = FetchUpdateAdminModulesPayloadSchema.parse(payload);

    const response = await api.put(`/admin/modules/${id}`, body);

    const parsedData = PvModuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
