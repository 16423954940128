import { AddressFormTwoSchema } from "./AddressFormTwoSchema";

import { z } from "zod";

export const BusinessUnitFormSchema = z
  .object({ name: z.string(), address: AddressFormTwoSchema })
  .partial();

export interface IBusinessUnitFormSchema
  extends z.infer<typeof BusinessUnitFormSchema> {}
