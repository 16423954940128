import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesFilterByOrganizationId } from "./interface";

import { fetchGetPaginatedHistoriesFilterByOrganizationId } from "~api/histories/organization/filters/get";

export const useFetchGetPaginatedHistoriesFilterByOrganizationIdKey =
  "api/histories/organization/filters/get";

export function useFetchGetPaginatedHistoriesFilterByOrganizationId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesFilterByOrganizationId) {
  return useQuery(
    [useFetchGetPaginatedHistoriesFilterByOrganizationIdKey, dependencyArray],
    async () => await fetchGetPaginatedHistoriesFilterByOrganizationId(payload),
    options,
  );
}
