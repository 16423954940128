import { api, apiException } from "~services/api";
import {
  ElectricUtilityProfileDTOSchema,
  FetchUpdateConcessionaireProfilePayloadSchema,
  IFetchUpdateConcessionaireProfilePayloadSchema,
} from "~types/schemas";

export const fetchUpdateConcessionaireProfile = async (
  payload: IFetchUpdateConcessionaireProfilePayloadSchema,
) => {
  try {
    const { concessionaireProfileId, ...body } =
      FetchUpdateConcessionaireProfilePayloadSchema.parse(payload);

    const response = await api.put(
      `/electric-utility-profile/${concessionaireProfileId}`,
      body,
    );

    const parsedData = ElectricUtilityProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
