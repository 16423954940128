import { api, apiException } from "~services/api";
import { StructureTypeDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetStructureTypes = async () => {
  try {
    const response = await api.get(`/company/structure_type`);

    const parsedData = z.array(StructureTypeDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
