import { useQuery } from "react-query";

import type { IUseFetchGetAllPermissions } from "./interface";

import { fetchGetAllPermissions } from "~api/permissions/get";

export const useFetchGetAllPermissionsKey = "api/performance-profiles/get";

export function useFetchGetAllPermissions({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAllPermissions) {
  return useQuery(
    [useFetchGetAllPermissionsKey, dependencyArray],
    async () => await fetchGetAllPermissions(payload),
    options,
  );
}
