import { z } from "zod";

export const FetchGetPaginatedAdditionalCostMarginTypePayloadSchema = z.object({
  page: z.number().int().gte(0).default(0),
  size: z.number().int().gte(0).lte(100).default(5),
});

export interface IFetchGetPaginatedAdditionalCostMarginTypePayloadSchema
  extends z.infer<
    typeof FetchGetPaginatedAdditionalCostMarginTypePayloadSchema
  > {}
