import { useMutation } from "react-query";

import { useFetchGetPaginatedPersonKey } from "../useFetchGetPaginatedPerson";
import { useFetchGetPersonByIdKey } from "../useFetchGetPersonById";
import type { IUseFetchBindsPipedriveIdToClient } from "./interface";

import { fetchBindsPipedriveIdToClient } from "~api/persons/{id}/link-pipedrive-id/{pipedriveId}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchBindsPipedriveIdToClientKey =
  "api/persons/{id}/link-pipedrive-id/{pipedriveId}/patch";

export function useFetchBindsPipedriveIdToClient({
  options,
}: IUseFetchBindsPipedriveIdToClient) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchBindsPipedriveIdToClient(payload),
    {
      ...options,
      mutationKey: useFetchBindsPipedriveIdToClientKey,
      onSuccess: (data, variables, context) => {
        message.success("Vinculado com sucesso!");
        queryClient.invalidateQueries(useFetchGetPaginatedPersonKey);
        queryClient.invalidateQueries(useFetchGetPersonByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao vincular o ID do pipedrive ao cliente: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
