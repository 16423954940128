import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldValueById } from "./interface";

import { fetchGetDealCustomFieldValueById } from "~api/deal/{id}/custom-fields/get";

export const useFetchGetDealCustomFieldValueByIdKey =
  "api/deal/{id}/custom-fields/get";

export function useFetchGetDealCustomFieldValueById({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetDealCustomFieldValueById) {
  return useQuery(
    [useFetchGetDealCustomFieldValueByIdKey, dependencyArray],
    async () => await fetchGetDealCustomFieldValueById(payload),
    options,
  );
}
