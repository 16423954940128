import { api, apiException } from "~services/api";
import {
  CustomFieldDTOSchema,
  FetchGetCustomFieldsPayloadSchema,
  IFetchGetCustomFieldsPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetCustomFields = async (
  payload: IFetchGetCustomFieldsPayloadSchema,
) => {
  try {
    const query = FetchGetCustomFieldsPayloadSchema.parse(payload);

    const response = await api.get(`/config/customFields`, {
      params: query,
    });

    const parsedData = z
      .array(CustomFieldDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
