import { z } from "zod";

export const CustomPvModuleStructureDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  type: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  subtype: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  appliesTo: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  numSupportedModules: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  precoBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  distributor: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ICustomPvModuleStructureDTOSchema
  extends z.infer<typeof CustomPvModuleStructureDTOSchema> {}
