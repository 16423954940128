import { z } from "zod";

export const SimpleUserAccountInputDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .email()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  avatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ISimpleUserAccountInputDTOSchema
  extends z.infer<typeof SimpleUserAccountInputDTOSchema> {}
