import { api, apiException } from "~services/api";
import {
  CreateUserDTOPayloadSchema,
  ICreateUserDTOPayloadSchema,
} from "~types/schemas";

export const fetchAdminCreateUser = async (
  payload: ICreateUserDTOPayloadSchema,
) => {
  try {
    const body = CreateUserDTOPayloadSchema.parse(payload);

    await api.post(`/admin/createUser`, body);
  } catch (error) {
    throw apiException(error);
  }
};
