import { useQuery } from "react-query";

import type { IUseFetchGetPipedrivePipelines } from "./interface";

import { fetchGetPipedrivePipelines } from "~api/pipedrive/pipelines/get";

export const useFetchGetPipedrivePipelinesKey = "api/pipedrive/pipelines/get";

export function useFetchGetPipedrivePipelines({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedrivePipelines) {
  return useQuery(
    [useFetchGetPipedrivePipelinesKey, dependencyArray],
    async () => await fetchGetPipedrivePipelines(),
    options,
  );
}
