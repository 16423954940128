import { api, apiException } from "~services/api";
import {
  FetchGetProposalPayments,
  IFetchGetProposalPayments,
} from "~types/schemas";

export const fetchGetProposalPayments = async (
  payload: IFetchGetProposalPayments,
) => {
  try {
    const { customId } = FetchGetProposalPayments.parse(payload);

    const { data } = await api.get(
      `/project/payment/simulation-item-project/custom-id/${customId}`,
    );

    return data ?? [];
  } catch (error) {
    throw apiException(error);
  }
};
