import { z } from "zod";

export const InverterDetailFormSchema = z.object({
  maxCurrentPerMpptInput: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  maxShortCircuitCurrentPerMpptInput: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  inputPerMpptNum: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IInverterDetailFormSchema
  extends z.infer<typeof InverterDetailFormSchema> {}
