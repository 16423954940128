import { LogisticsFormSchema } from "./LogisticsFormSchema";

import { z } from "zod";

export const FetchUpdateLogisticsPayloadSchema = LogisticsFormSchema.extend({
  logisticsId: z.number().int().gt(0),
});

export interface IFetchUpdateLogisticsPayloadSchema
  extends z.infer<typeof FetchUpdateLogisticsPayloadSchema> {}
