import { z } from "zod";

export const FetchDeleteDatasheetPayloadSchema = z.object({
  id: z
    .number({ message: "ID do Datasheet é obrigatório." })
    .int({ message: "ID do Datasheet deve ser um inteiro." })
    .gt(0, { message: "ID deve ser maior que zero." }),
});

export interface IFetchDeleteDatasheetPayloadSchema
  extends z.infer<typeof FetchDeleteDatasheetPayloadSchema> {}
