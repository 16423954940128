import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { TaxTypeEnum } from "~types/enum";
import { z } from "zod";

export const MediumVoltageFormSchema = z.object({
  averageConsumptionP: z.number(),
  taxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teP: z.number(),
  tusdP: z.number(),
  averageConsumptionFP: z.number(),
  taxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFP: z.number(),
  tusdFP: z.number(),
  wireBTaxP: z.number(),
  wireBTaxFP: z.number(),
  contractedDemand: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxBefore: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cosipTaxAfter: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  reactivePowerAverage: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlyValuesP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  monthlyValuesFP: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  taxType: TaxTypeEnum.nullish().transform((val) => val ?? undefined),
  contractedDemandP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  postSolarDemandKw: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxTusdG: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IMediumVoltageFormSchema
  extends z.infer<typeof MediumVoltageFormSchema> {}
