import { PropertyDiffSchema } from "./PropertyDiffSchema";

import { LogActionTypeEnum, LogEntityTypeEnum } from "~types/enum";
import { z } from "zod";

export const UsageLogsDTOSchema = z.object({
  actionType: LogActionTypeEnum,
  companyId: z.number().int().gt(0),
  createdAt: z.string(),
  description: z.string(),
  entityCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  entityId: z.number().int().gt(0),
  entityType: LogEntityTypeEnum,
  id: z.number().int().gt(0),
  link: z.string(),
  propertyDiffs: PropertyDiffSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  targetName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  userAccountId: z.number().int().gt(0),
  userAccountName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IUsageLogsDTOSchema
  extends z.infer<typeof UsageLogsDTOSchema> {}
