import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalStatusPayloadSchema,
  IFetchUpdateProposalStatusPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalStatus = async (
  payload: IFetchUpdateProposalStatusPayloadSchema,
) => {
  try {
    const { customId, ...body } =
      FetchUpdateProposalStatusPayloadSchema.parse(payload);

    await api.patch(`/proposal/pipedrive/status/custom-id/${customId}`, body);
  } catch (error) {
    throw apiException(error);
  }
};
