import { ReferenceEntityEnum } from "~types/enum";
import { z } from "zod";

export const ItemKitDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  category: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  quantity: z.number().transform((val) => val ?? 0),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  otherParams: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  systemGenerated: z.boolean().transform((val) => !!val),
  manuallyAdded: z.boolean().transform((val) => !!val),
  manuallyOverwritten: z.boolean().transform((val) => !!val),
  additionalCostDescriptorId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  referenceEntity: ReferenceEntityEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  print: z.boolean().transform((val) => !!val),
  deactivated: z.boolean().transform((val) => !!val),
  ignoreViewPricePermission: z.boolean().transform((val) => !!val),
  groupName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  solar: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  outOfStock: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IItemKitDTOSchema extends z.infer<typeof ItemKitDTOSchema> {}
