import { api, apiException } from "~services/api";
import { FileDocx } from "~types/api/FileDocx";

export type FetchGetDocxProposal = {
  path: {
    id: number;
  };
};

export const fetchGetDocxProposal = async ({ path }: FetchGetDocxProposal) => {
  try {
    return await api.get<FileDocx>(
      `/projects/step_five/docx/custom-id/${path.id}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
