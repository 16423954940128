import { z } from "zod";

export const FetchGetAutoCompleteProductsPayloadSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetAutoCompleteProductsPayloadSchema
  extends z.infer<typeof FetchGetAutoCompleteProductsPayloadSchema> {}
