import { api, apiException } from "~services/api";
import {
  FetchUpdatePremisesFinancialPayloadSchema,
  IFetchUpdatePremisesFinancialPayloadSchema,
  PremiseDTOSchema,
} from "~types/schemas";

export const fetchUpdatePremisesFinancial = async (
  payload: IFetchUpdatePremisesFinancialPayloadSchema,
) => {
  try {
    const body = FetchUpdatePremisesFinancialPayloadSchema.parse(payload);

    const response = await api.put(`/premises/financial`, body);

    const parsedData = PremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
