import { useQuery } from "react-query";

import { IUseFetchGetPaginatedProductImportHistory } from "./interface";

import { fetchGetPaginatedProductImportHistory } from "~api/product-queue/get";

export const useFetchGetPaginatedProductImportHistoryKey =
  "api/product-queue/get";

export function useFetchGetPaginatedProductImportHistory({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedProductImportHistory) {
  return useQuery(
    [useFetchGetPaginatedProductImportHistoryKey, dependencyArray],
    async () => await fetchGetPaginatedProductImportHistory(payload),
    {
      ...options,
      onSuccess: (data) => {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );
}
