import { api, apiException } from "~services/api";
import {
  FetchBindsPipedriveIdToClientPayloadSchema,
  PersonDTOSchema,
  type IFetchBindsPipedriveIdToClientPayloadSchema,
} from "~types/schemas";

export const fetchBindsPipedriveIdToClient = async (
  payload: IFetchBindsPipedriveIdToClientPayloadSchema,
) => {
  try {
    const { pipedriveId, personId } =
      FetchBindsPipedriveIdToClientPayloadSchema.parse(payload);

    const response = await api.patch(
      `/persons/${personId}/link-pipedrive-id/${pipedriveId}`,
    );

    const parsedData = PersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
