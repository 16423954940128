import { z } from "zod";

export const KitItemCategoryEnum = z
  .enum([
    "Inversor",
    "Módulo",
    "Outro",
    "Estrutura",
    "Disjuntor",
    "Cabo",
    "Garantia Estendida",
  ] as const)
  .catch((e) => e.input);

export type KitItemCategoryEnum = z.infer<typeof KitItemCategoryEnum>;
