import { api, apiException } from "~services/api";
import type { CustomProductDTO } from "~types/api";
import {
  CustomProductDTOSchema,
  FetchUpdateProductTypeOtherPayloadSchema,
  IFetchUpdateProductTypeOtherPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProductTypeOther = async (
  payload: IFetchUpdateProductTypeOtherPayloadSchema,
) => {
  try {
    const { productId, ...body } =
      FetchUpdateProductTypeOtherPayloadSchema.parse(payload);

    const response = await api.put<CustomProductDTO>(
      `/product/other/${productId}`,
      body,
    );

    const parsedData = CustomProductDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
