import { api, apiException } from "~services/api";
import {
  FetchDeleteProductTypeOtherPayloadSchema,
  IFetchDeleteProductTypeOtherPayloadSchema,
} from "~types/schemas";

export const fetchDeleteProductTypeOther = async (
  payload: IFetchDeleteProductTypeOtherPayloadSchema,
) => {
  try {
    const { id } = FetchDeleteProductTypeOtherPayloadSchema.parse(payload);

    await api.delete(`/product/other/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
