import { useQuery } from "react-query";

import type { IUseFetchGetUserBusinessUnits } from "./interface";

import { fetchGetUserBusinessUnits } from "~api/users/business-units/me/get";

export const useFetchGetUserBusinessUnitsKey =
  "api/users/business-units/me/get";

export function useFetchGetUserBusinessUnits({
  dependencyArray = [],
  options,
}: IUseFetchGetUserBusinessUnits) {
  return useQuery(
    [useFetchGetUserBusinessUnitsKey, dependencyArray],
    async () => await fetchGetUserBusinessUnits(),
    options,
  );
}
