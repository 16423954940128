import { useQuery } from "react-query";

import type { IUseFetchGetFortlevStructures } from "./interface";

import { fetchGetAllFortlevEquipmentStructures } from "~api/equipments/fortlev/structures/get";

export const useFetchGetFortlevStructuresKey =
  "api/equipments/fortlev/structures/get";

export function useFetchGetFortlevStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetFortlevStructures) {
  return useQuery(
    [useFetchGetFortlevStructuresKey, dependencyArray],
    async () => await fetchGetAllFortlevEquipmentStructures(),
    options,
  );
}
