import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetUserBusinessUnitsKey } from "../../business-unit/useFetchGetUserBusinessUnits";
import { useFetchGetBusinessUnitUsersKey } from "../useFetchGetBusinessUnitUsers";
import { useFetchGetBusinessUnitUsersOutKey } from "../useFetchGetBusinessUnitUsersOut";
import { useFetchGetUserOutBusinessUnitKey } from "../useFetchGetUserOutBusinessUnit";
import type { IUseFetchDeleteBusinessUnitFromUser } from "./interface";

import { fetchDeleteBusinessUnitFromUser } from "~api/business-units/{id}/remove/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteBusinessUnitFromUserKey =
  "api/business-units/{id}/remove/remove";

export function useFetchDeleteBusinessUnitFromUser(
  options?: IUseFetchDeleteBusinessUnitFromUser,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteBusinessUnitFromUser(payload),
    {
      ...options,
      mutationKey: useFetchDeleteBusinessUnitFromUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário foi desassociado!");
        queryClient.invalidateQueries(useFetchGetBusinessUnitUsersKey);
        queryClient.invalidateQueries(useFetchGetBusinessUnitUsersOutKey);
        queryClient.invalidateQueries(useFetchGetUserOutBusinessUnitKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao desassociar: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
