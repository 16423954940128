import { useMutation } from "react-query";

import { useFetchGetProposalFieldsKey } from "../useFetchGetProposalFields";
import type { IUseFetchUpdateProposalDefaultFieldsProfileSettings } from "./interface";

import { fetchUpdateProposalDefaultFieldsProfileSettings } from "~api/default-fields/profile-settings/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalDefaultFieldsProfileSettingsKey =
  "api/default-fields/profile-settings/put";

export function useFetchUpdateProposalDefaultFieldsProfileSettings({
  options,
}: IUseFetchUpdateProposalDefaultFieldsProfileSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchUpdateProposalDefaultFieldsProfileSettings(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalDefaultFieldsProfileSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi atualizado!");
        queryClient.invalidateQueries(useFetchGetProposalFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao atualizar campo: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
