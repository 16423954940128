import { api, apiException } from "~services/api";
import {
  ConsumptionUnitDTOSchema,
  FetchUpdateConsumptionUnitPayloadSchema,
  IFetchUpdateConsumptionUnitPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchUpdateConsumptionUnit = async (
  payload: IFetchUpdateConsumptionUnitPayloadSchema,
) => {
  try {
    const {
      customId,
      consumerUnits: body,
      compensationType,
      allocationPercentage,
    } = FetchUpdateConsumptionUnitPayloadSchema.parse(payload);

    const response = await api.put(
      `/projects/step_two/consumption-unit/${customId}/compensationType/${compensationType}`,
      body,
      {
        params: {
          allocationPercentage,
        },
      },
    );

    const parsedData = z
      .array(ConsumptionUnitDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
