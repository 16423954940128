import { api, apiException } from "~services/api";
import {
  ClientDTOSchema,
  FetchGetPersonInformationByPipedriveIdPayloadSchema,
  type IFetchGetPersonInformationByPipedriveIdPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetPersonInformationByPipedriveId = async (
  payload: IFetchGetPersonInformationByPipedriveIdPayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchGetPersonInformationByPipedriveIdPayloadSchema.parse(payload);

    const response = await api.get(`/clients/pipedrive/${pipedriveId}`);

    const parsedData = ClientDTOSchema.omit({ id: true })
      .extend({
        id: z
          .number()
          .int()
          .gt(0)
          .nullish()
          .transform((val) => val ?? undefined),
      })
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
