import { useMutation } from "react-query";

import { useFetchGetDatasheetByIdKey } from "../useFetchGetDatasheetById";
import { useFetchGetDatasheetLastRevisionKey } from "../useFetchGetDatasheetLastRevision";
import { useFetchGetDatasheetRevisionByIdKey } from "../useFetchGetDatasheetRevisionById";
import { useFetchGetPaginatedDatasheetKey } from "../useFetchGetPaginatedDatasheet";
import { useFetchGetPaginatedDatasheetRevisionsKey } from "../useFetchGetPaginatedDatasheetRevisions";
import { useFetchGetSearchPaginatedDatasheetKey } from "../useFetchGetSearchPaginatedSearchDatasheet";
import type { IUseFetchUploadFileDatasheet } from "./interface";

import { fetchUploadFileDatasheet } from "~api/admin/datasheets/{id}/upload/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUploadFileDatasheetKey =
  "api/admin/datasheets/{id}/upload/post";

export function useFetchUploadFileDatasheet({
  options,
}: IUseFetchUploadFileDatasheet) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUploadFileDatasheet(payload),
    {
      ...options,
      mutationKey: useFetchUploadFileDatasheetKey,
      onSuccess: (data, variables, context) => {
        message.success("Arquivo do Datasheet foi enviado");

        queryClient.invalidateQueries(useFetchGetDatasheetByIdKey);
        queryClient.invalidateQueries(useFetchGetPaginatedDatasheetKey);
        queryClient.invalidateQueries(useFetchGetSearchPaginatedDatasheetKey);
        queryClient.invalidateQueries(useFetchGetDatasheetRevisionByIdKey);
        queryClient.invalidateQueries(useFetchGetDatasheetLastRevisionKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedDatasheetRevisionsKey,
        );

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao Arquivo do Datasheet: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
