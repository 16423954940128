import {
  ActivityStatusQueryEnum,
  ActivityEventTypeEnum,
  ActivityPriorityEnum,
  ActivityPeriodsEnum,
  ActivityStateQueryEnum,
} from "~types/enum";
import { z } from "zod";

export const FetchGetPaginatedActivityByPeriodPayloadSchema = z.object({
  begin: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? undefined),
  end: z
    .string()
    .datetime()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .array(ActivityStatusQueryEnum)
    .nullish()
    .transform((val) => val ?? undefined),
  state: z
    .array(ActivityStateQueryEnum)
    .nullish()
    .transform((val) => val ?? undefined),
  eventType: z
    .array(ActivityEventTypeEnum)
    .nullish()
    .transform((val) => val ?? undefined),
  priority: z
    .array(ActivityPriorityEnum)
    .nullish()
    .transform((val) => val ?? undefined),
  organizationId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  ownerId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  calendarShortcut: ActivityPeriodsEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  page: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedActivityByPeriodPayloadSchema
  extends z.infer<typeof FetchGetPaginatedActivityByPeriodPayloadSchema> {}
