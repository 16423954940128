import { api, apiException } from "~services/api";
import {
  FetchAddPersonToOrganizationPayloadSchema,
  IFetchAddPersonToOrganizationPayloadSchema,
} from "~types/schemas";

export const fetchAddPersonToOrganization = async (
  payload: IFetchAddPersonToOrganizationPayloadSchema,
) => {
  try {
    const { organizationId, ...body } =
      FetchAddPersonToOrganizationPayloadSchema.parse(payload);

    await api.patch(`/organizations/${organizationId}/people/add`, body);
  } catch (error) {
    throw apiException(error);
  }
};
