import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesFilterByDealIdPayloadSchema,
  PaginatedHistoryDTOSchema,
  type IFetchGetHistoriesFilterByDealIdPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedHistoriesFilterByDealId = async (
  payload: IFetchGetHistoriesFilterByDealIdPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetHistoriesFilterByDealIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/deal/filters", {
      params: queryParams,
    });

    const parsedData = PaginatedHistoryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
