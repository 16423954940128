import { useMutation } from "react-query";

import { useFetchGetAllStructuresKey } from "../../structures/useFetchGetAllStructures";
import { useFetchGetStructureTypesKey } from "../useFetchGetStructureTypes";
import type { IUseFetchDeleteStructureType } from "./interface";

import { fetchDeleteStructureType } from "~api/company/structure_type/{structureTypeId}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteStructureTypeKey =
  "api/company/structure_type/{structureTypeId}/delete";

export function useFetchDeleteStructureType({
  options,
}: IUseFetchDeleteStructureType) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteStructureType(payload),
    {
      ...options,
      mutationKey: useFetchDeleteStructureTypeKey,
      onSuccess: (data, variables, context) => {
        message.success("Tipo de estrutura foi removido!");
        queryClient.invalidateQueries(useFetchGetStructureTypesKey);
        queryClient.invalidateQueries(useFetchGetAllStructuresKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao remover tipo de estrutura: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
