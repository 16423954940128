import { AddressInputDTOSchema } from "./AddressInputDTOSchema";
import { SimpleUserAccountInputDTOSchema } from "./SimpleUserAccountInputDTOSchema";

import { z } from "zod";

export const ClientInputDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  address: AddressInputDTOSchema.nullish().transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  owner: SimpleUserAccountInputDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IClientInputDTOSchema
  extends z.infer<typeof ClientInputDTOSchema> {}
