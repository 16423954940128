import { api, apiException } from "~services/api";
import {
  UserAccountDTOSchema,
  type IFetchUploadProfileImagePayloadSchema,
  FetchUploadProfileImagePayloadSchema,
} from "~types/schemas";

export const fetchUploadProfileImage = async (
  payload: IFetchUploadProfileImagePayloadSchema,
) => {
  try {
    const body = FetchUploadProfileImagePayloadSchema.parse(payload);

    const response = await api.post(`/users/avatar`, body);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
