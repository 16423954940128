import { useQuery } from "react-query";

import type { IUseFetchGetLeadTimeInsight } from "./interface";

import { fetchGetLeadTimeInsight } from "~api/insights/leadTimeInsight/get";

export const useFetchGetLeadTimeInsightKey = "api/insights/leadTimeInsight/get";

export function useFetchGetLeadTimeInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetLeadTimeInsight) {
  return useQuery(
    [useFetchGetLeadTimeInsightKey, dependencyArray],
    async () => await fetchGetLeadTimeInsight(payload),
    options,
  );
}
