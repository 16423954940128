import { z } from "zod";

export const PermissionsEnum = z
  .enum([
    "VIEW_PRICE_DETAILS_KIT",
    "VIEW_PRICE_DETAILS_ADDITIONAL_COST",
    "EDIT_ITEMS_IN_DETAIL",
    "ACCESS_ADMINISTRATIVE_AREA",
    "MANAGEMENT_MANUAL_KITS",
    "MANAGEMENT_ADDITIONAL_COST",
    "REGISTER_MANUAL_KIT",
    "ACCESS_OWN_PROPOSALS",
    "IMPORT_SPREADSHEET_OF_COMPLEMENTARY_ITEMS",
    "NOT_PROVIDE_DISCOUNT",
    "NOT_PROVIDE_KIT_DISCOUNT",
    "DELETE_PROPOSALS",
    "ACCESS_GROUP_PROPOSALS",
    "ALLOW_DEACTIVATE_ITEM_ADDITIONAL_COST",
    "ALLOW_DEACTIVATE_ITEM_KIT",
    "USEFUL_AREA_READONLY",
    "DISTANCE_READONLY",
    "PERFORMANCE_RATE_READONLY",
    "EDIT_ITEM_KIT",
    "ADD_ITEM_KIT",
    "EDIT_ITEM_ADDITIONAL_COST",
    "ADD_ITEM_ADDITIONAL_COST",
    "VIEW_ADDITIONAL_COST_ADDED_AUTOMATICALLY",
    "SIMULTANEITY_FACTOR_READONLY",
    "PROPOSAL_EXPIRATION_READONLY",
    "NOT_EDIT_STRUCTURE_GROUP",
    "CREATE_DEFAULT_PROPOSAL",
    "CREATE_DIVERSE_PROPOSAL",
    "CREATE_EXPRESS_PROPOSAL",
    "CREATE_PIPELINE",
    "CHANGE_PIPELINE",
    "REMOVE_PIPELINE",
    "REMOVE_DEAL",
    "ACCESS_OWN_DEALS",
    "ACCESS_GROUP_DEALS",
  ] as const)
  .catch((e) => e.input);

export type PermissionsEnum = z.infer<typeof PermissionsEnum>;
