import { api, apiException } from "~services/api";
import {
  FetchGetLaborsSuggestedByStructureIdPayloadSchema,
  IFetchGetLaborsSuggestedByStructureIdPayloadSchema,
  LaborSuggestedSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetLaborsSuggestedByStructureId = async (
  payload: IFetchGetLaborsSuggestedByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetLaborsSuggestedByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(`/labors/suggested/${structureId}`);

    const parsedData = z
      .array(LaborSuggestedSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
