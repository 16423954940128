import { HistoryOrganizationDTOSchema } from "./HistoryOrganizationDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedHistoryOrganizationFilterDTOSchema = PaginationSchema(
  HistoryOrganizationDTOSchema,
);

export interface IPaginatedHistoryOrganizationFilterDTOSchema
  extends z.infer<typeof PaginatedHistoryOrganizationFilterDTOSchema> {}
