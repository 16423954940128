import { z } from "zod";

export const TemplateVariableEnum = z
  .enum([
    "PROPOSTA_DISTANCIA_KM_EQUIVALENTE",
    "PROPOSTA_ARVORES_EQUIVALENTE",
    "PROPOSTA_CARBONO_EQUIVALENTE",
    "PROPOSTA_NOME",
    "PROPOSTA_DISTANCIA",
    "PROPOSTA_PAYBACK",
    "PROPOSTA_TARIFA_SOLAR",
    "PROPOSTA_AREA_UTIL",
    "PROPOSTA_VALOR_EQUIPAMENTO",
    "PROPOSTA_VALOR_CUSTO_ADICIONAL",
    "PROPOSTA_MODELO_INVERSOR",
    "PROPOSTA_MARCA_INVERSOR_N",
    "PROPOSTA_MODELO_MODULO",
    "PROPOSTA_MARCA_MODULO",
    "PROPOSTA_ESTRUTURA",
    "PROPOSTA_ECONOMIA_ENERGIA_MENSAL",
    "PROPOSTA_VALOR_MENSAL_DEPOIS",
    "PROPOSTA_VALOR_MENSAL_ANTES",
    "PROPOSTA_TARIFA_DISTRIBUIDOR_FP",
    "PROPOSTA_CONSUMO_MEDIO",
    "PROPOSTA_FASE",
    "PROPOSTA_OBSERVACAO",
    "PROPOSTA_QTD_INVERSOR",
    "PROPOSTA_QTD_MODULOS",
    "PROPOSTA_GERACAO_MENSAL",
    "PROPOSTA_GERACAO_ANUAL_KWH",
    "PROPOSTA_GERACAO_ANUAL_MWH",
    "PROPOSTA_FLUXO_CAIXA_INV_N",
    "PROPOSTA_FLUXO_CAIXA_ACC_N",
    "PROPOSTA_FLUXO_CAIXA_ANO_N",
    "PROPOSTA_FLUXO_CAIXA_ECO_N",
    "P_FC_PAGO_ANTES_SOLAR_MES_N",
    "P_FC_PAGO_APOS_SOLAR_MES_N",
    "P_FC_PERCENTUAL_ECON_APOS_SOLAR_N",
    "PROPOSTA_FLUXO_CAIXA_TAR_N",
    "PROPOSTA_FLUXO_CAIXA_GER_N",
    "P_FC_PAGO_APOS_SOLAR_GDI_MES_N",
    "P_FC_PAGO_APOS_SOLAR_GDI_ANO_N",
    "FC_P_SOL_M_N",
    "FC_ANT_SOL_M_N",
    "FC_ECON_P_SOL_M_N",
    "FC_ANT_SOL_A_N",
    "FC_ECON_P_SOL_A_N",
    "FC_P_SOL_A_N",
    "PROPOSTA_FLUXO_CAIXA_ACC_B_N",
    "FC_ECON_P_SOL_A_VP_N",
    "PROPOSTA_FLUXO_ECO_ACC_VP_N",
    "PROPOSTA_FLUXO_CAIXA_ECO_VP_N",
    "FC_ECO_EXC_M_N",
    "FC_ECO_EXC_A_N",
    "FC_ENE_EXC_M_N",
    "FC_ENE_EXC_A_N",
    "PROPOSTA_DATA_CRIACAO",
    "PROPOSTA_DATA_CRIACAO_POR_EXTENSO",
    "PROPOSTA_IDENTIFICADOR",
    "SUA_EMPRESA_RESPONSAVEL_NOME",
    "SUA_EMPRESA_RESPONSAVEL_EMAIL",
    "SUA_EMPRESA_RESPONSAVEL_CELULAR",
    "CLIENTE_EMAIL",
    "CLIENTE_ENDERECO_LOGRADOURO",
    "CLIENTE_ENDERECO_ESTADO",
    "CLIENTE_ENDERECO_UF",
    "CLIENTE_ENDERECO_NUMERO",
    "CLIENTE_ENDERECO_CIDADE",
    "CLIENTE_ENDERECO_CEP",
    "CLIENTE_ENDERECO_BAIRRO",
    "CLIENTE_NOME",
    "CLIENTE_CELULAR",
    "CLIENTE_CNPJ_CPF",
    "SUA_EMPRESA_NOME",
    "PROPOSTA_VALOR",
    "PROPOSTA_POTENCIA_TOTAL",
    "PROPOSTA_GERACAO_MES_A_MES",
    "PROPOSTA_CONSUMO_MES_A_MES",
    "PROPOSTA_CONSUMO_PONTA_MES_A_MES",
    "OPCAO_N_ENTRADA",
    "OPCAO_N_PARCELA",
    "PROPOSTA_LINK",
    "SIMULTANEIDADE",
    "DEMANDA_ANTES_USINA_KWH",
    "DEMANDA_DEPOIS_USINA_KWH",
    "DEMANDA_ANTES_USINA_VALOR",
    "DEMANDA_DEPOIS_USINA_VALOR",
    "PROPOSTA_PAYBACK_GRAFICO",
    "CONCESSIONARIA",
    "PERFIL_DE_CONSUMO",
    "PROPOSTA_POTENCIA_INVERSOR_KWP",
    "PROPOSTA_POTENCIA_MODULO_WP",
    "LATITUDE_LOCAL_INSTALACAO",
    "LONGITUDE_LOCAL_INSTALACAO",
    "CLIENTE_ENDERECO_COMPLETO",
    "TRANSFORMADOR_ADICIONADO",
    "P_GER_TOTAL_KWH",
    "P_GER_TOTAL_MWH",
    "LCOE",
    "TENSAO_SAIDA_AC",
    "DESEMPENHO_SISTEMA",
    "IRRADIACAO_MEDIA_DIARIA",
    "PROPOSTA_VALOR_POR_KWP",
    "TIR_5",
    "TIR_25",
    "TMA",
    "PROPOSTA_PAYBACK_B",
    "LCOE_KWH",
    "PROPOSTA_REND_A_ESP",
    "INFL_ENERG_A",
    "PROPOSTA_COSIP_ANT_S",
    "PROPOSTA_COSIP_POS_S",
    "PROPOSTA_CONSUMO_MEDIO_TOTAL",
    "MAX_MODULOS_POR_STRING",
    "PROPOSTA_ESTRUTURA_CATEGORIA",
    "PROPOSTA_CONSUMO_MEDIO_PONTA",
    "PROPOSTA_TARIFA_DISTRIBUIDOR_P",
    "FC_ECON_PERC_M_PRIM_A",
    "TARIFA_DEMANDA_CONTRATADA",
    "PROPOSTA_VALOR_TOTAL_EXT",
    "PROPOSTA_GER_CREDITO_MENSAL",
    "PROPOSTA_DT_VALIDADE",
    "PROPOSTA_DT_VALIDADE_EXT",
    "PROPOSTA_VAL_DIA",
    "OPCAO_N_PARCELA_VAL",
    "OPCAO_N_PARCELA_QTD",
    "TARIFA_TE",
    "TARIFA_TE_FP",
    "TARIFA_TUSD",
    "TARIFA_TUSD_FP",
    "TARIFA_FIO_B",
    "TARIFA_FIO_B_FP",
    "GRUPO_TARIFA",
    "CONSUMO_MINIMO",
    "CONSUMO_MINIMO_ANUAL",
    "PROPOSTA_GER_REAIS",
    "GER_MENSAL_DEC",
    "CLIENTE_END_REGIAO",
    "CLIENTE_END_COD_REGIAO",
    "OPCAO_N_TOTAL_COM_JUROS",
    "POTENCIA_MAXIMA_INVERSORES",
    "PROPOSTA_MAIOR_POTENCIA_INVERSOR_KWP",
    "PROPOSTA_GERACAO_MEDIA_MES",
    "FC_P_SOL_M_K_N",
    "FC_ANT_SOL_M_K_N",
    "UC_EXT_NOME_N",
    "UC_EXT_CODIGO_N",
    "TENSAO_REDE",
    "CRED_M",
    "PROPOSTA_ESTRUTURA_DET",
    "UC_EXT_FASE_N",
    "PROPOSTA_VALOR_POR_WP",
    "PROPOSTA_MODELO_INVERSOR_N",
    "PROPOSTA_QNT_INVERSOR_N",
    "PROPOSTA_MARCA_INVERSOR",
    "INVERSOR_FASE",
    "COBERTURA_CONSUMO_PERCENTUAL",
    "KIT_DISTRIBUIDOR",
    "KIT_NOME",
    "PROPOSTA_ROI",
    "PROPOSTA_FASE_INVERSOR_N",
    "PROPOSTA_POTENCIA_INVERSOR_N",
    "PROPOSTA_POTENCIA_MAX_INVERSOR_N",
    "PROPOSTA_TENSAO_AC_INVERSOR_N",
  ] as const)
  .catch((e) => e.input);

export type TemplateVariableEnum = z.infer<typeof TemplateVariableEnum>;
