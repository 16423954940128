import { z } from "zod";

export const CompanyLogoSchema = z.object({
  imageUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ICompanyLogoSchema extends z.infer<typeof CompanyLogoSchema> {}
