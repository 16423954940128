import { api, apiException } from "~services/api";
import {
  AdditionalCostTaxDTOSchema,
  FetchUpdateAdditionalCostTaxPayloadSchema,
  IFetchUpdateAdditionalCostTaxPayloadSchema,
} from "~types/schemas";

export const fetchUpdateAdditionalCostTax = async (
  payload: IFetchUpdateAdditionalCostTaxPayloadSchema,
) => {
  try {
    const body = FetchUpdateAdditionalCostTaxPayloadSchema.parse(payload);

    const response = await api.put(`/config/additionalCostTax`, body);

    const parsedData = AdditionalCostTaxDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
