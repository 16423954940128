import { api, apiException } from "~services/api";
import {
  FetchCreateSimultaneityProfilesPayloadSchema,
  IFetchCreateSimultaneityProfilesPayloadSchema,
} from "~types/schemas";

export const fetchCreateSimultaneityProfiles = async (
  payload: IFetchCreateSimultaneityProfilesPayloadSchema,
) => {
  try {
    const body = FetchCreateSimultaneityProfilesPayloadSchema.parse(payload);
    await api.post(`/simultaneityProfiles`, body);
  } catch (error) {
    throw apiException(error);
  }
};
