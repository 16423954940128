import { api, apiException } from "~services/api";
import {
  DealCustomFieldGroupDTOSchema,
  FetchGetDealCustomFieldByPipelineStageIdPayloadSchema,
  IFetchGetDealCustomFieldByPipelineStageIdPayloadSchema,
} from "~types/schemas";

export const fetchGetDealCustomFieldsByPipelineStageId = async (
  payload: IFetchGetDealCustomFieldByPipelineStageIdPayloadSchema,
) => {
  try {
    const { pipelineStageId } =
      FetchGetDealCustomFieldByPipelineStageIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/config/deals/custom-fields/groups/by-stage/${pipelineStageId}`,
    );

    const parsedData = DealCustomFieldGroupDTOSchema.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
