import { useQuery } from "react-query";

import type { IUseFetchGetAllUsers } from "./interface";

import { fetchGetAllUsers } from "~api/users/get";

export const useFetchGetAllUsersKey = "api/users/get";

export function useFetchGetAllUsers({
  dependencyArray = [],
  options,
}: IUseFetchGetAllUsers) {
  return useQuery(
    [useFetchGetAllUsersKey, dependencyArray],
    async () => await fetchGetAllUsers(),
    options,
  );
}
