import { z } from "zod";

export const PipedriveAddressDTOSchema = z.object({
  address: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  streetNumber: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  route: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  sublocality: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  adminAreaLevel1: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  adminAreaLevel2: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  country: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  postalCode: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  formattedAddress: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IPipedriveAddressDTOSchema
  extends z.infer<typeof PipedriveAddressDTOSchema> {}
