import { useQuery } from "react-query";

import { IUseFetchGetCity } from "./interface";

import { fetchGetCities } from "~api/cities/search/get";

export const useFetchGetCityKey = "api/cities/search/get";

export function useFetchGetCity({
  dependencyArray = [],
  options,
  payload = {
    term: "",
  },
}: IUseFetchGetCity) {
  return useQuery(
    [useFetchGetCityKey, dependencyArray],
    async () => await fetchGetCities(payload),
    options,
  );
}
