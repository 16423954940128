import { api, apiException } from "~services/api";

export type FetchGetMessageWhatsAppProposal = {
  path: {
    customId: number;
  };
};

export type FetchGetMessageEmailProposal = {
  path: {
    customId: number;
  };
};

export const fetchGetMessageWhatsAppProposal = async ({
  path,
}: FetchGetMessageWhatsAppProposal) => {
  try {
    return await api.get<string>(
      `/projects/step_five/message/${path.customId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};

export const fetchGetMessageEmailProposal = async ({
  path,
}: FetchGetMessageEmailProposal) => {
  try {
    return await api.get<string>(
      `/projects/step_five/message/${path.customId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
