import { DataLowVoltageDTOSchema } from "./DataLowVoltageDTOSchema";
import { DataMediumVoltageDTOSchema } from "./DataMediumVoltageDTOSchema";

import { VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const ElectricUtilityProfileDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gte(0) // yes! it is a exception
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  companyId: z.number().int().gt(0),
  lowVoltage: DataLowVoltageDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  mediumVoltage: DataMediumVoltageDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  electricUtilityId: z.number().int().gt(0),
  voltageType: VoltageTypeEnum.nullish().transform((val) => val ?? undefined),
});

export interface IElectricUtilityProfileDTOSchema
  extends z.infer<typeof ElectricUtilityProfileDTOSchema> {}
