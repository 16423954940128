import { AddressDTOSchema } from "./AddressDTOSchema";
import { SimpleUserAccountDTOSchema } from "./SimpleUserAccountDTOSchema";

import { z } from "zod";

export const ClientDTOSchema = z.object({
  id: z.number().int().gt(0),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  pipedriveId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  owner: SimpleUserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IClientDTOSchema extends z.infer<typeof ClientDTOSchema> {}
