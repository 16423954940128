import { useQuery } from "react-query";

import type { IUseFetchGetAllKomecoEquipmentStructures } from "./interface";

import { fetchGetAllKomecoEquipmentStructures } from "~api/equipments/komeco/structures/get";

export const useFetchGetAllKomecoEquipmentStructuresKey =
  "api/equipments/komeco/structures/get";

export function useFetchGetAllKomecoEquipmentStructures({
  dependencyArray = [],
  options,
}: IUseFetchGetAllKomecoEquipmentStructures) {
  return useQuery(
    [useFetchGetAllKomecoEquipmentStructuresKey, dependencyArray],
    async () => await fetchGetAllKomecoEquipmentStructures(),
    options,
  );
}
