import { z } from "zod";

export const AdditionalCostCalculationScaleItemSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  key: z.number(),
  value: z.number(),
  minValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IAdditionalCostCalculationScaleItemSchema
  extends z.infer<typeof AdditionalCostCalculationScaleItemSchema> {}
