import { api, apiException } from "~services/api";
import {
  FetchUpdateConfigWegDistributorIntegrationPayloadSchema,
  IFetchUpdateConfigWegDistributorIntegrationPayloadSchema,
  WegParameterDTOSchema,
} from "~types/schemas";

export const fetchUpdateConfigWegDistributorIntegration = async (
  payload: IFetchUpdateConfigWegDistributorIntegrationPayloadSchema,
) => {
  try {
    const body =
      FetchUpdateConfigWegDistributorIntegrationPayloadSchema.parse(payload);

    const response = await api.put(`/distributors/weg`, body);

    const parsedData = WegParameterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
