import { z } from "zod";

export const historyTypeEnum = [
  "GENERAL",
  "CALL",
  "MEETING",
  "TASK",
  "EMAIL",
  "PROPOSAL",
  "DEAL",
  "PERSON",
  "ORGANIZATION",
  "NOTE",
] as const;

export const HistoryTypeEnum = z.enum(historyTypeEnum).catch((e) => e.input);

export type HistoryTypeEnum = z.infer<typeof HistoryTypeEnum>;
