import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedNotification } from "./interface";

import { fetchGetPaginatedNotifications } from "~api/notifications/get";

export const fetchGetPaginatedNotificationsKey = "api/notifications/get";

export function useFetchGetPaginatedNotifications({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPaginatedNotification) {
  return useQuery(
    [fetchGetPaginatedNotificationsKey, dependencyArray],
    async () => await fetchGetPaginatedNotifications(payload),
    options,
  );
}
