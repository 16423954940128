import { z } from "zod";

export const StepTwoAutocompleteDTO = z.object({
  regionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  proposalExpirationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IStepTwoAutocompleteDTO
  extends z.infer<typeof StepTwoAutocompleteDTO> {}
