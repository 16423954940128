import { useMutation } from "react-query";

import type {
  IUseFetchProposalCopy,
  UseFetchProposalCopyResultType,
  UseFetchProposalCopyErrorType,
  UseFetchProposalCopyPayloadType,
} from "./interface";

import { fetchCopyProject } from "~api/projects/copy/post";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchProposalCopyKey = "api/projects/copy/post";

export function useFetchProposalCopy({ options }: IUseFetchProposalCopy) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchProposalCopyResultType,
    UseFetchProposalCopyErrorType,
    UseFetchProposalCopyPayloadType,
    unknown
  >(
    async ({ id = 0 }: UseFetchProposalCopyPayloadType) => {
      const response = await fetchCopyProject({
        path: {
          customId: id,
        },
      });

      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchProposalCopyKey,
      onSuccess: (data, variables, context) => {
        message.success("Proposta copiada!");
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
