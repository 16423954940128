import { useQuery } from "react-query";

import { IUseFetchGetAdminUsers } from "./interface";

import { fetchGetPaginatedAdminUsers } from "~api/admin/users/post";

export const useFetchGetAdminUsersKey = "api/admin/users/post";

export function useFetchGetAdminUsers({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetAdminUsers) {
  return useQuery(
    [useFetchGetAdminUsersKey, dependencyArray],
    async () => await fetchGetPaginatedAdminUsers(payload),
    options,
  );
}
