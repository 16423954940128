import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetPaginatedPersonDealsByPersonIdKey } from "../../persons/useFetchGetPaginatedPersonDealsByPersonId";
import { useFetchGetPersonDealsCountByPersonIdKey } from "../../persons/useFetchGetPersonDealsCountByPersonId";
import { useFetchGetPipelineByIdKey } from "../../pipeline/useFetchGetPipelineById";
import { useFetchGetDealByCustomIdKey } from "../useFetchGetDealByCustomId";
import { useFetchSearchDealKey } from "../useFetchSearchDeal";
import { IuseFetchUpadateSolarServiceByDealId } from "./interface";

import { fetchUpdateSolarServiceByDealId } from "~api/deal/{id}/solar-service/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateSolarServiceByDealIdKey =
  "api/deal/{id}/solar-service/patch";

export function useFetchUpdateSolarServiceByDealId({
  options,
}: IuseFetchUpadateSolarServiceByDealId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateSolarServiceByDealId(payload),
    {
      ...options,
      mutationKey: useFetchUpdateSolarServiceByDealIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Serviço solar foi atualizado!");
        queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedPersonDealsByPersonIdKey,
        );
        queryClient.invalidateQueries(useFetchGetPersonDealsCountByPersonIdKey);
        queryClient.invalidateQueries(useFetchGetPipelineByIdKey);
        queryClient.invalidateQueries(useFetchSearchDealKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar Serviço Solar: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
