import { useQuery } from "react-query";

import type { IUseFetchGetAllRegions } from "./interface";

import { fetchGetAllEquipmentRegions } from "~api/equipments/regions/get";

export const useFetchGetAllRegionsKey = "api/equipments/regions/get";

export function useFetchGetAllRegions({
  dependencyArray = [],
  options,
}: IUseFetchGetAllRegions) {
  return useQuery(
    [useFetchGetAllRegionsKey, dependencyArray],
    async () => await fetchGetAllEquipmentRegions(),
    options,
  );
}
