import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedModules } from "./interface";

import { fetchGetPaginatedModules } from "~api/product/modulos/post";

export const useFetchGetPaginatedModulesKey = "api/product/modulos/post";

export function useFetchGetPaginatedModules({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
  },
}: IUseFetchGetPaginatedModules) {
  return useQuery(
    [useFetchGetPaginatedModulesKey, dependencyArray],
    async () => await fetchGetPaginatedModules(payload),
    options,
  );
}
