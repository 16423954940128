import { UsefulAreaDTOSchema } from "./UsefulAreaDTOSchema";

import {
  IrradiationTypeEnum,
  PipedriveBaseCalculationTypeEnum,
  StructureGroupEnum,
} from "~types/enum";
import { z } from "zod";

export const PremiseFormSchema = z.object({
  currentEnergyInflation: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  vplDiscountRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  moduleDeprecationFirstYear: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  moduleDeprecationOtherYears: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  performanceRate: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fdi: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  usefulAreas: z
    .array(UsefulAreaDTOSchema)
    .nullish()
    .transform((val) => val ?? undefined),
  updateValueDealPipedrive: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveBaseCalculationType:
    PipedriveBaseCalculationTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  irradiationType: IrradiationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  structureGroup: StructureGroupEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  microAndStringInverterMixAllowedInSolarKit: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  differentPhaseInverterMixAllowedInSolarKit: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPremiseFormSchema extends z.infer<typeof PremiseFormSchema> {}
