import { api, apiException } from "~services/api";
import {
  FetchGetTransformerRulesByIdPayloadSchema,
  IFetchGetTransformerRulesByIdPayloadSchema,
  TransformerAdditionalCostDtoSchema,
} from "~types/schemas";

export const fetchGetTransformerRulesById = async (
  payload: IFetchGetTransformerRulesByIdPayloadSchema,
) => {
  try {
    const { ruleId } = FetchGetTransformerRulesByIdPayloadSchema.parse(payload);

    const response = await api.get(`/config/transformer/${ruleId}`);

    const parsedData = TransformerAdditionalCostDtoSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
