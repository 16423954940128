import { api, apiException } from "~services/api";
import {
  AdditionalCostDTOSchema,
  FetchUpdateAdditionalCostsPayloadSchema,
  IFetchUpdateAdditionalCostsPayloadSchema,
} from "~types/schemas";

export const updateAdvancedAdditionalCosts = async (
  payload: IFetchUpdateAdditionalCostsPayloadSchema,
) => {
  try {
    const { additionalCostId, ...body } =
      FetchUpdateAdditionalCostsPayloadSchema.parse(payload);

    const response = await api.put(
      `/advanced/additional-costs/${additionalCostId}`,
      body,
    );

    const parsedData = AdditionalCostDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
