import { api, apiException } from "~services/api";
import type { TokenDTO } from "~types/api/TokenDTO";
import {
  FetchAdminImpersonateUserPayloadSchema,
  IFetchAdminImpersonateUserPayloadSchema,
} from "~types/schemas/FetchAdminImpersonateUserPayloadSchema";
import { TokenDTOSchema } from "~types/schemas/TokenDTOSchema";

export const fetchAdminImpersonateUser = async (
  payload: IFetchAdminImpersonateUserPayloadSchema,
) => {
  try {
    const body = FetchAdminImpersonateUserPayloadSchema.parse(payload);

    const response = await api.post<TokenDTO>(`/admin/impersonate`, body);

    const parsedData = TokenDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
