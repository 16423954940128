import { api, apiException } from "~services/api";
import { KomecoEquipmentModuleDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetAllKomecoEquipmentModules = async () => {
  try {
    const response = await api.get(`/equipments/komeco/modules`);

    const parsedData = z
      .array(KomecoEquipmentModuleDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
