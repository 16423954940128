import { useQuery } from "react-query";

import { IUseFetchGetPaginatedUserGroup } from "./interface";

import { fetchGetPaginatedUserGroup } from "~api/usergroup/get";

export const UseFetchGetPaginatedUserGroupKey = "api/usergroup/get";

export function useFetchGetPaginatedUserGroup({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedUserGroup) {
  return useQuery(
    [UseFetchGetPaginatedUserGroupKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedUserGroup(payload);
    },
    options,
  );
}
