import { z } from "zod";

export const AlternatingCurrentMaterialFormSchema = z
  .object({
    power: z.number().gte(0).optional(),
    value: z.number().gte(0).optional(),
    additionalCostTaxId: z.number().int().gt(0).optional(),
    costAggregatorIds: z.array(z.number()).optional(),
    notApplyDefaultAdditionalCostTax: z.boolean().optional().default(false),
  })
  .partial();

export interface IAlternatingCurrentMaterialFormSchema
  extends z.infer<typeof AlternatingCurrentMaterialFormSchema> {}
