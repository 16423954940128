import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedOtherProducts } from "./interface";

import { fetchGetPaginatedOtherProducts } from "~api/product/other/get";

export const useFetchGetPaginatedOtherProductsKey = "api/product/other/get";

export function useFetchGetPaginatedOtherProducts({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
  },
}: IUseFetchGetPaginatedOtherProducts) {
  return useQuery(
    [useFetchGetPaginatedOtherProductsKey, dependencyArray],
    async () => fetchGetPaginatedOtherProducts(payload),
    options,
  );
}
