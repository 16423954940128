import { useMutation } from "react-query";

import { useFetchGetAdminModulesByIdKey } from "../useFetchGetAdminModulesById";
import { useFetchGetPaginatedAdminModulesKey } from "../useFetchGetPaginatedAdminModules";
import type { IUseFetchUpdateAdminModules } from "./interface";

import { fetchUpdateAdminModules } from "~api/admin/modules/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAdminModulesKey = "api/admin/modules/{id}/put";

export function useFetchUpdateAdminModules({
  options,
}: IUseFetchUpdateAdminModules) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateAdminModules(payload),
    {
      ...options,
      mutationKey: useFetchUpdateAdminModulesKey,
      onSuccess: (data, variables, context) => {
        message.success("Módulo foi atualizado");

        queryClient.invalidateQueries(useFetchGetPaginatedAdminModulesKey);
        queryClient.invalidateQueries(useFetchGetAdminModulesByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar módulo: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
