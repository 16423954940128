import { api, apiException } from "~services/api";
import {
  type IFetchDeletePipelinePayloadSchema,
  FetchDeletePipelinePayloadSchema,
} from "~types/schemas";

export const fetchDeletePipeline = async (
  payload: IFetchDeletePipelinePayloadSchema,
) => {
  try {
    const { pipelineId, ...params } =
      FetchDeletePipelinePayloadSchema.parse(payload);

    await api.delete(`/pipeline/${pipelineId}`, {
      params,
    });
  } catch (error) {
    throw apiException(error);
  }
};
