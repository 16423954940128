import { useMutation } from "react-query";

import { useFetchGetCustomVariablesWithFieldBindKey } from "../../custom-variable/useFetchGetCustomVariablesWithFieldBind";
import { useFetchGetVariablesWithFieldKey } from "../../variables/useFetchGetVariablesWithFieldBind";
import type { IUseFetchUpdateFieldBind } from "./interface";

import { fetchUpdateFieldBind } from "~api/field-bind/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateFieldBindKey = "api/field-bind/put";

export function useFetchUpdateFieldBind({ options }: IUseFetchUpdateFieldBind) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdateFieldBind(payload), {
    ...options,
    mutationKey: useFetchUpdateFieldBindKey,
    onSuccess: (data, variables, context) => {
      message.success("A variável foi alterada");
      queryClient.invalidateQueries(useFetchGetVariablesWithFieldKey);
      queryClient.invalidateQueries(useFetchGetCustomVariablesWithFieldBindKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao alterar variável: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
