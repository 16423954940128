import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedDistributorsPayloadSchema,
  IFetchGetPaginatedDistributorsPayloadSchema,
  PaginatedDistributorDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedDistributors = async (
  payload: IFetchGetPaginatedDistributorsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedDistributorsPayloadSchema.parse(payload);

    const response = await api.get(`/distributors`, {
      params,
    });

    const parsedData = PaginatedDistributorDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
