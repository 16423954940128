import { useQuery } from "react-query";

import type { IUseFetchGetAdditionalCostTaxes } from "./interface";

import { fetchGetAdditionalCostTaxes } from "~api/config/additionalCostTax/get";

export const useFetchGetAdditionalCostTaxesKey =
  "api/config/additionalCostTax/get";

export function useFetchGetAdditionalCostTaxes({
  dependencyArray = [],
  options,
}: IUseFetchGetAdditionalCostTaxes) {
  return useQuery(
    [useFetchGetAdditionalCostTaxesKey, dependencyArray],
    async () => await fetchGetAdditionalCostTaxes(),
    options,
  );
}
