import { ProfileSchema } from "./ProfileSchema";

import {
  FlagsEnum,
  IntegratorEnum,
  ProjectTypeEnum,
  UserRolesEnum,
} from "~types/enum";
import { z } from "zod";

export const UserDTOSchema = z.object({
  id: z.number(),
  name: z.string(),
  lastName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z.string(),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  companyId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  idPipedrive: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  company: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  companyDomain: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pipedriveIntegration: z.boolean().transform((val) => !!val),
  pipedriveImportedUser: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  businessUnitId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  businessUnit: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  baseAddressBusinessUnitId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  roles: UserRolesEnum.array()
    .nullish()
    .transform((val) => val ?? []),
  accountOwner: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  profile: ProfileSchema.nullish().transform((val) => val ?? undefined),
  impersonate: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  distributors: z
    .array(IntegratorEnum)
    .nullish()
    .transform((val) => val ?? []),
  initialAccess: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  projectType: ProjectTypeEnum.nullish().transform((val) => val ?? undefined),
  pricingTypeId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  avatarUrl: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  flags: FlagsEnum.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IUserDTOSchema extends z.infer<typeof UserDTOSchema> {}
