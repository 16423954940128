import { api, apiException } from "~services/api";
import {
  FetchUpdateTransformerRulePayloadSchema,
  IFetchUpdateTransformerRulePayloadSchema,
  TransformerAdditionalCostDtoSchema,
} from "~types/schemas";

export const fetchUpdateTransformerRule = async (
  payload: IFetchUpdateTransformerRulePayloadSchema,
) => {
  try {
    const body = FetchUpdateTransformerRulePayloadSchema.parse(payload);

    const response = await api.put(`/config/transformer`, body);

    const parsedData = TransformerAdditionalCostDtoSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
