import { useMutation } from "react-query";

import { useFetchGetAllPipelinesKey } from "../useFetchGetAllPipelines";
import { useFetchGetAllPipelinesDetailedKey } from "../useFetchGetAllPipelinesDetailed";
import { useFetchGetPipelineByIdKey } from "../useFetchGetPipelineById";
import type { IUseFetchCreatePipeline } from "./interface";

import { fetchCreatePipeline } from "~api/pipeline/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePipelineKey = "api/organizations/post";

export function useFetchCreatePipeline({ options }: IUseFetchCreatePipeline) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreatePipeline(payload), {
    ...options,
    mutationKey: useFetchCreatePipelineKey,
    onSuccess: (data, variables, context) => {
      message.success("Pipeline foi criada!");
      queryClient.invalidateQueries(useFetchGetAllPipelinesKey);
      queryClient.invalidateQueries(useFetchGetAllPipelinesDetailedKey);
      queryClient.invalidateQueries(useFetchGetPipelineByIdKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar Pipeline: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
