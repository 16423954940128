import { TotalWonGroupedByUserInsightBarDataSchema } from "./TotalWonGroupedByUserInsightBarDataSchema";

import { z } from "zod";

export const TotalWonGroupedByUserInsightDTOSchema = z.object({
  data: TotalWonGroupedByUserInsightBarDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ITotalWonGroupedByUserInsightDTOSchema
  extends z.infer<typeof TotalWonGroupedByUserInsightDTOSchema> {}
