import { api, apiException } from "~services/api";
import {
  FetchUpdatePremisesDefaultValuesPayloadSchema,
  IFetchUpdatePremisesDefaultValuesPayloadSchema,
  PremiseDTOSchema,
} from "~types/schemas";

export const fetchUpdatePremisesDefaultValues = async (
  payload: IFetchUpdatePremisesDefaultValuesPayloadSchema,
) => {
  try {
    const body = FetchUpdatePremisesDefaultValuesPayloadSchema.parse(payload);

    const response = await api.put(`/premises/default-values`, body);

    const parsedData = PremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
