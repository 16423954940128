import { UpdateDealFormSchema } from "./UpdateDealFormSchema";

import { z } from "zod";

export const FetchUpdateDealByCustomIdPayloadSchema =
  UpdateDealFormSchema.extend({
    customId: z.number().int().gt(0),
  });

export interface IFetchUpdateDealByCustomIdPayloadSchema
  extends z.infer<typeof FetchUpdateDealByCustomIdPayloadSchema> {}
