import { api, apiException } from "~services/api";
import {
  TemplateReportDTOSchema,
  FetchUploadFilePayloadSchema,
  IFetchUploadFilePayloadSchema,
} from "~types/schemas";

export const fetchUploadFile = async (
  payload: IFetchUploadFilePayloadSchema,
) => {
  try {
    const body = FetchUploadFilePayloadSchema.parse(payload);

    const response = await api.post(`/reports/upload`, body);

    const parsedData = TemplateReportDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
