import { api, apiException } from "~services/api";
import {
  FetchUpdateLogisticsDistancePayloadSchema,
  IFetchUpdateLogisticsDistancePayloadSchema,
  StepFourUpdateLogisticsDTOSchema,
} from "~types/schemas";

export const fetchUpdateLogisticsDistance = async (
  payload: IFetchUpdateLogisticsDistancePayloadSchema,
) => {
  try {
    const { proposalId, ...body } =
      FetchUpdateLogisticsDistancePayloadSchema.parse(payload);

    const response = await api.put<void>(
      `/projects/step_four/logistics/${proposalId}`,
      body,
    );

    const parsedData = StepFourUpdateLogisticsDTOSchema.optional().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
