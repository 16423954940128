import { useQuery } from "react-query";

import { IUseFetchGetConfigFortlevDistributorIntegration } from "./interface";

import { fetchGetConfigFortlevDistributorIntegration } from "~api/distributors/fortlev/get";

export const useFetchGetConfigFortlevDistributorIntegrationKey =
  "api/distributors/fortlev/get";

export function useFetchGetConfigFortlevDistributorIntegration({
  dependencyArray = [],
  options,
}: IUseFetchGetConfigFortlevDistributorIntegration) {
  return useQuery(
    [useFetchGetConfigFortlevDistributorIntegrationKey, dependencyArray],
    async () => await fetchGetConfigFortlevDistributorIntegration(),
    options,
  );
}
