import { EosDocumentSearchDataSchema } from "./EosDocumentSearchDataSchema";

import { z } from "zod";

export const EosDocumentSearchResponseSchema = z.object({
  data: z.array(EosDocumentSearchDataSchema).nullish().default([]),
});

export interface IEosDocumentSearchResponseSchema
  extends z.infer<typeof EosDocumentSearchResponseSchema> {}
