import { api, apiException } from "~services/api";
import {
  FetchGetKomecoStructureByStructureIdPayloadSchema,
  type IFetchGetKomecoStructureByStructureIdPayloadSchema,
  KomecoStructureDTOSchema,
} from "~types/schemas";

export const fetchGetKomecoStructureByStructureId = async (
  payload: IFetchGetKomecoStructureByStructureIdPayloadSchema,
) => {
  try {
    const { structureId } =
      FetchGetKomecoStructureByStructureIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/equipments/komeco/structure-by-relationship/${structureId}`,
    );

    // when not return result the backend returns a empty string in response.data
    const parsedData = KomecoStructureDTOSchema.parse(response.data || {});

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
