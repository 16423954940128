import { EdeltecInverterBrandTypeEnum } from "~types/enum";
import { z } from "zod";

export const EdeltecInverterBrandDTOSchema = z.object({
  inverterBrandType: EdeltecInverterBrandTypeEnum.nullish().transform(
    (val) => val ?? "",
  ),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEdeltecInverterBrandDTOSchema
  extends z.infer<typeof EdeltecInverterBrandDTOSchema> {}
