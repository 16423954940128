import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedConcessionairesProfilesByIdPayloadSchema,
  IFetchGetPaginatedConcessionairesProfilesByIdPayloadSchema,
  PaginatedConcessionairesProfilesDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedConcessionairesProfilesById = async (
  payload: IFetchGetPaginatedConcessionairesProfilesByIdPayloadSchema,
) => {
  try {
    const { concessionaireId, ...params } =
      FetchGetPaginatedConcessionairesProfilesByIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/electric-utility-profile/electric-utility/${concessionaireId}`,
      {
        params,
      },
    );

    const parsedData = PaginatedConcessionairesProfilesDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
