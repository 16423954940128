import { CustomVariableDTOSchema } from "./CustomVariableDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCustomVariableDTOSchema = PaginationSchema(
  CustomVariableDTOSchema,
);

export interface IPaginatedCustomVariableDTOSchema
  extends z.infer<typeof PaginatedCustomVariableDTOSchema> {}
