import {
  StructureFixationEnum,
  StructureSubCategoryEnum,
  StructureTypeEnum,
  StructureUnitSizeEnum,
} from "~types/enum";
import { z } from "zod";

export const StructureTypeSchema = z
  .object({
    id: z.number().int(),
    type: StructureTypeEnum.nullish().transform((val) => val ?? undefined),
    subCategory: StructureSubCategoryEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    active: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    fixation: StructureFixationEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
    size: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    unitSize: StructureUnitSizeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  })
  .partial();

export interface IStructureTypeSchema
  extends z.infer<typeof StructureTypeSchema> {}
