import { api, apiException } from "~services/api";
import { ResponsePipelineSchema } from "~types/schemas";

export const fetchGetPipedrivePipelines = async () => {
  try {
    const response = await api.get(`/pipedrive/pipelines`);

    const parsedData = ResponsePipelineSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
