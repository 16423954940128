import { DatasheetDTOSchema } from "./DatasheetDTOSchema";
import { InverterDetailDTOSchema } from "./InverterDetailDTOSchema";

import {
  GridTypeEnum,
  InverterTypeEnum,
  PhaseEnum,
  VoltageEnum,
} from "~types/enum";
import { z } from "zod";

export const InverterDTOSchema = z.object({
  id: z.number().int().gt(0),
  gridType: GridTypeEnum,
  type: InverterTypeEnum,
  model: z.string(),
  manufacturer: z.string(),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  maxPower: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  nominalPower: z.number(),
  overload: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  startupVoltage: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  maxInputVoltagePerMppt: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  outputVoltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  warrantyInYears: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  details: InverterDetailDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  datasheet: DatasheetDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IInverterDTOSchema extends z.infer<typeof InverterDTOSchema> {}
