import { CostAggregatorDTOSchema } from "./CostAggregatorDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCostAggregatorDTOSchema = PaginationSchema(
  CostAggregatorDTOSchema,
);

export interface IPaginatedCostAggregatorDTOSchema
  extends z.infer<typeof PaginatedCostAggregatorDTOSchema> {}
