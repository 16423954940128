import { AddressDTOSchema } from "./AddressDTOSchema";
import { OrganizationDTOSchema } from "./OrganizationDTOSchema";
import { UserAccountDTOSchema } from "./UserAccountDTOSchema";

import { z } from "zod";

export const PersonDTOSchema = z.object({
  id: z.number().int().gt(0),
  customId: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .min(0)
    .max(15)
    .nullish()
    .transform((val) => val ?? undefined),
  owner: UserAccountDTOSchema.nullish().transform((val) => val ?? undefined),
  organization: OrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  pipedriveId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPersonDTOSchema extends z.infer<typeof PersonDTOSchema> {}
