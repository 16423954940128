import { z } from "zod";

export const UpdateAdditionalCostTaxFormSchema = z.object({
  id: z.number().int(),
  rate: z.number(),
  identifier: z.string().optional(),
  name: z.string(),
  active: z.boolean(),
});

export interface IUpdateAdditionalCostTaxFormSchema
  extends z.infer<typeof UpdateAdditionalCostTaxFormSchema> {}
