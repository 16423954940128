import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises";
import { useFetchGetPremisesTariffKey } from "../useFetchGetPremisesTariff";
import type { IUseFetchUpdatePremisesTariff } from "./interface";

import { fetchUpdatePremisesTariff } from "~api/premises/tariff/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const UseFetchUpdatePremisesTariffKey = "api/premises/tariff/put";

export function useFetchUpdatePremisesTariff({
  options,
  displaySuccessMessage = true,
}: IUseFetchUpdatePremisesTariff) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdatePremisesTariff(payload),
    {
      ...options,
      mutationKey: UseFetchUpdatePremisesTariffKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Tarifas alteradas");
        }
        queryClient.invalidateQueries(useFetchGetPremisesKey);
        queryClient.invalidateQueries(useFetchGetPremisesTariffKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao alterar tarifas: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
