import { api, apiException } from "~services/api";
import {
  FetchDeletePersonCustomFieldPayloadSchema,
  IFetchDeletePersonCustomFieldPayloadSchema,
} from "~types/schemas";

export const fetchDeletePersonCustomField = async (
  payload: IFetchDeletePersonCustomFieldPayloadSchema,
) => {
  try {
    const { id } = FetchDeletePersonCustomFieldPayloadSchema.parse(payload);

    await api.delete(`/config/personCustomFields/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
