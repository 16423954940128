import { api, apiException } from "~services/api";

export const fetchGetComplementaryCostExcel = async () => {
  try {
    return await api.get(`complementary-cost/download-template`, {
      responseType: "blob",
    });
  } catch (error) {
    throw apiException(error);
  }
};
