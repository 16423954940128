import { api, apiException } from "~services/api";
import {
  FetchGetProposalByPipedriveDealIdPayloadSchema,
  IFetchGetProposalByPipedriveDealIdPayloadSchema,
  ResponseDealSchema,
} from "~types/schemas";

export const fetchGetProposalByPipedriveDealId = async (
  payload: IFetchGetProposalByPipedriveDealIdPayloadSchema,
) => {
  try {
    const { pipedriveDealId } =
      FetchGetProposalByPipedriveDealIdPayloadSchema.parse(payload);

    const response = await api.get(`/proposal/pipedrive/${pipedriveDealId}`);

    const parsedData = ResponseDealSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
