import { z } from "zod";

export const FetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema =
  z.object({
    provinceId: z.number().int().gt(0),
  });

export interface IFetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema
  extends z.infer<
    typeof FetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema
  > {}
