import { PaginationSchema } from "./PaginationSchema";
import { TrackingProjectDTOSchema } from "./TrackingProjectDTOSchema";

import { z } from "zod";

export const PaginatedTrackingDTOSchema = PaginationSchema(
  TrackingProjectDTOSchema,
);

export interface IPaginatedTrackingDTOSchema
  extends z.infer<typeof PaginatedTrackingDTOSchema> {}
