import { api, apiException } from "~services/api";
import {
  FetchUpdateConfigAldoDistributorIntegrationPayloadSchema,
  IFetchUpdateConfigAldoDistributorIntegrationPayloadSchema,
} from "~types/schemas";

export const fetchUpdateConfigAldoDistributorIntegration = async (
  payload: IFetchUpdateConfigAldoDistributorIntegrationPayloadSchema,
) => {
  try {
    const body =
      FetchUpdateConfigAldoDistributorIntegrationPayloadSchema.parse(payload);

    await api.put(`/distributors/aldo`, body);
  } catch (error) {
    throw apiException(error);
  }
};
