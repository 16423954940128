import { z } from "zod";

export const FetchGetPaginatedPersonNotesByPersonIdPayloadSchema = z.object({
  personId: z.number().int().gt(0),
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .lte(100)
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedPersonNotesByPersonIdPayloadSchema
  extends z.infer<typeof FetchGetPaginatedPersonNotesByPersonIdPayloadSchema> {}
