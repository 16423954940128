import { useQuery } from "react-query";

import { IUseFetchGetAutoCompletePerson } from "./interface";

import { fetchGetAutoCompletePerson } from "~api/persons/auto-complete/get";

export const useFetchGetAutoCompletePersonKey = "api/persons/auto-complete/get";

export function useFetchGetAutoCompletePerson({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAutoCompletePerson) {
  return useQuery(
    [useFetchGetAutoCompletePersonKey, dependencyArray],
    async () => await fetchGetAutoCompletePerson(payload),
    options,
  );
}
