import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { fetchGetAutoCompleteOrganizationKey } from "../useFetchGetAutoCompleteOrganization";
import { useFetchGetOrganizationByIdKey } from "../useFetchGetOrganizationById";
import { fetchGetPaginatedOrganizationKey } from "../useFetchGetPaginatedOrganization";
import { useFetchGetPersonsFromOrganizationKey } from "../useFetchGetPersonsFromOrganization";
import type { IUseFetchUpdateOrganization } from "./interface";

import { fetchUpdateOrganization } from "~api/organizations/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateOrganizationKey = "api/organizations/{id}/put";

export function useFetchUpdateOrganization({
  options,
}: IUseFetchUpdateOrganization) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateOrganization(payload),
    {
      ...options,
      mutationKey: useFetchUpdateOrganizationKey,
      onSuccess: (data, variables, context) => {
        message.success("Organização foi atualizada!");
        queryClient.invalidateQueries(fetchGetPaginatedOrganizationKey);
        queryClient.invalidateQueries(useFetchGetOrganizationByIdKey);
        queryClient.invalidateQueries(useFetchGetPersonsFromOrganizationKey);
        queryClient.invalidateQueries(fetchGetPaginatedOrganizationKey);
        queryClient.invalidateQueries(fetchGetAutoCompleteOrganizationKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar organização: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
