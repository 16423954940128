import { api, apiException } from "~services/api";
import { RuleExpressionDTO } from "~types/api/RuleExpressionDTO";
import {
  RuleExpressionDTOSchema,
  FetchCreateValidationRulePayloadSchema,
  IFetchCreateValidationRulePayloadSchema,
} from "~types/schemas";

export const fetchCreateValidationRule = async (
  payload: IFetchCreateValidationRulePayloadSchema,
) => {
  try {
    const body = FetchCreateValidationRulePayloadSchema.parse(payload);

    const response = await api.post<RuleExpressionDTO>(
      `/rule-expression`,
      body,
    );

    const parsedData = RuleExpressionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
