import { useMutation } from "react-query";

import { useFetchGetPaginatedAlternatingCurrentMaterialsKey } from "../useFetchGetPaginatedAlternatingCurrentMaterials";
import type { IUseFetchUpdateAlternatingCurrentMaterial } from "./interface";

import { fetchUpdateAlternatingCurrentMaterial } from "~api/ac-materials/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAlternatingCurrentMaterialKey =
  "api/ac-materials/{id}/put";

export function useFetchUpdateAlternatingCurrentMaterial({
  options,
}: IUseFetchUpdateAlternatingCurrentMaterial) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateAlternatingCurrentMaterial(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateAlternatingCurrentMaterialKey,
      onSuccess: (data, variables, context) => {
        message.success("Material atualizado com sucesso!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedAlternatingCurrentMaterialsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
