import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetAutoCompletePersonKey } from "../useFetchGetAutoCompletePerson";
import { useFetchGetPaginatedPersonKey } from "../useFetchGetPaginatedPerson";
import { useFetchGetPersonByIdKey } from "../useFetchGetPersonById";
import type { IUseFetchPatchTogglePersonStatus } from "./interface";

import { fetchPatchTogglePersonStatus } from "~api/persons/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchTogglePersonStatusKey = "api/persons/{id}/patch";

export function useFetchPatchTogglePersonStatus(
  options?: IUseFetchPatchTogglePersonStatus,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchPatchTogglePersonStatus(payload),
    {
      ...options,
      mutationKey: useFetchPatchTogglePersonStatusKey,
      onSuccess: (data, variables, context) => {
        message.success("Pessoa foi atualizada");
        queryClient.invalidateQueries(useFetchGetAutoCompletePersonKey);
        queryClient.invalidateQueries(useFetchGetPaginatedPersonKey);
        queryClient.invalidateQueries(useFetchGetPersonByIdKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar pessoa: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
