import { z } from "zod";

export const AdditionalCostTaxDTOSchema = z.object({
  id: z.number().int().gt(0),
  defaultTax: z.boolean(),
  rate: z.number(),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  active: z.boolean(),
});

export interface IAdditionalCostTaxDTOSchema
  extends z.infer<typeof AdditionalCostTaxDTOSchema> {}
