import { z } from "zod";

export const AldoStructureDTOSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IAldoStructureDTOSchema
  extends z.infer<typeof AldoStructureDTOSchema> {}
