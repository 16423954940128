import { z } from "zod";

export const ConversionRateInsightDataSchema = z.object({
  order: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  quantity: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  percent: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IConversionRateInsightDataSchema
  extends z.infer<typeof ConversionRateInsightDataSchema> {}
