import { ProposalStatusEnum } from "~types/enum";
import { z } from "zod";

export const FetchUpdateProposalStatusPayloadSchema = z.object({
  customId: z.number().int().gt(0),
  status: ProposalStatusEnum,
});

export interface IFetchUpdateProposalStatusPayloadSchema
  extends z.infer<typeof FetchUpdateProposalStatusPayloadSchema> {}
