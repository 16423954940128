import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldsKey } from "../useFetchGetDealCustomFields";
import type { IUseFetchCreateDealCustomField } from "./interface";

import { fetchCreateDealCustomField } from "~api/config/deals/custom-fields/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateDealCustomFieldsKey =
  "api/config/deals/custom-fields/post";

export function useFetchCreateDealCustomField({
  options,
}: IUseFetchCreateDealCustomField) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateDealCustomField(payload),
    {
      ...options,
      mutationKey: useFetchCreateDealCustomFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi criado com sucesso");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
