import { useQuery } from "react-query";

import { IUseFetchGetPaginatedAlternatingCurrentMaterials } from "./interface";

import { fetchGetPaginatedAlternatingCurrentMaterials } from "~api/ac-materials/get";

export const useFetchGetPaginatedAlternatingCurrentMaterialsKey =
  "api/ac-materials/get";

export function useFetchGetPaginatedAlternatingCurrentMaterials({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedAlternatingCurrentMaterials) {
  return useQuery(
    [useFetchGetPaginatedAlternatingCurrentMaterialsKey, dependencyArray],
    async () => await fetchGetPaginatedAlternatingCurrentMaterials(payload),
    options,
  );
}
