import { AlternatingCurrentMaterialCalcBaseEnum } from "~types/enum";
import { z } from "zod";

export const FetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema =
  z.object({
    alternatingCurrentMaterialCalcBase: AlternatingCurrentMaterialCalcBaseEnum,
  });

export interface IFetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema
  extends z.infer<
    typeof FetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema
  > {}
