import { z } from "zod";

export const ProposalHistoryResponseSchema = z.object({
  id: z.number().int().gt(0),
  fileName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  creationDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProposalHistoryResponseSchema
  extends z.infer<typeof ProposalHistoryResponseSchema> {}
