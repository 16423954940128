import { api, apiException } from "~services/api";
import {
  FetchGetPipelineByIdPayloadSchema,
  type IFetchGetPipelineByIdPayloadSchema,
  PipelineDTOSchema,
} from "~types/schemas";

export const fetchGetPipelineById = async (
  payload: IFetchGetPipelineByIdPayloadSchema,
) => {
  try {
    const { pipelineId, ...queryParams } =
      FetchGetPipelineByIdPayloadSchema.parse(payload);

    const response = await api.get(`/pipeline/${pipelineId}`, {
      params: queryParams,
    });

    const parsedData = PipelineDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
