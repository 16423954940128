import { useQuery } from "react-query";

import { IUseFetchGetDownloadDocx } from "./interface";

import { fetchGetDownloadDocx } from "~api/reports/docx/{id}/get";

export const useFetchGetDownloadDocxKey = "api/reports/docx/{id}/get";

export function useFetchGetDownloadDocx({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDownloadDocx) {
  return useQuery(
    [useFetchGetDownloadDocxKey, dependencyArray],
    async () => {
      return await fetchGetDownloadDocx(payload);
    },
    options,
  );
}
