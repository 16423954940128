import { z } from "zod";

export const guarantorNatureOccupationEnum = [
  "APOSENTADO_PENSIONISTA",
  "ASSALARIADO",
  "EMPRESARIO",
  "AUTONOMO",
  "FUNCIONARIO_PUBLICO",
  "PROFISSIONAL_LIBERAL",
] as const;

export const GuarantorNatureOccupationEnum = z
  .enum(guarantorNatureOccupationEnum)
  .catch((e) => e.input);

export type GuarantorNatureOccupationEnum = z.infer<
  typeof GuarantorNatureOccupationEnum
>;
