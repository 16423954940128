import { api, apiException } from "~services/api";
import {
  FetchDeleteTransformerRulePayloadSchema,
  IFetchDeleteTransformerRulePayloadSchema,
} from "~types/schemas";

export const fetchDeleteTransformerRule = async (
  payload: IFetchDeleteTransformerRulePayloadSchema,
) => {
  try {
    const { transformerRuleId } =
      FetchDeleteTransformerRulePayloadSchema.parse(payload);

    return await api.delete<boolean>(
      `/config/transformer/${transformerRuleId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
