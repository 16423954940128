import { z } from "zod";

export const PipedriveSolarzUserInfoDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  admin: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  imported: z
    .object({
      profileId: z
        .number()
        .int()
        .gt(0)
        .nullish()
        .transform((val) => val ?? undefined),
      userId: z
        .number()
        .int()
        .gt(0)
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPipedriveSolarzUserInfoDTOSchema
  extends z.infer<typeof PipedriveSolarzUserInfoDTOSchema> {}
