import { useQuery } from "react-query";

import type { IFetchGetElectricUtilityProfileConsumerClass } from "./interface";

import { fetchGetElectricUtilityProfileConsumerClass } from "~api/electric-utility-profile/consumer-class/get";

export const useFetchGetElectricUtilityProfileConsumerClassKey =
  "api/electric-utility-profile/consumer-class/get";

export function useFetchGetElectricUtilityProfileConsumerClass({
  dependencyArray = [],
  options,
}: IFetchGetElectricUtilityProfileConsumerClass) {
  return useQuery(
    [useFetchGetElectricUtilityProfileConsumerClassKey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileConsumerClass(),
    options,
  );
}
