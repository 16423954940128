import { EosClientResponseSchema } from "./EosClientResponseSchema";

import { z } from "zod";

export const EosProposalResponseSchema = z.object({
  protocolo: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  status: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  carencia: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  parcela: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  valorTotal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  entrada: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cliente: EosClientResponseSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IEosProposalResponseSchema
  extends z.infer<typeof EosProposalResponseSchema> {}
