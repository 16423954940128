import { api, apiException } from "~services/api";
import {
  FetchDeleteAdditionalCostsPayloadSchema,
  IFetchDeleteAdditionalCostsPayloadSchema,
} from "~types/schemas";

export const fetchDeleteComplementaryItemsOfSettings = async (
  payload: IFetchDeleteAdditionalCostsPayloadSchema,
) => {
  try {
    const { additionalCostsId } =
      FetchDeleteAdditionalCostsPayloadSchema.parse(payload);

    return await api.delete<void>(`/additional-costs/${additionalCostsId}`);
  } catch (error) {
    throw apiException(error);
  }
};
