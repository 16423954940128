import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedProductInverters } from "./interface";

import { fetchGetPaginatedProductInverters } from "~api/product/search/inverter/get";

export const useFetchGetPaginatedProductInvertersKey =
  "api/product/search/inverter/get";

export function useFetchGetPaginatedProductInverters({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedProductInverters) {
  return useQuery(
    [useFetchGetPaginatedProductInvertersKey, dependencyArray],
    async () => await fetchGetPaginatedProductInverters(payload),
    options,
  );
}
