import { z } from "zod";

export const UpdateProposalPaymentPayloadSchema = z.object({
  name: z.string(),
  monthlyInterestRate: z.number(),
  installments: z.number().int(),
  downPayment: z.number(),
});

export interface IUpdateProposalPaymentPayloadSchema
  extends z.infer<typeof UpdateProposalPaymentPayloadSchema> {}
