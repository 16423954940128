import { api, apiException } from "~services/api";
import { PaginatedActivityDTOSchema } from "~types/schemas";
import {
  FetchGetPaginatedActivityByPeriodPayloadSchema,
  type IFetchGetPaginatedActivityByPeriodPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedActivityByPeriod = async (
  payload?: IFetchGetPaginatedActivityByPeriodPayloadSchema,
) => {
  try {
    const { page, size, ...params } =
      FetchGetPaginatedActivityByPeriodPayloadSchema.parse(payload);

    const response = await api.get(
      `/activities/period/page/${page}/size/${size}`,
      {
        params,
      },
    );

    const parsedData = PaginatedActivityDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
