import { api, apiException } from "~services/api";
import {
  FetchDeleteProfilePayloadSchema,
  IFetchDeleteProfilePayloadSchema,
} from "~types/schemas";

export const fetchDeleteProfile = async (
  payload: IFetchDeleteProfilePayloadSchema,
) => {
  try {
    const { profileId } = FetchDeleteProfilePayloadSchema.parse(payload);

    await api.delete(`/profiles/${profileId}`);
  } catch (error) {
    throw apiException(error);
  }
};
