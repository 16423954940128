import { useMutation } from "react-query";

import { useFetchGetAllDistributorsKey } from "../../product/useFetchGetAllDistributors";
import { useFetchGetPaginatedInvertersKey } from "../../product/useFetchGetPaginatedInverters";
import { useFetchGetPaginatedKitsKey } from "../../product/useFetchGetPaginatedKits";
import { useFetchGetPaginatedModulesKey } from "../../product/useFetchGetPaginatedModules";
import { useFetchGetPaginatedStructuresKey } from "../../product/useFetchGetPaginatedStructures";
import type { IUseFetchUpdateProductsQueue } from "./interface";

import { fetchImportProductQueue } from "~api/product-queue/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchImportProductQueueKey = "api/product-queue/post";

export function useFetchImportProductQueue({
  options,
}: IUseFetchUpdateProductsQueue) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchImportProductQueue(payload),
    {
      ...options,
      mutationKey: useFetchImportProductQueueKey,
      onSuccess: (data, variables, context) => {
        message.success("Dados enviados para importação!");
        queryClient.invalidateQueries(useFetchGetPaginatedInvertersKey);
        queryClient.invalidateQueries(useFetchGetAllDistributorsKey);
        queryClient.invalidateQueries(useFetchGetPaginatedModulesKey);
        queryClient.invalidateQueries(useFetchGetPaginatedStructuresKey);
        queryClient.invalidateQueries(useFetchGetPaginatedKitsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao enviar dados para importação: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
