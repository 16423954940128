import { ProposalStepEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetCustomFieldsSectionsByProposalStepPayloadSchema = z.object(
  {
    proposalStep: ProposalStepEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  },
);

export interface IFetchGetCustomFieldsSectionsByProposalStepPayloadSchema
  extends z.infer<
    typeof FetchGetCustomFieldsSectionsByProposalStepPayloadSchema
  > {}
