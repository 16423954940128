import { useQuery } from "react-query";

import type { IUseFetchGetProposalByPipedriveDealId } from "./interface";

import { fetchGetProposalByPipedriveDealId } from "~api/proposal/pipedrive/{pipedriveDealId}/get";

export const useFetchGetProposalByPipedriveDealIdKey =
  "api/proposal/pipedrive/{pipedriveDealId}/get";

export function useFetchGetProposalByPipedriveDealId({
  options,
  dependencyArray,
  payload = {
    pipedriveDealId: 0,
  },
}: IUseFetchGetProposalByPipedriveDealId) {
  return useQuery(
    [useFetchGetProposalByPipedriveDealIdKey, dependencyArray],
    async () => await fetchGetProposalByPipedriveDealId(payload),
    options,
  );
}
