import { z } from "zod";

export const SellerResponseSchema = z.object({
  email: z.string().email(),
  commissionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ISellerResponseSchema
  extends z.infer<typeof SellerResponseSchema> {}
