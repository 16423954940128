import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFields } from "./interface";

import { fetchGetDealCustomFields } from "~api/config/deals/custom-fields/get";

export const useFetchGetDealCustomFieldsKey =
  "api/config/deals/custom-fields/get";

export function useFetchGetDealCustomFields({
  dependencyArray = [],
  options,
}: IUseFetchGetDealCustomFields) {
  return useQuery(
    [useFetchGetDealCustomFieldsKey, dependencyArray],
    async () => {
      const data = await fetchGetDealCustomFields();

      const sortedData = [...data].sort(
        (a, b) => (a.displayOrder ?? 999999) - (b.displayOrder ?? 999999),
      );

      return sortedData;
    },
    options,
  );
}
