import { api, apiException } from "~services/api";
import {
  MessageDTOSchema,
  FetchDeleteUserFromCompanyByUserIdPayloadSchema,
  IFetchDeleteUserFromCompanyByUserIdPayloadSchema,
} from "~types/schemas";

export const fetchDeleteUserFromCompanyByUserId = async (
  payload: IFetchDeleteUserFromCompanyByUserIdPayloadSchema,
) => {
  try {
    const path = FetchDeleteUserFromCompanyByUserIdPayloadSchema.parse(payload);

    const response = await api.delete(`/company/users/remove/${path.userId}`);

    const parsedData = MessageDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
