import { useQuery } from "react-query";

import type { IUseFetchGetOrganizationDefaultFieldSettings } from "./interface";

import { fetchGetOrganizationDefaultFieldSettings } from "~api/config/organizations/default-fields/settings/get";

export const useFetchGetOrganizationDefaultFieldSettingsKey =
  "api/config/organizations/default-fields/settings/get";

export function useFetchGetOrganizationDefaultFieldSettings({
  dependencyArray = [],
  options,
}: IUseFetchGetOrganizationDefaultFieldSettings) {
  return useQuery(
    [useFetchGetOrganizationDefaultFieldSettingsKey, dependencyArray],
    async () => await fetchGetOrganizationDefaultFieldSettings(),
    options,
  );
}
