import { api, apiException } from "~services/api";
import {
  AlternatingCurrentMaterialCalcBaseDTOSchema,
  IFetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema,
  FetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema,
} from "~types/schemas";

export const fetchUpdateAlternatingCurrentMaterialCalcBase = async (
  payload: IFetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema,
) => {
  try {
    const body =
      FetchUpdateAlternatingCurrentMaterialCalcBasePayloadSchema.parse(payload);

    const response = await api.put(`/ac-materials/calcBase`, body);

    const parsedData = AlternatingCurrentMaterialCalcBaseDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
