import { api, apiException } from "~services/api";
import {
  SimultaneityProfileDTOSchema,
  FetchUpdateSimultaneityProfilePayloadSchema,
  IFetchUpdateSimultaneityProfilePayloadSchema,
} from "~types/schemas";

export const fetchUpdateSimultaneityProfile = async (
  payload: IFetchUpdateSimultaneityProfilePayloadSchema,
) => {
  try {
    const { simultaneityProfileId, ...body } =
      FetchUpdateSimultaneityProfilePayloadSchema.parse(payload);

    const response = await api.put(
      `/simultaneityProfiles/${simultaneityProfileId}`,
      body,
    );

    const parsedData = SimultaneityProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
