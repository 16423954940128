import { DatasheetRevisionSummaryDTOSchema } from "./DatasheetRevisionSummaryDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedDatasheetRevisionSummaryDTOSchema = PaginationSchema(
  DatasheetRevisionSummaryDTOSchema,
);

export interface IPaginatedDatasheetRevisionSummaryDTOSchema
  extends z.infer<typeof PaginatedDatasheetRevisionSummaryDTOSchema> {}
