import { useQuery } from "react-query";

import { IUseFetchGetPipedriveLeadType } from "./interface";

import { fetchGetPipedriveLeadType } from "~api/company/pipedrive-lead-type/get";

export const useFetchGetPipedriveLeadTypeKey =
  "api/company/pipedrive-lead-type/get";

export function useFetchGetPipedriveLeadType({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedriveLeadType) {
  return useQuery(
    [useFetchGetPipedriveLeadTypeKey, dependencyArray],
    async () => {
      return await fetchGetPipedriveLeadType();
    },
    options,
  );
}
