import { DefaultFieldProfileSettingsFormSchema } from "./DefaultFieldProfileSettingsFormSchema";

import { z } from "zod";
export const FetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema =
  z.object({
    formFieldId: z.number().int().gt(0),
    settings: z
      .array(DefaultFieldProfileSettingsFormSchema)
      .nullish()
      .transform((val) => val ?? []),
  });

export interface IFetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema
  extends z.infer<
    typeof FetchUpdateProposalDefaultFieldsProfileSettingsPayloadSchema
  > {}
