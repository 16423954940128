import { api, apiException } from "~services/api";
import {
  FetchGetReasonForLossByIdPayloadSchema,
  ReasonForLossResponseSchema,
  type IFetchGetReasonForLossByIdPayloadSchema,
} from "~types/schemas";

export const fetchGetReasonForLossById = async (
  payload: IFetchGetReasonForLossByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetReasonForLossByIdPayloadSchema.parse(payload);

    const response = await api.get(`/reason-for-loss/${id}`);

    const parsedData = ReasonForLossResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
