import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedSysAdminUser } from "./interface";

import { fetchGetPaginatedSysAdminUser } from "~api/sys-admin-management/all/get";

export const useFetchGetPaginatedSysAdminUserKey =
  "api/sys-admin-management/all";

export function useFetchGetPaginatedSysAdminUser({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedSysAdminUser) {
  return useQuery(
    [useFetchGetPaginatedSysAdminUserKey, dependencyArray],
    async () => await fetchGetPaginatedSysAdminUser(payload),
    options,
  );
}
