import { api, apiException } from "~services/api";

export type FetchUnblockUser = {
  path: {
    id: number;
  };
};

export const fetchUnblockUser = async ({ path }: FetchUnblockUser) => {
  try {
    await api.patch<void>(`/admin/users/${path.id}/unblock`);
  } catch (error) {
    throw apiException(error);
  }
};
