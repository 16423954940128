import { api, apiException } from "~services/api";
import {
  FetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema,
  IFetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema,
} from "~types/schemas";

export const fetchUpdateProposalCustomFieldsProfileSettings = async (
  payload: IFetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema,
) => {
  try {
    const { customFieldId, settings } =
      FetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema.parse(
        payload,
      );

    await api.put(`/fields/hideable/custom-field/${customFieldId}`, settings);
  } catch (error) {
    throw apiException(error);
  }
};
