import { CostAggregatorFormSchema } from "./CostAggregatorFormSchema";

import { z } from "zod";

export const FetchUpdateCostAggregatorPayloadSchema =
  CostAggregatorFormSchema.extend({
    costAggregatorId: z.number().int().gt(0),
  });

export interface IFetchUpdateCostAggregatorPayloadSchema
  extends z.infer<typeof FetchUpdateCostAggregatorPayloadSchema> {}
