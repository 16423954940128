import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductInverterPowersPayloadSchema,
  IFetchGetPaginatedProductInverterPowersPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetPaginatedProductInverterPowers = async (
  payload: IFetchGetPaginatedProductInverterPowersPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductInverterPowersPayloadSchema.parse(payload);

    const response = await api.get(`/product/search/inverter/powerList`, {
      params,
    });

    const parsedData = z.array(z.number()).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
