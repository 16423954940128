import { api, apiException } from "~services/api";
import {
  FetchGetDealCustomFieldGroupByIdPayloadSchema,
  type IFetchGetDealCustomFieldGroupByIdPayloadSchema,
  DealCustomFieldGroupDTOSchema,
} from "~types/schemas";

export const fetchGetDealCustomFieldGroupById = async (
  payload: IFetchGetDealCustomFieldGroupByIdPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchGetDealCustomFieldGroupByIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/config/deals/custom-fields/groups/${id}`,
      body,
    );

    const parsedData = DealCustomFieldGroupDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
