import { useMutation } from "react-query";

import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "../useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "../useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetRemainingPercentageKey } from "../useFetchGetRemainingPercentage";
import type { IUseFetchCreateCopyComplementaryItems } from "./interface";

import { fetchCreateCopyComplementaryItems } from "~api/additional-costs/{id}/copy/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateCopyComplementaryItemsKey =
  "api/additional-costs/{id}/copy/post";

export function useFetchCreateCopyComplementaryItems({
  options,
}: IUseFetchCreateCopyComplementaryItems) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateCopyComplementaryItems(payload),
    {
      ...options,
      mutationKey: useFetchCreateCopyComplementaryItemsKey,
      onSuccess: (data, variables, context) => {
        message.success("Copia foi criada");
        queryClient.invalidateQueries(
          useFetchGetPaginatedComplementaryItemsOfSettingsKey,
        );
        queryClient.invalidateQueries(
          useFetchGetPaginatedAdditionalCostsAuxVariableKey,
        );
        queryClient.invalidateQueries(useFetchGetRemainingPercentageKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao copiar, ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
