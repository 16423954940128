import { z } from "zod";

export const MonthlyValuesObjectSchema = z.object({
  january: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  february: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  march: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  april: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  may: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  june: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  july: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  august: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  september: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  october: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  november: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  december: z
    .number()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IMonthlyValuesObjectSchema
  extends z.infer<typeof MonthlyValuesObjectSchema> {}
