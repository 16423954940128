import { BaseCalculationEnum } from "~types/enum";
import { z } from "zod";

export const UpdateProjectPaymentSimulationOptionFormSchema = z.object({
  id: z.number().int().gte(0), // YES, IT CAN BE ZERO!
  name: z.string(),
  projectPaymentSimulationOptionId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  installments: z.number().int().lte(240),
  monthlyInterestRate: z.number().gte(0).lte(80),
  downPaymentPercentage: z
    .number()
    .lte(100)
    .nullish()
    .transform((val) => val ?? undefined),
  downPaymentFixedValue: z
    .number()
    .lte(100)
    .nullish()
    .transform((val) => val ?? undefined),
  baseCalculation: BaseCalculationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IUpdateProjectPaymentSimulationOptionFormSchema
  extends z.infer<typeof UpdateProjectPaymentSimulationOptionFormSchema> {}
