import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedConcessionaireProfilesById } from "./interface";

import { fetchGetPaginatedConcessionairesProfilesById } from "~api/electric-utility-profile/electric-utility/{id}/get";

export const useFetchGetPaginatedConcessionaireProfilesKey =
  "api/electric-utility-profile/electric-utility/{id}/get";

export function useFetchGetPaginatedConcessionaireProfilesById({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 5,
    concessionaireId: 0,
  },
}: IUseFetchGetPaginatedConcessionaireProfilesById) {
  return useQuery(
    [useFetchGetPaginatedConcessionaireProfilesKey, dependencyArray],
    async () => await fetchGetPaginatedConcessionairesProfilesById(payload),
    options,
  );
}
