import { PaginationSchema } from "./PaginationSchema";
import { ProposalResponseSchema } from "./ProposalResponseSchema";

import { z } from "zod";

export const PaginatedProposalResponseSchema = PaginationSchema(
  ProposalResponseSchema,
);

export interface IPaginatedProposalResponseSchema
  extends z.infer<typeof PaginatedProposalResponseSchema> {}
