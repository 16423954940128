import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetBusinessUnitUsersKey } from "../useFetchGetBusinessUnitUsers";
import { useFetchGetBusinessUnitUsersOutKey } from "../useFetchGetBusinessUnitUsersOut";
import { useFetchGetUserBusinessUnitsKey } from "../useFetchGetUserBusinessUnits";
import { useFetchGetUserOutBusinessUnitKey } from "../useFetchGetUserOutBusinessUnit";
import type { IUseFetchAssignBusinessUnitToUser } from "./interface";

import { fetchAssignBusinessUnitToUser } from "~api/business-units/{id}/add/{id}/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const fetchAssignBusinessUnitToUserKey =
  "api/business-units/{id}/add/{id}/post";

export function useFetchAssignBusinessUnitToUser(
  options?: IUseFetchAssignBusinessUnitToUser,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchAssignBusinessUnitToUser(payload),
    {
      ...options,
      mutationKey: fetchAssignBusinessUnitToUserKey,
      onSuccess: (data, variables, context) => {
        message.success("Usuário foi vinculado à unidade de negócio!");
        queryClient.invalidateQueries(useFetchGetBusinessUnitUsersKey);
        queryClient.invalidateQueries(useFetchGetBusinessUnitUsersOutKey);
        queryClient.invalidateQueries(useFetchGetUserOutBusinessUnitKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao vincular usuário: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
