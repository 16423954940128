import { useQuery } from "react-query";

import { IUseFetchGetPaginatedAdditionalCostMarginType } from "./interface";

import { fetchGetPaginatedAdditionalCostMarginType } from "~api/additional-cost-margin-type/get";

export const UseFetchGetPaginatedAdditionalCostMarginTypeKey =
  "api/additional-cost-margin-type/get";

export function useFetchGetPaginatedAdditionalCostMarginType({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedAdditionalCostMarginType) {
  return useQuery(
    [UseFetchGetPaginatedAdditionalCostMarginTypeKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedAdditionalCostMarginType(payload);
    },
    options,
  );
}
