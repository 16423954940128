import { useMutation } from "react-query";

import { useFetchGetEosFinancialProposalDocumentsKey } from "../useFetchGetEosFinancialProposalDocuments";
import type { IUseFetchUploadDocumentEosFinancing } from "./interface";

import { fetchUploadDocumentEosFinancing } from "~api/eosfin/proposals/{protocol}/documents/{documentType}/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUploadDocumentEosFinancingKey =
  "api/eosfin/proposals/{protocol}/documents/{documentType}/post";

export function useFetchUploadDocumentEosFinancing({
  options,
}: IUseFetchUploadDocumentEosFinancing) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUploadDocumentEosFinancing(payload),
    {
      ...options,
      mutationKey: useFetchUploadDocumentEosFinancingKey,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(
          useFetchGetEosFinancialProposalDocumentsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao fazer upload: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
