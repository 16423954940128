import { z } from "zod";

export const FetchGetPaginatedPersonPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 5),
  query: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  userId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  orgId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedPersonPayloadSchema
  extends z.infer<typeof FetchGetPaginatedPersonPayloadSchema> {}
