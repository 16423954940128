import { UpdatePersonCustomFieldOrderFormSchema } from "./UpdatePersonCustomFieldOrderFormSchema";

import { z } from "zod";

export const FetchUpdatePersonCustomFieldOrderFormPayloadSchema = z.array(
  UpdatePersonCustomFieldOrderFormSchema,
);

export interface IFetchUpdatePersonCustomFieldOrderFormPayloadSchema
  extends z.infer<typeof FetchUpdatePersonCustomFieldOrderFormPayloadSchema> {}
