import { api, apiException } from "~services/api";
import {
  ConsultantCompanyFilterPayloadSchema,
  IFetchGetPaginatedConsultantPayloadSchema,
  PaginatedConsultantCompanyDTOSchema,
} from "~types/schemas";

export const fetchPostToGetPaginatedConsultantCompanies = async (
  payload: IFetchGetPaginatedConsultantPayloadSchema,
) => {
  try {
    const body = ConsultantCompanyFilterPayloadSchema.parse(payload);

    const response = await api.post(`/consultants/companies/pageable`, body);

    const parsedData = PaginatedConsultantCompanyDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
