import { useQuery } from "react-query";

import { IUseFetchGetPaginatedTracking } from "./interface";

import { fetchGetPaginatedTracking } from "~api/tracking/custom-id/{id}/get";

export const useFetchGetPaginatedTrackingKey =
  "api/tracking/custom-id/{id}/get";

export function useFetchGetPaginatedTracking({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPaginatedTracking) {
  return useQuery(
    [useFetchGetPaginatedTrackingKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedTracking(payload);
    },
    options,
  );
}
