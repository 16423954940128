import { useMutation } from "react-query";

import type { IUseFetchUpdateBaseAddress } from "./interface";

import { fetchUpdateBaseAddress } from "~api/company/addresses/{id}/put";
import { useFetchGetCompanyAddressesKey } from "~hooks/api/company/useFetchGetCompanyAddresses";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateBaseAddressKey = "api/company/addresses/{id}/put";

export function useFetchUpdateBaseAddress({
  options,
}: IUseFetchUpdateBaseAddress) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdateBaseAddress(payload), {
    ...options,
    mutationKey: useFetchUpdateBaseAddressKey,
    onSuccess: (data, variables, context) => {
      message.success("Endereço alterado com sucesso!");
      queryClient.invalidateQueries(useFetchGetCompanyAddressesKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
