import { UpdateFormFieldSettingsFormSchema } from "./UpdateFormFieldSettingsFormSchema";

import { z } from "zod";

export const FetchUpdateDefaultFieldPayloadSchema =
  UpdateFormFieldSettingsFormSchema.extend({
    id: z.number().int().gt(0),
  });

export interface IFetchUpdateDefaultFieldPayloadSchema
  extends z.infer<typeof FetchUpdateDefaultFieldPayloadSchema> {}
