import { api, apiException } from "~services/api";
import {
  type IFetchGetPaginatedAdminModulesPayloadSchema,
  FetchGetPaginatedAdminModulesPayloadSchema,
  PaginatedModuleSummaryDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedAdminModules = async (
  payload: IFetchGetPaginatedAdminModulesPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetPaginatedAdminModulesPayloadSchema.parse(payload);

    const response = await api.get(`/admin/modules`, {
      params: queryParams,
    });

    const parsedData = PaginatedModuleSummaryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
