import { useQuery } from "react-query";

import type { IUseFetchGetDealCustomFieldsGroupList } from "./interface";

import { fetchGetDealCustomFieldsGroupList } from "~api/config/deals/custom-fields/groups/list/get";

export const useFetchGetDealCustomFieldsGroupListKey =
  "api/config/deals/custom-fields/groups/list/get";

export function useFetchGetDealCustomFieldsGroupList({
  dependencyArray = [],
  options,
}: IUseFetchGetDealCustomFieldsGroupList) {
  return useQuery(
    [useFetchGetDealCustomFieldsGroupListKey, dependencyArray],
    async () => await fetchGetDealCustomFieldsGroupList(),
    options,
  );
}
