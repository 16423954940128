import { LostMotiveInsightDataSchema } from "./LostMotiveInsightDataSchema";

import { z } from "zod";

export const LostMotiveInsightDTOSchema = z.object({
  data: LostMotiveInsightDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  dealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ILostMotiveInsightDTOSchema
  extends z.infer<typeof LostMotiveInsightDTOSchema> {}
