import { useMutation } from "react-query";

import { useFetchGetPaginatedOtherProductsKey } from "../useFetchGetPaginatedOtherProducts";
import type { IUseFetchUpdateProductTypeOther } from "./interface";

import { fetchUpdateProductTypeOther } from "~api/product/other/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProductTypeOtherKey = "api/product/other/{id}/put";

export function useFetchUpdateProductTypeOther({
  options,
}: IUseFetchUpdateProductTypeOther) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProductTypeOther(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProductTypeOtherKey,
      onSuccess: (data, variables, context) => {
        message.success("Produto foi atualizado");
        queryClient.invalidateQueries(useFetchGetPaginatedOtherProductsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar produto: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
