import { api, apiException } from "~services/api";
import {
  FetchLinkPipedrivePayloadSchema,
  type IFetchLinkPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchLinkPipedrive = async (
  payload: IFetchLinkPipedrivePayloadSchema,
) => {
  try {
    const { companyId } = FetchLinkPipedrivePayloadSchema.parse(payload);

    await api.patch<void>(`/admin/users/${companyId}/link-pipedrive`);
  } catch (error) {
    throw apiException(error);
  }
};
