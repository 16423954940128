import { api, apiException } from "~services/api";
import {
  FetchDeleteReportTemplatePayloadSchema,
  IFetchDeleteReportTemplatePayloadSchema,
} from "~types/schemas";

export const fetchDeleteReportTemplateById = async (
  payload: IFetchDeleteReportTemplatePayloadSchema,
) => {
  try {
    const { reportTemplateId } =
      FetchDeleteReportTemplatePayloadSchema.parse(payload);

    await api.delete(`/reports/${reportTemplateId}`);
  } catch (error) {
    throw apiException(error);
  }
};
