import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetProfileImageKey } from "../useFetchGetProfileImage";
import type { IUseFetchDeleteProfileImage } from "./interface";

import { fetchDeleteProfileImage } from "~api/users/avatar/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteProfileImageKey = "api/users/avatar/delete";

export function useFetchDeleteProfileImage({
  options,
}: IUseFetchDeleteProfileImage) {
  const { message } = useAppConfig();

  return useMutation(async () => await fetchDeleteProfileImage(), {
    ...options,
    mutationKey: useFetchDeleteProfileImageKey,
    onSuccess: (data, variables, context) => {
      message.success("Foto do perfil foi removida!");
      queryClient.invalidateQueries(useFetchGetProfileImageKey);
      queryClient.invalidateQueries(useFetchGetSessionDataKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
