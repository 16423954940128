import { useQuery } from "react-query";

import { IUseFetchGetCalculationScaleTypesComplementaryItems } from "./interface";

import { fetchGetCalculationTypeComplementaryItems } from "~api/additional-costs/advanced/calculationScaleTypes/{calculationType}/get";

export const useFetchGetCalculationScaleTypesComplementaryItemsKey =
  "api/additional-costs/advanced/calculationScaleTypes/{calculationType}/get";

export function useFetchGetCalculationScaleTypesComplementaryItems({
  dependencyArray = [],
  options,
  payload = {
    calculationType: "PERCENTUAL",
  },
}: IUseFetchGetCalculationScaleTypesComplementaryItems) {
  return useQuery(
    [useFetchGetCalculationScaleTypesComplementaryItemsKey, dependencyArray],
    async () => {
      return await fetchGetCalculationTypeComplementaryItems({
        calculationType: payload.calculationType,
      });
    },
    options,
  );
}
