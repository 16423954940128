import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedDistributors } from "./interface";

import { fetchGetPaginatedDistributors } from "~api/distributors/get";

export const useFetchGetPaginatedDistributorsKey = "api/distributors/get";

export function useFetchGetPaginatedDistributors({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedDistributors) {
  return useQuery(
    [useFetchGetPaginatedDistributorsKey, dependencyArray],
    async () => await fetchGetPaginatedDistributors(payload),
    options,
  );
}
