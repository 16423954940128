import { DealCustomFieldValueSchema } from "./DealCustomFieldValueSchema";

import { z } from "zod";

export const FetchUpdateDealCustomFieldValuePayloadSchema = z.object({
  id: z.number().int().gt(0),
  customFieldValues: z
    .array(DealCustomFieldValueSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IFetchUpdateDealCustomFieldValuePayloadSchema
  extends z.infer<typeof FetchUpdateDealCustomFieldValuePayloadSchema> {}
