import { api, apiException } from "~services/api";
import {
  FetchCreatePremisesTariffPayloadSchema,
  IFetchCreatePremisesPayloadSchema,
  TariffPremiseDTOSchema,
} from "~types/schemas";

export const fetchCreatePremisesTariff = async (
  payload: IFetchCreatePremisesPayloadSchema,
) => {
  try {
    const body = FetchCreatePremisesTariffPayloadSchema.parse(payload);

    const response = await api.post(`/premises/tariff`, body);

    const parsedData = TariffPremiseDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
