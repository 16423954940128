import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises";
import type { IUseFetchCreatePremises } from "./interface";

import { fetchCreatePremises } from "~api/premises/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePremisesKey = "api/premises/post";

export function useFetchCreatePremises({
  options,
  displaySuccessMessage = true,
}: IUseFetchCreatePremises) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreatePremises(payload), {
    ...options,
    mutationKey: useFetchCreatePremisesKey,
    onSuccess: (data, variables, context) => {
      if (displaySuccessMessage) {
        message.success("Premissas criadas");
      }
      queryClient.invalidateQueries(useFetchGetPremisesKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar premissa: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
