import { SyncLogDTOSchema } from "./SyncLogDTOSchema";

import { z } from "zod";

export const AdminDistributorsDTOSchema = z.object({
  id: z
    .number({ message: "ID do distribuidor é obrigatório!" })
    .int({ message: "ID do distribuidor deve ser um tipo inteiro" })
    .gt(0, { message: "ID do distribuidor deve ser maior que zero" }),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  companiesQuantity: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  syncLog: SyncLogDTOSchema.nullish().transform((val) => val ?? undefined),
});

export interface IAdminDistributorsDTOSchema
  extends z.infer<typeof AdminDistributorsDTOSchema> {}
