import { AddressFormSchema } from "./AddressFormSchema";

import { z } from "zod";

export const PersonFormSchema = z.object({
  name: z.string(),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  organizationId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  responsibleSellerId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  address: AddressFormSchema.nullish().transform((val) => val ?? undefined),
  pipedriveId: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPersonFormSchema extends z.infer<typeof PersonFormSchema> {}
