import { ModulePhaseEnum } from "~types/enum";
import { z } from "zod";

export const ModuleSummaryDTOSchema = z.object({
  id: z
    .number({ message: "Faltando o ID do módulo" })
    .int({ message: "ID do módulo deve ser um inteiro" })
    .gt(0, { message: "ID do módulo deve ser maior que zero" }),
  model: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  manufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  surfaceType: ModulePhaseEnum.nullish().transform((val) => val ?? undefined),
  powerWp: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  datasheetId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IModuleSummaryDTOSchema
  extends z.infer<typeof ModuleSummaryDTOSchema> {}
