import { ConsultantUserDTOSchema } from "./ConsultantUserDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedConsultantUserDTOSchema = PaginationSchema(
  ConsultantUserDTOSchema,
);

export interface IPaginatedConsultantUserDTOSchema
  extends z.infer<typeof PaginatedConsultantUserDTOSchema> {}
