import { useQuery } from "react-query";

import type { IUseFetchGetProposalPDFFile } from "./interface";

import { fetchGetProposalPDFFile } from "~api/proposal/file/{id}/get";

export const useFetchGetProposalPDFFileKey = "api/proposal/file/{id}/get";

export function useFetchGetProposalPDFFile({
  options,
  dependencyArray,
  payload = {
    id: 0,
  },
}: IUseFetchGetProposalPDFFile) {
  return useQuery(
    [useFetchGetProposalPDFFileKey, dependencyArray],
    async () => await fetchGetProposalPDFFile(payload),
    options,
  );
}
