import { useMutation } from "react-query";

import { useFetchGetPaginatedBusinessUnitsKey } from "../useFetchGetPaginatedBusinessUnits";
import { useFetchGetUserBusinessUnitsKey } from "../useFetchGetUserBusinessUnits";
import type { IUseFetchDeactivateBusinessUnit } from "./interface";

import { fetchDeactivateBusinessUnit } from "~api/business-units/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeactivateBusinessUnitKey =
  "api/business-units/{id}/delete";

export function useFetchDeactivateBusinessUnit(
  options?: IUseFetchDeactivateBusinessUnit,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeactivateBusinessUnit(payload),
    {
      ...options,
      mutationKey: useFetchDeactivateBusinessUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidade desativada!");
        queryClient.invalidateQueries(useFetchGetPaginatedBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
