import { PhaseEnum, StructureFixationEnum, VoltageEnum } from "~types/enum";
import { z } from "zod";

export const CustomKitDTOSchema = z.object({
  id: z.number().int().gt(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  distributor: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  inverterBrand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  pvModuleBrand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  available: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  powerKwp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  code: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  voltage: VoltageEnum.nullish().transform((val) => val ?? undefined),
  phase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  structure: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  structureFixation: StructureFixationEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  priceBaseCents: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  customInvertersCount: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  customPvModulesCount: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  totalPriceCents: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  potenciaTotal: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  inverterTotalPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  areaMinima: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICustomKitDTOSchema
  extends z.infer<typeof CustomKitDTOSchema> {}
