import { VoltageEnum } from "~types/enum";
import { z } from "zod";

export const CreateTransformerAdditionalCostFormSchema = z.object({
  inputVoltage: VoltageEnum,
  outputVoltage: VoltageEnum,
});

export interface ICreateTransformerAdditionalCostFormSchema
  extends z.infer<typeof CreateTransformerAdditionalCostFormSchema> {}
