import { useMutation } from "react-query";

import { useFetchGetAdditionalCostTaxesKey } from "../useFetchGetAdditionalCostTaxes";
import type { IUseFetchSetDefaultAdditionalCostTax } from "./interface";

import { fetchSetDefaultAdditionalCostTax } from "~api/config/additionalCostTax/setDefault/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetDefaultAdditionalCostTaxKey =
  "api/config/additionalCostTax/setDefault/post";

export function useFetchSetDefaultAdditionalCostTax({
  options,
}: IUseFetchSetDefaultAdditionalCostTax) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchSetDefaultAdditionalCostTax(payload),
    {
      ...options,
      mutationKey: useFetchSetDefaultAdditionalCostTaxKey,
      onSuccess: (data, variables, context) => {
        message.success("Alíquota foi marcada como padrão");
        queryClient.invalidateQueries(useFetchGetAdditionalCostTaxesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
