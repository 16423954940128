import { api, apiException } from "~services/api";
import { NotificationDataDTOSchema } from "~types/schemas";

export const fetchGetNotificationStatus = async () => {
  try {
    const response = await api.get(`/notifications/status`);

    const parsedData = NotificationDataDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
