import { PermissionTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetAllPermissionsPayloadSchema = z
  .object({
    permissionType: PermissionTypeEnum.nullish().transform(
      (val) => val ?? undefined,
    ),
  })
  .partial()
  .nullish()
  .transform((val) => val ?? {});

export interface IFetchGetAllPermissionsPayloadSchema
  extends z.infer<typeof FetchGetAllPermissionsPayloadSchema> {}
