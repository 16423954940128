import { RuleExpressionFormSchema } from "./RuleExpressionFormSchema";

import { z } from "zod";

export const FetchUpdateValidationRulePayloadSchema =
  RuleExpressionFormSchema.extend({
    validationRuleId: z.number().int().gt(0),
  });

export interface IFetchUpdateValidationRulePayloadSchema
  extends z.infer<typeof FetchUpdateValidationRulePayloadSchema> {}
