import { api, apiException } from "~services/api";
import {
  // DealDTOSchema,
  FetchCreateDealPayloadSchema,
  IFetchCreateDealPayloadSchema,
} from "~types/schemas";

export const fetchCreateDeal = async (
  payload: IFetchCreateDealPayloadSchema,
) => {
  try {
    const body = FetchCreateDealPayloadSchema.parse(payload);

    await api.post(`/deal`, body);
    // const response = await api.post(`/deal`, body);

    // const parsedData = DealDTOSchema.parse(response.data);

    // return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
