import { z } from "zod";

export const DatasheetRevisionBase64DTOSchema = z.object({
  id: z
    .number({ message: "ID do Datasheet é obrigatório" })
    .int({ message: "ID do Datasheet deve ser um inteiro" })
    .gt(0, { message: "ID do Datasheet deve ser maior que zero" }),
  base64: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDatasheetRevisionBase64DTOSchema
  extends z.infer<typeof DatasheetRevisionBase64DTOSchema> {}
