import { useQuery } from "react-query";

import { IUseFetchGetAutoCompleteProposal } from "./interface";

import { fetchGetAutoCompleteProposal } from "~api/proposal/auto-complete/get";

export const useFetchGetAutoCompleteProposalKey =
  "api/proposal/auto-complete/get";

export function useFetchGetAutoCompleteProposal({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAutoCompleteProposal) {
  return useQuery(
    [useFetchGetAutoCompleteProposalKey, dependencyArray],
    async () => await fetchGetAutoCompleteProposal(payload),
    options,
  );
}
