import { api, apiException } from "~services/api";
import {
  DealDTOSchema,
  FetchUpdateDealCustomFieldValuePayloadSchema,
  IFetchUpdateDealCustomFieldValuePayloadSchema,
} from "~types/schemas";

export const fetchUpdateDealCustomFieldValue = async (
  payload: IFetchUpdateDealCustomFieldValuePayloadSchema,
) => {
  try {
    const { id, customFieldValues } =
      FetchUpdateDealCustomFieldValuePayloadSchema.parse(payload);

    const response = await api.patch(
      `/deal/${id}/custom-fields`,
      customFieldValues,
    );

    const parsedData = DealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
