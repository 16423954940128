import { CustomDiverseKitDTOSchema } from "./CustomDiverseKitDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCustomDiverseKitDTOSchema = PaginationSchema(
  CustomDiverseKitDTOSchema,
);

export interface IPaginatedCustomDiverseKitDTOSchema
  extends z.infer<typeof PaginatedCustomDiverseKitDTOSchema> {}
