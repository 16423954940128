import { PaginationSchema } from "./PaginationSchema";
import { ReasonForLossResponseSchema } from "./ReasonForLossResponseSchema";

import { z } from "zod";

export const PaginatedReasonForLossResponseSchema = PaginationSchema(
  ReasonForLossResponseSchema,
);

export interface IPaginatedReasonForLossResponseSchema
  extends z.infer<typeof PaginatedReasonForLossResponseSchema> {}
