import { api, apiException } from "~services/api";
import {
  DealCustomFieldResponseSchema,
  FetchGetDealCustomFieldByIdPayloadSchema,
  type IFetchGetDealCustomFieldByPipelineIdPayloadSchema,
} from "~types/schemas";

export const fetchGetDealCustomFieldsByPipelineId = async (
  payload: IFetchGetDealCustomFieldByPipelineIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetDealCustomFieldByIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/config/deals/custom-fields/pipeline/${id}`,
    );

    const parsedData = DealCustomFieldResponseSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
