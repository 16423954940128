import { useQuery } from "react-query";

import type { IUseFetchGetAddressesCompany } from "./interface";

import { fetchGetAddressesCompany } from "~api/company/addresses/get";

export const useFetchGetAddressesCompanyKey = "api/company/addresses/get";

export function useFetchGetAddressesCompany({
  dependencyArray = [],
  options,
}: IUseFetchGetAddressesCompany) {
  return useQuery(
    [useFetchGetAddressesCompanyKey, dependencyArray],
    async () => {
      return await fetchGetAddressesCompany();
    },
    options,
  );
}
