import { api, apiException } from "~services/api";
import {
  FetchGetCalculationTypeComplementaryItemsPayloadSchema,
  IFetchGetCalculationTypeComplementaryItemsPayloadSchema,
  CalculationTypeComplementaryItemsSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetCalculationTypeComplementaryItems = async (
  payload: IFetchGetCalculationTypeComplementaryItemsPayloadSchema,
) => {
  try {
    const { calculationType } =
      FetchGetCalculationTypeComplementaryItemsPayloadSchema.parse(payload);

    const response = await api.get(
      `/advanced/additional-costs/calculationScaleTypes/${calculationType}`,
    );

    const parsedData = z
      .array(CalculationTypeComplementaryItemsSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
