import { useQuery } from "react-query";

import type { IUseFetchGetAllEcoriModules } from "./interface";

import { fetchGetAllEcoriModules } from "~api/equipments/ecori/modules/get";

export const useFetchGetAllEcoriEquipmentModulesKey =
  "api/equipments/ecori/modules/get";

export function useFetchGetAllEcoriEquipmentModules({
  dependencyArray = [],
  options,
}: IUseFetchGetAllEcoriModules) {
  return useQuery(
    [useFetchGetAllEcoriEquipmentModulesKey, dependencyArray],
    async () => await fetchGetAllEcoriModules(),
    options,
  );
}
