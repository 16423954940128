import { api, apiException } from "~services/api";
import {
  StructureMaterialCostRuleDTOSchema,
  FetchUpdateStructuresMaterialCostRulePayloadSchema,
  IFetchUpdateStructuresMaterialCostRulePayloadSchema,
} from "~types/schemas";

export const fetchUpdateStructuresMaterialCostRule = async (
  payload: IFetchUpdateStructuresMaterialCostRulePayloadSchema,
) => {
  try {
    const { structuresMaterialCostRuleId, ...body } =
      FetchUpdateStructuresMaterialCostRulePayloadSchema.parse(payload);

    const response = await api.put(
      `/structuresMaterialCostRule/${structuresMaterialCostRuleId}`,
      body,
    );
    const parsedData = StructureMaterialCostRuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
