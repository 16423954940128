import { api, apiException } from "~services/api";
import {
  FetchUpdateDiverseServiceByDealIdPayloadSchema,
  type IFetchUpdateDiverseServiceByDealIdPayloadSchema,
  DealDTOSchema,
} from "~types/schemas";

export const fetchUpdateDiverseServiceByDealId = async (
  payload: IFetchUpdateDiverseServiceByDealIdPayloadSchema,
) => {
  try {
    const { dealId, ...body } =
      FetchUpdateDiverseServiceByDealIdPayloadSchema.parse(payload);

    const response = await api.patch(`/deal/${dealId}/diverse-service`, body);

    const parsedData = DealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
