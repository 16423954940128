import { AddressFormSchema } from "./AddressFormSchema";

import { z } from "zod";

export const CreateUserDTOSchema = z.object({
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  password: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  companyName: z.string(),
  identifier: z.string(),
  address: AddressFormSchema.nullish().transform((val) => val ?? undefined),
  pipedriveIntegration: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  testPeriodStartDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  testPeriodEndDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  uuid: z
    .string()
    .uuid()
    .nullish()
    .transform((val) => val ?? undefined),
  maxUsersLicensed: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
  companyIdForImportSettings: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICreateUserDTOSchema
  extends z.infer<typeof CreateUserDTOSchema> {}
