import { z } from "zod";

export const FetchGetReasonForLossByIdPayloadSchema = z.object({
  id: z
    .number({ message: "ID do motivo de perda é obrigatório" })
    .int({ message: "ID do motivo de perda deve ser um inteiro" })
    .gt(0, { message: "ID do motivo de perda deve ser maior do que zero" }),
});

export interface IFetchGetReasonForLossByIdPayloadSchema
  extends z.infer<typeof FetchGetReasonForLossByIdPayloadSchema> {}
