import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedSimultaneityProfiles } from "./interface";

import { fetchGetPaginatedSimultaneityProfiles } from "~api/simultaneityProfiles/pages/get";

export const useFetchGetPaginatedSimultaneityProfilesKey =
  "api/simultaneityProfiles/pages/get";

export function useFetchGetPaginatedSimultaneityProfiles({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPaginatedSimultaneityProfiles) {
  return useQuery(
    [useFetchGetPaginatedSimultaneityProfilesKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedSimultaneityProfiles(payload);
    },
    options,
  );
}
