import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedProductModules } from "./interface";

import { fetchGetPaginatedProductModules } from "~api/product/search/pvModule/get";

export const useFetchGetPaginatedProductModulesKey =
  "api/product/search/inverter/get";

export function useFetchGetPaginatedProductModules({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedProductModules) {
  return useQuery(
    [useFetchGetPaginatedProductModulesKey, dependencyArray],
    async () => await fetchGetPaginatedProductModules(payload),
    options,
  );
}
