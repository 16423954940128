import { api, apiException } from "~services/api";
import {
  type IFetchGetPersonDealsCountByPersonIdPayloadSchema,
  FetchGetPersonDealsCountByPersonIdPayloadSchema,
  PersonDealSectionDTOSchema,
} from "~types/schemas";

export async function fetchGetPersonDealsCountByPersonId(
  payload: IFetchGetPersonDealsCountByPersonIdPayloadSchema,
) {
  try {
    const { personId } =
      FetchGetPersonDealsCountByPersonIdPayloadSchema.parse(payload);

    const response = await api.get(`/persons/${personId}/deals/count`);

    const parsedData = PersonDealSectionDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
