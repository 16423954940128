import { Base64 } from "js-base64";
import { z } from "zod";

export const FetchUploadFileDatasheetPayloadSchema = z.object({
  id: z
    .number({ message: "ID do Datasheet é obrigatório" })
    .int({ message: "ID do Datasheet deve ser um inteiro" })
    .gt(0, { message: "ID do Datasheet deve ser maior que zero" }),
  file: z
    .string({ message: "Faltando arquivo base64" })
    .refine(Base64.isValid, { message: "Arquivo não é um base64 válido" }),
});

export interface IFetchUploadFileDatasheetPayloadSchema
  extends z.infer<typeof FetchUploadFileDatasheetPayloadSchema> {}
