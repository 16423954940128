import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveUnlinkedAutoCompleteOrganization } from "./interface";

import { fetchGetPipedriveUnlinkedAutoCompleteOrganization } from "~api/organizations/pipedrive-unlinked/auto-complete/get";

export const fetchGetPipedriveUnlinkedAutoCompleteOrganizationKey =
  "api/organizations/pipedrive-unlinked/auto-complete/get";

export function useFetchGetPipedriveUnlinkedAutoCompleteOrganization({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPipedriveUnlinkedAutoCompleteOrganization) {
  return useQuery(
    [fetchGetPipedriveUnlinkedAutoCompleteOrganizationKey, dependencyArray],
    async () =>
      await fetchGetPipedriveUnlinkedAutoCompleteOrganization(payload),
    options,
  );
}
