import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedSysAdminUserPayloadSchema,
  IFetchGetPaginatedSysAdminUserPayloadSchema,
  PaginatedSysAdminUserDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedSysAdminUser = async (
  payload: IFetchGetPaginatedSysAdminUserPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedSysAdminUserPayloadSchema.parse(payload);
    const response = await api.get(`/sys-admin-management/all`, { params });

    const parsedData = PaginatedSysAdminUserDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
