import { useQuery } from "react-query";

import type { IUseFetchGetPaymentSimulationsOptions } from "./interface";

import { fetchGetSimulationOptionById } from "~api/project/payment/simulation/option/{id}/get";

export const useFetchGetSimulationOptionByIdKey =
  "api/project/payment/simulation/option/get";

export function useFetchGetSimulationOptionById({
  dependencyArray = [],
  options,
  payload = {
    projectPaymentSimulationGroupId: 0,
  },
}: IUseFetchGetPaymentSimulationsOptions) {
  return useQuery(
    [useFetchGetSimulationOptionByIdKey, dependencyArray],
    async () => await fetchGetSimulationOptionById(payload),
    options,
  );
}
