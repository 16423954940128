import { z } from "zod";

export const DatasheetDTOSchema = z.object({
  id: z.number({ message: "ID do Datasheet é obrigatório" }).int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  brand: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDatasheetDTOSchema
  extends z.infer<typeof DatasheetDTOSchema> {}
