import { useMutation } from "react-query";

import { useFetchGetDealByCustomIdKey } from "../../deal/useFetchGetDealByCustomId";
import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetSearchClientKey } from "../../projects/useFetchGetSearchClient";
import { useFetchGetAutoCompletePersonKey } from "../useFetchGetAutoCompletePerson";
import { useFetchGetPaginatedPersonKey } from "../useFetchGetPaginatedPerson";
import { useFetchGetPersonByIdKey } from "../useFetchGetPersonById";
import type { IUseFetchCreatePerson } from "./interface";

import { fetchCreatePerson } from "~api/persons/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreatePersonKey = "api/persons/post";

export function useFetchCreatePerson({ options }: IUseFetchCreatePerson) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreatePerson(payload), {
    ...options,
    mutationKey: useFetchCreatePersonKey,
    onSuccess: (data, variables, context) => {
      message.success("Pessoa foi criada");
      queryClient.invalidateQueries(useFetchGetAutoCompletePersonKey);
      queryClient.invalidateQueries(useFetchGetPaginatedPersonKey);
      queryClient.invalidateQueries(useFetchGetPersonByIdKey);
      queryClient.invalidateQueries(useFetchGetSearchClientKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
      queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar pessoa: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
