import { CalculationTypeEnum, CostGroupEnum, ScaleEnum } from "~types/enum";
import { z } from "zod";

export const ProjectAdditionalCostFormSchema = z
  .object({
    nameItem: z.string(),
    calculationType: CalculationTypeEnum,
    description: z.string(),
    identifier: z.string(),
    quantity: z.number(),
    valueItem: z.number(),
    totalValueItem: z.number(),
    applyDiscount: z.boolean(),
    baseCalculationType: ScaleEnum,
    additionalCostDescriptorId: z.number().int(),
    laborId: z.number().int(),
    alternatingCurrentMaterialId: z.number().int(),
    logisticsId: z.number().int(),
    manuallyAdded: z.boolean(),
    deactivated: z.boolean(),
    costGroup: CostGroupEnum,
    nonDeactivated: z.boolean(),
  })
  .partial();

export interface IProjectAdditionalCostFormSchema
  extends z.infer<typeof ProjectAdditionalCostFormSchema> {}
