import { useQuery } from "react-query";

import { IUseFetchGetProposalCustomFieldSettingsByProfile } from "./interface";

import { fetchGetProposalCustomFieldSettingsByProfile } from "~api/fields/hideable/custom-field/{customFieldId}/get";

export const useFetchGetProposalCustomFieldSettingsByProfileKey =
  "api/fields/hideable/{profileId}/get";

export function useFetchGetProposalCustomFieldSettingsByProfile({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProposalCustomFieldSettingsByProfile) {
  return useQuery(
    [useFetchGetProposalCustomFieldSettingsByProfileKey, dependencyArray],
    async () => await fetchGetProposalCustomFieldSettingsByProfile(payload),
    options,
  );
}
