import { useQuery } from "react-query";

import type { IUseFetchGetProposalStep } from "./interface";

import { fetchGetProposalStepFive } from "~api/projects/step_five/{id}/get";

export const useFetchGetProposalStepKey = "api/projects/step_five/{id}/get";

export function useFetchGetProposalStep({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetProposalStep) {
  return useQuery(
    [useFetchGetProposalStepKey, dependencyArray],
    async () => await fetchGetProposalStepFive(payload),
    options,
  );
}
