import { api, apiException } from "~services/api";
import {
  AddressDTOSchema,
  FetchCreateBaseAddressPayloadSchema,
  IFetchCreateBaseAddressPayloadSchema,
} from "~types/schemas";

export const fetchCreateBaseAddress = async (
  payload: IFetchCreateBaseAddressPayloadSchema,
) => {
  try {
    const body = FetchCreateBaseAddressPayloadSchema.parse(payload);

    const response = await api.post<void>(`/company/addresses`, body);

    const parsedData = AddressDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
