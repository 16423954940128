import { useQuery } from "react-query";

import type { IUseFetchGetConcessionaireProfile } from "./interface";

import { fetchGetConcessionaireProfile } from "~api/electric-utility-profile/{id}/get";

export const useFetchGetConcessionaireProfileKey =
  "api/electric-utility-profile/{id}/get";

export function useFetchGetConcessionaireProfile({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetConcessionaireProfile) {
  return useQuery(
    [useFetchGetConcessionaireProfileKey, dependencyArray],
    async () => await fetchGetConcessionaireProfile(payload),
    options,
  );
}
