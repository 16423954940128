import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import { useFetchGetPersonalTokenKey } from "../useFetchGetPersonalToken";
import type { IUseFetchDeletePersonalToken } from "./interface";

import { fetchDeletePersonalToken } from "~api/users/personal-token/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePersonalTokenKey = "api/users/avatar/delete";

export function useFetchDeletePersonalToken({
  options,
}: IUseFetchDeletePersonalToken) {
  const { message } = useAppConfig();

  return useMutation(async () => await fetchDeletePersonalToken(), {
    ...options,
    mutationKey: useFetchDeletePersonalTokenKey,
    onSuccess: (data, variables, context) => {
      message.success("Integração removida!");
      queryClient.invalidateQueries(useFetchGetPersonalTokenKey);
      queryClient.invalidateQueries(useFetchGetSessionDataKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
