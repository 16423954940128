import { useQuery } from "react-query";

import type { IUseFetchGetPipelineDealsListById } from "./interface";

import { fetchGetPipelineDealsListById } from "~api/pipeline/{id}/deals/get";

export const useFetchGetPipelineDealsListByIdKey =
  "api/pipeline/{id}/deals/get";

export function useFetchGetPipelineDealsListById({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPipelineDealsListById) {
  return useQuery(
    [useFetchGetPipelineDealsListByIdKey, dependencyArray],
    async () => await fetchGetPipelineDealsListById(payload),
    options,
  );
}
