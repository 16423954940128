import { api, apiException } from "~services/api";
import {
  FetchDeleteBusinessUnitFromUserPayloadSchema,
  IFetchDeleteBusinessUnitFromUserPayloadSchema,
} from "~types/schemas";

export const fetchDeleteBusinessUnitFromUser = async (
  payload: IFetchDeleteBusinessUnitFromUserPayloadSchema,
) => {
  try {
    const { businessUnitId, userId } =
      FetchDeleteBusinessUnitFromUserPayloadSchema.parse(payload);

    await api.delete<void>(
      `/business-units/${businessUnitId}/remove/${userId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
