import { z } from "zod";

export const ConsultantCompanyDTOSchema = z.object({
  id: z.number().gt(0),
  company: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  companyId: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  blocked: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  maxUsersLicensed: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  countUsers: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  locationService: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  lastActivity: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  dataUsage: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  pipedriveIntegration: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IConsultantCompanyDTOSchema
  extends z.infer<typeof ConsultantCompanyDTOSchema> {}
