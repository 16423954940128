import { api, apiException } from "~services/api";
import {
  FetchGetProjectRevisionByIdPayloadSchema,
  type IFetchGetProjectRevisionByIdPayloadSchema,
  RevisionResponseSchema,
} from "~types/schemas";

export const fetchGetProjectRevisionById = async (
  payload: IFetchGetProjectRevisionByIdPayloadSchema,
) => {
  try {
    const { projectId } =
      FetchGetProjectRevisionByIdPayloadSchema.parse(payload);

    const response = await api.get(`/projects/revision/${projectId}`);

    const parsedData = RevisionResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
