import { z } from "zod";

export const NotificationDataDTOSchema = z.object({
  eventType: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  totalUnread: z.number(),
});

export interface INotificationDataDTOSchema
  extends z.infer<typeof NotificationDataDTOSchema> {}
