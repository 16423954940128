import { useMutation } from "react-query";

import { useFetchGetEosFinancialProposalsKey } from "../useFetchGetEosFinancialProposals";
import type { IUseFetchCreateOrganizationEosFinancing } from "./interface";

import { fetchCreateOrganizationEosFinancing } from "~api/eosfin/organization/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateOrganizationEosFinancingKey =
  "api/eosfin/organization/post";

export function useFetchCreateOrganizationEosFinancing({
  options,
}: IUseFetchCreateOrganizationEosFinancing) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateOrganizationEosFinancing(payload),
    {
      ...options,
      mutationKey: useFetchCreateOrganizationEosFinancingKey,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(useFetchGetEosFinancialProposalsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar financiamento: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
