import { api, apiException } from "~services/api";
import { TrackingProjectDTO } from "~types/api/TrackingProjectDTO";
import {
  TrackingProjectDTOSchema,
  FetchSaveTrackingViewedPayloadSchema,
  IFetchSaveTrackingViewedPayloadSchema,
} from "~types/schemas";

export const fetchSaveTrackingViewed = async (
  path: IFetchSaveTrackingViewedPayloadSchema,
) => {
  try {
    const { uuid } = FetchSaveTrackingViewedPayloadSchema.parse(path);

    const response = await api.post<TrackingProjectDTO>(
      `/tracking/viewed/${uuid}`,
    );

    const parsedData = TrackingProjectDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
