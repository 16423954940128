import { api, apiException } from "~services/api";
import {
  FetchRemovePersonFromOrganizationPayloadSchema,
  IFetchRemovePersonFromOrganizationPayloadSchema,
} from "~types/schemas";

export const fetchRemovePersonFromOrganization = async (
  payload: IFetchRemovePersonFromOrganizationPayloadSchema,
) => {
  try {
    const { organizationId, ...body } =
      FetchRemovePersonFromOrganizationPayloadSchema.parse(payload);

    await api.patch(`/organizations/${organizationId}/people/remove`, body);
  } catch (error) {
    throw apiException(error);
  }
};
