import { api, apiException } from "~services/api";
import {
  DefaultFieldSettingsDTOSchema,
  FetchGetDefaultFieldSettingsPayloadSchema,
  IFetchGetDefaultFieldSettingsPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetDefaultFieldSettings = async (
  payload: IFetchGetDefaultFieldSettingsPayloadSchema,
) => {
  try {
    const { pricingTypeId } =
      FetchGetDefaultFieldSettingsPayloadSchema.parse(payload);

    const response = await api.get(`/default-fields/settings/${pricingTypeId}`);

    const parsedData = z
      .array(DefaultFieldSettingsDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
