import { LaborFormSchema } from "./LaborFormSchema";

import { z } from "zod";

export const FetchUpdateLaborPayloadSchema = LaborFormSchema.extend({
  laborId: z.number().int().gt(0),
});

export interface IFetchUpdateLaborPayloadSchema
  extends z.infer<typeof FetchUpdateLaborPayloadSchema> {}
