import { z } from "zod";

export const PaginationSchema = <T extends z.ZodTypeAny>(contentType: T) =>
  z.object({
    totalElements: z.number().int().gte(0),
    totalPages: z.number().int().gte(0),
    size: z.number().int().gte(0),
    number: z.number().int().gte(0),
    content: z.array(contentType).default([]),
  });

export interface IPaginationSchema<T extends z.ZodTypeAny>
  extends z.infer<ReturnType<typeof PaginationSchema<T>>> {}
