import { api, apiException } from "~services/api";
import { PipedriveLeadTypeDTOSchema } from "~types/schemas";

export const fetchGetPipedriveLeadType = async () => {
  try {
    const response = await api.get(`/company/pipedrive-lead-type`);

    const parsedData = PipedriveLeadTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
