import { EntityIdentifierDTOSchema } from "./EntityIdentifierDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import { z } from "zod";

export const CostAggregatorDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  entityIdentifier: EntityIdentifierDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface ICostAggregatorDTOSchema
  extends z.infer<typeof CostAggregatorDTOSchema> {}
