import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedEosFinancialProposalsPayloadSchema,
  IFetchGetPaginatedEosFinancialProposalsPayloadSchema,
  PaginatedEosFinancialProposalsSchema,
} from "~types/schemas";

export const fetchGetEosFinancialProposals = async (
  payload?: IFetchGetPaginatedEosFinancialProposalsPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedEosFinancialProposalsPayloadSchema.parse(payload);

    const response = await api.get(`/eosfin/proposals`, { params });

    const parsedData = PaginatedEosFinancialProposalsSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
