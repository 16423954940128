import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchUpdatePaymentSimulation } from "./interface";

import { fetchUpdateSimulationGroup } from "~api/project/payment/simulation/group/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePaymentSimulationKey =
  "api/projects/payment/simulation/group/put";

export function useFetchUpdatePaymentSimulation({
  options,
}: IUseFetchUpdatePaymentSimulation) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => fetchUpdateSimulationGroup(payload), {
    ...options,
    mutationKey: useFetchUpdatePaymentSimulationKey,
    onSuccess: (data, variables, context) => {
      message.success("Simulação foi alterada");
      queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao alterar simulação: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
