import { useQuery } from "react-query";

import type { IUseFetchGetAllPaymentSimulations } from "./interface";

import { fetchGetSimulationGroup } from "~api/project/payment/simulation/group/get";

export const useFetchGetAllPaymentSimulationsKey =
  "api/project/payment/simulation/group/get";

export function useFetchGetAllPaymentSimulations({
  dependencyArray = [],
  options,
}: IUseFetchGetAllPaymentSimulations) {
  return useQuery(
    [useFetchGetAllPaymentSimulationsKey, dependencyArray],
    async () => await fetchGetSimulationGroup(),
    options,
  );
}
