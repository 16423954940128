import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import type { IUseFetchUpdateNote } from "./interface";

import { fetchUpdateNote } from "~api/note/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateNoteKey = "api/note/put";

export function useFetchUpdateNote({ options }: IUseFetchUpdateNote) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdateNote(payload), {
    ...options,
    mutationKey: useFetchUpdateNoteKey,
    onSuccess: (data, variables, context) => {
      message.success("Anotação foi atualizada!");

      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao atualizar anotação: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
