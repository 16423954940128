import { api, apiException } from "~services/api";
import {
  FetchImpersonateConsultantInCompanyByIdPayloadSchema,
  IFetchImpersonateConsultantInCompanyByIdPayloadSchema,
  TokenDTOSchema,
} from "~types/schemas";

export const fetchImpersonateConsultantInCompanyById = async (
  payload: IFetchImpersonateConsultantInCompanyByIdPayloadSchema,
) => {
  try {
    const { companyId } =
      FetchImpersonateConsultantInCompanyByIdPayloadSchema.parse(payload);

    const response = await api.post(
      `/consultants/companies/${companyId}/impersonate`,
    );

    const parsedData = TokenDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
