import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesFilterByPersonIdPayloadSchema,
  PaginatedHistoryDTOSchema,
  type IFetchGetHistoriesFilterByPersonIdPayloadSchema,
} from "~types/schemas";

export const fetchGetPaginatedHistoriesFilterByPersonId = async (
  payload: IFetchGetHistoriesFilterByPersonIdPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetHistoriesFilterByPersonIdPayloadSchema.parse(payload);

    const response = await api.get("/histories/person/filters", {
      params: queryParams,
    });

    const parsedData = PaginatedHistoryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
