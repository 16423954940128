import { PermissionsEnum } from "~types/enum";
import { z } from "zod";

export const ProfileFormSchema = z.object({
  name: z.string(),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  permissions: PermissionsEnum.array()
    .nullish()
    .transform((val) => val ?? undefined),
  userGroupId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  users: z
    .number()
    .int()
    .gt(0)
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProfileFormSchema extends z.infer<typeof ProfileFormSchema> {}
