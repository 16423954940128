import { useQuery } from "react-query";

import type { IUseFetchGetSearchPipedriveDeal } from "./interface";

import { fetchGetSearchPipedriveDeal } from "~api/projects/step_one/pipedrive/deal/search/get";

export const useFetchGetSearchPipedriveDealKey =
  "api/projects/step_one/search/get";

export function useFetchGetSearchPipedriveDeal({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetSearchPipedriveDeal) {
  return useQuery(
    [useFetchGetSearchPipedriveDealKey, dependencyArray],
    async () => await fetchGetSearchPipedriveDeal(payload),
    options,
  );
}
