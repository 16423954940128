import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { fetchGetAutoCompleteOrganizationKey } from "../useFetchGetAutoCompleteOrganization";
import { useFetchGetOrganizationByIdKey } from "../useFetchGetOrganizationById";
import { fetchGetPaginatedOrganizationKey } from "../useFetchGetPaginatedOrganization";
import { useFetchGetPersonsFromOrganizationKey } from "../useFetchGetPersonsFromOrganization";
import type { IUseFetchSetOrganizationOwner } from "./interface";

import { fetchSetOrganizationOwner } from "~api/organizations/{id}/owner/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchSetOrganizationOwnerKey =
  "api/organizations/{id}/owner/{id}/patch";

export function useFetchSetOrganizationOwner({
  options,
}: IUseFetchSetOrganizationOwner) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchSetOrganizationOwner(payload),
    {
      ...options,
      mutationKey: useFetchSetOrganizationOwnerKey,
      onSuccess: (data, variables, context) => {
        message.success("Dono da organização foi alterado");
        queryClient.invalidateQueries(fetchGetPaginatedOrganizationKey);
        queryClient.invalidateQueries(useFetchGetOrganizationByIdKey);
        queryClient.invalidateQueries(useFetchGetPersonsFromOrganizationKey);
        queryClient.invalidateQueries(fetchGetPaginatedOrganizationKey);
        queryClient.invalidateQueries(fetchGetAutoCompleteOrganizationKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao alterar o dono da organização: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
