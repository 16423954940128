import { api, apiException } from "~services/api";
import {
  FetchUpdateDefaultMessageUserPayloadSchema,
  IFetchUpdateDefaultMessageUserPayloadSchema,
  UserDefaultProposalMessageFormSchema,
} from "~types/schemas";

export const fetchUpdateDefaultMessageUser = async (
  payload: IFetchUpdateDefaultMessageUserPayloadSchema,
) => {
  try {
    const body = FetchUpdateDefaultMessageUserPayloadSchema.parse(payload);

    const response = await api.put(`/users/default-message`, body);

    const parsedData = UserDefaultProposalMessageFormSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
