import { api, apiException } from "~services/api";
import {
  ElectricUtilityDTOSchema,
  FetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema,
  IFetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetConcessionairesWithAssociatedProfileByProvince = async (
  payload: IFetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema,
) => {
  try {
    const { provinceId } =
      FetchGetConcessionairesWithAssociatedProfileByProvincePayloadSchema.parse(
        payload,
      );

    const response = await api.get(
      `/electric-utility/list-by-province/${provinceId}`,
    );

    const parsedData = z.array(ElectricUtilityDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
