import { api, apiException } from "~services/api";
import { BusinessUnit } from "~types/api/BusinessUnit";

export type PageBusinessUnit = {
  content: BusinessUnit[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
};

export const pageBusinessUnit = async (
  page: number,
  isActive: boolean | undefined,
) => {
  try {
    return await api.get<PageBusinessUnit>(
      `/business-units/pageable?page=${page}${
        isActive !== undefined ? `&isActive=${isActive}` : ""
      }`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
