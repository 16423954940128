import { api, apiException } from "~services/api";
import {
  FetchCreatePricingTypesPayloadSchema,
  IFetchCreatePricingTypesPayloadSchema,
  PricingTypeDTOSchema,
} from "~types/schemas";

export const fetchCreatePricingTypes = async (
  payload: IFetchCreatePricingTypesPayloadSchema,
) => {
  try {
    const body = FetchCreatePricingTypesPayloadSchema.parse(payload);

    const response = await api.post(`/pricing-types`, body);

    const parsedData = PricingTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
