import { api, apiException } from "~services/api";
import {
  FetchDeleteDealByCustomIdPayloadSchema,
  type IFetchDeleteDealByCustomIdPayloadSchema,
} from "~types/schemas";

export const fetchDeleteDealByCustomId = async (
  payload: IFetchDeleteDealByCustomIdPayloadSchema,
) => {
  try {
    const { customId } = FetchDeleteDealByCustomIdPayloadSchema.parse(payload);

    await api.delete(`/deal/${customId}`);
  } catch (error) {
    throw apiException(error);
  }
};
