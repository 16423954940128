import { api, apiException } from "~services/api";
import {
  FetchDeleteOrganizationPipedrivePayloadSchema,
  IFetchDeleteOrganizationPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchDeleteOrganizationPipedrive = async (
  payload: IFetchDeleteOrganizationPipedrivePayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchDeleteOrganizationPipedrivePayloadSchema.parse(payload);

    await api.delete(`/organizations/pipedrive/${pipedriveId}`);
  } catch (error) {
    throw apiException(error);
  }
};
