import { z } from "zod";

export const TotalWonGroupedByUserInsightBarDataSchema = z.object({
  userId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  totalWon: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  dealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ITotalWonGroupedByUserInsightBarDataSchema
  extends z.infer<typeof TotalWonGroupedByUserInsightBarDataSchema> {}
