import { HistoryDealDTOSchema } from "./HistoryDealDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedHistoryFilterDTOSchema =
  PaginationSchema(HistoryDealDTOSchema);

export interface IPaginatedHistoryFilterDTOSchema
  extends z.infer<typeof PaginatedHistoryFilterDTOSchema> {}
