import { api, apiException } from "~services/api";
import {
  ProfileSchema,
  FetchCreateProfilePayloadSchema,
  IFetchCreateProfilePayloadSchema,
} from "~types/schemas";

export const fetchCreateProfile = async (
  payload: IFetchCreateProfilePayloadSchema,
) => {
  try {
    const body = FetchCreateProfilePayloadSchema.parse(payload);

    const response = await api.post(`/profiles`, body);

    const parsedData = ProfileSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
