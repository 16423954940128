import { z } from "zod";

export const typeDocumentEnum = [
  "CONTRATO_PRODUTO_SERVICO",
  "COMPROVANTE_RECEBIMENTO_EOS",
  "EVIDENCIA_PROCESSO_FINANCIADOR",
  "IDENTIFICACAO_PF",
  "CONTA_ENERGIA",
  "COMPROVANTE_RENDA_PF",
  "IDENTIFICACAO_PJ",
  "COMPROVANTE_RENDA_PJ",
  "CIENTE_ACORDO_CLIENTE",
  "PARECER_ACESSO",
  "PEDIDO_DISTRIBUIDOR",
  "MATRICULA_IMOVEL",
  "ORDEM_RECEBIMENTO",
  "CONTRATO_FORMALIZACAO",
  "CONTRATO_INTERMEDIACAO",
  "NF_PRODUTO",
  "NF_SERVICO",
  "COMPR_ENTRADA_INTEGRADOR",
  "COMPR_ENTRADA_DISTRIBUIDOR",
  "MILESTONE_INVOICE",
] as const;

export const TypeDocumentEnum = z.enum(typeDocumentEnum).catch((e) => e.input);

export type TypeDocumentEnum = z.infer<typeof TypeDocumentEnum>;
