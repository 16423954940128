import { useMutation } from "react-query";

import { useFetchGetProjectRevisionKey } from "../../projects/useFetchGetProjectRevisionById";
import { useFetchGetGetProposalByCustomIdKey } from "../useFetchGetGetProposalByCustomId";
import { useFetchGetProposalByPipedriveDealIdKey } from "../useFetchGetProposalByPipedriveDealId";
import { useFetchPostToGetPaginatedProposalsKey } from "../useFetchPostToGetPaginatedProposals";
import type { IUseFetchUpdateProposalStatus } from "./interface";

import { fetchUpdateProposalStatus } from "~api/proposal/pipedrive/status/{customId}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateProposalStatusKey =
  "api/proposal/pipedrive/status/{customId}/patch";

export function useFetchUpdateProposalStatus({
  options,
}: IUseFetchUpdateProposalStatus) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateProposalStatus(payload),
    {
      ...options,
      mutationKey: useFetchUpdateProposalStatusKey,
      onSuccess: (data, variables, context) => {
        message.success("Status da proposta foi definido!");
        queryClient.invalidateQueries(useFetchPostToGetPaginatedProposalsKey);
        queryClient.invalidateQueries(useFetchGetProposalByPipedriveDealIdKey);
        queryClient.invalidateQueries(useFetchGetGetProposalByCustomIdKey);
        queryClient.invalidateQueries(useFetchGetProjectRevisionKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao definir status da proposta: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
