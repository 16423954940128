import { api, apiException } from "~services/api";
import {
  FetchDeleteIntegrationLinkPayloadSchema,
  IFetchDeleteIntegrationLinkPayloadSchema,
} from "~types/schemas";

export const fetchDeleteIntegrationLinks = async (
  payload: IFetchDeleteIntegrationLinkPayloadSchema,
) => {
  try {
    const { distributorId } =
      FetchDeleteIntegrationLinkPayloadSchema.parse(payload);
    await api.delete<void>(
      `/structure-distributor/distributor/${distributorId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
