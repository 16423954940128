import { useQuery } from "react-query";

import type { IUseFetchGetDetailsProposalByCustomId } from "./interface";

import { fetchGetDetailsProposalByCustomId } from "~api/proposal/{customId}/details/get";

export const useFetchGetDetailsProposalByCustomIdKey =
  "api/proposal/{customId}/details/get";

export function useFetchGetDetailsProposalByCustomId({
  options,
  dependencyArray,
  payload = {
    customId: 0,
  },
}: IUseFetchGetDetailsProposalByCustomId) {
  return useQuery(
    [useFetchGetDetailsProposalByCustomIdKey, dependencyArray],
    async () => await fetchGetDetailsProposalByCustomId(payload),
    options,
  );
}
