import { z } from "zod";

export const fieldTypeEnumArray = [
  "NUMBER",
  "TEXT",
  "TEXT_AREA",
  "BOOLEAN",
  "SELECT",
  "IMAGE",
  "DATE",
] as const;

export const FieldTypeEnum = z.enum(fieldTypeEnumArray).catch((e) => e.input);

export type FieldTypeEnum = z.infer<typeof FieldTypeEnum>;
