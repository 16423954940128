import { api, apiException } from "~services/api";
import {
  type IFetchPatchDealDefaultFieldsSettingsPayloadSchema,
  DealDefaultFieldsSettingsDTOSchema,
} from "~types/schemas";

export const fetchPatchDealDefaultFieldsSettings = async (
  payload: IFetchPatchDealDefaultFieldsSettingsPayloadSchema,
) => {
  try {
    const { id, ...body } = payload;
    const response = await api.patch(
      `/config/deals/default-fields/settings/${id}`,
      body,
    );

    const parsedData = DealDefaultFieldsSettingsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
