import { useMutation } from "react-query";

import type {
  IUseFetchUpdateValueForCustomer,
  UseFetchUpdateValueForCustomerResultType,
  UseFetchUpdateValueForCustomerErrorType,
  UseFetchUpdateValueForCustomerPayloadType,
} from "./interface";

import { fetchUpdateValueForCustomer } from "~api/projects/step_four/custom-id/updateValueForCustomer/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
export const useFetchUpdateValueForCustomerKey =
  "api/projects/step_four/custom-id/updateValueForCustomer/{id}/put";

export function useFetchUpdateValueForCustomer({
  options,
}: IUseFetchUpdateValueForCustomer) {
  const { message } = useAppConfig();

  return useMutation<
    UseFetchUpdateValueForCustomerResultType,
    UseFetchUpdateValueForCustomerErrorType,
    UseFetchUpdateValueForCustomerPayloadType,
    unknown
  >(
    async ({ dealId, valueForCustomer }) => {
      const response = await fetchUpdateValueForCustomer({
        path: {
          id: dealId,
        },
        body: {
          newValue: valueForCustomer,
        },
      });

      const parsedToObject = {
        valueForCustomer: response.data,
      };

      return parsedToObject;
    },
    {
      ...options,
      mutationKey: useFetchUpdateValueForCustomerKey,
      onSuccess: (data, variables, context) => {
        message.success("Valor da proposta foi alterado");
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
