import { api, apiException } from "~services/api";
import {
  type IFetchGetAdminInvertersByIdPayloadSchema,
  FetchGetAdminInvertersByIdPayloadSchema,
  InverterDTOSchema,
} from "~types/schemas";

export const fetchGetAdminInvertersById = async (
  payload: IFetchGetAdminInvertersByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetAdminInvertersByIdPayloadSchema.parse(payload);

    const response = await api.get(`/admin/inverters/${id}`);

    const parsedData = InverterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
