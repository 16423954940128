import { UserFormSchema } from "./UserFormSchema";

import { z } from "zod";

export const FetchUpdateUserPayloadSchema = UserFormSchema.extend({
  userId: z.number().int().gt(0),
});

export interface IFetchUpdateUserPayloadSchema
  extends z.infer<typeof FetchUpdateUserPayloadSchema> {}
