import { useQuery } from "react-query";

import type { IUseFetchGetEdeltecModuleBrands } from "./interface";

import { fetchGetEdeltecModuleBrands } from "~api/equipments/edeltec/module-brands/get";

export const useFetchGetEdeltecModuleBrandsKey =
  "api/equipments/edeltec/module-brands/get";

export function useFetchGetEdeltecModuleBrands({
  dependencyArray = [],
  options,
}: IUseFetchGetEdeltecModuleBrands) {
  return useQuery(
    [useFetchGetEdeltecModuleBrandsKey, dependencyArray],
    async () => await fetchGetEdeltecModuleBrands(),
    options,
  );
}
