import { useMutation } from "react-query";

import { useFetchGetAdditionalCostTaxesKey } from "../useFetchGetAdditionalCostTaxes";
import type { IUseFetchUpdateAdditionalCostTax } from "./interface";

import { fetchUpdateAdditionalCostTax } from "~api/config/additionalCostTax/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateAdditionalCostTaxKey =
  "api/config/additionalCostTax/put";

export function useFetchUpdateAdditionalCostTax({
  options,
}: IUseFetchUpdateAdditionalCostTax) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateAdditionalCostTax(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateAdditionalCostTaxKey,
      onSuccess: (data, variables, context) => {
        message.success("Alíquota de imposto foi alterada");
        queryClient.invalidateQueries(useFetchGetAdditionalCostTaxesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
