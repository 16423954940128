import { api, apiException } from "~services/api";
import {
  FetchCreateManySimulationOptionPayloadSchema,
  IFetchCreateManySimulationOptionPayloadSchema,
  ProjectPaymentSimulationOptionDTOSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchCreateManySimulationOption = async (
  payload: IFetchCreateManySimulationOptionPayloadSchema,
) => {
  try {
    const body = FetchCreateManySimulationOptionPayloadSchema.parse(payload);

    const response = await api.post(
      "project/payment/simulation/option/from-list",
      body,
    );

    const parsedData = z
      .array(ProjectPaymentSimulationOptionDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
