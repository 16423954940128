import { CellTypeEnum, ModulePhaseEnum } from "~types/enum";
import { z } from "zod";

export const PvModuleCreateRequestSchema = z.object({
  model: z.string({ message: "Nome do módulo esta faltando" }),
  manufacturer: z.string({ message: "Fabricante do módulo esta faltando" }),
  cellType: CellTypeEnum,
  surfaceType: ModulePhaseEnum,
  powerWp: z.number({ message: "Potência do módulo esta faltando" }),
  height: z.number({ message: "Altura do módulo esta faltando" }),
  width: z.number({ message: "Largura do módulo esta faltando" }),
  depth: z.number({ message: "Profundidade do módulo esta faltando" }),
  weight: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vocStc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  iscStc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vmpStc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  impStc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  efficiencyStc: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vocNmot: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  iscNmot: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vmpNmot: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  impNmot: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  vocCoefficientTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  iscCoefficientTemperature: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  annualLoss: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  productWarranty: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  performanceWarranty: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  warrantyInYears: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  datasheetId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IPvModuleCreateRequestSchema
  extends z.infer<typeof PvModuleCreateRequestSchema> {}
