import { api, apiException } from "~services/api";
import {
  FetchGetProposalExpirationRulesPayloadSchema,
  IFetchGetProposalExpirationRulesPayloadSchema,
  StepOneExpirationRuleSchema,
} from "~types/schemas";

export const fetchGetProposalExpirationRules = async (
  payload?: IFetchGetProposalExpirationRulesPayloadSchema,
) => {
  const params = FetchGetProposalExpirationRulesPayloadSchema.parse(payload);

  try {
    const response = await api.get(
      `/projects/step_one/proposal-expiration-rules`,
      { params },
    );

    const parsedData = StepOneExpirationRuleSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
