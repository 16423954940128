import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductModulesPayloadSchema,
  IFetchGetPaginatedProductModulesPayloadSchema,
  PaginatedCustomPvModuleDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedProductModules = async (
  payload: IFetchGetPaginatedProductModulesPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedProductModulesPayloadSchema.parse(payload);

    const response = await api.get(`/product/search/pvModule`, {
      params,
    });

    const parsedData = PaginatedCustomPvModuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
