import { useQuery } from "react-query";

import type { IUseFetchGetProfileById } from "./interface";

import { fetchGetProfileById } from "~api/profiles/{id}/get";

export const useFetchGetProfileByIdKey = "api/profiles/{id}/get";

export function useFetchGetProfileById({
  dependencyArray = [],
  options,
  payload = {
    profileId: 0,
  },
}: IUseFetchGetProfileById) {
  return useQuery(
    [useFetchGetProfileByIdKey, dependencyArray],
    async () => fetchGetProfileById(payload),
    options,
  );
}
