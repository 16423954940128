import { z } from "zod";

export const FetchGetDealCustomFieldByPipelineIdPayloadSchema = z.object({
  id: z
    .number({ message: "É esperado o ID do funil" })
    .int({ message: "O ID do funil deve ser um inteiro" })
    .gt(0, { message: "O ID do funil deve ser maior do que zero" }),
});

export interface IFetchGetDealCustomFieldByPipelineIdPayloadSchema
  extends z.infer<typeof FetchGetDealCustomFieldByPipelineIdPayloadSchema> {}
