import { z } from "zod";

export const AssignUserWithOrganizationFormSchema = z
  .object({
    userId: z.number().int().gt(0),
    organizationId: z.number().int().gt(0),
  })
  .partial();

export interface IAssignUserWithOrganizationFormSchema
  extends z.infer<typeof AssignUserWithOrganizationFormSchema> {}
