import { z } from "zod";

export const POSSIBLE_PROPOSAL_STEP = [
  "DATA",
  "SIZING",
  "DETAILS",
  "AFTER_SIZING",
  "PAYMENT",
  "PROPOSAL",
  "FINISH",
] as const;

export const ProposalStepEnum = z
  .enum(POSSIBLE_PROPOSAL_STEP)
  .catch((e) => e.input);

export type ProposalStepEnumType = z.infer<typeof ProposalStepEnum>;
