import { z } from "zod";

export const HideableFieldDTOSchema = z.object({
  id: z.number().int().gt(0),
  nameField: z.string(),
  fieldCategory: z.string(),
  displayName: z.string(),
  profileId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  profileName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IHideableFieldDTOSchema
  extends z.infer<typeof HideableFieldDTOSchema> {}
