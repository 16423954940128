import { PipedriveBaseCalculationTypeEnum } from "~types/enum";
import { z } from "zod";

export const PremisesPipedriveFormSchema = z
  .object({
    updateValueDealPipedrive: z.boolean(),
    pipedriveBaseCalculationType: PipedriveBaseCalculationTypeEnum,
    pipedrivePipelineId: z.number().int().gt(0),
    commonUserPipelineId: z.number().int().gt(0),
  })
  .partial();

export interface IPremisesPipedriveFormSchema
  extends z.infer<typeof PremisesPipedriveFormSchema> {}
