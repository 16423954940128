import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesByOrganizationId } from "./interface";

import { fetchGetHistoriesByOrganizationId } from "~api/histories/organization/{id}/get";

export const useFetchGetHistoriesByOrganizationIdKey =
  "api/histories/organization/{id}/get";

export function useFetchGetHistoriesByOrganizationId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesByOrganizationId) {
  return useQuery(
    [useFetchGetHistoriesByOrganizationIdKey, dependencyArray],
    async () => await fetchGetHistoriesByOrganizationId(payload),
    options,
  );
}
