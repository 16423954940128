import { ImportStatusEnum, ImportTypeEnum } from "~types/enum";
import { z } from "zod";

export const ImportProductQueueDTOSchema = z.object({
  id: z.number().int().gt(0),
  importType: ImportTypeEnum,
  importStatus: ImportStatusEnum,
  response: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export interface IImportProductQueueDTOSchema
  extends z.infer<typeof ImportProductQueueDTOSchema> {}
