import { api, apiException } from "~services/api";
import {
  CitySchema,
  FetchSearchCityPayloadSchema,
  IFetchSearchCityPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetCities = async (
  payload: IFetchSearchCityPayloadSchema,
) => {
  try {
    const query = FetchSearchCityPayloadSchema.parse(payload);

    const response = await api.get("/cities/search", {
      params: query,
    });

    const parsedData = z.array(CitySchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
