import { AddressDTOSchema } from "./AddressDTOSchema";
import { ClientDTOSchema } from "./ClientDTOSchema";
import { OrganizationDTOSchema } from "./OrganizationDTOSchema";
import { SimpleUserAccountDTOSchema } from "./SimpleUserAccountDTOSchema";

import {
  ActivityEventTypeEnum,
  ActivityStatusEnum,
  BusyFreeEnum,
  ActivityPriorityEnum,
} from "~types/enum";
import { z } from "zod";

export const ActivityDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  beginDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  endDate: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  priority: ActivityPriorityEnum.nullish().transform((val) => val ?? undefined),
  busyFree: BusyFreeEnum.nullish().transform((val) => val ?? undefined),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  notes: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  owner: SimpleUserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  allDay: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined),
  organization: OrganizationDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  client: ClientDTOSchema.nullish().transform((val) => val ?? undefined),
  status: ActivityStatusEnum.nullish().transform((val) => val ?? undefined),
  eventType: ActivityEventTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IActivityDTOSchema extends z.infer<typeof ActivityDTOSchema> {}
