import { z } from "zod";

export const AddressInputDTOSchema = z.object({
  id: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  street: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  zipCode: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  district: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  number: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  latitude: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  longitude: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  city: z
    .object({
      id: z
        .number()
        .gt(0)
        .nullish()
        .transform((val) => val ?? undefined),
      name: z
        .string()
        .nullish()
        .transform((val) => val ?? undefined),
      province: z
        .object({
          id: z
            .number()
            .gt(0)
            .nullish()
            .transform((val) => val ?? undefined),
          name: z
            .string()
            .nullish()
            .transform((val) => val ?? undefined),
          uf: z
            .string()
            .nullish()
            .transform((val) => val ?? undefined),
        })
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  province: z
    .object({
      id: z
        .number()
        .gt(0)
        .nullish()
        .transform((val) => val ?? undefined),
      name: z
        .string()
        .nullish()
        .transform((val) => val ?? undefined),
      uf: z
        .string()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  country: z
    .object({
      id: z
        .number()
        .gt(0)
        .nullish()
        .transform((val) => val ?? undefined),
      name: z
        .string()
        .nullish()
        .transform((val) => val ?? undefined),
    })
    .nullish()
    .transform((val) => val ?? undefined),
  formattedAddress: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  note: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IAddressInputDTOSchema
  extends z.infer<typeof AddressInputDTOSchema> {}
