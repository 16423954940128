import { ActivityDataSchema } from "./ActivityDataSchema";

import { z } from "zod";

export const ResponseActivityTypeSchema = z.object({
  success: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  data: z
    .array(ActivityDataSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IResponseActivityTypeSchema
  extends z.infer<typeof ResponseActivityTypeSchema> {}
