import { api, apiException } from "~services/api";
import {
  DealCustomFieldDTOSchema,
  FetchUpdateDealCustomFieldOrderFormPayloadSchema,
  IFetchUpdateDealCustomFieldOrderFormPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchUpdateDealCustomFieldsOrder = async (
  payload: IFetchUpdateDealCustomFieldOrderFormPayloadSchema,
) => {
  try {
    const { body } =
      FetchUpdateDealCustomFieldOrderFormPayloadSchema.parse(payload);

    const response = await api.patch(`/config/deals/custom-fields/order`, body);

    const parsedData = z
      .array(DealCustomFieldDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
