import { api, apiException } from "~services/api";
import {
  FetchUpdatePipedriveApiTokenPayloadSchema,
  IFetchUpdatePipedriveApiTokenPayloadSchema,
} from "~types/schemas";

export const fetchUpdatePipedriveApiToken = async (
  payload: IFetchUpdatePipedriveApiTokenPayloadSchema,
) => {
  try {
    const body = FetchUpdatePipedriveApiTokenPayloadSchema.parse(payload);
    await api.patch(`/company/pipedrive-api-token`, body);
  } catch (error) {
    throw apiException(error);
  }
};
