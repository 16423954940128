import { DealSchema } from "./DealSchema";

import { z } from "zod";

export const ResponseDealSchema = z.object({
  data: DealSchema,
});

export interface IResponseDealSchema
  extends z.infer<typeof ResponseDealSchema> {}
