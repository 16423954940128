import { api, apiException } from "~services/api";
import {
  ElectricUtilityProfileDTOSchema,
  FetchGetConcessionaireProfilesByVoltagePayloadSchema,
  IFetchGetConcessionaireProfilesByVoltagePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetConcessionaireProfilesByVoltage = async (
  payload: IFetchGetConcessionaireProfilesByVoltagePayloadSchema,
) => {
  try {
    const { electricUtilityId, ...params } =
      FetchGetConcessionaireProfilesByVoltagePayloadSchema.parse(payload);

    const response = await api.get(
      `/electric-utility-profile/electric-utility/voltage-type/${electricUtilityId}`,
      {
        params,
      },
    );

    const parsedData = z
      .array(ElectricUtilityProfileDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
