import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchDeletePaymentSimulationOption } from "./interface";

import { fetchDeleteSimulationOption } from "~api/project/payment/simulation/option/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePaymentSimulationOptionKey =
  "api/project/payment/simulation/option/delete";

export function useFetchDeletePaymentSimulationOption({
  options,
}: IUseFetchDeletePaymentSimulationOption) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => fetchDeleteSimulationOption(payload), {
    ...options,
    mutationKey: useFetchDeletePaymentSimulationOptionKey,
    onSuccess: (data, variables, context) => {
      message.success("Simulação removida!");
      queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao remover simulação: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
