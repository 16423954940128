import { useQuery } from "react-query";

import type { IFetchGetElectricUtilityProfileAccessBy } from "./interface";

import { fetchGetElectricUtilityProfileAccessBy } from "~api/electric-utility-profile/access-by/get";

export const useFetchGetElectricUtilityProfileAccessByKey =
  "api/electric-utility-profile/access-by/get";

export function useFetchGetElectricUtilityProfileAccessBy({
  dependencyArray = [],
  options,
  payload = {
    initialism: "",
  },
}: IFetchGetElectricUtilityProfileAccessBy) {
  return useQuery(
    [useFetchGetElectricUtilityProfileAccessByKey, dependencyArray],
    async () => await fetchGetElectricUtilityProfileAccessBy(payload),
    options,
  );
}
