import { DealCustomFieldGroupListItemSchema } from "./DealCustomFieldGroupListItemSchema";
import { EntityIdentifierDTOSchema } from "./EntityIdentifierDTOSchema";

import { FieldTypeEnum } from "~types/enum";
import { z } from "zod";

export const DealCustomFieldValueResponseSchema = z.object({
  id: z
    .number({ message: "É esperado o ID do campo customizado do negócio" })
    .int({
      message: "O ID do campo customizado do negócio deve ser um inteiro",
    })
    .gt(0, {
      message:
        "O ID do campo de customizado do negócio deve ser maior que zero",
    }),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  type: FieldTypeEnum.nullish().transform((val) => val ?? undefined),
  value: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  displayOrder: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  options: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  group: DealCustomFieldGroupListItemSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  entityIdentifier: EntityIdentifierDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IDealCustomFieldValueResponseSchema
  extends z.infer<typeof DealCustomFieldValueResponseSchema> {}
