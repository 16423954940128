import { DataLowVoltageDTOSchema } from "./DataLowVoltageDTOSchema";
import { DataMediumVoltageDTOSchema } from "./DataMediumVoltageDTOSchema";

import { PhaseEnum } from "~types/enum";
import { z } from "zod";

export const ConsumptionUnitFormSchema = z
  .object({
    name: z.string(),
    code: z.string(),
    extra: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    lowVoltage: DataLowVoltageDTOSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
    mediumVoltage: DataMediumVoltageDTOSchema.nullish().transform(
      (val) => val ?? undefined,
    ),
    allocationPercentage: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
    powerPhase: PhaseEnum.nullish().transform((val) => val ?? undefined),
  })
  .partial();

export interface IConsumptionUnitFormSchema
  extends z.infer<typeof ConsumptionUnitFormSchema> {}
