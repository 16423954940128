import { useQuery } from "react-query";

import type { IUseFetchGetAllAldoModules } from "./interface";

import { fetchGetAllAldoModules } from "~api/equipments/aldo/modules/get";

export const useFetchGetAllAldoEquipmentModulesKey =
  "api/equipments/aldo/modules/get";

export function useFetchGetAllAldoEquipmentModules({
  dependencyArray = [],
  options,
}: IUseFetchGetAllAldoModules) {
  return useQuery(
    [useFetchGetAllAldoEquipmentModulesKey, dependencyArray],
    async () => await fetchGetAllAldoModules(),
    options,
  );
}
