import { api, apiException } from "~services/api";
import {
  DealDTOSchema,
  FetchGetDealByCustomIdPayloadSchema,
  IFetchGetDealByCustomIdPayloadSchema,
} from "~types/schemas";

export const fetchGetDealByCustomId = async (
  payload: IFetchGetDealByCustomIdPayloadSchema,
) => {
  try {
    const { customId } = FetchGetDealByCustomIdPayloadSchema.parse(payload);

    const response = await api.get(`/deal/${customId}`);

    const parsedData = DealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
