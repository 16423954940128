import { ProvinceSchema } from "./ProvinceSchema";

import { z } from "zod";

export const CitySchema = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  ibge: z.number().int(),
  province: ProvinceSchema.optional(),
});

export interface ICitySchema extends z.infer<typeof CitySchema> {}
