import { z } from "zod";

export const LeadTimeInsightGroupedByPipelineStageDataSchema = z.object({
  pipelineStageId: z
    .number({ message: "Faltando o ID do estágio do funil" })
    .int({ message: "ID do estágio do funil deve ser um número inteiro" }),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  position: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  deals: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  averageDays: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  averageHours: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ILeadTimeInsightGroupedByPipelineStageDataSchema
  extends z.infer<typeof LeadTimeInsightGroupedByPipelineStageDataSchema> {}
