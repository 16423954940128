import { useMutation } from "react-query";

import { useFetchGetOrganizationDefaultFieldSettingsKey } from "../useFetchGetOrganizationDefaultFieldSettings";
import type { IUseFetchPatchOrganizationDefaultFieldSettings } from "./interface";

import { fetchPatchOrganizationDefaultFieldSettings } from "~api/config/organizations/default-fields/settings/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchOrganizationDefaultFieldSettingsKey =
  "api/config/organizations/default-fields/settings/{id}/patch";

export function useFetchPatchOrganizationDefaultFieldSettings({
  options,
}: IUseFetchPatchOrganizationDefaultFieldSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) =>
      await fetchPatchOrganizationDefaultFieldSettings(payload),
    {
      ...options,
      mutationKey: useFetchPatchOrganizationDefaultFieldSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Configurações do campo atualizadas");
        queryClient.invalidateQueries(
          useFetchGetOrganizationDefaultFieldSettingsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
