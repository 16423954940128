import { z } from "zod";

export const ActivityDataSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  key_string: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  order_nr: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IActivityDataSchema
  extends z.infer<typeof ActivityDataSchema> {}
