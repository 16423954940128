import { LocationServiceEnum } from "~types/enum";
import { z } from "zod";

export const FetchUpdateLocationServicePayloadSchema = z.object({
  userId: z.number().int().gt(0),
  locationService: LocationServiceEnum,
});

export interface IFetchUpdateLocationServicePayloadSchema
  extends z.infer<typeof FetchUpdateLocationServicePayloadSchema> {}
