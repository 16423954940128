import { z } from "zod";

export const FetchGetPaginatedProductModulePowersPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => (!val ? 100 : val)),
  manufacturer: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedProductModulePowersPayloadSchema
  extends z.infer<typeof FetchGetPaginatedProductModulePowersPayloadSchema> {}
