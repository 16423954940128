import { api, apiException } from "~services/api";
import { LogisticsDTOSchema } from "~types/schemas";

export const fetchGetLogistics = async () => {
  try {
    const response = await api.get(`/logistics`);

    const parsedData = LogisticsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
