import { api, apiException } from "~services/api";
import {
  type IFetchPatchSysAdminUserPayloadSchema,
  FetchPatchSysAdminUserPayloadSchema,
} from "~types/schemas";

export const fetchPatchSysAdminManagement = async (
  payload: IFetchPatchSysAdminUserPayloadSchema,
) => {
  try {
    const { id } = FetchPatchSysAdminUserPayloadSchema.parse(payload);

    await api.patch(`/sys-admin-management/disable/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
