import { FieldTypeEnum, ProposalSectionEnum } from "~types/enum";
import { z } from "zod";

export const CustomFieldFormSchema = z
  .object({
    id: z.number().int().gte(0),
    identifier: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    label: z.string(),
    proposalSection: ProposalSectionEnum,
    type: FieldTypeEnum,
    defaultValue: z.string(),
    required: z.boolean(),
    enabled: z.boolean(),
    options: z.array(z.string()).nullish(),
  })
  .partial();

export interface ICustomFieldFormSchema
  extends z.infer<typeof CustomFieldFormSchema> {}
