import { DealCustomFieldDTOSchema } from "./DealCustomFieldDTOSchema";

import { z } from "zod";

export const DealCustomFieldGroupDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gte(0) // ZERO IS ACCEPTABLE
    .nullish()
    .transform((val) => val ?? undefined), // ID IN DEAL CUSTOM FIELD GROUP IS NOT REQUIRED,
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  dealCustomFields: DealCustomFieldDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IDealCustomFieldGroupDTOSchema
  extends z.infer<typeof DealCustomFieldGroupDTOSchema> {}
