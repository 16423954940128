import { ItemKitProjectSchema } from "./ItemKitProjectSchema";
import { StructureTypeSchema } from "./StructureTypeSchema";

import { PhaseEnum } from "~types/enum";
import { z } from "zod";

export const EquipmentKitProjectDTOSchema = z
  .object({
    supplierName: z.string(),
    typeEstructure: StructureTypeSchema,
    name: z.string(),
    inverterBrand: z.string(),
    moduleBrand: z.string(),
    fullPower: z.number(),
    totalValue: z.number(),
    minArea: z.number(),
    outputVoltage: PhaseEnum,
    inverterPower: z.number(),
    items: z.array(ItemKitProjectSchema),
  })
  .partial();

export interface IEquipmentKitProjectDTOSchema
  extends z.infer<typeof EquipmentKitProjectDTOSchema> {}
