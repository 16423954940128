import { z } from "zod";

export const FetchGetDealCustomFieldByPipelineStageIdPayloadSchema = z.object({
  pipelineStageId: z.number().int().gt(0),
});

export interface IFetchGetDealCustomFieldByPipelineStageIdPayloadSchema
  extends z.infer<
    typeof FetchGetDealCustomFieldByPipelineStageIdPayloadSchema
  > {}
