import { z } from "zod";

export const DiverseServiceDTOSchema = z.object({
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IDiverseServiceDTOSchema
  extends z.infer<typeof DiverseServiceDTOSchema> {}
