import { PaginationSchema } from "./PaginationSchema";
import { RuleExpressionDTOSchema } from "./RuleExpressionDTOSchema";

import { z } from "zod";

export const PaginatedRuleExpressionDTOSchema = PaginationSchema(
  RuleExpressionDTOSchema,
);

export interface IPaginatedRuleExpressionDTOSchema
  extends z.infer<typeof PaginatedRuleExpressionDTOSchema> {}
