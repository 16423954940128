import { z } from "zod";

export const ProjectItemKitFormSchema = z.object({
  name: z.string(),
  brand: z.string().optional(),
  category: z.string().optional(),
  value: z.number(),
  quantity: z.number(),
  description: z.string(),
  systemGenerated: z.boolean().optional(),
  manuallyAdded: z.boolean().optional(),
  additionalCostDescriptorId: z.number().int().optional(),
  deactivated: z.boolean().optional(),
  print: z.boolean().optional(),
  groupName: z.string().optional(),
  totalValue: z.number().optional(),
  nonDeactivated: z.boolean().optional(),
});

export interface IProjectItemKitFormSchema
  extends z.infer<typeof ProjectItemKitFormSchema> {}
