import { z } from "zod";

export const FetchGetDefaultFieldSettingsPayloadSchema = z.object({
  pricingTypeId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetDefaultFieldSettingsPayloadSchema
  extends z.infer<typeof FetchGetDefaultFieldSettingsPayloadSchema> {}
