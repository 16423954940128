import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedOtherProductsPayloadSchema,
  IFetchGetPaginatedOtherProductsPayloadSchema,
  PaginatedCustomProductDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedOtherProducts = async (
  payload: IFetchGetPaginatedOtherProductsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedOtherProductsPayloadSchema.parse(payload);

    const response = await api.get(`/product/other`, {
      params,
    });

    const parsedData = PaginatedCustomProductDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
