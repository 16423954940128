import { PricingTypeFormSchema } from "./PricingTypeFormSchema";

import { z } from "zod";

export const FetchUpdatePricingTypesPayloadSchema =
  PricingTypeFormSchema.extend({
    pricingId: z.number().int().gt(0),
  });

export interface IFetchUpdatePricingTypesPayloadSchema
  extends z.infer<typeof FetchUpdatePricingTypesPayloadSchema> {}
