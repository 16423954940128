import { api, apiException } from "~services/api";
import {
  FetchDeleteCustomFieldPayloadSchema,
  IFetchDeleteCustomFieldPayloadSchema,
} from "~types/schemas";

export type FetchDeleteCustomField = {
  path: {
    id: number;
  };
};

export const fetchDeleteCustomField = async (
  payload: IFetchDeleteCustomFieldPayloadSchema,
) => {
  try {
    const { customFieldId } =
      FetchDeleteCustomFieldPayloadSchema.parse(payload);

    return await api.delete<boolean>(`/config/customFields/${customFieldId}`);
  } catch (error) {
    throw apiException(error);
  }
};
