import { AddressFormSchema } from "./AddressFormSchema";

import { z } from "zod";

export const FetchUpdateBaseAddressPayloadSchema = AddressFormSchema.extend({
  baseAddressId: z.number().int().gt(0),
});

export interface IFetchUpdateBaseAddressPayloadSchema
  extends z.infer<typeof FetchUpdateBaseAddressPayloadSchema> {}
