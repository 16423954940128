import { z } from "zod";

export const KomecoParameterFormSchema = z.object({
  password: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IKomecoParameterFormSchema
  extends z.infer<typeof KomecoParameterFormSchema> {}
