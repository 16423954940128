import { useQuery } from "react-query";

import type { IUseFetchGetDealDefaultFieldsSettings } from "./interface";

import { fetchGetDealDefaultFieldsSettings } from "~api/config/deals/default-fields/settings/get";

export const useFetchGetDealDefaultFieldsSettingsKey =
  "api/config/deals/default-fields/settings/get";

export function useFetchGetDealDefaultFieldsSettings({
  dependencyArray = [],
  options,
}: IUseFetchGetDealDefaultFieldsSettings) {
  return useQuery(
    [useFetchGetDealDefaultFieldsSettingsKey, dependencyArray],
    async () => await fetchGetDealDefaultFieldsSettings(),
    options,
  );
}
