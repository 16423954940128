import { z } from "zod";

export const FetchPostToGetRevisionPdfAsBase64 = z.object({
  id: z
    .number({ message: "ID da revisão é obrigatório!" })
    .int({ message: "ID da revisão deve ser um inteiro" })
    .gt(0, { message: "ID da revisão deve ser maior do que zero" }),
});

export interface IFetchPostToGetRevisionPdfAsBase64
  extends z.infer<typeof FetchPostToGetRevisionPdfAsBase64> {}
