import { DealCustomFieldValueSchema } from "./DealCustomFieldValueSchema";

import { z } from "zod";

export const CreateDealFormSchema = z.object({
  name: z.string(),
  pipelineId: z.number().int().gt(0),
  pipelineStageId: z.number().int().gt(0),
  personId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  organizationId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  ownerId: z.number().int().gt(0),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  customFieldValues: DealCustomFieldValueSchema.array()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ICreateDealFormSchema
  extends z.infer<typeof CreateDealFormSchema> {}
