import { api, apiException } from "~services/api";
import {
  FetchChangePasswordPayloadSchema,
  IFetchChangePasswordPayloadSchema,
  UserAccountDTOSchema,
} from "~types/schemas";

export const fetchChangePassword = async (
  payload: IFetchChangePasswordPayloadSchema,
) => {
  try {
    const body = FetchChangePasswordPayloadSchema.parse(payload);

    const response = await api.post(`/users/change-password`, body);

    const parsedData = UserAccountDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
