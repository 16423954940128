import { AdditionalCostCalculationScaleItemSchema } from "./AdditionalCostCalculationScaleItemSchema";
import { BusinessUnitDTOSchema } from "./BusinessUnitDTOSchema";
import { CostAggregatorDTOSchema } from "./CostAggregatorDTOSchema";
import { PricingTypeDTOSchema } from "./PricingTypeDTOSchema";

import {
  CalculationTypeEnum,
  ScaleEnum,
  CostGroupEnum,
  ValueFlexibilityTypeEnum,
} from "~types/enum";
import { z } from "zod";

export const AdditionalCostDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  calculationType: CalculationTypeEnum,
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  value: z.number().nullish().default(0),
  minValue: z.number().nullish().default(0),
  baseCalculationType: ScaleEnum.nullish().transform((val) => val ?? undefined),
  active: z.boolean(),
  fixed: z.boolean(),
  advanced: z.boolean(),
  businessUnit: BusinessUnitDTOSchema,
  isAuxVariable: z.boolean(),
  additionalCostTaxId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  notApplyDefaultAdditionalCostTax: z.boolean(),
  ignoreViewPricePermission: z.boolean(),
  costGroup: CostGroupEnum,
  customExpression: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  customConditional: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  scaleType: ScaleEnum.nullish().transform((val) => val ?? undefined),
  executionOrder: z.number(),
  scale: z.array(AdditionalCostCalculationScaleItemSchema).default([]),
  valueFlexibilityType: ValueFlexibilityTypeEnum,
  conditional: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined)
    .default(false),
  costAggregators: z
    .array(CostAggregatorDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  customExpressionForScale: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  pricingType: PricingTypeDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IAdditionalCostDTOSchema
  extends z.infer<typeof AdditionalCostDTOSchema> {}
