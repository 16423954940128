import { z } from "zod";

export const HistoryUpdateInfoDTOSchema = z.object({
  id: z.number().int().gt(0),
  userName: z.string(),
  createdAt: z.string(),
});

export interface IHistoryUpdateInfoDTOSchema
  extends z.infer<typeof HistoryUpdateInfoDTOSchema> {}
