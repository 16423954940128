import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedLaborsPayloadSchema,
  IFetchGetPaginatedLaborsPayloadSchema,
  PaginatedLaborDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedLabors = async (
  payload: IFetchGetPaginatedLaborsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedLaborsPayloadSchema.parse(payload);

    const response = await api.get(`/labors`, {
      params,
    });

    const parsedData = PaginatedLaborDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
