import { ENV } from "./Env";

export const TOKEN_STORAGE_KEY = "@solarz_crm:session-token-1.0.0";
export const BACKUP_TOKEN_STORAGE_KEY =
  "@solarz_crm:backup-session-token-1.0.0";
export const PATH_BEFORE_REDIRECT = "@solarz_crm:session_path_redirect-1.0.0";
export const PIPEDRIVE_CLIENT_ID = ENV.PIPEDRIVE_CLIENT_ID;
export const PIPEDRIVE_REDIRECT_URI = ENV.PIPEDRIVE_REDIRECT_URI;
export const AFTER_SIZING_STEP_STORAGE_KEY =
  "@solarz_crm:after-sizing-step-1.0.0";
