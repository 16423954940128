import { useMutation } from "react-query";

import { useFetchGetProposalExpirationRuleKey } from "../useFetchGetProposalExpirationRule";
import type { IUseFetchDeleteProposalExpirationRule } from "./interface";

import { fetchDeleteProposalExpirationRule } from "~api/proposal-expiration-rule/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteProposalExpirationRuleKey =
  "api/proposal-expiration-rule/{id}/delete";

export function useFetchDeleteProposalExpirationRule(
  options?: IUseFetchDeleteProposalExpirationRule,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => fetchDeleteProposalExpirationRule(payload),
    {
      ...options,
      mutationKey: useFetchDeleteProposalExpirationRuleKey,
      onSuccess: (data, variables, context) => {
        message.success("Regra foi removida!");
        queryClient.invalidateQueries(useFetchGetProposalExpirationRuleKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao remover regra: " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
