import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveUnlinkedAutoCompletePerson } from "./interface";

import { fetchGetPipedriveUnlinkedAutoCompletePerson } from "~api/persons/pipedrive-unlinked/auto-complete/get";

export const fetchGetPipedriveUnlinkedAutoCompletePersonKey =
  "api/persons/pipedrive-unlinked/auto-complete/get";

export function useFetchGetPipedriveUnlinkedAutoCompletePerson({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetPipedriveUnlinkedAutoCompletePerson) {
  return useQuery(
    [fetchGetPipedriveUnlinkedAutoCompletePersonKey, dependencyArray],
    async () => await fetchGetPipedriveUnlinkedAutoCompletePerson(payload),
    options,
  );
}
