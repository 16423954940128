import { DealCustomFieldOrderFormSchema } from "./DealCustomFieldOrderFormSchema";

import { z } from "zod";

export const FetchUpdateDealCustomFieldOrderFormPayloadSchema = z.object({
  body: z.array(DealCustomFieldOrderFormSchema).optional(),
});

export interface IFetchUpdateDealCustomFieldOrderFormPayloadSchema
  extends z.infer<typeof FetchUpdateDealCustomFieldOrderFormPayloadSchema> {}
