import { z } from "zod";

export const CreateDealCustomFieldGroupFormSchema = z.object({
  name: z.string({
    message: "Nome do grupo de variáveis customizado de negócio é obrigatório",
  }),
});

export interface ICreateDealCustomFieldGroupFormSchema
  extends z.infer<typeof CreateDealCustomFieldGroupFormSchema> {}
