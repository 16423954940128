import { api, apiException } from "~services/api";
import { ProposalExpirationRuleDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetProposalExpirationRule = async () => {
  try {
    const response = await api.get(`/proposal-expiration-rule`);

    const parsedData = z
      .array(ProposalExpirationRuleDTOSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
