import { useMutation } from "react-query";

import { useFetchGetLogisticsKey } from "../useFetchGetLogistics";
import type { IUseFetchUpdateLogistics } from "./interface";

import { fetchUpdateLogistics } from "~api/logistics/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateLogisticsKey = "api/logistics/{id}/put";

export function useFetchUpdateLogistics({ options }: IUseFetchUpdateLogistics) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUpdateLogistics(payload), {
    ...options,
    mutationKey: useFetchUpdateLogisticsKey,
    onSuccess: (data, variables, context) => {
      message.success("Logística alterada com sucesso!");
      queryClient.invalidateQueries(useFetchGetLogisticsKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao alterar logística: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
