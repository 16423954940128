import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetOrganizationDealsCountByOrganizationIdKey } from "../../organizations/useFetchGetOrganizationDealsCountByOrganizationId";
import { useFetchGetPaginatedOrganizationDealsByOrganizationIdKey } from "../../organizations/useFetchGetPaginatedOrganizationDealsByOrganizationId";
import { useFetchGetPaginatedPersonDealsByPersonIdKey } from "../../persons/useFetchGetPaginatedPersonDealsByPersonId";
import { useFetchGetPersonDealsCountByPersonIdKey } from "../../persons/useFetchGetPersonDealsCountByPersonId";
import { useFetchGetAllPipelinesKey } from "../../pipeline/useFetchGetAllPipelines";
import { useFetchGetAllPipelinesDetailedKey } from "../../pipeline/useFetchGetAllPipelinesDetailed";
import { useFetchGetPipelineByIdKey } from "../../pipeline/useFetchGetPipelineById";
import { useFetchGetPipelineDealsListByIdKey } from "../../pipeline/useFetchGetPipelineDealsListById";
import { useFetchSearchDealKey } from "../useFetchSearchDeal";
import type { IUseFetchCreateDeal } from "./interface";

import { fetchCreateDeal } from "~api/deal/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateDealKey = "api/deal/post";

export function useFetchCreateDeal({ options }: IUseFetchCreateDeal) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchCreateDeal(payload), {
    ...options,
    mutationKey: useFetchCreateDealKey,
    onSuccess: (data, variables, context) => {
      message.success("Negócio foi criado!");
      queryClient.invalidateQueries(
        useFetchGetPaginatedPersonDealsByPersonIdKey,
      );
      queryClient.invalidateQueries(
        useFetchGetOrganizationDealsCountByOrganizationIdKey,
      );
      queryClient.invalidateQueries(useFetchGetPipelineByIdKey);
      queryClient.invalidateQueries(useFetchSearchDealKey);
      queryClient.invalidateQueries(useFetchGetPipelineDealsListByIdKey);
      queryClient.invalidateQueries(useFetchGetAllPipelinesKey);
      queryClient.invalidateQueries(useFetchGetAllPipelinesDetailedKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedHistoriesFilterByDealIdKey,
      );
      queryClient.invalidateQueries(useFetchGetHistoriesTypeFilterByDealIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
      queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedPersonDealsByPersonIdKey,
      );
      queryClient.invalidateQueries(useFetchGetPersonDealsCountByPersonIdKey);
      queryClient.invalidateQueries(
        useFetchGetPaginatedOrganizationDealsByOrganizationIdKey,
      );

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar negócio: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
