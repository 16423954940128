import { useQuery } from "react-query";

import type { IUseFetchGetPersonInformationByPipedriveId } from "./interface";

import { fetchGetPersonInformationByPipedriveId } from "~api/clients/pipedrive/{pipedriveId}/get";

export const useFetchGetPersonInformationByPipedriveIdKey =
  "api/clients/pipedrive/{pipedriveId}/get";

export function useFetchGetPersonInformationByPipedriveId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPersonInformationByPipedriveId) {
  return useQuery(
    [useFetchGetPersonInformationByPipedriveIdKey, dependencyArray],
    async () => await fetchGetPersonInformationByPipedriveId(payload),
    options,
  );
}
