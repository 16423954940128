import { z } from "zod";

export const FetchDeletePipelinePayloadSchema = z.object({
  pipelineId: z.number().int().gt(0),
  destinationPipelineId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchDeletePipelinePayloadSchema
  extends z.infer<typeof FetchDeletePipelinePayloadSchema> {}
