import { z } from "zod";

export const ProfileImageDTOSchema = z.object({
  file: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IProfileImageDTOSchema
  extends z.infer<typeof ProfileImageDTOSchema> {}
