import { LogActionTypeEnum, LogEntityTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetPaginatedUsageLogsPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .lte(100)
    .nullish()
    .transform((val) => val ?? 10),
  userName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  date: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined), // FORMAT YYYY/MM/DD
  startAt: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined), // FORMAT YYYY/MM/DD
  endAt: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined), // FORMAT YYYY/MM/DD
  entity: LogEntityTypeEnum.nullish().transform((val) => val ?? undefined),
  action: LogActionTypeEnum.nullish().transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedUsageLogsPayloadSchema
  extends z.infer<typeof FetchGetPaginatedUsageLogsPayloadSchema> {}
