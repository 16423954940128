import { useMutation } from "react-query";

import { useFetchGetAllCustomVariablesKey } from "../useFetchGetAllCustomVariables";
import type { IUseFetchUpdateCustomVariable } from "./interface";

import { fetchUpdateCustomVariable } from "~api/custom-variable/{name}/put";
import {
  useFetchGetCustomVariablesWithFieldBindKey,
  useFetchGetVariablesWithFieldKey,
} from "~hooks/api";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateCustomVariableKey = "api/custom-variable/{name}/put";

export function useFetchUpdateCustomVariable({
  options,
}: IUseFetchUpdateCustomVariable) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateCustomVariable(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateCustomVariableKey,
      onSuccess: (data, variables, context) => {
        message.success("Variável customizada foi alterada");
        queryClient.invalidateQueries(useFetchGetAllCustomVariablesKey);
        queryClient.invalidateQueries(useFetchGetVariablesWithFieldKey);
        queryClient.invalidateQueries(
          useFetchGetCustomVariablesWithFieldBindKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao alterar variável customizada: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
