import { z } from "zod";

export const FieldCategoryEnum = z
  .enum([
    "PROJECT_DATA",
    "CLIENT_DATA",
    "ADDRESS_DATA",
    "TAXES_DATA",
    "SIZING_DATA",
  ] as const)
  .catch((e) => e.input);

export type FieldCategoryEnum = z.infer<typeof FieldCategoryEnum>;
