import { api, apiException } from "~services/api";
import {
  FetchGetAutoCompleteProposalPayloadSchema,
  type IFetchGetAutoCompleteProposalPayloadSchema,
  ProposalAutoCompleteResponseSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetAutoCompleteProposal = async (
  payload?: IFetchGetAutoCompleteProposalPayloadSchema,
) => {
  try {
    const params = FetchGetAutoCompleteProposalPayloadSchema.parse(payload);

    const response = await api.get("/proposal/auto-complete", {
      params,
    });

    const parsedData = z
      .array(ProposalAutoCompleteResponseSchema)
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
