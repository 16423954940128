import { useQuery } from "react-query";

import { IUseFetchGetHistoriesFilterByDealId } from "./interface";

import { fetchGetPaginatedHistoriesFilterByDealId } from "~api/histories/deal/filters/get";

export const useFetchGetPaginatedHistoriesFilterByDealIdKey =
  "api/histories/deal/filters/get";

export function useFetchGetPaginatedHistoriesFilterByDealId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesFilterByDealId) {
  return useQuery(
    [useFetchGetPaginatedHistoriesFilterByDealIdKey, dependencyArray],
    async () => await fetchGetPaginatedHistoriesFilterByDealId(payload),
    options,
  );
}
