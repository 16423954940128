import { TaxTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetPaginatedAneelInDevelopmentPayloadSchema = z.object({
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .lte(100)
    .nullish()
    .transform((val) => val ?? 10),
  concessionaire: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  subgroup: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  class: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  subclass: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  detail: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  accessBy: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  modality: TaxTypeEnum.nullish().transform((val) => val ?? undefined),
  tusd: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  te: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  icms: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  pisCofins: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetPaginatedAneelInDevelopmentPayloadSchema
  extends z.infer<typeof FetchGetPaginatedAneelInDevelopmentPayloadSchema> {}
