import { api, apiException } from "~services/api";
import {
  FetchPostToGetEstimatedPowerPlantPayloadSchema,
  type IFetchPostToGetEstimatedPowerPlantPayloadSchema,
  StepTwoEstimatedPowerDTOSchema,
} from "~types/schemas";

export const fetchPostToGetEstimatedPowerPlant = async (
  payload: IFetchPostToGetEstimatedPowerPlantPayloadSchema,
) => {
  try {
    const body = FetchPostToGetEstimatedPowerPlantPayloadSchema.parse(payload);

    const response = await api.post(`/projects/step_two/expected-power`, body);

    const parsedData = StepTwoEstimatedPowerDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
