import { api, apiException } from "~services/api";
import {
  AdditionalCostMarginTypeDTOSchema,
  FetchCreateAdditionalCostMarginTypePayloadSchema,
  IFetchCreateAdditionalCostMarginTypePayloadSchema,
} from "~types/schemas";

export const fetchCreateAdditionalCostMarginType = async (
  payload: IFetchCreateAdditionalCostMarginTypePayloadSchema,
) => {
  try {
    const body =
      FetchCreateAdditionalCostMarginTypePayloadSchema.parse(payload);

    const response = await api.post(`/additional-cost-margin-type`, body);

    const parsedData = AdditionalCostMarginTypeDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
