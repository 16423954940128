import { useMutation } from "react-query";

import { useFetchGetPaginatedAlternatingCurrentMaterialsKey } from "../useFetchGetPaginatedAlternatingCurrentMaterials";
import type { IUseFetchDeleteAlternatingCurrentMaterial } from "./interface";

import { fetchDeleteAlternatingCurrentMaterial } from "~api/ac-materials/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteAlternatingCurrentMaterialKey =
  "api/ac-materials/{id}/delete";

export function useFetchDeleteAlternatingCurrentMaterial(
  options?: IUseFetchDeleteAlternatingCurrentMaterial,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      const response = await fetchDeleteAlternatingCurrentMaterial(payload);
      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchDeleteAlternatingCurrentMaterialKey,
      onSuccess: (data, variables, context) => {
        message.success("Material de corrente alternada foi removido!");
        queryClient.invalidateQueries(
          useFetchGetPaginatedAlternatingCurrentMaterialsKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(
          `Falha ao remover material de corrente alternada: ${error.message}`,
        );
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
