import { useQuery } from "react-query";

import type { IUseFetchGetProposalStepOneInitialValues } from "./interface";

import { fetchGetProposalStepOneInitialValues } from "~api/projects/step_one/auto-complete/get";

export const useFetchGetProposalStepOneInitialValuesKey =
  "api/projects/step_one/auto-complete/get";

export function useFetchGetProposalStepOneInitialValues({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProposalStepOneInitialValues) {
  return useQuery(
    [useFetchGetProposalStepOneInitialValuesKey, dependencyArray],
    async () => await fetchGetProposalStepOneInitialValues(payload),
    options,
  );
}
