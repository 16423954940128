import { api, apiException } from "~services/api";
import { PermissionsEnum } from "~types/enum";
import {
  FetchGetPermissionsAssignToProfileByProfileIdPayloadSchema,
  IFetchGetPermissionsAssignToProfileByProfileIdPayloadSchema,
} from "~types/schemas";

export const fetchPermissionsAssignToProfileByProfileId = async (
  payload: IFetchGetPermissionsAssignToProfileByProfileIdPayloadSchema,
) => {
  try {
    const { profileId } =
      FetchGetPermissionsAssignToProfileByProfileIdPayloadSchema.parse(payload);

    const response = await api.get(`/permissions/by-profile/${profileId}`);

    const parsedData = PermissionsEnum.array()
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
