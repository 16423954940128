import { z } from "zod";

export const ProposalDealResponseSchema = z.object({
  dealId: z.number().int().gt(0),
  dealCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  dealStatus: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IProposalDealResponseSchema
  extends z.infer<typeof ProposalDealResponseSchema> {}
