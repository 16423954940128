import { CustomPvModuleStructureDTOSchema } from "./CustomPvModuleStructureDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedCustomPvModuleStructureDTOSchema = PaginationSchema(
  CustomPvModuleStructureDTOSchema,
);

export interface IPaginatedCustomPvModuleStructureDTOSchema
  extends z.infer<typeof PaginatedCustomPvModuleStructureDTOSchema> {}
