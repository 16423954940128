import { z } from "zod";

export const CustomVariableDTOSchema = z.object({
  id: z.number().int().optional(),
  name: z.string(),
  value: z.string(),
  description: z.string().optional(),
});

export interface ICustomVariableDTOSchema
  extends z.infer<typeof CustomVariableDTOSchema> {}
