import { useMutation } from "react-query";

import { useFetchGetPaginatedBusinessUnitsKey } from "../useFetchGetPaginatedBusinessUnits";
import { useFetchGetUserBusinessUnitsKey } from "../useFetchGetUserBusinessUnits";
import type { IUseFetchCreateBusinessUnit } from "./interface";

import { fetchCreateBusinessUnit } from "~api/business-units/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateBusinessUnitKey = "api/business-units/{id}/put";

export function useFetchCreateBusinessUnit({
  options,
}: IUseFetchCreateBusinessUnit) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateBusinessUnit(payload),
    {
      ...options,
      mutationKey: useFetchCreateBusinessUnitKey,
      onSuccess: (data, variables, context) => {
        message.success("Unidade de negócio criada");
        queryClient.invalidateQueries(useFetchGetPaginatedBusinessUnitsKey);
        queryClient.invalidateQueries(useFetchGetUserBusinessUnitsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
