import { api, apiException } from "~services/api";
import type { CustomFieldDto } from "~types/api/CustomFieldDto";
import {
  CustomFieldDTOSchema,
  FetchUpdateCustomFieldPayloadSchema,
  IFetchUpdateCustomFieldPayloadSchema,
} from "~types/schemas";

export const fetchUpdateCustomField = async (
  payload: IFetchUpdateCustomFieldPayloadSchema,
) => {
  try {
    const { ...body } = FetchUpdateCustomFieldPayloadSchema.parse(payload);

    const response = await api.put<CustomFieldDto>(
      `/config/customFields`,
      body,
    );

    const parsedData = CustomFieldDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
