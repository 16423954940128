import { useQuery } from "react-query";

import type { IUseFetchGetTotalWonGroupedByUserInsight } from "./interface";

import { fetchGetTotalWonGroupedByUserInsight } from "~api/insights/totalWonGroupedByUserInsight/get";

export const useFetchGetTotalWonGroupedByUserInsightKey =
  "api/insights/totalWonGroupedByUserInsight/get";

export function useFetchGetTotalWonGroupedByUserInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetTotalWonGroupedByUserInsight) {
  return useQuery(
    [useFetchGetTotalWonGroupedByUserInsightKey, dependencyArray],
    async () => await fetchGetTotalWonGroupedByUserInsight(payload),
    options,
  );
}
