import { useQuery } from "react-query";

import type { IUseFetchGetEdeltecStructureByStructureId } from "./interface";

import { fetchGetEdeltecStructureByStructureId } from "~api/equipments/edeltec/structure-by-relationship/{structureId}/get";

export const useFetchGetEdeltecStructureByStructureIdKey =
  "api/equipments/edeltec/structure-by-relationship/{structureId}/get";

export function useFetchGetEdeltecStructureByStructureId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetEdeltecStructureByStructureId) {
  return useQuery(
    [useFetchGetEdeltecStructureByStructureIdKey, dependencyArray],
    async () => await fetchGetEdeltecStructureByStructureId(payload),
    options,
  );
}
