import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import type { IUseFetchAdminCreateUser } from "./interface";

import { fetchAdminCreateUser } from "~api/admin/createUser/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchAdminCreateUserKey = "api/admin/createUser/post";

export function useFetchAdminCreateUser({ options }: IUseFetchAdminCreateUser) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchAdminCreateUser(payload), {
    ...options,
    mutationKey: useFetchAdminCreateUserKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário adicionado com sucesso!");
      queryClient.invalidateQueries(useFetchGetAdminUsersKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao criar usuário: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
