import { api, apiException } from "~services/api";
import {
  AdditionalCostTaxDTOSchema,
  ICreateAdditionalCostTaxFormSchema,
  CreateAdditionalCostTaxFormSchema,
} from "~types/schemas";

export type FetchCreateAdditionalCostTax = {
  body: ICreateAdditionalCostTaxFormSchema;
};

export const fetchCreateAdditionalCostTax = async ({
  body,
}: FetchCreateAdditionalCostTax) => {
  try {
    const parsedBody = CreateAdditionalCostTaxFormSchema.parse(body);

    const response = await api.post(`/config/additionalCostTax`, parsedBody);

    const parsedData = AdditionalCostTaxDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
