import { z } from "zod";

export const MediumVoltageTaxesDTOSchema = z.object({
  teP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdG: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxTusdG: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFP: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tePFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdFPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBFPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdGFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxTusdGFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  contractedDemandTaxFPFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  unity: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IMediumVoltageTaxesDTOSchema
  extends z.infer<typeof MediumVoltageTaxesDTOSchema> {}
