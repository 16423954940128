import { z } from "zod";

export const ProgressInsightBarsDataSchema = z.object({
  pipelineStageId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  pipelineStageName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  totalWon: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  pipelinePosition: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IProgressInsightBarsDataSchema
  extends z.infer<typeof ProgressInsightBarsDataSchema> {}
