import { z } from "zod";

export const LaborCopierFormSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ILaborCopierFormSchema
  extends z.infer<typeof LaborCopierFormSchema> {}
