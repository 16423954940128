import { useQuery } from "react-query";

import type { IUseFetchGetDatasheetRevisionById } from "./interface";

import { fetchGetDatasheetLastRevision } from "~api/admin/datasheets/{id}/last-revision/get";

export const useFetchGetDatasheetRevisionByIdKey =
  "api/admin/datasheets/{id}/download-last-revision/get";

export function useFetchGetDatasheetRevisionById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDatasheetRevisionById) {
  return useQuery(
    [useFetchGetDatasheetRevisionByIdKey, dependencyArray],
    async () => await fetchGetDatasheetLastRevision(payload),
    options,
  );
}
