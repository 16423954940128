import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedProductInverterManufacturersPayloadSchema,
  IFetchGetPaginatedProductInverterManufacturersPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetPaginatedProductInverterManufacturers = async (
  payload: IFetchGetPaginatedProductInverterManufacturersPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedProductInverterManufacturersPayloadSchema.parse(payload);

    const response = await api.get(
      `/product/search/inverter/manufacturerList`,
      {
        params,
      },
    );

    const parsedData = z.array(z.string()).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
