import { CustomProductDTOSchema } from "./CustomProductDTOSchema";

import { z } from "zod";

export const CustomDiverseKitProductEntryDTOSchema = z.object({
  id: z.number().int().gt(0),
  customProduct: CustomProductDTOSchema,
  quantity: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ICustomDiverseKitProductEntryDTOSchema
  extends z.infer<typeof CustomDiverseKitProductEntryDTOSchema> {}
