import { DealTotalValueTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchPatchDealTotalValueTypePayloadSchema = z.object({
  customId: z.number().int().gt(0),
  dealUpdateTotalValueType: DealTotalValueTypeEnum,
});

export interface IFetchPatchDealTotalValueTypePayloadSchema
  extends z.infer<typeof FetchPatchDealTotalValueTypePayloadSchema> {}
