import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAdditionalCostsPayloadSchema,
  IFetchGetPaginatedAdditionalCostsPayloadSchema,
  PaginatedAdditionalCostsDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedComplementaryItems = async (
  payload: IFetchGetPaginatedAdditionalCostsPayloadSchema,
) => {
  try {
    const params = FetchGetPaginatedAdditionalCostsPayloadSchema.parse(payload);

    const response = await api.get(`/additional-costs?no-aux-variables=true`, {
      params,
    });

    const parsedData = PaginatedAdditionalCostsDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
