import { EosContactSchema } from "./EosContactSchema";

import { z } from "zod";

export const EosPersonIdentitySchema = z.object({
  nomeCompleto: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  cpf: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  contato: EosContactSchema.nullish().transform((val) => val ?? undefined),
});

export interface IEosPersonIdentitySchema
  extends z.infer<typeof EosPersonIdentitySchema> {}
