import { useQuery } from "react-query";

import type { IUseFetchGetCustomFields } from "./interface";

import { fetchGetCustomFields } from "~api/config/customFields/get";

export const useFetchGetCustomFieldsKey = "api/config/customFields/get";

export function useFetchGetCustomFields({
  dependencyArray = [],
  payload = {
    proposalSection: undefined,
    projectType: undefined,
  },
  options,
}: IUseFetchGetCustomFields) {
  return useQuery(
    [useFetchGetCustomFieldsKey, dependencyArray],
    async () => {
      const data = await fetchGetCustomFields(payload);

      const sortedData = [...data].sort(
        (a, b) => (a.displayOrder ?? 999999) - (b.displayOrder ?? 999999),
      );

      return sortedData;
    },
    options,
  );
}
