import { KomecoStructureTypeEnum } from "~types/enum";
import { z } from "zod";

export const KomecoEquipmentStructureDTOSchema = z.object({
  structureType: KomecoStructureTypeEnum,
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IKomecoEquipmentStructureDTOSchema
  extends z.infer<typeof KomecoEquipmentStructureDTOSchema> {}
