import { useQuery } from "react-query";

import type { IFetchGetPaginatedProductModulePowers } from "./interface";

import { fetchGetPaginatedProductModulePowers } from "~api/product/search/pvModule/modulePowerList/get";

export const useFetchGetPaginatedProductModulePowersKey =
  "api/product/search/pvModule/modulePowerList/get";

export function useFetchGetPaginatedProductModulePowers({
  dependencyArray = [],
  options,
  payload,
}: IFetchGetPaginatedProductModulePowers) {
  return useQuery(
    [useFetchGetPaginatedProductModulePowersKey, dependencyArray],
    async () => await fetchGetPaginatedProductModulePowers(payload),
    options,
  );
}
