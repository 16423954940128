import { api, apiException } from "~services/api";
import {
  FetchDeleteLogisticsPayloadSchema,
  IFetchDeleteLogisticsPayloadSchema,
} from "~types/schemas";

export const fetchDeleteLogistics = async (
  payload: IFetchDeleteLogisticsPayloadSchema,
) => {
  try {
    const { logisticsId } = FetchDeleteLogisticsPayloadSchema.parse(payload);
    await api.delete<void>(`/logistics/${logisticsId}`);
  } catch (error) {
    throw apiException(error);
  }
};
