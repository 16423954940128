import { z } from "zod";

export const LogChangeTypeEnum = z
  .enum([
    "PROPERTY_ADDED",
    "PROPERTY_REMOVED",
    "PROPERTY_VALUE_CHANGED",
  ] as const)
  .catch((e) => e.input);

export type LogChangeTypeEnum = z.infer<typeof LogChangeTypeEnum>;
