import { PipedriveAddressDTOSchema } from "./PipedriveAddressDTOSchema";

import { z } from "zod";

export const OrganizationDetailPipedriveDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? 0),
  pipedriveId: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  address: PipedriveAddressDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IOrganizationDetailPipedriveDTOSchema
  extends z.infer<typeof OrganizationDetailPipedriveDTOSchema> {}
