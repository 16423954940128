import { PaginationSchema } from "./PaginationSchema";
import { UserListItemDTOSchema } from "./UserListItemDTOSchema";

import { z } from "zod";

export const PaginatedUserListItemDTOSchema = PaginationSchema(
  UserListItemDTOSchema,
);

export interface IPaginatedUserListItemDTOSchema
  extends z.infer<typeof PaginatedUserListItemDTOSchema> {}
