import { api, apiException } from "~services/api";
import {
  FetchGetProposalStepOnePayloadSchema,
  IFetchGetProposalStepOnePayloadSchema,
  StepOneDTOSchema,
} from "~types/schemas";

export const fetchGetProposalStepOne = async (
  payload: IFetchGetProposalStepOnePayloadSchema,
) => {
  try {
    const { customId } = FetchGetProposalStepOnePayloadSchema.parse(payload);

    const response = await api.get(`/projects/step_one/custom-id/${customId}`);

    const parsedData = StepOneDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
