import { useQuery } from "react-query";

import type { IUseFetchGetSessionData } from "./interface";

import { fetchGetSessionData } from "~api/auth/sessionData/get";

export const useFetchGetSessionDataKey = "api/auth/sessionData/get";

export function useFetchGetSessionData({
  dependencyArray = [],
  payload,
  options,
}: IUseFetchGetSessionData) {
  return useQuery(
    [useFetchGetSessionDataKey, dependencyArray],
    async () => await fetchGetSessionData(payload),
    options,
  );
}
