import { useQuery } from "react-query";

import type { IUseFetchGetAllPricingTypes } from "./interface";

import { fetchGetAllPricingTypes } from "~api/pricing-types/get";

export const useFetchGetAllPricingTypesKey = "api/pricing-types/get";

export function useFetchGetAllPricingTypes({
  dependencyArray = [],
  payload = undefined,
  options,
}: IUseFetchGetAllPricingTypes) {
  return useQuery(
    [useFetchGetAllPricingTypesKey, dependencyArray],
    async () => await fetchGetAllPricingTypes(payload),
    options,
  );
}
