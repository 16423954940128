import { OrganizationFormSchema } from "./OrganizationFormSchema";

import { z } from "zod";

export const FetchUpdateOrganizationPayloadSchema =
  OrganizationFormSchema.extend({
    organizationId: z.number().int().gt(0),
  });

export interface IFetchUpdateOrganizationPayloadSchema
  extends z.infer<typeof FetchUpdateOrganizationPayloadSchema> {}
