import { ProposalSectionEnum, ProposalStepEnum } from "~types/enum";
import { z } from "zod";

export const FormFieldDTOSchema = z.object({
  id: z.number().int().gt(0),
  nameField: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  proposalSection: ProposalSectionEnum.nullish().transform((val) => val ?? ""),
  proposalStep: ProposalStepEnum.nullish().transform((val) => val ?? undefined),
  fieldCategory: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  displayName: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  configurable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IFormFieldDTOSchema
  extends z.infer<typeof FormFieldDTOSchema> {}
