import { api, apiException } from "~services/api";
import {
  FetchDeleteUserPayloadSchema,
  IFetchDeleteUserPayloadSchema,
} from "~types/schemas";

export const fetchDeleteUser = async (
  payload: IFetchDeleteUserPayloadSchema,
) => {
  try {
    const body = FetchDeleteUserPayloadSchema.parse(payload);

    await api.delete(`/admin/users/${body.id}`);
  } catch (error) {
    throw apiException(error);
  }
};
