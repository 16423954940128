import { MonthlyValuesObjectSchema } from "./MonthlyValuesObjectSchema";

import { z } from "zod";

export const DataLowVoltageDTOSchema = z.object({
  averageConsumption: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  te: z.number(),
  tusd: z.number(),
  wireBTax: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  powerPhase: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  inverterTransformerPowerLimitKwp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  monthlyValues: MonthlyValuesObjectSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IDataLowVoltageDTOSchema
  extends z.infer<typeof DataLowVoltageDTOSchema> {}
