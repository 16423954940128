import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import { useFetchGetCountOfUsersAccountOwnerKey } from "../useFetchGetCountOfUsersAccountOwner";
import type { IUseFetchUnlinkPipedrive } from "./interface";

import { fetchUnlinkPipedrive } from "~api/admin/users/{companyId}/unlink-pipedrive/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUnlinkPipedriveKey =
  "api/admin/users/{companyId}/link-pipedrive/patch";

export function useFetchUnlinkPipedrive(options?: IUseFetchUnlinkPipedrive) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchUnlinkPipedrive(payload), {
    ...options,
    mutationKey: useFetchUnlinkPipedriveKey,
    onSuccess: (data, variables, context) => {
      message.success("Usuário foi desassociado do pipedrive!");
      queryClient.invalidateQueries(useFetchGetAdminUsersKey);
      queryClient.invalidateQueries(useFetchGetCountOfUsersAccountOwnerKey);
      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(error.message);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
