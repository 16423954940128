import { z } from "zod";

export const PerformanceProfileDTOSchema = z.object({
  id: z.number().int().gt(0),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  defaultProfile: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPerformanceProfileDTOSchema
  extends z.infer<typeof PerformanceProfileDTOSchema> {}
