import { RuleDTOSchema } from "./RuleDTOSchema";

import { z } from "zod";

export const CommissionDTOSchema = z.object({
  id: z.number().int().gt(0),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  rules: z
    .array(RuleDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  sellers: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? []),
});

export interface ICommissionDTOSchema
  extends z.infer<typeof CommissionDTOSchema> {}
