import { z } from "zod";

export const AldoStructureIdentifierSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  code: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IAldoStructureIdentifierSchema
  extends z.infer<typeof AldoStructureIdentifierSchema> {}
