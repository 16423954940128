import { useQuery } from "react-query";

import type { IUseFetchGetLogistics } from "./interface";

import { fetchGetLogistics } from "~api/logistics/get";

export const useFetchGetLogisticsKey = "api/logistics/get";

export function useFetchGetLogistics({
  dependencyArray = [],
  options,
}: IUseFetchGetLogistics) {
  return useQuery(
    [useFetchGetLogisticsKey, dependencyArray],
    async () => await fetchGetLogistics(),
    options,
  );
}
