import { api, apiException } from "~services/api";
import {
  FetchUploadComplementaryCostSpreadsheetPayloadSchema,
  IFetchUploadComplementaryCostSpreadsheetPayloadSchema,
  FetchUploadComplementaryCostSpreadsheetResultSchema,
} from "~types/schemas";

export type FetchUploadComplementaryCostSpreadsheetResult = {
  data: string;
};

export const fetchUploadComplementaryCostSpreadsheet = async (
  payload: IFetchUploadComplementaryCostSpreadsheetPayloadSchema,
) => {
  try {
    const body =
      FetchUploadComplementaryCostSpreadsheetPayloadSchema.parse(payload);

    const response =
      await api.post<FetchUploadComplementaryCostSpreadsheetResult>(
        `/complementary-cost/import`,
        body,
      );

    const parsedBody =
      FetchUploadComplementaryCostSpreadsheetResultSchema.parse(response.data);

    return parsedBody;
  } catch (error) {
    throw apiException(error);
  }
};
