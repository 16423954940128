import { useQuery } from "react-query";

import type { IUseFetchPermissionsAssignToProfileByProfileId } from "./interface";

import { fetchPermissionsAssignToProfileByProfileId } from "~api/permissions/by-profile/{profileId}/get";

export const useFetchPermissionsAssignToProfileByProfileIdKey =
  "api/permissions/by-profile/{profileId}/get";

export function useFetchPermissionsAssignToProfileByProfileId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchPermissionsAssignToProfileByProfileId) {
  return useQuery(
    [useFetchPermissionsAssignToProfileByProfileIdKey, dependencyArray],
    async () => await fetchPermissionsAssignToProfileByProfileId(payload),
    options,
  );
}
