import { DataLowVoltageDTOSchema } from "./DataLowVoltageDTOSchema";
import { DataMediumVoltageDTOSchema } from "./DataMediumVoltageDTOSchema";

import { VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const ConcessionaireProfileFormSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z.string(),
  lowVoltage: DataLowVoltageDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  mediumVoltage: DataMediumVoltageDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  electricUtilityId: z.number().int(),
  voltageType: VoltageTypeEnum,
});

export interface IConcessionaireProfileFormSchema
  extends z.infer<typeof ConcessionaireProfileFormSchema> {}
