import { api, apiException } from "~services/api";
import {
  FetchUpdatePipedriveLeadPayloadSchema,
  IFetchUpdatePipedriveLeadPayloadSchema,
} from "~types/schemas";

export const fetchUpdatePipedriveLead = async (
  payload: IFetchUpdatePipedriveLeadPayloadSchema,
) => {
  try {
    const body = FetchUpdatePipedriveLeadPayloadSchema.parse(payload);

    await api.patch(`/company/pipedrive-lead-type`, body);
  } catch (error) {
    throw apiException(error);
  }
};
