import { api, apiException } from "~services/api";
import {
  type IFetchGetSearchPipedriveClientPayloadSchema,
  FetchGetSearchPipedriveClientPayloadSchema,
  ClientSearchPipedriveDTOSchema,
} from "~types/schemas";

export const fetchGetSearchPipedriveClient = async (
  payload: IFetchGetSearchPipedriveClientPayloadSchema,
) => {
  const params = FetchGetSearchPipedriveClientPayloadSchema.parse(payload);

  try {
    const response = await api.get(
      `/projects/step_one/pipedrive/client/search`,
      { params },
    );

    const parsedData = ClientSearchPipedriveDTOSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
