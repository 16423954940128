import { useQuery } from "react-query";

import { IUseFetchGetConfigEdeltecDistributorIntegration } from "./interface";

import { fetchGetConfigEdeltecDistributorIntegration } from "~api/distributors/edeltec/get";

export const useFetchGetConfigEdeltecDistributorIntegrationKey =
  "api/distributors/edeltec/get";

export function useFetchGetConfigEdeltecDistributorIntegration({
  dependencyArray = [],
  options,
}: IUseFetchGetConfigEdeltecDistributorIntegration) {
  return useQuery(
    [useFetchGetConfigEdeltecDistributorIntegrationKey, dependencyArray],
    async () => await fetchGetConfigEdeltecDistributorIntegration(),
    options,
  );
}
