import { api, apiException } from "~services/api";
import {
  FetchDeleteLaborPayloadSchema,
  IFetchDeleteLaborPayloadSchema,
} from "~types/schemas";

export const fetchDeleteLabor = async (
  payload: IFetchDeleteLaborPayloadSchema,
) => {
  try {
    const { laborId } = FetchDeleteLaborPayloadSchema.parse(payload);

    await api.delete(`/labors/${laborId}`);
  } catch (error) {
    throw apiException(error);
  }
};
