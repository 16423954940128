import { useQuery } from "react-query";

import type { IUseFetchGetGlobalIrradiationByCustomId } from "./interface";

import { fetchGetGlobalIrradiationByCustomId } from "~api/atlas/{custom-id}/get";

export const fetchGetGlobalIrradiationByCustomIdKey =
  "api/atlas/{custom-id}/get";

export function useFetchGetGlobalIrradiationByCustomId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetGlobalIrradiationByCustomId) {
  return useQuery(
    [fetchGetGlobalIrradiationByCustomIdKey, dependencyArray],
    async () => await fetchGetGlobalIrradiationByCustomId(payload),
    options,
  );
}
