import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedAneelInDevelopment } from "./interface";

import { fetchGetPaginatedAneelInDevelopment } from "~api/development/aneel-list/get";

export const useFetchGetPaginatedAneelInDevelopmentKey =
  "api/development/aneel-list/get";

export function useFetchGetPaginatedAneelInDevelopment({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 10,
  },
}: IUseFetchGetPaginatedAneelInDevelopment) {
  return useQuery(
    [useFetchGetPaginatedAneelInDevelopmentKey, dependencyArray],
    async () => await fetchGetPaginatedAneelInDevelopment(payload),
    options,
  );
}
