import { ProgressInsightBarsDataSchema } from "./ProgressInsightBarsDataSchema";

import { MonthEnum } from "~types/enum";
import { z } from "zod";

export const ProgressInsightDataSchema = z.object({
  year: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  month: MonthEnum.nullish().transform((val) => val ?? undefined),
  progressInsightBarsData: ProgressInsightBarsDataSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  total: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IProgressInsightDataSchema
  extends z.infer<typeof ProgressInsightDataSchema> {}
