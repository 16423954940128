import { useMutation } from "react-query";

import { useFetchGetActivityMessageFromPipedriveKey } from "../useFetchGetActivityMessageFromPipedrive";
import type { IUseFetchUpdateActivityMessageFromPipedrive } from "./interface";

import { fetchUpdateActivityMessageFromPipedrive } from "~api/company/custom-pipedrive-activity-message/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateActivityMessageFromPipedriveKey =
  "api/company/custom-pipedrive-activity-message/put";

export function useFetchUpdateActivityMessageFromPipedrive(
  options?: IUseFetchUpdateActivityMessageFromPipedrive,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateActivityMessageFromPipedrive(payload),
    {
      ...options,
      mutationKey: useFetchUpdateActivityMessageFromPipedriveKey,
      onSuccess: (data, variables, context) => {
        message.success("Mensagem do pipedrive foi alterada");
        queryClient.invalidateQueries(
          useFetchGetActivityMessageFromPipedriveKey,
        );
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao alterar mensagem:" + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
