import { AdditionalCostCalculationScaleItemSchema } from "./AdditionalCostCalculationScaleItemSchema";

import {
  CalculationTypeEnum,
  ScaleEnum,
  CostGroupEnum,
  ValueFlexibilityTypeEnum,
} from "~types/enum";
import { z } from "zod";

export const AdditionalCostFormSchema = z.object({
  name: z.string(),
  calculationType: CalculationTypeEnum,
  value: z.number().nullish().default(0),
  identifier: z.string().optional(),
  baseCalculationType: ScaleEnum.optional(),
  fixed: z.boolean().optional(),
  active: z.boolean().optional(),
  applyDiscount: z.boolean().optional().default(false),
  fixedCalculation: z.boolean().optional(),
  advanced: z.boolean().optional(),
  businessUnitId: z.number().int().optional(),
  additionalCostTaxId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined)
    .optional(),
  notApplyDefaultAdditionalCostTax: z.boolean().optional().default(false),
  ignoreViewPricePermission: z.boolean().optional(),
  customExpression: z.string().optional(),
  customConditional: z.string().optional(),
  scaleType: ScaleEnum.optional(),
  conditional: z.boolean().optional(),
  minValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0)
    .default(0),
  executionOrder: z.number().int().optional(),
  costGroup: CostGroupEnum,
  scale: z.array(AdditionalCostCalculationScaleItemSchema).optional(),
  valueFlexibilityType: ValueFlexibilityTypeEnum,
  isAuxVariable: z.boolean().optional(),
  costAggregatorIds: z.array(z.number()).optional(),
  customExpressionForScale: z.string().optional(),
});

export interface IAdditionalCostFormSchema
  extends z.infer<typeof AdditionalCostFormSchema> {}
