import { useQuery } from "react-query";

import type { IUseFetchGetBoardActivity } from "./interface";

import { fetchGetActivityBoard } from "~api/activities/board/get";

export const useFetchGetBoardActivityKey = "api/activities/board/get";

export function useFetchGetBoardActivity({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetBoardActivity) {
  return useQuery(
    [useFetchGetBoardActivityKey, dependencyArray],
    async () => await fetchGetActivityBoard(payload),
    options,
  );
}
