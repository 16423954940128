import { RuleTypeEnum } from "~types/enum";
import { z } from "zod";

export const RuleDTOSchema = z.object({
  percentageRange: z.number().gt(0),
  type: RuleTypeEnum,
  commissionAmount: z.number(),
});

export interface IRuleDTOSchema extends z.infer<typeof RuleDTOSchema> {}
