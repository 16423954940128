import { z } from "zod";

export const EosProtocolResponseSchema = z.object({
  message: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  data: z.object({
    protocolo: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
  }),
});

export interface IEosProtocolResponseSchema
  extends z.infer<typeof EosProtocolResponseSchema> {}
