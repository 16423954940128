import { UpdateDealStatusRequestSchema } from "./UpdateDealStatusRequestSchema";

import { z } from "zod";

export const FetchUpdateProposalStatusByCustomIdPayloadSchema =
  UpdateDealStatusRequestSchema.extend({
    customId: z.number().int().gt(0),
    changeDealStatus: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
  });

export interface IFetchUpdateProposalStatusByCustomIdPayloadSchema
  extends z.infer<typeof FetchUpdateProposalStatusByCustomIdPayloadSchema> {}
