import { useQuery } from "react-query";

import { IUseFetchGetDealCustomFieldsByPipelineStageId } from "./interface";

import { fetchGetDealCustomFieldsByPipelineStageId } from "~api/config/deals/custom-fields/groups/by-stage/{pipelineStageId}/get";

export const useFetchGetDealCustomFieldsByPipelineStageIdKey =
  "api/config/deals/custom-fields/groups/by-stage/{pipelineStageId}/get";

export function useFetchGetDealCustomFieldsByPipelineStageId({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetDealCustomFieldsByPipelineStageId) {
  return useQuery(
    [useFetchGetDealCustomFieldsByPipelineStageIdKey, dependencyArray],
    async () => await fetchGetDealCustomFieldsByPipelineStageId(payload),
    options,
  );
}
