import { z } from "zod";

export const ConsultantUserDTOSchema = z.object({
  id: z
    .number()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IConsultantUserDTOSchema
  extends z.infer<typeof ConsultantUserDTOSchema> {}
