import { api, apiException } from "~services/api";
import type { AlternatingCurrentMaterialDTO } from "~types/api";
import {
  AlternatingCurrentMaterialDTOSchema,
  FetchCreateAlternatingCurrentMaterialPayloadSchema,
  IFetchCreateAlternatingCurrentMaterialPayloadSchema,
} from "~types/schemas";

export const fetchCreateAlternatingCurrentMaterial = async (
  payload: IFetchCreateAlternatingCurrentMaterialPayloadSchema,
) => {
  try {
    const body =
      FetchCreateAlternatingCurrentMaterialPayloadSchema.parse(payload);

    const response = await api.post<AlternatingCurrentMaterialDTO>(
      `/ac-materials`,
      body,
    );

    const parsedData = AlternatingCurrentMaterialDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
