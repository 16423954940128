import { z } from "zod";

export const StepTwoEstimatedPowerDTOSchema = z.object({
  expectedPowerFull: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IStepTwoEstimatedPowerDTOSchema
  extends z.infer<typeof StepTwoEstimatedPowerDTOSchema> {}
