import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useRouter } from "next/router";

import {
  IPublishParams,
  IRxStompClientContext,
  IRxStompClientContextProviderProps,
  IRxStompSubscription,
  ISubscribeCallbackParam,
} from "./interface";

import { RxStomp } from "@stomp/rx-stomp";
import { ENV } from "~configs/Env";
import { useAppConfig } from "~hooks/useAppConfig";
import { useSession } from "~hooks/useSession";
import { IApiDefaultErrorResponse } from "~services/api/interface";
export const RxStompClientContext = createContext({} as IRxStompClientContext);

export function RxStompClientContextProvider({
  children,
}: IRxStompClientContextProviderProps) {
  const MAX_RECONNECTION_ATTEMPTS = 3;

  const { user, token, sessionStatus, userIsSysAdmin, userIsSuperSysAdmin } =
    useSession();
  const { message } = useAppConfig();
  const { pathname } = useRouter();

  const [isConnected, setIsConnected] = useState(false);
  const [userToken, setUserToken] = useState("");

  const [reconnectionAttempts, setReconnectionAttempts] = useState(
    MAX_RECONNECTION_ATTEMPTS,
  );

  const WEB_SOCKET_ERROR_MESSAGE =
    pathname === "/funil"
      ? "Não está sendo possível mostrar as movimentações dos negócios em tempo real. Atualize a página novamente, se quiser ver se houve atualizações."
      : "Não está sendo possível mostrar as notificações em tempo real. Atualize a página novamente, se quiser ver se tem novas notificações.";

  const clientRef = useRef(new RxStomp());
  const client = clientRef.current;

  const connect = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (isConnected) reject();

        client?.activate();
        setIsConnected(true);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }, [client, isConnected]);

  const disconnect = useCallback(() => {
    client?.deactivate();
    setUserToken("");
  }, [client]);

  const subscribe = useCallback(
    async (
      destination: string,
      callback: ISubscribeCallbackParam,
    ): Promise<IRxStompSubscription | undefined> => {
      try {
        if (!isConnected || !client)
          throw new Error(
            `You must be connected in WebSocket service first before connect to ${destination}`,
          );
        return client?.watch(destination).subscribe(callback);
      } catch (error: any) {
        console.error(`STOMP CLIENT: ${error.message}`);
      }
    },
    [client, isConnected],
  );

  const publish = useCallback(
    (params: IPublishParams) => {
      if (!isConnected)
        throw new Error(
          `You must be connected in WebSocket service first before publish to ${params?.destination}`,
        );
      client?.publish(params);
    },
    [client, isConnected],
  );

  const handleShowErrors = useCallback(() => {
    subscribe(`/receive/user/${user?.id}/errors/`, (frame) => {
      const errorMessage = JSON.parse(frame.body) as IApiDefaultErrorResponse;
      message.error(errorMessage.message);
    });
  }, [message, subscribe, user?.id]);

  useEffect(() => {
    if (
      sessionStatus === "authenticated" &&
      !userIsSysAdmin &&
      !userIsSuperSysAdmin &&
      !!userToken
    ) {
      client?.configure({
        brokerURL: ENV.WEB_SOCKET_URL + "/api/ws",
        connectHeaders: {
          Authorization: "Bearer " + userToken,
        },
        debug:
          ENV.AMBIENT === "DEVELOPMENT" || ENV.AMBIENT === "HOMOLOG"
            ? (debug) => console.info("DEBUG--", debug)
            : () => {},
        reconnectDelay: 20000,
      });
      client?.connected$.subscribe(() => {
        handleShowErrors();
      });
      connect();
    }

    if (
      sessionStatus === "unauthenticated" ||
      ((userIsSuperSysAdmin || userIsSysAdmin) &&
        sessionStatus === "authenticated")
    ) {
      disconnect();
      setIsConnected(false);
      setUserToken("");
    }
  }, [
    client,
    connect,
    disconnect,
    handleShowErrors,
    sessionStatus,
    userIsSuperSysAdmin,
    userIsSysAdmin,
    userToken,
  ]);

  useEffect(() => {
    if (
      token &&
      !userToken &&
      !userIsSysAdmin &&
      !userIsSuperSysAdmin &&
      user?.id
    ) {
      setUserToken(token);
    }
  }, [token, user?.id, userIsSuperSysAdmin, userIsSysAdmin, userToken]);

  // useEffect(() => {
  //   client.onWebSocketError = (frame) => {
  //     if (reconnectionAttempts === 0) {
  //       message.info(WEB_SOCKET_ERROR_MESSAGE, 5);
  //       if (ENV.AMBIENT === "DEVELOPMENT") console.info("WS-ERROR--", frame);
  //       client.reconnectDelay = 0;
  //     }

  //     if (reconnectionAttempts >= 0) {
  //       setReconnectionAttempts((quantity) => quantity - 1);
  //     }
  //   };
  // }, [
  //   WEB_SOCKET_ERROR_MESSAGE,
  //   client,
  //   disconnect,
  //   message,
  //   reconnectionAttempts,
  // ]);

  const memorizedContextValues: IRxStompClientContext = useMemo(
    () => ({
      connect,
      disconnect,
      subscribe,
      publish,
      client,
      isConnected,
    }),
    [client, connect, disconnect, isConnected, publish, subscribe],
  );

  return (
    <RxStompClientContext.Provider value={memorizedContextValues}>
      {children}
    </RxStompClientContext.Provider>
  );
}
