import { useMutation } from "react-query";

import type { IUseFetchUpdateLogisticsDistance } from "./interface";

import { fetchUpdateLogisticsDistance } from "~api/projects/step_four/logistics/{id}/put";
import { useAppConfig } from "~hooks/useAppConfig";

export const useFetchUpdateLogisticsDistanceKey =
  "api/projects/step_four/logistics/{id}/put";

export function useFetchUpdateLogisticsDistance(
  options?: IUseFetchUpdateLogisticsDistance,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      return await fetchUpdateLogisticsDistance(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdateLogisticsDistanceKey,
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
