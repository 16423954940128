import { api, apiException } from "~services/api";
import {
  FetchDeleteConcessionaireProfilePayloadSchema,
  IFetchDeleteConcessionaireProfilePayloadSchema,
} from "~types/schemas";

export const fetchDeleteConcessionaireProfile = async (
  payload: IFetchDeleteConcessionaireProfilePayloadSchema,
) => {
  try {
    const { concessionaireProfileId } =
      FetchDeleteConcessionaireProfilePayloadSchema.parse(payload);

    return await api.delete<void>(
      `/electric-utility-profile/${concessionaireProfileId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
