import { api, apiException } from "~services/api";
import {
  type IFetchGetSearchPipedriveDealPayloadSchema,
  FetchGetSearchPipedriveDealPayloadSchema,
  DealSearchPipedriveDTOSchema,
} from "~types/schemas";

export const fetchGetSearchPipedriveDeal = async (
  payload: IFetchGetSearchPipedriveDealPayloadSchema,
) => {
  const params = FetchGetSearchPipedriveDealPayloadSchema.parse(payload);

  try {
    const response = await api.get(`/projects/step_one/pipedrive/deal/search`, {
      params,
    });

    const parsedData = DealSearchPipedriveDTOSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
