import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedAlternatingCurrentMaterialsPayloadSchema,
  IFetchGetPaginatedAlternatingCurrentMaterialsPayloadSchema,
  PaginatedAlternatingCurrentMaterialDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedAlternatingCurrentMaterials = async (
  payload: IFetchGetPaginatedAlternatingCurrentMaterialsPayloadSchema,
) => {
  try {
    const params =
      FetchGetPaginatedAlternatingCurrentMaterialsPayloadSchema.parse(payload);

    const response = await api.get(`/ac-materials`, {
      params,
    });

    const parsedData = PaginatedAlternatingCurrentMaterialDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
