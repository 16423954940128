import { api, apiException } from "~services/api";
import {
  FetchGetProposalStepOneInitialValuesPayloadSchema,
  IFetchGetProposalStepOneInitialValuesPayloadSchema,
  StepOneDTOSchema,
} from "~types/schemas";

export const fetchGetProposalStepOneInitialValues = async (
  payload?: IFetchGetProposalStepOneInitialValuesPayloadSchema,
) => {
  const params =
    FetchGetProposalStepOneInitialValuesPayloadSchema.parse(payload);
  try {
    const response = await api.get(`/projects/step_one/auto-complete`, {
      params,
    });

    const parsedData = StepOneDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
