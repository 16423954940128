import { EosAddressDTOSchema } from "./EosAddressDTOSchema";
import { EosContactSchema } from "./EosContactSchema";

import { z } from "zod";

export const EosCompanySchema = z.object({
  cnpj: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  nomeFantasia: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  endereco: EosAddressDTOSchema.nullish().transform((val) => val ?? undefined),
  contato: EosContactSchema.nullish().transform((val) => val ?? undefined),
});

export interface IEosCompanySchema extends z.infer<typeof EosCompanySchema> {}
