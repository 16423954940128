import { api, apiException } from "~services/api";
import {
  FetchDeleteAdditionalCostMarginTypePayloadSchema,
  IFetchDeleteAdditionalCostMarginTypePayloadSchema,
} from "~types/schemas";

export const fetchDeleteAdditionalCostMarginType = async (
  payload: IFetchDeleteAdditionalCostMarginTypePayloadSchema,
) => {
  try {
    const { additionalCostMarginTypeId } =
      FetchDeleteAdditionalCostMarginTypePayloadSchema.parse(payload);

    return await api.delete(
      `/additional-cost-margin-type/${additionalCostMarginTypeId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
