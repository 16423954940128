import { z } from "zod";

export const AldoParameterFormSchema = z
  .object({
    authCode: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    token: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
  })
  .partial();

export interface IAldoParameterFormSchema
  extends z.infer<typeof AldoParameterFormSchema> {}
