import { api, apiException } from "~services/api";
import { TemplateReportDTO } from "~types/api/TemplateReportDTO";
import {
  FetchGetDownloadDocxPayloadSchema,
  IFetchGetDownloadDocxPayloadSchema,
  TemplateReportDTOSchema,
} from "~types/schemas";

export const fetchGetDownloadDocx = async (
  path: IFetchGetDownloadDocxPayloadSchema,
) => {
  try {
    const { docxId } = FetchGetDownloadDocxPayloadSchema.parse(path);

    const response = await api.get<TemplateReportDTO>(
      `/reports/docx/${docxId}`,
    );

    const parsedData = TemplateReportDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
