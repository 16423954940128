import { z } from "zod";

export const OrganizationDealSectionDTOSchema = z.object({
  closedDealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  openDealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  wonDealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  lostDealsCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  totalValueWon: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  totalValueLost: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  totalValueOpen: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IOrganizationDealSectionDTOSchema
  extends z.infer<typeof OrganizationDealSectionDTOSchema> {}
