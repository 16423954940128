import { EdeltecProductTypeEnum } from "~types/enum";
import { z } from "zod";

export const EdeltecProductTypeDTOSchema = z.object({
  productType: EdeltecProductTypeEnum.nullish().transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IEdeltecProductTypeDTOSchema
  extends z.infer<typeof EdeltecProductTypeDTOSchema> {}
