import { api, apiException } from "~services/api";
import {
  FetchDeleteDataFieldsPayloadSchema,
  IFetchDeleteDataFieldsPayloadSchema,
} from "~types/schemas";

export const fetchDeleteDataFields = async (
  payload: IFetchDeleteDataFieldsPayloadSchema,
) => {
  try {
    const { defaultFieldProfileSettingsId } =
      FetchDeleteDataFieldsPayloadSchema.parse(payload);

    await api.delete(
      `/default-fields/profile-settings/${defaultFieldProfileSettingsId}`,
    );
  } catch (error) {
    throw apiException(error);
  }
};
