import { DealStatusEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetPipelineDealsListByIdPayloadSchema = z.object({
  pipelineId: z.number().int().gt(0),
  responsibleSellerId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  dealName: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  dealStatus: DealStatusEnum.nullish().transform((val) => val ?? undefined),
  page: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPipelineDealsListByIdPayloadSchema
  extends z.infer<typeof FetchGetPipelineDealsListByIdPayloadSchema> {}
