import { api, apiException } from "~services/api";
import {
  IFetchGetSessionDataPayloadSchema,
  FetchGetSessionDataPayloadSchema,
  UserDTOSchema,
} from "~types/schemas";

export const fetchGetSessionData = async (
  payload: IFetchGetSessionDataPayloadSchema,
) => {
  try {
    const { bearerToken } = FetchGetSessionDataPayloadSchema.parse(payload);

    const response = await api.get(`/auth/sessionData`, {
      headers: {
        Authorization: bearerToken ? `Bearer ${bearerToken}` : undefined,
      },
    });

    const parsedData = UserDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
