import { api, apiException } from "~services/api";
import {
  FetchUpdateLaborStructurePayloadSchema,
  IFetchUpdateLaborStructurePayloadSchema,
} from "~types/schemas";

export const fetchUpdateLaborStructure = async (
  payload: IFetchUpdateLaborStructurePayloadSchema,
) => {
  try {
    const { laborId, structureIds } =
      FetchUpdateLaborStructurePayloadSchema.parse(payload);

    await api.put(`/labors/structures/${laborId}`, structureIds);
  } catch (error) {
    throw apiException(error);
  }
};
