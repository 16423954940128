import { z } from "zod";

export const BlobSchema = z.custom<Blob>((data) => {
  if (data instanceof Blob) {
    return data;
  }

  throw new Error("Value is not a Blob");
});

export interface IBlobSchema extends z.infer<typeof BlobSchema> {}
