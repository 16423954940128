import { useQuery } from "react-query";

import type { IUseFetchGetAdminDistributors } from "./interface";

import { fetchGetAdminModules } from "~api/admin/distributors/get";

export const useFetchGetAdminDistributorsKey = "api/admin/distributors/get";

export function useFetchGetAdminDistributors({
  dependencyArray = [],
  options,
}: IUseFetchGetAdminDistributors) {
  return useQuery(
    [useFetchGetAdminDistributorsKey, dependencyArray],
    async () => await fetchGetAdminModules(),
    options,
  );
}
