import { AddressDTOSchema } from "./AddressDTOSchema";
import { SimpleCompanyDTOSchema } from "./SimpleCompanyDTOSchema";
import { SimpleUserAccountDTOSchema } from "./SimpleUserAccountDTOSchema";

import { z } from "zod";

export const OrganizationDTOSchema = z.object({
  id: z.number().int().gt(0),
  customId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  cnpj: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  phone: z
    .string()
    .min(0)
    .max(15)
    .nullish()
    .transform((val) => val ?? ""),
  address: AddressDTOSchema.nullish().transform((val) => val ?? undefined),
  company: SimpleCompanyDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  owner: SimpleUserAccountDTOSchema.nullish().transform(
    (val) => val ?? undefined,
  ),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  pipedriveId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  clientCount: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IOrganizationDTOSchema
  extends z.infer<typeof OrganizationDTOSchema> {}
