import { z } from "zod";

export const FortlevModuleDTOSchema = z.object({
  id: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFortlevModuleDTOSchema
  extends z.infer<typeof FortlevModuleDTOSchema> {}
