import { api, apiException } from "~services/api";
import {
  FetchDeleteActivityPayloadSchema,
  type IFetchDeleteActivityPayloadSchema,
} from "~types/schemas";

export const fetchDeleteActivity = async (
  payload?: IFetchDeleteActivityPayloadSchema,
) => {
  try {
    const { activityId } = FetchDeleteActivityPayloadSchema.parse(payload);

    await api.delete<void>(`/activities/${activityId}`);
  } catch (error) {
    throw apiException(error);
  }
};
