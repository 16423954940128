import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedConsultant } from "./interface";

import { fetchGetPaginatedConsultant } from "~api/consultants/pageable/get";

export const useFetchGetPaginatedConsultantKey = "api/consultants/pageable/get";

export function useFetchGetPaginatedConsultant({
  dependencyArray = [],
  options,
  payload = {
    page: 0,
    size: 5,
  },
}: IUseFetchGetPaginatedConsultant) {
  return useQuery(
    [useFetchGetPaginatedConsultantKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedConsultant(payload);
    },
    options,
  );
}
