import { useMutation } from "react-query";

import type { IUseFetchAdminImpersonateUser } from "./interface";

import { fetchAdminImpersonateUser } from "~api/admin/impersonate/post";

export const useFetchAdminImpersonateUserKey = "api/admin/impersonate/post";

export function useFetchAdminImpersonateUser({
  options,
}: IUseFetchAdminImpersonateUser) {
  return useMutation(
    async (payload) => {
      return await fetchAdminImpersonateUser(payload);
    },
    {
      ...options,
      mutationKey: useFetchAdminImpersonateUserKey,
    },
  );
}
