import { api, apiException } from "~services/api";
import {
  FetchChangeIsTemplatePayloadSchema,
  type IFetchChangeIsTemplatePayloadSchema,
} from "~types/schemas";

export const fetchChangeIsTemplate = async (
  payload: IFetchChangeIsTemplatePayloadSchema,
) => {
  try {
    const { companyId } = FetchChangeIsTemplatePayloadSchema.parse(payload);

    await api.patch(`/admin/users/${companyId}/change-is-template`);
  } catch (error) {
    throw apiException(error);
  }
};
