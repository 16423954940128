import { useMutation } from "react-query";

import { useFetchGetDealDefaultFieldsSettingsKey } from "../useFetchGetDealDefaultFieldsSettings";
import type { IUseFetchPatchDealDefaultFieldsSettings } from "./interface";

import { fetchPatchDealDefaultFieldsSettings } from "~api/config/deals/default-fields/settings/{id}/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchPatchDealDefaultFieldsSettingsKey =
  "api/config/deals/default-fields/settings/{id}/patch";

export function useFetchPatchDealDefaultFieldsSettings({
  options,
}: IUseFetchPatchDealDefaultFieldsSettings) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchPatchDealDefaultFieldsSettings(payload),
    {
      ...options,
      mutationKey: useFetchPatchDealDefaultFieldsSettingsKey,
      onSuccess: (data, variables, context) => {
        message.success("Configurações do campo atualizadas");
        queryClient.invalidateQueries(useFetchGetDealDefaultFieldsSettingsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
