import { useQuery } from "react-query";

import type { IUseFetchGetHistoriesTypeFilterByPersonId } from "./interface";

import { fetchGetHistoriesFiltersTypeByPersonId } from "~api/histories/person/filters/types/get";

export const useFetchGetHistoriesTypeFilterByPersonIdKey =
  "api/histories/person/filters/types/get";

export function useFetchGetHistoriesTypeFilterByPersonId({
  options,
  dependencyArray,
  payload,
}: IUseFetchGetHistoriesTypeFilterByPersonId) {
  return useQuery(
    [useFetchGetHistoriesTypeFilterByPersonIdKey, dependencyArray],
    async () => await fetchGetHistoriesFiltersTypeByPersonId(payload),
    options,
  );
}
