import { z } from "zod";

export const DealReasonForLossDTOSchema = z.object({
  reasonForLossId: z
    .number({ message: "ID do motivo de perda do negócio é obrigatório" })
    .int({ message: "ID do motivo de perda do negócio deve ser um inteiro" })
    .gt(0, {
      message: "ID do motivo de perda do negócio deve ser maior do que zero",
    }),
  comment: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface IDealReasonForLossDTOSchema
  extends z.infer<typeof DealReasonForLossDTOSchema> {}
