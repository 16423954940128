import { api, apiException } from "~services/api";
import {
  FetchUpdateNotePayloadSchema,
  type IFetchUpdateNotePayloadSchema,
  NoteDTOSchema,
} from "~types/schemas";

export const fetchUpdateNote = async (
  payload: IFetchUpdateNotePayloadSchema,
) => {
  try {
    const body = FetchUpdateNotePayloadSchema.parse(payload);

    const response = await api.put(`/note`, body);

    const parsedData = NoteDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
