import { z } from "zod";

export const ProjectPaymentSimulationDTOSchema = z.object({
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  id: z.number().int().gt(0),
  optionsCount: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IProjectPaymentSimulationDTOSchema
  extends z.infer<typeof ProjectPaymentSimulationDTOSchema> {}
