import { z } from "zod";

export const FileDTOSchema = z
  .object({
    id: z.number().int().gt(0),
    path: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    name: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    contentType: z
      .string()
      .nullish()
      .transform((val) => val ?? ""),
    size: z
      .number()
      .nullish()
      .transform((val) => val ?? undefined),
  })
  .partial();

export interface IFileDTOSchema extends z.infer<typeof FileDTOSchema> {}
