import { api, apiException } from "~services/api";
import {
  FetchUpdateUserCompanyConfigurationPayloadSchema,
  UserConfigurationDTOSchema,
  type IFetchGetUserCompanyConfigurationPayloadSchema,
} from "~types/schemas";

export const fetchGetUserCompanyConfiguration = async (
  payload: IFetchGetUserCompanyConfigurationPayloadSchema,
) => {
  try {
    const { companyId } =
      FetchUpdateUserCompanyConfigurationPayloadSchema.parse(payload);

    const response = await api.get(`/admin/users/${companyId}/configurations`);

    const parsedData = UserConfigurationDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
