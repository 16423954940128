import { api, apiException } from "~services/api";
import {
  FetchGetGlobalIrradiationByCustomIdPayloadSchema,
  IFetchGetGlobalIrradiationByCustomIdPayloadSchema,
  GlobalSolarRadiation,
} from "~types/schemas";

export const fetchGetGlobalIrradiationByCustomId = async (
  payload: IFetchGetGlobalIrradiationByCustomIdPayloadSchema,
) => {
  try {
    const { customId } =
      FetchGetGlobalIrradiationByCustomIdPayloadSchema.parse(payload);

    const response = await api.get(`/atlas/custom-id/${customId}`);

    const parsedData = GlobalSolarRadiation.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
