import { AttechedFileFormSchema } from "./AttechedFileFormSchema";

import { z } from "zod";

export const UpdateNoteFormSchema = z.object({
  id: z.number().int().gt(0),
  text: z.string(),
  attachedFiles: AttechedFileFormSchema.array()
    .nullish()
    .transform((val) => val ?? undefined),
  mentionedIds: z
    .number()
    .array()
    .nullish()
    .transform((val) => val ?? []),
  textComponent: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IUpdateNoteFormSchema
  extends z.infer<typeof UpdateNoteFormSchema> {}
