import { api, apiException } from "~services/api";
import {
  FetchSetOrRemovePerformanceProfileDefaultPayloadSchema,
  IFetchSetOrRemovePerformanceProfileDefaultPayloadSchema,
  PerformanceProfileDTOSchema,
} from "~types/schemas";

export const fetchSetOrRemovePerformanceProfileDefault = async (
  payload: IFetchSetOrRemovePerformanceProfileDefaultPayloadSchema,
) => {
  try {
    const { id } =
      FetchSetOrRemovePerformanceProfileDefaultPayloadSchema.parse(payload);

    const response = await api.patch(`/performance-profile/default/${id}`);

    const parsedData = PerformanceProfileDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
