import { useQuery } from "react-query";

import type { IUseFetchGetOrganizationDetailPipedrive } from "./interface";

import { fetchGetPipedriveOrganizationAddress } from "~api/projects/step_one/pipedrive/organization/{id}/get";

export const FetchGetPipedriveOrganizationPipedriveKey =
  "api/projects/step_one/pipedrive/organization/{id}/get";

export function useFetchGetOrganizationDetailPipedrive({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetOrganizationDetailPipedrive) {
  return useQuery(
    [FetchGetPipedriveOrganizationPipedriveKey, dependencyArray],
    async () => await fetchGetPipedriveOrganizationAddress(payload),
    options,
  );
}
