import { api, apiException } from "~services/api";
import {
  FetchCreateLaborCopyPayloadSchema,
  IFetchCreateLaborCopyPayloadSchema,
  LaborDTOSchema,
} from "~types/schemas";

export const fetchCreateLaborCopy = async (
  payload: IFetchCreateLaborCopyPayloadSchema,
) => {
  try {
    const { laborId, ...body } =
      FetchCreateLaborCopyPayloadSchema.parse(payload);

    const response = await api.post(`labors/${laborId}/copy`, body);

    const parsedData = LaborDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
