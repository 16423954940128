import { api, apiException } from "~services/api";
import {
  PersonDTOSchema,
  FetchUpdatePersonPayloadSchema,
  type IFetchUpdatePersonPayloadSchema,
} from "~types/schemas";

export const fetchUpdatePerson = async (
  payload: IFetchUpdatePersonPayloadSchema,
) => {
  try {
    const { personId, ...body } = FetchUpdatePersonPayloadSchema.parse(payload);

    const response = await api.put(`/persons/${personId}`, body);

    const parsedData = PersonDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
