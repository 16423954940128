import { useQuery } from "react-query";

import { IUseFetchGetHideableFieldsByProfile } from "./interface";

import { fetchGetHideableFieldsByProfile } from "~api/fields/hideable/{profileId}/get";

export const useFetchGetHideableFieldsByProfileKey =
  "api/fields/hideable/{profileId}/get";

export function useFetchGetHideableFieldsByProfile({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetHideableFieldsByProfile) {
  return useQuery(
    [useFetchGetHideableFieldsByProfileKey, dependencyArray],
    async () => await fetchGetHideableFieldsByProfile(payload),
    options,
  );
}
