import { ItemKitDTOSchema } from "./ItemKitDTOSchema";

import { z } from "zod";

export const CustomDiverseKitDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  priceCents: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  items: z.array(ItemKitDTOSchema).optional(),
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface ICustomDiverseKitDTOSchema
  extends z.infer<typeof CustomDiverseKitDTOSchema> {}
