import { useQuery } from "react-query";

import type { IUseFetchGetCurrentAdditionalCostMarginType } from "./interface";

import { fetchGetCurrentAdditionalCostMarginType } from "~api/additional-cost-margin-type/current/get";

export const UseFetchGetCurrentAdditionalCostMarginTypeKey =
  "api/additional-cost-margin-type/current/get";

export function useFetchGetCurrentAdditionalCostMarginType({
  dependencyArray = [],
  options,
}: IUseFetchGetCurrentAdditionalCostMarginType) {
  return useQuery(
    [UseFetchGetCurrentAdditionalCostMarginTypeKey, dependencyArray],
    async () => await fetchGetCurrentAdditionalCostMarginType(),
    options,
  );
}
