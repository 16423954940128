import { useQuery } from "react-query";

import type { IUseFetchGetSearchPaginatedSearchDatasheet } from "./interface";

import { fetchGetPaginatedSearchDatasheet } from "~api/admin/datasheets/search/get";

export const useFetchGetSearchPaginatedDatasheetKey =
  "api/admin/datasheets/search/get";

export function useFetchGetSearchPaginatedSearchDatasheet({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetSearchPaginatedSearchDatasheet) {
  return useQuery(
    [useFetchGetSearchPaginatedDatasheetKey, dependencyArray],
    async () => await fetchGetPaginatedSearchDatasheet(payload),
    options,
  );
}
