import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedAdminModules } from "./interface";

import { fetchGetPaginatedAdminModules } from "~api/admin/modules/get";

export const useFetchGetPaginatedAdminModulesKey = "api/admin/modules/get";

export function useFetchGetPaginatedAdminModules({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedAdminModules) {
  return useQuery(
    [useFetchGetPaginatedAdminModulesKey, dependencyArray],
    async () => await fetchGetPaginatedAdminModules(payload),
    options,
  );
}
