import { useMutation } from "react-query";

import { useFetchGetAdminUsersKey } from "../useFetchGetAdminUsers";
import { useFetchGetUserCompanyConfigurationKey } from "../useFetchGetUserCompanyConfiguration";
import type { IUseFetchUpdateUserCompanyConfiguration } from "./interface";

import { fetchUpdateUserCompanyConfiguration } from "~api/admin/users/{companyId}/configurations/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateUserCompanyConfigurationKey =
  "api/admin/users/{companyId}/configurations/patch";

export function useFetchUpdateUserCompanyConfiguration({
  options,
}: IUseFetchUpdateUserCompanyConfiguration) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateUserCompanyConfiguration(payload),
    {
      ...options,
      mutationKey: useFetchUpdateUserCompanyConfigurationKey,
      onSuccess: (data, variables, context) => {
        message.success("Configurações foram atualizadas");

        queryClient.invalidateQueries(useFetchGetAdminUsersKey);
        queryClient.invalidateQueries(useFetchGetUserCompanyConfigurationKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar configurações: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
