import { api, apiException } from "~services/api";
import { ElectricUtilityDTOSchema } from "~types/schemas";
import { z } from "zod";

export const fetchGetConcessionairesWithAssociatedProfile = async () => {
  try {
    const response = await api.get(`/electric-utility/associated-profile`);

    const parsedData = z.array(ElectricUtilityDTOSchema).parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
