import { UserManagementDTOSchema } from "./UserManagementDTOSchema";

import { PermissionsEnum } from "~types/enum";
import { z } from "zod";

export const ProfileDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z.string(),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  permissions: z
    .array(PermissionsEnum)
    .nullish()
    .transform((val) => val ?? ""),
  active: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  userGroupId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  users: UserManagementDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
  settings: z
    .object({
      editable: z
        .boolean()
        .nullish()
        .transform((val) => !!val),
      erasable: z
        .boolean()
        .nullish()
        .transform((val) => !!val),
      default: z
        .boolean()
        .nullish()
        .transform((val) => !!val),
    })
    .nullish()
    .transform(
      (val) =>
        val ?? {
          editable: false,
          erasable: false,
          default: false,
        },
    ),
});

export interface IProfileDTOSchema extends z.infer<typeof ProfileDTOSchema> {}
