import { ElectricUtilityProfileDetailSearchSchema } from "./ElectricUtilityProfileDetailSearchSchema";

import { z } from "zod";

export const FetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema =
  ElectricUtilityProfileDetailSearchSchema;

export interface IFetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema
  extends z.infer<
    typeof FetchPostToGetPaginatedElectricUtilityProfilesPayloadSchema
  > {}
