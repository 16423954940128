import { z } from "zod";

export const PersonDefaultFieldDTOSchema = z.object({
  id: z.number().int().gte(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  configurable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
});

export interface IPersonDefaultFieldDTOSchema
  extends z.infer<typeof PersonDefaultFieldDTOSchema> {}
