import { z } from "zod";

export const FetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema =
  z.object({
    query: z.string(),
  });

export interface IFetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema
  extends z.infer<
    typeof FetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema
  > {}
