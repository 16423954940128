import { useQuery } from "react-query";

import type { IUseFetchGetEcoriInverterBrands } from "./interface";

import { fetchGetEcoriInverterBrands } from "~api/equipments/ecori/inverters/get";

export const useFetchGetEcoriInverterBrandsKey =
  "api/equipments/ecori/inverters/get";

export function useFetchGetEcoriInverterBrands({
  dependencyArray = [],
  options,
}: IUseFetchGetEcoriInverterBrands) {
  return useQuery(
    [useFetchGetEcoriInverterBrandsKey, dependencyArray],
    async () => await fetchGetEcoriInverterBrands(),
    options,
  );
}
