import { api, apiException } from "~services/api";
import {
  ConsultantUserDTOSchema,
  CreateConsultantUserFormSchema,
  ICreateConsultantUserFormSchema,
} from "~types/schemas";

export const fetchCreateConsultantUser = async (
  payload: ICreateConsultantUserFormSchema,
) => {
  try {
    const body = CreateConsultantUserFormSchema.parse(payload);

    const response = await api.post(`/consultants`, body);

    const parsedData = ConsultantUserDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
