import { api, apiException } from "~services/api";
import {
  PersonCustomFieldDtoSchema,
  FetchUpdatePersonCustomFieldPayloadSchema,
  IFetchUpdatePersonCustomFieldPayloadSchema,
} from "~types/schemas";

export const fetchUpdatePersonCustomField = async (
  payload: IFetchUpdatePersonCustomFieldPayloadSchema,
) => {
  try {
    const { ...body } =
      FetchUpdatePersonCustomFieldPayloadSchema.parse(payload);

    const response = await api.put(`/config/personCustomFields`, body);

    const parsedData = PersonCustomFieldDtoSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
