import { api, apiException } from "~services/api";
import {
  OrganizationDTOSchema,
  FetchGetOrganizationInformationByPipedriveIdPayloadSchema,
  type IFetchGetOrganizationInformationByPipedriveIdPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetOrganizationInformationByPipedriveId = async (
  payload: IFetchGetOrganizationInformationByPipedriveIdPayloadSchema,
) => {
  try {
    const { pipedriveId } =
      FetchGetOrganizationInformationByPipedriveIdPayloadSchema.parse(payload);

    const response = await api.get(`/organizations/pipedrive/${pipedriveId}`);

    const parsedData = OrganizationDTOSchema.omit({ id: true })
      .extend({
        id: z
          .number()
          .int()
          .gt(0)
          .nullable()
          .transform((val) => val ?? undefined),
      })
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
