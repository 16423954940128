import { api, apiException } from "~services/api";
import {
  FetchSendNewPasswordPayloadSchema,
  IFetchSendNewPasswordPayloadSchema,
} from "~types/schemas";

export const fetchSendNewPassword = async (
  payload: IFetchSendNewPasswordPayloadSchema,
) => {
  try {
    const { token, ...body } = FetchSendNewPasswordPayloadSchema.parse(payload);

    await api.post(`/users/reset-password`, body, {
      params: {
        token,
      },
      headers: {
        ...api.defaults.headers.common,
        Authorization: undefined, // Remove Bearer token
      },
    });
  } catch (error) {
    throw apiException(error);
  }
};
