import { z } from "zod";

export const FieldBindDTOSchema = z.object({
  id: z.number().int().gt(0),
  fieldKey: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  bindKey: z.string(),
});

export interface IFieldBindDTOSchema
  extends z.infer<typeof FieldBindDTOSchema> {}
