import { api, apiException } from "~services/api";
import {
  CreatePersonCustomFieldFormSchema,
  ICreatePersonCustomFieldFormSchema,
  PersonCustomFieldDtoSchema,
} from "~types/schemas";

export const fetchCreatePersonCustomField = async (
  payload: ICreatePersonCustomFieldFormSchema,
) => {
  try {
    const body = CreatePersonCustomFieldFormSchema.parse(payload);

    const response = await api.post(`/config/personCustomFields`, body);

    const parsedData = PersonCustomFieldDtoSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
