import { api, apiException } from "~services/api";
import {
  FetchDeleteValidationRulePayloadSchema,
  IFetchDeleteValidationRulePayloadSchema,
} from "~types/schemas";

export const fetchDeleteValidationRule = async (
  payload: IFetchDeleteValidationRulePayloadSchema,
) => {
  try {
    const { validationRulId } =
      FetchDeleteValidationRulePayloadSchema.parse(payload);

    await api.delete(`/rule-expression/${validationRulId}`);
  } catch (error) {
    throw apiException(error);
  }
};
