import { z } from "zod";

export const projectTypeEnumValues = [
  "ALL",
  "DEFAULT",
  "EXPRESS",
  "DIVERSE",
] as const;

export const ProjectTypeEnum = z
  .enum(projectTypeEnumValues)
  .catch((e) => e.input);

export type ProjectTypeEnum = z.infer<typeof ProjectTypeEnum>;
