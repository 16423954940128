import { z } from "zod";

export const TemplateReportFormSchema = z
  .object({
    name: z.string().optional(),
    fileDataInBase64: z.string().optional(),
    mimeType: z.string().optional(),
  })
  .partial();

export interface ITemplateReportFormSchema
  extends z.infer<typeof TemplateReportFormSchema> {}
