import { api, apiException } from "~services/api";
import {
  type IFetchGetSearchClientPayloadSchema,
  FetchGetSearchClientPayloadSchema,
  ClientSearchDTOSchema,
} from "~types/schemas";

export const fetchGetSearchClient = async (
  payload: IFetchGetSearchClientPayloadSchema,
) => {
  const params = FetchGetSearchClientPayloadSchema.parse(payload);

  try {
    const response = await api.get(`/projects/step_one/search`, { params });

    const parsedData = ClientSearchDTOSchema.array().parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
