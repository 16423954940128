import { api, apiException } from "~services/api";
import {
  FetchDeleteCostAggregatorPayloadSchema,
  IFetchDeleteCostAggregatorPayloadSchema,
} from "~types/schemas/FetchDeleteCostAggregatorPayloadSchema";

export const fetchDeleteCostAggregator = async (
  payload: IFetchDeleteCostAggregatorPayloadSchema,
) => {
  try {
    const path = FetchDeleteCostAggregatorPayloadSchema.parse(payload);

    await api.delete<void>(`/cost-aggregator/${path.costAggregatorId}`);
  } catch (error) {
    throw apiException(error);
  }
};
