import { useQuery } from "react-query";

import type { IUseFetchGetCustomVariablesWithFieldBind } from "./interface";

import { fetchGetCustomVariablesWithFieldBind } from "~api/custom-variable/field-bind/get";

export const useFetchGetCustomVariablesWithFieldBindKey =
  "api/custom-variable/field-bind/get";

export function useFetchGetCustomVariablesWithFieldBind({
  dependencyArray = [],
  options,
}: IUseFetchGetCustomVariablesWithFieldBind) {
  return useQuery(
    [useFetchGetCustomVariablesWithFieldBindKey, dependencyArray],
    async () => await fetchGetCustomVariablesWithFieldBind(),
    options,
  );
}
