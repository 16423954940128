import { z } from "zod";

export const PerformanceProfileFormSchema = z.object({
  description: z.string(),
  value: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IPerformanceProfileFormSchema
  extends z.infer<typeof PerformanceProfileFormSchema> {}
