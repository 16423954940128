import { TypeDocumentEnum } from "~types/enum";
import { z } from "zod";

export const EosDocFormatedReturnSchema = z.object({
  protocol: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  tipoDocumento: TypeDocumentEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  url: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IEosDocFormatedReturnSchema
  extends z.infer<typeof EosDocFormatedReturnSchema> {}
