import { HistoryFilterEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetHistoriesFilterByPersonIdPayloadSchema = z.object({
  personId: z.number().int().gt(0),
  searchQuery: HistoryFilterEnum,
  page: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 5),
});

export interface IFetchGetHistoriesFilterByPersonIdPayloadSchema
  extends z.infer<typeof FetchGetHistoriesFilterByPersonIdPayloadSchema> {}
