import { z } from "zod";

export const VoltageEnum = z
  .enum([
    "V110",
    "V220",
    "V380",
    "V460",
    "V480",
    "V500",
    "V540",
    "V600",
    "V750",
    "V800",
  ] as const)
  .catch((e) => e.input);

export type VoltageEnum = z.infer<typeof VoltageEnum>;
