import { useMutation } from "react-query";

import { useFetchGetAllPaymentSimulationsKey } from "../useFetchGetAllPaymentSimulations";
import type { IUseFetchUpdateManyPaymentSimulationOptions } from "./interface";

import { fetchUpdateManySimulationOption } from "~api/project/payment/simulation/option/from-list/put";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateManyPaymentSimulationOptionsKey =
  "api/project/payment/simulation/option/from-list/put";

export function useFetchUpdateManyPaymentSimulationOptions({
  options,
}: IUseFetchUpdateManyPaymentSimulationOptions) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateManySimulationOption(payload),
    {
      ...options,
      mutationKey: useFetchUpdateManyPaymentSimulationOptionsKey,
      onSuccess: (data, variables, context) => {
        message.success("Simulação alterada");
        queryClient.invalidateQueries(useFetchGetAllPaymentSimulationsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao alterar simulação: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
