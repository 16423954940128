import { api, apiException } from "~services/api";
import {
  FetchDeleteReasonForLossPayloadSchema,
  type IFetchDeleteReasonForLossPayloadSchema,
} from "~types/schemas";

export const fetchDeleteReasonForLoss = async (
  payload: IFetchDeleteReasonForLossPayloadSchema,
) => {
  try {
    const { id } = FetchDeleteReasonForLossPayloadSchema.parse(payload);

    await api.delete(`/reason-for-loss/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
