import { z } from "zod";

export const FetchImportUserFromPipedrivePayloadSchema = z.object({
  userId: z.number().int().gt(0),
  profileId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchImportUserFromPipedrivePayloadSchema
  extends z.infer<typeof FetchImportUserFromPipedrivePayloadSchema> {}
