import { z } from "zod";

export const FetchGetProposalDefaultFieldSettingsByProfilePayloadSchema =
  z.object({
    formFieldId: z.number().int().gt(0),
  });

export interface IFetchGetProposalDefaultFieldSettingsByProfilePayloadSchema
  extends z.infer<
    typeof FetchGetProposalDefaultFieldSettingsByProfilePayloadSchema
  > {}
