import { api, apiException } from "~services/api";
import {
  type IFetchUpdateSolarServicebyDealIdPayloadSchema,
  DealDTOSchema,
  FetchUpdateSolarServicebyDealIdPayloadSchema,
} from "~types/schemas";

export const fetchUpdateSolarServiceByDealId = async (
  payload: IFetchUpdateSolarServicebyDealIdPayloadSchema,
) => {
  try {
    const { id, ...body } =
      FetchUpdateSolarServicebyDealIdPayloadSchema.parse(payload);

    const response = await api.patch(`/deal/${id}/solar-service`, body);

    const parsedData = DealDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
