import { z } from "zod";

export const LowVoltageTaxesDTOSchema = z.object({
  te: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusd: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireB: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  teFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  tusdFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  wireBFinalValueWithTaxes: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  unity: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ILowVoltageTaxesDTOSchema
  extends z.infer<typeof LowVoltageTaxesDTOSchema> {}
