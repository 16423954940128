import { z } from "zod";

export const BaseCalculationEnum = z
  .enum([
    "VALOR_TOTAL",
    "CUSTOS_COMPLEMENTARES",
    "VALOR_DO_MATERIAL",
    "VALOR_FIXO",
  ] as const)
  .catch((e) => e.input);

export type BaseCalculationEnum = z.infer<typeof BaseCalculationEnum>;
