import { useQuery } from "react-query";

import type { IUseFetchGetPipedriveCustomFields } from "./interface";

import { fetchGetPipedriveCustomFields } from "~api/pipedrive/custom-fields/get";

export const useFetchGetPipedriveCustomFieldsKey =
  "api/pipedrive/custom-fields/get";

export function useFetchGetPipedriveCustomFields({
  dependencyArray = [],
  options,
}: IUseFetchGetPipedriveCustomFields) {
  return useQuery(
    [useFetchGetPipedriveCustomFieldsKey, dependencyArray],
    async () => await fetchGetPipedriveCustomFields(),
    options,
  );
}
