import { useQuery } from "react-query";

import type { IUseFetchGetDefaultMessageUser } from "./interface";

import { fetchGetDefaultMessageUser } from "~api/users/default_message/get";

export const useFetchGetDefaultMessageUserKey = "api/users/default_message/get";

export function useFetchGetDefaultMessageUser({
  dependencyArray = [],
  options,
}: IUseFetchGetDefaultMessageUser) {
  return useQuery(
    [useFetchGetDefaultMessageUserKey, dependencyArray],
    async () => await fetchGetDefaultMessageUser(),
    options,
  );
}
