import { api, apiException } from "~services/api";
import {
  FetchUpdateActivityDateSchema,
  type IFetchUpdateActivityDateSchema,
} from "~types/schemas";

export const fetchUpdateActivityDate = async (
  payload: IFetchUpdateActivityDateSchema,
) => {
  try {
    const { id, ...body } = FetchUpdateActivityDateSchema.parse(payload);

    await api.patch(`/activities/${id}/date`, body);
  } catch (error) {
    throw apiException(error);
  }
};
