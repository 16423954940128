import { api, apiException } from "~services/api";
import {
  FetchDeleteDealCustomFieldPayloadSchema,
  IFetchDeleteDealCustomFieldPayloadSchema,
} from "~types/schemas";

export const fetchDeleteDealCustomField = async (
  payload: IFetchDeleteDealCustomFieldPayloadSchema,
) => {
  try {
    const { id } = FetchDeleteDealCustomFieldPayloadSchema.parse(payload);

    await api.delete(`/config/deals/custom-fields/${id}`);
  } catch (error) {
    throw apiException(error);
  }
};
