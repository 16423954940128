import { useQuery } from "react-query";

import { IUseFetchGetPaginatedDiverseKits } from "./interface";

import { fetchGetPaginatedDiverseKits } from "~api/product/other/kit/get";

export const UseFetchGetPaginatedDiverseKitsKey = "api/product/other/kit/get";

export function useFetchGetPaginatedDiverseKits({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedDiverseKits) {
  return useQuery(
    [UseFetchGetPaginatedDiverseKitsKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedDiverseKits(payload);
    },
    options,
  );
}
