import { useQuery } from "react-query";

import type { IUseFetchGetPaginatedDatasheet } from "./interface";

import { fetchGetPaginatedDatasheet } from "~api/admin/datasheets/get";

export const useFetchGetPaginatedDatasheetKey = "api/admin/datasheets/get";

export function useFetchGetPaginatedDatasheet({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedDatasheet) {
  return useQuery(
    [useFetchGetPaginatedDatasheetKey, dependencyArray],
    async () => await fetchGetPaginatedDatasheet(payload),
    options,
  );
}
