import { useQuery } from "react-query";

import type { IUseFetchGetReasonForLossById } from "./interface";

import { fetchGetReasonForLossById } from "~api/reason-for-loss/{id}/get";

export const useFetchGetReasonForLossByIdKey = "api/reason-for-loss/{id}/get";

export function useFetchGetReasonForLossById({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetReasonForLossById) {
  return useQuery(
    [useFetchGetReasonForLossByIdKey, dependencyArray],
    async () => await fetchGetReasonForLossById(payload),
    options,
  );
}
