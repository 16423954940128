import { OrganizationDTOSchema } from "./OrganizationDTOSchema";
import { PaginationSchema } from "./PaginationSchema";

import { z } from "zod";

export const PaginatedOrganizationDTOSchema = PaginationSchema(
  OrganizationDTOSchema,
);

export interface IPaginatedOrganizationDTOSchema
  extends z.infer<typeof PaginatedOrganizationDTOSchema> {}
