import { CommissionFormSchema } from "./CommissionFormSchema";

import { z } from "zod";

export const FetchUpdateCommissionByIdPayloadSchema =
  CommissionFormSchema.extend({
    commissionId: z.number().int().gt(0),
  });

export interface IFetchUpdateCommissionByIdPayloadSchema
  extends z.infer<typeof FetchUpdateCommissionByIdPayloadSchema> {}
