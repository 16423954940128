import { useMutation } from "react-query";

import { useFetchGetSessionDataKey } from "../../auth/useFetchGetSessionData";
import type { IUseFetchUpdatePricingTypeInUse } from "./interface";

import { fetchUpdatePricingTypeInUse } from "~api/users/project-type/{pricingTypeId}/put";
import { useFetchGetPaginatedAlternatingCurrentMaterialsKey } from "~hooks/api/ac-materials/useFetchGetPaginatedAlternatingCurrentMaterials";
import { UseFetchGetCurrentAdditionalCostMarginTypeKey } from "~hooks/api/additional-cost-margin-type/useFetchGetCurrentAdditionalCostMarginType";
import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "~hooks/api/additional-costs/useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "~hooks/api/additional-costs/useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetAdditionalCostTaxesKey } from "~hooks/api/config/useFetchGetAdditionalCostTaxes";
import { useFetchGetCustomFieldsKey } from "~hooks/api/config/useFetchGetCustomFields";
import { useFetchGetPaginatedTransformerRulesKey } from "~hooks/api/config/useFetchGetPaginatedTransformerRules";
import { useFetchGetPaginatedCostAggregatorKey } from "~hooks/api/cost-aggregator/useFetchGetPaginatedCostAggregator";
import { useFetchGetCustomVariablesWithFieldBindKey } from "~hooks/api/custom-variable/useFetchGetCustomVariablesWithFieldBind";
import { useFetchGetProposalFieldsKey } from "~hooks/api/fields/useFetchGetProposalFields";
import { useFetchGetAllLaborsKey } from "~hooks/api/labors/useFetchGetPaginatedLabors";
import { useFetchGetLogisticsKey } from "~hooks/api/logistics/useFetchGetLogistics";
import { useFetchGetStructureCalculationKey } from "~hooks/api/structuresMaterialCostRule/useFetchGetStructureCalculation";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdatePricingTypeInUseKey =
  "api/users/project-type/{pricingTypeId}/put";

export function useFetchUpdatePricingTypeInUse({
  activeTab,
  ...options
}: IUseFetchUpdatePricingTypeInUse) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchUpdatePricingTypeInUse(payload);
    },
    {
      ...options,
      mutationKey: useFetchUpdatePricingTypeInUseKey,
      onSuccess: (data, variables, context) => {
        message.success("Precificação atualizada!");
        queryClient.invalidateQueries(useFetchGetSessionDataKey);
        queryClient.invalidateQueries(useFetchGetCustomFieldsKey);
        queryClient.invalidateQueries(useFetchGetProposalFieldsKey);
        queryClient.invalidateQueries(
          useFetchGetCustomVariablesWithFieldBindKey,
        );
        if (activeTab === "itens-complementares") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedComplementaryItemsOfSettingsKey,
          );
        }
        if (activeTab === "variaveis-auxiliares") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedAdditionalCostsAuxVariableKey,
          );
        }
        if (activeTab === "logistica") {
          queryClient.invalidateQueries(useFetchGetLogisticsKey);
        }
        if (activeTab === "corrente-alternada") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedAlternatingCurrentMaterialsKey,
          );
        }
        if (activeTab === "mao-de-obra") {
          queryClient.invalidateQueries(useFetchGetAllLaborsKey);
        }
        if (activeTab === "calculo-de-estrutura") {
          queryClient.invalidateQueries(useFetchGetStructureCalculationKey);
        }
        if (activeTab === "additional-cost-margin-type") {
          queryClient.invalidateQueries(
            UseFetchGetCurrentAdditionalCostMarginTypeKey,
          );
        }
        if (activeTab === "transformadores") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedTransformerRulesKey,
          );
        }
        if (activeTab === "impostos-pos-precificacao") {
          queryClient.invalidateQueries(useFetchGetAdditionalCostTaxesKey);
        }
        if (activeTab === "agregador-de-custos") {
          queryClient.invalidateQueries(useFetchGetPaginatedCostAggregatorKey);
        }
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error("Falha ao trocar precificação : " + error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
