import { api, apiException } from "~services/api";
import {
  type IFetchGetLeadTimeInsightGroupedByUserPayloadSchema,
  FetchGetLeadTimeInsightGroupedByUserPayloadSchema,
  LeadTimeInsightGroupedByUserDtoSchema,
} from "~types/schemas";

export const fetchGetLeadTimeInsightGroupedByUser = async (
  payload: IFetchGetLeadTimeInsightGroupedByUserPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetLeadTimeInsightGroupedByUserPayloadSchema.parse(payload);

    const response = await api.get("/insights/leadTimeInsightGroupedByUser", {
      params: queryParams,
    });

    const parsedData = LeadTimeInsightGroupedByUserDtoSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
