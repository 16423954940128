import { api, apiException } from "~services/api";
import {
  FetchUpdatePricingTypeInUsePayloadSchema,
  IFetchUpdatePricingTypeInUsePayloadSchema,
} from "~types/schemas";

export const fetchUpdatePricingTypeInUse = async (
  payload: IFetchUpdatePricingTypeInUsePayloadSchema,
) => {
  try {
    const { pricingTypeId } =
      FetchUpdatePricingTypeInUsePayloadSchema.parse(payload);

    await api.put(`/users/pricing-type/${pricingTypeId}`);
  } catch (error) {
    throw apiException(error);
  }
};
