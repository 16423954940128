import { CustomFieldSettingsFormSchema } from "./CustomFieldSettingsFormSchema";

import { z } from "zod";

export const FetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema =
  z.object({
    customFieldId: z.number().int().gt(0),
    settings: z
      .array(CustomFieldSettingsFormSchema)
      .nullish()
      .transform((val) => val ?? []),
  });

export interface IFetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema
  extends z.infer<
    typeof FetchUpdateProposalCustomFieldsProfileSettingsPayloadSchema
  > {}
