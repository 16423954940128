import { api, apiException } from "~services/api";
import type { CustomProductDTO } from "~types/api";
import {
  CustomProductDTOSchema,
  CustomProductFormSchema,
  ICustomProductFormSchema,
} from "~types/schemas";

export const fetchCreateProductTypeOther = async (
  payload: ICustomProductFormSchema,
) => {
  try {
    const body = CustomProductFormSchema.parse(payload);

    const response = await api.post<CustomProductDTO>(`/product/other`, body);

    const parsedData = CustomProductDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
