import { api, apiException } from "~services/api";
import {
  type IFetchGetAdminModulesByIdPayloadSchema,
  FetchGetAdminModulesByIdPayloadSchema,
  PvModuleDTOSchema,
} from "~types/schemas";

export const fetchGetAdminModulesById = async (
  payload: IFetchGetAdminModulesByIdPayloadSchema,
) => {
  try {
    const { id } = FetchGetAdminModulesByIdPayloadSchema.parse(payload);

    const response = await api.get(`/admin/modules/${id}`);

    const parsedData = PvModuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
