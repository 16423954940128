import { useQuery } from "react-query";

import type { IUseFetchGetAllKomecoEquipmentModules } from "./interface";

import { fetchGetAllKomecoEquipmentModules } from "~api/equipments/komeco/modules/get";

export const useFetchGetAllKomecoEquipmentModulesKey =
  "api/equipments/komeco/modules/get";

export function useFetchGetAllKomecoEquipmentModules({
  dependencyArray = [],
  options,
}: IUseFetchGetAllKomecoEquipmentModules) {
  return useQuery(
    [useFetchGetAllKomecoEquipmentModulesKey, dependencyArray],
    async () => await fetchGetAllKomecoEquipmentModules(),
    options,
  );
}
