import { useQuery } from "react-query";

import type { IUseFetchGetAllProposalDefaultFieldSettings } from "./interface";

import { fetchGetDefaultFieldSettings } from "~api/default-fields/settings/get";

export const useFetchGetAllProposalDefaultFieldSettingsKey =
  "api/default-fields/settings/get";

export function useFetchGetAllProposalDefaultFieldSettings({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetAllProposalDefaultFieldSettings) {
  return useQuery(
    [useFetchGetAllProposalDefaultFieldSettingsKey, dependencyArray],
    async () => await fetchGetDefaultFieldSettings(payload),
    options,
  );
}
