import { api, apiException } from "~services/api";
import {
  IFetchGetEosFinancialProposalDocumentsPayloadSchema,
  FetchGetEosFinancialProposalDocumentsPayloadSchema,
  EosDocumentSearchResponseSchema,
} from "~types/schemas";

export const fetchGetEosFinancialProposalDocuments = async (
  payload: IFetchGetEosFinancialProposalDocumentsPayloadSchema,
) => {
  try {
    const { protocol } =
      FetchGetEosFinancialProposalDocumentsPayloadSchema.parse(payload);

    const response = await api.get(`/eosfin/proposals/${protocol}/documents
    `);

    const parsedData = EosDocumentSearchResponseSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
