import { PermissionValueSchema } from "./PermissionValueSchema";

import { PermissionSectionEnum } from "~types/enum";
import { z } from "zod";

export const PermissionDTOSchema = z.object({
  section: PermissionSectionEnum.transform((val) => val ?? undefined),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  permissions: z
    .array(PermissionValueSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IPermissionDTOSchema
  extends z.infer<typeof PermissionDTOSchema> {}
