import { z } from "zod";

export const CustomVariableBindDTOSchema = z.object({
  id: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined), // ID IN CUSTOM VARIABLES IS NOT REQUIRED
  name: z.string(), // REQUIRED
  value: z.string(), // REQUIRED
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  fieldKey: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
});

export interface ICustomVariableBindDTOSchema
  extends z.infer<typeof CustomVariableBindDTOSchema> {}
