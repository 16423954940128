import { useMutation } from "react-query";

import { useFetchGetCustomFieldsKey } from "../useFetchGetCustomFields";
import type { IUseFetchDeleteCustomField } from "./interface";

import { fetchDeleteCustomField } from "~api/config/customFields/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteCustomFieldsKey =
  "api/config/customFields/{id}/delete";

export function useFetchDeleteCustomField(
  options?: IUseFetchDeleteCustomField,
) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      const response = await fetchDeleteCustomField(payload);
      return response.data;
    },
    {
      ...options,
      mutationKey: useFetchDeleteCustomFieldsKey,
      onSuccess: (data, variables, context) => {
        message.success("Campo foi removido");
        queryClient.invalidateQueries(useFetchGetCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
