import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldsKey } from "../../useFetchGetDealCustomFields";
import { useFetchGetDealCustomFieldGroupByIdKey } from "../useFetchGetDealCustomFieldGroupById";
import { useFetchGetDealCustomFieldsGroupKey } from "../useFetchGetDealCustomFieldsGroup";
import { useFetchGetDealCustomFieldsGroupListKey } from "../useFetchGetDealCustomFieldsGroupList";
import type { IUseFetchDeleteDealCustomFieldGroup } from "./interface";

import { fetchDeleteDealCustomFieldGroup } from "~api/config/deals/custom-fields/groups/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteDealCustomFieldGroupKey =
  "api/config/deals/custom-fields/groups/{id}/delete";

export function useFetchDeleteDealCustomFieldGroup({
  options,
}: IUseFetchDeleteDealCustomFieldGroup) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeleteDealCustomFieldGroup(payload),
    {
      ...options,
      mutationKey: useFetchDeleteDealCustomFieldGroupKey,
      onSuccess: (data, variables, context) => {
        message.success("Grupo de campos customizados de negócio foi apagado!");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsGroupListKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldGroupByIdKey);
        queryClient.invalidateQueries(useFetchGetDealCustomFieldsKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
