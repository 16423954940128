import { z } from "zod";

export const FetchImpersonateConsultantInCompanyByIdPayloadSchema = z.object({
  companyId: z.number().int().gt(0),
});

export interface IFetchImpersonateConsultantInCompanyByIdPayloadSchema
  extends z.infer<
    typeof FetchImpersonateConsultantInCompanyByIdPayloadSchema
  > {}
