import { useMutation } from "react-query";

import type { IUseFetchDeleteBaseAddress } from "./interface";

import { fetchDeleteBaseAddress } from "~api/company/addresses/{id}/delete";
import { useFetchGetCompanyAddressesKey } from "~hooks/api/company/useFetchGetCompanyAddresses";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteBaseAddressKey = "api/company/addresses/{id}/delete";

export function useFetchDeleteBaseAddress({
  options,
}: IUseFetchDeleteBaseAddress) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => {
      await fetchDeleteBaseAddress({ baseAddressId: payload.baseAddressId });
    },
    {
      ...options,
      mutationKey: useFetchDeleteBaseAddressKey,
      onSuccess: (data, variables, context) => {
        message.success("Endereço base foi removido");
        queryClient.invalidateQueries(useFetchGetCompanyAddressesKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao remover endereço: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
