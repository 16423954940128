import { useQuery } from "react-query";

import type { IFetchGetPaginatedProductInverterPowers } from "./interface";

import { fetchGetPaginatedProductInverterPowers } from "~api/product/search/inverter/powerList/get";

export const useFetchGetPaginatedProductInverterPowersKey =
  "api/product/search/inverter/powerList/get";

export function useFetchGetPaginatedProductInverterPowers({
  dependencyArray = [],
  options,
  payload,
}: IFetchGetPaginatedProductInverterPowers) {
  return useQuery(
    [useFetchGetPaginatedProductInverterPowersKey, dependencyArray],
    async () => await fetchGetPaginatedProductInverterPowers(payload),
    options,
  );
}
