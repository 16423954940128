import { PaginationSchema } from "./PaginationSchema";
import { ProposalByDealIdResponseSchema } from "./ProposalByDealIdResponseSchema";

import { z } from "zod";

export const PaginatedProposalByDealIdResponseSchema = PaginationSchema(
  ProposalByDealIdResponseSchema,
);
export interface IPaginatedProposalByDealIdResponseSchema
  extends z.infer<typeof PaginatedProposalByDealIdResponseSchema> {}
