import { z } from "zod";

export const FetchGetProposalStepOneInitialValuesPayloadSchema = z.object({
  dealId: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IFetchGetProposalStepOneInitialValuesPayloadSchema
  extends z.infer<typeof FetchGetProposalStepOneInitialValuesPayloadSchema> {}
