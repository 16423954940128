import { z } from "zod";

export const FetchGetProposalCustomFieldsPayloadSchema = z.object({
  pricingTypeId: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchGetProposalCustomFieldsPayloadSchema
  extends z.infer<typeof FetchGetProposalCustomFieldsPayloadSchema> {}
