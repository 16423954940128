import { useQuery } from "react-query";

import { IUseFetchGetPaginatedAdditionalCostsAuxVariable } from "./interface";

import { fetchGetPaginatedAdditionalCostsAuxVariable } from "~api/additional-costs/aux-variable/get";

export const useFetchGetPaginatedAdditionalCostsAuxVariableKey =
  "api/additional-costs/aux-variable/get";

export function useFetchGetPaginatedAdditionalCostsAuxVariable({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetPaginatedAdditionalCostsAuxVariable) {
  return useQuery(
    [useFetchGetPaginatedAdditionalCostsAuxVariableKey, dependencyArray],
    async () => {
      return await fetchGetPaginatedAdditionalCostsAuxVariable(payload);
    },
    options,
  );
}
