import { EdeltecParameterFormSchema } from "./EdeltecParameterFormSchema";

import { z } from "zod";

export const FetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema =
  EdeltecParameterFormSchema;

export interface IFetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema
  extends z.infer<
    typeof FetchUpdateConfigEdeltecDistributorIntegrationPayloadSchema
  > {}
