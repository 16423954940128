import { z } from "zod";

export const ReferenceEntityEnum = z
  .enum([
    "ADDITIONAL_COST_DESCRIPTOR",
    "LABOR",
    "ALTERNATING_CURRENT_MATERIAL",
    "LOGISTIC",
    "STRUCTURE",
    "TRANSFORMER",
  ] as const)
  .catch((e) => e.input);

export type ReferenceEntityEnum = z.infer<typeof ReferenceEntityEnum>;
