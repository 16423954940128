import { ActivitiesDoneByUserInsightBarsDataSchema } from "./ActivitiesDoneByUserInsightBarsDataSchema";

import { MonthEnum } from "~types/enum";
import { z } from "zod";

export const ActivitiesDoneByUserInsightDataSchema = z.object({
  year: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  month: MonthEnum.nullish().transform((val) => val ?? undefined),
  activitiesDoneByUserInsightBarsData:
    ActivitiesDoneByUserInsightBarsDataSchema.array()
      .nullish()
      .transform((val) => val ?? []),
  totalActivities: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IActivitiesDoneByUserInsightDataSchema
  extends z.infer<typeof ActivitiesDoneByUserInsightDataSchema> {}
