import { z } from "zod";

export const FetchGetPaginatedAdminUsersPayloadSchema = z.object({
  page: z.number().int().gte(0).default(0),
  size: z.number().int().gte(0).default(5),
  company: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  email: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  id: z
    .number()
    .int()
    .gte(0)
    .nullish()
    .transform((val) => val ?? undefined),
  isPipedriveIntegration: z
    .boolean()
    .nullish()
    .transform((val) => val ?? undefined), // not force it to be boolean!!!
});

export interface IFetchGetPaginatedAdminUsersPayloadSchema
  extends z.infer<typeof FetchGetPaginatedAdminUsersPayloadSchema> {}
