import { CustomProductFormSchema } from "./CustomProductFormSchema";

import { z } from "zod";

export const FetchUpdateProductTypeOtherPayloadSchema =
  CustomProductFormSchema.extend({
    productId: z.number().int().gt(0),
  });

export interface IFetchUpdateProductTypeOtherPayloadSchema
  extends z.infer<typeof FetchUpdateProductTypeOtherPayloadSchema> {}
