import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedStructuresPayloadSchema,
  IFetchGetPaginatedStructuresPayloadSchema,
  PaginatedCustomPvModuleStructureDTOSchema,
} from "~types/schemas";

export const fetchGetPaginatedStructures = async (
  payload: IFetchGetPaginatedStructuresPayloadSchema,
) => {
  try {
    const body = FetchGetPaginatedStructuresPayloadSchema.parse(payload);

    const response = await api.post(`/product/pvModuleStructure`, body);

    const parsedData = PaginatedCustomPvModuleStructureDTOSchema.parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
