import { ProjectTypeEnum } from "~types/enum";
import { z } from "zod";

export const PricingTypeFormSchema = z.object({
  name: z.string(),
  projectTypes: z
    .array(ProjectTypeEnum)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IPricingTypeFormSchema
  extends z.infer<typeof PricingTypeFormSchema> {}
