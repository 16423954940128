import { useMutation } from "react-query";

import { useFetchGetDealCustomFieldValueByIdKey } from "../useFetchGetDealCustomFieldValueById";
import type { IUseFetchUpdateDealCustomFieldValue } from "./interface";

import { fetchUpdateDealCustomFieldValue } from "~api/deal/{id}/custom-fields/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateDealCustomFieldValueKey =
  "api/deal/{id}/custom-fields/patch";

export function useFetchUpdateDealCustomFieldValue({
  options,
}: IUseFetchUpdateDealCustomFieldValue) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateDealCustomFieldValue(payload),
    {
      ...options,
      mutationKey: useFetchUpdateDealCustomFieldValueKey,
      onSuccess: (data, variables, context) => {
        message.success("Campos atualizados");
        queryClient.invalidateQueries(useFetchGetDealCustomFieldValueByIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
