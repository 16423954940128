import { MonthlySolarRadiationDTOSchema } from "./MonthlySolarRadiationDTOSchema";

import { z } from "zod";

export const FetchUpdateMonthlyRadiationByCustomIdPayloadSchema =
  MonthlySolarRadiationDTOSchema.extend({
    customId: z.number().int().gt(0),
  });

export interface IFetchUpdateMonthlyRadiationByCustomIdPayloadSchema
  extends z.infer<typeof FetchUpdateMonthlyRadiationByCustomIdPayloadSchema> {}
