import { OrganizationPeopleFormSchema } from "./OrganizationPeopleFormSchema";

import { z } from "zod";

export const FetchRemovePersonFromOrganizationPayloadSchema =
  OrganizationPeopleFormSchema.extend({
    organizationId: z.number().int().gt(0),
  });

export interface IFetchRemovePersonFromOrganizationPayloadSchema
  extends z.infer<typeof FetchRemovePersonFromOrganizationPayloadSchema> {}
