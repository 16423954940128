import { useMutation } from "react-query";

import { useFetchGetPremisesKey } from "../useFetchGetPremises";
import { useFetchGetPremisesTariffKey } from "../useFetchGetPremisesTariff";
import type { IUseFetchPatchPremisesUsefulArea } from "./interface";

import { fetchPatchPremisesUsefulArea } from "~api/premises/useful-area/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const UseFetchPatchPremisesUsefulAreaKey =
  "api/premises/useful-area/patch";

export function useFetchPatchPremisesUsefulArea({
  options,
  displaySuccessMessage = true,
}: IUseFetchPatchPremisesUsefulArea) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchPatchPremisesUsefulArea(payload),
    {
      ...options,
      mutationKey: UseFetchPatchPremisesUsefulAreaKey,
      onSuccess: (data, variables, context) => {
        if (displaySuccessMessage) {
          message.success("Valores das áreas úteis foram alterados");
        }
        queryClient.invalidateQueries(useFetchGetPremisesKey);
        queryClient.invalidateQueries(useFetchGetPremisesTariffKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar das áreas úteis: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
