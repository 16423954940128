import { api, apiException } from "~services/api";
import {
  IFetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema,
  FetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema,
  OrganizationAutoCompleteResponseSchema,
} from "~types/schemas";

export const fetchGetPipedriveUnlinkedAutoCompleteOrganization = async (
  payload: IFetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema,
) => {
  try {
    const queryParams =
      FetchGetPipedriveUnlinkedAutoCompleteOrganizationPayloadSchema.parse(
        payload,
      );

    const response = await api.get(
      `/organizations/pipedrive-unlinked/auto-complete`,
      {
        params: queryParams,
      },
    );

    const parsedData = OrganizationAutoCompleteResponseSchema.array().parse(
      response.data,
    );

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
