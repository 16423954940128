import { BusinessUnitDTOSchema } from "./BusinessUnitDTOSchema";
import { CustomFieldSettingsFormSchema } from "./CustomFieldSettingsFormSchema";

import {
  FieldTypeEnum,
  ProposalSectionEnum,
  ProposalStepEnum,
} from "~types/enum";
import { z } from "zod";

export const CustomFieldDTOSchema = z.object({
  id: z.number().int().gte(0),
  identifier: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  label: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  displayOrder: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  proposalSection: ProposalSectionEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  proposalStep: ProposalStepEnum.nullish().transform((val) => val ?? undefined),
  type: FieldTypeEnum,
  defaultValue: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
  required: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  editable: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  hidden: z
    .boolean()
    .nullish()
    .transform((val) => !!val),
  options: z
    .array(z.string())
    .nullish()
    .transform((val) => val ?? []),
  businessUnits: z
    .array(BusinessUnitDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  width: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  height: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  fieldSettings: CustomFieldSettingsFormSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface ICustomFieldDTOSchema
  extends z.infer<typeof CustomFieldDTOSchema> {}
