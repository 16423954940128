import { api, apiException } from "~services/api";
import { AdditionalCostDescriptor } from "~types/api/AdditionalCostDescriptor";
import type { AdditionalCostForm } from "~types/api/AdditionalCostForm";
import {
  AdditionalCostDTOSchema,
  FetchCreateAdditionalCostsPayloadSchema,
  IFetchCreateAdditionalCostsPayloadSchema,
} from "~types/schemas";

export type FetchSaveAdvancedComplementaryItems = {
  body: AdditionalCostForm;
};

export const fetchSaveAdvancedComplementaryItems = async (
  payload: IFetchCreateAdditionalCostsPayloadSchema,
) => {
  try {
    const body = FetchCreateAdditionalCostsPayloadSchema.parse(payload);

    const response = await api.post<AdditionalCostDescriptor>(
      `/advanced/additional-costs`,
      body,
    );

    const parsedData = AdditionalCostDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
