import { ConsumptionUnitFormSchema } from "./ConsumptionUnitFormSchema";

import { ConsumerUnitCompensationEnum } from "~types/enum";
import { z } from "zod";

export const FetchUpdateConsumptionUnitPayloadSchema = z.object({
  consumerUnits: z.array(ConsumptionUnitFormSchema),
  customId: z.number().int().gt(0),
  compensationType: ConsumerUnitCompensationEnum,
  allocationPercentage: z
    .number()
    .min(0)
    .max(100)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IFetchUpdateConsumptionUnitPayloadSchema
  extends z.infer<typeof FetchUpdateConsumptionUnitPayloadSchema> {}
