import { api, apiException } from "~services/api";
import {
  FetchGetElectricUtilityProfileAccessByPayloadSchema,
  IFetchGetElectricUtilityProfileAccessByPayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetElectricUtilityProfileAccessBy = async (
  payload: IFetchGetElectricUtilityProfileAccessByPayloadSchema,
) => {
  try {
    const query =
      FetchGetElectricUtilityProfileAccessByPayloadSchema.parse(payload);

    const response = await api.get(`/electric-utility-profile/access-by`, {
      params: {
        query,
      },
    });

    const parsedData = z
      .array(z.string())
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
