import { api, apiException } from "~services/api";
import {
  FetchUpdateActivitiesPayloadSchema,
  ActivitiesDTOSchema,
  IFetchUpdateActivitiesPayloadSchema,
} from "~types/schemas";

export const fetchUpdateActivities = async (
  payload: IFetchUpdateActivitiesPayloadSchema,
) => {
  try {
    const { activitiesId } = FetchUpdateActivitiesPayloadSchema.parse(payload);

    const response = await api.put(`/activitiesType/${activitiesId}`);

    const parsedData = ActivitiesDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
