import { useMutation } from "react-query";

import { useFetchGetAllPricingTypesKey } from "../useFetchGetAllPricingTypes";
import type { IUseFetchDeletePricingTypes } from "./interface";

import { fetchDeletePricingTypes } from "~api/pricing-types/{id}/delete";
import { useFetchGetPaginatedAlternatingCurrentMaterialsKey } from "~hooks/api/ac-materials/useFetchGetPaginatedAlternatingCurrentMaterials";
import { UseFetchGetCurrentAdditionalCostMarginTypeKey } from "~hooks/api/additional-cost-margin-type/useFetchGetCurrentAdditionalCostMarginType";
import { useFetchGetPaginatedAdditionalCostsAuxVariableKey } from "~hooks/api/additional-costs/useFetchGetPaginatedAdditionalCostsAuxVariable";
import { useFetchGetPaginatedComplementaryItemsOfSettingsKey } from "~hooks/api/additional-costs/useFetchGetPaginatedComplementaryItemsOfSettings";
import { useFetchGetAdditionalCostTaxesKey } from "~hooks/api/config/useFetchGetAdditionalCostTaxes";
import { useFetchGetPaginatedCostAggregatorKey } from "~hooks/api/cost-aggregator/useFetchGetPaginatedCostAggregator";
import { useFetchGetAllLaborsKey } from "~hooks/api/labors/useFetchGetPaginatedLabors";
import { useFetchGetLogisticsKey } from "~hooks/api/logistics/useFetchGetLogistics";
import { useFetchGetStructureCalculationKey } from "~hooks/api/structuresMaterialCostRule/useFetchGetStructureCalculation";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeletePricingTypesKey = "api/pricing-types/{id}/delete";

export function useFetchDeletePricingTypes({
  activeTab,
  ...options
}: IUseFetchDeletePricingTypes) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchDeletePricingTypes(payload),
    {
      ...options,
      mutationKey: useFetchDeletePricingTypesKey,
      onSuccess: (data, variables, context) => {
        message.success("Precificação foi removida");

        queryClient.invalidateQueries(useFetchGetAllPricingTypesKey);
        if (activeTab === "itens-complementares") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedComplementaryItemsOfSettingsKey,
          );
        }
        if (activeTab === "variaveis-auxiliares") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedAdditionalCostsAuxVariableKey,
          );
        }
        if (activeTab === "logistica") {
          queryClient.invalidateQueries(useFetchGetLogisticsKey);
        }
        if (activeTab === "corrente-alternada") {
          queryClient.invalidateQueries(
            useFetchGetPaginatedAlternatingCurrentMaterialsKey,
          );
        }
        if (activeTab === "mao-de-obra") {
          queryClient.invalidateQueries(useFetchGetAllLaborsKey);
        }
        if (activeTab === "calculo-de-estrutura") {
          queryClient.invalidateQueries(useFetchGetStructureCalculationKey);
        }
        if (activeTab === "additional-cost-margin-type") {
          queryClient.invalidateQueries(
            UseFetchGetCurrentAdditionalCostMarginTypeKey,
          );
        }
        // if (activeTab === "transformadores") {
        // }
        if (activeTab === "impostos-pos-precificacao") {
          queryClient.invalidateQueries(useFetchGetAdditionalCostTaxesKey);
        }
        if (activeTab === "agregador-de-custos") {
          queryClient.invalidateQueries(useFetchGetPaginatedCostAggregatorKey);
        }
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(error.message);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
