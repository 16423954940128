import { z } from "zod";

export const RegionSchema = z.object({
  id: z.number().int().gt(0),
  description: z
    .string()
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface IRegionSchema extends z.infer<typeof RegionSchema> {}
