import { useQuery } from "react-query";

import { IUseFetchGetProposalDefaultFieldSettingsByProfile } from "./interface";

import { fetchGetProposalDefaultFieldSettingsByProfile } from "~api/default-fields/profile-settings/get";

export const useFetchGetProposalDefaultFieldSettingsByProfileKey =
  "api/default-fields/profile-settings/get";

export function useFetchGetProposalDefaultFieldSettingsByProfile({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetProposalDefaultFieldSettingsByProfile) {
  return useQuery(
    [useFetchGetProposalDefaultFieldSettingsByProfileKey, dependencyArray],
    async () => await fetchGetProposalDefaultFieldSettingsByProfile(payload),
    options,
  );
}
