import { z } from "zod";

export const DefaultFieldProfileSettingsFormSchema = z
  .object({
    profileId: z.number().int().gte(0),
    editable: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    required: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
    hidden: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
  })
  .partial();

export interface IDefaultFieldProfileSettingsFormSchema
  extends z.infer<typeof DefaultFieldProfileSettingsFormSchema> {}
