import { useQuery } from "react-query";

import type { IUseFetchGetTotalWonGroupedByMonthInsight } from "./interface";

import { fetchGetTotalWonGroupedByMonthInsight } from "~api/insights/totalWonGroupedByMonthInsight/get";

export const useFetchGetTotalWonGroupedByMonthInsightKey =
  "api/insights/totalWonGroupedByMonthInsight/get";

export function useFetchGetTotalWonGroupedByMonthInsight({
  dependencyArray = [],
  options,
  payload,
}: IUseFetchGetTotalWonGroupedByMonthInsight) {
  return useQuery(
    [useFetchGetTotalWonGroupedByMonthInsightKey, dependencyArray],
    async () => await fetchGetTotalWonGroupedByMonthInsight(payload),
    options,
  );
}
