import { api, apiException } from "~services/api";
import {
  FetchDeleteCustomVariablePayloadSchema,
  IFetchDeleteCustomVariablePayloadSchema,
} from "~types/schemas";

export const fetchDeleteCustomVariable = async (
  payload: IFetchDeleteCustomVariablePayloadSchema,
) => {
  try {
    const { customVariableName } =
      FetchDeleteCustomVariablePayloadSchema.parse(payload);

    await api.delete<void>(`/custom-variable/${customVariableName}`);
  } catch (error) {
    throw apiException(error);
  }
};
