import { api, apiException } from "~services/api";
import {
  FetchGetProposalCustomFieldSettingsByProfilePayloadSchema,
  FieldSettingsDTOSchema,
  IFetchGetProposalCustomFieldSettingsByProfilePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetProposalCustomFieldSettingsByProfile = async (
  payload: IFetchGetProposalCustomFieldSettingsByProfilePayloadSchema,
) => {
  try {
    const { customFieldId } =
      FetchGetProposalCustomFieldSettingsByProfilePayloadSchema.parse(payload);

    const response = await api.get(
      `/fields/hideable/custom-field/${customFieldId}`,
    );

    const parsedData = z
      .array(FieldSettingsDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
