import { z } from "zod";

export const FetchGetPaginatedAdminModulesPayloadSchema = z.object({
  models: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  manufacturers: z
    .string()
    .array()
    .nullish()
    .transform((val) => val ?? undefined),
  powerWp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  page: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  size: z
    .number()
    .nullish()
    .transform((val) => val ?? 10),
});

export interface IFetchGetPaginatedAdminModulesPayloadSchema
  extends z.infer<typeof FetchGetPaginatedAdminModulesPayloadSchema> {}
