import { api, apiException } from "~services/api";
import {
  FetchGetPaginatedPersonNotesByPersonIdPayloadSchema,
  type IFetchGetPaginatedPersonNotesByPersonIdPayloadSchema,
  PaginatedNoteDTOSchema,
} from "~types/schemas";

export async function fetchGetPaginatedPersonNotesByPersonId(
  payload: IFetchGetPaginatedPersonNotesByPersonIdPayloadSchema,
) {
  try {
    const { personId, ...queryParams } =
      FetchGetPaginatedPersonNotesByPersonIdPayloadSchema.parse(payload);

    const response = await api.get(`/persons/${personId}/notes`, {
      params: queryParams,
    });

    const parsedData = PaginatedNoteDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
}
