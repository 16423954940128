import { api, apiException } from "~services/api";
import {
  DefaultFieldProfileSettingsDTOSchema,
  FetchGetProposalDefaultFieldSettingsByProfilePayloadSchema,
  IFetchGetProposalDefaultFieldSettingsByProfilePayloadSchema,
} from "~types/schemas";
import { z } from "zod";

export const fetchGetProposalDefaultFieldSettingsByProfile = async (
  payload: IFetchGetProposalDefaultFieldSettingsByProfilePayloadSchema,
) => {
  try {
    const { formFieldId } =
      FetchGetProposalDefaultFieldSettingsByProfilePayloadSchema.parse(payload);

    const response = await api.get(
      `/default-fields/profile-settings/${formFieldId}`,
    );

    const parsedData = z
      .array(DefaultFieldProfileSettingsDTOSchema)
      .nullish()
      .transform((val) => val ?? [])
      .parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
