import { useMutation } from "react-query";

import { useFetchGetHistoriesByOrganizationIdKey } from "../../histories/useFetchGetHistoriesByOrganizationId";
import { useFetchGetHistoriesByPersonIdKey } from "../../histories/useFetchGetHistoriesByPersonId";
import { useFetchGetPaginatedHistoriesFilterByDealIdKey } from "../../histories/useFetchGetHistoriesFilterByDealId";
import { useFetchGetHistoriesTypeFilterByDealIdKey } from "../../histories/useFetchGetHistoriesTypeFilterByDealId";
import { useFetchGetPaginatedPersonDealsByPersonIdKey } from "../../persons/useFetchGetPaginatedPersonDealsByPersonId";
import { useFetchGetPersonDealsCountByPersonIdKey } from "../../persons/useFetchGetPersonDealsCountByPersonId";
import { useFetchGetPipelineByIdKey } from "../../pipeline/useFetchGetPipelineById";
import { useFetchGetDealByCustomIdKey } from "../useFetchGetDealByCustomId";
import { useFetchSearchDealKey } from "../useFetchSearchDeal";
import { IUseFetchUpdateDiverseServiceByDealId } from "./interface";

import { fetchUpdateDiverseServiceByDealId } from "~api/deal/{id}/diverse-service/patch";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchUpdateDiverseServiceByDealIdKey =
  "api/deal/{id}/diverse-service/patch";

export function useFetchUpdateDiverseServiceByDealId({
  options,
}: IUseFetchUpdateDiverseServiceByDealId) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchUpdateDiverseServiceByDealId(payload),
    {
      ...options,
      mutationKey: useFetchUpdateDiverseServiceByDealIdKey,
      onSuccess: (data, variables, context) => {
        message.success("Serviço diverso foi atualizado!");
        queryClient.invalidateQueries(useFetchGetDealByCustomIdKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedPersonDealsByPersonIdKey,
        );
        queryClient.invalidateQueries(useFetchGetPersonDealsCountByPersonIdKey);
        queryClient.invalidateQueries(useFetchGetPipelineByIdKey);
        queryClient.invalidateQueries(useFetchSearchDealKey);
        queryClient.invalidateQueries(
          useFetchGetPaginatedHistoriesFilterByDealIdKey,
        );
        queryClient.invalidateQueries(
          useFetchGetHistoriesTypeFilterByDealIdKey,
        );
        queryClient.invalidateQueries(useFetchGetHistoriesByOrganizationIdKey);
        queryClient.invalidateQueries(useFetchGetHistoriesByPersonIdKey);
        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao atualizar serviço diverso: ${error.message}`);
        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
