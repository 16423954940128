import { AttachedFileDTOSchema } from "./AttachedFileDTOSchema";
import { PropertyDiffSchema } from "./PropertyDiffSchema";

import { EntityActionEnum, HistoryTypeEnum } from "~types/enum";
import { z } from "zod";

export const HistoryLogSchema = z.object({
  entityId: z.number().int().gt(0),
  entityCustomId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
  entityType: z.string(),
  entityAction: EntityActionEnum,
  historyType: HistoryTypeEnum,
  title: z.string(),
  attachedFiles: z
    .array(AttachedFileDTOSchema)
    .nullish()
    .transform((val) => val ?? []),
  propertyDiffs: z
    .array(PropertyDiffSchema)
    .nullish()
    .transform((val) => val ?? []),
});

export interface IHistoryLogSchema extends z.infer<typeof HistoryLogSchema> {}
