import { useQuery } from "react-query";

import type { IUseFetchGetNoteFile } from "./interface";

import { fetchGetNoteFile } from "~api/note/{noteId}/file/{fileId}/get";

export const useFetchNoteFileKey = "api/note/{noteId}/file/{fileId}/get";

export function useFetchGetNoteFile({
  options,
  dependencyArray,
  payload,
  customKey = useFetchNoteFileKey,
}: IUseFetchGetNoteFile) {
  return useQuery(
    [customKey, dependencyArray],
    async () => await fetchGetNoteFile(payload),
    options,
  );
}
