import { api, apiException } from "~services/api";
import {
  FetchLeaveConsultantInCompanyByIdPayloadSchema,
  IFetchLeaveConsultantInCompanyByIdPayloadSchema,
  TokenDTOSchema,
} from "~types/schemas";

export const fetchLeaveConsultantInCompanyById = async (
  payload: IFetchLeaveConsultantInCompanyByIdPayloadSchema,
) => {
  try {
    const { companyId } =
      FetchLeaveConsultantInCompanyByIdPayloadSchema.parse(payload);

    const response = await api.post(
      `/consultants/companies/${companyId}/leave`,
    );

    const parsedData = TokenDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
