import { api, apiException } from "~services/api";
import {
  FetchGetHistoriesByOrganizationIdPayloadSchema,
  PaginatedHistoryDTOSchema,
  type IFetchGetHistoriesByOrganizationIdPayloadSchema,
} from "~types/schemas";

export const fetchGetHistoriesByOrganizationId = async (
  payload: IFetchGetHistoriesByOrganizationIdPayloadSchema,
) => {
  try {
    const { organizationId, ...queryParams } =
      FetchGetHistoriesByOrganizationIdPayloadSchema.parse(payload);

    const response = await api.get(
      `/histories/organization/${organizationId}`,
      {
        params: queryParams,
      },
    );

    const parsedData = PaginatedHistoryDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
