import { SimplePipelineStageDTOSchema } from "./SimplePipelineStageDTOSchema";

import { z } from "zod";

export const DetailedPipelineDTOSchema = z.object({
  id: z.number().int().gt(0),
  name: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  dealsTotalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  dealsQuantity: z
    .number()
    .int()
    .nullish()
    .transform((val) => val ?? 0),
  stages: SimplePipelineStageDTOSchema.array()
    .nullish()
    .transform((val) => val ?? []),
});

export interface IDetailedPipelineDTOSchema
  extends z.infer<typeof DetailedPipelineDTOSchema> {}
