import { api, apiException } from "~services/api";
import { EdeltecParameterDTOSchema } from "~types/schemas";

export const fetchGetConfigEdeltecDistributorIntegration = async () => {
  try {
    const response = await api.get(`/distributors/edeltec`);

    const parsedData = EdeltecParameterDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
