import { TaxTypeEnum } from "~types/enum";
import { z } from "zod";

export const AneelInDevelopmentDTOSchema = z.object({
  concessionaire: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  ANEELResolution: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  startTerm: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  endTerm: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  tariffBases: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  subgroup: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  modality: TaxTypeEnum.nullish().transform((val) => val ?? undefined),
  class: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  subclass: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  detail: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  accessBy: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  station: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  unity: z
    .string()
    .nullish()
    .transform((val) => val ?? ""),
  tusd: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
  te: z
    .number()
    .nullish()
    .transform((val) => val ?? 0),
});

export interface IAneelInDevelopmentDTOSchema
  extends z.infer<typeof AneelInDevelopmentDTOSchema> {}
