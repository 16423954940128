import { useMutation } from "react-query";

import { UseFetchGetPaginatedUserGroupKey } from "../useFetchGetPaginatedUserGroup";
import type { IUseFetchDeleteUserGroup } from "./interface";

import { fetchDeleteUserGroup } from "~api/usergroup/{id}/delete";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchDeleteUserGroupKey = "api/usergroup/{id}/delete";

export function useFetchDeleteUserGroup(options?: IUseFetchDeleteUserGroup) {
  const { message } = useAppConfig();

  return useMutation(async (payload) => await fetchDeleteUserGroup(payload), {
    ...options,
    mutationKey: useFetchDeleteUserGroupKey,
    onSuccess: (data, variables, context) => {
      message.success("Grupo de usuários foi removido!");
      queryClient.invalidateQueries(UseFetchGetPaginatedUserGroupKey);

      if (typeof options?.onSuccess === "function") {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      message.error(`Falha ao remover grupo de usuários: ${error.message}`);
      if (typeof options?.onError === "function") {
        options.onError(error, variables, context);
      }
    },
  });
}
