import { useQuery } from "react-query";

import type { IUseFetchGetPersonDetailPipedrive } from "./interface";

import { fetchGetPersonDetailPipedrive } from "~api/projects/step_one/pipedrive/person/{id}/get";

export const useFetchGetPersonDetailPipedriveKey =
  "api/projects/step_one/pipedrive/person/{id}/get";

export function useFetchGetPersonDetailPipedrive({
  dependencyArray = [],
  options,
  payload = {
    pipedriveId: 0,
  },
}: IUseFetchGetPersonDetailPipedrive) {
  return useQuery(
    [useFetchGetPersonDetailPipedriveKey, dependencyArray],
    async () => await fetchGetPersonDetailPipedrive(payload),
    options,
  );
}
