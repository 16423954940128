import { api, apiException } from "~services/api";
import {
  FetchDeactivateBusinessUnitPayloadSchema,
  IFetchDeactivateBusinessUnitPayloadSchema,
} from "~types/schemas";

export const fetchDeactivateBusinessUnit = async (
  payload: IFetchDeactivateBusinessUnitPayloadSchema,
) => {
  try {
    const { businessUnitId } =
      FetchDeactivateBusinessUnitPayloadSchema.parse(payload);

    await api.delete<void>(`/business-units/${businessUnitId}`);
  } catch (error) {
    throw apiException(error);
  }
};
