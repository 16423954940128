import { useMutation } from "react-query";

import { useFetchGetAdminInvertersByIdKey } from "../useFetchGetAdminInvertersById";
import { useFetchGetPaginatedAdminInvertersKey } from "../useFetchGetPaginatedAdminInverters";
import type { IUseFetchCreateAdminInverters } from "./interface";

import { fetchCreateAdminInverters } from "~api/admin/inverters/post";
import { useAppConfig } from "~hooks/useAppConfig";
import { queryClient } from "~services/queryClient";

export const useFetchCreateAdminInvertersKey = "api/admin/inverters/post";

export function useFetchCreateAdminInverters({
  options,
}: IUseFetchCreateAdminInverters) {
  const { message } = useAppConfig();

  return useMutation(
    async (payload) => await fetchCreateAdminInverters(payload),
    {
      ...options,
      mutationKey: useFetchCreateAdminInvertersKey,
      onSuccess: (data, variables, context) => {
        message.success("Inversor foi criado");

        queryClient.invalidateQueries(useFetchGetPaginatedAdminInvertersKey);
        queryClient.invalidateQueries(useFetchGetAdminInvertersByIdKey);

        if (typeof options?.onSuccess === "function") {
          options.onSuccess(data, variables, context);
        }
      },
      onError(error, variables, context) {
        message.error(`Falha ao criar inversor: ${error.message}`);

        if (typeof options?.onError === "function") {
          options.onError(error, variables, context);
        }
      },
    },
  );
}
