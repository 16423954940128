import { FileDTOSchema } from "./FileDTOSchema";

import { z } from "zod";

export const TemplateReportDTOSchema = z
  .object({
    id: z.number().int().gt(0),
    name: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    file: FileDTOSchema.nullish().transform((val) => val ?? undefined),
    base64File: z
      .string()
      .nullish()
      .transform((val) => val ?? undefined),
    custom: z
      .boolean()
      .nullish()
      .transform((val) => !!val),
  })
  .partial();

export interface ITemplateReportDTOSchema
  extends z.infer<typeof TemplateReportDTOSchema> {}
