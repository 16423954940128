import { z } from "zod";

export const UserGroupFormSchema = z
  .object({
    name: z.string(),
    users: z.array(z.number()).optional(),
  })
  .partial();

export interface IUserGroupFormSchema
  extends z.infer<typeof UserGroupFormSchema> {}
