import { BusinessUnitFormNameSchema } from "./BusinessUnitFormNameSchema";

import { z } from "zod";

export const FetchCopyBusinessUnitPayloadSchema =
  BusinessUnitFormNameSchema.extend({
    businessUnitId: z.number().int().gt(0),
  });

export interface IFetchCopyBusinessUnitPayloadSchema
  extends z.infer<typeof FetchCopyBusinessUnitPayloadSchema> {}
