import { SysAdminUserFormSchema } from "./SysAdminUserFormSchema";

import { z } from "zod";

export const FetchPutSysAdminUserPayloadSchema = SysAdminUserFormSchema.extend({
  id: z.number().int().gt(0),
}).omit({
  email: true,
});

export interface IFetchPutSysAdminUserPayloadSchema
  extends z.infer<typeof FetchPutSysAdminUserPayloadSchema> {}
