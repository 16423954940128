import { api, apiException } from "~services/api";
import {
  CostAggregatorDTOSchema,
  CostAggregatorFormPayloadSchema,
  ICostAggregatorFormPayloadSchema,
} from "~types/schemas";

export const fetchCreateCostAggregator = async (
  payload: ICostAggregatorFormPayloadSchema,
) => {
  try {
    const body = CostAggregatorFormPayloadSchema.parse(payload);

    const response = await api.post(`/cost-aggregator`, body);

    const parsedData = CostAggregatorDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
