import { useQuery } from "react-query";

import type { IUseFetchGetUserOutBusinessUnit } from "./interface";

import { fetchGetUserOutBusinessUnit } from "~api/users/business-units/out/{id}/get";

export const useFetchGetUserOutBusinessUnitKey =
  "api/users/business-units/out/{id}/get";

export function useFetchGetUserOutBusinessUnit({
  dependencyArray = [],
  options,
  payload = {
    userId: 0,
  },
}: IUseFetchGetUserOutBusinessUnit) {
  return useQuery(
    [useFetchGetUserOutBusinessUnitKey, dependencyArray],
    async () => await fetchGetUserOutBusinessUnit(payload),
    options,
  );
}
