import { z } from "zod";

export const ScaleEnum = z
  .enum([
    "VALOR_TOTAL",
    "CUSTOS_COMPLEMENTARES",
    "VALOR_DO_MATERIAL",
    "POTENCIA_INVERSOR",
    "POTENCIA_MODULOS",
    "NUM_MODULOS",
    "EXPRESSAO_PERSONALIZADA",
  ] as const)
  .catch((e) => e.input);

export type ScaleEnum = z.infer<typeof ScaleEnum>;
