import { StepFourUpdateLogisticsFormSchema } from "./StepFourUpdateLogisticsFormSchema";

import { z } from "zod";

export const FetchUpdateLogisticsDistancePayloadSchema =
  StepFourUpdateLogisticsFormSchema.extend({
    proposalId: z.number().int().gt(0),
  });

export interface IFetchUpdateLogisticsDistancePayloadSchema
  extends z.infer<typeof FetchUpdateLogisticsDistancePayloadSchema> {}
