import {
  StructureComplementTypeEnum,
  StructureOrientationTypeEnum,
} from "~types/enum";
import { z } from "zod";

export const StructureTypeDetailsDTOSchema = z.object({
  id: z.number().int().nullish(),
  orientation: StructureOrientationTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
  complement: StructureComplementTypeEnum.nullish().transform(
    (val) => val ?? undefined,
  ),
});

export interface IStructureTypeDTOSchema
  extends z.infer<typeof StructureTypeDetailsDTOSchema> {}
