import { CalculationTypeEnum } from "~types/enum";
import { z } from "zod";

export const FetchGetCalculationTypeComplementaryItemsPayloadSchema = z.object({
  calculationType: CalculationTypeEnum,
});

export interface IFetchGetCalculationTypeComplementaryItemsPayloadSchema
  extends z.infer<
    typeof FetchGetCalculationTypeComplementaryItemsPayloadSchema
  > {}
