import { api, apiException } from "~services/api";
import {
  StructureMaterialCostRuleDTOSchema,
  FetchCreateStructuresMaterialCostRulePayloadSchema,
  IFetchCreateStructuresMaterialCostRulePayloadSchema,
} from "~types/schemas";

export const fetchCreateStructuresMaterialCostRule = async (
  payload: IFetchCreateStructuresMaterialCostRulePayloadSchema,
) => {
  try {
    const body =
      FetchCreateStructuresMaterialCostRulePayloadSchema.parse(payload);

    const response = await api.post(`/structuresMaterialCostRule`, body);

    const parsedData = StructureMaterialCostRuleDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
