import { api, apiException } from "~services/api";
import {
  CompanyDTOSchema,
  FetchUpdateActivityMessageFromPipedrivePayloadSchema,
  IFetchUpdateActivityMessageFromPipedrivePayloadSchema,
} from "~types/schemas";

export const fetchUpdateActivityMessageFromPipedrive = async (
  payload: IFetchUpdateActivityMessageFromPipedrivePayloadSchema,
) => {
  try {
    const body =
      FetchUpdateActivityMessageFromPipedrivePayloadSchema.parse(payload);

    const response = await api.put(
      `/company/custom-pipedrive-activity-message`,
      body,
    );

    const parsedData = CompanyDTOSchema.parse(response.data);

    return parsedData;
  } catch (error) {
    throw apiException(error);
  }
};
