import { VoltageTypeEnum } from "~types/enum";
import { z } from "zod";

export const SolarServiceFormSchema = z.object({
  voltageType: VoltageTypeEnum.nullish().transform((val) => val ?? ""),
  energyRequirement: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  energyRequirementFp: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  desiredPower: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  totalValue: z
    .number()
    .nullish()
    .transform((val) => val ?? undefined),
  cityId: z
    .number()
    .int()
    .gt(0)
    .nullish()
    .transform((val) => val ?? undefined),
});

export interface ISolarServiceFormSchema
  extends z.infer<typeof SolarServiceFormSchema> {}
